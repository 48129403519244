import * as React from 'react';
import { connect } from 'react-redux';
import { ImageSlider } from 'rsv8-components';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getProgram } from '../../redux/actions';
import { programBannersSelector } from '../../redux/selectors';

enum DevicesWidthSize {
  IPAD_PRO_WIDTH = 1024,
  IPAD_WIDTH = 768
}

function getWindowSize() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  React.useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const getContent = (content: any): string => {
  let buffer = '';
  const keys = Object.getOwnPropertyNames(content);
  keys.forEach((propertyName) => {
    const item = `${propertyName}: ${content[propertyName]};`;
    buffer += item;
  });
  return buffer;
};

const ProgramSliderContainer = styled.div`
  img {
    object-fit: contain;
  }
  ${(props) => getContent(props)};
`;

const ProgramBannerSlider = ({ programBanners, ...rest }) => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(
    () => {
      if (!programBanners) {
        return;
      }

      setLoading(false);
    },
    [programBanners]
  );

  const { width } = useWindowSize();

  const getBannerUrl = (banner) => {
    if (width > DevicesWidthSize.IPAD_PRO_WIDTH) {
      return banner.imageUrl;
    }
    if (width >= DevicesWidthSize.IPAD_WIDTH && width <= DevicesWidthSize.IPAD_PRO_WIDTH) {
      return banner.tabletImageUrl;
    }
    return banner.mobileImageUrl;
  };

  const sliderSettings = {
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false
  };
  if (!loading && programBanners) {
    const sortBanners = (a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0);
    const contentSlides = programBanners
      .filter((it) => !!it.imageUrl)
      .sort(sortBanners)
      .map((it) => {
        return {
          image: getBannerUrl(it)
        };
      });
    const { content } = rest;
    return (
      <ProgramSliderContainer {...content}>
        <ImageSlider sliderSettings={sliderSettings} contentSlides={contentSlides} />
      </ProgramSliderContainer>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  programBanners: programBannersSelector.selectMany(state)
});

export default register('rsv8-app/ProgramBannerSlider')(
  connect(mapStateToProps),
  withApiDataProvider(getProgram, programBannersSelector),
  withTheme()
)(ProgramBannerSlider);
