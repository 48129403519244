import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  HeaderThree,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
  } from 'rsv8-components';
import { myAwardActions } from 'rsv8-nomination';
import { Resources as NT } from 'rsv8-nomination';
import { withResource } from 'xcel-react-core';
import { emptyObject } from 'xcel-util';
import NominationDetailModalBody from './NominationDetailModalBody';

export interface NominationDetailModalProps {
  dispatch: Function;
  earning: any;
  award: any;
  cultureLanguage: string;
  nominationDetail: NT.NominationDetailAttributes;
  nominationDetailId: number | undefined;
  handleClose: Function;
  nominationDetailTitle: string;
  closeLabel: string;
}

export interface NominationModalDetailState {}

export class NominationDetailModal extends React.Component<NominationDetailModalProps, NominationModalDetailState> {
  nominationDetailId: number | undefined = undefined;

  handleHideNominationDetailModal = () => {
    this.props.handleClose();
  };

  getNominationDetailId(): number | undefined {
    return this.props.nominationDetailId;
  }

  fetchAwardDetails() {
    const id = this.getNominationDetailId();
    if (id !== undefined && id !== this.nominationDetailId) {
      this.props.dispatch;
      this.props.dispatch(myAwardActions.get({ filter: `nominationDetailId = ${id} ` }));
      this.props.dispatch(myAwardActions.getDetails(id));
    }
    this.nominationDetailId = id;
  }

  componentDidMount() {
    this.fetchAwardDetails();
  }

  componentDidUpdate(prevProps: NominationDetailModalProps, prevState: NominationModalDetailState) {
    this.fetchAwardDetails();
  }

  render() {
    const id = this.getNominationDetailId();
    return (
      <Modal onHide={this.props.handleClose} show={id !== undefined}>
        <ModalHeader>
          <HeaderThree>{this.props.nominationDetailTitle}</HeaderThree>
        </ModalHeader>
        <ModalBody>{id !== undefined && <NominationDetailModalBody nominationDetailId={id} />}</ModalBody>
        <ModalFooter>
          <Button themeVariation={`secondary`} onClick={this.props.handleClose}>
            {this.props.closeLabel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapResource = (getResource) => ({
  nominationDetailTitle: getResource('accountBalance.nominationModal.title', 'Nomination Detail'),
  closeLabel: getResource('accountBalance.modal.closeLabel', 'Close')
});

const mapStateToProps = (state, ownProps) => ({
  earning: emptyObject
});

export default compose(
  withResource(mapResource),
  connect(mapStateToProps)
)(NominationDetailModal as any) as any;
