import * as React from 'react';
import { ModularMenu } from 'rsv8-navigation';
import { CatalogSearch } from 'rsv8-catalog';

const CatalogLayout = ({ children }: any) => {
  return (
    <React.Fragment>
      <ModularMenu appKey="CatalogMenu" title="Shop" template="NominationsHeaderNav">
        <CatalogSearch />
      </ModularMenu>
      {children}
    </React.Fragment>
  );
};

export default CatalogLayout;
