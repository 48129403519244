import * as React from 'react';
import { ControlLabel, FormGroup, Radio } from 'react-bootstrap';
import { Forms } from 'rsv8-forms';
import styled from 'styled-components';

const Checked = styled.span`
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #bababa;
  height: 19px;
  left: 0;
  padding-right: 6px;
  position: absolute;
  top: 0;
  width: 19px;

  &:after {
    content: '';
    display: none;
    position: absolute;
  }
` as any;

const StyledRadioButton = styled(Radio)`
  cursor: pointer;
  position: relative;

  input {
    opacity: 0;
  }

  input:checked ~ ${Checked} {
    border-color: #b5be35;

    &:after {
      background: #b5be35;
      border-radius: 50%;
      display: block;
      height: 9px;
      left: 3px;
      top: 3px;
      width: 9px;
    }
  }
` as any;

const StyledRadioGroup: Forms.Inputs.RadioGroup = ({ input, meta, options, label, ...rest }) => {
  const $options = options.map((opt, i) => (
    <StyledRadioButton
      key={i}
      {...input}
      name={`${input.name}[${i}]`}
      checked={input.value.toString() === opt.value.toString()}
      value={opt.value}
    >
      {opt.label}
      <Checked />
    </StyledRadioButton>
  ));
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      {$options}
    </FormGroup>
  );
};

export default StyledRadioGroup;
