import * as React from 'react';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';
export type Props = {
  backgroundImage: string;
  backgroundColor: string;
  backgroundPosition: string;
  backgroundRepeat: string;
  backgroundSize: string;
  backgroundAttachment: string;
};

const StyledBackground = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background-image: url('${(props: Props) => props.backgroundImage ? assetService.getImage(props.backgroundImage) : ''}');
  background-color: ${(props: Props) => (props.backgroundColor ? `#${props.backgroundColor}` : '')};
  background-position: ${(props: Props) => (props.backgroundPosition ? props.backgroundPosition : 'center')};
  background-repeat: ${(props: Props) => (props.backgroundRepeat ? props.backgroundRepeat : 'no-repeat')};
  background-size: ${(props: Props) => (props.backgroundSize ? props.backgroundSize : 'auto')};
  background-attachment: ${(props: Props) => (props.backgroundAttachment ? props.backgroundAttachment : 'scroll')};
`;

const FullBackground = ({ children, ...rest }: any) => {
  return <StyledBackground {...rest}>{children}</StyledBackground>;
};

const mapContentToProps = (getContent) => ({
  backgroundImage: getContent('backgroundImage', { type: 'none', label: 'Background Image' }),
  backgroundColor: getContent('backgroundColor', { type: 'none', label: 'Background Color (HEX)' }),
  backgroundPosition: getContent('backgroundPosition', {
    type: 'select',
    label: 'Background Position',
    options: [
      { label: 'left top', value: 'left top' },
      { label: 'left center', value: 'left center' },
      { label: 'left bottom', value: 'left bottom' },
      { label: 'right top', value: 'right top' },
      { label: 'right center', value: 'right center' },
      { label: 'right bottom', value: 'right bottom' },
      { label: 'center top', value: 'center top' },
      { label: 'center center', value: 'center center' },
      { label: 'center bottom', value: 'center bottom' }
    ]
  }),
  backgroundRepeat: getContent('backgroundRepeat', {
    type: 'select',
    label: 'Background Repeat',
    options: [
      { label: 'repeat', value: 'repeat' },
      { label: 'no-repeat', value: 'no-repeat' },
      { label: 'repeat-x', value: 'repeat-x' },
      { label: 'repeat-y', value: 'repeat-y' },
      { label: 'space', value: 'space' },
      { label: 'round', value: 'round' },
      { label: 'initial', value: 'initial' },
      { label: 'inherit', value: 'inherit' }
    ]
  }),
  backgroundSize: getContent('backgroundSize', {
    type: 'select',
    label: 'Background Size',
    options: [
      { label: 'auto', value: 'auto' },
      { label: 'length', value: 'length' },
      { label: 'cover', value: 'cover' },
      { label: 'contain', value: 'contain' },
      { label: 'initial', value: 'initial' },
      { label: 'inherit', value: 'inherit' },
      { label: '100% 100%', value: '100% 100%' }
    ]
  }),
  backgroundAttachment: getContent('backgroundAttachment', {
    type: 'select',
    label: 'Background Attachment',
    options: [
      { label: 'scroll', value: 'scroll' },
      { label: 'fixed', value: 'fixed' },
      { label: 'local', value: 'local' },
      { label: 'initial', value: 'initial' },
      { label: 'inherit', value: 'inherit' }
    ]
  })
});

export default register('rsv8-components/FullBackground')(
  withContent(mapContentToProps),
  withTheme(['.login-background'])
)(FullBackground);
