import * as R from 'ramda';
import { push } from 'react-router-redux';
import { authStateSelectors, userStateSelectors } from 'rsv8-auth';
import { mapJsonApiActions } from 'xcel-redux-orm';
import { cmsApi, navigationApi } from '../../api';
import {
  Clear,
  ConfirmClear,
  ConfirmPath,
  DeleteLastVisitedLanding,
  IntentPath,
  PostponePath,
  SetDataLoaded,
  SetLandingPath,
  SetLoading
  } from '../../types/landing';
import { LandingContent } from '../../types/landing';
import {
  DELETE_LAST_VISITED_LANDING,
  LANDING_CLEAR,
  LANDING_CONFIRM_CLEAR,
  LANDING_CONFIRM_PATH,
  LANDING_INTENT_PATH,
  LANDING_POSTPONE_PATH,
  LANDING_SET_PATH,
  SET_DATA_LOADED,
  SET_LOADING_DATA
  } from '../events';
import { getLanding, landingSelectors } from '../selectors';

const startsWith = (pathToCheck) => (landing) => R.startsWith(pathToCheck, landing.path);
const pickLanding = ({ path }) => path;
const pickVisited = ({ route }) => route;
const mappedNavigationApiActions = mapJsonApiActions(navigationApi);
const fetchLandingData = async (dispatch, getState, path) => {
  const mappedJsonApi = mapJsonApiActions(cmsApi);
  let visitedLandingPages = [];
  let landingPagesForPath = [];
  let state = getState();
  const user = userStateSelectors.getUser(state);
  const auth = authStateSelectors.getAuth(state);
  if ((!auth || auth.isOAuth || !auth.isValid) && user.clientId) {
    await dispatch(
      mappedJsonApi.getContentsTypesPublicByContentType({
        path,
        contentType: 'landing',
        sort: 'publishedDateTimeUtc+'
      })
    );
  } else {
    await dispatch(
      mappedJsonApi.getContentsTypesByContentType({
        path,
        contentType: 'landing',
        sort: 'publishedDateTimeUtc+'
      })
    );
  }

  landingPagesForPath = landingSelectors.searchMany(state, startsWith(path));
  landingPagesForPath = R.map(pickLanding)(landingPagesForPath);

  if (landingPagesForPath.length !== 0) {
    visitedLandingPages = await navigationApi.getVisits({
      routeKeys: landingPagesForPath
    });
    visitedLandingPages = R.map(pickVisited)(visitedLandingPages);
  }

  return { landingPagesForPath, visitedLandingPages };
};

const intentPath = (path): IntentPath => ({
  type: LANDING_INTENT_PATH,
  payload: path
});

const setLandingPath = (paths: Array<LandingContent>, visitedPaths: Array<string>): SetLandingPath => ({
  type: LANDING_SET_PATH,
  payload: { paths, visitedPaths }
});

const postponePath = ({ path }): PostponePath => ({
  type: LANDING_POSTPONE_PATH,
  payload: { path }
});

const confirmPath = ({ path }): ConfirmPath => ({
  type: LANDING_CONFIRM_PATH,
  payload: { path }
});

const deleteLastVisitedLanding = ({ path }): DeleteLastVisitedLanding => ({
  type: DELETE_LAST_VISITED_LANDING,
  payload: { path }
});

const clear = (): Clear => ({
  type: LANDING_CLEAR
});

const confirmClear = (): ConfirmClear => ({
  type: LANDING_CONFIRM_CLEAR
});

const loadingLandingData = (): SetLoading => ({
  type: SET_LOADING_DATA
});

const landingDataLoaded = (): SetDataLoaded => ({
  type: SET_DATA_LOADED
});

const reportIntentPath = (path: string) => async (dispatch, getState) => {
  let state = getState();
  const { nextLandingRedirect, intentedPath } = getLanding(state);
  const landingData = await fetchLandingData(dispatch, getState, path);
  dispatch(landingDataLoaded());
  dispatch(setLandingPath(landingData.landingPagesForPath, landingData.visitedLandingPages));
  if (path === intentedPath || path === nextLandingRedirect) {
    return;
  }

  dispatch(intentPath(path));
  dispatch(loadingLandingData());
};

const navigateNextLanding = () => async (dispatch, getState) => {
  let state = getState();
  let { nextLandingPath: lastNavigated, intentedPath } = getLanding(state);
  let landingData = await fetchLandingData(dispatch, getState, intentedPath);

  landingData.visitedLandingPages.push(lastNavigated);

  await dispatch(setLandingPath(landingData.landingPagesForPath, landingData.visitedLandingPages));

  state = getState();
  let nextLandingPath = getLanding(state).nextLandingPath;
  intentedPath = getLanding(state).intentedPath;

  if (!nextLandingPath) {
    dispatch(push(intentedPath));
  }
};

const navigatePrevLanding = (path: string) => async (dispatch, getState) => {
  await dispatch(deleteLastVisitedLanding({ path }));

  const state = getState();
  const { intentedPath } = getLanding(state);
  const landingData = await fetchLandingData(dispatch, getState, intentedPath);

  dispatch(setLandingPath(landingData.landingPagesForPath, landingData.visitedLandingPages));
};

const postponeLandingVisit = (path: string) => async (dispatch, getState) => {
  path = path.replace('/landing', '');
  dispatch(postponePath({ path }));
  dispatch(navigateNextLanding());
};

const confirmLandingVisit = (path: string) => async (dispatch, getState) => {
  path = path.replace('/landing', '');
  await dispatch(
    mappedNavigationApiActions.updateVisits({
      body: { routeKey: path, route: path }
    })
  );
  dispatch(confirmPath({ path }));
  dispatch(navigateNextLanding());
};

export {
  intentPath,
  setLandingPath,
  reportIntentPath,
  navigateNextLanding,
  navigatePrevLanding,
  postponePath,
  confirmPath,
  clear,
  postponeLandingVisit,
  confirmLandingVisit,
  confirmClear
};
