import * as React from 'react';
import { Step } from '../../../types';
import { Wizard } from '../Wizard';

const ContentWizard: React.StatelessComponent<{
  name: string;
  steps: Array<Step>;
}> = ({ name, steps, ...rest }) => {
  return <Wizard name={name} steps={steps} {...rest} />;
};

export { ContentWizard };
