import * as React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Forms } from '../../types';
import FormControl from './FormControl';
import { OptionsList, StatusText } from './helpers';
import Label from './Label';
import { TooltipContainer } from './TooltipContainer';

const BSSelect: Forms.Inputs.BSSelect = ({
  label,
  options,
  input,
  meta,
  validationState,
  helpText,
  helpIconText,
  defaultSelectText,
  autoHideDefaultSelectText,
  optionRenderer = OptionsList,
  ...rest
}) => {
  const OptionsComponent = optionRenderer as any;

  if (options.length > 0 && defaultSelectText) {
    let added = false;
    for (var i = 0; i < options.length; i++) {
      if (options[i].label && options[i].label === defaultSelectText) {
        added = true;
        break;
      }
    }
    if (!added) {
      options.unshift({
        value: '',
        label: defaultSelectText,
        disabled: autoHideDefaultSelectText,
        hidden: autoHideDefaultSelectText,
        selected: true
      });
    }
  }
  return (
    <FormGroup
      controlId={input.name}
      validationState={validationState}
      className={!meta.valid && meta.touched ? 'has-error' : ''}
    >
      {label && <Label>{label}</Label>}
      {helpIconText && <TooltipContainer tooltipChildren={helpIconText} />}
      <FormControl componentClass="select" {...input} {...rest}>
        {options && <OptionsComponent options={options} />}
      </FormControl>
      <StatusText meta={meta} helpText={helpText} />
    </FormGroup>
  );
};

export default BSSelect;
