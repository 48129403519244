import styled from 'styled-components';
import {HeaderOne} from 'rsv8-components';

export const CategoryPageHeading = styled(HeaderOne)`
  margin: 3px 0 25px 0;
  padding: 3px 0 15px 0;

  @media (max-width: 768px) {
    text-align: center;
  }

  @media (min-width: 769px) {
    border-bottom: 1px solid #ededed;
  }
` as any;
