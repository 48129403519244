import * as React from 'react';

const AddBehavior = ({ children, ...props }: any) => {
  if (children) {
    return React.Children.map(children, (child: any) => {
      return React.cloneElement(child, { ...props });
    });
  }
  return null;
};
export default AddBehavior as any;
