import * as React from "react";
import { ComponentRenderer } from "rsv8-cms";

interface Props extends React.HTMLProps<any> {
  loading: boolean;
  value: number;
  emptyValue?: string;
  formatOptions?: Object;
}

const UserMetricText: React.FC<Props> = ({
  className,
  loading,
  value,
  emptyValue = "-",
  formatOptions = {},
  children,
  ...props
}) => {
  const content = React.useMemo(() => {
    let content = null;
    if (Array.isArray(children)) {
      content = { content: children };
    } else if (typeof children === 'object') {
      const isEditing = props['data-cms-editing'];
      if (isEditing) {
        content = { content: (children as any).props.components };
      } else {
        content = { content: (children as any).props.data };
      }
    }
    return content;
  }, [children]);

  const valueStr = React.useMemo(() => {
    return value != null ? new Intl.NumberFormat('en-US', formatOptions).format(value) : emptyValue;
  }, [value]);

  if (!content) {
    return (
      <div className={className}>
        {loading && <span className="fa fa-circle-o-notch fa-spin" />}
        {!loading && value != null && <div className="value">{valueStr}</div>}
        {!loading && value == null && <div className="no-value">{emptyValue}</div>}
      </div>
    );
  }

  return (
    <div className={className}>
      {loading && <span className="fa fa-circle-o-notch fa-spin" />}
      {!loading && <ComponentRenderer value={valueStr} data={content.content} />}
    </div>
  )
};

export default UserMetricText;