import * as React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Forms } from '../../types';
import addValidationState from '../HOC/addValidationState';
import FormControl from './FormControl';
import { StatusText } from './helpers';
import Label from './Label';
import { TooltipContainer } from './TooltipContainer';
const TextArea: Forms.Inputs.TextArea = ({
  meta,
  validationState,
  helpText,
  helpIconText,
  input,
  label,
  rows,
  ...rest
}) => {
  return (
    <FormGroup controlId={input.name} validationState={validationState}>
      <Label>{label}</Label>
      {helpIconText && <TooltipContainer tooltipChildren={helpIconText} />}
      <FormControl componentClass="textarea" rows={rows || 4} {...input} />
      <StatusText meta={meta} helpText={helpText} />
    </FormGroup>
  );
};

export default addValidationState(TextArea);
