import * as React from 'react';
import { Toastify } from 'rsv8-components';
import { RouteMatch } from 'xcel-react-core';
import AchievementNav from './AchievementNav';

interface LayoutProps {
  auth?: boolean
  [index: string]: any
};

const AchievementLayout: React.FC<LayoutProps> = ({ children }) => (
  <RouteMatch>
    <React.Fragment>
      <AchievementNav />
      <Toastify hideProgressBar={true} autoClose={2500} />
      {children}
    </React.Fragment>
  </RouteMatch>
);

export default AchievementLayout;
