import * as React from 'react';
import { connect } from 'react-redux';
import {
  GridColumn,
  HeaderTwo,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
  } from 'rsv8-components';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
import { withResource } from 'xcel-react-core';
import metricApiActions from '../../redux/actions/metricApiActions';
import * as selectors from '../../redux/selectors/metricSelectors';
import { numberFormat } from 'xcel-util';
import {
  GoalItem,
  GoalItemContainer,
  GoalItemInput,
  Intro,
  Result,
  StyledText
  } from './styles';

export interface GoalsModalProps {
  show: boolean;
  onHide: any;
  programKey: string;
  calculatorKey: string;
  dispatch: any;
  goals: selectors.CalculatorMetric[];
  totals: selectors.CalculatorResult[];
  timePeriod: selectors.TimePeriod[];
  goalValue: number;
  projectedResult: string;
  modalIntro: string;
  currency: string;
  saveAndNextButton: string;
  saveAndBackButton: string;
  saveAndFinishButton: string;
  cancelButton: string;
  className: string;
}

export interface GoalsModalState {
  index: number;
  goals: any;
}

const CancelLink = styled(Link)`
  float: left;
  margin: 3px 0;
  text-align: center;
`;

const ContinueLink = styled(Link)`
  float: right;
  margin: 3px 0;
  text-align: center;
`;

class GoalsModal extends React.Component<GoalsModalProps, GoalsModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      index: 0,
      goals: {}
    };
  }

  componentDidMount() {
    this.getTimePeriod().then(() => {
      this.getGoals().then(() => {
        this.getInitialTotal();
      });
    });
  }

  getActiveTimePeriod = (index = this.state.index): selectors.TimePeriod => {
    return this.props.timePeriod[index] || ({} as any);
  };
  getActiveGoals = (index = this.state.index) => {
    return this.props.goals.filter((goal) => goal.timePeriodKey === this.getActiveTimePeriod(index).key);
  };
  getTimePeriod = () => {
    const { dispatch, programKey } = this.props;
    return dispatch(metricApiActions.getTimePeriod({ programKey }));
  };
  getActiveTotal = () => {
    return this.props.totals.find((total) => total.id === `TOTAL_${this.getActiveTimePeriod().key}`);
  };

  getInitialTotal = (index = this.state.index) => {
    const { dispatch, programKey, calculatorKey } = this.props;
    const timePeriodKey = this.getActiveTimePeriod(index).key;
    return Promise.all([
      dispatch(metricApiActions.createCalculatorGoalsCalculateByCalculatorKey({ programKey, calculatorKey, body: [] })),
      dispatch(
        metricApiActions.createCalculatorCalculateByCalculatorKey({
          programKey,
          calculatorKey,
          timePeriodKey,
          body: this.getActiveGoals(index).map((goal) => ({ metricKey: goal.key, value: goal.goalValue }))
        })
      )
    ]);
  };

  getGoals = (index = this.state.index) => {
    const { dispatch, programKey, calculatorKey } = this.props;
    const timePeriodKey = this.getActiveTimePeriod(index).key;
    return dispatch(metricApiActions.getCalculatorGoalsByCalculatorKey({ programKey, calculatorKey, timePeriodKey }));
  };

  updateTotal = async (id, inputValue) => {
    const { dispatch, programKey, calculatorKey } = this.props;
    const goals = this.getActiveGoals();
    const timePeriodKey = this.getActiveTimePeriod().key;
    const getFromState = goals
      .filter((goal) => goal.key !== id && goal.key in this.state.goals)
      .map((goal) => ({ metricKey: goal.key, value: this.state.goals[goal.key] }));
    const getFromGoals = goals
      .filter((goal) => goal.key !== id && !(goal.key in this.state.goals))
      .map((goal) => ({ metricKey: goal.key, value: goal.goalValue }));
    const filteredFromGoals = goals
      .filter((goal) => goal.key === id)
      .map((goal) => ({ metricKey: id, value: inputValue }));
    const combinedGoals = getFromGoals.concat(filteredFromGoals, getFromState);

    await dispatch(
      metricApiActions.createCalculatorCalculateByCalculatorKey({
        programKey,
        calculatorKey,
        timePeriodKey,
        body: combinedGoals
      })
    );
    dispatch(metricApiActions.createCalculatorGoalsCalculateByCalculatorKey({ programKey, calculatorKey, body: [] }));
    this.postGoals(combinedGoals);
  };

  clearInputField = (e) => {
    return (
      e.target.value
    )
  }

  handleChange = (key, e) => {
    const verifyPositiveNumber = Math.abs(e.target.value);
    this.setState({ goals: { ...this.state.goals, [key]: verifyPositiveNumber } });
  };

  postGoals = (combinedGoals) => {
    const { dispatch, programKey, calculatorKey } = this.props;
    const timePeriodKey = this.getActiveTimePeriod().key;

    return dispatch(
      metricApiActions.createCalculatorByCalculatorKey({
        programKey,
        calculatorKey,
        timePeriodKey,
        body: combinedGoals
      })
    );
  };

  next = async () => {
    let index = this.state.index;
    if (this.state.index !== this.props.timePeriod.length - 1) {
      index++;
      await this.getGoals(index);
      await this.getInitialTotal(index);
      this.setState({ index, goals: {} });
    } else {
      this.props.onHide();
    }
  };

  back = async () => {
    let index = this.state.index;
    if (this.state.index !== 0) {
      index--;
      await this.getGoals(index);
      await this.getInitialTotal(index);
      this.setState({ index, goals: {} });
    } else {
      this.props.onHide();
    }
  };

  render() {
    const total = this.getActiveTotal();
    const goals = this.getActiveGoals();
    return (
      <Modal className={this.props.className} show={this.props.show} onHide={this.props.onHide}>
        <ModalHeader closeButton={true} />
        <ModalBody>
          <Intro md={24}>
            <StyledText>{this.props.modalIntro}</StyledText>
            <HeaderTwo className="goal-header">{this.getActiveTimePeriod().name}</HeaderTwo>
          </Intro>
          {goals
            .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
            .map((item, i) => (
              <GoalItemContainer key={item.key} className="goal-item-container" fluid="true">
                <GoalItem className="goal-item" xs={18} sm={18} md={20} lg={20}>
                  <StyledText>{item.label}</StyledText>
                </GoalItem>
                <GridColumn xs={6} sm={6} md={4} lg={4}>
                  <GoalItemInput
                    className="goal-item-input"
                    id={item.key}
                    type="number"
                    min="0"
                    name={item.key}
                    onBlur={(e) => this.updateTotal(item.key, e.target.value)}
                    onChange={(e) => this.handleChange(item.key, event)}
                    onFocus={(e) => this.clearInputField(event)}
                    value={
                      this.state.goals[item.key] !== undefined
                        ? this.state.goals[item.key]
                        : item.goalValue && item.goalValue === null
                        ? '0'
                        : item.goalValue && item.goalValue !== null
                        ? item.goalValue
                        : ''
                    }
                  />
                </GridColumn>
              </GoalItemContainer>
            ))}
          <Result md={24}>
            <StyledText>{this.props.projectedResult}</StyledText>
                  <HeaderTwo className="goal-result">{total && numberFormat(total.value)} {this.props.currency}</HeaderTwo>
          </Result>
        </ModalBody>
        <ModalFooter>

          <CancelLink themeVariation="secondary-button" className="cancel-button" onClick={this.back}>
            {this.state.index === 0 ? this.props.cancelButton : this.props.saveAndBackButton}
          </CancelLink>
          <ContinueLink themeVariation="primary-button" className="save-button" onClick={this.next}>
            {this.state.index === this.props.timePeriod.length - 1
              ? this.props.saveAndFinishButton
              : this.props.saveAndNextButton}
          </ContinueLink>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  projectedResult: getResource('myGoals.projectedResult', 'Projected Result'),
  modalIntro: getResource('myGoals.modalIntro', 'Set my monthly goal'),
  currency: getResource('myGoals.currency', 'pts.'),
  saveAndNextButton: getResource('myGoals.saveAndNextButton', 'SAVE & NEXT'),
  saveAndBackButton: getResource('myGoals.saveAndBackButton', 'SAVE & GO BACK'),
  saveAndFinishButton: getResource('myGoals.saveAndFinishButton', 'SAVE & FINISH'),
  cancelButton: getResource('myGoals.cancelButton', 'CANCEL')
});

const mapStateToProps = (state) => {
  return {
    goals: selectors.calculatorMetric.selectMany(state, null),
    totals: selectors.calculatorResult.selectMany(state, null),
    timePeriod: selectors.timePeriod.selectMany(state, null)
  };
};

export default register('rsv8-metrics/GoalsModal')(
  connect(mapStateToProps),
  withTheme(),
  withResource(mapResourceToProps)
)(GoalsModal);
