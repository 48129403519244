import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions } from 'xcel-redux-orm';
import { productApi } from '../../../../api';

const actions = createJsonApiActions('getProduct');
const getProductCall = (id) => {
  return productApi.getProductsById({ id: id }, { jsonApi: true });
};

const getProduct = promiseDispatcher(getProductCall, actions);

export { getProduct };
