import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setActiveModal } from '../../redux/actions';
import { getCurrentModal } from '../../redux/selectors';
import AssetAdminModal from '../Modal';
import { CreateRoute } from './index';

export interface CreateAssetRoute {
  handleHide?: any;
  show?: any;
  actions?: {
    setActiveModal: Function;
  };
  activeModal?: string;
  handleClick?: Function;
}

class CreateRouteButton extends React.Component<CreateAssetRoute> {
  state = { activeModal: '' };
  handleClick = () => {
    this.setState({ activeModal: 'create-button-modal' });
  };

  handleHide = () => {
    this.setState({ activeModal: '' });
  };

  render() {
    return (
      <AssetAdminModal
        handleClick={this.handleClick}
        handleHide={this.handleHide}
        show={this.state.activeModal === 'create-button-modal'}
        icon="fa fa-plus"
        modaleTitle="Add Route"
        color="green"
      >
        <CreateRoute />
      </AssetAdminModal>
    );
  }
}

type MapState = Pick<CreateAssetRoute, 'activeModal'>;
type MapDispatch = Pick<CreateAssetRoute, 'actions'>;

const mapDispatchToProps = (dispatch): MapDispatch => ({
  actions: bindActionCreators(
    {
      setActiveModal
    },
    dispatch
  )
});

const mapStateToProps = (state): MapState => {
  return {
    activeModal: getCurrentModal(state)
  };
};

export default connect<MapState, MapDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(CreateRouteButton);
