import * as React from 'react';
import { generateDelay, defaultDuration } from './../../util';
import Particles from './Particles';
class PlayableParticles extends React.Component<any, any> {
  state = {
    isPlaying: false
  };
  handlePlay = () => {
    let { duration, delay = generateDelay } = this.props;
    duration = duration === undefined ? defaultDuration : duration;
    const animationTimeout = duration * delay().max * 1000;
    this.setState({ isPlaying: true }, () => {
      setTimeout(() => {
        this.setState({ isPlaying: false });
      }, animationTimeout);
    });
  };
  render() {
    const { children, animation, ...rest } = this.props;
    return (
      <div>
        <Particles {...rest} animation={this.state.isPlaying ? animation : undefined}>
          {children}
        </Particles>
        <button onClick={this.handlePlay}>play</button>
      </div>
    );
  }
}
export default PlayableParticles;
