import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, ReactField, reduxForm } from 'rsv8-forms';
import cmsPluginSelectors from '../../redux/cms.plugin.selectors';
import { fontPreviewSet, fontRemove, fontUpdate } from '../../redux/font.actions';
import { getTheme } from '../../redux/theme.selectors';
import { mapDispatchToActions } from '../../util';
import FontLibraryPicker from './../../components/Fields/Font/FontLibraryPicker';
import FontSizePreview from './FontSizePreview';
import { Spacer } from './styles';
const FontManage = ({ actions, fonts, preview, handleSubmit }) => {
  preview = preview ? preview : 'None';
  const activeFont = fonts[preview] || {};
  const handleChange = (property) => (e) => {
    let font = { ...activeFont, [property]: e.target ? e.target.value : e };
    actions.fontUpdate(font);
  };
  const handleRemove = () => {
    actions.fontRemove(preview);
  };
  if (Object.keys(fonts).length === 0) return null;
  const handleChangeFont = (value) => {
    const fontName = Object.keys(fonts).find((key) => key.toLocaleLowerCase() === value.toLocaleLowerCase());
    actions.fontPreviewSet(fonts[fontName]);
  };
  return (
    <form onSubmit={handleSubmit(fontUpdate)}>
      <h4>Manage Fonts</h4>
      <Row>
        <Col md={2}>
          <label>Preview</label>
          <Field component={'checkbox'} name={'showPreview'} />
        </Col>
        <Col md={6}>
          <FontLibraryPicker label={'Font Name'} onChange={handleChangeFont} value={preview} />
        </Col>
        <Col md={6}>
          <ReactField
            label={`Font Family`}
            component={'bs-input'}
            value={activeFont.family}
            onChange={handleChange('family')}
          />
        </Col>
        <Col md={4}>
          <ReactField
            label={`Line Height`}
            component={'bs-input'}
            value={activeFont.lineHeight}
            name={'lineHeight'}
            onChange={handleChange('lineHeight')}
          />
        </Col>
        <Col md={2}>
          <Spacer />
          <Button onClick={handleRemove}>
            <i className="fa fa-trash-o" />
          </Button>
        </Col>

        <Col md={20}>
          <ReactField
            label={`Font Face`}
            component={'bs-textarea'}
            rows={10}
            value={activeFont.face}
            name={'face'}
            onChange={handleChange('face')}
          />
        </Col>
        <Col md={24}>
          <FontSizePreview />
        </Col>
      </Row>
    </form>
  );
};

const mapStateToProps = (state) => ({
  fonts: cmsPluginSelectors.getFonts(state),
  preview: getTheme(state).font.preview
});
const mapDispatchToProps = mapDispatchToActions({ fontPreviewSet, fontRemove, fontUpdate });

export default reduxForm({ form: 'fontManage' })(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(FontManage)
);
