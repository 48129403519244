import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions } from 'xcel-redux-orm';
import { nominationApi } from '../../../api/index';

const actions = createJsonApiActions('getWinners');
const getWinnersCall = () => {
  return nominationApi.getWinners({}, { jsonApi: true });
};
const getWinners = promiseDispatcher(getWinnersCall, actions);

export { getWinners };
