import * as R from 'ramda';
import * as React from 'react';
import {
  Alert,
  Col,
  Grid,
  Row
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { withWizard, WithWizardProps } from 'rsv8-wizard';
import styled from 'styled-components';
import { register, withResource } from 'xcel-react-core';
import { IndividualAwardInstructions as AwardInstructions } from '../../components/index';
import NominationCollapse from '../../components/NominationCollapse/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { individualNominationActions } from '../../redux/actions/index';
import { individualNominationSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types';
import AwardSelection from './AwardSelection';

const { selectAward, getAwardDetails, getAwards } = individualNominationActions;

const StyledGrid = styled(Grid)`
  &.hide {
    display: none;
  }
` as any;

const ColFlexCentered = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
` as any;

const AwardWrapper = styled.div`
  padding-top: 50px;
` as any;

export interface AwardPageProps extends WithWizardProps {
  currentAward: string;
  awards: Array<Resources.Award>;
  errorText: string;
  buttonTitle: string;
  actions: {
    selectAward: (awardId: string | number, confirmText: string) => void;
    getAwardDetails: (awardId: string, nomineeId: string) => void;
    getAwards: (nomineeId?: string | number) => void;
  };
  nominee: Object;
  nomineeId: string;
  hideAwardDetails: string;
}

interface AwardPageState {
  hasError: boolean;
  showPage: boolean;
  toastDisplayed: boolean;
}
class AwardPage extends React.Component<AwardPageProps, AwardPageState> {
  skipped = false;

  constructor(props: AwardPageProps) {
    super(props);
    this.state = { hasError: false, showPage: false, toastDisplayed: false };
  }

  componentDidMount() {
    const { wizard } = this.props;
    if (wizard) {
      wizard.registerIncrement(this.checkValid);
    }
    this.shouldSkip();
  }

  componentDidUpdate(prevProps: any) {
    const { awards, nominee } = this.props;
    if (awards !== prevProps.awards && awards && awards.length) {
      this.shouldSkip();
    }
    if (awards.length === 0 && !this.state.showPage) {
      this.setState({ showPage: true });
    }
    if (
      this.shouldShowEligibilityModal() &&
      awards !== prevProps.awards &&
      this.state.toastDisplayed === false
    ) {
      toast({
        template: 'ContentToast',
        contentTemplate: (
          <PageRenderer
            path={'toast/nomination/individual/threshold'}
            onNotFound={() => <PageRenderer path={`toast/nomination/individual/threshold`} cultureId="0" />}
            nominee={nominee}
          />
        )
      });
      this.setState({ toastDisplayed: true });
    }
  }

  shouldSkip = () => {
    let { awards, actions, nomineeId } = this.props;
    if (awards.length === 0) {
      actions.getAwards(nomineeId);
      return;
    }
    if (awards.length === 1) {
      this.skipAwardSection(awards[0].id);
    } else {
      this.setState({ showPage: true });
    }
  };

  skipAwardSection = async (value) => {
    await this.forceSelection(value);
    const { wizard } = this.props;
    if (wizard && !this.skipped) {
      wizard.removeWizardStep();
      wizard.increment();
      this.skipped = true;
    }
  };

  forceSelection = (value) => {
    const { actions, nomineeId } = this.props;
    actions.getAwardDetails(value, nomineeId);
    actions.selectAward(value, null);
  };

  checkValid = () => {
    const { currentAward } = this.props;
    if (currentAward !== undefined && currentAward !== null && currentAward !== '') {
      this.setState({ hasError: false });
      return Promise.resolve();
    } else {
      this.setState({ hasError: true });
      return Promise.reject('Please select an award.');
    }
  };

  render() {
    const { showPage, hasError } = this.state;
    const { currentAward, errorText, awards, nomineeId, hideAwardDetails, buttonTitle } = this.props;
    return (
      <StyledGrid className={!showPage && 'hide'}>
        <Row>
          <ColFlexCentered xs={24} lg={20} lgOffset={2}>
            <AwardInstructions />
          </ColFlexCentered>
        </Row>
        <Row>
          <Col xs={24}>
            {hasError && !currentAward ? (
              <ErrorFormContainer>
                <Alert bsStyle="danger">{errorText}</Alert>
              </ErrorFormContainer>
            ) : null}

            <AwardWrapper>
              <AwardSelection awards={awards} nominee={nomineeId} />
              {hideAwardDetails === 'false' && (
                <NominationCollapse title={buttonTitle}>
                  <PageRenderer path={`partial/nomination/individual/award-approval-details`} />
                </NominationCollapse>
              )}
            </AwardWrapper>
          </Col>
        </Row>
      </StyledGrid>
    );
  }

  private shouldShowEligibilityModal() {
    const notEligible = (award: Resources.Award) => !award.isEligible;
    return R.any(notEligible)(this.props.awards);
  }
}

const mapState = (state) => {
  const awards = individualNominationSelectors.getAwards(state);
  const nominee = individualNominationSelectors.getCurrentNominee(state);
  return {
    nomineeId: nominee.id.toString(),
    currentAward: individualNominationSelectors.getCurrentAwardId(state),
    awards: !Array.isArray(awards) ? [awards] : awards,
    nominee
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ selectAward, getAwards, getAwardDetails }, dispatch)
});

const mapResource = (getResource) => ({
  errorText: getResource('individualNomination.selectAwardError', 'Please Select an Award'),
  buttonTitle: getResource('nominationProcess.awardDetailsButtonTitle', 'Award Approval Details'),
  hideAwardDetails: getResource('nominationProcess.hideAwardDetails', 'false')
});

const compose = register('rsv8-nomination/IndividualSelectAward');

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource),
  withWizard()
)(AwardPage);