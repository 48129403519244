import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose, shouldUpdate } from 'recompose';
import { bindActionCreators } from 'redux';
import { DropdownItem, Text } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { salesActions } from '../../redux/actions';
import { SalesActivityContext } from './SalesActivity';

const MenuContainer = styled.div`
  display: inline-block;
  .open > .dropdown-menu {
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
  }
`;

interface Props {
  activateModal: typeof salesActions.activateModal;
  data: {
    id: number | string;
  };
  qs: { [index: string]: any };
  resources: {
    [index: string]: any;
  };
}

const SaleDetailsIcon: React.FunctionComponent<Props> = ({ data: { id }, activateModal, resources }) => {
  const context = React.useContext(SalesActivityContext);

  return (
    <MenuContainer>
      <DropdownButton title="" id={id.toString()}>
        <MenuItem>
          <DropdownItem onClick={() => activateModal(id.toString(), context.qs)}>
            <Text themeVariation="secondary-text">{resources.detailsLabel}</Text>
          </DropdownItem>
        </MenuItem>
      </DropdownButton>
    </MenuContainer>
  );
};

const mapDispatch = (dispatch) => bindActionCreators({ activateModal: salesActions.activateModal }, dispatch);

const mapResource = (getResource) => ({
  resources: {
    detailsLabel: getResource('salesEntry.viewDetailsButtonText', 'View Details')
  }
});

export default compose(
  shouldUpdate((propsA: any, propsB: any) => propsB.data.id !== propsA.data.id),
  withResource(mapResource),
  connect(
    null,
    mapDispatch
  )
)(SaleDetailsIcon) as any;
