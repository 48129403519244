import * as React from 'react';
import {
  DataTableColumn,
  DataTableDefault,
  HeaderTwo,
  Link,
  renderDate
  } from 'rsv8-components';
import { SORT_DIRECTIONS } from 'rsv8-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { numberFormat } from 'xcel-util';
import getOrders from '../../redux/actions/getOrders';
import orderSelectors from '../../redux/selectors/orderSelectors';

export interface OrdersTableProps {
  dispatch: Function;
  pointsShorthand: string;
  dateTableHeader: string;
  pointsLabel: string;
  confirmationTableHeader: string;
  orderHistoryLabel: string;
  ordersEmptyText: string;
}

const defaultSort = { field: 'orderDate', direction: SORT_DIRECTIONS.desc };

export class OrdersTable extends React.PureComponent<OrdersTableProps, {}> {
  renderConfirmationLink = ({ record, column }) => (
    <Link to={`/catalog/history/${record[column.field]}`}>{record[column.field]}</Link>
  );
  renderPoints = ({ record }) => {
    return numberFormat(record.orderPoints) + ' ' + this.props.pointsShorthand;
  };

  render() {
    return (
      <React.Fragment>
        <HeaderTwo>{this.props.orderHistoryLabel}</HeaderTwo>
        <DataTableDefault {...this.props} defaultSort={defaultSort} emptyText={this.props.ordersEmptyText}>
          <DataTableColumn field="orderDate" renderCell={renderDate}>
            {this.props.dateTableHeader}
          </DataTableColumn>
          <DataTableColumn field="id" sortable={false} renderCell={this.renderConfirmationLink}>
            {this.props.confirmationTableHeader}
          </DataTableColumn>
          <DataTableColumn field="orderPoints" renderCell={this.renderPoints}>
            {this.props.pointsLabel}
          </DataTableColumn>
        </DataTableDefault>
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({
  orderHistoryLabel: getResource('accountBalance.orderHistoryLabel', 'Order History'),
  pointsShorthand: getResource('account.pointsShorthand', 'pts.'),
  pointsLabel: getResource('account.pointsTableHeader', 'Points'),
  dateTableHeader: getResource('account.dateTableHeader', 'Date'),
  confirmationTableHeader: getResource('account.confirmationTableHeader', 'Confirmation'),
  ordersEmptyText: getResource('accountBalance.ordersEmptyText', 'You have no order history')
});

export default register('rsv8-account/OrdersTable')(
  withTheme(),
  withResource(mapResource),
  withApiDataProvider(getOrders, orderSelectors, { sort: defaultSort }),
  withContent()
)(OrdersTable);
