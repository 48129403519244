import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { Loader } from 'rsv8-components';
import styled from 'styled-components';
import { register } from 'xcel-react-core';
import { withResource } from 'xcel-react-core';
import { NarrowGrid, SelectCriteria, TeamCriteriaInstructions } from '../../components/index';
import NominationCollapse from '../../components/NominationCollapse/index';
import { teamNominationActions } from '../../redux/actions/index';
import { teamNominationSelectors } from '../../redux/selectors/index';

const { selectCustomFields } = teamNominationActions;

const StyledGrid = styled(NarrowGrid)`
  &.hide {
    display: none;
  }
` as any;

class TeamCriteriaPage extends React.Component<{
  wizard: any;
  buttonTitle: string;
  award: string;
  form: string;
  initialValues: { NominationForm: { criteria: object } };
  selectCustomFields: (v: object) => void;
}> {
  state = { showPage: false };

  handleShowPage = () => {
    this.setState({ showPage: true });
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.showPage && <Loader />}
        <StyledGrid className={!this.state.showPage && 'hide'}>
          <TeamCriteriaInstructions />
          <SelectCriteria type="team" showPage={this.handleShowPage} {...this.props} />
          <NominationCollapse title={this.props.buttonTitle}>
            <PageRenderer path={`partial/nomination/team/criteria`} />
          </NominationCollapse>
        </StyledGrid>
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({
  buttonTitle: getResource('nominationProcess.criteriaDefinitionsbuttonTitle', 'View Criteria Definitions')
});

const mapState = (state) => ({
  form: 'TeamCriteriaForm',
  award: teamNominationSelectors.getCurrentAwardId(state),
  initialValues: {
    NominationForm: teamNominationSelectors.getCurrentCustomFieldMap(state)
  }
});

const mapDispatch = (dispatch) => bindActionCreators({ selectCustomFields }, dispatch);

const compose = register('rsv8-nomination/TeamSelectCriteria');

export default compose(
  withResource(mapResource),
  connect(
    mapState,
    mapDispatch
  )
)(TeamCriteriaPage);
