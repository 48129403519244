import {
  CURRENT_NOMINATION_KEY,
  INDIVIDUAL_NOMINATION_REDUCER_KEY,
  NOMINATION_ACTIVITY_REDUCER_KEY,
  NOMINEE_SEARCH_KEY,
  TEAM_NOMINATION_REDUCER_KEY
  } from '../constants';
import myAwardsReducer from './reducers/myAwards';
import nominationActivityReducer from './reducers/nominationActivity';
import nominationDashboardReducer from './reducers/nominationDashboard';
import individualNominationReducer from './reducers/singleNomination/index';
import teamNominationReducer from './reducers/teamNomination/index';
export { AnyAction } from 'redux';

const reducers = {
  [INDIVIDUAL_NOMINATION_REDUCER_KEY]: individualNominationReducer,
  [TEAM_NOMINATION_REDUCER_KEY]: teamNominationReducer,
  [NOMINATION_ACTIVITY_REDUCER_KEY]: nominationActivityReducer,
  nominationDashboard: nominationDashboardReducer,
  myAwards: myAwardsReducer
};

export { reducers };
export {
  awardSelectors,
  productSelectors,
  individualNominationSelectors,
  nominationActivitySelectors,
  nomineeSearchSelectors,
  nomineeSelectors,
  teamNominationSelectors,
  teamNominationHistorySelectors,
  individualNominationHistorySelectors,
  teamDraftSelectors,
  individualDraftSelectors,
  nominationApprovalSelectors
} from './selectors/index';
export {
  individualNominationActions,
  teamNominationActions,
  teamMemberActions,
  nominationActions,
  nominationApprovalActions,
  nominationActivityActions
} from './actions/index';
export {
  CURRENT_NOMINATION_KEY,
  NOMINEE_SEARCH_KEY,
  INDIVIDUAL_NOMINATION_REDUCER_KEY,
  NOMINATION_ACTIVITY_REDUCER_KEY,
  TEAM_NOMINATION_REDUCER_KEY
};
