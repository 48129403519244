import * as React from 'react';
import { Col } from 'react-bootstrap';
import { register, withResource } from 'xcel-react-core';
import { HeaderOne } from "rsv8-components";
import { PaddedRow, CentredContainer } from "./Elements";

interface InternalProps {
    resource: {
        redeemedBeforeErrorHeader: string;
    };
}

const RedeemedBeforeForm: React.SFC<InternalProps> = (props) => {

    return (
        <React.Fragment>
            <PaddedRow>
                <Col>
                    <CentredContainer>
                        <HeaderOne themeVariation="primary-link-3xl-bold-text">
                            {props.resource.redeemedBeforeErrorHeader}
                        </HeaderOne>
                    </CentredContainer>
                </Col>
            </PaddedRow>

        </React.Fragment>
    );
};

const mapResource = (getResource) => ({
    resource: {
        redeemedBeforeErrorHeader: getResource('nominations.redeemedBeforeErrorHeader', 'Your award already redeemed'),
    }
});

export default register('rsv8-nomination/RedeemedBeforeForm')(
    withResource(mapResource),
)(RedeemedBeforeForm);
