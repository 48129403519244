import { Panel } from 'rsv8-components';
import styled from 'styled-components';

export default styled(Panel)`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
  &.panel {
    margin-bottom: 0px;
  }
  text-align: center;
  padding: 5px;
  .progress {
    margin-bottom: 0px;
  }
` as any;
