import * as events from '../events';

const initialState = {
  awards: []
};

export default function myAwardsReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case events.GET_MY_AWARDS_SUCCESS:
      return {
        ...state,
        awards: [...action.payload.data],
        selectList: [...action.payload.included]
      };
    default:
      return state;
  }
}
