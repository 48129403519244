import * as React from 'react';
import { compose } from 'recompose';
import { Button } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import { reduxForm } from 'rsv8-forms';
import { ensureArray } from 'xcel-util';
import SubmitMessages from './SubmitMessages';
import { ApprovalFormInternalProps, ApprovalFormProps, connectApprovalForm } from './util';

const GROUP_KEY = ['deny'];

class DenialForm extends React.Component<ApprovalFormInternalProps, {}> {
  state = { displayForm: true as boolean };

  componentDidMount() {
    if (this.props.autoSubmit) {
      this.props.handleSubmit(this.submit)();
    }
  }

  removeForm = () => {
    this.setState({ displayForm: false });
    this.props.changeVisibility();
  };
  submit = (values) => {
    return this.props
      .approveNomination({
        model: {
          id: this.props.processId,
          action: 'Deny',
          scheduledDate: undefined,
          sendBackComments: undefined,
          customFields: Object.keys(values.NominationProcess || {}).map((key) => ({
            id: key,
            values: ensureArray(values.NominationProcess[key])
          }))
        }
      })
      .then(() => this.removeForm());
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <SubmitMessages
          themeVariation="denied"
          messages={this.props.resources.deny}
          request={this.props.lastRequest}
          {...this.props}
        />
        {this.state.displayForm && !this.props.autoSubmit && (
          <React.Fragment>
            <CustomFields formType="NominationProcess" groupKey={GROUP_KEY} appKey={this.props.applicationId} />
            <Button type="submit">{this.props.buttons.deny}</Button>
          </React.Fragment>
        )}
      </form>
    );
  }
}
export default compose<ApprovalFormInternalProps, ApprovalFormProps>(
  connectApprovalForm,
  reduxForm({ form: 'DenialForm' })
)(DenialForm);
