import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link, Loader } from 'rsv8-components';
import { injectGlobal } from 'styled-components';
import { register, withContent, withResource } from 'xcel-react-core';
import { HorizontalBarChart } from '../../components/HorizontalBarChart';
import { RibbonSvg } from '../../components/Ribbon';
import { analyticsApiActions } from '../../redux/actions/analytics/analyticsApiActions';
import {
  getBudgetStatus,
  getCompanyStats,
  getGoalInPercentage,
  getPendingApprovalCount,
  getYourCountryPercentage,
  getYourTeamPercentage
  } from '../../redux/selectors/analyticsSelectors';
import {
  ActivityHeader,
  ApprovalContainer,
  BudgetContainer,
  ChartContainer,
  Container,
  Dashboard,
  ErrorText,
  PendingApprovals,
  PendingText,
  TargetText
  } from './styles';

injectGlobal`
    .defined-loader-padding {
      padding: 10px
    }
  `;

interface Props {
  actions: {
    getData: any;
  };
  resources: {
    trendingBudgetLabel: string;
    pendingApprovalsLabel: string;
    noPendingApprovalsLabel: string;
    hasPendingApprovalsLabel: string;
    detailsLink: string;
    teamLabel: string;
    linkText: string;
    countryLabel: string;
    companyGoalLabel: string;
    companyStatsLabel: string;
    budgetVeryLowText: string;
    budgetOnTrackText: string;
    budgetHighText: string;
    budgetLowText: string;
    chartHeading: string;
    errorText: string;
  };
  pendingApprovalCount: any;
  budgetStatus: any;
  yourTeamPercentage: number;
  yourCountryPercentage: number;
  companyStats: number;
  goalInPercentage: number;
}

const HomepageDashboard: React.FunctionComponent<Props> = (props) => {
  React.useEffect(() => {
    props.actions.getData({ ids: ['nomination', 'user'], startDate: '01-01-2019' });
  }, []);

  const budgetStatusText = () => {
    if (!props.budgetStatus) return false;
    switch (props.budgetStatus.budgetStatusResourceKey) {
      case 'BudgetVeryLow':
        return props.resources.budgetVeryLowText;
      case 'BudgetLow':
        return props.resources.budgetLowText;
      case 'BudgetOnTrack':
        return props.resources.budgetOnTrackText;
      case 'BudgetHigh':
        return props.resources.budgetHighText;
      default:
        return false;
    }
  };

  return (
    <Container>
      <Dashboard>
        <BudgetContainer>
          <div>
            <ActivityHeader>{props.resources.trendingBudgetLabel}</ActivityHeader>
            {props.budgetStatus !== undefined && props.budgetStatus !== null ? (
              <div>
                {props.budgetStatus.budgetStatusResourceKey ? (
                  <React.Fragment>
                    <TargetText>{budgetStatusText()}</TargetText>
                    &emsp;&emsp;&emsp;
                    <TargetText>{props.budgetStatus && props.budgetStatus.totalBudgetPercentage}%</TargetText>
                  </React.Fragment>
                ) : (
                  <ErrorText>{props.resources.errorText}</ErrorText>
                )}
              </div>
            ) : (
              <Loader className="defined-loader-padding" width={20} strokeWidth={2} />
            )}
          </div>
        </BudgetContainer>
        <ApprovalContainer>
          <div>
            <ActivityHeader>{props.resources.pendingApprovalsLabel}</ActivityHeader>
            {props.pendingApprovalCount !== null ? (
              <PendingApprovals>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <RibbonSvg />

                  <TargetText style={{ margin: '0 5px' }}>{props.pendingApprovalCount}</TargetText>
                </div>
                <PendingText>
                  {props.pendingApprovalCount === 0
                    ? props.resources.noPendingApprovalsLabel
                    : props.resources.hasPendingApprovalsLabel}
                </PendingText>
              </PendingApprovals>
            ) : (
              <Loader className="defined-loader-padding" width={20} strokeWidth={2} />
            )}
          </div>
        </ApprovalContainer>
        <ChartContainer>
          <div style={{ width: '100%' }}>
            <ActivityHeader>{props.resources.chartHeading}</ActivityHeader>
            {props.yourCountryPercentage !== null &&
            props.yourTeamPercentage !== null &&
            props.companyStats !== null &&
            props.goalInPercentage !== null ? (
              <div style={{ marginTop: '10px' }}>
                <HorizontalBarChart
                  id="HBZ1"
                  chartData={[
                    {
                      label: props.resources.teamLabel,
                      value: props.yourTeamPercentage
                    },
                    {
                      label: props.resources.countryLabel,
                      value: props.yourCountryPercentage
                    },
                    {
                      label: props.resources.companyStatsLabel,
                      value: props.companyStats
                    },
                    {
                      label: props.resources.companyGoalLabel,
                      value: props.goalInPercentage
                    }
                  ]}
                />
              </div>
            ) : (
              <Loader className="defined-loader-padding" width={20} strokeWidth={2} />
            )}
          </div>
        </ChartContainer>
      </Dashboard>
      <Link to={props.resources.detailsLink} style={{ textAlign: 'center' }}>
        {props.resources.linkText}
      </Link>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    budgetStatus: getBudgetStatus(state),
    goalInPercentage: getGoalInPercentage(state), // company goals
    pendingApprovalCount: getPendingApprovalCount(state),
    yourTeamPercentage: getYourTeamPercentage(state),
    yourCountryPercentage: getYourCountryPercentage(state),
    companyStats: getCompanyStats(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      getData: analyticsApiActions.getData
    },
    dispatch
  )
});

const mapResourceToProps = (getResource) => ({
  resources: {
    chartHeading: getResource('managersDashboard.analytics.chartHeading', 'Employees awarded KPI'),
    trendingBudgetLabel: getResource('managersDashboard.analytics.trendingBudgetLabel', 'Your 2019 budget is trending'),
    pendingApprovalsLabel: getResource('managersDashboard.analytics.pendingApprovalsLabel', 'Pending Approvals'),
    noPendingApprovalsLabel: getResource(
      'managersDashboard.analytics.noPendingApprovalsLabel',
      'Congratulations! You’re all caught up on your team’s nominations.'
    ),
    hasPendingApprovalsLabel: getResource(
      'managersDashboard.analytics.hasPendingApprovalsLabel',
      'You have new nominations to review'
    ),
    detailsLink: getResource(
      'managersDashboard.analytics.detailsLink',
      'http://philips.rewardstation-test.net/sso/oauthentry.aspx?target=reports/default.aspx&amp;auth='
    ),
    linkText: getResource('managersDashboard.analytics.linkText', 'See More Details'),
    teamLabel: getResource('managersDashboard.analytics.teamLabel', 'Your Team'),
    countryLabel: getResource('managersDashboard.analytics.countryLabel', 'Your Country'),
    companyStatsLabel: getResource('managersDashboard.analytics.companyStatsLabel', 'Company Stats'),
    companyGoalLabel: getResource('managersDashboard.analytics.companyGoalLabel', 'Company Goals'),
    budgetVeryLowText: getResource('managersDashboard.analytics.BudgetVeryLowText', 'Very Low'),
    budgetLowText: getResource('managersDashboard.analytics.BudgetLowText', 'Low'),
    budgetOnTrackText: getResource('managersDashboard.analytics.BudgetOnTrackText', 'On Target'),
    budgetHighText: getResource('managersDashboard.analytics.BudgetHighText', 'High'),
    errorText: getResource(
      'managersDashboard.analytics.errorText',
      'There is no data available to report at this time.'
    )
  }
});

export default register('rsv8-data-vis/HomepageDashboard')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(HomepageDashboard);
