import * as React from 'react';
import {
  Col,
  Grid,
  Modal,
  Row
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { SelectAssetModal } from 'rsv8-assets-admin';
import { Tab, Tabs } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { registry } from 'xcel-react-core';
import { getReduxStore } from 'xcel-redux-module';
import { getValue, setValue } from 'xcel-util';
import { componentActions } from '../../../../../redux/actions';
import { contentSelector, contentTypeSelector, socialSelector } from '../../../../../redux/selectors';
import { RouteModel } from '../../../../../redux/types/route';
import ArrayField from './components/ArrayField';
import ThemeVariationSelect from './components/ThemeVariationSelect';

const Property = styled.div``;
const TabTitle = styled.span`
  display: inline-block;
  font-weight: 700;
  font-size: 26px;
`;
const StyledTextArea: any = styled.textarea`
  height: ${(props: any) => (props.height ? `${props.height}!important` : '')};
`;

const StyledReactField: any = styled(ReactField)`
  color: orange;
  font-weight: bold;
`;
const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .modal-lg {
    width: 1000px
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;
  font-size: 12px;
  .form-control {
    font-size: 12px;
  }
`;

class ComponentSettingsModal extends React.Component<{
  onHide: Function;
  onComponentChanged?: any;
  show?: boolean;
  route?: RouteModel;
  contentTypeId?: string;
  edit: any;
  dispatch?: any;
  onCreate?: any;
  component: any;
}> {
  state = {
    content: undefined,
    model: undefined,
    mouseDownPosition: undefined,
    ignoreBackdropClick: false
  };
  static getDerivedStateFromProps(props: any, state: any) {
    const { edit, component } = props;
    const config = component && component.data;
    const model = config && config.model;
    const content = edit && model && getValue(edit.content, model) && getValue(edit.content, model).content;

    if (!props.show) return { model: props.model, content: {} };

    if (content !== state.content) return { model: props.model, content: { ...content, ...state.content } };

    return state;
  }

  static getEdit(state: any, contentTypeId: any) {
    if (contentTypeId === 'Activity') {
      return socialSelector.currentVersion(state);
    }
    return contentSelector.getEdit(state, contentTypeId);
  }

  handleChange = (property) => (e) => {
    this.handleComponentChange(property, e.target ? e.target.value : e);
  };
  handleBlur = (property) => (e) => {
    this.handleComponentChange(property, e.target ? e.target.value : e);
  };
  handleComponentChange = (property, value) => {
    const { component, dispatch, onComponentChanged, contentTypeId } = this.props;
    const model = component.data.model;
    const content = setValue({ ...this.state.content }, property, value);
    this.setState({ content });
    dispatch(componentActions.propertySet({ model, property, value }));
    if (onComponentChanged) {
      const store = getReduxStore();
      const state = store.getState();
      const edit = ComponentSettingsModal.getEdit(state, contentTypeId);
      onComponentChanged(edit.content);
    }

  };
  handleMouseUp = e => {
    const clientX = e.clientX;
    const clientY = e.clientY;
    const ignoreBackdropClick = this.state.mouseDownPosition &&
      this.state.mouseDownPosition.clientX !== clientX &&
      this.state.mouseDownPosition.clientY !== clientY;

    this.setState({ ignoreBackdropClick: ignoreBackdropClick, mouseDownPosition: undefined });
  };
  handleMouseDown = e => {
    const clientX = e.clientX;
    const clientY = e.clientY;
    this.setState({ mouseDownPosition: { clientX, clientY } });
  };
  handleHide = (e) => {
    const { onHide } = this.props;
    if (!this.state.ignoreBackdropClick && !(!!this.state.mouseDownPosition)) {
      onHide();
    }
  };
  handleAdd = (property, value) => (e) => {
    const { component, dispatch } = this.props;
    const model = component.data.model;
    const content = value ? [...value, {}] : [{}];

    dispatch(componentActions.propertySet({ model, property, value: value ? [...value, {}] : [{}] }));
    this.setState({ content });
  };
  handleDelete = (property, value, index) => (e) => {
    const { component, dispatch } = this.props;
    const model = component.data.model;
    value.splice(index, 1);
    const content = [...value];
    dispatch(componentActions.propertySet({ model, property, value: content }));
    this.setState({ content });
  };
  getName = () => {
    const { component } = this.props;
    const config = component && component.data;
    return config && (config.name || config.namespace);
  };
  getFields = () => {
    const components = registry.get().content;
    const name = this.getName();
    return components[name] && components[name].fields;
  };
  getStyles = () => {
    const components = registry.get().content;
    const name = this.getName();
    return components[name] && components[name].styles;
  };
  getModel = () => {
    const { component } = this.props;
    const config = component && component.data;
    return config && config.model;
  };
  renderField = (fields) => {
    const model = this.getModel();
    const content = this.state.content;
    return (
      fields &&
      Object.keys(fields).map((key, index) => {
        const field = fields[key] || {};
        let component = (
          <StyledTextArea
            className="form-control"
            placeholder={`Enter value for ${key}`}
            onChange={this.handleChange(key)}
            onBlur={this.handleBlur(key)}
            value={content[key]}
            height={field.height}
          />
        );
        switch (field.type) {
          case 'array':
            component = (
              <ArrayField
                field={field}
                model={key}
                className="form-control"
                placeholder={`Enter value for ${key}`}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onDelete={this.handleDelete}
                content={content}
                value={content[key]}
              />
            );
            break;
          case 'select':
            component = (
              <StyledReactField
                component={'bs-select'}
                options={field.options}
                placeholder={`Enter value for ${key}`}
                onChange={this.handleBlur(key)}
                value={content[key]}
              />
            );
            break;
          case 'input':
            component = (
              <StyledReactField
                className="form-control"
                component={'bs-input'}
                placeholder={`Enter value for ${key}`}
                onChange={this.handleBlur(key)}
                onBlur={this.handleBlur(key)}
                value={content[key]}
              />
            );
            break;
          case 'checkbox':
            component = (
              <ReactField
                field={field}
                model={key}
                className="form-control"
                component={'checkbox'}
                label={key}
                onChange={this.handleBlur(key)}
                value={content[key]}
              />
            );
            break;
          case 'image':
            component = <SelectAssetModal currentDesktopUrl={content[key]} onChange={this.handleBlur(key)} />;
            break;
          default:
            break;
        }
        const smallWidth = field.type !== 'array' && field.width !== 'full' ? 12 : 24;
        const mediumWidth = field.type !== 'array' && field.width !== 'full' ? 6 : 24;
        return (
          <Col key={model + key} sm={smallWidth} md={mediumWidth}>
            <Property className="form-group">
              <label>
                <strong>{field.label || key}</strong>
                &nbsp;&nbsp;&nbsp;
                {field.type === 'array' && (
                  <button className="btn btn-primary" onClick={this.handleAdd(key, content[key])}>
                    add
                  </button>
                )}
              </label>
              {component}
            </Property>
          </Col>
        );
      })
    );
  };

  render() {
    const content = this.state.content;
    const { show } = this.props;
    return (
      <StyledModal show={show} onHide={this.handleHide} onMouseUp={this.handleMouseUp} onMouseDown={this.handleMouseDown} bsSize="large">
        <Modal.Header closeButton={true}>
          <Modal.Title>Edit Component Settings</Modal.Title>
        </Modal.Header>

        <ModalBody>
          <Grid fluid={true}>
            <Row>
              <Tabs defaultActiveKey={0}>
                <Tab eventKey={0} title={<TabTitle>Component</TabTitle>}>
                  <Row>
                    <Col md={24}>
                      <ThemeVariationSelect
                        onChange={this.handleBlur('themeVariation')}
                        content={content}
                        name={this.getName()}
                      />
                    </Col>

                    {this.renderField(this.getFields())}
                  </Row>
                </Tab>
                <Tab eventKey={1} title={<TabTitle>Style</TabTitle>}>
                  <Row>{this.renderField(this.getStyles())}</Row>
                </Tab>
              </Tabs>
            </Row>
          </Grid>
        </ModalBody>
      </StyledModal>
    );
  }
}

const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  const edit = ComponentSettingsModal.getEdit(state, contentTypeId);

  return {
    contentTypeId,
    edit
  };
};
export default connect(mapStateToProps)(ComponentSettingsModal as any) as any;
