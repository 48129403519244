import * as React from 'react';
import NomineeRadio from './NomineeRadio';
import { NomineeLoader } from './styles';

export interface NomineeStatusIconProps {
  active: boolean;
  loading: boolean;
}

export const NomineeStatusIcon: React.SFC<NomineeStatusIconProps> = ({ active, loading }) =>
  !loading ? <NomineeRadio checked={active} /> : <NomineeLoader width={18}/>;
