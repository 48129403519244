import * as events from '../../events';

const initialState = {
  currentFavorites: [],
  favoritesLoaded: false
};

const initialUndoState = {
  undoFavoritesList: []
};

const favoritesReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case events.SET_FAVORITES:
      return {
        ...state,
        currentFavorites: payload.products.map((result) => result.id)
      };
    case events.FAVORITE_PRODUCTS_LOADED:
      return {
        ...state,
        favoritesLoaded: payload
      };
    default:
      return {
        ...state
      };
  }
};

const favoriteUndoReducer = (state: any = initialUndoState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case events.ADD_UNDO_FAVORITE:
      return {
        ...state,
        undoFavoritesList: [...state.undoFavoritesList, payload]
      };
    case events.REMOVE_UNDO_FAVORITE:
      return {
        ...state,
        undoFavoritesList: [...state.undoFavoritesList.filter((product) => product !== payload)]
      };
    case events.RESET_UNDO_FAVORITE:
      return {
        ...state,
        undoFavoritesList: []
      };
    default:
      return {
        ...state
      };
  }
};

export { favoritesReducer, favoriteUndoReducer };
