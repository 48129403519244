import { destroy, destroyRequest } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import { getAccountSummary } from '../../actions/account/getSummary';
import { cartApiActions } from './cartApiActions';

const patchCartItemOption = (optionId: string, productId: number) => async (dispatch, getState) => {
  const response = await dispatch(
    cartApiActions.patchCartById(
      {
        id: productId,
        body: convertToPatchOperations({ productOptionId: optionId })
      },
      { jsonApi: true }
    )
  );

  await dispatch(destroyRequest('getCart'));
  dispatch(destroyRequest('getSummary'));
  dispatch(getAccountSummary());
  dispatch(cartApiActions.getCart({ jsonApi: true }));

  return response;
};

const patchCartItemQuantity = (newQuantity: string, productId: number) => async (dispatch, getState) => {
  try {
    await dispatch(destroyRequest('getCart'));
    const response = await dispatch(
      cartApiActions.patchCartById(
        {
          id: productId,
          body: convertToPatchOperations({ quantity: parseInt(newQuantity, 10) })
        },
        { jsonApi: true }
      )
    );
    dispatch(destroyRequest('getSummary'));
    dispatch(getAccountSummary());
    dispatch(cartApiActions.getCart({ jsonApi: true }));

    return response;
  } catch (e) {
    if (e.response && e.response.status) {
      dispatch(destroy('cartItem', productId.toString()));
      dispatch(destroyRequest('getSummary'));
      dispatch(getAccountSummary());
      dispatch(cartApiActions.getCart({ jsonApi: true }));
    }
    throw e;
  }
};

export { patchCartItemOption, patchCartItemQuantity };
