import * as React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { PointsLongHand, PointsShortHand } from 'rsv8-account';
// import { CartIconWithCount } from 'rsv8-catalog';
import { CultureDropdown } from 'rsv8-client';
import { Image, Link } from 'rsv8-components';
import {
  HamburgerMenu,
  HamburgerMenuNavigation,
  Logo,
  ModularMenu
  } from 'rsv8-navigation';
import styled from 'styled-components';
import { register, withResource, withTheme } from 'xcel-react-core';
import PrimaryMenuContainer from './PrimaryMenuContainer';

const ColStyled = styled.div`
  .header-left,
  .header-right {
    vertical-align: middle;
  }
`;

const CultureCol = styled(Col)`
  margin: 0 50px;
`;

const LogoCol = styled(Col)`
  flex-grow: 1;
`;

const HeaderCultureSelect = styled.select`
  border: 0;
  background: transparent;
  -moz-appearance: none;
  position: relative;
  z-index: 99;
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff;
  ::-ms-expand {
    border: none;
    background: transparent;
  }
  option {
    color: #000;
  }
`;
const StyledBurgerIconCol = styled(Col)`
  position: static;
`;
const StyledPointsShortHand = PointsShortHand
  ? styled(PointsShortHand)`
      display: inline-block;
      float: right;
      margin-top: 17px;
    `
  : null;

const HeaderCultureDropdown = (props) => {
  return (
    props.options.length > 1 && (
      <React.Fragment>
        <Image themeVariation="icon-globe-header" />
        <HeaderCultureSelect {...props} {...props.input} className="wrapper">
          {props.options.map(({ value, label }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </HeaderCultureSelect>
      </React.Fragment>
    )
  );
};

const PrimaryMenu = (props) => (
  <PrimaryMenuContainer className={props.className} ref={props.primaryMenuContainerRef}>
    <Grid fluid={false}>
      <Row>
        <StyledBurgerIconCol sm={2} xs={3} lgHidden={true} mdHidden={true} smHidden={true}>
          <HamburgerMenu />
        </StyledBurgerIconCol>
        <Col sm={12} xs={11} lgHidden={true} mdHidden={true} smHidden={true}>
          <Logo uri={props.menuLogoURI} />
        </Col>
        <Col sm={10} xs={10} lgHidden={true} mdHidden={true} smHidden={true}>
          { /*<CartIconWithCount /> */}
          <Link to="/account/information">
            <StyledPointsShortHand themeVariation="primary" />
          </Link>
        </Col>
      </Row>
      <ColStyled>
        <Row>
          <LogoCol xsHidden={true}>
            <Logo uri={props.menuLogoURI} />
          </LogoCol>
          <CultureCol className="culture-column" xsHidden={true}>
            <CultureDropdown component={HeaderCultureDropdown} />
          </CultureCol>
          <Col xsHidden={true}>
            <ModularMenu appKey="HeaderMenu" template="HeaderNavigationWithNotifications" />
          </Col>
        </Row>
      </ColStyled>
    </Grid>
    <HamburgerMenuNavigation className="sm-hidden md-hidden lg-hidden">
      <HamburgerMenu />
      <PointsLongHand variationName="mobile-menu" />
    </HamburgerMenuNavigation>
  </PrimaryMenuContainer>
);

const mapResource = (getResource) => ({
  menuLogoURI: getResource('primaryMenu.menuLogoURI', '/clientassets/images/logos/rewardstation-logo_white.svg')
});

export default register('rsv8-app/PrimaryMenu')(
  withResource(mapResource),
  withTheme(['.navbar .nav li', '.navbar .nav li .dropdown-menu li'])
)(PrimaryMenu);
