import { values } from 'ramda';
import * as events from '../events';
import wizardReducer from './wizard';

const masterReducer = (state = {}, action) => {
  const { type, payload } = action;
  if (values(events).indexOf(type) !== -1) {
    if (!payload.name) {
      throw 'You sent in a Wizard related action without a name.';
    }
    return { ...state, [payload.name]: wizardReducer(state[payload.name], action) };
  }
  return state;
};

export default masterReducer;
