import * as React from 'react';
import { Checkbox } from 'react-bootstrap';
import styled from 'styled-components';

const StyledCheckboxBase = styled(Checkbox)`
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox'] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    cursor: pointer;
    &:after {
      border-width: 0;
    }
  }

  input[type='checkbox']:checked + span {
    &:after {
      transform: rotate(45deg);
      position: absolute;
      left: 7px;
      top: 3px;
      display: table;
      width: 5px;
      height: 10px;
      border-width: 1px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      content: ' ';
      animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      animation-duration: 0.3s;
      animation-name: amCheckboxOut;
    }
  }

  input[type='checkbox'] + span {
    margin-right: 10px;
  }

  & label {
    padding-left: 0px !important;
  }
`;

export const StyledCheckbox = ({ children, ...rest }) => (
  <StyledCheckboxBase {...rest}>
    <span className="show-password-checkbox" />
    {children}
  </StyledCheckboxBase>
);
