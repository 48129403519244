import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CultureDropdown } from 'rsv8-client';
import { CountryDropdown } from 'rsv8-client';
import { Field, reduxForm } from 'rsv8-forms';
import { getMenuadmin } from '../../redux/navigationMenuAdmin.actions';

const SortForm = (props) => {
  const { handleSubmit, appKey, menuType } = props;

  const filterMenus = (values) => {
    props && props.actions.getMenuadmin(props.clientId, values);
  };

  return (
    <form onSubmit={handleSubmit(filterMenus)}>
      <Row>
        <Col xs={24}>
          <h3>Filter Menus</h3>
        </Col>
        <Col xs={3}>
          <div className="form-group">
            <label>Application</label>
            <div>
              <Field name="appKey" component="select" className="form-control">
                {appKey.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.id}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div className="form-group">
            <label>Culture</label>
            <div>
              <Field
                name="cultureId"
                component={({ input }) => {
                  return <CultureDropdown property="id" allowCultureChange={false} {...input} />;
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div className="form-group">
            <label>Country</label>
            <div>
              <Field
                name="countryCode"
                component={({ input }) => {
                  return <CountryDropdown property="id" {...input} />;
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div className="form-group">
            <label>Menu Type</label>
            <div>
              <Field name="menuType" component="select" className="form-control">
                {menuType.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.key}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        </Col>

        <Col xs={3}>
          <button type="submit" className="btn btn-sm btn-primary" style={{ marginTop: '27px' }}>
            Submit
          </button>
        </Col>
      </Row>
      <hr />
    </form>
  );
};

const mapStateToProps = (state) => {
  let menuType = state.navigationMenuAdmin.lookup.menuType;
  let appKey = state.navigationMenuAdmin.application;

  return {
    menuType,
    appKey
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      getMenuadmin
    },
    dispatch
  )
});

const wrappedSomething = connect(
  mapStateToProps,
  mapDispatch
)(SortForm);

export default reduxForm({ form: 'SortForm' })(wrappedSomething as any) as any;
