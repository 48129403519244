import * as React from 'react';

const Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox={props.viewBox}>
      <path d={props.icon} />
    </svg>
  );
};

export default Icon;
