import * as React from 'react';

// TODO: Replace hardcoded fill color.
const SupportIcon = (props) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <title>search</title>
    <path
      className="cls-1"
      fill="#ffffff"
      d="M6.5,12.5A6.16,6.16,0,0,0,7,15,6.73,6.73,0,0,0,8.41,17.1a6.65,6.65,0,0,0,2.06,1.4,6.45,6.45,0,0,0,7.12-1.4A6.75,6.75,0,0,0,19,15,6.41,6.41,0,0,0,19,10a6.71,6.71,0,0,0-1.4-2.06,6.59,6.59,0,0,0-2.06-1.4,6.53,6.53,0,0,0-5.06,0,6.83,6.83,0,0,0-2.06,1.4A6.68,6.68,0,0,0,7,10,6.16,6.16,0,0,0,6.5,12.5ZM24.37,24.16a.52.52,0,0,1,.13.36.49.49,0,0,1-.17.34.27.27,0,0,1-.15.11A.52.52,0,0,1,24,25a.48.48,0,0,1-.2,0,.48.48,0,0,1-.17-.12l-5.94-6.48a7.54,7.54,0,0,1-10-.55,7.65,7.65,0,0,1-1.62-2.44A7.48,7.48,0,0,1,7.69,7.21a7.49,7.49,0,0,1,8.18-1.64,7.28,7.28,0,0,1,2.45,1.64,7.46,7.46,0,0,1,1.62,2.42,7.65,7.65,0,0,1,0,5.67,7.33,7.33,0,0,1-1.54,2.38Z"
    />
    <path
      fill="#ffffff"
      d="M6.5,12.5A6.16,6.16,0,0,0,7,15,6.73,6.73,0,0,0,8.41,17.1a6.65,6.65,0,0,0,2.06,1.4,6.45,6.45,0,0,0,7.12-1.4A6.75,6.75,0,0,0,19,15,6.41,6.41,0,0,0,19,10a6.71,6.71,0,0,0-1.4-2.06,6.59,6.59,0,0,0-2.06-1.4,6.53,6.53,0,0,0-5.06,0,6.83,6.83,0,0,0-2.06,1.4A6.68,6.68,0,0,0,7,10,6.16,6.16,0,0,0,6.5,12.5ZM24.37,24.16a.52.52,0,0,1,.13.36.49.49,0,0,1-.17.34.27.27,0,0,1-.15.11A.52.52,0,0,1,24,25a.48.48,0,0,1-.2,0,.48.48,0,0,1-.17-.12l-5.94-6.48a7.54,7.54,0,0,1-10-.55,7.65,7.65,0,0,1-1.62-2.44A7.48,7.48,0,0,1,7.69,7.21a7.49,7.49,0,0,1,8.18-1.64,7.28,7.28,0,0,1,2.45,1.64,7.46,7.46,0,0,1,1.62,2.42,7.65,7.65,0,0,1,0,5.67,7.33,7.33,0,0,1-1.54,2.38Z"
    />
  </svg>
);

export default SupportIcon;
