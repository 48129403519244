import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { HeaderDecoration, HeaderOne, Image } from 'rsv8-components';
import { SubmissionError } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';
import { userStateSelectors } from '../../../module';
import { userPasswordReset } from '../../redux/user.actions';
import ResetPasswordForm from './ResetPasswordForm';

const hasNotUpperCase = (text) => text.search(/[A-Z]/) < 0;
const hasNotLowerCase = (text) => text.search(/[a-z]/) < 0;
const incorrectLength = (text) => text.length < 8 || text.length > 20;
const hasNotNumber = (text) => text.search(/[0-9]/) < 0;

interface ResetPasswordProps {
  location: any;
  expiredRedirectPath: string;
  actions: any;
  successRedirectPath: string;
  failureRedirectPath: string;
  forgotPassword: any;
  resource: {
    errorMessageLengthText: any;
    errorMessageNumberText: any;
    errorMessageUpperCaseLetterText: any;
    errorMessageLowerCaseLetterText: any;
    errorMessageNotEqualText: any;
    resetPasswordHeaderText: any;
  };
}

class ResetPassword extends React.Component<ResetPasswordProps, any> {
  componentDidMount() {
    // checks if the access key has been validated previously
    // just in case when the user hard code the URL to reset the password
    // `forgotPassword` is the result of a successful validation
    const { forgotPassword, expiredRedirectPath, actions } = this.props;

    if (forgotPassword === undefined) {
      // go to the invalid-token page when the validation has failed
      actions.push(expiredRedirectPath);
    }
  }
  handleSubmit = (params, dispatch) => {
    const { password, passwordConfirmation } = params;
    const {
      actions,
      successRedirectPath,
      failureRedirectPath,
      expiredRedirectPath,
      forgotPassword,
      resource: {
        errorMessageLengthText,
        errorMessageNumberText,
        errorMessageUpperCaseLetterText,
        errorMessageLowerCaseLetterText,
        errorMessageNotEqualText
      }
    } = this.props;
    let _errors: any = [];
    if (!password || !passwordConfirmation || incorrectLength(password) || incorrectLength(passwordConfirmation)) {
      _errors.push(errorMessageLengthText);
    }
    if (!password || !passwordConfirmation || hasNotUpperCase(password) || hasNotUpperCase(passwordConfirmation)) {
      _errors.push(errorMessageUpperCaseLetterText);
    }
    if (!password || !passwordConfirmation || hasNotLowerCase(password) || hasNotLowerCase(passwordConfirmation)) {
      _errors.push(errorMessageLowerCaseLetterText);
    }
    if (!password || !passwordConfirmation || hasNotNumber(password) || hasNotNumber(passwordConfirmation)) {
      _errors.push(errorMessageNumberText);
    }
    if (password && passwordConfirmation && password !== passwordConfirmation) {
      _errors.push(errorMessageNotEqualText);
    }
    if (_errors.length > 0) {
      throw new SubmissionError({
        password: '',
        passwordConfirmation: '',
        _error: _errors
      });
    }
    actions
      .userPasswordReset(
        {
          accessKey: forgotPassword.data.attributes.key,
          userName: forgotPassword.data.attributes.userName,
          newPassword: password
        },
        {
          validatedPath: successRedirectPath,
          invalidatedPath: failureRedirectPath
        }
      )
      .catch((e) => {
        dispatch(push(expiredRedirectPath));
      });
  };
  render() {
    const {
      resource: { resetPasswordHeaderText }
    } = this.props;
    return (
      <div>
        <HeaderDecoration />
        <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
          <Image themeVariation="primary-company-logo1" />
        </Col>
        <LayoutContainer>
          <Row>
            <Col xs={22} xsOffset={1} sm={18} smOffset={3} md={12} mdOffset={6} lg={8} lgOffset={8}>
              <Image themeVariation="icon-reset-password" />
            </Col>
          </Row>
          <Row>
            <Col xs={22} xsOffset={1} sm={18} smOffset={3} md={12} mdOffset={6} lg={8} lgOffset={8}>
              <HeaderOne themeVariation="center">{resetPasswordHeaderText}</HeaderOne>
            </Col>
          </Row>
          <Row>
            <Col xs={22} xsOffset={1} sm={18} smOffset={3} md={12} mdOffset={6} lg={8} lgOffset={8}>
              <ResetPasswordForm onSubmit={this.handleSubmit} />
            </Col>
          </Row>
        </LayoutContainer>
      </div>
    );
  }
}
const mapState = (state) => ({
  user: userStateSelectors.getUser(state),
  forgotPassword: userStateSelectors.getUser(state).forgotPassword
});

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ userPasswordReset, push }, dispatch)
});

const mapResourceToProps = (getResource) => ({
  resource: {
    resetPasswordHeaderText: getResource('resetPassword.resetPasswordHeaderText', 'Reset your password'),
    errorMessageLengthText: getResource('resetPassword.errorMessageLengthText', 'Must be between 8 and 20 characters'),
    errorMessageNumberText: getResource('resetPassword.errorMessageNumberText', 'At least one number'),
    errorMessageUpperCaseLetterText: getResource(
      'resetPassword.errorMessageUpperCaseLetterText',
      'At least one uppercase letter'
    ),
    errorMessageLowerCaseLetterText: getResource(
      'resetPassword.errorMessageLowerCaseLetterText',
      'At least one lowercase letter'
    ),
    errorMessageNotEqualText: getResource('resetPassword.errorMessageNotEqualText', 'Password not match')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(ResetPassword as any) as any;
