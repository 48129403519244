import favoritesModule from './favoritesModule';
export { FavoritesOverview } from './app/views/FavoritesOverview';
// export { NominationApprovals } from './app/views/NominationApprovals';
// export { CoreBehaviors } from './app/views/CoreBehaviors';
// export { HomepageDashboard } from './app/views/HomepageDashboard';
// export { ManagerDashboard } from './app/views/ManagerDashboard';
// export { KeyPerformanceIndicators } from './app/views/KeyPerformanceIndicators';
// export { NominationActivity } from './app/views/NominationActivity';
// export { NominationSubmissions } from './app/views/NominationSubmissions';
export { favoritesModule };
