import { Col } from 'react-bootstrap';
import { Button, HeaderTwo, Link } from 'rsv8-components';
import styled from 'styled-components';

export const OrderContainer = styled.div`
  border-radius: 4px;
  border: solid 1px #ededed;
  margin: 30px 0;
` as any;

export const OrderTopBar = styled.div`
  background: #ededed;
  display: flex;
  padding: 13px 25px;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
` as any;

export const OrderPlaced = styled.div`
  @media (min-width: 769px) {
    margin-right: 50px;
  }
` as any;

export const OrderPointTotal = styled.div`
  @media (min-width: 769px) {
    margin-left: 50px;
  }
` as any;

export const OrderConfirmation = styled.div`
  @media (min-width: 769px) {
    margin-left: auto;
  }
` as any;

export const TopBarHeading = styled.div`
  color: #4c4f4f;
  font-size: 12px;
` as any;

export const TopBarContent = styled.div`
  color: #4c4f4f;
  font-weight: bold;
` as any;

export const TopBarConfirmation = styled(TopBarContent)`
  float: right;
` as any;

export const OrderProduct = styled.div`
  align-items: center;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 50px 25px;

  @media (max-width: 768px) {
    align-items: stretch;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 30px;
  }
` as any;

export const ProductInfo = styled.div`
  display: flex;
  flex-basis: 35%;
  margin-right: 15px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
` as any;

export const ProductImage = styled.div`
  align-self: center;
  background-size: cover;
  background-image: url(${(props: any) => props.imageurl});
  background-position: center center;
  background-repeat: no-repeat;
  height: 76px;
  width: 76px;
` as any;

export const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  width: 100%;
` as any;

export const BrandLink = styled(Link)`
  color: #4192a5;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    outline: none;
    text-decoration: none;
  }
` as any;

export const ProductName = styled(Link)`
  color: #4c4f4f;

  &:active,
  &:focus,
  &:hover {
    color: #4c4f4f;
    outline: none;
    text-decoration: none;
  }
` as any;

export const OrderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    margin: 5px 0;
  }
` as any;

export const ContentTitle = styled.div`
  color: #7c8180;
  font-size: 12px;
  white-space: nowrap;

  @media (max-width: 768px) {
    color: #606060;
    font-size: 14px;
    font-weight: bold;
    width: 40%;
  }
` as any;

export const ContentOption = styled.div`
  /* flex-basis: 15%; */
` as any;

export const ContentQuantity = styled.div`
  text-align: center;
` as any;

export const OrderHeading = styled(HeaderTwo)`
  color: #4c4f4f;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
` as any;

export const StatusButton = styled(Button)`
  background: #fff;
  border: 0;
  color: #4192a5;
  font-size: 14px;
  padding: 0;
  text-align: left;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    outline: none;
    text-decoration: none;
  }
` as any;

export const OrderInfoTable = styled.div`
  display: flex;
  flex-basis: 65%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
` as any;

export const BuyAgainButton = styled(Button)`
  background-color: #b5be35;
  border: 0;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px;
  text-align: center;
  transition: background-color 0.2s linear;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 15px;
  }

  &[disabled] {
    color: #9b9b9b !important;
    background-color: #e6e6e6 !important;
    border: 1px solid #ffffff !important;
    cursor: auto !important;
    &:hover {
      color: #9b9b9b !important;
      background-color: #e6e6e6 !important;
      border: 1px solid #ffffff !important;
      cursor: auto !important;
    }
  }
  &:active,
  &:focus,
  &:hover,
  &:active:focus {
    background-color: #a2aa2f;
    color: #fff;
    outline: none;
    text-decoration: none;
  }
` as any;

export const HelpCol = styled(Col)`
  text-align: center;
  @media (min-width: 769px) {
    text-align: right;
  }
` as any;

export const HelpLink = styled(Link)`
  @media (max-width: 768px) {
    width: 100%;
  }
` as any;
