import * as React from 'react';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

export interface EmptyContainerProps {
  children: any;
}

const EmptyStateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 315px;
  justify-content: center;
  padding: 20px;
  box-sizing: content-box;
  overflow: hidden;
  border: ${(props: any) => (props.noborder ? '0' : '1px solid #dddddd')};
` as any;

const EmptyContainer: React.SFC<EmptyContainerProps> = ({ children, ...rest }) => (
  <EmptyStateContainer {...rest}>{children}</EmptyStateContainer>
);

export default register('rsv8-catalog/EmptyContainer')(withTheme())(EmptyContainer);
