import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const ArrayRow = styled.div`
  position: relative;
`;
const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 20px;
  padding: 2px;
  outline: none;
  border: 0;
`;
const ArrayField = (props: any) => {
  let { field: config, model, content, value, onBlur, onDelete, onChange, placeholder, ...rest } = props;

  if (config.getDefaultContent) {
    value = config.getDefaultContent(content);
  }
  return (
    <div {...rest}>
      <Row>
        {value &&
          Array.isArray(value) &&
          config.schema &&
          value.map((data, index) => {
            return (
              <Row key={index}>
                <ArrayRow>
                  {config.schema.map((field) => {
                    let fieldModel = model + '.' + index + '.' + field.model;
                    let val = data[field.model] === undefined ? '' : data[field.model];

                    return (
                      <Col key={fieldModel} md={Math.round(24 / config.schema.length)}>
                        <div className="form-group">
                          <label>
                            <strong>{field.label || field.model}</strong>
                          </label>
                          <textarea
                            className="form-control"
                            placeholder={`Enter value for ${fieldModel}`}
                            onBlur={onBlur(fieldModel)}
                            onChange={onChange(fieldModel)}
                            value={val}
                          />
                        </div>
                      </Col>
                    );
                  })}
                  <DeleteButton className="btn btn-danger" onClick={onDelete(model, value, index)}>
                    X
                  </DeleteButton>
                </ArrayRow>
              </Row>
            );
          })}
      </Row>
    </div>
  );
};

export default ArrayField;
