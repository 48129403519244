import { selectMany } from 'xcel-redux-orm';
import { destroyRequest } from 'xcel-redux-orm';
import { GET_USER_FAVORITES_ENDPOINT } from '../../../constants';
import * as events from '../../events';
import { favoriteSelector } from '../../selectors';
import { getProducts } from './getProducts';
import { productApiActions } from './productApiActions';

export const setFavorites = (response) => ({ type: events.SET_FAVORITES, payload: response });
export const setFavoritesLoaded = (response) => ({ type: events.FAVORITE_PRODUCTS_LOADED, payload: response });

const getFavoriteProducts = ({ id }) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest(GET_USER_FAVORITES_ENDPOINT));
    dispatch(destroyRequest('getProducts'));
    const response = await dispatch(productApiActions.getProductsListsById({ id }, { jsonApi: true }));

    if (!!response) {
      const favoriteList = favoriteSelector.selectOne(getState(), id);
      await dispatch(getProducts(selectMany(getState(), 'productRollupItem', null).map((a) => a.id)));
      dispatch(setFavorites(favoriteList));
    } else {
      dispatch(setFavorites({ products: [] }));
    }

    return response;
  } catch (e) {
    throw e;
  } finally {
    dispatch(setFavoritesLoaded(true));
  }
};

export { getFavoriteProducts };
