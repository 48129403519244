import { DashboardModal } from './app/components';
import {
  IndividualApprovalHistoryTable,
  IndividualDraftTable,
  IndividualHistoryTable,
  IndividualPendingApprovalsTable,
  TeamApprovalHistoryTable,
  TeamDraftTable,
  TeamHistoryTable,
  TeamPendingApprovalsTable
  } from './app/pages';
import AwardHeader from './app/pages/MyAwards/AwardCard/AwardHeader';
import * as nominationSelectors from './app/redux/selectors';
import nominationModule from './nominationModule';

export { Resources } from './app/types';
export { myAwardActions } from './app/redux/actions';
export {
  IndividualApprovalHistoryTable,
  IndividualDraftTable,
  IndividualHistoryTable,
  IndividualPendingApprovalsTable,
  TeamApprovalHistoryTable,
  TeamDraftTable,
  TeamHistoryTable,
  TeamPendingApprovalsTable,
  DashboardModal
};
export {
  IndividualAwardPage,
  IndividualProductPage,
  IndividualNomineeSearch,
  MyAwards,
  NominationDashboard,
  NominationWizard as IndividualNominationWizard,
  TeamNominationWizard,
  RedeemingGiftWizard,
  TeamAwardPage,
  TeamCriteriaPage,
  IndividualCriteriaPage,
  TeamMemberPanel,
  TeamConfirmationPage,
  TeamCongratulationsPage,
  TeamNomineePage,
  TeamDetailPage,
  ApproveByEmailForm,
  TeamNomineeDetailPage,
  NominationActivity,
  NominationApproval
} from './app/pages';
export { StarWinners } from './app/components/index';
export { nominationModule };
export { nominationSelectors, AwardHeader };
export * from './app/pages/Winners';
