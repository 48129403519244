import { CombinedMetricGoals, TimePeriod } from 'xcel-api-generator/dist/metric';
import { createTypeSelector } from 'xcel-redux-orm';
import {
DATA_ROOT_STATE,
ROOT_STATE,
// TIME_PERIOD_REDUCER_KEY,
// COMBINED_METRICS_REDUCER_KEY
}from '../../constants';
 
export const getCombinedMetricGoalsSelector = createTypeSelector<CombinedMetricGoals>('CombinedMetricGoals');
export const getTimePeriodSelector = createTypeSelector<TimePeriod>('TimePeriod');

export const getApiCombinedMetricGoals = (state) => state[DATA_ROOT_STATE].combinedMetricsGoals;
export const getCombinedMetricsGoals = (state) => state[ROOT_STATE].combinedMetricGoals;
export const getTimePeriodKey = (state) => state[ROOT_STATE].timePeriod;