import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { findDOMNode } from 'react-dom';
import * as Icon from 'react-fontawesome';
import { Link, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import {
  AddToCartButton,
  CatalogPointValueData,
  CatalogTable,
  CatalogTableBody,
  CatalogTableData,
  CatalogTableHead,
  CatalogTableHeading,
  CatalogTableHeadRow,
  CatalogTableInfo,
  CatalogTableInfoContainer,
  CatalogTableInfoImage,
  CatalogTableInfoLink,
  CatalogTableInfoProduct,
  CatalogTableRow,
  LinkButton
  } from '../../../components/ProductTable/ProductTableStyles';
import { isCartDisabled } from '../../../utils/cart';
import { FavoritesTableProps } from '../types';
import {
  CatalogTableButton,
  FavOptions,
  FavProduct,
  FavValue,
  GroupProductFormGroup,
  OptionFormGroup,
  UndoButton,
  UndoTableContent,
  UndoTableData,
  UndoTableRow
  } from './styles';

class FavoritesTable extends React.Component<FavoritesTableProps, any> {
  productOptionSelection: Array<any>;
  productGroupSelection: Array<any>;
  constructor(props: any) {
    super(props);
    this.productOptionSelection = [];
    this.productGroupSelection = [];
    this.state = { cartFlags: [] };
  }

  showUndoProducts = () =>
    this.props.undoProducts
      ? this.props.undoProducts.map((product, index) => (
          <UndoTableRow key={product.id}>
            <UndoTableData colSpan="5">
              <UndoTableContent>
                <LinkButton
                  themeVariation="primary-link"
                  to={`/catalog/product/${encodeURIComponent(product.id)}${product.groupedProducts &&
                    product.groupedProducts.length > 0 &&
                    `?defaultGroupProduct=${encodeURIComponent(product.id)}`}`}
                  dangerouslySetInnerHTML={{ __html: `${product.name.replace(/<(.|\n)*?>/g, '').substring(0, 10)}...` }}
                />
                <Text>{` ${this.props.undoSuccessLabel}`}</Text>
              </UndoTableContent>
              <UndoButton
                themeVariation="primary-link"
                onClick={this.props.undoFavorite(product)}
                disabled={this.props.undoDisabled}
              >
                <Icon name="undo" />
                {this.props.undoLabel}
              </UndoButton>
            </UndoTableData>
          </UndoTableRow>
        ))
      : null;
  handleProductGroupChange = (product) => {
    let groupedProductSelected = findDOMNode(this.productGroupSelection[product.id]) as any;
    if (groupedProductSelected) {
      groupedProductSelected = product.groupedProducts.find((prod) => prod.productId === groupedProductSelected.value);
    }
    var node = findDOMNode(this.productGroupSelection[product.id]) as any;
    if (node == null) return;
    const currentValue = node.value;
    const currentProduct = product.groupedProducts.filter((a) => a.productId === currentValue)[0];
    if (currentProduct === undefined || Object.keys(currentProduct).length === 0) {
      this.setState((state) => (state.cartFlags[product.id] = true));
    } else {
      this.setState((state) => (state.cartFlags[product.id] = false));
    }
  };

  showProductOptions = (product) =>
    product.productOptions.length > 0 && (
      <OptionFormGroup controlId="formControlsSelect">
        <FormControl
          componentClass="select"
          placeholder="select"
          name={product.id}
          ref={(component) => {
            this.productOptionSelection[product.id] = component;
          }}
        >
          {product.productOptions.map((option) => (
            <option key={option.productOptionID} value={option.productOptionID}>
              {option.selection}
            </option>
          ))}
        </FormControl>
      </OptionFormGroup>
    );

  showGroupOptions = (product) =>
    product.groupedProducts.length > 0 && (
      <GroupProductFormGroup controlId="formControlsSelect">
        <FormControl
          componentClass="select"
          placeholder="select"
          name={product.id}
          onChange={() => this.handleProductGroupChange(product)}
          ref={(component) => {
            this.productGroupSelection[product.id] = component;
          }}
        >
          <option>Select One</option>
          {product.groupedProducts.map((groupProduct) => (
            <option key={groupProduct.productId} value={groupProduct.productId}>
              {groupProduct.productName}
            </option>
          ))}
        </FormControl>
      </GroupProductFormGroup>
    );

  handleAddCartOption = (product) => (e) => {
    e.preventDefault();

    let optionSelected = findDOMNode(this.productOptionSelection[product.id]) as any;
    if (optionSelected) {
      optionSelected = optionSelected.value;
    }

    let groupedProductSelected = findDOMNode(this.productGroupSelection[product.id]) as any;
    if (groupedProductSelected) {
      groupedProductSelected = product.groupedProducts.find((prod) => prod.productId === groupedProductSelected.value);
    }
    this.props.addToCart(product, optionSelected, groupedProductSelected);
  };

  render() {
    const {
      accountSummary,
      addToCartLabel,
      pointsAbbrev,
      products,
      removeFavorite,
      removeFromFavoritesLabel,
      tableOptionsHeading,
      removeFavoriteDisabled,
      tablePointValueHeading,
      addCartButtonDisabled,
      tableProductHeading,
      contactUsForDetails
    } = this.props;

    return (
      <Row className="hidden-xs">
        <Col sm={24} smOffset={0} md={22} mdOffset={1} lg={20} lgOffset={2}>
          <CatalogTable>
            <CatalogTableHead>
              <CatalogTableHeadRow>
                <FavProduct>
                  <Text themeVariation="primary-table-heading">{tableProductHeading}</Text>
                </FavProduct>
                <FavOptions>
                  <Text themeVariation="primary-table-heading">{tableOptionsHeading}</Text>
                </FavOptions>
                <FavValue>
                  <Text themeVariation="primary-table-heading">{tablePointValueHeading}</Text>
                </FavValue>
                <CatalogTableHeading />
                <CatalogTableHeading />
              </CatalogTableHeadRow>
            </CatalogTableHead>
            <CatalogTableBody>
              {this.showUndoProducts()}
              {products &&
                products.map(
                  (product) =>
                    product && (
                      <CatalogTableRow key={product.id}>
                        <CatalogTableInfo>
                          <CatalogTableInfoImage src={product.imageLg} />
                          <CatalogTableInfoContainer>
                            <CatalogTableInfoLink to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
                              {product.manufacturer}
                            </CatalogTableInfoLink>
                            <CatalogTableInfoProduct
                              themeVariation="text-link"
                              to={`/catalog/product/${encodeURIComponent(product.id)}`}
                              dangerouslySetInnerHTML={{
                                __html: !product.productGroupId ? product.name : product.productGroupDescription
                              }}
                            />
                          </CatalogTableInfoContainer>
                        </CatalogTableInfo>
                        <CatalogTableData>
                          {this.showProductOptions(product)}
                          {this.showGroupOptions(product)}
                        </CatalogTableData>
                        <CatalogPointValueData>
                          <Text themeVariation="secondary-text-bold">
                            {product.pointValue && product.pointValue > 0
                              ? !!product.productGroupId
                                ? `${parseFloat(product.productGroupMinPoints).toLocaleString()} - ${parseFloat(
                                    product.productGroupMaxPoints
                                  ).toLocaleString()} ${pointsAbbrev}`
                                : `${parseFloat(product.pointValue).toLocaleString()} ${pointsAbbrev}`
                              : null}
                          </Text>
                        </CatalogPointValueData>
                        <CatalogTableData>
                          <LinkButton
                            themeVariation="primary-link"
                            onClick={removeFavorite(product)}
                            disabled={removeFavoriteDisabled}
                          >
                            {removeFromFavoritesLabel}
                          </LinkButton>
                        </CatalogTableData>
                        <CatalogTableButton>
                          {product.pointValue && product.pointValue > 0 ? (
                            <AddToCartButton
                              themeVariation="secondary"
                              onClick={this.handleAddCartOption(product)}
                              disabled={
                                isCartDisabled(product, accountSummary) ||
                                addCartButtonDisabled ||
                                this.state.cartFlags[product.id]
                              }
                            >
                              {addToCartLabel}
                            </AddToCartButton>
                          ) : (
                            <Link themeVariation="primary" href={`${window.location.search}#zero-point`}>
                              {contactUsForDetails}
                            </Link>
                          )}
                        </CatalogTableButton>
                      </CatalogTableRow>
                    )
                )}
            </CatalogTableBody>
          </CatalogTable>
        </Col>
      </Row>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  pointsAbbrev: getResource('catalog.myFavorites.pointsAbbrev', 'pts.'),
  addToCartLabel: getResource('catalog.myFavorites.addToCartLabel', 'Add to Cart'),
  undoLabel: getResource('catalog.myFavorites.undoLabel', 'Undo'),
  undoSuccessLabel: getResource('catalog.myFavorites.undoSuccessLabel', 'was removed from your favorites'),
  removeFromFavoritesLabel: getResource('catalog.myFavorites.removeFromFavoritesLabel', 'Remove'),
  tableProductHeading: getResource('catalog.myFavorites.tableProductHeading', 'Product'),
  tableOptionsHeading: getResource('catalog.myFavorites.tableOptionsHeading', 'Options'),
  tablePointValueHeading: getResource('catalog.myFavorites.tablePointValueHeading', 'Point Value'),
  contactUsForDetails: getResource('catalog.myFavorites.contactUsForDetails', 'Contact Us for Details')
});

export default withResource(mapResourceToProps)(FavoritesTable);
