import * as React from 'react';
import {
  Button,
  MenuItem,
  Nav,
  NavDropdown
  } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import Guard from '../Guard';
import NavItem from './NavItem';
import { PrimaryNavStyledWrap } from './Style/TabsStyle';
import Tab from './Tab';

export interface TabsProps {
  collapseData: any;
  setComponentProperty: any;
  getContent: any;
  renderComponents: any;
}

const StyledDeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`;

class Tabs extends React.Component<
  TabsProps & JSX.IntrinsicElements['div'],
  { key: any; removeElement: any; moreButtonIndex: any }
> {
  el: HTMLElement;
  constructor(props: TabsProps) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      key: 0,
      removeElement: [],
      moreButtonIndex: -1
    };
  }

  handleSelect(key: any) {
    this.setState({ key });
  }

  checkWidth = () => {
    if (!this.el) return;
    const parent: HTMLElement = this.el.querySelector('.primary-item-wrap');
    const nav: HTMLElement = this.el.querySelector('.menu-item-container');
    if (!parent || !nav) return;
    const navWidth = nav.offsetWidth;
    const width = parent.offsetWidth;
    const parentPos = parent.getBoundingClientRect();

    if (navWidth < width) {
      return this.setState({ moreButtonIndex: -1 });
    }

    let moreButtonIndex = -1;

    for (let i = 0; i < nav.children[0].children.length; i++) {
      const child: HTMLElement = (nav as any).children[0].children[i];

      const pos = child.getBoundingClientRect();
      const left = pos.left - parentPos.left;
      if (width < left + child.offsetWidth) {
        moreButtonIndex = i - 1;
        break;
      } else {
        '';
      }
    }
    if (this.state.moreButtonIndex !== moreButtonIndex) {
      this.setState({ moreButtonIndex });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.checkWidth);
    this.checkWidth();
  }
  handleMount = (el) => {
    this.el = ReactDOM.findDOMNode(el) as HTMLElement;
  };
  render() {
    const { setComponentProperty, getContent, renderComponents } = this.props;
    let collapseData = getContent('collapseData', { type: 'none', label: 'collapseData' });
    if (!Array.isArray(collapseData)) {
      collapseData = [];
    }
    const isEditing = this.props['data-cms-editing'];
    const handleClick = () => {
      setComponentProperty('collapseData', [...collapseData, {}]);
    };
    const handleClickRemoveInd = (tabIndex) => {
      collapseData.splice(tabIndex, 1);
      setComponentProperty('collapseData', collapseData);
    };
    return (
      <PrimaryNavStyledWrap className={this.props.className} ref={this.handleMount}>
        {isEditing && (
          <div>
            <Button bsStyle="success" onClick={handleClick}>
              Add A Tab
            </Button>
          </div>
        )}
        <Tab.Container activeKey={this.state.key} onSelect={this.handleSelect} defaultActiveKey={0}>
          <div className="clearfix">
            <div className="getWidth primary-item-wrap menu-item-container">
              <Nav bsStyle="tabs">
                {collapseData &&
                  collapseData.map &&
                  collapseData.map((node, index) => {
                    const hasMoreButton = this.state.moreButtonIndex !== -1;
                    if (index === this.state.moreButtonIndex && hasMoreButton) {
                      return (
                        <NavDropdown
                          eventKey="3"
                          key={index}
                          title={getContent(`collapseData.moreButtonTitle`)}
                          id="nav-dropdown-within-tab"
                          className="more-dropdown"
                        >
                          {collapseData
                            .filter((moreFiltered, filteredIndex) => filteredIndex >= this.state.moreButtonIndex)
                            .map((mores, moresIndex) => (
                              <MenuItem key={moresIndex} eventKey={moresIndex + this.state.moreButtonIndex}>
                                {getContent(`collapseData.${moresIndex}.title`)}
                              </MenuItem>
                            ))}
                        </NavDropdown>
                      );
                    }

                    return (
                      <Guard {...node.guard} {...this.props} key={index}>
                        <NavItem
                          key={index}
                          role="presentation"
                          eventKey={index}
                          className={hasMoreButton && index >= this.state.moreButtonIndex ? 'more' : `not-more`}
                          onSelect={this.handleSelect}
                          active={this.state.key === index}
                        >
                          {getContent(`collapseData.${index}.title`, { type: 'html' })}
                          {isEditing && (
                            <StyledDeleteButton
                              className="btn btn-danger"
                              onClick={() => (window.confirm('Delete this?') ? handleClickRemoveInd(index) : '')}
                            >
                              X
                            </StyledDeleteButton>
                          )}
                        </NavItem>
                      </Guard>
                    );
                  })}
              </Nav>
            </div>

            <Tab.Content animation={true}>
              {collapseData &&
                collapseData.map((node, i) => (
                  <Tab.Pane key={i} eventKey={i}>
                    {renderComponents(`collapseData.${i}.children`)}
                  </Tab.Pane>
                ))}
            </Tab.Content>
          </div>
        </Tab.Container>
      </PrimaryNavStyledWrap>
    );
  }
}
export { Tabs };

const mapContentToProps = (getContent) => ({ getContent });
export default register('rsv8-components/tabs/DynamicContentTabs')(
  withContent(mapContentToProps),
  withTheme(['li', 'a', 'p', 'ul.nav', 'li.active', '.tab-content'])
)(Tabs);
