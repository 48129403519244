import * as React from 'react';

const OrderHistoryContext = (React as any).createContext({
  accountSummary: undefined,
  products: undefined,
  toggleShippingModal: () => undefined,
  addToCart: () => undefined,
  addCartButtonDisabled: undefined,
  togglePointsModal: undefined,
  cartProducts: undefined
});

export default OrderHistoryContext as any;
