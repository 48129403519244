import { Tabs as BootstrapTabs } from 'react-bootstrap';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const Tabs = styled(BootstrapTabs)`
  ul.nav {
    // Allow tabs scroll/swipe horizontally on small breakpoints
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    li {
      white-space: nowrap;
    }
  }
  .nav-tabs > li.active > a {
    box-shadow: 0 0 0 #ffffff !important;
  }
` as any;

export default register('rsv8-components/tabs/Tabs')(withTheme(['ul.nav', 'li.active', 'a', 'li', '.tab-content']))(
  Tabs
) as typeof Tabs;
