import { promiseDispatcher } from 'xcel-react-core';
import { customFieldApi } from '../../api';
import * as types from './types';

const updateUserFieldsRequest = (response, params) => {
  return {
    type: types.PUT_USER_CUSTOM_FIELDS_REQUEST,
    payload: response
  };
};

const updateUserFieldsSuccess = (response, params) => {
  return {
    type: types.PUT_USER_CUSTOM_FIELDS_SUCCESS,
    payload: response
  };
};

const updateUserFieldsFailure = (response, params) => {
  return {
    type: types.PUT_USER_CUSTOM_FIELDS_FAILURE,
    payload: response
  };
};

const updateUserCustomFields = promiseDispatcher(customFieldApi.updateUsercustomfieldByFormTypeAndKey, {
  request: updateUserFieldsRequest,
  success: updateUserFieldsSuccess,
  failure: updateUserFieldsFailure
}) as any;

export default updateUserCustomFields;
