import {
  F,
  findIndex,
  findLastIndex,
  T
  } from 'ramda';
import { RegisterOptions as Options, Step } from '../../../types';
import * as events from '../events';
import { getWizardCurrentStepNumber, getWizardSteps } from '../selectors';

export const setWizardStep = (name: string, step: number) => ({
  type: events.SET_WIZARD_STEP,
  payload: {
    name,
    step
  }
});

export const incrementWizard = (name: string) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const currentStepNumber = getWizardCurrentStepNumber(state, name);
  const wizardSteps = getWizardSteps(state, name);
  const nextIndex = findIndex<Step>((v) => !v.skip)(wizardSteps.slice(currentStepNumber + 1)) + (currentStepNumber + 1);
  dispatch(setWizardStep(name, nextIndex));
};

export const decrementWizard = (name: string) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const currentStepNumber = getWizardCurrentStepNumber(state, name);
  const wizardSteps = getWizardSteps(state, name);
  const nextIndex = findLastIndex<Step>((v) => !v.skip)(wizardSteps.slice(0, currentStepNumber));
  dispatch(setWizardStep(name, nextIndex));
};

export const setWizardStepByStepTitle = (name: string, title: string) => (dispatch, getState) => {
  const steps = getWizardSteps(getState(), name).map((s) => s.title);
  const titleIndex = steps.indexOf(title);
  if (titleIndex !== -1) {
    dispatch(setWizardStep(name, titleIndex));
  }
};

export const registerWizard = (name: string, opts: Options) => ({
  type: events.REGISTER_WIZARD,
  payload: { name, ...opts }
});

export const removeStep = (name: string, step: Step) => ({
  type: events.REMOVE_WIZARD_STEP,
  payload: { name, step }
});

export const unregisterWizard = (name: string) => ({
  type: events.UNREGISTER_WIZARD,
  payload: { name }
});

const modifyWizardStep = (fn) => (name, step) => {
  return {
    type: events.MODIFY_WIZARD_STEP,
    payload: { name, step, function: fn }
  };
};

const modifyAllSteps = (fn) => (name) => {
  return {
    type: events.MODIFY_ALL_STEPS,
    payload: { name, function: fn }
  };
};

export const skipStep = modifyWizardStep({ skip: T });

export const unskipStep = modifyWizardStep({ skip: F });

export const unskipAll = modifyAllSteps({ skip: F });
