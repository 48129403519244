export const AUTH_STATE = 'rsv8-auth/auth';
export const SESSION_STATE = 'rsv8-auth/session';
export const USER_STATE = 'rsv8-auth/user';
export const CLIENT_STATE = 'xcel-redux-orm/data'

/** session selectors */
export const getSession = (state) => state[SESSION_STATE];
export const getLastUpdateDate = (state) => state[SESSION_STATE] && state[SESSION_STATE].lastUpdated;

/** user selectors */
export const getUser = (state) => state[USER_STATE];
export const isAdminUser = (state) => {
  const {
    [USER_STATE]: { tokenType, clientId }
  } = state;
  return tokenType === 'AdminUser' || clientId === 0 || clientId === '0';
};

export const isValidatingKey = (state) => getUser(state).isValidatingKey;
export const getClientId = (state) => getUser(state).clientId;

/** client selectors */
export const getClient = (state) => state[CLIENT_STATE]
export const getClientName = (state) => {
  const { [USER_STATE]: {clientId} } = state
  return getClient(state).clientItem[clientId].attributes.name
}

/** auth selectors */

export const getAuth = (state) => state[AUTH_STATE];
