import * as React from 'react';
import { Switch } from 'react-router';
import { AuthRoute } from 'rsv8-auth';
import { ProgramLanding, AchievementLayout } from 'rsv8-achievement';

const getParamId = props => props.match.params.id;
const ProgramLandingContainer = (productProps) => {
  return <ProgramLanding programId={getParamId(productProps)} {...productProps} />;
};

const AchievementRoutes = ({ match: { path } }) => (
  <AchievementLayout>
    <Switch>
      <AuthRoute exact={true} path={path} component={ProgramLandingContainer} />
      <AuthRoute path={`${path}/program/:id`} component={ProgramLandingContainer} />
    </Switch>
  </AchievementLayout>
);

export { AchievementRoutes };
