import * as React from 'react';
import { connect } from 'react-redux';
import { Card, Container, HeaderTwo, Text } from 'rsv8-components';
import { CustomerProduct, Program } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { ProgramName } from '../../constants';
import { getProgram, getSummary } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import MarketShareProduct from './MarketShareProduct';
import UnitsProduct from './UnitsProduct';
import YTDUnitsProduct from './YTDUnitsProduct';
import { NoDataSpinner, ProductsComponentBody } from './styles';

const Products: React.FC<Props> = ({ className, products, program, title = 'Products' }) => (
  <Container className={className} themeVariation="dashboard-product-card">
    <Card themeVariation="product-shipto-cards">
      {!program && <NoDataSpinner className="fa fa-circle-o-notch fa-spin" />}

      {!!program && (
        <>
          <div className="product-header">
            <HeaderTwo themeVariation="home-card-header">{title}</HeaderTwo>

            {(ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program)) && (
              <Text themeVariation="homepage-header-note">*Estimated Alcon Reported Sales</Text>
            )}
          </div>

          <ProductsComponentBody className="product-body">
            {products
              .sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0))
              .map((product) => {
                if (ProgramName.match(ProgramName.OptiPort, program) || ProgramName.match(ProgramName.VisionSource, program))
                  return <YTDUnitsProduct key={'ytd-units-product' + product.id} product={product} />;
                if (ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program))
                  return <UnitsProduct key={'units-product' + product.id} product={product} />;
                if (ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program))
                  return <MarketShareProduct key={'market-share-product' + product.id} product={product} />;
                return null;
              })}
          </ProductsComponentBody>
        </>
      )}
    </Card>
  </Container>
);

const mapStateToProps = (state) => ({
  products: selectors.customerProductsSelector.selectMany(state),
  program: selectors.programSelector.selectMany(state)[0],
});

const mapContentToProps = (getContent) => ({
  title: getContent('title', {
    type: 'string',
    schema: [{ model: 'Title', label: 'Title:' }],
  }),
});

interface StateProps {
  products: CustomerProduct[];
  program: Program;
}
interface ContentProps {
  title: string;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('alcon-products/Products')(
  connect<StateProps>(mapStateToProps),
  withApiDataProvider(getSummary, selectors.dashboardSelector),
  withApiDataProvider(getProgram, selectors.programSelector),
  withContent(mapContentToProps),
  withTheme()
)(Products);
