import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  HeaderDecoration,
  HeaderOne,
  Image,
  Link,
  Text
  } from 'rsv8-components';
import { SubmissionError } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';
import { LoginMaxWidth } from '../../../components/LoginMaxWidth';
import { userStateSelectors } from '../../../module';
import { userForgotUsername } from '../../redux/user.actions';
import UsernameReminderRequestForm from './UsernameReminderRequestForm';

const invalidEmail = (value) => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

interface ForgotUsernameProps {
  actions: any;
  successRedirectPath: any;
  failureRedirectPath: any;
  unprocessableRedirectPath: any;
  noEmailAddressRedirectPath: any;
  resource: {
    errorMessageText: any;
    errorInvalidEmailText: any;
    contactMessageText: any;
    backToLoginPageText: any;
    forgotUserNameHeaderText: any;
    forgotUserNameParagraphText: any;
  };
  loginHelpLinkPath: any;
  imageURI: any;
  hrEmailAddress: any;
}

class ForgotUsername extends React.Component<ForgotUsernameProps, any> {
  handleSubmit = (params, dispatch) => {
    const {
      actions,
      successRedirectPath,
      failureRedirectPath,
      unprocessableRedirectPath,
      noEmailAddressRedirectPath,
      resource: { errorMessageText, errorInvalidEmailText }
    } = this.props;
    let _errors = '';
    if (!params.emailAddress) {
      _errors = errorMessageText;
    } else if (invalidEmail(params.emailAddress)) {
      _errors = errorInvalidEmailText;
    }
    if (_errors) {
      throw new SubmissionError({
        emailAddress: '',
        _error: _errors
      });
    }
    actions
      .userForgotUsername({ body: params.emailAddress }, {
        validatedRedirect: successRedirectPath,
        invalidatedRedirect: failureRedirectPath,
        unprocessableRedirect: unprocessableRedirectPath
      })
      .then(() => dispatch(push(successRedirectPath)))
      .catch((e) => {
        if (e['model.EmailAddress'] && e['model.EmailAddress'].includes('multiple_users_found')) {
          dispatch(push(unprocessableRedirectPath));
        } else if (e['model.EmailAddress'] && e.modelState['model.EmailAddress'].includes('no_email_address_found')) {
          dispatch(push(noEmailAddressRedirectPath));
        } else {
          dispatch(push(failureRedirectPath));
        }
      });
  };
  render() {
    const {
      loginHelpLinkPath,
      resource: { forgotUserNameHeaderText, forgotUserNameParagraphText, backToLoginPageText }
    } = this.props;
    return (
      <div>
        <HeaderDecoration />
        <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
          <Image themeVariation="primary-company-logo1" />
        </Col>
        <LayoutContainer>
          <Row>
            <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={8} mdOffset={8}>
              <Image themeVariation="icon-forgot-username" />
            </Col>
          </Row>
          <Row>
            <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={10} mdOffset={7}>
              <LoginMaxWidth>
                <HeaderOne className="text-nowrap text-center">{forgotUserNameHeaderText}</HeaderOne>
              </LoginMaxWidth>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: '0px' }} xs={22} xsOffset={1} lg={6} lgOffset={9} md={8} mdOffset={8}>
              <LoginMaxWidth>
                <Text>{forgotUserNameParagraphText}</Text>
              </LoginMaxWidth>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={22} xsOffset={1} lg={6} lgOffset={9} md={8} mdOffset={8}>
              <UsernameReminderRequestForm onSubmit={this.handleSubmit} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={8} mdOffset={7}>
              <Link href={loginHelpLinkPath || '/login'} textalign="center" display="block">
                {backToLoginPageText}
              </Link>
            </Col>
          </Row>
        </LayoutContainer>
      </div>
    );
  }
}

const mapState = (state) => ({
  user: userStateSelectors.getUser(state),
  forgotUsername: userStateSelectors.getUser(state).forgotUsername
});

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ userForgotUsername }, dispatch)
});

const mapResourceToProps = (getResource) => ({
  resource: {
    forgotUserNameHeaderText: getResource('forgotUsername.forgotUserNameHeaderText', 'Forgot your username?'),
    forgotUserNameParagraphText: getResource(
      'forgotUsername.forgotUserNameParagraphText',
      'Enter the email address associated with your account. We will send you a reminder of your username.'
    ),
    contactMessageText: getResource(
      'forgotUsername.contactMessageText',
      'Don’t remember/have an email address associated to your account? Contact your HR Service Center at '
    ),
    backToLoginPageText: getResource('forgotUsername.backToLoginPageText', 'Back to the login page >'),
    errorMessageText: getResource('forgotUsername.errorMessageText', 'A email is required to recover your username.'),
    errorInvalidEmailText: getResource('forgotUsername.errorInvalidEmailText', 'Invalid email address.')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(ForgotUsername as any) as any;
