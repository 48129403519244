import * as React from 'react';
import { NominationActivity, Resources } from '../../types';
import { ApproverBody, NominatorBody, TeamBody } from './Body';
import { isApprover, isTeam } from './util';

export interface NominationModalBodyProps {
  nomination: Resources.NominationDetail;
  type: NominationActivity.ActivityTables;
}

const NominationModalBody: React.SFC<NominationModalBodyProps> = ({ nomination, type }) => {
  if (isApprover(nomination, type)) {
    return <ApproverBody nomination={nomination} nominationType={type} />;
  } else if (isTeam(nomination, type)) {
    return <TeamBody nomination={nomination} nominationType={type} />;
  }
  return <NominatorBody nomination={nomination} nominationType={type} />;
};

export default NominationModalBody;
