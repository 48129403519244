import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsGantt from "highcharts/modules/gantt";
import { connect } from "react-redux";
import {
  register,
  withResource,
  withTheme,
  withContent
} from "xcel-react-core";
import { TempStatus, TempMetrics, TempGoals } from "./Util/TestData";
import {
  createBarSeriesObjectPerStatus,
  createFakeMetrics,
  mapResourceToProps,
  mapDispatchToProps,
  mapContentToProps,
  createProgressBar,
} from "./Util/goalsBarFunctions"; 
highchartsGantt(Highcharts);

export interface Props {
  GoalsProgressBarFirstCategory: string;
  GoalsProgressBarSecondCategory: string;
  getGoals: Function;
  getMetrics: Function;
  myStatuses: Array<string>;
  Goals: Array<Object>;
  ProgressBarStatusandColors: Array<object>;
  GoalsColor: Array<string>;
  GoalsMin: string;
  GoalsMax: string;
  GoalsIncrement: string;
  GoalsBuffer: string;
  ProgressBarYaxisMarkers: Array<object>;
  metricData: Array<object>;
  mobileStyle: object;
  className:string;
  GoalsBarSize:string;
  programKey: string;
  calculatorKey: string;
  flipped:string;

}
/**
 *
 * Creates ProgessBar Component
 * @export
 * @class GoalsProgressBar
 * @extends {React.Component<Props>}
 */
export class GoalsProgressBar extends React.Component<Props> {
  state = {
    progressbar: null,
    Goals: TempGoals,
    myStatuses: TempStatus,
    metricData: [],
    mobileStyle: null,
    flipped:'false',
    metricgoalsmax:0,
    metricmax:0,
  };
  async componentWillMount() {
    await this.checkForMetrics();
    try {
      await this.props.getGoals(this.props.programKey,this.props.calculatorKey).then((response:any) => {this.setState({Goals:response.data})});
     } catch(error) {
        console.log("No goals in back end");
     }
    var otherData = await this.finishCheckForMetrics(this.state.metricData);
    var data = this.createGrayBackgroundProgressBar(otherData);
    // if component has statuses and colors setup
    if (this.props.ProgressBarStatusandColors) {
      this.addMetricstoProgressBar(this.props.ProgressBarStatusandColors, data);
    }
    // if not use my test settings
    if (!this.props.ProgressBarStatusandColors) {
      this.addMetricstoProgressBar(this.state.myStatuses, data);
    }
    this.updateProgressBar(data, createProgressBar(this.props));
  }
  /**
   * initial check for metric data
   */
  async checkForMetrics() {
    // var AnotherTempMetrics=[];
    if (this.props.ProgressBarStatusandColors) {
      // loop through the progress bar status
      await this.usePropsStatuses();
    } else {
      this.useMystatuses();
    }
  }
/**
 * use statuses from prop
 */
  async usePropsStatuses() {
    for (const element of this.props.ProgressBarStatusandColors) {
    //  console.log("status" + element["status"]);
      try {
        await this.props.getMetrics(this.props.programKey,this.props.calculatorKey,element["status"]).then((response: any) => {
          this.state.metricData.push({ [element["status"]]: response.data });
        });
      } catch (error) {
        console.log("You do not have a " + element["status"] + "status");
      }
    }
  }
  /**
   * use test statuses saved in myStatuses
   */
  useMystatuses() {
    // This is where the bar uses tempstatus just in case you have no
    // loop through the progress bar status
    this.state.myStatuses.forEach(async (element: any) => {
      // use the Tempstatus to try to find the metric
      var temp = await this.props
        .getMetrics(this.props.programKey,this.props.calculatorKey,element.status)
        .then((response: any) => {
          return response.data;
        });
      if (temp) {
        this.state.metricData.push({ [element.status]: temp });
      }
    });
  }
  /**
   * Check metrics data again in case the data is not there
   * use fake data in order to show the bar in admin
   * @param temp
   */
  async finishCheckForMetrics(temp: Array<object>) {
    if (temp.length === 0) {
      this.setState({
        metricData: createFakeMetrics(TempMetrics, this.state.myStatuses)
      });
      return this.createTotalMetricsBar();
    }
    this.setState({ metricData: temp });

    return this.createTotalMetricsBar();
  }

  /**
   * Create total series that shows on Bottom bar
   * uses GoalsColor
   * @param countotal
   */
  createTotalMetricSeries(countotal: Number) {
    var returnme = {
      stacking: "overlap",
      groupPadding: 0,
      pointPadding: 0,
      name: "My Goals",
      type: "bar",
      data: [null, Number(countotal)],
      color: this.props.GoalsColor,
      borderRadius: 0,
      pointInterval: 1,
      maxPointWidth: 20
    };
    return returnme;
  }

  /**
   * Create total bar that shows on Bottom bar
   */
  createTotalMetricsBar() {
    var datavar = 0;
    // Loop trhough the fake data and add them up
    if (this.state.Goals) {
    
      this.state.Goals.forEach(element => {
        if (element.id === "TOTAL") {
          datavar = Number(element.attributes.value);
          // this checks wether value > max then we add buffer to it
          // if (Number(element.attributes.value) >= Number(this.props.GoalsMax)) {
           // datavar =
           //    Number(element.attributes.value);
          // }
    }});
  }
    return this.createTotalMetricSeries(datavar);
  }

  /**
   * Create Progress bar array to feed into state.myData series
   * update  state.max
   */
    createGrayBackgroundProgressBar(otherData: any) {
    let series = [];
    var datavar = 5000;
    var goalsdatavar = 0;
    // this.state.Goals.forEach(element => {
     if (this.props.GoalsMax) {
        datavar = Number(this.props.GoalsMax);
       // goalsdatavar
     }
     var arrayofMetricValues = []
      this.state.metricData.map((value:any,index:any)=> {
       var arrayofkeys = Object.keys(value);
       // console.log("arrayofkeys"+arrayofkeys.toString() );
       // console.log("looking for"+JSON.stringify(value[arrayofkeys[index]]) );
        if(value[arrayofkeys[index]]) {
          value[arrayofkeys[index]].forEach(element=> {
            if(element.id ==="TOTAL") {
              arrayofMetricValues.push(Number(element.attributes.value));
              
            }
          });
        }
   
     });

     this.state.Goals.forEach(element => {
      if (element.id === "TOTAL") {
        goalsdatavar =Math.ceil( Number(element.attributes.value)/Number(this.props.GoalsBuffer))*Number(this.props.GoalsBuffer);
        this.setState({metricgoalsmax:Number(element.attributes.value)});
  }});

    // console.log("looking for"+JSON.stringify(arrayofMetricValues) );
   var max  =  Math.max.apply(Math, arrayofMetricValues);
   this.setState({metricmax:max});
   // alert(max)
     const potentialsize =Math.ceil(max/Number(this.props.GoalsBuffer))*Number(this.props.GoalsBuffer)

        if(potentialsize >= Number(this.props.GoalsMin) && potentialsize <= max) {
          datavar = max;
        }
        if(potentialsize <= Number(this.props.GoalsMin) ) {
          datavar =Number(this.props.GoalsMin);
        }
        if(Number(this.props.GoalsMin) >= goalsdatavar) {
          goalsdatavar = Number(this.props.GoalsMin);
        }
        if(potentialsize > max ) {
          datavar =potentialsize;
        }
       // alert("hi"+goalsdatavar)
       // alert("not"+this.props.GoalsMax)
         if(goalsdatavar >= Number(this.props.GoalsMax)) {
          goalsdatavar = goalsdatavar +Number(this.props.GoalsBuffer);
          }
          // if(Number(this.props.GoalsMin) > goalsdatavar) {
          //   goalsdatavar = Number(this.props.GoalsMin);
          // }
          if(goalsdatavar >datavar) {
            datavar = goalsdatavar;
            }
            if(goalsdatavar< datavar) {
              goalsdatavar = datavar;
              }
        series.push({
          stacking: "normal",
          groupPadding: 0,
          pointPadding: 0,
          name: "Maximum ",
          type: "bar",
          data: [datavar,goalsdatavar ],
          color: "#dcdcdc",
          borderRadius: 0,
          pointInterval: 1,
          maxPointWidth: 20
        });
      
  //  });
    series.push(otherData);
    return series;
  }
  /**
   * Check if window is smaller then 600 
   * and flip bar 90 degrees
   * @param other 
   */
  CheckforMobileView(other: any) {
   
    if (this.props.flipped==='true') {
      this.state.mobileStyle = {
        transform: `rotate(90deg)`,
      };
      other.chart.height = window.innerHeight * 0.25;
      other.chart.width = null;
      other.yAxis.reversed = true;
      other.yAxis.labels.rotation = 270;
      other.yAxis.labels.y = 40;
      other.yAxis.labels.x = 0;
    }
  }
  /**
   * update progressbar total
   * @param seriestoUpdate
   * @param optionsToUpdate
   */
  updateProgressBar(seriestoUpdate: Object, optionsToUpdate: any) {
    // this updates data for series for progress bar
    const other = { ...optionsToUpdate, series: seriestoUpdate };    ///  + 5015/100==50.15   / 500*500
    var datavar = 0;
    var goalsdatavar = Math.ceil(this.state.metricgoalsmax/Number(this.props.GoalsBuffer))*Number(this.props.GoalsBuffer)
    // var barsize = Math.ceil((Number(this.props.GoalsMax)+ Number(this.props.GoalsBuffer))/(Number(this.props.GoalsBuffer) * 1.0) * Number(this.props.GoalsBuffer));
   // alert(goalsdatavar)
    const potentialsize =Math.ceil(this.state.metricmax/Number(this.props.GoalsBuffer))*Number(this.props.GoalsBuffer)
     /// alert(potentialsize);
        if(potentialsize >= Number(this.props.GoalsMin) && potentialsize <= this.state.metricmax) {
          datavar = this.state.metricmax;
        }
        if(potentialsize <= Number(this.props.GoalsMin) ) {
          datavar =Number(this.props.GoalsMin);
        }
        if(potentialsize > this.state.metricmax ) {
          datavar =potentialsize;
        }
        if(Number(this.props.GoalsMin) >= goalsdatavar) {
          goalsdatavar = Number(this.props.GoalsMin);
        }
         if(goalsdatavar >= Number(this.props.GoalsMax)) {
           goalsdatavar = goalsdatavar +Number(this.props.GoalsBuffer);
           }
          if(goalsdatavar > datavar) {
            datavar = goalsdatavar;
          //  alert("its big")
            }
    // this updates the max using the total
    if (this.props.GoalsMax) {
     // alert(datavar)
       other.yAxis.max = datavar;

    }
    if (this.props.GoalsMin) {
      other.yAxis.min = Number(this.props.GoalsMin);
    }
    if (this.props.GoalsIncrement) {
      other.yAxis.tickInterval = datavar/Number(this.props.GoalsIncrement);
    }
    this.CheckforMobileView(other);

    // this.setState({progressbar:other});
    this.setState({ progressbar: other });
  }
  /**
   * Using arrayof statuses push series data to  currentData(Only does it messed up data)
   * @param arrayofStatuses
   * @param currentData
   * @return {Array<object>}
   */
  addMetricstoProgressBar(arrayofStatuses: any, currentData: Array<Object>) {
    var temp = null;
    if (arrayofStatuses) {
      arrayofStatuses.forEach(statuselement => {
        // if(this.state.metricData.length>0) {
        this.state.metricData.forEach(element => {
          temp = createBarSeriesObjectPerStatus(
            element,
            statuselement.status,
            statuselement.color
          );
          if (temp) {
            currentData.push(temp);
          }
        });
        // }
        // }
      });
    }
  }
  render() {
    return (
      <div {...this.props} style={this.state.mobileStyle}>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.progressbar}
        />
      </div>
    );
  }
}

/* This function is the one that connects everything via redux
 * aka it it maps things to prop values
 */
export default register("rsv8-metrics/GoalsProgressBar")(
  connect(null, mapDispatchToProps),
  withResource(mapResourceToProps),
  withTheme(),
  withContent(mapContentToProps)
)(GoalsProgressBar);
