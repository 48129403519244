import { destroyRequest } from 'xcel-redux-orm';
import { contentCultureSelector, contentDetailSelector } from '../selectors';
import cmsAdminApi from './cmsAdminApi';
import { contentActions, contentVersionActions } from './index';
const { getAdminContentcultureById, createAdminContentsByIdCultures } = cmsAdminApi;

const contentCultureActions = {
  get: getAdminContentcultureById,
  create: (contentTypeId, contentId, cultureId, providerId) => async (dispatch, getState) => {
    const response = await dispatch(
      createAdminContentsByIdCultures({ id: contentId, body: { cultureId, providerId } })
    );
    // after we create the content culture we need to update our content item.
    dispatch(destroyRequest('getAdminContentsById'));
    dispatch(destroyRequest('getAdminRoutes'));
    // then we need to fetch the updated contentId

    const content = contentDetailSelector.getActive(getState(), contentTypeId);
    await dispatch(contentActions.getById(contentId, content.id));
    const contentCultureId = response.data.id;
    dispatch(contentCultureActions.setActive(contentTypeId, contentCultureId));
    await dispatch(contentVersionActions.get(contentTypeId, contentCultureId));

    return response;
  },
  setActiveByCultureId: (contentTypeId, cultureId) => async (dispatch, getState) => {
    const active = contentCultureSelector.getActive(getState(), contentTypeId);
    const providerId = active.provider.id;
    return await dispatch(
      contentCultureActions.setActiveByCultureIdAndProviderId(contentTypeId, cultureId, providerId)
    );
  },
  setActiveByProviderId: (contentTypeId, providerId) => async (dispatch, getState) => {
    const active = contentCultureSelector.getActive(getState(), contentTypeId);
    const cultureId = active.cultureId;
    return await dispatch(
      contentCultureActions.setActiveByCultureIdAndProviderId(contentTypeId, cultureId, providerId)
    );
  },
  setActiveByCultureIdAndProviderId: (contentTypeId, cultureId, providerId) => async (dispatch, getState) => {
    let contentCulture = contentCultureSelector.getByCultureIdAndProviderId(
      getState(),
      contentTypeId,
      cultureId,
      providerId
    );
    let response;
    if (!contentCulture) {
      const content = contentDetailSelector.getActive(getState(), contentTypeId);
      response = await dispatch(contentCultureActions.create(contentTypeId, content.id, cultureId, providerId));
    } else {
      response = await dispatch(contentVersionActions.get(contentTypeId, contentCulture.id));
      dispatch(contentCultureActions.setActive(contentTypeId, contentCulture.id));
      // const versions = contentVersionSelector.getVersionsByContentCultureId(getState(), contentCulture.id);
      // const version = versions.reverse()[0];
      // if (version) {
      //   dispatch(contentVersionActions.setActive(contentTypeId, version.id));
      //   dispatch(contentActions.setEdit(contentTypeId, version));
      // }
    }
    return response;
  },
  setActive: (contentTypeId, contentCultureId) =>
    contentActions.setActiveProperty(contentTypeId, 'contentCultureId', contentCultureId)
};

export default contentCultureActions;
