import * as React from 'react';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';

const Copyright = ({ resource, className = '' }) => {
  return (
    <span className={`copyright ${className}`}>
      &copy; {new Date().getFullYear()} {resource.copyrightLabel}
    </span>
  );
};

const mapResourceToProps = (getResource) => ({
  resource: {
    copyrightLabel: getResource('footer.copyrightLabel', 'Xceleration')
  }
});

export default register('rsv8-app/Copyright')(withContent(), withResource(mapResourceToProps), withTheme())(Copyright);
