import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AnimatedCircle } from 'rsv8-components';
import styled from 'styled-components';

const Spinner = styled(AnimatedCircle)`
  margin: 16px auto;
`;
const CenteredCol = styled(Col)`
  text-align: center;
`;
const NoDataSpinner: React.FC = () => (
  <Row>
    <CenteredCol xs={24}>
      <Spinner />
    </CenteredCol>
  </Row>
);

export default NoDataSpinner;
