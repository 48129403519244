import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid, Separator } from 'rsv8-components';
import { EnterSales } from '../../components/EnterSales';
import SalesActivity from '../../components/SalesActivity/SalesActivity';
import SalesNav from '../../components/SalesNav/SalesNav';

class SalesLanding extends React.Component<any, any> {
  render() {
    return (
      <Grid themeVariation="page-wrapper">
        <div>Sales Entry Lives!</div>
        <SalesNav />
        <EnterSales />
        <Separator />
        <SalesActivity />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ test }, dispatch)
});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SalesLanding);
