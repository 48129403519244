import { IndividualApprovalHistoryTable, TeamApprovalHistoryTable } from './History';
import NominationApproval from './NominationApproval';
import { IndividualPendingApprovalsTable, TeamPendingApprovalsTable } from './Pending';
export {
  NominationApproval,
  IndividualPendingApprovalsTable,
  TeamPendingApprovalsTable,
  IndividualApprovalHistoryTable,
  TeamApprovalHistoryTable
};
