import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { userStateSelectors } from 'rsv8-auth';
import { PageRenderer } from 'rsv8-cms';
import { Grid } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { AchievementLevels } from '../../components/AchievementLevels';

interface ProgramLandingProps {
  match?: any;
  programId: number;
  computedMatch?: any;
  routing: any;
  countryCode: string;
  showComponents: string;
}

class ProgramLanding extends React.Component<ProgramLandingProps> {
  renderComponents = () => {
    // ToDo: default program ID ?
    const { programId = 100, showComponents } = this.props;
    const componentList = {
      achievements: <AchievementLevels programId={programId} />
    };
    return showComponents.split(',').map(key => componentList[key]);
  };
  render() {
    const { programId } = this.props;
    return (
      <Grid themeVariation="page-wrapper">
        <PageRenderer
          path={`/achievement/program/${programId}`}
          onNotFound={() => <PageRenderer path={`/achievement/default`} />}
        />
        {this.renderComponents()}
      </Grid>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  showComponents: getResource('achievement.showComponents', 'achievements')
});

const mapStateToProps = (state) => ({
  countryCode: userStateSelectors.getUser(state).countryCode
});

export default compose<any>(
  connect(mapStateToProps),
  withResource(mapResourceToProps)
)(ProgramLanding);
