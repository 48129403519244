import * as React from 'react';
import { Easing } from 'xcel-react-animations';

export type Props = {
  scroll: boolean;
  disable?: boolean;
};

class AutoScroll extends React.Component<Props> {
  private scrollNode: HTMLElement;

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.scroll && this.props.scroll !== prevProps.scroll) {
      this.smoothScroll();
    }
  }

  getCollapsePosition = (element) => {
    let yPosition = 0;
    let lastScrollTop = 0;
    while (element) {
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      lastScrollTop = element.scrollTop;
      element = element.offsetParent;
    }
    return lastScrollTop + yPosition;
  };

  smoothScroll() {
    if (!this.props.disable) {
      const offsetTop = this.getCollapsePosition(this.scrollNode);
      // on IE11 the current scrollY position is available in the `pageYOffset` prop, same for x pos
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      Easing.animate(scrollY, offsetTop - 100, 1000, Easing.EaseType.QUAD_IN_OUT, (newValue) =>
        window.scroll(scrollX, newValue)
      );
    }
  }

  getScrollNode = (node: HTMLElement) => {
    this.scrollNode = node;
  };

  render() {
    return (
      <div className="AutoScroll" ref={this.getScrollNode}>
        {this.props.children}
      </div>
    );
  }
}

export default AutoScroll;
