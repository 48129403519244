import * as React from 'react';

export interface BackendResourceData {
  backendKey: string;
  resourceKey: string;
  params: Array<string>;
}

export interface BackendResourceContextValue {
  resourceIndex: Record<string, BackendResourceData>;
  resources: Record<string, string>;
}

const ErrorContext = React.createContext<BackendResourceContextValue>({ resourceIndex: {}, resources: {} });

export default ErrorContext;
