import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { compose } from 'redux';
import { HeaderThree, Text } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { StyledNavigationButtons } from './NavigationButtons';

export type Props = {
  previousPage: any;
  handleSubmit: any;
  pageNumber: string;
  lastPage: boolean;
  subtitle: string;
  descriptionText: string;
  recommendation: any;
  options: any;
  topicId: string;
  formError: string;
  formSubmitted: boolean;
};
const StyledText = styled(Text)`
  width: 100%;
` as any;
const StyledAlert = styled(Alert)`
  text-align: center;
` as any;

const validate = (values, props) => {
  let errors = {} as any;

  if (!values[`pageNumber-${props.pageNumber}`]) {
    errors.message = 'Select an Option';
  }

  return errors;
};

const getOptionsRadioButtons = (options: any, pageNumber: string) => {
  const StyledRow = styled(Row)`
    margin: 2em 0;
  ` as any;
  const StyledField = styled(Field)`
    box-shadow: none !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    width: auto;
  ` as any;
  const CenteredCol = styled(Col)`
    text-align: center;
  ` as any;
  const CenteredLabel = styled.label`
    text-align: center;
    .form-group {
      margin: 0;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
  ` as any;
  const radioButtons = options.map((option, index) => (
    <CenteredCol xs={Math.floor(24 / options.length)} key={index}>
      <CenteredLabel htmlFor={`option-${index}`}>
        <StyledField
          name={`pageNumber-${pageNumber}`}
          key={option.id}
          component="bs-input"
          type="radio"
          value={`${option.id}`}
        />
        <StyledText>{option.label}</StyledText>
      </CenteredLabel>
    </CenteredCol>
  ));
  return <StyledRow>{radioButtons}</StyledRow>;
};

const WizardFormPage: React.SFC<Props> = ({
  previousPage,
  handleSubmit,
  pageNumber,
  lastPage,
  subtitle,
  descriptionText,
  recommendation,
  options,
  formError,
  formSubmitted
}) => {
  const optionsRadioButtons = getOptionsRadioButtons(options, pageNumber);
  return (
    <div>
      <HeaderThree>{subtitle}</HeaderThree>
      <Text>{descriptionText}</Text>
      {recommendation}
      <form onSubmit={handleSubmit}>
        {optionsRadioButtons}
        {formError && formSubmitted && <StyledAlert bsStyle="danger">{formError}</StyledAlert>}
        <StyledNavigationButtons previousPage={previousPage} previous={pageNumber !== '0'} lastPage={lastPage} />
      </form>
    </div>
  );
};

export default compose<any>(
  reduxForm({
    form: 'wizard',
    validate,
    destroyOnUnmount: false
  })
)(WizardFormPage);
