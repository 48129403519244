import * as constants from '../../constants';

const initialState = {
  enrollmentStatus: '',
  enrolledUser: {},
  setAchSearchInformation: {},
};

export const directDepositReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.DIRECT_DEPOSIT_REDUCER_KEY:
      return {
        ...state,
        enrollmentStatus: payload,
      };
    case constants.ENROLLED_USER_KEY:
      return {
        ...state,
        enrolledUser: payload,
      };
    case constants.ACH_SEARCH_INFORMATION:
      return {
        ...state,
        setAchSearchInformation: payload,
      };
    default:
      return state;
  }
};

