import * as React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { Text } from 'rsv8-components';

((_1?: StyledComponentClass<any, any>, _2?: typeof React) => 0)();

interface BarContainerProps {
  collapsed: boolean;
}

export const BarContainer = styled.div<BarContainerProps>`
  background: white;
  width: 100%;
  box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.08);
  min-height: 50px;
  border-radius: 5px;

  .dashboard-bar__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    .dashboard-bar__q {
      order: 1;
    }
    .dashboard-bar__points {
      order: 2;
    }
    .dashboard-bar__rebate_percent {
      order: 3;
    }
    .dashboard-bar__tier {
      order: 4;
    }
    .dashboard-bar__goal-1 {
      order: 5;
    }
    .dashboard-bar__goal-2 {
      order: 6;
    }
    .dashboard-bar__goal-3 {
      order: 7;
    }
    .dashboard-bar__projected-rebate {
      order: 8;
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    .dashboard-bar__q {
      order: 1;
    }
    .dashboard-bar__projected-rebate {
      order: 2;
    }
    .dashboard-bar__points {
      order: 3;
    }
    .dashboard-bar__tier {
      order: 4;
    }
    .dashboard-bar__rebate_percent {
      order: 5;
    }
    .dashboard-bar__goal-1 {
      order: 6;
    }
    .dashboard-bar__goal-2 {
      order: 7;
    }
    .dashboard-bar__goal-3 {
      order: 8;
    }

    ${(props) =>
      props.collapsed &&
      `
      .dashboard-bar__content {
        display: none;
      }
    `};
  }

  @media screen and (min-width: 993px) {
    .dashboard-bar__q {
      order: 1;
    }
    .dashboard-bar__points {
      order: 2;
    }
    .dashboard-bar__goal-1 {
      order: 3;
    }
    .dashboard-bar__goal-2 {
      order: 4;
    }
    .dashboard-bar__goal-3 {
      order: 5;
    }
    .dashboard-bar__rebate_percent {
      order: 6;
    }
    .dashboard-bar__tier {
      order: 7;
      padding-right: 15px;
    }

    .dashboard-bar__projected-rebate {
      position: relative;
      order: 8;
      &:before {
        content: '';
        border-left: 1px solid rgba(80, 93, 111, 0.35);
        height: 70px;
        left: 0;
        top: calc(50% - 35px);
        position: absolute;
      }
    }
  }

  @media screen and (min-width: 450px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const RebateBarContainer = styled.div<BarContainerProps>`
  background: white;
  box-shadow: 0 0 3px 0 rgba(80, 93, 111, 0.25);
  min-height: 50px;
  position: relative;
  margin: auto;

  top: 50px;
  width: 90%;
  margin-left: 5%;
  position: relative;
  margin-top: -20px;

  .rebate-bar__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 20px;
    box-shadow: 0 0 1px 0 rgba(80, 93, 111, 0.35);
    flex-grow: 1;
  }

  @media screen and (min-width: 2200px) and (max-width: 2500px) {
    width: 80%;
  }

  @media screen and (min-width: 2500px) and (max-width: 3400px) {
    width: 70%;
  }

  @media screen and (min-width: 3400px) and (max-width: 3900px) {
    width: 50%;
  }

  @media screen and (min-width: 3900px) and (max-width: 5800px) {
    width: 40%;
  }

  @media screen and (min-width: 5810px) {
    width: 30%;
  }

  @media screen and (min-width: 413px) and (max-width: 992px) {
    .rebate-bar__q {
      order: 1;
    }
    .rebate-bar__points {
      order: 2;
    }
    .rebate-bar__projected-rebate {
      order: 3;
    }
    .rebate-bar__goal-1 {
      order: 4;
    }
    .rebate-bar__goal-2 {
      order: 5;
    }
    .rebate-bar__goal-3 {
      order: 6;
    }
    .rebate-bar__run-rate {
      order: 7;
    }
    .rebate-bar__target {
      order: 8;
    }
    .rebate-bar__custom {
      order: 9;
    }
  }

  @media screen and (max-width: 412px) {
    justify-content: center;

    .rebate-bar__q {
      order: 1;
    }
    .rebate-bar__projected-rebate {
      order: 2;
    }
    .rebate-bar__points {
      order: 3;
    }
    .rebate-bar__goal-1 {
      order: 4;
    }
    .rebate-bar__goal-2 {
      order: 5;
    }
    .rebate-bar__goal-3 {
      order: 6;
    }
    .rebate-bar__run-rate {
      order: 7;
    }
    .rebate-bar__target {
      order: 8;
    }
    .rebate-bar__custom {
      order: 9;
    }
  }
  @media screen and (min-width: 993px) {
    .rebate-bar__q {
      order: 1;
    }
    .rebate-bar__points {
      order: 2;
    }
    .rebate-bar__goal-1 {
      order: 3;
    }
    .rebate-bar__goal-2 {
      order: 4;
    }
    .rebate-bar__goal-3 {
      order: 5;
    }
    .rebate-bar__projected-rebate {
      order: 6;
    }
    .rebate-bar__run-rate {
      order: 7;
    }
    .rebate-bar__target {
      order: 8;
    }
    .rebate-bar__custom {
      order: 9;
    }

    .rebate-bar__run-rate {
      position: relative;
      &:before {
        content: '';
        /* border-left: 1px solid rgba(80, 93, 111, 0.35); */
        height: 70px;
        left: 0;
        top: calc(50% - 35px);
        position: absolute;
      }
    }
  }

  @media screen and (max-width: 767px) {
    ${(props) =>
      props.collapsed &&
      `
      .rebate-bar__content {
        display: none;
      }
      .rebate-bar__info-icon {
        display: none;
      }
    `};
  }
`;

export const MarketShareRebateBarContainer = styled.div<BarContainerProps>`
  background: white;
  box-shadow: 0 0 3px 0 rgba(80, 93, 111, 0.25);
  min-height: 50px;
  position: relative;
  margin: auto;

  top: 50px;
  position: relative;
  margin-top: -20px;

  .rebate-bar__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 20px;
    box-shadow: 0 0 1px 0 rgba(80, 93, 111, 0.35);
    flex-grow: 1;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    ${(props) =>
      props.collapsed &&
      `
      .rebate-bar__content {
        display: none;
      }
      .rebate-bar__info-icon {
        display: none;
      }
    `};
  }
`;

interface BarToggleProps {
  collapsed: boolean;
}

export const BarToggle = styled.div<BarToggleProps>`
  display: none;
  overflow: hidden;
  height: 50px;
  cursor: pointer;
  align-items: center;
  padding: 0 15px;

  ${(props) =>
    props.collapsed &&
    `
    height: 50px;
  `} @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;

    .dashboard-bar__toggle-label + .dashboard-bar__toggle-icon {
      margin-left: auto;
    }
  }
`;

export const BarCell = styled.div`
  text-align: center;
  width: 50%;
  padding-top: 25px;
  padding-bottom: 25px;

  @media screen and (min-width: 376px) and (max-width: 767px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    justify-content: center;
    width: 25%;
  }

  @media screen and (min-width: 993px) {
    padding: 25px 10px;
    text-align: center;
    width: auto;
    min-width: 95px;
  }
  @media screen and (min-width: 1366px) {
    padding: 25px;
  }
`;

export const MarketShareBarCell = styled.div`
  text-align: center;
  width: 50%;
  padding: 15px 0 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  @media screen and (min-width: 376px) and (max-width: 767px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
    width: 25%;
    padding: 15px 0 20px;
  }

  @media screen and (min-width: 992px) {
    padding: 15px 4px 20px;
    text-align: center;
    width: 16.66%;
  }
`;

export const RebateBarCell = styled.div`
  text-align: center;
  width: 50%;
  padding-top: 25px;
  padding-bottom: 25px;

  @media screen and (max-width: 412px) {
    &.rebate-bar__run-rate {
      position: relative;
      padding-top: 25px;
    }
    &.rebate-bar__run-rate,
    &.rebate-bar__target,
    &.rebate-bar__custom {
      width: 100%;
    }
  }

  @media screen and (min-width: 413px) and (max-width: 1365px) {
    justify-content: center;
    width: 33.33%;

    &.rebate-bar__run-rate,
    &.rebate-bar__target,
    &.rebate-bar__custom {
      position: relative;
      padding-top: 25px;
      align-self: flex-start;
    }
  }

  @media screen and (min-width: 1366px) {
    width: 160px;
  }
`;

export const BarCellLabel = styled.div<{ color?: string }>`
  font-family: 'open-sans-bold', sans-serif !important;
  font-size: 12px;
  color: ${(props: any) => (props.color ? props.color : '#505d6f')};
  letter-spacing: 1.5px;
  line-height: 14px;
  font-weight: bold;
  margin: 0 auto 2px;
  padding: 0 6px;
  max-width: 200px;
  @media screen and (max-width: 767px) {
    padding: 0 3px;
  }
`;

export const BarCellValue = styled(Text)`
  font-family: 'open-sans-bold', sans-serif !important;
  font-size: 24px;
  line-height: 30px;
  color: #505d6f;
  text-align: center;
  padding: 0 6px;

  @media screen and (max-width: 767px) {
    padding: 0 3px;
  }
`;

interface LoadingPillProps {
  small?: boolean;
}

export const LoadingPill = styled.div<LoadingPillProps>`
  margin: 0 auto 10px;
  width: 90%;
  height: 20px;
  border-radius: 18px;
  background-image: linear-gradient(to right, #fafafa 0%, #f6f6f6 100%);
  ${(props) =>
    props.small &&
    `
    height: 10px;
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

// CSS for the new rebate calculator
export const ProjectedRebateBarWrapper = styled.div`
  display: flex;
  .rebate-bar__content {
    width: 75%;
  }
  .rebate-bar__content.projected {
    width: 25%;
  }
  .rebate-bar-cell {
    width: 140px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    padding: 15px 0 10px;
    &.projected {
      width: 100%;
    }
  }
  @media screen and (max-width: 1365px) {
    flex-direction: column;
    justify-content: center;
    .rebate-bar__content {
      padding: 0;
    }
    .rebate-bar__content,
    .rebate-bar__content.projected {
      justify-content: center;
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    .rebate-bar-cell {
      width: 180px;
      padding: 15px 10px 20px;
    }
  }
  @media screen and (min-width: 992px) {
    .rebate-bar-cell {
      width: 20%;
    }
  }
`;
