import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  register,
  withContent,
  withTheme,
  withResource
} from "xcel-react-core";
import { getConfigurationProperty } from "xcel-config";
import { push } from "react-router-redux";
import { userLoginOAuth } from "./../app/redux/user.actions";
import { changeFirstVisitState } from "./../app/redux/auth.actions";
import AuthService from "../services/OAuthService";
import { toast } from "rsv8-components";

let logged = false;

const LoginRedirectCallback = ({
  actions: { userLoginOAuth, changeFirstVisitState },
  redirectPath,
  match,
  location,
  children,
  resource: { errorMessageText },
  className,
  dispatch,
  ...rest
}) => {
  let identityServerConfigData = getConfigurationProperty([
    "identityServerConfigData"
  ]);
  if (identityServerConfigData) {
    if (location.state === null || typeof location.state === "undefined") {
      if (!logged) {
        AuthService.userManager
          .signinRedirectCallback()
          .then(user => {
            changeFirstVisitState(false);
            userLoginOAuth(redirectPath ? redirectPath : "/home");
          })
          .catch(error => {
            toast({
              template: "Default",
              themeVariation: "error-text",
              message: errorMessageText
            });
            changeFirstVisitState(false);
            dispatch(push("/login"));
          });
        logged = true;
      }
    } else {
      dispatch(push(location.stat));
    }
  } else {
    dispatch(push("/login"));
  }

  return <></>;
};

const mapState = () => ({});

const mapDispatch = dispatch => ({
  actions: bindActionCreators(
    { userLoginOAuth, changeFirstVisitState },
    dispatch
  ),
  dispatch: dispatch
});

const mapContentToProps = getContent => ({
  redirectPath: getContent("redirectPath", {
    type: "none",
    label: "Redirect Path"
  })
});

const mapResourceToProps = getResource => ({
  resource: {
    errorMessageText: getResource(
      "login.authenticationFailed",
      "Authentication failed"
    )
  }
});

export default register("rsv8-auth/LoginRedirectCallback")(
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme(),
  connect(
    mapState,
    mapDispatch
  )
)(LoginRedirectCallback);