import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Button, Drawer } from 'rsv8-components';
import { reduxForm } from 'rsv8-forms';
import { NOMINEE_FILTER_FORM_NAME } from '../../constants';
import { nomineeFilter, nomineeReset } from '../../redux/actions/index';
import { nomineeSearchSelectors } from '../../redux/selectors/index';
import { Header } from '../Header/index';
import FilterField from './FilterField';
import { withResource } from 'xcel-react-core'

export interface FilterFormProps {
  // onSubmit: (v: any) => void;
}

interface Props extends FilterFormProps {
  handleSubmit: any;
  reset: () => void;
  handleReset: (values: any) => void;
  isLoading: boolean;
  filterFields: Array<string>;
  headerText: string;
  buttonText: string;
  applicationId: string;
}

const FilterForm: React.SFC<Props> = (props) => {
  const handleReset = () => {
    props.reset();
    props.handleReset(props.filterFields.reduce((acc, val) => ({ ...acc, [val]: '0' }), {}));
  };
  return (
    <Drawer start="md" buttonText="Filter">
      <Header text={props.headerText} />
      <form>
        {props.filterFields.map((field, i) => (
          <FilterField key={i} disabled={props.isLoading} name={field} />
        ))}
      </form>
      <Button onClick={handleReset}>{props.buttonText}</Button>
    </Drawer>
  );
};

const mapState = (state) => ({
  isLoading: nomineeSearchSelectors.getRequest(state).loading,
  filterFields: nomineeSearchSelectors.getNomineeSearchFilterNames(state)
});

// The nomineeFilter action directly becomes the onChange handler for the whole form
const mapDispatch = (dispatch, ownProps) => {
  const actions = bindActionCreators({ nomineeFilter, nomineeReset }, dispatch);
  let onChange = actions.nomineeFilter;
  if (ownProps.applicationId) {
      onChange = (values) => {
          return actions.nomineeFilter({...values, applicationId: ownProps.applicationId});
      }
  }
  return { onChange: onChange, handleReset: actions.nomineeReset };
};

const mapResource = (getResource) => ({
    headerText: getResource("teamNominationFilter.headerText", "Filter By:"),
    buttonText: getResource("teamNominationResetFilter.buttonText", "Reset Filters")
});

export default compose<any>(
  connect(
    mapState,
    mapDispatch
  ),
  reduxForm({ form: NOMINEE_FILTER_FORM_NAME, destroyOnUnmount: false }),
  withResource(mapResource)
)(FilterForm);
