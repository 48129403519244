export const GET_TRENDING_PRODUCTS_SUCCESS = 'rsv8-catalog/GET_TRENDING_PRODUCTS_SUCCESS';
export const GET_TRENDING_PRODUCTS_FAILURE = 'rsv8-catalog/GET_TRENDING_PRODUCTS_FAILURE';
export const GET_TRENDING_PRODUCTS_REQUEST = 'rsv8-catalog/GET_TRENDING_PRODUCTS_REQUEST';

export const GET_TRENDING_PRODUCT_DETAILS_SUCCESS = 'rsv8-catalog/GET_TRENDING_PRODUCT_DETAILS_SUCCESS';
export const GET_TRENDING_PRODUCT_DETAILS_FAILURE = 'rsv8-catalog/GET_TRENDING_PRODUCT_DETAILS_FAILURE';
export const GET_TRENDING_PRODUCT_DETAILS_REQUEST = 'rsv8-catalog/GET_TRENDING_PRODUCT_DETAILS_REQUEST';
export const SET_CURRENT_TRENDING = 'rsv8-catalog/SET_CURRENT_TRENDING';

export const GET_FAVORITE_PRODUCTS_SUCCESS = 'rsv8-catalog/GET_FAVORITE_PRODUCTS_SUCCESS';
export const GET_FAVORITE_PRODUCTS_FAILURE = 'rsv8-catalog/GET_FAVORITE_PRODUCTS_FAILURE';
export const GET_FAVORITE_PRODUCTS_REQUEST = 'rsv8-catalog/GET_FAVORITE_PRODUCTS_REQUEST';

export const GET_FAVORITES_PRODUCT_DETAILS_SUCCESS = 'rsv8-catalog/GET_FAVORITES_PRODUCT_DETAILS_SUCCESS';
export const GET_FAVORITES_PRODUCT_DETAILS_FAILURE = 'rsv8-catalog/GET_FAVORITES_PRODUCT_DETAILS_FAILURE';
export const GET_FAVORITES_PRODUCT_DETAILS_REQUEST = 'rsv8-catalog/GET_FAVORITES_PRODUCT_DETAILS_REQUEST';

export const GET_ACCOUNT_SUMMARY_SUCCESS = 'rsv8-catalog/GET_ACCOUNT_SUMMARY_SUCCESS';
export const GET_ACCOUNT_SUMMARY_FAILURE = 'rsv8-catalog/GET_ACCOUNT_SUMMARY_FAILURE';
export const GET_ACCOUNT_SUMMARY_REQUEST = 'rsv8-catalog/GET_ACCOUNT_SUMMARY_REQUEST';

export const SET_CURRENT_PRODUCT_SEARCH = 'rsv8-catalog/SET_CURRENT_PRODUCT_SEARCH';
export const SET_CURRENT_SEARCH_QUERY = 'rsv8-catalog/SET_CURRENT_SEARCH_QUERY';
export const RESET_SEARCH_RESULTS = 'rsv8-catalog/RESET_SEARCH_RESULTS';
export const SET_BRAND_CHANGE = 'rsv8-catalog/SET_BRAND_CHANGE';
export const UPDATE_SEARCH_RESULTS = 'rsv8-catalog/UPDATE_SEARCH_RESULTS';
export const SET_CATEGORY_CHANGE = 'rsv8-catalog/SET_CATEGORY_CHANGE';
export const SET_POINT_RANGE_CHANGE = 'rsv8-catalog/SET_POINT_RANGE_CHANGE';
export const RESET_SEARCH_FILTERS = 'rsv8-catalog/RESET_SEARCH_FILTERS';
export const SET_SEARCH_SORT = 'rsv8-catalog/SET_SEARCH_SORT';
export const RESET_SEARCH_SORT = 'rsv8-catalog/RESET_SEARCH_SORT';
export const RESET_SEARCH_TOTAL = 'rsv8-catalog/RESET_SEARCH_TOTAL';
export const RESET_POINT_RANGE_FILTER = 'rsv8-catalog/RESET_POINT_RANGE_FILTER';

export const SET_FAVORITES = 'rsv8-catalog/SET_FAVORITES';
export const FAVORITE_PRODUCTS_LOADED = 'rsv8-catalog/FAVORITE_PRODUCTS_LOADED';
export const ADD_UNDO_FAVORITE = 'rsv8-catalog/ADD_UNDO_FAVORITE';
export const RESET_UNDO_FAVORITE = 'rsv8-catalog/RESET_UNDO_FAVORITE';
export const REMOVE_UNDO_FAVORITE = 'rsv8-catalog/REMOVE_UNDO_FAVORITE';

export const SET_CART = 'rsv8-catalog/SET_CART';
export const SET_ORDER_USER = 'rsv8-catalog/SET_ORDER_USER';
export const SAVE_PROFILE_INFO = 'rsv8-catalog/SAVE_PROFILE_INFO';
export const UPDATE_USER_INFO = 'rsv8-catalog/UPDATE_USER_INFO';

export const CHECKOUT_TOGGLE = 'rsv8-catalog/CHECKOUT_TOGGLE';
export const BRANDS_TOGGLE = 'rsv8-catalog/BRANDS_TOGGLE';
export const CATEGORIES_TOGGLE = 'rsv8-catalog/CATEGORIES_TOGGLE';
export const SHIPPING_MODAL_TOGGLE = 'rsv8-catalog/SHIPPING_MODAL_TOGGLE';
export const POINTS_MODAL_TOGGLE = 'rsv8-catalog/POINTS_MODAL_TOGGLE';
