import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SolidGauge from 'highcharts/modules/solid-gauge';
import * as React from 'react';
import * as styles from './styles/style';

SolidGauge(Highcharts);

interface Props {
  labelColor: string;
  gaugeColor: string;
  completedGaugeColor: string;
  valueLabelSize: string;
  bottomLabelSize: string;
  dialMiddleText: string;
  chartWidgetSize: number;
  labelFont: string;
  title: string;
  currentValue: number;
  maxValue: number;
  maxValueFormatted: string | number;
}

const GaugeChart: React.FC<Props> = ({
  chartWidgetSize,
  labelColor,
  gaugeColor,
  completedGaugeColor,
  valueLabelSize,
  dialMiddleText,
  bottomLabelSize,
  labelFont,
  title,
  currentValue,
  maxValue,
  maxValueFormatted,
}) => {
  let mymax;
  if (maxValue === 0) {
    currentValue = null;
    mymax = 1;
  } else {
    mymax = maxValue;
  }

  const options = {
    chart: {
      type: 'solidgauge',
      height: '110%',
    },
    title: null,

    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          backgroundColor: 'transparent',
          innerRadius: '88%',
          outerRadius: '100%',
          borderWidth: 0,
          shape: 'arc',
        },
      ],
    },
    tooltip: {
      enabled: false,
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y:
            !!valueLabelSize && !!bottomLabelSize
              ? -1 * (Number(valueLabelSize.replace('px', '')) + Number(bottomLabelSize.replace('px', '')) - 2)
              : -chartWidgetSize / 4,
          align: 'center',
          borderWidth: 0,
          useHTML: true,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
    yAxis: [
      {
        min: 0,
        max: mymax,
        tickPosition: 'outside',
        lineColor: 'white',
        lineWidth: 0,
        minorTickPosition: 'outside',
        tickColor: 'transparent',
        minorTickColor: 'transparent',
        tickLength: 0,
        minorTickLength: 5,
        y: '1px',
        labels: {
          distance: 12,
          rotation: 'auto',
          enabled: false,
        },
        offset: 20,
        endOnTick: false,
      },
    ],
    credits: {
      enabled: false,
    },

    series: [
      {
        data: [
          {
            color: gaugeColor,
            radius: '114%',
            innerRadius: '98%',
            zIndex: 20,
            y: mymax,
          },
        ],
        dataLabels: {
          formatter: () => {
            // in this formatter we take your points and we display them
            return (
              '<div style="text-align:center; border-width:0!important;line-height: 1.25;">' +
              '<span style="font-size:' +
              valueLabelSize +
              ';font-family:' +
              labelFont +
              ';color:' +
              labelColor +
              ';line-height: 1.17;letter-spacing:1.5px;">' +
              maxValueFormatted +
              '</span><br/>' +
              '<span style="font-size:' +
              bottomLabelSize +
              ';font-family:' +
              labelFont +
              ';color:' +
              labelColor +
              ';line-height: 1.25;letter-spacing:0.5px;">' +
              dialMiddleText +
              '</span><br/>' +
              '</div>'
            );
          },
        },
      },
      {
        data: [
          {
            color: completedGaugeColor,
            radius: '114%',
            innerRadius: '98%',
            y: currentValue,
          },
        ],
        dataLabels: {
          useHtml: false,
          enabled: false,
        },
      },
    ],
  };

  return (
    <styles.ChartContainer className="row" size={chartWidgetSize}>
      <HighchartsReact highcharts={Highcharts} options={options} />

      <styles.GaugeTitle size={16} font={labelFont} className="col-lg-24 col-md-24 col-sm-24">
        {title.toUpperCase()}
      </styles.GaugeTitle>
    </styles.ChartContainer>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.currentValue === nextProps.currentValue && prevProps.maxValue === nextProps.maxValue;
}

export default React.memo(GaugeChart, areEqual);
