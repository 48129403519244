import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux';

const achievementModule: ReduxModule = createReduxModule({
  name: 'rsv8-achievement',
  reducers
});

reduxObserver.addModule(achievementModule);
export default achievementModule;
