import { UserProfileModel } from 'xcel-api-generator/dist/user';
import { build, createTypeSelector } from 'xcel-redux-orm';
import {
  AUTH_USER_STATE,
  REDEEM_STATE,
  ROOT_STATE,
  USER_PROFILE_JSON_TYPE
  } from '../../constants';

export const getUserInfo = (state) => state[ROOT_STATE][REDEEM_STATE].userInfo;
export const getUserProfile = (state) => build(state, USER_PROFILE_JSON_TYPE, state[AUTH_USER_STATE].userId);

export const userSelector = createTypeSelector<UserProfileModel>(USER_PROFILE_JSON_TYPE);
export const getLastUser = (state) => userSelector.selectLast(state, 'getUser')[0];
