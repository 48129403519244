import * as R from 'ramda';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Loader } from 'rsv8-components';
import * as smoothscroll from 'smoothscroll-polyfill';
import { register, withResource } from 'xcel-react-core';
import { getValue } from 'xcel-util';
import DigitalCardInfo from '../../components/Checkout/DigitalCardInfo/DigitalCardInfo';
import EditContactInfo from '../../components/Checkout/EditContactInfo/EditContactInfo';
import EditInfo from '../../components/Checkout/EditInfo/EditInfo';
import GyftInfo from '../../components/Checkout/GyftInfo/GyftInfo';
import ItemReview from '../../components/Checkout/ItemReview/ItemReview';
import ShippingInfo from '../../components/Checkout/ShippingInfo/ShippingInfo';
import {
  deleteCartById,
  getCart,
  getUser,
  placeOrder,
  saveInfo
  } from '../../redux/actions';
import { getCartProducts, getCurrentAccountSummary, getUserInfo } from '../../redux/selectors';
import {
  BackLink,
  ButtonLoader,
  CheckoutErrors,
  CheckoutHeading,
  CheckoutIcon,
  OrderButton,
  PlaceOrderContainer,
  PointTotal
  } from './styles';
import { CheckoutProps, CheckoutState } from './types';

const validateEmail = (email) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

class Checkout extends React.Component<CheckoutProps, CheckoutState> {
  state = {
    editingEmail: false,
    editingInfo: false,
    errors: {
      contact: [],
      personal: []
    },
    orderPlaced: false,
    removeDisabled: false
  };
  errorRef = React.createRef();
  formRef = React.createRef<Col>();

  componentDidMount() {
    this.props.actions.getUser();
    this.props.actions.getCart();
    smoothscroll.polyfill();
  }

  handleEditingEmail = () => {
    this.setState({ editingEmail: !this.state.editingEmail });
  };

  isEmailFieldEnabled = () => {
    return this.state.editingEmail;
  };

  handleEditingInfo = () => (e) => {
    this.setState({ editingInfo: !this.state.editingInfo });
  };

  findErrors = (values, contactValidation = false, shippingValidation = false) => {
    const {
      stateLabel,
      countryLabel,
      postalCodeLabel,
      cityLabel,
      lastNameLabel,
      firstNameLabel,
      cartProducts,
      phoneLabel,
      emailLabel,
      addressOneLabel,
      altEmailLabel,
      CPFLabel,
      userProfile,
      gyftEmailLabel,
      digitalCardEmailLabel
    } = this.props;

    let errors: any = {
      contact: [],
      personal: []
    };

    if (contactValidation) {
      if (!values.phone) {
        errors.contact.push(phoneLabel);
      }
      if (!values.emailAddress || validateEmail(values.emailAddress)) {
        errors.contact.push(emailLabel);
      }
      if (values.altEmailAddress && validateEmail(values.altEmailAddress)) {
        errors.contact.push(altEmailLabel);
      }
      if (userProfile.isCPFRequired && !values.cpf) {
        errors.contact.push(CPFLabel);
      }
    } else {
      errors.contact = this.state.errors.contact || [];
    }

    if (cartProducts.some((product) => product.isDigitalCard && product.isGyftProduct) && !shippingValidation) {
      if (!values.gyftEmail || (values.gyftEmail && validateEmail(values.gyftEmail))) {
        errors.contact.push(gyftEmailLabel);
      }
      if (!values.gyftConfirmation) {
        errors.contact.push('Gyft Email Confirmation');
      }
    }

    if (cartProducts.some((product) => product.isDigitalCard && !product.isGyftProduct) && !shippingValidation) {
      if (!values.digitalCardEmail || (values.digitalCardEmail && validateEmail(values.digitalCardEmail))) {
        errors.contact.push(digitalCardEmailLabel);
      }
      if (!values.digitalCardConfirmation) {
        errors.contact.push('eGift Card Email Confirmation');
      }
    }

    // if there are physical items in the cart
    if (cartProducts.some((product) => !product.isDigitalCard)) {
      if (!values.firstName) {
        errors.personal.push(firstNameLabel);
      }
      if (!values.lastName) {
        errors.personal.push(lastNameLabel);
      }
      if (!values.addressOne) {
        errors.personal.push(addressOneLabel);
      }
      if (!values.city) {
        errors.personal.push(cityLabel);
      }
      if (userProfile.countryId === 226 && !values.state) {
        errors.personal.push(stateLabel);
      }
      if (!values.country) {
        errors.personal.push(countryLabel);
      }
      if (!values.postalCode) {
        errors.personal.push(postalCodeLabel);
      }
    }
    if (errors.personal.length > 0 || errors.contact.length > 0) {
      this.setState({ errors }, () => {
        const domNode = ReactDOM.findDOMNode(this.errorRef.current as any) as HTMLElement;
        domNode.scrollIntoView({ behavior: 'smooth' });
      });
    }
    return errors;
  };

  saveEditInfo = (values) => {
    const errors: any = this.findErrors(values, false, true);
    if (errors.personal.length === 0) {
      this.props.actions.saveInfo(values);
      this.setState((prevState) => ({
        editingInfo: !this.state.editingInfo,
        errors: {
          ...prevState.errors,
          personal: []
        }
      }));
    }
  };

  handleOrder = () => {
    const errors = this.findErrors(this.props.userProfile, true);

    if (errors.personal.length === 0 && errors.contact.length === 0) {
      this.setState({ errors: { contact: [], personal: [] }, orderPlaced: true, removeDisabled: true });
      this.props.actions.placeOrder(this.props.history);
    }
  };

  removeFromCart = (product) => (e) => {
    e.preventDefault();
    this.props.actions.deleteCartById(product).catch((err) => {
      if (err.response.status === 404) {
        this.props.history.push({
          pathname: '/catalog'
        });
      }
    });
  };

  formOrder = (key: string) => {
    // parent = rendered elements in form
    //   -Slices place order button object as that is not numbered
    //   -Filters unrendered false elements
    const renderedElements = getValue(this.formRef, 'current.props.children.props.children');
    const parent: object[] = renderedElements && renderedElements.slice(0, -1).filter((el) => el);
    // Find array position of form section
    let position: number = parent && R.findIndex(R.propEq('key', key))(parent);

    if (position !== null && position !== -1) {
      return position + 1; // Add one to array index
    } else {
      // Will never show up
      return 0;
    }
  };

  render() {
    const {
      cartProducts,
      checkoutIcon,
      pointTotalLabel,
      pointsAbbrevLabel,
      errorLabel,
      checkoutTitle,
      orderButtonText,
      backToCartLabel,
      userProfile,
      accountSummary
    } = this.props;

    return (
      <Grid themeVariation="page-wrapper">
        <Row>
          <Col xs={24} smHidden={true} xsHidden={true}>
            <BackLink to="/catalog/cart">
              <Icon name="long-arrow-left" />
              {backToCartLabel}
            </BackLink>
          </Col>
        </Row>
        <Row>
          <Col xs={20} xsOffset={2} sm={22} smOffset={1}>
            <CheckoutIcon src={checkoutIcon} />
            <CheckoutHeading>{checkoutTitle}</CheckoutHeading>
          </Col>
        </Row>
        {(this.state.errors.contact.length > 0 || this.state.errors.personal.length > 0) && (
          <Row>
            <Col xs={24}>
              <CheckoutErrors bsStyle="danger" ref={this.errorRef}>
                <p>{errorLabel}</p>
                <ol>
                  {this.state.errors.personal.map((error, idx) => (
                    <li key={`${error}-${idx}`}>{error}</li>
                  ))}
                  {this.state.errors.contact.map((error, idx) => (
                    <li key={`${error}-${idx}`}>{error}</li>
                  ))}
                </ol>
              </CheckoutErrors>
            </Col>
          </Row>
        )}
        {cartProducts && Object.keys(userProfile).length > 0 ? (
          <Row>
            <Col xs={22} xsOffset={1} sm={16} smOffset={4} md={12} mdOffset={6} lg={8} lgOffset={8} ref={this.formRef}>
              {this.state.editingInfo ? (
                <EditInfo userProfile={userProfile} saveInfo={this.saveEditInfo} toggleEdit={this.handleEditingInfo} />
              ) : (
                <React.Fragment>
                  {cartProducts.some((product) => !product.isDigitalCard) && (
                    <ShippingInfo
                      formCount={this.formOrder('ShippingInfo')}
                      userProfile={userProfile}
                      toggleEdit={this.handleEditingInfo}
                      key="ShippingInfo"
                    />
                  )}
                  {cartProducts.some((product) => product.isDigitalCard && product.isGyftProduct) && (
                    <GyftInfo formCount={this.formOrder('GyftInfo')} userProfile={userProfile} key="GyftInfo" />
                  )}
                  {cartProducts.some((product) => product.isDigitalCard && !product.isGyftProduct) && (
                    <DigitalCardInfo
                      formCount={this.formOrder('DigitalCardInfo')}
                      userProfile={userProfile}
                      key="DigitalCardInfo"
                    />
                  )}
                  <EditContactInfo
                    userProfile={userProfile}
                    formCount={this.formOrder('ContactInfo')}
                    enableEmailField={this.handleEditingEmail}
                    isEmailFieldEnabled={this.isEmailFieldEnabled}
                    key="ContactInfo"
                  />
                  <ItemReview
                    products={cartProducts}
                    removeFromCart={this.removeFromCart}
                    formCount={this.formOrder('ItemReview')}
                    removeDisabled={this.state.removeDisabled}
                    key="ItemReview"
                  />
                  <PlaceOrderContainer>
                    {this.state.orderPlaced ? (
                      <ButtonLoader />
                    ) : (
                      <OrderButton onClick={this.handleOrder}>{orderButtonText}</OrderButton>
                    )}
                    <PointTotal>{`${pointTotalLabel} ${accountSummary.pointsInCart.total} ${pointsAbbrevLabel}`}</PointTotal>
                  </PlaceOrderContainer>
                </React.Fragment>
              )}
            </Col>
          </Row>
        ) : (
          <Loader />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfile: getUserInfo(state),
  cartProducts: getCartProducts(state).filter(Boolean),
  accountSummary: getCurrentAccountSummary(state)
});

const mapResourceToProps = (getResource) => ({
  addressOneLabel: getResource('catalog.checkout.addressOneLabel', 'Address'),
  backToCartLabel: getResource('catalog.checkout.backToCartLabel', 'Back to Shopping Cart'),
  checkoutIcon: getResource('catalog.checkoutIcon', '/clientassets/images/shop-checkout.svg'),
  checkoutTitle: getResource('catalog.checkoutTitle', 'Checkout'),
  countryLabel: getResource('catalog.checkout.countryLabel', 'Country'),
  errorLabel: getResource('catalog.checkout.errorLabel', 'You must complete the following required fields:'),
  firstNameLabel: getResource('catalog.checkout.firstNameLabel', 'First Name'),
  lastNameLabel: getResource('catalog.checkout.lastNameLabel', 'Last Name'),
  orderButtonText: getResource('catalog.orderButtonText', 'Place Order'),
  phoneLabel: getResource('catalog.checkout.phoneLabel', 'Phone'),
  emailLabel: getResource('catalog.checkout.emailLabel', 'Email'),
  altEmailLabel: getResource('catalog.checkout.altEmailLabel', 'Alternative Email'),
  postalCodeLabel: getResource('catalog.checkout.postalCodeLabel', 'Postal Code'),
  pointsAbbrevLabel: getResource('catalog.checkout.pointsAbbrevLabel', 'pts.'),
  pointTotalLabel: getResource('catalog.checkout.pointTotalLabel', 'Point Total:'),
  stateLabel: getResource('catalog.checkout.stateLabel', 'State/Province'),
  cityLabel: getResource('catalog.checkout.cityLabel', 'City'),
  CPFLabel: getResource('catalog.checkout.CPFLabel', 'CPF'),
  gyftEmailLabel: getResource('catalog.checkout.gyftPrimaryEmailLabel', 'Gyft Email'),
  digitalCardEmailLabel: getResource('catalog.checkout.digitalCardEmailLabel', 'eGift Card Email')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getUser,
      placeOrder,
      saveInfo,
      deleteCartById,
      getCart
    },
    dispatch
  )
});

const compose = register('rsv8-catalog/Checkout');

export default compose(connect(mapStateToProps, mapDispatchToProps), withResource(mapResourceToProps))(Checkout);
