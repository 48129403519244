import * as React from 'react';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';

const ListItem = styled.li`
  list-style-type: ${(props: Props) => (props.listStyle1 ? props.listStyle1 : 'disc')};
  li {
    list-style-type: ${(props: Props) => (props.listStyle2 ? props.listStyle2 : 'disc')};
    li {
      list-style-type: ${(props: Props) => (props.listStyle3 ? props.listStyle3 : 'disc')};
      li {
        list-style-type: 'disc';
      }
    }
  }
`;

const tokenRegex = /^ *\*/g;
const addToChildren = (parent, item, count = 0) => {
  if (count <= 1) {
    return parent.children.push(item);
  } else {
    const nextParent = parent.children[parent.children.length - 1] || parent;
    return addToChildren(nextParent, item, count - 1);
  }
};
const parseText = (text) => {
  const list = text
    .split('\n')
    .map((line) => ({ text: line, children: [] }))
    .reduce((items, item, index) => {
      const parent = items[items.length - 1];

      if (tokenRegex.test(item.text) && parent !== undefined) {
        const match = item.text.match(tokenRegex);
        const count = match && Math.floor(match[0].replace('*', '').length / 2);
        item.text = item.text.replace(tokenRegex, '');
        if (!count) {
          items.push(item);
        } else {
          addToChildren(parent, item, count);
        }
      } else {
        item.text = item.text.replace(tokenRegex, '');
        items.push(item);
      }
      return items;
    }, []);
  return list;
};

const ListGenerator = (props) => {
  const ListType = props.listType || 'ul';
  return (
    <ListType>
      {props.listItems.map((item, index) => {
        return (
          <ListItem {...props} key={index}>
            <span dangerouslySetInnerHTML={{ __html: item.text }} />
            {item.children && item.children.length > 0 && (
              <ListGenerator listType={props.listType} listItems={item.children} />
            )}
          </ListItem>
        );
      })}
    </ListType>
  );
};

const DefinitionGenerator = (props) => {
  return (
    <dl>
      {props.listItems.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <dt key={index}>
              <span dangerouslySetInnerHTML={{ __html: item.text }} />
            </dt>
            {item.children &&
              item.children.length > 0 &&
              item.children.map((childItem, childIndex) => {
                return (
                  <dd key={childIndex}>
                    <span dangerouslySetInnerHTML={{ __html: childItem.text }} />
                  </dd>
                );
              })}
          </React.Fragment>
        );
      })}
    </dl>
  );
};

export type Props = {
  listItems?: string;
  listStyle1?: string;
  listStyle2?: string;
  listStyle3?: string;
  listType?: string;
  className?: string;
};

class List extends React.Component<Props> {
  render() {
    const { listItems, className, ...rest } = this.props;
    const isEditing = this.props['data-cms-editing'];
    return (
      <div className={className}>
        {listItems ? (
          this.props.listType === 'dl' ? (
            <DefinitionGenerator {...rest} listItems={parseText(listItems)} />
          ) : (
            <ListGenerator {...rest} listItems={parseText(listItems)} />
          )
        ) : (
          isEditing && <span>No List Items Found</span>
        )}
      </div>
    );
  }
}

const listStyleOptions = [
  { label: 'disc', value: 'disc' },
  { label: 'circle', value: 'circle' },
  { label: 'square', value: 'square' },
  { label: 'numbers', value: 'decimal' },
  { label: 'uppercase letters', value: 'upper-alpha' },
  { label: 'lowercase letters', value: 'lower-alpha' },
  { label: 'uppercase roman', value: 'upper-roman' },
  { label: 'lowercase roman', value: 'lower-roman' }
];

const mapContentToProps = (getContent) => ({
  listType: getContent('listType', {
    type: 'select',
    label: 'List Type',
    options: [
      { label: 'ordered', value: 'ol' },
      { label: 'unordered', value: 'ul' },
      { label: 'definition', value: 'dl' }
    ]
  }),
  listStyle1: getContent('listStyle1', {
    type: 'select',
    label: '1st Level Style',
    options: listStyleOptions
  }),
  listStyle2: getContent('listStyle2', {
    type: 'select',
    label: '2nd Level Style',
    options: listStyleOptions
  }),
  listStyle3: getContent('listStyle3', {
    type: 'select',
    label: '3rd Level Style',
    options: listStyleOptions
  }),
  listItems: getContent('listItems', { type: 'textarea', width: 'full', height: '200px', label: 'List Items' })
});

export default register('rsv8-components/List')(
  withContent(mapContentToProps),
  withTheme(['a', 'ul', 'ol', 'dl', 'li', 'dt', 'dd'])
)(List);
