import * as events from '../events';
const hoverReducer = (state: any = { active: undefined, last: undefined }, action: any) => {
  let { type, payload } = action;
  switch (type) {
    case events.HOVER_ACTIVE_SET:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default hoverReducer;
