import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Amount, RebateTimePeriod } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getMarketSharePercent } from '../../../utils';
import { getSummary } from '../../redux/actions';
import { currentTimePeriodSelector, dashboardSelector, performanceAmountSelector } from '../../redux/selectors';

const StyledTable = styled.table`
  margin: 0 auto;
  border-collapse: separate;
  border-top: 1px solid #b2b2b2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  td {
    width: 50%;
    border-right: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
  }

  tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`;

const MarketShare: React.FC<Props> = ({
  baselineLabel,
  currentLabel,
  growthLabel,
  baseline,
  current,
  yoyVariance,
  currentTimePeriod,
  className,
}) => {
  if (!currentTimePeriod || !baseline || !current || !yoyVariance) return null;

  return (
    <StyledTable className={className}>
      <tbody>
        <tr>
          <td className="share-label">
            <div dangerouslySetInnerHTML={{ __html: baselineLabel }} />
          </td>

          <td className="share-value">{getMarketSharePercent(baseline, '--')}</td>
        </tr>

        <tr>
          <td className="share-label">
            {currentTimePeriod.name}
            <br />
            {currentLabel}
          </td>

          <td className="share-value">{getMarketSharePercent(current, '--')}</td>
        </tr>

        <tr>
          <td className="share-label">
            {currentTimePeriod.name}
            <br />
            {growthLabel}
          </td>

          <td className="share-value">{getMarketSharePercent(yoyVariance, '--')}</td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

const mapContentToProps = (getContent) => ({
  baselineLabel: getContent('baselineLabel', { type: 'html', label: 'Baseline Label' }),
  currentLabel: getContent('currentLabel', { type: 'html', label: 'Current Label' }),
  growthLabel: getContent('growthLabel', { type: 'html', label: 'Growth Label' }),
});

const mapStateToProps = (state, ownProps) => ({
  baseline: performanceAmountSelector(state, 'Baseline'),
  current: performanceAmountSelector(state, 'Current'),
  yoyVariance: performanceAmountSelector(state, 'YOYVariance'),
  currentTimePeriod: currentTimePeriodSelector(state),
  ownProps,
});

interface StateProps {
  baseline: Amount;
  current: Amount;
  yoyVariance: Amount;
  currentTimePeriod: RebateTimePeriod;
}
interface ContentProps {
  baselineLabel: string;
  currentLabel: string;
  growthLabel: string;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('rsv8-alcon/MarketShare')(
  connect(mapStateToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withContent(mapContentToProps),
  withTheme()
)(MarketShare);
