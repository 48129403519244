import * as React from 'react';
import styled from 'styled-components';
import { register, withResource, withTheme } from 'xcel-react-core';
import { Button } from '../../../components/Button';
import { AnimatedCircle } from '../../../components/Loader';
import DataTable from './DataTable';
import ResultCountText from './ResultCountText';

const CenterContent = styled.div`
  text-align: center;
`;
const OverlayLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;
const DataTableDefault = (props) => {
  const { sort: onSort, defaultSort, data, emptyIcon, emptyText, loading, pagination } = props;
  return (
    <div>
      {loading && (
        <OverlayLoader>
          <AnimatedCircle />
        </OverlayLoader>
      )}
      {data.length !== 0 && <ResultCountText currentCount={props.data.length} totalCount={props.pagination.totalRecords} />}
      <DataTable
        onSort={onSort}
        defaultSort={defaultSort}
        data={data}
        condensed={false}
        hover={true}
        emptyIcon={emptyIcon}
        emptyText={emptyText}
        loading={loading}
      >
        {props.children}
      </DataTable>
      {!pagination.isLastPage &&
        data.length !== 0 && (
          <CenterContent>
            <Button themeVariation="primary" onClick={props.loadMore}>
              {props.labels.loadMore}
            </Button>
          </CenterContent>
        )}
    </div>
  );
};

const mapResource = (getResource) => ({
  labels: {
    loadMore: getResource('DataTableDefault.loadMore', 'Load More')
  }
});

const selectors = ['.placeholder-banner', '.result-count-text', 'hr', 'table', 'tr', 'th', 'td'];
export default register('rsv8-components/DataTableDefault')(withResource(mapResource), withTheme(selectors))(
  DataTableDefault
);
