import styled from 'styled-components';

interface Props {
  index: string;
}

const FabButton = styled.button<Props>`
  position: fixed !important;
  bottom: 2em;
  right: 3em;
  border-radius: 100% !important;
  height: 60px;
  width: 60px;
  i {
    font-size: 28px;
  }
  z-index: 5000;
  ${props => {
    return props.index && `transform: translateX(-${parseInt(props.index, 10) * 100}%);`;
  }};
` as any;
export default FabButton;
