import { promiseDispatcher } from 'xcel-react-core';
import { customFieldApi } from '../../api';
import * as types from './types';

const getFormKeys = type => customFieldApi.getCustomfieldsByFormtype({ formtype: type }, {});

const getFormKeysSuccess = (response, key) => ({
  type: types.GET_FORM_KEYS_SUCCESS,
  payload: { response: response.map(o => o.key), key }
});

export default promiseDispatcher(getFormKeys, {
  success: getFormKeysSuccess,
  failure: types.GET_FORM_KEYS_FAILURE
});
