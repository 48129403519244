export const ACHIEVEMENT_PRODUCT_DETAILS_TOGGLE_MODAL = 'rsv8-achievement/ACHIEVEMENT_PRODUCT_DETAILS_TOGGLE_MODAL';
export const ACHIEVEMENT_ORDER_DETAILS_TOGGLE_MODAL = 'rsv8-achievement/ACHIEVEMENT_ORDER_DETAILS_TOGGLE_MODAL';

export const SET_FAVORITES = 'rsv8-achievement/SET_FAVORITES';
export const FAVORITE_PRODUCTS_LOADED = 'rsv8-achievement/FAVORITE_PRODUCTS_LOADED';
export const ADD_UNDO_FAVORITE = 'rsv8-achievement/ADD_UNDO_FAVORITE';
export const RESET_UNDO_FAVORITE = 'rsv8-achievement/RESET_UNDO_FAVORITE';
export const REMOVE_UNDO_FAVORITE = 'rsv8-achievement/REMOVE_UNDO_FAVORITE';

export const SET_ORDER_INFO = 'rsv8-achievement/SET_ORDER_INFO';
export const SET_SHARE_PREVIEW = 'rsv8-achievement/SET_SHARE_PREVIEW';

export const SET_USER_INFO = 'rsv8-achievement/SET_USER_INFO';
export const SAVE_PROFILE_INFO = 'rsv8-achievement/SAVE_PROFILE_INFO';
export const UPDATE_USER_INFO = 'rsv8-achievement/UPDATE_USER_INFO';
