import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { isCartDisabled } from '../../../utils/cart';
import OrderHistoryContext from '../OrderHistoryContext';
import {
  BrandLink,
  BuyAgainButton,
  ContentOption,
  ContentQuantity,
  ContentTitle,
  OrderContent,
  OrderInfoTable,
  OrderProduct,
  ProductDescription,
  ProductImage,
  ProductInfo,
  ProductName,
  StatusButton
  } from '../styles';

const Product: React.SFC<any> = ({
  product,
  optionsLabel,
  quantityLabel,
  pointValueLabel,
  pointValueAbbrevLabel,
  statusLabel,
  buyAgainLabel,
  downloadLabel,
  contactUsForDetails
}) => (
  <OrderHistoryContext.Consumer>
    {({ accountSummary, toggleShippingModal, addToCart, addCartButtonDisabled, products, cartProducts }) => (
      <OrderProduct className="order-body">
        <ProductInfo>
          <div>
            <ProductImage imageurl={product.productLargeImageUrl} />
          </div>
          <ProductDescription>
            <BrandLink to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
              {product.manufacturerName}
            </BrandLink>
            <ProductName
              themeVariation="text-link"
              to={`/catalog/product/${encodeURIComponent(btoa(product.productId))}`}
            >
              {product.productName}
            </ProductName>
          </ProductDescription>
        </ProductInfo>
        <OrderInfoTable>
          {product.productOptionName && (
            <OrderContent>
              <ContentTitle>
                <Text themeVariation="tertiary-text-sm">{optionsLabel}</Text>
              </ContentTitle>
              <ContentOption>
                <Text>{product.productOptionName}</Text>
              </ContentOption>
            </OrderContent>
          )}
          <OrderContent>
            <ContentTitle>
              <Text themeVariation="tertiary-text-sm">{quantityLabel}</Text>
            </ContentTitle>
            <ContentQuantity>
              <Text>{product.quantity}</Text>
            </ContentQuantity>
          </OrderContent>
          <OrderContent>
            <ContentTitle>
              <Text themeVariation="tertiary-text-sm">{pointValueLabel}</Text>
            </ContentTitle>
            <Text>{product && product.productPoints && `${product.productPoints} ${pointValueAbbrevLabel}`}</Text>
          </OrderContent>
          <OrderContent>
            <ContentTitle>
              <Text themeVariation="tertiary-text-sm">{statusLabel}</Text>
            </ContentTitle>
            {product.shipCompanyName ? (
              <StatusButton
                themeVariation="primary-link"
                onClick={() => {
                  toggleShippingModal(product, products.shippingInfo);
                }}
              >
                {product.status}
              </StatusButton>
            ) : product.directLinkUrl ? (
              <Link href={product.directLinkUrl} target="_blank">
                {downloadLabel}
              </Link>
            ) : (
              <Text>{product.status}</Text>
            )}
          </OrderContent>
          <OrderContent>
            {product.productPoints > 0 ? (
              <BuyAgainButton
                disabled={isCartDisabled(product, accountSummary)}
                onClick={() => addToCart(product, product.productOptionId)}
              >
                {buyAgainLabel}
              </BuyAgainButton>
            ) : (
              <Link themeVariation="primary" href={`${window.location.search}#zero-point`}>
                {contactUsForDetails}
              </Link>
            )}
          </OrderContent>
        </OrderInfoTable>
      </OrderProduct>
    )}
  </OrderHistoryContext.Consumer>
);

const mapResourceToProps = (getResource) => ({
  optionsLabel: getResource('catalog.orderHistory.optionsLabel', 'Options'),
  quantityLabel: getResource('catalog.orderHistory.quantityLabel', 'Quantity'),
  pointValueLabel: getResource('catalog.orderHistory.pointValueLabel', 'Point Value'),
  pointValueAbbrevLabel: getResource('catalog.orderHistory.pointValueAbbrevLabel', 'pts.'),
  buyAgainLabel: getResource('catalog.orderHistory.buyAgainLabel', 'Buy Again'),
  statusLabel: getResource('catalog.orderHistory.statusLabel', 'Status'),
  downloadLabel: getResource('catalog.orderHistory.downloadLabel', 'Download'),
  contactUsForDetails: getResource('catalog.orderHistory.contactUsForDetails', 'Contact Us for Details')
});

export default compose<any>(connect(), withResource(mapResourceToProps))(Product) as any;
