import * as React from 'react';
import { HeaderTwo, Tab, Tabs } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import IndividualPendingApprovalTable from './IndividualPendingApprovalTable';
import TeamPendingApprovalTable from './TeamPendingApprovalTable';

const NominationHistory: React.SFC<ReturnType<typeof mapResource>> = (props) => {
  return (
    <Tabs defaultActiveKey={0}>
      <Tab eventKey={0} title={props.individualLabel}>
        <HeaderTwo>{props.individualHeader}</HeaderTwo>
        <IndividualPendingApprovalTable />
      </Tab>
      <Tab eventKey={1} title={props.teamLabel}>
        <HeaderTwo>{props.teamHeader}</HeaderTwo>
        <TeamPendingApprovalTable />
      </Tab>
    </Tabs>
  );
};

const mapResource = (getResource) => ({
  teamLabel: getResource('nominationActivity.teamTabLabel', 'Team'),
  individualLabel: getResource('nominationActivity.individualTabLabel', 'Individual'),
  teamHeader: getResource('nominationActivity.pendingApprovalTeamHeader', 'Pending Team Approvals'),
  individualHeader: getResource('nominationActivity.pendingApprovalIndividualHeader', 'Pending Individual Approvals')
});

export default withResource(mapResource)(NominationHistory) as React.ComponentType<{}>;
