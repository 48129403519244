import * as React from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { ContentVersionModel } from 'xcel-api-generator/dist/social';
import { contentActions, socialActions } from '../../../../redux/actions';
import { socialSelector } from '../../../../redux/selectors';
import { sortVersions } from '../../../../util';

const VersionFieldStyle = styled.div`
  & > div {
    display: inline-block;
    width: calc(100% - 65px);
  }
`;

export interface Props {
  activityId: string;
  removableVersion: boolean;
  version: ContentVersionModel;
  versions: ContentVersionModel[];
  removeVersion: any;
  setEditVersion: any;
  setEdit: any;
}

class VersionField extends React.Component<Props> {
  versionOptions() {
    const { versions = [] } = this.props;

    return versions.map((version, index) => ({
      // formating versions to options
      label: this.versionToLabel(version, versions.length - index),
      value: version.id
    }));
  }

  versionToLabel(versionItem: any, index: number) {
    const dateLabel = this.dateToLabel(versionItem);

    return `(${index})[${versionItem.publishStatus}] ${dateLabel}`;
  }

  dateToLabel({ updatedDateTimeUtc, updatedBy = '---' }: any) {
    const date = updatedDateTimeUtc && new Date(updatedDateTimeUtc + 'Z');
    const day = date
      ? date.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })
      : '--/--/--';
    const time = date
      ? date.toLocaleTimeString('en-us', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      })
      : '--:--:-- --';

    return `${day} ${time} (${updatedBy})`;
  }

  deleteVersion = () => {
    const { activityId, version, removeVersion } = this.props;

    removeVersion(activityId, version.id);
  };

  onChangeVersion = (versionId) => {
    const { versions, setEditVersion } = this.props
    let pickedVersion;
    for (let i = 0; i < versions.length; i++) {
      if (versionId === versions[i].id) {
        pickedVersion = versions[i]
      } else {
        continue
      }
    }

    setEditVersion(pickedVersion)
  };

  render() {
    const { version = {} as ContentVersionModel, removableVersion } = this.props;

    return (
      <VersionFieldStyle>
        <ReactField
          component="bs-select"
          label="Versions"
          options={this.versionOptions()}
          onChange={this.onChangeVersion}
          value={version.id}
        />
        <Button bsStyle="default" title="delete version" onClick={this.deleteVersion} disabled={!removableVersion}>
          <Icon name="trash" tag="i" />
        </Button>
      </VersionFieldStyle>
    );
  }
}

type StateProps = Pick<Props, 'versions' | 'removableVersion'>;
type DispatchProps = Pick<Props, 'removeVersion' | 'setEditVersion' | 'setEdit'>;

const getVersionList = (versions) => {
  const list = versions || [];
  if (versions instanceof Array) {
    return list.sort(sortVersions);
  }
  return [versions];
};

const mapStateToProps = (state: any, props: Props): StateProps => {
  const versions = getVersionList(socialSelector.versions(state));
  const couldRemoveVersion = socialSelector.validVersionToRemove(props.version);

  return {
    versions,
    removableVersion: couldRemoveVersion && versions.length > 1
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      removeVersion: socialActions.removeVersion,
      setEditVersion: socialActions.setEditVersion,
      setEdit: contentActions.setEdit
    },
    dispatch
  );

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(VersionField);
