import * as React from 'react';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
import AutoScroll from '../AutoScroll';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import BaseCollapse, { BaseCollapseProps } from './BaseCollapse';

const FloatIcon = styled(Icon as any)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  margin-top: 0;
` as typeof Icon;

const CollapseHeader = styled.div`
  position: relative;
  cursor: pointer;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px !important;
  }
`;

export type CollapseProps = { title: React.ReactNode; disableAutoScroll: boolean } & BaseCollapseProps;

class Collapse extends React.Component<CollapseProps> {
  renderHeader = ({ onClick, open }) => {
    return (
      <AutoScroll scroll={open} disable={this.props.disableAutoScroll}>
        <CollapseHeader className="collapse-header" onClick={onClick}>
          <Text>
            {this.props.title}
            <FloatIcon className="collapse-icon" icon={open ? 'angle-down' : 'angle-right'} />
          </Text>
        </CollapseHeader>
      </AutoScroll>
    );
  };

  render() {
    const { title, ref, children, ...rest } = this.props;
    return (
      <BaseCollapse {...rest} heading={this.renderHeader}>
        <div className="collapse-body">{children}</div>
      </BaseCollapse>
    );
  }
}

export default register('rsv8-components/Collapse')(
  withTheme(['.collapse-icon', '.collapse-body', '.collapse-header'])
)(Collapse);
