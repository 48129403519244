import * as React from 'react';
import styled from 'styled-components';

const TableCell = styled.td`
  vertical-align: middle !important;
  &:not(:first-child) {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(props: any) => props.width && `width: ${props.width};`};
` as any;

export default TableCell as React.SFC<React.TdHTMLAttributes<any>>;
