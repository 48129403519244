import * as React from 'react';
import { CultureDropdown } from 'rsv8-client';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';

const FooterCultureSelect = styled.select`
  border: 0;
  background: transparent;
  -moz-appearance: none;
  ::-ms-expand {
    border: none;
    background: transparent;
  }
  option {
    color: #000;
  }
`;

const FooterCultureDropdown = (props: any) => {
  return (
    <FooterCultureSelect {...props} {...props.input} className={`wrapper ${props.className}`}>
      {props.options &&
        props.options.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
    </FooterCultureSelect>
  );
};

const V8CultureDropdown = (props) => {
  return <CultureDropdown component={FooterCultureDropdown} {...props} />;
};

export default register('rsv8-app/CultureDropdown')(withContent(), withTheme())(V8CultureDropdown);
