export const sortVersions = (a, b) => {
  const adate = new Date(a.id);
  const bdate = new Date(b.id);
  if (adate > bdate) {
    return -1;
  } else if (bdate > adate) {
    return 1;
  }
  return 0;
};
