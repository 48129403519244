import * as React from 'react';

export const Doc = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1230 1410" preserveAspectRatio="xMidYMid meet">
      <g id="layer101" fill="#eb8400" stroke="none">
        <path d="M0 810 l0 -600 110 0 110 0 0 -105 0 -105 413 0 412 0 93 93 92 92 0 508 0 507 -110 0 -110 0 0 105 0 105 -505 0 -505 0 0 -600z m930 100 l0 -430 -81 0 c-117 0 -119 -2 -119 -110 l0 -90 -330 0 -330 0 0 530 0 530 430 0 430 0 0 -430z m230 -215 l0 -435 -95 0 c-114 0 -118 -4 -113 -116 l3 -74 -332 0 -333 0 0 70 0 70 264 0 264 0 96 98 96 97 0 363 0 362 75 0 75 0 0 -435z" />
        <path d="M160 1025 l0 -35 345 0 345 0 0 35 0 35 -345 0 -345 0 0 -35z" />
        <path d="M160 815 l0 -35 345 0 345 0 0 35 0 35 -345 0 -345 0 0 -35z" />
        <path d="M160 595 l0 -35 345 0 345 0 0 35 0 35 -345 0 -345 0 0 -35z" />
      </g>
    </svg>
  );
};
