import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import Slider from 'react-slick-deux';
import { bindActionCreators, compose } from 'redux';
import { HeaderFive, Loader } from 'rsv8-components';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { register, withResource, withTheme } from 'xcel-react-core';
import { getAccountSummary } from '../../redux/actions';
import { getCurrentAccountSummary } from '../../redux/selectors';
import CarouselCard from '../CarouselCard/CarouselCard';
import { EmptyContainer } from '../EmptyContainer';
import {
  AllLinkContainer,
  CardLink,
  CarouselArrow,
  CarouselHeader,
  CustomCard,
  CustomCardContainer,
  CustomCardImage,
  EmptyImage,
  ItemCarouselContainer,
  ItemCarouselHeading,
  ItemCarouselSubHeading,
  ItemContainer,
  LoadingContainer
  } from './styles';
import { ItemCarouselProps } from './types';

const CarouselPrevArrow = (props) => {
  const { className, onClick } = props;

  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-left" size="3x" />
    </CarouselArrow>
  );
};

const CarouselNextArrow = (props) => {
  const { className, onClick } = props;

  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-right" size="3x" />
    </CarouselArrow>
  );
};

const settings = {
  dots: false,
  infinite: false,
  slidesToScroll: 6,
  slidesToShow: 6,
  prevArrow: <CarouselPrevArrow />,
  nextArrow: <CarouselNextArrow />,
  speed: 500,
  lazyLoad: 'progressive',
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToScroll: 6,
        slidesToShow: 6
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToScroll: 5,
        slidesToShow: 5
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToScroll: 4,
        slidesToShow: 4
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 3,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 385,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
};

const CarouselHeading = ({ allLink, title, titleAlign, titleSubtext, allText, unslicked }) => {
  return (
    <CarouselHeader titleAlign={titleAlign} unslicked={unslicked}>
      {title && (
        <ItemCarouselHeading>
          {title}
          {titleSubtext && <ItemCarouselSubHeading>{titleSubtext}</ItemCarouselSubHeading>}
        </ItemCarouselHeading>
      )}
      {allLink && (
        <AllLinkContainer className={'see-all-link'}>
          <CardLink to={allLink}>{allText}</CardLink>
        </AllLinkContainer>
      )}
    </CarouselHeader>
  );
};

interface CarouselProps extends React.RefObject<{}> {
  innerSlider: any;
}

class ItemCarousel extends React.Component<ItemCarouselProps, any> {
  carousel: Partial<CarouselProps> = React.createRef();
  state = {
    initResize: false,
    unslicked: false
  };
  carouselRef = (component) => (this.carousel = component);

  unslickCheck = () => {
    setTimeout(() => {
      if (
        this.carousel &&
        this.carousel.innerSlider &&
        this.carousel.innerSlider.props.className.includes('unslicked') &&
        !this.state.unslicked
      ) {
        this.setState({ unslicked: true });
      } else if (
        this.carousel &&
        this.carousel.innerSlider &&
        !this.carousel.innerSlider.props.className.includes('unslicked') &&
        this.state.unslicked
      ) {
        this.setState({ unslicked: false });
      } else if (!this.carousel) {
        this.setState({ unslicked: false });
      }
    }, 50);
  };

  componentDidMount() {
    this.props.actions.getAccountSummary();
    window.addEventListener('resize', this.unslickCheck);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    let event: any;

    if (this.carousel) {
      if (document.createEvent) {
        event = document.createEvent('Event');
        event.initEvent('resize', false, true);
      } else {
        event = new Event('resize');
      }
      window.dispatchEvent(event);
    }
  }

  renderSlider = () => {
    const {
      accountSummary,
      cardIcon,
      cardPath,
      cardText,
      emptySliderTextA,
      emptySliderTextB,
      favoritesList,
      filterByPoints,
      products,
      title,
      dataLoaded,
      shopCatalogText
    } = this.props;

    const filterProducts = filterByPoints ? filterByPoints : false;

    if (!dataLoaded) {
      return this.renderLoader();
    } else if (
      Array.isArray(products) &&
      products.length > 0 &&
      accountSummary &&
      products.filter((product) => !filterProducts || product.pointValue < accountSummary.balance.total).length > 0
    ) {
      return (
        <Slider {...settings} ref={this.carouselRef}>
          {products
            .filter((product) => product !== null)
            .filter((product) => !filterProducts || product.pointValue < accountSummary.balance.total)
            .map((product, index) => {
              return (
                <ItemContainer key={index}>
                  <CarouselCard userPoints={accountSummary.balance.total} {...{ favoritesList, product }} />
                </ItemContainer>
              );
            })}
          <CustomCard>
            <CustomCardContainer>
              <CustomCardImage src={cardIcon} />
              <CardLink to={cardPath}>
                {cardText} <Icon name="angle-right" />
              </CardLink>
            </CustomCardContainer>
          </CustomCard>
          {this.props.children}
        </Slider>
      );
    } else {
      return (
        <EmptyContainer noborder={true}>
          <EmptyImage src={cardIcon} />
          <HeaderFive>{`${emptySliderTextA} "${title}" ${emptySliderTextB}`}</HeaderFive>
          <CardLink to={`/catalog`}>
            {shopCatalogText} <Icon name="angle-right" />
          </CardLink>
        </EmptyContainer>
      );
    }
  };

  renderLoader = () => {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  };

  render() {
    const { allLink, title, titleAlign, titleSubtext, allText, className, ...rest } = this.props;

    return (
      <Row className={className}>
        <Col xs={24}>
          <CarouselHeading
            {...{ allLink, title, titleAlign, titleSubtext, allText }}
            unslicked={this.state.unslicked}
          />
          <ItemCarouselContainer className={'item-carousel-container'} unslicked={this.state.unslicked} {...rest}>
            {this.renderSlider()}
          </ItemCarouselContainer>
        </Col>
      </Row>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  allText: getResource('catalog.itemCarousel.seeAllText', 'See All'),
  emptySliderTextA: getResource('catalog.itemCarousel.emptySliderTextA', 'Your'),
  emptySliderTextB: getResource('catalog.itemCarousel.emptySliderTextB', 'List is Empty'),
  shopCatalogText: getResource('catalog.itemCarousel.shopCatalogText', 'Shop the Catalog')
});

const mapStateToProps = (state) => ({
  accountSummary: getCurrentAccountSummary(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAccountSummary
    },
    dispatch
  )
});

export default register('rsv8-catalog/ItemCarousel')(
  compose(connect<any, any, any>(mapStateToProps, mapDispatchToProps), withResource(mapResourceToProps), withTheme())
)(ItemCarousel);
