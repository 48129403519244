import { RandomRange } from './../util';
import { keyframes } from 'styled-components';

export default rand => {
  const left = rand.left + RandomRange(-10, 10);
  const top = left - 400;
  const scale = Math.round(RandomRange(6, 8));
  return keyframes`
    0% {
       opacity: 0;
    }
    40% {
       opacity: 1;
       left: ${left}%;
       top: ${top}%;
       transform:scale(${scale});
    }
    100% {
       opacity: 0;
       left: ${left}%;
       top: 600%;
    }
    ` as any;
};
