import * as React from 'react';
import styled from 'styled-components';

const OopsSVG = styled.svg`
  .st0 {
    fill: #ededed;
  }
  .st1 {
    fill: #cccccc;
  }
  .st2 {
    fill: #999999;
  }
`;

const OopsIcon = (props) => (
  <OopsSVG {...props} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 114.7 119.3">
    <path
      className="st1"
      d="M29,19.2l-0.6-2.7l-2.6-1l2.4-1.4l0.2-2.8l2.1,1.9l2.7-0.7l-1.1,2.6l1.5,2.4l-2.8-0.3L29,19.2z M28,15.2
l1.2,0.5l0.3,1.3l0.8-1l1.3,0.1L31,15l0.5-1.2l-1.3,0.3l-1-0.9l-0.1,1.3L28,15.2z"
    />
    <path
      className="st1"
      d="M93.9,50.2l-1.5-1.9L90,48.5l1.3-2l-0.9-2.2l2.3,0.6l1.8-1.6l0.2,2.4l2.1,1.2l-2.2,0.9L93.9,50.2z M92.8,47.7
l0.8,1l0.3-1.3l1.2-0.5L94,46.2v-1.3l-1,0.9l-1.3-0.3l0.5,1.2l-0.7,1.1L92.8,47.7z"
    />
    <ellipse className="st1" cx="25.8" cy="27.5" rx="0.4" ry="1.4" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -11.8561 27.4764)"
      className="st1"
      cx="27.2"
      cy="28"
      rx="1.4"
      ry="0.4"
    />
    <ellipse className="st1" cx="27.9" cy="29.6" rx="1.4" ry="0.4" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -14.059 28.3388)"
      className="st1"
      cx="27.2"
      cy="31.1"
      rx="0.4"
      ry="1.4"
    />
    <ellipse className="st1" cx="25.6" cy="31.6" rx="0.4" ry="1.4" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -14.8592 26.1682)"
      className="st1"
      cx="24.2"
      cy="31"
      rx="1.4"
      ry="0.4"
    />
    <ellipse className="st1" cx="23.5" cy="29.5" rx="1.4" ry="0.4" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.6564 25.3057)"
      className="st1"
      cx="24.2"
      cy="27.9"
      rx="0.4"
      ry="1.4"
    />
    <ellipse className="st1" cx="72.2" cy="12.8" rx="0.3" ry="1" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 12.141 55.5698)"
      className="st1"
      cx="73.1"
      cy="13.1"
      rx="1"
      ry="0.3"
    />
    <ellipse className="st1" cx="73.7" cy="14.2" rx="1" ry="0.3" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 10.6669 56.1927)"
      className="st1"
      cx="73.2"
      cy="15.2"
      rx="0.3"
      ry="1"
    />
    <ellipse className="st1" cx="72.2" cy="15.6" rx="0.3" ry="1" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 10.044 54.7187)"
      className="st1"
      cx="71.1"
      cy="15.2"
      rx="1"
      ry="0.3"
    />
    <ellipse className="st1" cx="70.7" cy="14.1" rx="1" ry="0.3" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 11.619 54.1192)"
      className="st1"
      cx="71.1"
      cy="13"
      rx="0.3"
      ry="1"
    />
    <ellipse className="st1" cx="93.4" cy="26.1" rx="0.5" ry="1.8" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 8.8866 75.2879)"
      className="st1"
      cx="95.3"
      cy="26.9"
      rx="1.8"
      ry="0.5"
    />
    <ellipse className="st1" cx="96.2" cy="28.9" rx="1.8" ry="0.5" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 6.145 76.341)"
      className="st1"
      cx="95.2"
      cy="30.8"
      rx="0.5"
      ry="1.8"
    />
    <ellipse className="st1" cx="93.3" cy="31.5" rx="0.5" ry="1.8" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 5.0972 73.6557)"
      className="st1"
      cx="91.5"
      cy="30.7"
      rx="1.8"
      ry="0.5"
    />
    <ellipse className="st1" cx="90.8" cy="28.8" rx="1.8" ry="0.5" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 7.7449 72.628)"
      className="st1"
      cx="91.5"
      cy="27"
      rx="0.5"
      ry="1.8"
    />
    <ellipse className="st1" cx="19.9" cy="48.2" rx="0.5" ry="1.8" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -28.2804 29.7423)"
      className="st1"
      cx="21.8"
      cy="49"
      rx="1.8"
      ry="0.5"
    />
    <ellipse className="st1" cx="22.5" cy="51" rx="1.8" ry="0.5" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -31.025 30.7884)"
      className="st1"
      cx="21.7"
      cy="52.8"
      rx="0.5"
      ry="1.8"
    />
    <ellipse className="st1" cx="19.8" cy="53.5" rx="0.5" ry="1.8" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -32.0335 28.0438)"
      className="st1"
      cx="17.8"
      cy="52.7"
      rx="1.8"
      ry="0.5"
    />
    <ellipse className="st1" cx="17.1" cy="50.8" rx="1.8" ry="0.5" />
    <ellipse
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -29.4121 27.0724)"
      className="st1"
      cx="18"
      cy="49"
      rx="0.5"
      ry="1.8"
    />
    <circle className="st1" cx="56.2" cy="20" r="0.6" />
    <path
      className="st1"
      d="M56.2,21.8c-0.9,0-1.7-0.7-1.7-1.7c0,0,0,0,0,0c0-0.9,0.8-1.7,1.7-1.7S58,19.1,58,20C58,21,57.2,21.7,56.2,21.8
C56.3,21.8,56.3,21.8,56.2,21.8z M56.2,18.8c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.1-1.2
C56.3,18.7,56.3,18.7,56.2,18.8z"
    />
    <circle className="st1" cx="44" cy="12.8" r="2.3" />
    <circle className="st1" cx="84.2" cy="23.2" r="1.2" />
    <circle className="st1" cx="84.7" cy="34.8" r="1.2" />
    <circle className="st1" cx="18.1" cy="36" r="1.2" />
    <circle className="st1" cx="41.7" cy="23.2" r="1.2" />
    <g>
      <path
        className="st2"
        d="M58,28.6c-16,0-28.9,13-28.9,29c0,16,13,28.9,29,28.9c16,0,28.9-13,28.9-28.9C86.9,41.6,73.9,28.6,58,28.6z
  M58,84.1c-14.7,0-26.6-12-26.5-26.6s12-26.6,26.6-26.5c14.6,0,26.5,11.9,26.5,26.5C84.6,72.2,72.7,84.1,58,84.1z"
      />
      <path
        className="st2"
        d="M58,65.6c-3.2,0-6.2,1.3-8.3,3.6c-0.5,0.5-0.5,1.2,0,1.7c0,0,0,0,0,0c0.2,0.2,0.5,0.3,0.8,0.3
 c0.3,0,0.6-0.1,0.8-0.4c3.5-3.7,9.4-3.8,13.1-0.3c0,0,0.1,0.1,0.1,0.1c0.5,0.4,1.2,0.3,1.7-0.2c0.4-0.4,0.4-1.1,0-1.5
 C64,66.8,61,65.6,58,65.6z"
      />
      <ellipse className="st2" cx="46.9" cy="55.2" rx="3.5" ry="4.6" />
      <ellipse className="st2" cx="69" cy="55.2" rx="3.5" ry="4.6" />
      <path
        className="st2"
        d="M49.8,41.6c0.2-0.6-0.1-1.3-0.7-1.5c-0.6-0.2-1.3,0.1-1.5,0.7c-1.8,5.6-7.2,4.1-7.8,3.9
 c-0.6-0.2-1.3,0.1-1.5,0.7s0.1,1.3,0.7,1.5c1,0.3,2,0.5,3,0.5c0.4,0,0.9,0,1.3-0.1C46.5,46.9,49,44.7,49.8,41.6z"
      />
      <path
        className="st2"
        d="M77.6,45.5c-0.2-0.6-0.8-0.9-1.4-0.8c0,0,0,0-0.1,0c-1.1,0.3-2.2,0.4-3.2,0.3c-2.2-0.3-4.1-1.9-4.5-4.2
 c-0.2-0.6-0.9-0.9-1.5-0.7c-0.6,0.2-0.9,0.9-0.7,1.5c0.8,3,3.3,5.3,6.4,5.7c0.4,0.1,0.9,0.1,1.3,0.1c1,0,2-0.1,3-0.5
 C77.4,46.8,77.7,46.2,77.6,45.5z"
      />
    </g>
  </OopsSVG>
);

export default OopsIcon;
