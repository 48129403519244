import { toast } from 'rsv8-components';

export const assetAdminToast = (className: any, message: any) => {
  return toast(
    {
      template: 'Default',
      themeVariation: 'success',

      message: `${message}`
    },
    { autoClose: false, className: `${className}` }
  );
};
