import * as React from 'react';
import { componentObserver } from 'xcel-observer';
const mapLegacyContent = (props) => (name, obj, namespace = undefined) => {
  const addContentProperty = componentObserver.addProperty(`content.${name}.properties`);
  if (obj === undefined || obj === null) return;
  if (Object.keys(obj).length === 0) return '';
  return Object.keys(obj).reduce(
    (current, next) => {
      const model = namespace ? `${namespace}.${next}` : next;
      if (typeof current[next] === 'object') {
        if (Array.isArray(current[next])) {
          current[next] = [...mapLegacyContent(props)(name, current[next], model)];
        } else {
          current[next] = { ...mapLegacyContent(props)(name, current[next], model) };
        }
      } else {
        // renderhtml is killing us :)
        // const value = current[next];
        // current[next] = createValueRenderer({
        //   value,
        //   editingValue: value,
        //   meta: {
        //     model: props.model,
        //     type: 'text',
        //     name,
        //     property: model,
        //     connector: { icon: 'fa-file-text-o', type: 'content' }
        //   }
        // });
        addContentProperty(model, {});
      }
      return current;
    },
    Array.isArray(obj) ? [...obj] : { ...obj }
  );
};
const withLegacyContent = (name) => (Component) => ({ content, ...rest }) => {
  return <Component {...rest} content={mapLegacyContent(rest)(name, content)} />;
};
export default withLegacyContent;
