import * as R from 'ramda';
export const sortBrands = (brandOptions) => {
  const sortByLabel: any = R.sortBy(
    R.compose(
      R.toLower,
      R.prop('filterLabel')
    )
  );
  const sortedBrands = sortByLabel(brandOptions);
  let groupedBrands = {};

  if (Array.isArray(sortedBrands)) {
    sortedBrands.forEach((brand) => {
      const firstLetter = brand.filterLabel.charAt(0);
      if (groupedBrands[firstLetter] === undefined) {
        groupedBrands[firstLetter] = [];
      }
      groupedBrands[firstLetter].push(brand);
    });
  }
  return groupedBrands;
};
