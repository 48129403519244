import * as React from 'react';
import { DetailFormControl, OptionsFormGroup, OptionsText } from './styles';

export interface ProductOptionsProps {
  product: any;
  productOptionSelection: any;
}

class ProductOptions extends React.Component<ProductOptionsProps, any> {
  render() {
    const { product, productOptionSelection } = this.props;

    return (
      <React.Fragment>
        <OptionsText>Options</OptionsText>
        <OptionsFormGroup controlId="formControlsSelect">
          <DetailFormControl
            componentClass="select"
            name={product.id}
            placeholder="select"
            ref={(component) => {
              productOptionSelection[product.id] = component;
            }}
          >
            {product.productOptions.map((option) => (
              <option key={option.productOptionID} value={option.productOptionID}>
                {option.selection}
              </option>
            ))}
          </DetailFormControl>
        </OptionsFormGroup>
      </React.Fragment>
    );
  }
}

export default ProductOptions;
