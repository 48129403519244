import * as React from 'react';
import { Text } from 'rsv8-components';
import styled from 'styled-components';

interface InputOption {
  value: string;
  label: string;
}

export interface ParagraphFieldProps {
  className?: string;
  field: {
    id: string;
    label: string;
    fieldType: string;
    options: InputOption[];
  };
  value: string;
  valueTheme?: string;
}

/**
 * Finds the text label based on its value for DropDown or Checkbox inputs
 *
 * @param {String} value - actual input value from option
 * @param {Array} options - option list for the dropdown input
 */
const getText = (value: string, options: InputOption[]) => {
  const option = options.find((opt) => opt.value === value);
  return (option && option.label) || value;
};

const ParagraphPadding = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const StyledList = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  li {
    list-style: none;
  }
`;
/**
 * Displays the custom field values
 *
 * @param {String} className  css classses
 * @param {Object} field      metadata about the input field, like type and name
 * @param {String} value      actual input value
 * @param {String} valueTheme
 */
const ParagraphField: React.SFC<ParagraphFieldProps> = ({ className, field, value, valueTheme }) => {
  const valueArray = value.replace(/\s+/g, '').split(',');
  return (
    <ParagraphPadding className={className}>
      <Text themeVariation="field-label">{field.label}</Text>
      <br />
      {(field.fieldType === 'CheckBox' && field.options && field.options.length > 1) ||
      field.fieldType === 'DropDown' ? (
        <StyledList>
          {valueArray.map((val, index) => {
            return <li key={index}>{getText(val, field.options)}</li>;
          })}
        </StyledList>
      ) : (
        <Text themeVariation={valueTheme} dangerouslySetInnerHTML={{ __html: value }} />
      )}
    </ParagraphPadding>
  );
};

export default ParagraphField;
