import * as React from "react";
import { connect } from "react-redux";
import { register, withContent, withTheme, withResource } from "xcel-react-core";
import * as styles from "./styles";
import {
  fetchRedeemOptions,
  saveMagnifeyeRedeem,
} from "../../redux/actions";
import { redeemOptionSelector, pointSummarySelector, redemptionHistorySelector } from "../../redux/selectors";
import { withApiDataProvider } from "xcel-redux-orm";
import * as utils from "../../../utils";
import { bindActionCreators } from "redux";
// const isReady = (request) => request && !request.loading && request.exists;
import * as Icon from "react-fontawesome";
import RebateModal from '../RebateModal'

interface ProductCreditProps {
  actions: any;
  productCreditOption: any;
  pointSummary: any;
  redemptionHistory: any;
  productDetailType: any;
  insufficientAmountText: any;
  submit: string;
  subTitle: string;
  currencySymbol: string;
  currencyAbbreviation: string;
  productCreditDescription: string;
}

const getWarning = (text) => {
  return (
    <p>
      <Icon name="fal fa-exclamation-triangle" />
      {" " + text}
    </p>
  );
};

const setAmount = (
  redemptionId,
  redemptionDescription,
  userPoints,
  insuffAmountText,
  submit,
  currencySymbol,
  currencyAbbreviation,
  isMobile?
) => {
  const [getCreditData, setCreditData] = React.useState('');  

  const countDecimals = function (value) {
    if(Math.floor(value) === value) return 0;
    const splitNumber = value.toString().split(".")[1].length;
    return splitNumber
  }

  const checkInputValueDecimalCount = getCreditData && getCreditData !== '' ? countDecimals(parseFloat(getCreditData)) : ''
  const amountToSubmit = checkInputValueDecimalCount > 2 ? parseFloat(getCreditData).toFixed(2) : getCreditData

  if (isMobile) {
    return (
      <div>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            <styles.AmountInput
              type="text"
              key={`redeem_` + redemptionId}
              name={`redeem_` + redemptionId}
              defaultValue={getCreditData}
              value ={amountToSubmit}
              onChange={(e) => setCreditData( utils.allowDollarChars(e.target.value) )}
            />
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            { 
              amountToSubmit === ''
                ? <div />
                : amountToSubmit <= userPoints
                    ? <styles.AmountText>{currencySymbol}{amountToSubmit} {currencyAbbreviation}</styles.AmountText> 
                    : <styles.InsufficientAmountText>{getWarning(insuffAmountText)}</styles.InsufficientAmountText>
            }
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            <styles.MobileRedeemTypeDescription>{redemptionDescription}</styles.MobileRedeemTypeDescription>
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.MobileRedeemInfoCell>
            { amountToSubmit > userPoints || amountToSubmit === ''
            ?  <styles.MobileSubmitButtonDisabled
                id={submit}
                key={submit}
                disabled={true}
                onClick={handleButtonClick}
               >
                {submit}
               </styles.MobileSubmitButtonDisabled>
            :  <styles.MobileSubmitButton
                id={submit}
                key={submit}
                disabled={false}
                onClick={handleButtonClick}
              >
                {submit}
              </styles.MobileSubmitButton>
            }
          </styles.MobileRedeemInfoCell>
        </styles.TableRow>
      </div>
    );
  } else {
    return (
      <div>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            <styles.AmountInput
             type="text"
             key={`redeem_` + redemptionId}
             name={`redeem_` + redemptionId}
             defaultValue={getCreditData}
             value ={amountToSubmit}
             onChange={(e) => setCreditData( utils.allowDollarChars(e.target.value) )}
            />
          </styles.RedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            { 
              amountToSubmit === ''
                ? <div />
                : amountToSubmit <= userPoints
                    ? <styles.AmountText>{currencySymbol}{amountToSubmit} {currencyAbbreviation}</styles.AmountText> 
                    : <styles.InsufficientAmountText>{getWarning(insuffAmountText)}</styles.InsufficientAmountText>
            }
          </styles.RedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            <styles.RedeemTypeDescription>{redemptionDescription}</styles.RedeemTypeDescription>
          </styles.RedeemInfoCell>
        </styles.TableRow>
        <styles.TableRow>
          <styles.RedeemInfoCell>
            { amountToSubmit > userPoints || amountToSubmit === ''
            ?  <styles.SubmitButtonDisabled
                id={submit}
                key={submit}
                disabled={true}
                onClick={handleButtonClick}
               >
                {submit}
               </styles.SubmitButtonDisabled>
            :  <styles.SubmitButton
                id={submit}
                key={submit}
                disabled={false}
                onClick={handleButtonClick}
              >
                {submit}
              </styles.SubmitButton>
            }
          </styles.RedeemInfoCell>
        </styles.TableRow>
      </div>
    );
  }
};

const desktopSize: number = 1025;
const tabletSize: number = 1024;
// const smallTabletSize: number = 824;
const mobileSize: number = 761;
const smallMobileSize: number = 400;

const ProductCredit: React.SFC<ProductCreditProps> = ({
  productCreditOption,
  pointSummary,
  redemptionHistory,
  productDetailType = productCreditOption.id,
  submit,
  subTitle,
  currencySymbol,
  currencyAbbreviation,
  ...props
}) => {

  function getWindowSize() {
    const [getWindowSize, setWindowSize] = React.useState(window.innerWidth);
    React.useLayoutEffect(() => {
      const windowReSize = () => setWindowSize(window.innerWidth);
      window.addEventListener("resize", windowReSize);
      return () => {
        window.removeEventListener("resize", windowReSize);
      };
    }, []);
    return getWindowSize;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (event !== undefined && event !== null) {
      const target = event.target;

      if (target !== undefined && target !== null) {
        const targetElements: HTMLFormControlsCollection = target.elements;

        if (
          targetElements !== undefined &&
          targetElements !== null &&
          targetElements.length > 0
        ) {
          for (let i = 0; i <= targetElements.length - 1; i++) {
            const formElement: any = target.elements[i];
            if (formElement !== undefined && formElement !== null) {
              const redemptionId: string = utils
                .toLowerCase(formElement.name)
                .replace("redeem_", "");
              const redemptionValue: any = parseFloat(formElement.value);

              if (
                redemptionId &&
                redemptionId !== null &&
                redemptionValue !== ""
              ) {
                const redemptionBody = {
                  redeemOptionId: redemptionId,
                  amount: redemptionValue,
                  productId: "",
                  quantity: 0,
                };

                if (buttonClicked === true) {
                  await props.actions.saveMagnifeyeRedeem(redemptionBody);
                  formElement.value = 0;
                }

                buttonClicked = false;
              }
            }
          }
        }
      }
    }
  }

  let productCredit = productCreditOption.filter(
    (item) => item.id === productDetailType
  );

  if (productCredit !== undefined && productCredit.length > 0) {
    productCredit = productCredit[0];

    if (productCredit.canRedeem) {
      const windowSize = getWindowSize();

      const imageDir = "/clientassets/images/products/";
      const mobileImageDir = imageDir + "mobile/";
      const tabletImageDir = imageDir + "tablet/";
      const id = productCredit.id;
      const name = productCredit.name;
      const description = props.productCreditDescription;
      const imgUrl = imageDir + productCredit.imageUrl;

      const mobileImgUrl =  tabletImageDir + "credit-small.png";
      const tabletImgUrl = mobileImageDir + "credit.png";

      const insuffAmountText = props.insufficientAmountText;
      const userPoints = pointSummary === undefined ? undefined : utils.calculateUserPoints(pointSummary.available);

      if (windowSize > desktopSize) {
        return (
          <div {...props}>
            <styles.RedeemContainer>
              <styles.RedeemForm {...props} onSubmit={handleSubmit}>
                <styles.RedeemTable>
                  <styles.TableRow>
                    <styles.ImageCell><img src={imgUrl} /></styles.ImageCell>
                    <styles.RedeemInfoCellFrame>
                      <styles.RedeemInfoTable>
                        <styles.TableRow>
                          <styles.AmountHeader>{name}</styles.AmountHeader>
                        </styles.TableRow>

                        <styles.TableRow>
                          <styles.RedeemInfoCell>
                            <styles.RedeemTypeDescription>
                              {subTitle}
                            </styles.RedeemTypeDescription>
                          </styles.RedeemInfoCell>
                        </styles.TableRow>

                        {setAmount(
                          id,
                          description,
                          userPoints,
                          insuffAmountText,
                          submit,
                          currencySymbol,
                          currencyAbbreviation,
                          false
                        )}
                      </styles.RedeemInfoTable>
                    </styles.RedeemInfoCellFrame>
                  </styles.TableRow>
                </styles.RedeemTable>
              </styles.RedeemForm>
              { redemptionHistory !== undefined 
                ? <RebateModal 
                    image={imgUrl} 
                    value={null}
                    quantity={null}
                    orderTotal={redemptionHistory.amount}
                    confirmationNumber={redemptionHistory.id}
                  /> 
                : null}
            </styles.RedeemContainer>
          </div>
        );
      } else if (
        windowSize <= desktopSize &&
        windowSize <= tabletSize &&
        windowSize > mobileSize
      ) {
        return (
          <styles.RedeemContainer>
            <styles.RedeemForm {...props} onSubmit={handleSubmit}>
              <styles.RedeemTable>
                <styles.TableRow>
                  <styles.ImageCell>
                    <img src={tabletImgUrl} />
                  </styles.ImageCell>

                  <styles.RedeemInfoCellFrame>
                    <styles.RedeemInfoTable>
                      <styles.TableRow>
                        <styles.AmountHeader>{name}</styles.AmountHeader>
                      </styles.TableRow>

                      <styles.TableRow>
                        <styles.RedeemInfoCell>
                          <styles.RedeemTypeDescription>
                            {subTitle}
                          </styles.RedeemTypeDescription>
                        </styles.RedeemInfoCell>
                      </styles.TableRow>

                      {setAmount(
                        id,
                        description,
                        userPoints,
                        insuffAmountText,
                        submit,
                        currencySymbol,
                        currencyAbbreviation,
                        false
                      )}
                    </styles.RedeemInfoTable>
                  </styles.RedeemInfoCellFrame>
                </styles.TableRow>
              </styles.RedeemTable>
            </styles.RedeemForm>
            { redemptionHistory !== undefined 
              ? <RebateModal 
                  image={imgUrl} 
                  value={null}
                  quantity={null}
                  orderTotal={redemptionHistory.amount}
                  confirmationNumber={redemptionHistory.id}
                /> 
              : null}
          </styles.RedeemContainer>
        );
      } else if (
        windowSize < desktopSize &&
        windowSize < tabletSize &&
        windowSize <= mobileSize
      ) {
        return (
          <styles.MobileRedeemContainer>
            <styles.RedeemForm {...props} onSubmit={handleSubmit}>
              <styles.RedeemTable>
                <styles.TableRow>
                  <styles.MobileRedeemInfoCell>
                    <styles.AmountHeader>{name}</styles.AmountHeader>
                  </styles.MobileRedeemInfoCell>
                </styles.TableRow>
                <styles.TableRow>
                  <styles.MobileRedeemInfoCell>
                    <styles.MobileRedeemInfoTable>
                      <styles.TableRow>
                        <styles.MobileImageCell>
                          <img src={mobileImgUrl} />
                        </styles.MobileImageCell>
                      </styles.TableRow>

                      <styles.TableRow>
                        <styles.MobileRedeemInfoCell>
                          <styles.RedeemTypeDescription>
                            {subTitle}
                          </styles.RedeemTypeDescription>
                        </styles.MobileRedeemInfoCell>
                      </styles.TableRow>

                      {setAmount(
                        id,
                        description,
                        userPoints,
                        insuffAmountText,
                        submit,
                        currencySymbol,
                        currencyAbbreviation,
                        true
                      )}
                    </styles.MobileRedeemInfoTable>
                  </styles.MobileRedeemInfoCell>
                </styles.TableRow>
              </styles.RedeemTable>
            </styles.RedeemForm>
            { redemptionHistory !== undefined 
              ? <RebateModal 
                  image={imgUrl} 
                  value={null}
                  quantity={null}
                  orderTotal={redemptionHistory.amount}
                  confirmationNumber={redemptionHistory.id}
                /> 
              : null}
          </styles.MobileRedeemContainer>
        );
      } else if (
        windowSize < desktopSize &&
        windowSize < tabletSize &&
        windowSize < mobileSize &&
        windowSize <= smallMobileSize
      ) {
        return (
          <styles.MobileRedeemContainer>
            <styles.RedeemForm {...props} onSubmit={handleSubmit}>
              <styles.RedeemTable>
                <styles.TableRow>
                  <styles.MobileRedeemInfoCell>
                    <styles.AmountHeader>{name}</styles.AmountHeader>
                  </styles.MobileRedeemInfoCell>
                </styles.TableRow>
                <styles.TableRow>
                  <styles.MobileRedeemInfoCell>
                    <styles.MobileRedeemInfoTable>
                      <styles.TableRow>
                        <styles.MobileImageCell>
                          <img src={mobileImgUrl} />
                        </styles.MobileImageCell>
                      </styles.TableRow>

                      <styles.TableRow>
                        <styles.MobileRedeemInfoCell>
                          <styles.RedeemTypeDescription>
                            {subTitle}
                          </styles.RedeemTypeDescription>
                        </styles.MobileRedeemInfoCell>
                      </styles.TableRow>

                      {setAmount(
                        id,
                        description,
                        userPoints,
                        insuffAmountText,
                        submit,
                        currencySymbol,
                        currencyAbbreviation,
                        true
                      )}
                    </styles.MobileRedeemInfoTable>
                  </styles.MobileRedeemInfoCell>
                </styles.TableRow>
              </styles.RedeemTable>
            </styles.RedeemForm>
          </styles.MobileRedeemContainer>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

let buttonClicked = false;

function handleButtonClick() {
  buttonClicked = true;
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ saveMagnifeyeRedeem }, dispatch),
});

const mapStateToProps = (state) => ({
  productCreditOption: redeemOptionSelector.selectMany(state),
  pointSummary: pointSummarySelector.selectMany(state)[0],
  redemptionHistory: redemptionHistorySelector.selectMany(state)[0]
});

const mapContentToProps = (getContent) => ({
  productDetailType: getContent("productDetailType"),
  insufficientAmountText: getContent("insufficientAmountText"),
  productCreditDescription: getContent("productCreditDescription"),
});

const mapResourceToProps = (getResource) => ({
  subTitle: getResource('AlconCredit.subTitle', 'Rebate Payout'),
  submit: getResource('AlconCredit.submit', 'Submit'),
  currencySymbol: getResource('AlconCredit.currencySymbol', '$'),
  currencyAbbreviation: getResource('AlconCredit.currencyAbbreviation', 'USD'),
});

export default register("alcon-products/ProductCredit")(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withApiDataProvider(fetchRedeemOptions, redeemOptionSelector),
  withTheme(),
  withContent(mapContentToProps),
  withResource(mapResourceToProps)
)(ProductCredit);
export { ProductCredit };
