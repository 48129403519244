import * as React from 'react';
import { compose } from 'redux';
import * as styles from './styles'
import {    
  Modal,
  ModalHeader,
  ModalBody  
  } from 'rsv8-components';
import { withResource } from 'xcel-react-core';

export interface Props {
  show: boolean;
  onHide: Function;
  image: string;
  selectAccountText: string;
  missingAccountText: string;
  confirmationText: string;
  verificationText: string;
  achSearch: any;
  sendInvitation: Function;
  modalContinueButtonText: string;
  modalDoneButtonText: string;
  modalNoneMatchButtonText: string;
  modalSendInvitationButtonText: string;
  modalHeader: string;
  modalContactCustomerServiceButtonText: string;
  categoryId: string;
  categoryLocation: string;
  categoryEmail: string;
}

class EnrollmentModal extends React.Component<Props> {      
  state={
    accountSelected: '',
    showInvitationContent: true,
    showConfirmationContent: false
  }

  getNetworkId = (networkId) => {
    this.setState({ accountSelected: networkId })
  }

  clearRadio = () => {
    this.setState({ accountSelected: '' })
  }

  sendInvitation = (networkId?) => {
    this.setState({ showInvitationContent: false })
    this.setState({ showConfirmationContent: true })
    networkId ? this.props.sendInvitation(networkId) : this.props.sendInvitation()
  }

  closeModal = () => {
    this.setState({ showConfirmationContent: false })
    this.setState({ showInvitationContent: true })
    this.props.onHide()
  }
  getUsersBillDotComAccount = (accounts: any, categoryId: string, categoryLocation: string, categoryEmail: string) => {

    if (accounts.length > 0) {
      return (
        accounts.map((item, index) => {

          return (
            <div key={index}>
              <styles.AccountRadio>
                <input type="radio" name="accounts" key={index} onClick={() => this.getNetworkId(item.networkId)} />
              </styles.AccountRadio>
              <styles.AccountDropdown>
                <styles.StyledCollapse title={item.name}>
                  <div className="section">
                    <div className="category">{categoryId}</div>
                    <div className="information">{item.networkId}</div>
                  </div>
                  <div className="section">
                    <div className="category">{categoryLocation}</div>
                    <div className="information">{item.addressLine1}</div>
                    <div className="information">{`${item.addressCity}, ${item.addressState} ${item.addressZip}`}</div>
                  </div>
                  <div className="section">
                    <div className="category">{categoryEmail}</div>
                    <div className="information">{item.email}</div>
                  </div>
                </styles.StyledCollapse>
              </styles.AccountDropdown>
            </div>   
          );
        })
      )
    } else {
      return null
    }
  }

  getConfirmationContent = (confirmationText: string, modalDoneButtonText: string) => {
    if (this.state.showConfirmationContent) {
      return (
        <styles.ShowConfirmationContent>
          <div className="contentText">{confirmationText}</div>
          <styles.ContinueButtonLink href="/rebate-options">{modalDoneButtonText}</styles.ContinueButtonLink>
        </styles.ShowConfirmationContent>
      )
    } else {
      return <styles.HideConfirmationContent />
    } 
  }

  displayModalContentForUnregisteredUser = (missingAccountText: string, modalSendInvitationButtonText: string) => {
    if (this.state.showInvitationContent) {
      return (
        <styles.ShowInvitationContent>
          <div className="contentText">{missingAccountText}</div>
          <styles.ContinueButton onClick={() => this.sendInvitation()}>{modalSendInvitationButtonText}</styles.ContinueButton>
        </styles.ShowInvitationContent>
      )
    } else {
      return <styles.HideInvitationContent />
    } 
  }

  displayModalContentForRegisteredUser = (
    selectAccountText: string, 
    achSearch: any, accountSelected: string, 
    modalContinueButtonText: string, 
    modalNoneMatchButtonText: string, 
    categoryId: string, 
    categoryLocation: string, 
    categoryEmail: string ) => {
      
    if (this.state.showInvitationContent) {
      return (
        <styles.ShowInvitationContent>
          <div className="contentText">{selectAccountText}</div>
          <form>
            {this.getUsersBillDotComAccount(achSearch, categoryId, categoryLocation, categoryEmail)}
          </form>
          { accountSelected !== ''
              ? <styles.ContinueButton onClick={() => this.sendInvitation(accountSelected)}>{modalContinueButtonText}</styles.ContinueButton>
              : <styles.ContinueButtonDisabled>{modalContinueButtonText}</styles.ContinueButtonDisabled>
          }
          <styles.OtherButton onClick={() => this.sendInvitation()}>{modalNoneMatchButtonText}</styles.OtherButton>
        </styles.ShowInvitationContent>
      )
    } else {
      return <styles.HideInvitationContent /> 
    }
  }

  render() {
      const { 
        image, 
        selectAccountText, 
        missingAccountText, 
        confirmationText, 
        achSearch, 
        modalContinueButtonText, 
        modalDoneButtonText, 
        modalNoneMatchButtonText, 
        modalSendInvitationButtonText, 
        modalHeader, 
        modalContactCustomerServiceButtonText,
        categoryId,
        categoryLocation,
        categoryEmail
      } = this.props;
      const { accountSelected } = this.state
    
      return (            
        <Modal show={this.props.show} onHide={this.props.onHide} onExit={() => this.clearRadio()}>
            <ModalHeader closeButton={true} />            
          <ModalBody>
              <styles.ModalBodyContainer>
                <styles.ModalHeader>{modalHeader}</styles.ModalHeader>
                <styles.ModalImage src={image} />
                  { this.props.achSearch.length === 0 
                      ? this.displayModalContentForUnregisteredUser(missingAccountText, modalSendInvitationButtonText)
                      : this.displayModalContentForRegisteredUser(selectAccountText, achSearch, accountSelected, modalContinueButtonText, modalNoneMatchButtonText, categoryId, categoryLocation, categoryEmail)
                  }
                  {this.getConfirmationContent(confirmationText, modalDoneButtonText)}
                <styles.CustServButton href="/help/contact-us">{modalContactCustomerServiceButtonText}</styles.CustServButton>
              </styles.ModalBodyContainer>
          </ModalBody>            
        </Modal>
      );
    }    
}

export default compose(withResource())(EnrollmentModal as any);