import * as React from 'react';
import { Table as BSTable, TableProps as BSTableProps } from 'react-bootstrap';
import styled from 'styled-components';
import TableContext from './TableContext';
const TableBase = styled(BSTable)`` as any;
export interface TableProps extends BSTableProps {
  onSort?: any;
  defaultSort?: { field: string; direction: string };
}
class Table extends React.PureComponent<TableProps, { sort: { field: string; direction: string }; onSort: any }> {
  constructor(props: any) {
    super(props);
    this.state = {
      sort: this.props.defaultSort || {
        field: undefined,
        direction: 'unsorted'
      },
      onSort: this.handleSort
    };
  }
  handleSort = (field) => {
    let direction = 'Asc';
    if (field === this.state.sort.field) {
      if (this.state.sort.direction === 'Desc') {
        direction = 'unsorted';
      } else {
        direction = this.state.sort.direction === 'Asc' ? 'Desc' : 'Asc';
      }
    }
    const sort = { direction, field };
    this.setState({ sort });
    if (this.props.onSort) {
      this.props.onSort(sort);
    }
  };
  render() {
    const { children, onSort, ...rest } = this.props;
    return (
      <TableBase {...rest}>
        <TableContext.Provider value={this.state}>{children}</TableContext.Provider>
      </TableBase>
    );
  }
}

export default Table;
