import { Text } from 'rsv8-components';
import styled from 'styled-components';

export const MetricCard = styled.div`
  border: 0;
  text-align: left;
  align-items: left;
  align-content: left;
  vertical-align: middle;
  table {
    box-shadow: 0 2px 4px 0 rgba(190, 190, 190, 0.5);
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
  }
` as any;

export const MetricTableFooterCell = styled.td`
  width: 100%;
  height: 50px;
  text-align: left;
  vertical-align: text-bottom;
  border: 1px;
` as any;

export const MetricTableRow = styled.tr`
  background-color: #ffffff;
  vertical-align: middle;
  text-align: center;
  td {
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    border-top: 0 !important;
  }
  td:last-child {
    border-right: 0;
  }
` as any;

export const MetricTableHeaderRow = styled.tr`
  width: 15%;
  background-color: #f1f1f1;
  padding: 5px;
  vertical-align: middle;
  text-align: center;
` as any;

export const MetricTable = styled.table`
  vertical-align: middle;
  align-self: center;
  border: 1px;
  padding: 10px;
` as any;

export const MetricTableLabelCell = styled.td`
  height: 50px;
  text-align: left;
  vertical-align: middle;
  border-color: #f1f1f1;
  background-color: #ffffff;
  padding: 10px;
` as any;

export const MetricTableCell = styled.td`
  height: 50px;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
  /*
  @media only screen and (max-width: 800px) {
    width: 23%;
    height: 35px;
   }
   @media only screen and (max-width: 600px) {
    width: 21%;
    height: 35px;
   }
    @media only screen and (max-width: 400px) {
      width: 18%;
    height: 25px;
  }*/
` as any;

export const MetricTableFooterRow = styled.tr`
  height: 50px;
  border-radius: 3px;
  /*background-color: #f4fff3;*/
  /* var(--achieved-green-5); */
` as any;

export const MetricTableHeaderCell = styled.td`
  height: 50px;
  text-align: center;
  vertical-align: middle;
  background-color: #f1f1f1;
  white-space: nowrap;
  padding: 5px;
` as any;

export const MetricTableLabelText = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  white-space: normal;
  background-color: transparent;
  vertical-align: middle;
  @media only screen and (max-width: 800px) {
    font-size: 11px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 9px;
  }
` as any;

export const MetricNoDataFoundTable = styled.table`
  font-size: 24px;
  height: 24px;
  font-family: 'BauerBodoniStd';
  background-color: #ffffff;
  vertical-align: middle;
  text-align: center;
  padding: 5px;
` as any;

export const MetricEmptyTableCell = styled.td`
  width: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
` as any;

export const MetricEmptyText = styled(Text)`
  background-color: #ffffff;
  vertical-align: middle;
  text-align: center;
` as any;

export const MobileMetricCard = styled.div`
  border-radius: 0;
  border: 0px;
  text-align: center;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  margin: 0px;
  background-color: transparent;
` as any;

export const MetricMobileTableLabelCell = styled.td`
  height: 50px;
  text-align: center;
  vertical-align: middle;
  margin: 0px;
  padding: 5px;
  border-color: #f1f1f1;
  border-style: solid;
  border-radius: 3px;
  background-color: #f1f1f1;
` as any;

export const MetricMobileTable = styled.table`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  border-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
` as any;

export const MetricMobileTableText = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  background-color: transparent;
` as any;

export const MetricMobileFinalCountRow = styled.table`
  width: 100%;
  height: 50px;
  vertical-align: text-top;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  white-space: nowrap;
  border-radius: 3px;
  border: solid 1px;
` as any;

export const MetricMobileTableCell = styled.td`
  width: 100%x;
  height: 20px;
  text-align: left;
  vertical-align: middle;
  background-color: transparent;
  margin: 0px;
  padding: 10px;
  border-radius: 3px;
` as any;

export const MetricMobileTableLabelText = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  vertical-align: middle;
  color: #414141;
  border-radius: 0px;
  padding: 0px;
` as any;

export const MetricMobileTableRow = styled.tr`
  width: 100%;
  background-color: #ffffff;
  border: solid 1px;
  padding: 0px;
  margin: 0px;
` as any;

export const MetricMobileCountRow = styled.tr`
  width: 100%;
  background-color: transparent;
` as any;

export const MetricMobileMetricRowsIncuded = styled.td`
  width: 100%;
  height: 22px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: transparent;
  color: #bebebe;
` as any;

export const MetricMobileButton = styled.button`
  width: 266px;
  height: 45px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  text-align: center;
  color: var(--black);
` as any;
