import { CLIENT_SET } from 'rsv8-client';
import { reduxObserver } from 'xcel-observer';
import { createReduxModule, onActionPlugin, ReduxModule } from 'xcel-redux-module';
import { contentVersionActions } from './redux/actions';
import * as events from './redux/events';
import reducers from './redux/reducers';
import { contentSelector, contentTypeSelector } from './redux';
let updateTimeout;
const onAction = ({ store, action }: any) => {
  switch (action.type) {
    case CLIENT_SET: {
      return {};
    }
    // allows for us to run a single dispatch when any of these methods happen.
    case events.COMPONENT_ADD:
    case events.COMPONENT_DUPLICATE:
    case events.COMPONENT_MOVE:
    case events.COMPONENT_REMOVE:
    case events.COMPONENT_PROPERTY_SET:
    case events.CONTENT_EDIT_PROPERTY_SET:
    case events.CONTENT_RAW_SET:
      if (action.type === events.CONTENT_RAW_SET) {
        const state = store.getState();
        const contentTypeId = contentTypeSelector.getActive(state);
        if (contentSelector.getRaw(state, contentTypeId) === action.payload) {
          return {};
        }
      }
      clearTimeout(updateTimeout);
      // debounce the update call.. so we wait for enough time before we update. cooling period.
      updateTimeout = setTimeout(() => store.dispatch(contentVersionActions.update()), 1000);
      return {};

    default:
      return {};
  }
};

const cmsAdminModule: ReduxModule = onActionPlugin(onAction)(
  createReduxModule({
    name: 'rsv8-cms-admin',
    reducers,
    hmr: ({ store }) => {
      (module as any).hot.accept('./redux/reducers', () => {
        const reducer = require('./redux/reducers').default;
        return store.updateReducer(reducer);
      });
    }
  })
);
reduxObserver.addModule(cmsAdminModule);
export default cmsAdminModule;
