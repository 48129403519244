import * as React from 'react';
import { connect } from "react-redux";
import { getAccount } from "../../redux/actions";
import { withApiDataProvider } from "xcel-redux-orm";
import * as style from "./styles/orgStyles";
import {
  accountSelector
} from "../../redux/selectors";
import {
  register,
  withContent,
  withTheme,
} from "xcel-react-core";

interface OrgProps {
  account: any;
  billTitle: string;
  shipTitle: string;
  affiliatesTitle: string;
}

const createSingleOrgAttribute = (data: any, title: string|null) => (
  <style.ShipToComponent className="row">
    <style.leftSide className="col-lg-6 col-md-6 col-sm-6 col-xs-8">
      {title && <style.Title>{title}</style.Title>}
      <style.subText>{data.accountNumber}</style.subText>
    </style.leftSide>
    <style.rightSide className={`col-lg-18 col-md-18 col-sm-18 col-xs-16 ${title ? 'offset' : ''}`}>
      <style.subText>{data.customerName}, {data.address1},</style.subText>
      <style.subText>{data.city}, {data.state}, {data.postalCode}</style.subText>
    </style.rightSide>
  </style.ShipToComponent>
)

const createAffiliation = (data: any, title: string) => (
  <style.ShipToComponent className="row">
    <style.leftSide className="col-lg-8">
      <style.Title>{title}</style.Title>
      <style.subText>{data.name}</style.subText>
    </style.leftSide>
  </style.ShipToComponent>
)
  
const orgAttributes: React.SFC<OrgProps> = ({
  affiliatesTitle = "Affiliates",
  shipTitle = "Ship-To",
  billTitle = "Bill-To",
  account: { shipTo, billTo, affiliation, ...acc },
  ...rest
}) => (
  <div {...rest}>
    {billTo && createSingleOrgAttribute(billTo, billTitle)}
    {shipTo && shipTo.map((o, i) => createSingleOrgAttribute(o, i === 0 ? shipTitle : null))}
    {affiliation && createAffiliation(affiliation, affiliatesTitle)}
  </div>
);

const mapStateToProps = state => ({
  account: accountSelector.selectMany(state)[0] || {},
});

/**
 * Content that the admin can enter to change this component
 * @param getContent 
 */
const mapContentToProps = (getContent) => ({
  billTitle: getContent('billTitle', {type: 'none', label: 'title for billTitle '}),
  shipTitle: getContent('shipTitle', {type: 'none', label: 'title for ship  '}),
  affiliatesTitle: getContent('affiliatesTitle', {type: 'none', label: 'affiliatesTitle'}),
  valuelabelColor: getContent('valuelabelColor', {type: 'none', label: 'Color for value'}),
});

export default register("alcon-information/orgAttributes")(
  connect(mapStateToProps),
  withApiDataProvider(getAccount, accountSelector),
  withTheme(),
  withContent(mapContentToProps),
)(orgAttributes);
