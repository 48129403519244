import * as React from 'react';
import { connect } from 'react-redux';
import { compose, shouldUpdate } from 'recompose';
import { bindActionCreators } from 'redux';
import { Dropdown, DropdownItem } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { ChildrenLoader } from '../../../components';
import { nominationActivityActions } from '../../../redux/index';
import { Resources } from '../../../types';
import { ContextMenuContainer } from '../styles/index';

export interface DraftIconProps {
  data: Resources.IndividualDraftNomination | Resources.TeamDraftNomination;
  completeDraftLabel: React.ReactNode;
  deleteDraftLabel: React.ReactNode;
  get: typeof nominationActivityActions.getDraft;
  destroy: typeof nominationActivityActions.destroyNomination;
}

class DraftIcon extends React.PureComponent<DraftIconProps, { loading: boolean }> {
  state = { loading: false };

  handleDelete = async () => {
    this.setState({ loading: true });
    try {
      await this.props.destroy(this.props.data.id.toString());
    } finally {
      this.setState({ loading: false });
    }
  };

  handleGet = async () => {
    this.setState({ loading: true });
    try {
      await this.props.get(this.props.data);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <ContextMenuContainer>
        <ChildrenLoader loading={this.state.loading}>
          <Dropdown id={this.props.data.id.toString()}>
            <DropdownItem onClick={this.handleGet}>{this.props.completeDraftLabel}</DropdownItem>
            <DropdownItem onClick={this.handleDelete}>{this.props.deleteDraftLabel}</DropdownItem>
          </Dropdown>
        </ChildrenLoader>
      </ContextMenuContainer>
    );
  }
}

const mapDispatch = (dispatch) =>
  bindActionCreators(
    { get: nominationActivityActions.getDraft, destroy: nominationActivityActions.destroyNomination },
    dispatch
  );

const mapResource = (getResource) => ({
  completeDraftLabel: getResource('nominationActivity.completeDraftButtonText', 'Complete'),
  deleteDraftLabel: getResource('nominationActivity.deleteDraftButtonText', 'Delete')
});

export default compose(
  shouldUpdate((propsA: DraftIconProps, propsB: DraftIconProps) => propsB.data.id !== propsA.data.id),
  withResource(mapResource),
  connect<{}, ReturnType<typeof mapDispatch>>(
    null,
    mapDispatch
  )
)(DraftIcon) as any;
