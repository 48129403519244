import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { componentVariationCreate } from '../../../../redux/component.actions';
import { valueToKey } from '../../../../util/valueToKey';
export interface CreateVariationModalProps {
  onHide: Function;
  show?: boolean;
  dispatch?: any;
  onCreate?: any;
  name: string;
}
class CreateVariationModal extends React.Component<CreateVariationModalProps> {
  state = { variation: null };
  handleCreate = () => {
    const { dispatch, onCreate, name } = this.props;
    dispatch(componentVariationCreate({ name, variation: this.state.variation }));
    if (onCreate) {
      onCreate(this.state.variation);
    }
  };
  handleChange = (property) => (value) => {
    value = valueToKey(value);
    this.setState({ ...this.state, [property]: value });
  };
  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.handleCreate();
    }
  };
  render() {
    return (
      <Modal {...this.props} bsSize="small">
        <Modal.Header closeButton={true}>
          <Modal.Title>Create Variation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ReactField
            label="Variation Name"
            placeholder="Type Variation Name"
            component="bs-input"
            value={this.state.variation}
            autoFocus={true}
            onChange={this.handleChange('variation')}
            onKeyDown={this.handleEnter}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(CreateVariationModal as any) as any;
