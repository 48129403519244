import { find } from 'ramda';
import { connect } from 'react-redux';
import CustomFieldParagraph from './CustomParagraph';

export interface FieldSummaryProps {
  field: { id: string; label: string };
  customFieldValues: Array<{ id: string; values: any[] }>;
}

const mapState = (state, ownProps: FieldSummaryProps) => {
  return {
    values: find((v) => v.id.toString() === ownProps.field.id.toString(), ownProps.customFieldValues)
  };
};

export default connect(
  mapState,
  null
)(CustomFieldParagraph);
