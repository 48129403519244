import { convertToPatchOperations } from 'xcel-util';
import { navigationAdminApi } from '../../api/generated';
import * as events from './events';
import { createEventDispatch } from './helper';

const navigationMenuAdminUpdate = (navigationMenu) => ({
  type: events.NAVIGATION_MENU_ADMIN_UPDATE,
  navigationMenu
});

const updateMenuadmin = (values, clientId, lastForm) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    values.menuTitle ? values.menuTitle : (values.menuTitle = false);

    let { menuTitle, menuItemImage } = values;

    menuTitle === 'true' ? (menuTitle = true) : (menuTitle = false);

    let newMetaObject = {
      menuTitle: menuTitle,
      menuItemImage: menuItemImage,
      addAuthToken: values.addAuthToken,
      linkTarget: values.linkTarget
    };

    if (values.providerId === undefined || values.providerId === null) {
      values.providerId = 'Default';
    }

    let model = {
      clientId: clientId,
      targetUrl: values.targetUrl,
      label: values.nodeLabel,
      cultureId: values.cultureId,
      menuTypeId: values.menuType,
      sequence: values.sequence,
      parentMenuId: values.parentMenuId,
      direction: 0,
      applicationId: values.applicationId,
      provider: {
        id: values.providerId
      },
      meta: JSON.stringify(newMetaObject)
    };

    const request = {
      operations: convertToPatchOperations(model)
    };

    navigationAdminApi
      .patchMenuadminByIdAndCultureId({ id: values.nodeID, cultureId: values.cultureId, request })
      .then((response) => {
        dispatch(showBootstrapAlert(lastForm, true));
        setTimeout(function() {
          dispatch(showBootstrapAlert(lastForm, false));
        }, 3000);
        lastForm;
        resolve(response);
      })
      .catch(function(err) {
        reject(err);
      });
  });
};

const patchMenuadmin = (dataObject) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .patchMenuadminByCultureId(dataObject)
      .then((response) => {
        resolve(response);
      })
      .catch(function(err) {
        console.log(err);
      });
  });
};

const createMenuadmin = (values, lastForm) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    values.cultureId ? values.cultureId : (values.cultureId = 0);
    values.siteMenuTypeId ? values.siteMenuTypeId : (values.siteMenuTypeId = 0);
    values.appKey ? values.appKey : (values.appKey = 'main');
    values.menuTitle ? values.menuTitle : (values.menuTitle = false);

    let { menuTitle, menuItemImage } = values;
    menuTitle === 'true' ? (menuTitle = true) : (menuTitle = false);

    let newMetaObject = {
      menuTitle: menuTitle,
      menuItemImage: menuItemImage,
      addAuthToken: values.addAuthToken,
      linkTarget: values.linkTarget
    };

    let model = {
      clientId: values.clientId,
      label: values.nodeLabel,
      cultureId: values.cultureId ? values.cultureId : (values.cultureId = 0),
      providerId: values.provider,
      applicationId: values.appKey,
      targetUrl: values.targetURL,
      parentMenuId: values.parentMenuId,
      sequence: 0,
      siteMenuTypeId: values.menuType ? values.menuType : (values.menuType = 0),
      meta: JSON.stringify(newMetaObject)
    };

    navigationAdminApi
      .createMenuadmin({ model } as any)
      .then((response) => {
        dispatch(showBootstrapAlert(lastForm, true));
        setTimeout(function() {
          dispatch(showBootstrapAlert(lastForm, false));
        }, 3000);
        resolve(response);
      })
      .catch(function(err) {
        console.log('err', err);
      });
  });
};

const getMenuadminReadOnly = createEventDispatch(
  'READ_ONLY_ADMIN_MENU',
  (clientId, cultureId, MenuTypeId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      cultureId ? cultureId : (cultureId = 0);
      MenuTypeId ? MenuTypeId : (MenuTypeId = 0);

      navigationAdminApi
        .getMenuadmin({ modelClientId: clientId, modelCultureId: cultureId, modelMenuTypeId: MenuTypeId })
        .then((response) => {
          response.map((item, index) => {
            item.title = item.label;

            if (item.children.length) {
              let child = item.children;
              child.forEach((subItem) => {
                subItem.title = subItem.label;
              });
            }
          });
          resolve(response);
        })
        .catch((err) => console.error(err));
    });
  }
);

const mapMenuItems = (item) => {
  const title = item.label;
  const meta = item.meta ? JSON.parse(item.meta) : {};
  let children = [];
  if (item.children && item.children.length > 0) {
    children = item.children.map(mapMenuItems);
  }
  return {
    ...item,
    title,
    meta,
    children
  };
};

const getMenuAdminProviders = createEventDispatch('NAVIGATION_MENU_ADMIN_PROVIDER', () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .getProvider({})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.error(err));
  });
});

const getMenuadmin = createEventDispatch('NAVIGATION_MENU_ADMIN', (clientId, values) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let { cultureId, menuType, appKey, applicationId, countryCode } = values;

    cultureId ? cultureId : (cultureId = 0);
    menuType ? menuType : (menuType = 0);
    countryCode && countryCode !== '' && typeof countryCode !== 'object' ? countryCode : (countryCode = 'USA');

    navigationAdminApi
      .getMenuadmin({
        modelClientId: clientId,
        modelCultureId: cultureId,
        modelCountryCode: countryCode,
        modelMenuTypeId: menuType,
        modelApplicationId: appKey || applicationId
      })
      .then((response) => {
        let data = response.map(mapMenuItems);

        resolve(data);
      })
      .catch((err) => console.error(err));
  });
});

const getLookupByKey = createEventDispatch('NAVIGATION_MENU_ADMIN_LOOKUP', (key) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .getLookupByKey({ key })
      .then((response) => {
        const retval = {
          data: response,
          key
        };
        resolve(retval);
      })
      .catch((err) => console.error(err));
  });
});

const deleteMenuadminById = (id, cultureId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .deleteMenuadminByIdCultureId({ id: id, cultureId: cultureId })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.error(err));
  });
};

const getApplication = createEventDispatch('NAVIGATION_MENU_ADMIN_APPLICATION', (clientId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .getApplication({ clientId: clientId })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.error(err));
  });
});

const updateApplication = (id, model) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const request = {
      operations: convertToPatchOperations(model)
    };

    navigationAdminApi
      .patchApplicationById({ id, request })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.error(err));
  });
};

const createApplication = (values, clientId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let model: any = {
      id: values.appKey,
      ClientId: clientId,
      name: values.appName,
      appIsDefault: values.appIsDefault
    };
    model.appIsDefault === undefined ? (model.isDefault = false) : '';

    navigationAdminApi
      .createApplication({ model })
      .then((response) => {
        resolve(response);
      })
      .catch(reject);
  });
};

const deleteApplicationByAppKey = (item) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .deleteApplicationById({ clientId: item.clientId, id: item.id })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.log(err));
  });
};

const deleteDataruleGroupsByGroupId = (groupId, clientId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .deleteDataruleGroupsByGroupId({ groupId: groupId, clientId: clientId })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.log(err));
  });
};

const updateDatarule = (dataRule, groupId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let model: any = {
      id: dataRule.id,
      rule: dataRule.rule,
      description: dataRule.description,
      dataRuleGroupId: groupId,
      isActive: dataRule.isActive
    };

    model.isDefault === undefined ? (model.isDefault = false) : '';
    navigationAdminApi
      .updateDatarule({ rule: model })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.error(err));
  });
};

const createDataruleGroupsMenu = (clientId, rulesId, groupId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let model: any = {
      id: groupId,
      menuId: rulesId
    };

    model.isDefault === undefined ? (model.isDefault = false) : '';
    navigationAdminApi
      .createDataruleGroupsMenu({ clientId: clientId, ruleGroupMenu: model })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.error(err));
  });
};

const deleteDataruleGroupsByGroupIdMenuAndMenuId = (gropuId, items) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    navigationAdminApi
      .deleteDataruleGroupsByGroupIdMenuAndMenuId({ groupId: gropuId, items, menuId: items } as any)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => console.log(err));
  });
};

const showBootstrapAlert = (formName, show) => {
  return { type: events.SHOW_BOOTSTRAP_ALERT, formName: formName, show: show };
};

const lastSubmittedForm = (formName) => {
  return { type: events.SHOW_LAST_FORM, lastForm: formName };
};

export {
  getMenuadmin,
  createMenuadmin,
  updateMenuadmin,
  deleteMenuadminById,
  getLookupByKey,
  navigationMenuAdminUpdate,
  getMenuadminReadOnly,
  getMenuAdminProviders,
  patchMenuadmin,
  getApplication,
  updateApplication,
  createApplication,
  deleteApplicationByAppKey,
  deleteDataruleGroupsByGroupId,
  updateDatarule,
  createDataruleGroupsMenu,
  deleteDataruleGroupsByGroupIdMenuAndMenuId,
  showBootstrapAlert,
  lastSubmittedForm
};
