import * as R from 'ramda';

const normalizeBy = R.indexBy;

const normalizeByKey = key => normalizeBy(o => o[key]);

const normalizeById = normalizeByKey('id');

const pipe = R.pipe;

const comparator = getValue => (a, b) => {
  const first = getValue(a);
  const second = getValue(b);
  if (first < second) {
    return -1;
  } else if (first === second) {
    return 0;
  } else {
    return 1;
  }
};

const sortBy = fn => list => {
  const newList = [...list];
  newList.sort(comparator(fn));
  return newList;
};

const sortByKey = key => sortBy(x => x[key]);

const sort = sortBy(x => x);

export { normalizeById, normalizeByKey, normalizeBy, sortBy, sortByKey, pipe, sort };
