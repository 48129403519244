import * as React from 'react';
import styled from 'styled-components';

const Base = styled.div`
  height: 2px;
  width: 26px;
  background-color: ${(props: any) => (props.open ? '#cccccc' : '#ffffff')};
  position: absolute;
  transition: all 0.25s ease-out;
  opacity: 1;
`;

const Top = Base.extend`
  ${(props: any) => (props.open ? 'top: 9px;' : 'top: 0;')}
  ${(props: any) => (props.open ? 'transform: rotate(-45deg);' : '')};
`;

const Middle = Base.extend`
  top: 9px;
  ${(props: any) => (props.open ? 'opacity: 0;' : '')};
`;

const Bottom = Base.extend`
  ${(props: any) => (props.open ? 'bottom: 9px;' : 'bottom: 0px;')}
  ${(props: any) => (props.open ? 'transform: rotate(45deg);' : '')};
`;

const HamburgerMenuIconElement: any = { Top, Middle, Bottom, Base };

const HamburgerMenuIconContainer = styled.div`
  height: 20px;
  position: relative;
`;

const HamburgerMenuIcon = (props) => {
  return (
    <HamburgerMenuIconContainer className={props.className}>
      <HamburgerMenuIconElement.Top open={props.open} className="hamburger-bar" />
      <HamburgerMenuIconElement.Middle open={props.open} className="hamburger-bar" />
      <HamburgerMenuIconElement.Bottom open={props.open} className="hamburger-bar" />
    </HamburgerMenuIconContainer>
  );
};

export default HamburgerMenuIcon;
