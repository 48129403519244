import { createTypeSelector } from 'xcel-redux-orm';
import {
    ROOT_STATE, PROGRAM_JSON_TYPE, PROGRAM_LEVEL_JSON_TYPE, ACHIEVEMENT_STATE,
    GET_ACHEIVEMENT_PROGRAM_LEVELS, GET_ACHEIVEMENT_PROGRAMS, REDEMPTION_ORDER_JSON_TYPE, GET_REDEMPTION_BY_ID, CREATE_REDEMPTION
} from '../../constants';

export const achievementProgramSelector = createTypeSelector<any>(PROGRAM_JSON_TYPE);
export const achievementProgramLevelSelector = createTypeSelector<any>(PROGRAM_LEVEL_JSON_TYPE);
export const redemptionOrderSelector = createTypeSelector<any>(REDEMPTION_ORDER_JSON_TYPE);

export const getAchievementPrograms = (state) => achievementProgramSelector.selectLast(state, GET_ACHEIVEMENT_PROGRAMS);
export const getAchievementProgramLevels = (state) => achievementProgramLevelSelector.selectLast(state, GET_ACHEIVEMENT_PROGRAM_LEVELS);
export const getRedemptionOrder = (state) => redemptionOrderSelector.selectLast(state, GET_REDEMPTION_BY_ID);
export const getCreatedRedemptionOrder = (state) => redemptionOrderSelector.selectLast(state, CREATE_REDEMPTION);

export const getProgramLevelProduct = (state) => state[ROOT_STATE][ACHIEVEMENT_STATE].product;
export const getProgramLevel = (state) => state[ROOT_STATE][ACHIEVEMENT_STATE].programLevel;

export const getProductDetailsModalVisibility = (state) => state[ROOT_STATE][ACHIEVEMENT_STATE].isProductDetailsModalOpen;
export const getOrderDetailsModalVisibility = (state) => state[ROOT_STATE][ACHIEVEMENT_STATE].isOrderDetailsModalOpen;
export const getWindowScrollY = (state) => state[ROOT_STATE][ACHIEVEMENT_STATE].windowScrollY;
export const getOrderInfo = (state) => state[ROOT_STATE][ACHIEVEMENT_STATE].order;
export const getSharePreview = (state) => state[ROOT_STATE][ACHIEVEMENT_STATE].sharePreview;
