import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';
import {
  Grid,
  Loader,
  LoadMoreButton,
  toast
  } from 'rsv8-components';
import { SORT_DIRECTIONS } from 'rsv8-components/dist/app/components/Table';
import { OrderModel } from 'xcel-api-generator/dist/catalog';
import { withResource } from 'xcel-react-core';
import { ApiDataProviderProps, withApiDataProvider } from 'xcel-redux-orm';
import EmptyList from '../../components/ItemCarousel/EmptyList';
import {
  getAccountSummary,
  getOrderById,
  postCart,
  togglePointsModal,
  toggleShippingModal
  } from '../../redux/actions';
import { orderApiActions } from '../../redux/actions/order/orderApiActions';
import {
  getCartProducts,
  getCurrentAccountSummary,
  getOrder,
  getOrderRequest,
  orderSelector
  } from '../../redux/selectors';
import Order from './Order/Order';
import OrderHistoryContext from './OrderHistoryContext';
import PointsBreakdown from './PointsBreakdown/PointsBreakdown';
import ShippingInfo from './ShippingInfo/ShippingInfo';
import { HelpCol, HelpLink, OrderHeading } from './styles';

interface OrderHistoryProps extends ApiDataProviderProps<OrderModel[]> {
  accountSummary: any;
  actions: {
    getOrders: any;
    toggleShippingModal: any;
    getAccountSummary: any;
    getOrderById: any;
    postCart: any;
    togglePointsModal: any;
  };
  id?: string;
  order: any;
  orderHistoryHeading: string;
  orderRequest: any;
  ordersMeta: any;
  emptyHistoryLabel: string;
  emptyHistoryIconPath: string;
  helpLabel: string;
  succeeded: any;
  pagination: any;
  cartProducts: any;
  helpLabelLink: string;
  helpLabelTarget: string;
  buyAgainErrorLabel: string;
  data: any;
  loading: boolean;
  loadMore: any;
}

class OrderHistory extends React.Component<OrderHistoryProps, any> {
  state = {
    shippingProduct: {},
    shippingInfo: {},
    currentPage: 0,
    recordLimit: 5,
    addCartButtonDisabled: false,
    pointsBreakdownOrder: {
      totalNonTangiblePoints: 0,
      totalPoints: 0
    },
    emptyHistory: false
  };

  componentDidMount() {
    this.props.actions.getAccountSummary();

    if (this.props.id) {
      this.props.actions.getOrderById(this.props.id).catch((err) => {
        if (err.response.status === 404) {
          this.setState({ emptyHistory: true });
        }
      });
    }
  }

  addToCart = (product, optionSelection) => {
    this.setState({ addCartButtonDisabled: true });
    this.props.actions
      .postCart(product, optionSelection)
      .catch((e) => {
        toast({
          template: 'CatalogBody',
          productImage: product.imageSm || product.productLargeImageUrl,
          productName: product.name || product.productName,
          productManufacturer: product.manufacturer || product.manufacturerName,
          actionText: this.props.buyAgainErrorLabel
        });
      })
      .finally(() => {
        this.setState({ addCartButtonDisabled: false });
      });
  };

  showShippingModal = (product, info) => {
    this.setState({ shippingProduct: product, shippingInfo: info });
    this.props.actions.toggleShippingModal();
  };

  showPointsModal = (products) => {
    this.setState({ pointsBreakdownOrder: products });
    this.props.actions.togglePointsModal();
  };

  orderProvider = (products) => {
    const providerValue = {
      accountSummary: this.props.accountSummary,
      products,
      toggleShippingModal: this.showShippingModal,
      togglePointsModal: this.showPointsModal,
      addToCart: this.addToCart,
      addCartButtonDisabled: this.state.addCartButtonDisabled,
      cartProducts: this.props.cartProducts
    };
    return (
      <OrderHistoryContext.Provider value={providerValue}>
        <Order />
      </OrderHistoryContext.Provider>
    );
  };

  orders = () => {
    const { id, order, data, accountSummary, loading } = this.props;

    if (data.length > 0 && accountSummary) {
      if (id) {
        return this.orderProvider(order);
      } else {
        return data.map(
          (products) => products.orderDetails[0] && products.orderDetails[0].productId && this.orderProvider(products)
        );
      }
    } else if ((id && !this.state.emptyHistory && !data.length) || loading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  render() {
    const {
      orderHistoryHeading,
      emptyHistoryIconPath,
      emptyHistoryLabel,
      helpLabel,
      data,
      pagination,
      loading,
      loadMore,
      succeeded,
      helpLabelLink,
      helpLabelTarget,
      id
    } = this.props;

    return (
      <Grid themeVariation="page-wrapper">
        <Row>
          <Col xs={22} xsOffset={1} sm={11} smOffset={1}>
            <OrderHeading>{orderHistoryHeading}</OrderHeading>
          </Col>
          <HelpCol xs={22} xsOffset={1} sm={12} smOffset={0} md={11}>
            <HelpLink themeVariation="primary-button" to={helpLabelLink} target={helpLabelTarget}>
              {helpLabel}
            </HelpLink>
          </HelpCol>
        </Row>
        <Row>
          <Col xs={22} xsOffset={1} sm={24} smOffset={0} md={22} mdOffset={1}>
            {this.orders()}
          </Col>
        </Row>

        {data.length ? (
          <LoadMoreButton pagingData={!id && pagination} isLoading={loading} loadMoreAction={loadMore} />
        ) : null}

        {(this.state.emptyHistory || (!loading && !succeeded)) && (
          <Row>
            <Col xs={22} xsOffset={1} sm={22} smOffset={1}>
              <EmptyList icon={emptyHistoryIconPath} label={emptyHistoryLabel} />
            </Col>
          </Row>
        )}

        <ShippingInfo shippingProduct={this.state.shippingProduct} shippingInfo={this.state.shippingInfo} />
        <PointsBreakdown displayOrder={this.state.pointsBreakdownOrder} />
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  accountSummary: getCurrentAccountSummary(state),
  order: getOrder(state, ownProps.id),
  orderRequest: getOrderRequest(state),
  cartProducts: getCartProducts(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAccountSummary,
      toggleShippingModal,
      togglePointsModal,
      getOrderById,
      postCart
    },
    dispatch
  )
});

const mapResourceToProps = (getResource) => ({
  orderHistoryHeading: getResource('catalog.orderHistory.orderHistoryHeading', 'Order History'),
  emptyHistoryLabel: getResource('catalog.orderHistory.emptyHistoryLabel', 'You have no previous orders.'),
  emptyHistoryIconPath: getResource(
    'catalog.orderHistory.emptyHistoryIconPath',
    '/clientassets/images/icons/empty-state--wishlist.svg'
  ),
  helpLabel: getResource('catalog.orderHistory.helpLabel', 'Need Help?'),
  helpLabelLink: getResource('catalog.orderHistory.helpLabelLink', '/help/overview'),
  helpLabelTarget: getResource('catalog.orderHistory.helpLabelTarget', '_self'),
  buyAgainErrorLabel: getResource(
    'catalog.orderHistory.buyAgainErrorLabel',
    'cannot be added to cart. Please contact Own It Honors Program Support for questions.'
  )
});

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  withResource(mapResourceToProps),
  withApiDataProvider(orderApiActions.getOrders, orderSelector, {
    sort: {
      field: 'id',
      direction: SORT_DIRECTIONS.desc
    },
    apiParams: { limit: 5 }
  })
)(OrderHistory);
