import { destroyRequest } from 'xcel-redux-orm';
import { productApiActions } from './productApiActions';

const getDepartments = () => async (dispatch, getState) => {
  dispatch(destroyRequest('getCategories'));
  const response = await dispatch(productApiActions.getProductsCategories({ jsonApi: true }));
  return response;
};

export { getDepartments };
