import * as React from 'react';
import { PageRenderer } from 'rsv8-cms';

export interface StepHeaderProps {
  name: string;
  instruction: string;
  currentAwardId: string;
  type: string;
  current: number;
}

const StepHeader: React.SFC<StepHeaderProps> = ({ name, instruction, type, currentAwardId, current, ...rest }) => {
  const pathHeaderContent: any = () => {
    if (type === 'individual') {
      return currentAwardId && instruction !== 'awards' && instruction !== 'nominee'
        ? `partial/nomination/${type}/${currentAwardId}/header/${instruction}`
        : `partial/nomination/${type}/header/${instruction}`;
    } else {
      return currentAwardId && instruction !== 'awards'
        ? `partial/nomination/${type}/${currentAwardId}/header/${instruction}`
        : `partial/nomination/${type}/header/${instruction}`;
    }
  };

  return <PageRenderer path={pathHeaderContent()} />;
};

export { StepHeader };
