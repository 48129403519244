import { mapJsonApiActions } from 'xcel-redux-orm';
import { nominationApi } from '../../../../api/index';
import * as events from '../../../events';

const nominationApiActions = mapJsonApiActions(nominationApi);
const setTeamMemberIds = (members) => ({ type: events.SET_NOMINATION_TEAM_MEMBER_IDS, payload: members });
const clearTeamMemberIds = () => ({ type: events.CLEAR_NOMINATION_TEAM_MEMBER_IDS});

export const getTeamMembers= (id) => async (dispatch) => {
  let memberIds = [];
  dispatch(clearTeamMemberIds());

  const teamMembers = await nominationApi.getTeamDraftByIdMembers({ id });

  teamMembers.map((member, i) => {
    memberIds.push(member.nominationDetailId);
    dispatch(nominationApiActions.getNominationsDetailsById({ id: Number(member.nominationDetailId) }, { jsonApi: true }));
  });

  dispatch(setTeamMemberIds(memberIds));
}
