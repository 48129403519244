import { Link } from 'rsv8-components';
import styled from 'styled-components';

export const ButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 992px) {
    flex-direction: column;

    &:not(:last-of-type) {
      border-right: solid 2px #e98300;
    }
  }
` as any;

export const Dashboard = styled.div`
  border: solid 2px #e98300;
  background-color: #7d0063;
  padding: 50px;
` as any;

export const Title = styled.div`
  font-family: 'CentraleSans-Regular';
  font-size: 30px;
  font-weight: 500;
  line-height: 1.17;
  color: white;
  margin-right: 30px;

  @media (max-width: 991px) {
    font-size: 20px;
  }
` as any;

export const DetailsLink = styled(Link)`
  color: #e98300 !important;
  font-size: 15px !important;
` as any;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
` as any;

export const Information = styled.div`` as any;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
` as any;

const DashboardText = styled.div`
  color: #e98300;
  font-size: 25px;
  font-weight: 500;
  font-family: 'CentraleSans-Regular';
  line-height: 1.4;
  @media (min-width: 992px) and (max-width: 1365px) {
    font-size: 20px;
  }
  @media (max-width: 991px) {
    font-size: 15px;
  }
` as any;

export const Description = styled(DashboardText)`
  flex-basis: 75%;
`;

export const ButtonText = styled(DashboardText)`
  text-align: center;
  @media (min-width: 992px) {
    margin-top: 15px;
  }
` as any;

export const Value = styled.div`
  font-size: 50px;
  font-weight: 500;
  color: white;
  line-height: 1.2;
  flex-grow: 1;
  text-align: center;

  @media (min-width: 992px) and (max-width: 1365px) {
    font-size: 40px;
  }
  @media (max-width: 991px) {
    font-size: 30px;
  }
` as any;

export const Buttons = styled.div`
  border-top: solid 2px #e98300;
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
` as any;

export const ButtonColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 992px) {
    flex-direction: column;

    &:not(:last-of-type) {
      border-right: solid 2px #e98300;
    }
  }

  padding: 10px;
  /* white-space: nowrap; */
  margin-top: 40px;
  flex-grow: 1;
  flex-basis: 33%;
` as any;

export const DashboardIcon = styled.div`
  width: 50px;
  height: 50px;

  @media (max-width: 1365px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 991px) {
    margin-right: 20px;
  }
` as any;
