import { destroyRequest } from 'xcel-redux-orm';
import { ensureArray } from 'xcel-util';
import { NOMINATION_SUBMISSION_ENDPOINT_NAME, NOMINEE_SEARCH_ENDPOINT_NAME } from '../../../constants';
import { IndividualNomination, Resources } from '../../../types';
import { CLEAR_INDIVIDUAL_NOMINATION, SET_INDIVIDUAL_NOMINATION } from '../../events';

const clearNomination = (): IndividualNomination.Action => ({
  type: CLEAR_INDIVIDUAL_NOMINATION
});

export const clearIndividualNomination = () => (dispatch, getState) => {
  dispatch(clearNomination());
  dispatch(destroyRequest(NOMINEE_SEARCH_ENDPOINT_NAME));
  dispatch(destroyRequest(NOMINATION_SUBMISSION_ENDPOINT_NAME));
};

export const setIndividualNomination = (draftObject: Resources.DraftSubmission): IndividualNomination.Action => ({
  type: SET_INDIVIDUAL_NOMINATION,
  payload: {
    id: draftObject.nominationDetailId,
    nominee: draftObject.nomineeId.toString(),
    award: draftObject.nominationApplicationKey,
    points: draftObject.incentiveValue,
    product: draftObject.nominationProductId,
    optionId: draftObject.nominationOptionId,
    optionPointValue: draftObject.nominationOptionPointValue,
    customFields: draftObject.customFields.map((v) => ({ id: v.id.toString(), values: ensureArray(v.values) })),
    incentive: draftObject.incentive,
    clearCurrentNominationData: false
  }
});
