import * as React from 'react';
import { isNil } from 'lodash';
import { transformFieldProps } from '../HOC';
import { BSInputProps as InputProps, BSMetaProps as MetaProps } from './types';

export type FieldFormat<V = any, F = any> = (value: V, name: string) => F;

export type FieldValueHandler<V = any> = (event: any, value: V, previousValue: V, name: string) => void;

export type FieldEventHandler = (event: any, name: string) => void;

export type FieldOnChangeHandler<V = any> = (value: V, previousValue: V, name: string) => void;

interface Props {
  name: string;
  component: any;
  format?: FieldFormat;
  parse?: FieldFormat;
  onBlur?: FieldValueHandler;
  onChange?: FieldOnChangeHandler;
  onDragStart?: FieldEventHandler;
  onDrop?: FieldValueHandler;
  onFocus?: FieldEventHandler;
  onKeyDown?: FieldEventHandler;
  validate?: Array<Function | String>;
  warn?: Array<Function | String>;
  value?: any;
  initialValue?: any;
  props?: any;
  [rest: string]: any;
}

interface State {
  hasFocus: boolean;
  touched: boolean;
  visited: boolean;
  hasError: boolean;
  hasWarning: boolean;
  error: string | undefined;
  warning: string | undefined;
  pristine: boolean;
  value: any;
  previousValue: any;
  initialValue: any;
}

const flatten = (arr) => {
  return [].concat(...arr);
};

export const calculateValid = (value, ...fns) => {
  return flatten(fns.map((fn) => fn(value))).filter((res) => {
    return typeof res === 'string';
  });
};

export const isArrayValid = (value: Array<string>) => value.length === 0;

export const firstOrUndefined = <P extends any>(list: P[]): P | undefined => list[0] || undefined;

export const refreshValueState = (props) => (value, prevValue) => {
  const calcValid = props.validate ? calculateValid(value, ...props.validate) : [];
  const calcWarning = props.warn ? calculateValid(value, ...props.warn) : []; // ?
  return {
    value: value,
    previousValue: prevValue,
    hasError: !isArrayValid(calcValid),
    hasWarning: !isArrayValid(calcWarning),
    error: firstOrUndefined(calcValid),
    warning: firstOrUndefined(calcWarning),
    pristine: value === props.initialValue || (props.initialValue === undefined && !value),
  };
};

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;

// Wraps a component in Bootstrap Styling, but manages state internally
// and passes only a subset of the event/state props that redux-form does
class ReactField extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const newValue = nextProps.value !== undefined ? nextProps.value : prevState.value;
    return refreshValueState(nextProps)(newValue, prevState.value);
  }

  constructor(props: Props) {
    super(props);
    const initialValue = props.value || props.initialValue || '';
    this.state = {
      hasFocus: false,
      visited: false,
      touched: false,
      pristine: true,
      hasError: false,
      error: null,
      warning: null,
      hasWarning: false,
      value: initialValue,
      previousValue: initialValue,
      initialValue: props.initialValue || initialValue,
    };
  }

  format = (v) => {
    return this.props.format ? this.props.format(v, this.props.name) : v;
  };

  parse = (v) => {
    return this.props.parse ? this.props.parse(v, this.props.name) : v;
  };

  updateValue = (value) => this.setState(refreshValueState(this.props)(value, this.state.value));

  onChange = (e) => {
    const value = e.target ? e.target.value : e;
    if (this.props.onChange) {
      this.props.onChange(this.parse(value), this.parse(this.state.value), this.props.name);
    }
    if (this.props.value === undefined) {
      this.updateValue(value);
    }
  };

  onDragStart = (e) => {
    if (this.props.onDragStart) {
      this.props.onDragStart(e, this.props.name);
    }
  };

  onDrop = (e) => {
    if (this.props.onDrop) {
      this.props.onDrop(e, this.parse(this.state.value), this.parse(this.state.previousValue), this.props.name);
    }
  };

  onBlur = (e) => {
    this.setState({
      touched: true,
      hasFocus: false,
    });
    if (this.props.onBlur) {
      this.props.onBlur(e, this.parse(e.target.value), this.state.value, this.props.name);
    }
  };

  onFocus = (e) => {
    this.setState({
      hasFocus: true,
      visited: true,
    });
    if (this.props.onFocus) {
      this.props.onFocus(e, this.props.name);
    }
  };

  onKeyDown = (e) => {
    const target = e.target as HTMLInputElement;

    // fix default input behaviour for number inputs
    // arrow up/down should increase/decrease current value by step with saving decimals places
    // by default it is updating native input value only on blur so before it old value is changing
    if (target.tagName === 'INPUT' || target.type === 'number') {
      const getFractionDigits = (): number => {
        const value = e.target.value;
        const decimalIndex = value.indexOf('.');

        if (decimalIndex === -1) {
          return 0;
        }

        return value.length - decimalIndex - 1;
      };

      const getStep = (): number => {
        const step = this.props.step;
        return isNil(step) ? 1 : Number(step);
      };

      const changeValue = (delta: number) => {
        const fractionDigits = getFractionDigits();

        const value = Number(target.value);

        // keep the same number of decimal places
        // without it can be rounded issues like 32.38 - 1 = 31.380000000000003
        const newValue = (value + delta).toFixed(fractionDigits);

        // use native input value setter, because it is overridden by react
        // without it in react v16 onChange event dispatching will not work
        nativeInputValueSetter.call(e.target, newValue);

        // dispatch onChange event
        e.target.dispatchEvent(new Event('input', { bubbles: true }));

        e.preventDefault();
      };

      if (e.key === 'ArrowUp') {
        changeValue(getStep());
      } else if (e.key === 'ArrowDown') {
        changeValue(-getStep());
      }
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(e, this.props.name);
    }
  };

  isPristine = (value) => {
    return value === this.state.initialValue;
  };

  getMetaProps = (state: State): MetaProps => ({
    active: state.hasFocus,
    dirty: !state.pristine,
    touched: state.touched,
    pristine: state.pristine,
    visited: state.visited,
    initial: state.initialValue,
    valid: !state.hasError,
    invalid: state.hasError,
    error: state.error,
    warning: state.warning,
  });

  getInputProps = (state: State): InputProps => ({
    name: this.props.name,
    onDragStart: this.onDragStart,
    onChange: this.onChange,
    onFocus: this.onFocus,
    onBlur: this.onBlur,
    onDrop: this.onDrop,
    onKeyDown: this.onKeyDown,
    value: this.format(state.value),
    checked: !!state.value,
  });

  getExtraProps = (myProps: Props) => {
    const {
      name,
      component,
      format,
      parse,
      onBlur,
      onChange,
      onDragStart,
      onDrop,
      onFocus,
      onKeyDown,
      validate,
      warn,
      value,
      initialValue,
      props,
      ...rest
    } = myProps;
    return { ...props, ...rest };
  };

  getAllProps = (simpleInput: boolean) => {
    if (simpleInput) {
      return { ...this.getInputProps(this.state), ...this.getExtraProps(this.props) };
    } else {
      return {
        input: this.getInputProps(this.state),
        meta: this.getMetaProps(this.state),
        ...this.getExtraProps(this.props),
      };
    }
  };

  render() {
    const simpleInput = typeof this.props.component === 'string';
    const InputComponent = this.props.component;
    const allProps = this.getAllProps(simpleInput);
    return <InputComponent {...allProps} />;
  }
}

export default transformFieldProps(ReactField);
