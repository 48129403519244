import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { withErrorBoundary } from 'xcel-react-core';
import ComponentAdd from '../ComponentAdd';
import SideBar from '../SideBar';
import ActivityEditForm from '../../views/ContentEditSidebar/ActivityEditForm';
import ContentProperties from '../../views/ContentEditSidebar/ContentProperties';
import { ComponentStuctureTreeStyled } from './styles';

let ContentManager = (props) => (
  <SideBar>
    <Tabs defaultActiveKey={1} id="content-edit-tab">
      <Tab eventKey={1} title="Properties">
        {props.activity ? <ActivityEditForm {...props} /> : <ContentProperties />}
      </Tab>
      <Tab eventKey={2} title="Components">
        <ComponentStuctureTreeStyled />
        <ComponentAdd />
      </Tab>
    </Tabs>
  </SideBar>
);

export default withErrorBoundary()(ContentManager);
