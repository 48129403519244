import { Modal } from 'react-bootstrap';
import { HeaderThree } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';

export const BrandsCheckboxGroup = styled(ReactField)`` as any;

export const BrandGroupAlpha = styled.div`
  color: #4192a5;
  font-family: LucidaGrande, Helvetica, Arial;
  margin-left: 9px;
  margin-bottom: 3px;
` as any;

export const BrandGroup = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(20% - 5px);
` as any;

export const BrandsModalContainer = styled(Modal)`
  .modal-dialog {
    width: calc(100vw - 200px);
  }
` as any;

export const BrandsModalBody = styled(Modal.Body)`
  height: calc(100vh - 300px);
  overflow-y: auto;
  width: 100%;

  .form-group {
    .control-label {
      display: none;
    }
    .checkbox {
      margin-top: 0;
    }
  }
` as any;

export const BrandsHeader = styled(Modal.Header)`
  border-bottom: 0;
` as any;

export const BrandsTitle = styled(HeaderThree)`
  color: #4c4f4f;
` as any;
