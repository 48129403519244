import * as React from 'react';
import { ModalProps } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import {
  Loader,
  Modal,
  ModalBody,
  ModalHeader
} from 'rsv8-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { callAll, Omit } from 'xcel-util';
import { nominationActions, nominationActivityActions, nominationActivitySelectors } from '../../redux/index';
import { NominationActivity, Resources } from '../../types';
import DashboardModalBody from './DashboardModalBody';
import { isPendingApproval, isTeam } from './util';

export interface NominationDetailModalProps extends Partial<Omit<ModalProps, 'show'>> {
  nominationId: string | number;
  nomination: Resources.NominationDetail;
  nominationType: NominationActivity.ActivityTables;
  show: boolean;
  hideModal: typeof nominationActivityActions.hideModal;
  loadNomination: typeof nominationActivityActions.getNominationDetails;
  loadTeamNomination: typeof nominationActions.getTeamById;
  cultureId: any;
  onHide: any;
}

class DashboardModal extends React.PureComponent<NominationDetailModalProps, {}> {
  state = { loading: false };

  componentDidMount() {
    if (this.props.nominationId && this.props.nominationType) {
      this.handleLoad();
    }
  }

  componentDidUpdate(prevProps: NominationDetailModalProps) {
    if (
      prevProps.nominationId !== this.props.nominationId ||
      prevProps.nominationType !== this.props.nominationType ||
      prevProps.cultureId !== this.props.cultureId
    ) {
      this.handleLoad();
    }
  }

  handleLoad = async () => {
    if (isTeam(this.props.nomination, this.props.nominationType)) {
      this.props.loadTeamNomination({ id: Number(this.props.nominationId) });
      // When I get nominationDetailID, I can enable this
    } else if (isPendingApproval(this.props.nomination, this.props.nominationType)) {
      this.props.loadNomination(this.props.nominationId);
    } else {
      this.props.loadNomination(this.props.nominationId);
    }
  };

  handleHide = (...args: any[]) => callAll(this.props.hideModal, this.props.onHide)(...args);

  render() {
    const { onHide, hideModal, nomination, nominationId, nominationType, loadNomination, ...rest } = this.props;
    return (
      <Modal onHide={this.handleHide} {...rest}>
        <ModalHeader closeButton={true} />
        <ModalBody>
          {nomination && nomination.id && !this.state.loading ? (
            <DashboardModalBody nomination={nomination} type={nominationType} />
          ) : (
            <Loader />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

const mapState = (state, ownProps) => {
  const modalId = ownProps.nominationId || nominationActivitySelectors.getModalId(state);
  const modalType =
    ownProps.nominationType ||
    (nominationActivitySelectors.getNominationActivityState(state)
      .detailsModalType as NominationActivity.ActivityTables);
  return {
    show: nominationActivitySelectors.getModalVisibility(state),
    nominationId: modalId,
    nomination: nominationActivitySelectors.getModalNominationDetail(state, modalId, modalType),
    nominationType: modalType,
    cultureId: cultureSelector.getActiveCultureId(state)
  };
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModal: nominationActivityActions.hideModal,
      loadNomination: nominationActivityActions.getNominationDetails,
      loadTeamNomination: nominationActions.getTeamById
    },
    dispatch
  );

export default register('rsv8-nomination/NominationDetailModal')(
  connect<ReturnType<typeof mapState>, ReturnType<typeof mapDispatch>>(
    mapState,
    mapDispatch
  ),
  withTheme(),
  withContent(),
)(DashboardModal as any) as React.ComponentType<{}>;
