import * as React from 'react';
import { Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { VersionDropdown } from 'rsv8-cms';
import styled from 'styled-components';
import { themePlugin } from '../../../config';
import ColorAdd from '../colors/ColorAdd';
import ColorManage from '../colors/ColorManage';
import FontAdd from '../fonts/FontAdd';
import FontManage from '../fonts/FontManage';

const StyledGrid = styled(Grid)`
  margin-bottom: 40px;
`;

class ThemesView extends React.Component<any> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(themePlugin.actions.adminGet());
  }
  render() {
    return (
      <StyledGrid bsClass="container-fluid">
        <VersionDropdown />
        <h3>Colors</h3>
        <hr />
        <ColorAdd />
        <ColorManage />
        <hr />
        <h3>Typography</h3>
        <hr />
        <FontAdd />
        <FontManage />
      </StyledGrid>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(ThemesView);
