import * as React from 'react';
import styled from 'styled-components';
import { ErrorBoundaryComponent } from 'xcel-react-core';
import { DataContext } from '../../../module';
import HoverContext from '../../hoc/withHover/HoverContext';
const ComponentEditableBase = styled(ErrorBoundaryComponent)`
  /* border: 0px solid transparent; */
  transition: all 200ms;
  ${(props: any) => {
    return (
      props.active &&
      `
      position: relative;
      z-index: 888;
      outline: 10px solid rgba(0, 0, 0, 0.05) !important;
      `
    );
  }};
` as any;
class Editable extends React.Component {
  shouldComponentUpdate(nextProps: any) {
    return ['active', 'children', 'content', 'className'].reduce((shouldUpdate, key) => {
      if (shouldUpdate) return shouldUpdate;
      return this.props[key] !== nextProps[key];
    }, false);
  }
  render() {
    return <ComponentEditableBase {...this.props} />;
  }
}
const ComponentEditable = (props) => {
  return (
    <HoverContext.Consumer>
      {({ isOver, onMouseOver, onMouseLeave }: { isOver: any; onMouseOver: any; onMouseLeave: any }) => {
        return (
          <DataContext.Consumer>
            {(data) => (
              <Editable
                {...props}
                {...data}
                onMouseOver={onMouseOver(props)}
                onMouseLeave={onMouseLeave(props)}
                active={isOver(props.model)}
                data-cms-editing={true}
              />
            )}
          </DataContext.Consumer>
        );
      }}
    </HoverContext.Consumer>
  );
};

// const isEditing = props.active !== undefined && props.active.model === props.model;

export default ComponentEditable;
