import * as React from 'react';
import { connect } from 'react-redux';
import { register, withTheme } from 'xcel-react-core';
import { Link } from 'rsv8-components';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getProgram } from "../../redux/actions";
import { programSelector } from '../../redux/selectors';

const ProgramDetailsLink = ({program, ...rest}) => {
    const [loading, setLoading]= React.useState(true);
    React.useEffect(() => {
        if (!program) {
          return;
        }
    
        setLoading(false);
    }, [program]);    

    console.log('program', program);
    
    if (!loading && program) {
        const {content, children} = rest;                 
        return (
            <Link href={program.programDetailsUrl} {...content} >
                {children}
            </Link>
        );
    } else {
        return null;
    }
};

const mapStateToProps = state => ({        
    program: programSelector.selectMany(state) ? programSelector.selectMany(state)[0] : null
});

export default register("rsv8-app/ProgramDetailsLink")(
  connect(mapStateToProps),
  withApiDataProvider(getProgram, programSelector),    
   withTheme())
   (ProgramDetailsLink);
