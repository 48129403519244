import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CodeEditor } from 'rsv8-cms';
import styled from 'styled-components';
import { exampleRemove } from '../../redux/example.actions';
import { mapDispatchToActions } from '../../util';
const Example = styled.div`
  height: 240px;
`;
const ExampleToolbar = styled.span`
  color: white;
  cursor: pointer;
  font-size: 16px;
  z-index: 9;
  position: absolute;
  right: 20px;
`;
const ExampleEditor = styled.div`
  height: 200px;
`;
const ComponentExampleCode: React.SFC<any> = ({
  index,
  value,
  name,
  isRemovable,
  isEditable,
  checked,
  onChange,
  onBlur,
  actions,
  ...rest
}) => {
  const handleRemove = () => {
    actions.exampleRemove({ index, name });
  };
  return (
    <Example {...rest}>
      <Col sm={24}>
        {/* will add this back later :) <input className={'pull-left'} type={'radio'} name={name} checked={checked} /> */}
        <ExampleToolbar className="pull-right">
          <i className="fa fa-trash" onClick={handleRemove} />
        </ExampleToolbar>
        <ExampleEditor>
          <CodeEditor language={'html'} isEditable={isEditable} onChange={onBlur} value={value} />
        </ExampleEditor>
      </Col>
    </Example>
  );
};

const mapStateToProps = (state) => ({});
const mapActionsToProps = mapDispatchToActions({ exampleRemove });
export default connect(
  mapStateToProps,
  mapActionsToProps
)(ComponentExampleCode) as any;
