import * as events from './events';
import userSelector from './selectors/user';
const userReducer = (state, action) => {
  state = state === undefined ? {} : state;

  switch (action.type) {
    case events.USER_DATA_SET: {
      const user = {
        ...state,
        ...action.payload,
        mustResetPassword: false
      };
      return user;
    }
    case events.AUTH_TOKEN_CREATE_SUCCESS:
    case events.AUTH_CODE_CREATE_SUCCESS:
    case events.AUTH_TOKEN_BASIC_CREATE_SUCCESS: {
      return { ...state, ...userSelector.getUserWithToken(action.payload.accessToken) };
    }
    case events.USER_FORGOT_USERNAME_FAILURE: {
      return { ...state, forgotUsername: action.payload };
    }
    case events.USER_FORGOT_USERNAME_SUCCESS: {
      delete state.forgotUsername;
      return { ...state };
    }
    case events.USER_GET_ACCESS_KEY_REQUEST: {
      return { ...state, isValidatingKey: true};
    }
    case events.USER_GET_ACCESS_KEY_SUCCESS:
    case events.USER_GET_ACCESS_KEY_FAILURE: {
      return { ...state, forgotPassword: action.payload, isValidatingKey: false };
    }
    case events.USER_PASSWORD_RESET_SUCCESS: {
      delete state.forgotPassword;
      return { ...state };
    }
    case events.USER_LOGOUT: {
      const user = {};
      return user;
    }
    case events.USER_PASSWORD_CHANGE_SUCCESS: {
      return { ...state, mustResetPassword: false };
    }
    case events.USER_MUST_CHANGE_PASSWORD: {
      return { ...state, mustResetPassword: true }
    }
    default: {
      return state;
    }
  }
};
export default userReducer;
