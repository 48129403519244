import * as React from 'react';
import { Button } from 'rsv8-components';
import styled, { css } from 'styled-components';
/**
 * NOTE: These should all be stateless and completely
 * ignorant of any specific logic. Functional components preferred.
 */

const FieldContainer = styled.div`
  display: block;
  margin: 0 0 25px;
` as any;

const ErrorSpan = styled.span`
  display: block;
  background-color: #d8374b;
  padding: 2px;
  border-radius: 4px;
  color: red;
` as any;

const isRequired = css`
  &::after {
    content: '*';
    display: inline;
    color: #d8374b;
    padding-left: 3px;
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  color: #4c4f4f;
  font-size: 0.9em;
  font-family: Helvetica;
  margin-bottom: 2px;
  ${(props: any) => (props.required ? isRequired : '')};
` as any;

const Input = styled.input`
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid grey;
  font-size: 1.2em;
  line-height: 1.2em;
  width: 100%;
  border-radius: 3px;
  padding: 5px 2px;
` as any;

const SubmitButton = (props) => (
  <Button type="submit" {...props}>
    {props.value}
  </Button>
);

export { FieldContainer, ErrorSpan, Label, Input, SubmitButton };
