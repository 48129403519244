import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import CaretIcon from '../Icons/CaretIcon';

const NavCaretIcon = styled(CaretIcon)`
  display: inline-block;
  height: 15px;
  transform: rotate(90deg);
` as any;

const NavigationItemBase = styled.li`
  float: left;
  width: ${(props: any) => (props.numberOfLinks ? 100 / props.numberOfLinks + '%' : '')};
  list-style: none;
  position: relative;
  margin-right: 0;

  & a {
    display: block;
    color: #606060;
    padding: 20px 0;
    text-align: center;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-color: transparent;

    &.nav-link-selected {
      border-color: #b5be35;
    }

    @media (min-width: 769px) {
      padding: 20px;
    }
  }

  @media (min-width: 769px) {
    width: auto;
    display: inline-block;
    font-size: 1.05em;
    margin: 0;

    &:first-of-type {
      padding-left: 0;
    }
  }

  & ul {
    text-align: none;
    position: absolute;
    display: none;
    width: auto;
    white-space: nowrap;
    height: auto;
    left: 20px;
    top: 64px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  }

  &:hover ul {
    display: block;
  }

  & ul li {
    text-align: left;
    display: block;
    float: none;
    font-size: 0.8em;

    &:first-of-type {
      border-bottom: 1px solid #cccccc;
    }
  }
` as any;

const NavigationItem = (props) => {
  const mergedProps = { ...props, link: props.link || { to: props.to, label: props.label } };
  const hasNestedLinks = mergedProps.link.children && mergedProps.link.children.length;

  // TODO: get rid of this code smell.
  // It'd be much cleaner if the `numberOfLinks` prop was directly passed
  // to the `NavigationItemBase` component.
  return (
    <NavigationItemBase numberOfLinks={props.numberOfLinks} scrollable={props.scrollable}>
      <NavLink to={mergedProps.link.targetUrl || mergedProps.link.to || ''} activeClassName="nav-link-selected">
        {mergedProps.link.label ? <span>{mergedProps.link.label}</span> : null}
        {props.children}
        {hasNestedLinks ? <NavCaretIcon dark /> : null}
      </NavLink>
      {hasNestedLinks ? <NavigationItemGroup links={mergedProps.link.children} hidden={true} /> : null}
    </NavigationItemBase>
  );
};

// TODO: Determine wether we'll need a clearfix class on this.
const NavigationItemGroupBase = styled.ul`
  margin: 0;
  padding: 0;
  white-space: ${(props: any) => (props.scrollable ? 'nowrap' : 'normal')};
  overflow: ${(props: any) => (props.scrollable ? 'auto' : '')};
` as any;

// TODO: derive the number of links from either the length of the `links` prop or
// that of the `children` prop.
const NavigationItemGroup = ({ links = [], children, scrollable }: any) => (
  <NavigationItemGroupBase scrollable={scrollable}>
    {links.map((link) => (
      <NavigationItem link={link} numberOfLinks={links.length} scrollable={scrollable} />
    ))}
    {children && children.map((c) => React.cloneElement(c, { ...c.props, scrollable: scrollable }))}
  </NavigationItemGroupBase>
);

export { NavigationItem, NavigationItemBase, NavigationItemGroupBase, NavigationItemGroup };
