import styled from 'styled-components';

const LoginBackground = styled.div`
  min-height: calc(100vh - 110px);
  padding: 55px 0;
  width: 100%;
  @media (max-width: 500px) {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
  }
` as any;

export default LoginBackground;
