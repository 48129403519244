import * as React from 'react';
// import styled from 'styled-components'
class SegmentedControl extends React.Component<any, any> {
  handleClick = (option) => (e) => {
    const { multiple = false, onChange, input } = this.props;
    let value = this.props.input.value.slice();
    if (this.props.input.value.indexOf(option.value) === -1) {
      if (multiple) {
        value.push(option.value);
      } else {
        value = [option.value];
      }
    } else {
      value.splice(this.props.input.value.indexOf(option.value), 1);
    }

    this.setState({ value });
    if (onChange) {
      onChange(value);
    }
    if (input.onChange) {
      input.onChange(value);
    }
  };
  isActive = (option) => {
    return this.props.input.value.indexOf(option.value) !== -1 ? 'active' : '';
  };
  render() {
    const { options } = this.props;
    return (
      <div className="btn-group input-group">
        {options.map((option, index) => (
          <span key={index} className={`btn btn-default ${this.isActive(option)}`} onClick={this.handleClick(option)}>
            {option.label}
          </span>
        ))}
      </div>
    );
  }
}

export default SegmentedControl;
