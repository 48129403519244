import * as React from 'react';
import { Col } from 'react-bootstrap';
import { register, withResource } from 'xcel-react-core';
import { HeaderOne } from "rsv8-components";
import { PaddedRow, CentredContainer } from "./Elements";

interface InternalProps {
    resource: {
        receivingCodeErrorHeader: string;
    };
}

const ErrorForm: React.SFC<InternalProps> = (props) => {

    return (
        <React.Fragment>
            <PaddedRow>
                <Col>
                    <CentredContainer>
                        <HeaderOne themeVariation="primary-link-3xl-bold-text">
                            {props.resource.receivingCodeErrorHeader}
                        </HeaderOne>
                    </CentredContainer>
                </Col>
            </PaddedRow>

        </React.Fragment>
    );
};

const mapResource = (getResource) => ({
    resource: {
        receivingCodeErrorHeader: getResource('nominations.receivingCodeErrorHeader', 'Your code is not found'),
    }
});

export default register('rsv8-nomination/RedeemingErrorForm')(
    withResource(mapResource),
)(ErrorForm);
