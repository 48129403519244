import { ProviderModel } from 'xcel-api-generator/dist/cms';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { contentSelector } from './index';
const providerSelector = {
  ...createTypeSelector<ProviderModel>('providerModel'),
  getActive: (state, contentTypeId) => {
    const { providerId } = contentSelector.getActiveProperty(state, contentTypeId);
    if (!providerId) return null;
    return providerSelector.selectOne(state, providerId);
  }
};

export default providerSelector;
export { Resource };
