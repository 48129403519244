import { mapObjIndexed } from 'ramda';
import * as React from 'react';
import { withRouter } from 'react-router';
import { register, withResource } from 'xcel-react-core';
import BackendResourceContext, { BackendResourceContextValue, BackendResourceData } from './BackendResourceContext';
import resources, { ErrorMap as BackendResourceMap } from './resources';

interface Props {
  resources: BackendResourceContextValue;
}

type State = BackendResourceContextValue;

const transformErrorMap = mapObjIndexed<BackendResourceMap, BackendResourceData>((value, key, errs) => ({
  backendKey: key,
  resourceKey: value.resourceKey,
  params: []
}));

class BackendResourceProvider extends React.Component<Props, State> {
  state = {
    resourceIndex: transformErrorMap(resources),
    resources: {}
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    return { resources: nextProps.resources };
  }

  render() {
    return <BackendResourceContext.Provider value={this.state}>{this.props.children}</BackendResourceContext.Provider>;
  }
}

const mapResource = (getResource) => ({
  resources: mapObjIndexed<BackendResourceMap, string>((val, key) =>
    getResource(val.resourceKey, val.defaultValue || key)
  )(resources)
});

export default register('rsv8-components/BackendResourceProvider')(withRouter, withResource(mapResource))(
  BackendResourceProvider
) as React.ComponentType<{}>;
