import { promiseDispatcher } from 'xcel-react-core';
import { customFieldApi } from '../../api';
import * as types from './types';
import { normalizeByKey } from '../helpers';

const getFormTypesSuccess = (response, params) => {
  const formNames = normalizeByKey('key')(response);
  Object.keys(formNames).forEach(k => (formNames[k] = {}));
  return {
    type: types.GET_FORM_TYPES_SUCCESS,
    payload: formNames
  };
};

export default promiseDispatcher(customFieldApi.getCustomfields, {
  request: types.GET_FORM_TYPES_REQUEST,
  success: getFormTypesSuccess,
  failure: types.GET_FORM_TYPES_FAILURE
});
