import { ProgressBar } from 'react-bootstrap';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
  } from 'rsv8-components';
import styled from 'styled-components';

export const LandingModal = styled(Modal as any)`
  @media (min-width: 601px) {
    .modal-dialog {
      height: 75vh;
      width: 95vw;
    }
  }
  .modal-content {
    position: relative;
  }
` as any;

export const LandingFooter = styled(ModalFooter as any)`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0;
  }
` as any;

export const LandingBody = styled(ModalBody as any)`
  overflow: scroll;
  padding: 0;

  @media (min-width: 601px) {
    height: 75vh;
  }
` as any;

export const RightButton = styled(Button)`
  white-space: nowrap;
  margin-right: 15px;
` as any;

export const NextButton = styled(Button)`
  white-space: nowrap;
` as any;

export const MobileProgressBar = styled(ProgressBar)`
  margin: 0;
  border-radius: 0;
  height: 5px;
  visibility: ${(props: { current: number }) => (props.current <= 1 ? 'hidden' : 'visible')};
` as any;

export const LandingProgressBar = styled(ProgressBar)`
  margin: 0;
  visibility: ${(props: { current: number }) => (props.current <= 1 ? 'hidden' : 'visible')};
` as any;

export const ProgressContainer = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    display: none;
  }
` as any;

export const FooterButtonContainer = styled.div`
  display: flex;
  width: 90%;
  @media (max-width: 600px) {
    padding: 15px;
    width: 100%;
  }
` as any;

export const PostponedButtonContainer = styled.div`
  margin-right: 25px;
` as any;

export const RightButtonContainer = styled.div`
  display: flex;
  margin-left: 25px;
  @media (max-width: 600px) {
    margin-left: auto;
  }
` as any;

export const PostponedButton = styled(Button)`
  visibility: ${(props: { show: boolean }) => (props.show ? 'visible' : 'hidden')};
` as any;
