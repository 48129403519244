import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getThemeComponents } from '../../../config';
import cmsPluginSelectors from '../../redux/cms.plugin.selectors';
import { mapDispatchToActions } from '../../util';
import { ComponentLink } from './components';
const ComponentSelector = ({ name, active, match, ...rest }: any) => {
  return (
    <li {...rest} key={name}>
      <ComponentLink name={name} title={name} match={match}>
        {name.replace(/.+\./, '')}
      </ComponentLink>
    </li>
  );
};
const components = getThemeComponents();
const ComponentSelectorStyled = styled(ComponentSelector)`
  a {
    color: black;
    text-decoration: none;
    font-size: 14px;
  }
  font-weight: 700;
  list-style: none;
  padding: 2px 5px;
  border-radius: 5px;
  ${(props) => props.active && 'background-color:black; a{color:white;}'};
` as any;
const ComponentSelectContainer = styled.ul`
  padding: 10px;
`;

const ComponentSelectList = ({ name, examples, themeComponents = components, parent = '', match }: any) => {
  return (
    <ComponentSelectContainer>
      {Object.keys(themeComponents).map((key, index) => {
        if (themeComponents[key].namespace === undefined) {
          const label = parent === '' ? key : `${parent}.${key}`;
          return (
            <React.Fragment key={key}>
              <h6>{label}</h6>
              <ComponentSelectList
                name={name}
                examples={examples}
                parent={label}
                themeComponents={themeComponents[key]}
                match={match}
              />
            </React.Fragment>
          );
        } else {
          const namespace = themeComponents[key].namespace;
          return <ComponentSelectorStyled active={namespace === name} key={key} name={namespace} match={match} />;
        }
      })}
    </ComponentSelectContainer>
  );
};

const mapStateToProps = (state) => ({
  examples: cmsPluginSelectors.getExamples(state)
});
const mapDispatchToProps = mapDispatchToActions({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentSelectList) as any;
