import { Grid, GridColumn, Text } from 'rsv8-components';
import styled from 'styled-components';

export const NewIntro = styled.div`
  margin-bottom: 50px !important;
  .newIntro {
    margin-bottom: 10px !important;
  }
` as any;

export const StyledText = styled(Text)`
  font-size: 16px !important;
  &.goal-value {
    text-align: right;
  }
` as any;

export const StyledTextIntro = styled(Text)`
  font-size: 14px !important;
` as any;

export const MyGoalsContainer = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(34, 34, 34, 0.2);
  padding: ${(props: any) => (props.largePadding ? '150px 15%' : '50px 15%')};
  text-align: center;
  min-height: 525px;
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
` as any;

export const GoalItemContainer = styled(Grid)`
  background-color: #eeeeee;

  border-radius: 3px;
  color: #333333;
  display: flex;
  margin: 7px 0;
  padding: 15px 20px 12px;
` as any;

export const GoalItem = styled(GridColumn)`
  display: inline-block;
` as any;

export const GoalItemInput = styled.input`
  width: 100%;
  background: transparent;
  border-bottom: 1px solid #333333;
  border-left: none;
  border-right: none;
  border-top: none;
  color: #333333;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  &:focus {
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Firefox */
   &[type=number] {
    -moz-appearance:textfield;
    }
` as any;

export const Intro = styled.div`
  text-align: center;
` as any;

export const Result = styled.div`
  margin-top: 20px;
  text-align: center;
` as any;

export const CompletedContainer = styled(Grid)`
  display: block;
  margin: 12px 0;
  text-align: left;
` as any;

export const ResultsContainer = styled.div`
  display: block;
  margin: 30px 0;
` as any;

export const CompletedNumber = styled(GridColumn)`
  text-align: right;
` as any;
