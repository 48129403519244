import ValidationProvider from './ValidationProvider';

// Build an array of validator functions from an array of strings
const ValidationBuilder = validations => {
  return validations.map((name, i) => {
    return ValidationProvider.getValidation(name);
  });
};

export default ValidationBuilder;
