import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Switch, withRouter } from 'react-router-dom';
import { AuthRoute } from 'rsv8-auth';
import { SourceEditor } from 'rsv8-cms';
import { FabButton } from 'rsv8-components';
import styled from 'styled-components';
import { themePlugin } from '../../config';
import PublishButton from '../components/PublishButton';
import ComponentEdit from '../views/components/ComponentEdit';
import ComponentList from '../views/components/ComponentList';
import ThemesView from '../views/themes';
const Nav = styled.div`
  text-align: center;
  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: gray;
    }
  }
`;
const SourceEditButton = styled(FabButton)`
  transform: translateX(-100%);
  z-index: 5000;
` as any;

class DefaultLayout extends React.Component<any> {
  state = {
    sourceOpen: false
  };
  componentDidMount() {
    const { dispatch } = this.props;
    // we need to set the active content type.
    dispatch(themePlugin.actions.setActive());
  }
  handleOpenSource = () => {
    this.setState({ sourceOpen: !this.state.sourceOpen });
  };
  handleRawChange = (value) => {
    const { dispatch } = this.props;
    dispatch(themePlugin.actions.setRaw(value));
  };
  render() {
    const { match, raw } = this.props;
    const pathLink = match.path === '/' ? '' : match.path;
    return (
      <div>
        <Nav>
          <Link to={`${match.path}`}>Theme</Link> | <Link to={`${pathLink}/components`}>Components</Link>
        </Nav>
        <SourceEditor value={raw || '{}'} visible={this.state.sourceOpen} onBlur={this.handleRawChange} />
        <Switch>
          <AuthRoute path={`${pathLink}/components`} component={ComponentList} />
          <AuthRoute path={`${pathLink}/component/:name`} component={ComponentEdit} />
          <AuthRoute path={`${match.path}`} component={ThemesView} />
        </Switch>
        <SourceEditButton className="btn btn-default" onClick={this.handleOpenSource}>
          <i className="fa fa-code" />
        </SourceEditButton>
        <PublishButton />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    raw: themePlugin.selector.getRaw(state)
  };
};
export default withRouter(connect(mapStateToProps)(DefaultLayout) as any);
