import { defaultTo } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SearchIcon from '../Icons/SearchIcon';

const BORDER_RADIUS = '4px';

const Container = styled.div`
  float: right;
  margin-top: 20px;
  height: 30px;
  width: 190px;
  margin-right: 15px;
` as any;

const Input = styled.input`
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  width: 82%;
  height: 100%;
  color: #ffffff;
  padding: 0.5rem 1.2rem;
  border-top-left-radius: ${BORDER_RADIUS};
  border-bottom-left-radius: ${BORDER_RADIUS};

  ::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 100;
  }
` as any;

const Button = styled.button`
  height: 100%;
  width: 18%;
  float: right;
  border: none;
  border-top-right-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
  background-color: rgba(0, 0, 0, 0.18);
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
` as any;

const SearchInput = (props) => (
  <Container>
    <Input
      type="text"
      placeholder={defaultTo('Search Reward Station', props.inputPlaceholder)}
      onChange={props.onSearchChange}
    />
    <Button type="button" onClick={props.onSearchClick}>
      <SearchIcon />
    </Button>
  </Container>
);

const mapState = (state) => ({
  inputPlaceholder: state.inputPlaceholder
});

const mapDispatch = (dispatch) => ({
  onSearchClick: (e) => console.log('clicked search', e),
  onSearchChange: (e) => console.log('changed search', e)
});

export default connect(
  mapState,
  mapDispatch
)(SearchInput) as any;
