import { promiseDispatcher } from 'xcel-react-core';
import { createJsonApiActions, getRequest } from 'xcel-redux-orm';
import { nominationApi } from '../../../api/index';
import { setIndividualNominationProp } from '../genericActions';
import { getId } from '../nomineeSearch/miscActions';

const getProductsActions = createJsonApiActions(`getProducts`);

const getApplications = (id) => {
  // Don't confuse the backend by sending nomineeIds of 0, empty string, or null
  if (id) {
    return nominationApi.getApplication({ nomineeId: id }, { jsonApi: true });
  } else {
    return nominationApi.getApplication({}, { jsonApi: true });
  }
};

const setSelectableProducts = setIndividualNominationProp('visibleProducts');

const getProductsRequest = (id) => (dispatch, getState) => dispatch(getProductsActions.request(id));

const getProductsSuccess = (response, id) => (dispatch, getState) => {
  dispatch(getProductsActions.success(response, id));
  dispatch(setSelectableProducts(response.data.map(getId)));
};

const getProductsFailure = (error, id) => (dispatch, getState) => dispatch(getProductsActions.failure(error, id));

export const getProducts = promiseDispatcher(getApplications, {
  request: getProductsRequest,
  success: getProductsSuccess,
  failure: getProductsFailure
});

const lazyGetProducts = (nomineeId) => (dispatch, getState) => {
  const status = getRequest(getState(), 'getProducts');
  if (!status.loading) {
    dispatch(getProducts(nomineeId));
  }
};

export const getProductsOnce = () => (dispatch, getState) => {
  getRequest(getState(), 'getProducts');
  dispatch(getProducts(undefined));
};

export default lazyGetProducts;
