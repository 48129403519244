import cx from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { withErrorBoundary } from 'xcel-react-core';
import contentActions from '../../../../redux/actions/content';
import { contentSelector, contentTypeSelector, socialSelector } from '../../../../redux/selectors';
import ComponentSettings from '../../../../views/ContentEdit/components/Modals/ComponentSettings';
import ComponentTree from '../ComponentTree';

const ToolBar = ({ className, active, handleDelete, handleCopy, handleToggleSettings }) => (
  <Row className={className}>
    <Col sm={8}>
      <i className="fa fa-cog" title={'Settings'} onClick={handleToggleSettings} />
    </Col>
    <Col sm={8}>
      <i className="fa fa-copy" title={'Duplicate'} onClick={handleCopy} />
    </Col>
    <Col sm={8}>
      <i className="fa fa-trash" title={'Remove'} onClick={handleDelete} />
    </Col>
  </Row>
);

const StyledToolBar = styled(ToolBar)`
  margin-top: 0.5em;
  font-size: 20px;
  i {
    cursor: ${(props) => (props.active ? 'pointer' : 'auto')};
  }
  i:hover {
    color: ${(props) => (props.active ? 'rgb(29,139,241)' : 'auto')};
  }
  color: ${(props) => (props.active ? 'black' : 'rgb(155,155,155)')};
` as any;

class ComponentStuctureTree extends React.Component<any, any> {
  state = { active: 0 as any, localTree: [] as any, activePath: [], showModal: false };

  static getDerivedStateFromProps(props: any, state: any) {
    if (
      (state.localTree && props.content && state.localTree.length === 0 && !R.equals(state.localTree, props.content)) ||
      JSON.stringify(state.localTree).length !== JSON.stringify(props.content).length
    ) {
      return { localTree: JSON.stringify(props.content).length > 0 ? props.content : [] };
    } else {
      return state;
    }
  }

  handleOnComponentChanged = (newContent) => {
    this.setState({localTree: newContent});
  }

  handleClick = (node) => (e) => {
    const tree = this.state.localTree;
    this.deleteActiveFromTree(tree);

    node.is_active = true;
    const recursivePath = this.getActivePath(tree, 'is_active', []);
    node.component !== 'Page'
      ? this.setState({ active: node, activePath: recursivePath, localTree: tree })
      : this.setState({ active: 0, activePath: [], localTree: tree });
  };

  handleUpdate = (modifierFunction: Function = undefined) => {
    if (modifierFunction) {
      return () => {
        const { actions, edit, contentTypeId } = this.props;
        const tree = modifierFunction();
        const newEdit = { ...edit, content: tree };
        actions.setEdit(contentTypeId, newEdit);
        actions.setRaw(contentTypeId, newEdit);
        this.setState({ localTree: tree, active: 0 });
      };
    } else {
      return (updatedTree) => {
        const { actions, edit, contentTypeId } = this.props;
        const newEdit = { ...edit, content: updatedTree.children };
        actions.setEdit(contentTypeId, newEdit);
        actions.setRaw(contentTypeId, newEdit);
        if (Array.isArray(updatedTree)) {
          this.setState({ localTree: [...(updatedTree as any).children] });
        }
      };
    }
  };
  deleteActiveFromTree = (value, key = 'is_active') => {
    if (typeof value === 'undefined') {
      return {};
    }
    if (Array.isArray(value)) {
      for (var i = 0; i < value.length; i++) {
        value[i] = this.deleteActiveFromTree(value[i], key);
      }
      return value;
    }
    if (typeof value !== 'object') {
      return value;
    }
    delete value.is_active;
    for (var val in value) {
      if (value.hasOwnProperty(val)) {
        value[val] = this.deleteActiveFromTree(value[val], key);
      }
    }
    return value;
  };
  deleteActiveNode = () => {
    let tree = [...this.state.localTree] as any;
    let node = tree;
    for (let i = 0; i < this.state.activePath.length - 1; i++) {
      node = node[this.state.activePath[i]];
    }
    node.splice(this.state.activePath[this.state.activePath.length - 1], 1);
    return tree;
  };
  copyActiveNode = () => {
    let tree = [...this.state.localTree] as any;
    let node = tree;
    for (let i = 0; i < this.state.activePath.length - 1; i++) {
      node = node[this.state.activePath[i]];
    }
    let objectToAdd = R.clone(node[this.state.activePath[this.state.activePath.length - 1]]);
    delete objectToAdd.is_active;
    node.splice(this.state.activePath[this.state.activePath.length - 1] + 1, 0, objectToAdd);
    return tree;
  };
  getActivePath = (treeNode, key = 'is_active', recursivePath: Array<any>) => {
    if (treeNode.hasOwnProperty(key)) {
      return recursivePath;
    }
    if (!Array.isArray(treeNode) && !treeNode.hasOwnProperty('children')) {
      return [];
    }
    if (Array.isArray(treeNode)) {
      return this.getActivePathFromArray(treeNode, 'is_active', recursivePath);
    }
    if (treeNode.hasOwnProperty('children') && treeNode.children.length > 0) {
      return this.getActivePathFromObject(treeNode, 'is_active', recursivePath);
    }
    return [];
  };
  getActivePathFromArray = (treeNode, key = 'is_active', recursivePath: Array<any>) => {
    let preliminarPath: Array<any>;
    for (var i = 0; i < treeNode.length; i++) {
      let recursivePathCopy = [...recursivePath];
      recursivePathCopy.push(i);
      preliminarPath = this.getActivePath(treeNode[i], key, recursivePathCopy);
      if (preliminarPath.length > 0) {
        return preliminarPath;
      }
    }
    return [];
  };
  getActivePathFromObject = (treeNode, key = 'is_active', recursivePath: Array<any>) => {
    let recursivePathCopy = [...recursivePath];
    let preliminarPath: Array<any>;
    recursivePathCopy.push('children');
    preliminarPath = this.getActivePath(treeNode.children, key, recursivePathCopy);
    if (preliminarPath.length > 0) {
      return preliminarPath;
    }
    return [];
  };
  handleToggleSettings = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  renderNode = (node) => {
    return (
      <span
        className={cx('node', {
          'is-active': node.is_active
        })}
        onClick={this.handleClick(node)}
      >
        {node.component}
      </span>
    );
  };
  render() {
    const { className, contentTypeId } = this.props;
    const activePathText = this.state.activePath.join('.');
    const componentToggleSettings = {
      data: {
        name: this.state.active !== 0 ? this.state.active.component : undefined,
        model: activePathText
      },
      property: activePathText
    };
    const pageTree = {
      component: contentTypeId,
      children: this.state.localTree
    };
    return (
      <div className={className}>
        <div className={className}>
          <Row>
            <Col sm={12}>
              <h4>Structure</h4>
            </Col>
            <Col sm={12}>
              <StyledToolBar
                active={this.state.active ? true : false}
                handleDelete={this.state.active ? this.handleUpdate(this.deleteActiveNode) : null}
                handleCopy={this.state.active ? this.handleUpdate(this.copyActiveNode) : null}
                handleToggleSettings={this.state.active ? this.handleToggleSettings : null}
              />
              <ComponentSettings
                show={this.state.showModal}
                onHide={this.handleToggleSettings}
                onComponentChanged={this.handleOnComponentChanged}
                component={componentToggleSettings}
              />
            </Col>
          </Row>
          <ComponentTree renderNode={this.renderNode} tree={pageTree} onChange={this.handleUpdate()} />
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setEdit: contentActions.setEdit,
      setRaw: contentActions.setRaw
    },
    dispatch
  )
});

const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);

  const content =
    contentTypeId === 'Activity'
      ? socialSelector.currentVersion(state).content
      : contentSelector.getEdit(state, contentTypeId).content;

  return {
    contentTypeId,
    content: content || [],
    edit: contentSelector.getEdit(state, contentTypeId)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary()(ComponentStuctureTree));
