import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
const Button = styled.button`
  background-color: #b5be35;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Helvetica;
  font-size: 1.2em;
  text-align: center;
  padding: 5px 15px;
  &.full-width {
    width: 100%;
  }
  :disabled {
    background-color: #ebebeb;
    color: #4c4f4f;
    cursor: not-allowed;    
  }
`;
export default register('rsv8-components/Button')(withTheme(), withContent())(Button);
