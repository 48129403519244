import * as React from 'react';
import { connect } from 'react-redux';
import { HeaderThree, Link } from 'rsv8-components';
import { Amount } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue, getRoundedValue, getValueOrNull } from '../../../utils';
import { getSummary } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import NoDataSpinner from '../NoDataSpinner';
import { OverlayPopover } from '../Overlay';
import ProjectionsChart from './ProjectionsChart';
import {
  AlignedCol,
  BranchingImage,
  ContentRow,
  HeaderRow,
  ProjectionValue,
  StyledHorizontalRule,
  StyledNames,
  StyledNote,
} from './styles';

const calculateGaugeParams = (targetValue: number, minIncrement: number, tickSections: number, minorTickSections: number) => {
  const increment = Math.ceil(targetValue / tickSections / minIncrement) * minIncrement;
  const tickInterval = increment < minIncrement ? minIncrement : increment;

  return {
    tickInterval,
    minorTickInterval: Math.ceil(tickInterval / minorTickSections),
    maxValue: tickSections * tickInterval,
  };
};

const MyProjections: React.FC<Props> = ({
  className,

  resource: {
    totalValueColor = '#003595',
    marginColor = '#2C9D9D',
    rebateColor = '#00AEEF',
    costSavingsColor = '#AE6CD8',
    goalColor = '#00AE44',
    labelColor = '#505d6f',

    totalValueLabel,
    totalValueNote,
    marginLabel,
    marginNote,
    rebateLabel,
    rebateNote,
    priceDiscountsLabel,
    costSavingsNote,
    totalPatientsQuarterLabel,
    totalPatientsWeekLabel,
    calculationTipLabel,
  },

  title = 'My Projections',
  infoIconText,
  lineWidth = 10,
  tickColor,
  minorTickColor,
  minIncrement = 250,
  tickSections = 8,
  minorTickSections = 5,

  target,
  runRate,
}) => {
  if (!target || !runRate) {
    return <NoDataSpinner />;
  }

  const { totalValue, margin, rebate, discount, patientsQuarter, patientsPerWeek } = React.useMemo(() => {
    return {
      totalValue: getValueOrNull(runRate.totalValue),
      margin: getValueOrNull(runRate.margin),
      rebate: getValueOrNull(runRate.rebate),
      discount: getValueOrNull(runRate.discount),
      patientsQuarter: getValueOrNull(runRate.patients),
      patientsPerWeek: getValueOrNull(runRate.patientsPerWeek),
    };
  }, [runRate]);
  const { totalValue: goalValue } = target;
  const targetValue = Math.max(goalValue, totalValue);

  const { tickInterval, minorTickInterval, maxValue } = React.useMemo(
    () => calculateGaugeParams(targetValue, minIncrement, tickSections, minorTickSections),
    [targetValue, minIncrement, tickSections, minorTickSections]
  );

  return (
    <div className={className}>
      <HeaderRow>
        <AlignedCol xs={24}>
          <HeaderThree themeVariation="my-dashboard">
            {title + ' '}
            <OverlayPopover id="popover-my-projections" placement="bottom">
              {infoIconText}
            </OverlayPopover>
          </HeaderThree>
        </AlignedCol>
      </HeaderRow>

      <ContentRow padding="40px 0 0">
        <AlignedCol xs={24} md={12} lg={12}>
          <ProjectionsChart
            maxValue={maxValue}
            margin={margin}
            rebate={rebate}
            discount={discount}
            totalValue={totalValue}
            goalValue={goalValue}
            lineWidth={lineWidth}
            tickColor={tickColor}
            minorTickColor={minorTickColor}
            tickInterval={tickInterval}
            minorTickInterval={minorTickInterval}
            totalValueColor={totalValueColor}
            marginColor={marginColor}
            rebateColor={rebateColor}
            costSavingsColor={costSavingsColor}
            goalColor={goalColor}
            labelColor={labelColor}
          />
        </AlignedCol>

        <AlignedCol xs={24} md={12} lg={10} lgOffset={1} padding="0 0 40px">
          <AlignedCol xs={22} xsOffset={1} sm={18} smOffset={3}>
            <StyledNames themeVariation="my-dashboard">{totalValueLabel}</StyledNames>
            <ProjectionValue color={totalValueColor} fontSize={26} lineHeight={26}>
              {getCurrencyValue(totalValue) || '-'}
            </ProjectionValue>
            <StyledNote dangerouslySetInnerHTML={{ __html: totalValueNote }} />
          </AlignedCol>

          <AlignedCol xs={24}>
            <BranchingImage />
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={0}>
            <StyledNames themeVariation="my-dashboard">{marginLabel}</StyledNames>
            <ProjectionValue color={marginColor}>{getCurrencyValue(margin) || '-'}</ProjectionValue>
            <StyledNote dangerouslySetInnerHTML={{ __html: marginNote }} />
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={0}>
            <StyledNames themeVariation="my-dashboard">{rebateLabel}</StyledNames>
            <ProjectionValue color={rebateColor}>{getCurrencyValue(rebate) || '-'}</ProjectionValue>
            <StyledNote dangerouslySetInnerHTML={{ __html: rebateNote }} />
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={0}>
            <StyledNames themeVariation="my-dashboard">{priceDiscountsLabel}</StyledNames>
            <ProjectionValue color={costSavingsColor}>{getCurrencyValue(discount) || '-'}</ProjectionValue>
            <StyledNote dangerouslySetInnerHTML={{ __html: costSavingsNote }} />
          </AlignedCol>

          <AlignedCol xs={24}>
            <StyledHorizontalRule />
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={3} md={10} mdOffset={1} lg={8} lgOffset={3}>
            <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
              {totalPatientsQuarterLabel}
            </StyledNames>
            <ProjectionValue>{getRoundedValue(patientsQuarter, 1) || '-'}</ProjectionValue>
          </AlignedCol>

          <AlignedCol xs={16} xsOffset={4} sm={8} smOffset={2} md={10} mdOffset={2} lg={8} lgOffset={2}>
            <StyledNames themeVariation="my-dashboard" fontSize={16} lineHeight={18}>
              {totalPatientsWeekLabel}
            </StyledNames>
            <ProjectionValue>{getRoundedValue(patientsPerWeek, 1) || '-'}</ProjectionValue>
          </AlignedCol>

          <AlignedCol xs={24} padding={'20px 0 0 0'}>
            <Link themeVariation="calculator-tip">{calculationTipLabel}</Link>
          </AlignedCol>
        </AlignedCol>
      </ContentRow>
    </div>
  );
};

/**
 * Content that the admin can enter to change this component
 * @param getContent
 */
const mapContentToProps = (getContent) => ({
  title: getContent('title', { type: 'string', label: 'Title' }),
  infoIconText: getContent('infoIconText', { type: 'string', label: 'Info Icon Text' }),

  lineWidth: getContent('lineWidth', { type: 'string', label: 'Line Width' }),
  tickColor: getContent('tickColor', { type: 'string', label: 'Tick Color' }),
  minorTickColor: getContent('minorTickColor', { type: 'string', label: 'Minor Tick Color' }),
  minIncrement: getContent('minIncrement', { type: 'string', label: 'Minimal Increment' }),
  tickSections: getContent('tickSections', { type: 'string', label: 'Tick Sections' }),
  minorTickSections: getContent('minorTickSections', { type: 'string', label: 'Minor Tick Sections' }),
});

const mapResourceToProps = (getResource) => ({
  resource: {
    totalValueColor: getResource('alcon.dashboard.projections.totalValueColor', '#003595'),
    marginColor: getResource('alcon.dashboard.projections.marginColor', '#2C9D9D'),
    rebateColor: getResource('alcon.dashboard.projections.rebateColor', '#00AEEF'),
    costSavingsColor: getResource('alcon.dashboard.projections.costSavingsColor', '#AE6CD8'),
    goalColor: getResource('alcon.dashboard.projections.goalColor', '#00AE44'),
    labelColor: getResource('alcon.dashboard.projections.labelColor', '#505d6f'),

    totalValueLabel: getResource('alcon.dashboard.projections.totalValueLabel', 'Projected Total Value'),
    totalValueNote: getResource(
      'alcon.dashboard.projections.totalValueNote',
      '(Sum of your <b>projected</b> margin, rebate, and price discounts)'
    ),
    marginLabel: getResource('alcon.dashboard.projections.marginLabel', 'Margin'),
    marginNote: getResource('alcon.dashboard.projections.marginNote', '(Projected Anticipated Margin is MSRP - List Price)'),
    rebateLabel: getResource('alcon.dashboard.projections.rebateLabel', 'Rebate'),
    rebateNote: getResource('alcon.dashboard.projections.rebateNote', '(Projected Practice Rebate)'),
    priceDiscountsLabel: getResource('alcon.dashboard.createGoals.priceDiscountsLabel', 'Price Discounts'),
    costSavingsNote: getResource('alcon.dashboard.projections.costSavingsNote', '(Projected Product Price Discounts)'),
    totalPatientsQuarterLabel: getResource(
      'alcon.dashboard.projections.totalPatientsQuarterLabel',
      'Projected Total Patients this Quarter'
    ),
    totalPatientsWeekLabel: getResource('alcon.dashboard.projections.totalPatientsWeekLabel', 'Projected Total Patients per Week'),
    calculationTipLabel: getResource('alcon.dashboard.projections.calculationTipLabel', 'VIEW HOW PATIENT NUMBERS ARE CALCULATED'),
  },
});

export const mapStateToProps = (state) => ({
  target: selectors.performanceAmountSelector(state, 'Target'),
  runRate: selectors.performanceAmountSelector(state, 'Run Rate'),
});

interface StateProps {
  target: Amount;
  runRate: Amount;
}
interface ContentProps {
  title: string;
  infoIconText: string;
  lineWidth?: number;
  tickColor?: string;
  minorTickColor?: string;
  minIncrement?: number;
  tickSections?: number;
  minorTickSections?: number;
}
interface ResourceProps {
  resource: {
    totalValueColor: string;
    marginColor: string;
    rebateColor: string;
    costSavingsColor: string;
    goalColor: string;
    labelColor: string;

    totalValueLabel: string;
    totalValueNote: string;
    marginLabel: string;
    marginNote: string;
    rebateLabel: string;
    rebateNote: string;
    priceDiscountsLabel: string;
    costSavingsNote: string;
    totalPatientsQuarterLabel: string;
    totalPatientsWeekLabel: string;
    calculationTipLabel: string;
  };
}
type Props = StateProps & ContentProps & ResourceProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/MyProjections')(
  withApiDataProvider(getSummary, selectors.performanceSelector),
  connect<StateProps>(mapStateToProps),
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(MyProjections);
