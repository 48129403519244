import { Dictionary, ThemeExample } from '../types';
import themeCmsPlugin from './cms.plugin.actions';
import selector from './cms.plugin.selectors';

export const exampleAdd = ({ name }) => (dispatch, getState) => {
  let examples: Dictionary<Array<ThemeExample>> = selector.getExamples(getState());
  if (examples[name] === undefined) {
    examples[name] = [];
  }
  examples[name].push({ jsx: `<${name}></${name}>` });
  dispatch(themeCmsPlugin.setProperty({ examples: { ...examples, [name]: [...examples[name]] } }));
  // dispatch(themeCmsPlugin.adminUpdate());
};
let updateTimeout;
export const exampleUpdate = ({ example, name, index }) => (dispatch, getState) => {
  let examples: Dictionary<Array<ThemeExample>> = selector.getExamples(getState());
  examples[name][index] = { jsx: example };
  dispatch(themeCmsPlugin.setProperty({ examples: { ...examples, [name]: [...examples[name]] } }));
  clearTimeout(updateTimeout);
  // updateTimeout = setTimeout(() => {
  //   dispatch(themeCmsPlugin.adminUpdate());
  // }, 1000);
};
export const exampleRemove = ({ name, index }) => (dispatch, getState) => {
  let examples: Dictionary<Array<ThemeExample>> = selector.getExamples(getState());
  examples[name].splice(index, 1);
  dispatch(themeCmsPlugin.setProperty({ examples: { ...examples, [name]: [...examples[name]] } }));
  // dispatch(themeCmsPlugin.adminUpdate());
};
