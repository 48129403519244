import * as React from 'react';
import { compose } from 'redux';
import styled from "styled-components";
import { PageRenderer } from 'rsv8-cms';
import {    
  Modal,
  ModalHeader,
  ModalBody  
  } from 'rsv8-components';

import { withResource } from 'xcel-react-core';


const ModaBodyContainer = styled.div`
font-family: open-sans,sans-serif;
padding: 0 10px 50px 10px;
` as any;

export interface Props {
    show: boolean;
    handleHide: Function;
    rebateOption: string;

}

class RedemptionHistoryStatusModal extends React.Component<Props> {       
    getCmsPageName = (rebateOption) => rebateOption;

    render() {
        const {rebateOption} = this.props;

        if (!rebateOption) {
            return null;
        }

        return (            
          <Modal  show={this.props.show} onHide={this.props.handleHide}>
              <ModalHeader closeButton={true} />            
            <ModalBody>
                <ModaBodyContainer>
                    <PageRenderer
                        path={`/modal/redeem-status/${this.getCmsPageName(rebateOption)}`}
                    />                    
                </ModaBodyContainer>
            </ModalBody>            
          </Modal>
        );
    }    
}
export default compose(withResource())(RedemptionHistoryStatusModal as any);

