import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Icon from 'react-fontawesome';
import Slider from 'react-slick-deux';
import { PageRenderer } from 'rsv8-cms';
import { HeaderThree } from 'rsv8-components';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { CarouselArrow, SliderContainer } from './styles';

const CmsPlaceholder = styled.div`
  width: 100%;
  height: 100px;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CarouselPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-left" size="3x" />
    </CarouselArrow>
  );
};

const CarouselNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-right" size="3x" />
    </CarouselArrow>
  );
};

class ContentSlider extends React.Component<any, any> {
  slider = null;

  componentDidMount() {
    const { contentSlides } = this.props;
    if (contentSlides && contentSlides.length > 1) {
      let activeDot = this.slider && this.slider.querySelector('.slick-dots .slick-active');
      if (activeDot) activeDot.className = 'slick-active active';
    }
  }
  onMount = (el) => {
    this.slider = ReactDOM.findDOMNode(el);
  };

  render() {
    const { sliderSettings, children, ...rest } = this.props;

    let { contentSlides } = this.props;
    if (this.props['data-cms-editing']) {
      return (
        <CmsPlaceholder>
          <HeaderThree themeVariation="equal-margins">Content Slider</HeaderThree>
        </CmsPlaceholder>
      );
    }

    let defaultSliderSettings = {
      dots: true,
      prevArrow: <CarouselPrevArrow />,
      nextArrow: <CarouselNextArrow />,
      afterChange: () => {
        let activeDot = this.slider && this.slider.querySelector('.slick-dots .slick-active');
        if (activeDot) activeDot.className = 'slick-active active';
      }
    };

    const settings = Object.assign({}, defaultSliderSettings, sliderSettings);

    return (
      <SliderContainer {...rest}>
        <div ref={this.onMount}>
          <Slider {...settings}>
            {contentSlides.map((slide: { contentPageUrl: string; href: string }, i: number) => (
              <div key={i}>
                <PageRenderer path={slide.contentPageUrl} />
              </div>
            ))}
          </Slider>
        </div>
      </SliderContainer>
    );
  }
}
const mapContentToProps = (getContent) => ({
  contentSlides: getContent('contentSlides', {
    type: 'array',
    schema: [{ model: 'contentPageUrl', label: 'Content Page URL' }]
  }),
  sliderSettings: getContent('sliderSettings', { type: 'object' })
});

export default register('rsv8-app/ContentSlider')(withContent(mapContentToProps), withTheme())(ContentSlider);
