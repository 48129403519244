/**
 * User Actions
 */
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_OAUTH_REQUEST = 'USER_LOGOUT_OAUTH_REQUEST';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAILURE = 'USER_FORGOT_PASSWORD_FAILURE';

export const USER_PASSWORD_CHANGE_REQUEST = 'USER_PASSWORD_CHANGE_REQUEST';
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS';
export const USER_PASSWORD_CHANGE_FAILURE = 'USER_PASSWORD_CHANGE_FAILURE';

export const USER_FORGOT_USERNAME_REQUEST = 'USER_FORGOT_USERNAME_REQUEST';
export const USER_FORGOT_USERNAME_SUCCESS = 'USER_FORGOT_USERNAME_SUCCESS';
export const USER_FORGOT_USERNAME_FAILURE = 'USER_FORGOT_USERNAME_FAILURE';

export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAILURE = 'USER_PASSWORD_RESET_FAILURE';

export const USER_GET_ACCESS_KEY_REQUEST = 'USER_GET_ACCESS_KEY_REQUEST';
export const USER_GET_ACCESS_KEY_SUCCESS = 'USER_GET_ACCESS_KEY_SUCCESS';
export const USER_GET_ACCESS_KEY_FAILURE = 'USER_GET_ACCESS_KEY_FAILURE';

export const USER_DATA_SET = 'rsv8-auth/USER_SET_DATA';
/**
 * Login Form Actions
 */
export const LOGIN_FORM_CHANGE = 'LOGIN_FORM_CHANGE';
export const LOGIN_FORM_CLEAR = 'LOGIN_FORM_CLEAR';

/**
 * Create Authentication Token Actions
 */
export const AUTH_TOKEN_CREATE_REQUEST = 'AUTH_TOKEN_CREATE_REQUEST';
export const AUTH_TOKEN_CREATE_SUCCESS = 'AUTH_TOKEN_CREATE_SUCCESS';
export const AUTH_TOKEN_CREATE_FAILURE = 'AUTH_TOKEN_CREATE_FAILURE';

/**
 * Create OAuth Token Actions
 */
export const O_AUTH_TOKEN_CREATE_REQUEST = 'O_AUTH_TOKEN_CREATE_REQUEST';
export const O_AUTH_TOKEN_CREATE_SUCCESS = 'O_AUTH_TOKEN_CREATE_SUCCESS';
export const O_AUTH_TOKEN_CREATE_FAILURE = 'O_AUTH_TOKEN_CREATE_FAILURE';

/**
 * OAuth popup actions
 */
export const O_AUTH_POPUP_OPEN = 'O_AUTH_POPUP_OPEN';

/**
 * Create Authentication Token Actions
 */
export const AUTH_CODE_CREATE_REQUEST = 'AUTH_CODE_CREATE_REQUEST';
export const AUTH_CODE_CREATE_SUCCESS = 'AUTH_CODE_CREATE_SUCCESS';
export const AUTH_CODE_CREATE_FAILURE = 'AUTH_CODE_CREATE_FAILURE';
/**
 * Refresh Authentication Token Actions
 */
export const AUTH_TOKEN_REFRESH_REQUEST = 'AUTH_TOKEN_REFRESH_REQUEST';
export const AUTH_TOKEN_REFRESH_SUCCESS = 'AUTH_TOKEN_REFRESH_SUCCESS';
export const AUTH_TOKEN_REFRESH_FAILURE = 'AUTH_TOKEN_REFRESH_FAILURE';

/**
 * Refresh Authentication Token Actions
 */
export const O_AUTH_TOKEN_REFRESH_REQUEST = 'O_AUTH_TOKEN_REFRESH_REQUEST';
export const O_AUTH_TOKEN_REFRESH_SUCCESS = 'O_AUTH_TOKEN_REFRESH_SUCCESS';
export const O_AUTH_TOKEN_REFRESH_FAILURE = 'O_AUTH_TOKEN_REFRESH_FAILURE';

/**
 * Refresh Authentication Token Actions
 */
export const AUTH_TOKEN_BASIC_CREATE_REQUEST = 'AUTH_TOKEN_BASIC_CREATE_REQUEST';
export const AUTH_TOKEN_BASIC_CREATE_SUCCESS = 'AUTH_TOKEN_BASIC_CREATE_SUCCESS';
export const AUTH_TOKEN_BASIC_CREATE_FAILURE = 'AUTH_TOKEN_BASIC_CREATE_FAILURE';

/**
 * Session Expiring Modal Actions
 */
export const AUTH_TOKEN_EXPIRE_MODAL_OPEN = 'AUTH_TOKEN_EXPIRE_MODAL_OPEN';
export const AUTH_TOKEN_EXPIRE_MODAL_CLOSE = 'AUTH_TOKEN_EXPIRE_MODAL_CLOSE';

export const USER_MERGE_DATA_FROM_API = 'USER_MERGE_DATA_FROM_API';
export const USER_MUST_CHANGE_PASSWORD = 'USER_MUST_CHANGE_PASSWORD';

/*
* Gigya Actions
*/
export const AUTH_GIGYA_SSO_SYNC_REQUEST = "AUTH_GIGYA_SSO_SYNC_REQUEST";
export const AUTH_GIGYA_SSO_SYNC_SUCCESS = "AUTH_GIGYA_SSO_SYNC_SUCCESS";
export const AUTH_GIGYA_SSO_SYNC_FAILURE = "AUTH_GIGYA_SSO_SYNC_FAILURE";

/*
* OAuth Actions
*/
export const AUTH_OAUTH_REQUEST = 'AUTH_OAUTH_REQUEST';
export const AUTH_OAUTH_SUCCESS = 'AUTH_OAUTH_SUCCESS';
export const AUTH_OAUTH_FAILURE = 'AUTH_OAUTH_FAILURE';

/*
* Fist visit actions
 */

export const AUTH_FIRST_VISIT_CHANGE = 'AUTH_FIRST_VISIT_CHANGE';