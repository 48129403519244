import * as R from 'ramda';
import * as React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ReactField } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { toggleValue } from 'xcel-util';
import { FieldLabel } from '../../../components/Label';
import Masonry from '../../../components/Masonry/Masonry';
import { setCategoryChange, toggleCategories } from '../../../redux/actions';
import { isCategoriesModalOpen } from '../../../redux/selectors/searchSelectors';
import {
  BrandsHeader,
  BrandsModalBody,
  BrandsModalContainer,
  BrandsTitle
  } from '../BrandsModal/styles';
import {
  CategoryContainer,
  CategoryFieldContainer,
  CategoryGroupAlpha,
  SubCategory,
  SubCategoryFieldContainer
  } from '../styles';

interface CategoryModalProps {
  actions: {
    toggleCategories: any;
    setCategoryChange: any;
  };
  checkedCategories: any;
  history: any;
  isOpen: boolean;
  categoryFilters: any;
  categoriesTitle: string;
  cancelLabel: string;
  filterLabel: string;
}

class CategoryModal extends React.Component<CategoryModalProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      categoryGroups: {},
      currentlyChecked: []
    };
  }

  sortCategories = () => {
    const sortByLabel: any = R.sortWith([R.ascend(R.prop('name'))]);
    return sortByLabel(this.props.categoryFilters);
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.categoryFilters !== prevProps.categoryFilters) {
      const categories = this.sortCategories();
      let groupedCategories = {};

      if (Array.isArray(categories)) {
        categories.forEach((category) => {
          const firstLetter = category.name.charAt(0);
          if (groupedCategories[firstLetter] === undefined) {
            groupedCategories[firstLetter] = [];
          }
          groupedCategories[firstLetter].push(category);
        });
        this.setState({ categoryGroups: groupedCategories });
      }
    }
    if (this.props.checkedCategories !== prevProps.checkedCategories) {
      this.setState({ currentlyChecked: this.props.checkedCategories });
    }
  }

  revertChanges = () => {
    this.setState({ currentlyChecked: this.props.checkedCategories });
    this.props.actions.toggleCategories();
  };

  handleCategoryChange = (value) => {
    this.setState({ currentlyChecked: toggleValue(value.toString(), this.state.currentlyChecked) });
  };

  submitFilter = () => {
    this.props.actions.setCategoryChange(this.state.currentlyChecked, this.props.history);
    this.props.actions.toggleCategories();
  };

  constructCategories = () => {
    return (
      <Form>
        <Masonry>
          {Object.keys(this.state.categoryGroups).map((categoryAlpha) => {
            return (
              <div key={categoryAlpha}>
                <CategoryGroupAlpha>{categoryAlpha}</CategoryGroupAlpha>
                {this.state.categoryGroups[categoryAlpha].map((category) => (
                  <CategoryContainer key={category.id}>
                    <CategoryFieldContainer>
                      <ReactField
                        component={'checkbox'}
                        label={
                          <FieldLabel
                            color={`#606060`}
                            isCheckbox={true}
                            isBold={true}
                            labelName={`${category.name} (${category.productCount})`}
                          />
                        }
                        name={`category-${category.id}`}
                        onChange={() => this.handleCategoryChange(category.id)}
                        value={
                          this.state.currentlyChecked && this.state.currentlyChecked.includes(category.id.toString())
                            ? true
                            : false
                        }
                      />
                    </CategoryFieldContainer>
                    {category.children.map((subCategory) => (
                      <SubCategory key={subCategory.id}>
                        <SubCategoryFieldContainer>
                          <ReactField
                            component={'checkbox'}
                            label={
                              <FieldLabel
                                isCheckbox={true}
                                isBold={false}
                                labelName={`${subCategory.name} (${subCategory.productCount})`}
                              />
                            }
                            name={`category-${subCategory.id}`}
                            onChange={() => this.handleCategoryChange(subCategory.id)}
                            value={
                              this.state.currentlyChecked &&
                              this.state.currentlyChecked.includes(subCategory.id.toString())
                                ? true
                                : false
                            }
                          />
                        </SubCategoryFieldContainer>
                      </SubCategory>
                    ))}
                  </CategoryContainer>
                ))}
              </div>
            );
          })}
        </Masonry>
      </Form>
    );
  };

  render() {
    const { categoryFilters, isOpen, categoriesTitle, filterLabel, cancelLabel } = this.props;

    return (
      <BrandsModalContainer show={isOpen} onHide={this.revertChanges}>
        <BrandsHeader closeButton={true}>
          <BrandsTitle>{categoriesTitle}</BrandsTitle>
        </BrandsHeader>
        <BrandsModalBody>{categoryFilters && this.constructCategories()}</BrandsModalBody>
        <Modal.Footer>
          <Button onClick={this.revertChanges}>{cancelLabel}</Button>
          <Button onClick={this.submitFilter}>{filterLabel}</Button>
        </Modal.Footer>
      </BrandsModalContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isOpen: isCategoriesModalOpen(state)
});

const mapResourceToProps = (getResource) => ({
  categoriesTitle: getResource('catalog.search.categoryModalTitle', 'Categories'),
  cancelLabel: getResource('catalog.search.cancelLabel', 'Cancel'),
  filterLabel: getResource('catalog.search.filterLabel', 'Filter')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setCategoryChange,
      toggleCategories
    },
    dispatch
  )
});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(CategoryModal);
