import * as React from 'react';
import { CustomFields } from 'rsv8-customfields';
import { BetterParagraph } from '../../CustomFieldValue';

export interface CriteriaAndDetailsProps {
  appKey: string;
  formType: string;
  customFields: Record<string, string>;
}

const groups = ['criteria', 'details'];

const CriteriaAndDetails: React.SFC<CriteriaAndDetailsProps> = ({ customFields, ...props }) => (
  <React.Fragment>
    <CustomFields {...props} groupKey={groups} component={BetterParagraph} customFieldValues={customFields} />
  </React.Fragment>
);

export default CriteriaAndDetails;
