import * as React from 'react';
import { Checkbox, ControlLabel, FormGroup } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import { Forms } from '../../types';
import addValidationState from '../HOC/addValidationState';
import { StatusText } from './helpers';
import Label from './Label';
import { TooltipContainer } from "./TooltipContainer";

const createHandleChange = (input, option) => (e) => {
  const newValue = [].concat(input.value).filter((v) => !!v);
  const indexOf = newValue.indexOf(option.value);

  if (indexOf === -1) {
    newValue.push(option.value);
  } else if (indexOf !== -1) {
    newValue.splice(indexOf, 1);
  }

  return input.onChange(Object.assign(e, { target: { value: newValue } }));
};

const CheckboxGroup: Forms.Inputs.CheckboxGroup = ({ input, meta, options, validationState, helpText, ...rest }) => {
  let $options =
    options &&
    options.map((option, i) => {
      return (
        <Checkbox
          key={i}
          name={`${input.name}[${i}]`}
          // Get the value for this option
          checked={input.value.indexOf(option.value) !== -1}
          onChange={createHandleChange(input, option)}
          {...rest}
        >
          {typeof option.label === 'string' ? (
            <Text dangerouslySetInnerHTML={{ __html: option.label }} />
          ) : (
            <Text>{option.label}</Text>
          )}
        </Checkbox>
      );
    });
  const { helpIconText } = rest;
  return $options ? (
    <FormGroup controlId={input.name} validationState={validationState}>
      <ControlLabel>
        <Label>{rest.label}</Label>
        {helpIconText && <TooltipContainer tooltipChildren={helpIconText} />}
      </ControlLabel>
      {$options}
      <StatusText meta={meta} helpText={helpText} />
    </FormGroup>
  ) : null;
};

export default addValidationState(CheckboxGroup);
