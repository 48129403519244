import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getThemeComponents } from '../../../config';
import { ComponentCard } from '../../components/Card';
import cmsPluginSelectors from '../../redux/cms.plugin.selectors';
import { mapDispatchToActions } from '../../util';
import JsxRenderer from './../../components/JsxRenderer/index';
import { ComponentLink } from './components';

const ComponentsContainer = styled.div`
  padding: 5px;
  a {
    color: black;
  }
`;
const components = getThemeComponents();
const ComponentList = ({ examples, match, computedMatch, root, parent = '', themeComponents = components }: any) => {
  return (
    <ComponentsContainer>
      {Object.keys(themeComponents).map((name, index) => {
        if (themeComponents[name].name === undefined) {
          const label = parent === '' ? name : `${parent}.${name}`;
          return (
            <React.Fragment key={index}>
              <h4>{label}</h4>
              <ComponentList examples={examples} match={match} parent={label} themeComponents={themeComponents[name]} />
              <hr />
            </React.Fragment>
          );
        }
        const namespace = themeComponents[name].namespace;
        return (
          <ComponentLink key={namespace} name={namespace} match={match}>
            <ComponentCard title={name}>
              {examples[namespace] ? (
                examples[namespace].map((example, exampleIndex) => {
                  return <JsxRenderer key={exampleIndex} jsx={example.jsx} />;
                })
              ) : (
                <span>No Example</span>
              )}
            </ComponentCard>
          </ComponentLink>
        );
      })}
    </ComponentsContainer>
  );
};

const mapStateToProps = (state) => ({
  examples: cmsPluginSelectors.getExamples(state)
});
const mapDispatchToProps = mapDispatchToActions({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentList);
