import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ModularMenu from '../ModularMenus';

// We could create a class that manages z-index using arrays.
// ZIndexCtl.push(this);
const HamburgerMenuNavigationOverlay = styled.div`
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: -200%;
  z-index: 201; // to overlap HamburgerMenu icon (z-index: 200)
  transition: all 0.2s ease-out;
  display: block;
  @media only screen and (max-width: 768px) {
    ${(props: any) => props.open && 'left: 0;'};
  }
`;

const StyledMobileNav = styled.div`
  .navbar-nav {
    border-bottom: 0;
    margin-bottom: 0;

    li {
      border-bottom: 1px solid #d4d4d4 !important;
      margin: 0 !important;
      padding: 0 15px !important;
      display: block !important;
      &.open {
        a {
          padding-bottom: 0 !important;
        }
      }
      a {
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 30px !important;
        color: #4c4f4f !important;
        .caret {
          float: right;
          margin-top: 14px;
        }
      }
      &.nav-link-selected {
        border-bottom: 1px solid #d4d4d4 !important;
      }
      .dropdown-menu {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
        li {
          padding: 0 !important;
          margin: 0 !important;
          border-bottom: 0 !important;
          a {
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 46px !important;
            color: #4c4f4f !important;
            padding: 0 4px !important;
          }
        }
      }
    }
  }
` as any;

const HamburgerMenuNavigation = (props) => {
  return (
    <HamburgerMenuNavigationOverlay {...props}>
      {props.children}
      <StyledMobileNav>
        <ModularMenu template="PrimaryNavigation" appKey="MainMobile" />
      </StyledMobileNav>
    </HamburgerMenuNavigationOverlay>
  );
};

const mapStateToProps = (state) => ({
  open: state.navigationMenu && state.navigationMenu.isOpen,
  links: state.navigationMenu && state.navigationMenu.items
});

export default connect(mapStateToProps)(HamburgerMenuNavigation) as any;
