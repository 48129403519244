import { image } from 'xcel-api-generator';
import { mapJsonApiActions } from 'xcel-redux-orm';

const getTypeByImageType = ({ imageType }) => {
  return image
    .getTypeByImageType({ imageType })
    .then(res => {
      return ({
        data: [{ attributes: res,  type: "userProfileImage", id: imageType }]
      })
    });
}
  

const imageApiActions = mapJsonApiActions({
  ...image,
  getTypeByImageType: getTypeByImageType
});

export default imageApiActions;
