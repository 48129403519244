import * as React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button } from '../Button';
import { GridColumn } from '../Layout';
import { Loader } from '../Loader';
import { withResource } from 'xcel-react-core';
import { ApiDataProviderPagination } from 'xcel-redux-orm';

export interface LoadMoreProps {
  pagingData: ApiDataProviderPagination;
  isLoading: boolean;
  currentPage?: number;
  loadMoreAction: Function;
}

export const LoadMoreButton = (props: LoadMoreProps) => {
  const { pagingData, isLoading, loadMoreAction } = props;
  const { isLastPage } = pagingData;
  const ButtonTemplate = () => {
    return (
      <Row>
        <GridColumn className="text-center" md={12} mdOffset={6}>
          <Button onClick={loadMoreAction}>Load More</Button>
        </GridColumn>
      </Row>
    );
  };
  const LoaderTemplate = () => {
    return (
      <Row>
        <GridColumn className="text-center" md={12} mdOffset={6}>
          <Loader />
        </GridColumn>
      </Row>
    );
  };
  const RenderLoadMoreButton = () => {
    if (isLoading) {
      return LoaderTemplate();
    } else {
      return (isLastPage !== undefined && !pagingData.isLastPage) ? ButtonTemplate() : null;
    }
  };
  return RenderLoadMoreButton();
};

const mapResourceToProps = (getResource) => ({
  loadMoreLabel: getResource('catalog.orderHistory.loadMoreLabel', 'Load More')
});

export default compose<any>(
  connect(),
  withResource(mapResourceToProps)
)(LoadMoreButton);
