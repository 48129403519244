import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'rsv8-forms';
import { destroyRequest } from 'xcel-redux-orm';
import { assetAdminApiActions } from '../../redux/actions';
import { getActiveRoute, routeSelector } from '../../redux/selectors';
import { assetAdminToast } from '../../util';

export interface CreateRouteProps {
  actions?: {
    createRoutes: Function;
    destroyRequest: Function;
    getRoutes: Function;
  };
  routes?: {};
  activeRouteId: any;
  reset: Function;
  handleSubmit: any;
}

const getRootRouteId: React.SFC<any> = (props) => {
  let rootRoute = props.routes.find((route) => route.routeNodeName === '');
  return rootRoute && rootRoute.id;
};

const CreateRoute: React.SFC<CreateRouteProps> = (props) => {
  let { actions, activeRouteId, handleSubmit, reset } = props;

  let submitForm = (values) => {
    if (activeRouteId === 'root') {
      activeRouteId = getRootRouteId(props);
    }

    const ChildRouteModel = { routeNodeName: values.routeNodeName, parentRouteId: activeRouteId };
    actions
      .createRoutes({ request: ChildRouteModel })
      .then((response) => {
        actions.destroyRequest('getClientAssetRoutes');
        actions.getRoutes({});
        reset('CreateRoute');
      })
      .catch((err) => {
        let errorResponse;
        if (err.response === undefined) {
          errorResponse = err; // catch code errors
        } else {
          errorResponse =
            err.response.data.errors[0].title !== ''
              ? err.response.data.errors[0].title
              : err.response.data.errors[0].meta.id; // catch server errors
        }
        assetAdminToast('admin-warning', errorResponse);
      });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="form-group">
        <label>
          Route Name <sup>*</sup>
        </label>
        <div>
          <Field required={true} name="routeNodeName" component="bs-input" type="text" className="form-control" />
          <Button bsStyle="success" onClick={handleSubmit(submitForm)}>
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};

const mapStatetoProps = (state, ownProps) => {
  return {
    activeRouteId: getActiveRoute(state),
    routes: routeSelector.selectMany(state, null)
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createRoutes: assetAdminApiActions.createClientAssetRoutes,
      destroyRequest,
      getRoutes: assetAdminApiActions.getClientAssetRoutes
    },
    dispatch
  )
});

export default compose(
  reduxForm({
    form: 'CreateRoute',
    enableReinitialize: true
  }),
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )
)(CreateRoute) as any;
