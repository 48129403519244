import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux/';

const salesEntryModule: ReduxModule = createReduxModule({
  name: 'rsv8-sales-entry',
  reducers
});

reduxObserver.addModule(salesEntryModule);
export default salesEntryModule;
