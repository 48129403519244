import * as React from 'react';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';

export type Props = {
  bottom?: string;
  left?: string;
  position?: string;
  right?: string;
  top?: string;
  zIndex?: string;
};

const PLACEHOLDER_IMAGE_URL = '/images/image-placeholer.png';
const REGEX_GUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const StyledImage = styled.img`
  max-width: ${(props: Props) => (props.position === 'absolute' ? '' : '100%')};
  position: ${(props: Props) => (props.position ? props.position : 'static')};
  top: ${(props: Props) => (props.top ? props.top : 'auto')};
  left: ${(props: Props) => (props.left ? props.left : 'auto')};
  right: ${(props: Props) => (props.right ? props.right : 'auto')};
  bottom: ${(props: Props) => (props.bottom ? props.bottom : 'auto')};
  z-index: ${(props: Props) => (props.zIndex ? props.zIndex : 'auto')};
  max-height: ${(props: Props) => (props.position === 'static' ? '375px' : '400px')};
`;

const imageVisibiltiy = (hideLarge, hideMedium, hideSmall, hideXs) => {
  let hiddenLg = hideLarge ? ' hidden-lg' : '';
  let hiddenMd = hideMedium ? ' hidden-md' : '';
  let hiddenSm = hideSmall ? ' hidden-sm' : '';
  let hiddenXs = hideXs ? ' hidden-xs' : '';
  return `${hiddenLg}${hiddenMd}${hiddenSm}${hiddenXs}`;
};

const Image = ({
  alignment = 'text-left',
  className,
  imgSrc,
  responsiveImgSrc,
  children,

  hideLarge,
  hideMedium,
  hideSmall,
  hideXs,
  ...rest
}: any) => {
  // Add a placeholder image only on Admin site
  const isEditing = rest['data-cms-editing'];
  const placeholder = isEditing && PLACEHOLDER_IMAGE_URL;

  const checkDesktopGuid = REGEX_GUID.test(imgSrc);
  const checkMobileGuid = REGEX_GUID.test(responsiveImgSrc);

  const getImageDesktop = checkDesktopGuid ? assetService.getAsset : assetService.getImage;
  const getImageResp = checkMobileGuid ? assetService.getAsset : assetService.getImage;

  const desktopImgSrc = (imgSrc && getImageDesktop(imgSrc)) || placeholder;
  const mobileImgSrc = responsiveImgSrc && getImageResp(responsiveImgSrc);

  return (
    <React.Fragment>
      {responsiveImgSrc && (
        <React.Fragment>
          <div className={`hidden-xs ${className} ${alignment}`}>
            <StyledImage src={desktopImgSrc} {...rest} />
          </div>
          <div className={`hidden-sm hidden-md hidden-lg ${className} ${alignment}`}>
            <StyledImage src={mobileImgSrc} {...rest} />
          </div>
        </React.Fragment>
      )}

      {!responsiveImgSrc && (
        <div className={`${className} ${alignment}`}>
          {imgSrc && (
            <StyledImage
              className={imageVisibiltiy(hideLarge, hideMedium, hideSmall, hideXs)}
              src={desktopImgSrc}
              {...rest}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapContentToProps = (getContent) => ({
  responsiveImgSrc: getContent('responsiveImgSrc', { type: 'image', label: 'Responsive Image on XS' }),
  imgSrc: getContent('imgSrc', { type: 'image', label: 'Select Desktop Asset' })
});

export { Image };

export default register('rsv8-components/Image')(withContent(mapContentToProps), withTheme())(Image);
