import * as React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Text } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { FieldLabel } from '../../Label/Label';
import { CheckoutModuleHeading } from '../styles';
import { USStates } from './USStates';
// tslint:disable

const EditInfoHeading = styled(CheckoutModuleHeading)`
  margin-bottom: 30px;
` as any;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 55px 0;
` as any;

const CancelButton = styled(Button)`
  margin-right: 10px;
` as any;

const NameGroup = styled.div`
  display: flex;

  .form-group {
    display: inline-block;
    width: 50%;
    &:first-of-type {
      margin-right: 15px;
    }
  }
` as any;

const ErrorContainer = styled.div`
  background-color: #eed3d7;
  border-radius: 4px;
  border: solid 1px #d8374b;
  color: #4c4f4f;
  padding: 15px 15px 5px 15px;
` as any;

interface EditInfoProps {
  addressOneLabel: string;
  addressTwoLabel: string;
  businessNameLabel: string;
  cancelLabel: string;
  cityLabel: string;
  countryLabel: string;
  error?: any;
  firstNameLabel: string;
  handleSubmit: any;
  lastNameLabel: string;
  postalCodeLabel: string;
  saveInfo: any;
  saveLabel: string;
  shippingInfoHeading: string;
  stateLabel: string;
  toggleEdit: any;
  userProfile: any;
  provinceLabel: string;
}

class EditInfo extends React.Component<EditInfoProps, any> {
  render() {
    const {
      addressOneLabel,
      addressTwoLabel,
      businessNameLabel,
      cancelLabel,
      cityLabel,
      countryLabel,
      error,
      firstNameLabel,
      lastNameLabel,
      postalCodeLabel,
      saveInfo,
      saveLabel,
      shippingInfoHeading,
      stateLabel,
      toggleEdit,
      userProfile,
      provinceLabel
    } = this.props;

    return (
      <div>
        {error && <ErrorContainer dangerouslySetInnerHTML={{ __html: error }} />}
        <EditInfoHeading>{shippingInfoHeading}</EditInfoHeading>
        <div>
          <Form onSubmit={this.props.handleSubmit(saveInfo)}>
            <NameGroup>
              <Field
                component="bs-input"
                label={
                  <FieldLabel required={true}>
                    <Text>{firstNameLabel}</Text>
                  </FieldLabel>
                }
                name="firstName"
                type="text"
              />
              <Field
                component="bs-input"
                name="lastName"
                type="text"
                label={
                  <FieldLabel required={true}>
                    <Text>{lastNameLabel}</Text>
                  </FieldLabel>
                }
              />
            </NameGroup>
            <Field
              component="bs-input"
              name="businessName"
              type="text"
              label={
                <FieldLabel>
                  <Text>{businessNameLabel}</Text>
                </FieldLabel>
              }
            />
            <Field
              component="bs-input"
              name="addressOne"
              type="text"
              label={
                <FieldLabel required={true}>
                  <Text>{addressOneLabel}</Text>
                </FieldLabel>
              }
            />
            <Field
              component="bs-input"
              name="addressTwo"
              type="text"
              label={
                <FieldLabel>
                  <Text>{addressTwoLabel}</Text>
                </FieldLabel>
              }
            />
            <Field
              component="bs-input"
              name="city"
              type="text"
              label={
                <FieldLabel required={true}>
                  <Text>{cityLabel}</Text>
                </FieldLabel>
              }
            />
            {userProfile.countryId === 226 ? (
              <Field
                component="bs-select"
                name="state"
                label={
                  <FieldLabel required={true}>
                    <Text>{stateLabel}</Text>
                  </FieldLabel>
                }
                options={USStates}
              />
            ) : (
              <Field
                component="bs-input"
                name="province"
                type="text"
                label={
                  <FieldLabel>
                    <Text>{provinceLabel}</Text>
                  </FieldLabel>
                }
              />
            )}

            <Field
              component="bs-input"
              name="postalCode"
              type="text"
              label={
                <FieldLabel required={true}>
                  <Text>{postalCodeLabel}</Text>
                </FieldLabel>
              }
            />
            <Field
              component="bs-input"
              name="country"
              type="text"
              label={
                <FieldLabel required={true}>
                  <Text>{countryLabel}</Text>
                </FieldLabel>
              }
              disabled={true}
            />
            <ButtonGroup>
              <CancelButton themeVariation="secondary" onClick={toggleEdit()}>
                {cancelLabel}
              </CancelButton>
              <Button type="submit">{saveLabel}</Button>
            </ButtonGroup>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    firstName: ownProps.userProfile.firstName,
    lastName: ownProps.userProfile.lastName,
    businessName: ownProps.userProfile.businessName,
    addressOne: ownProps.userProfile.addressOne,
    addressTwo: ownProps.userProfile.addressTwo,
    city: ownProps.userProfile.city,
    state: ownProps.userProfile.state,
    postalCode: ownProps.userProfile.postalCode,
    country: ownProps.userProfile.country
  }
});

const mapResourceToProps = (getResource) => ({
  shippingInfoHeading: getResource('catalog.checkout.editShippingInfoHeading', 'Edit Shipping Information'),
  firstNameLabel: getResource('catalog.checkout.firstNameLabel', 'First Name'),
  cancelLabel: getResource('catalog.search.cancelLabel', 'Cancel'),
  saveLabel: getResource('catalog.search.saveLabel', 'Save'),
  lastNameLabel: getResource('catalog.checkout.lastNameLabel', 'Last Name'),
  businessNameLabel: getResource('catalog.checkout.businessNameLabel', 'Business Name'),
  addressOneLabel: getResource('catalog.checkout.addressOneLabel', 'Address'),
  addressTwoLabel: getResource('catalog.checkout.addressTwoLabel', 'Address (Line 2)'),
  cityLabel: getResource('catalog.checkout.cityLabel', 'City'),
  stateLabel: getResource('catalog.checkout.stateLabel', 'State/Province'),
  countryLabel: getResource('catalog.checkout.countryLabel', 'Country'),
  postalCodeLabel: getResource('catalog.checkout.postalCodeLabel', 'Postal Code'),
  provinceLabel: getResource('catalog.checkout.provinceLabel', 'Province')
});

const mapDispatchToProps = (dispatch) => ({});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps),
  reduxForm({
    form: 'OrderPersonalInfoForm' as any,
    enableReinitialize: true
  })
)(EditInfo);
