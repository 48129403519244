import * as events from '../../events';

const initialState = {
  isCheckoutOpen: false,
  isShippingInfoOpen: false,
  isPointsBreakdownOpen: false,
  userInfo: {}
};

const constructUserInfo = (state, payload) => {
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      firstName: payload.firstName || state.firstName,
      lastName: payload.lastName || state.lastName,
      businessName: payload.businessName || state.businessName,
      addressOne: payload.addressOne || state.addressOne,
      addressTwo: payload.addressTwo || state.addressTwo,
      city: payload.city || state.city,
      state: payload.state || state.state,
      postalCode: payload.postalCode || state.postalCode,
      province: payload.province || state.province
    }
  };
};

const checkoutReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case events.SET_ORDER_USER:
      return {
        ...state, // Change me back ONCE GYFT EMAIL IS IN USER PROFILE
        userInfo: {
          ...payload,
          gyftEmail: payload.gyftEmail || payload.emailAddress,
          digitalCardEmail: state.userInfo.digitalCardEmail
        }
      };
    case events.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          [payload.name]: payload.value
        }
      };
    case events.SAVE_PROFILE_INFO:
      return constructUserInfo(state, payload);
    case events.SHIPPING_MODAL_TOGGLE:
      return {
        ...state,
        isShippingInfoOpen: !state.isShippingInfoOpen
      };
    case events.POINTS_MODAL_TOGGLE:
      return {
        ...state,
        isPointsBreakdownOpen: !state.isPointsBreakdownOpen
      };
    default:
      return {
        ...state
      };
  }
};

export { checkoutReducer };
