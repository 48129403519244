import { TeamNomination } from '../../../types';
import * as events from '../../events';
import { teamNominationSelectors } from '../../selectors/index';

export const selectTeamProductOptionIdBase = (appKey): TeamNomination.Action => {
  return {
    type: events.SELECT_PRODUCT_OPTION_ID,
    payload: appKey
  };
};

export const setTeamNominationProductOptionId = (value) => ({
  type: events.SET_TEAM_NOMINATION_OPTION,
  payload: value
});

const selectTeamProductOptionId = (value) => (dispatch, getState) => {
  const state = getState();
  if (teamNominationSelectors.getCurrentOptionId(state) !== value) {
    dispatch(selectTeamProductOptionIdBase(value));
  }
};

export default selectTeamProductOptionId;