import { clientStateSelectors } from 'rsv8-client';
import { contentCultureSelector } from 'rsv8-cms';
import { ActivityTypeDto, SocialDetailModel } from 'xcel-api-generator/dist/social';
import { createTypeSelector } from 'xcel-redux-orm';
import * as events from '../events';

const SOCIAL_ACTIVITIES = 'socialDetailModal';
const SOCIAL_ACTIVITY_TYPES = 'activityTypeDto';

export interface DropdownOption {
  label: string;
  value: any;
}

/**
 * Helper function for converting arrays or objects in dropdown options
 *
 * @param collection Array | Object
 * @param mapProperties Object - It's an object which is label key has
 *                               name of the property that it will be taken,
 *                               same for its value key
 */
function mapOptions(collection, mapProperties: DropdownOption) {
  let result: Array<DropdownOption> = [];

  if (collection.isArray) {
    result = collection.map((item) => convertToOption(item, mapProperties));
  } else {
    result = Object.keys(collection).map((index) => convertToOption(collection[index], mapProperties));
  }

  return result;
}

function convertToOption(item: object, mapProperties: DropdownOption) {
  return {
    label: item[mapProperties.label],
    value: item[mapProperties.value]
  };
}

export const activitiesSelector = createTypeSelector<SocialDetailModel>(SOCIAL_ACTIVITIES);
export const activityTypesSelector = createTypeSelector<ActivityTypeDto>(SOCIAL_ACTIVITY_TYPES);

export const activityId = (state) => state[events.ROOT_SOCIAL].activityId;

/**
 * Get all activities in a dictionary where key is the activity ID and the value
 * is the activity attributes, and this function grathers those an Array
 */
export const activities = (state) => {
  const allActivities = activitiesSelector.selectMany(state, null) || {};
  return Object.keys(allActivities).map((id) => allActivities[id]);
};

/**
 * Parsing the incoming activityType data in order to make it component friendly
 */
export const activityTypes = (state) => {
  const allActivitiesTypes = activityTypesSelector.selectMany(state, null) || {};
  return mapOptions(allActivitiesTypes, { label: 'name', value: 'id' });
};

export const culturesByActivity = (state, { cultures = [] }: any) => {
  const cultureIds = cultures.map(({ id }) => id);
  return contentCultureSelector.selectMany(state, cultureIds) || [];
};

export const versions = (state) => {
  const { culture = {} } = state[events.ROOT_SOCIAL];
  const versions = culture.versions || [];

  return versions.slice().reverse();
};

export const currentVersion = (state) => {
  const { version } = state[events.ROOT_SOCIAL];
  return version || {};
};

export const currentCulture = (state) => {
  const { culture = {} } = state[events.ROOT_SOCIAL];
  return culture;
};

export const countries = (state) => {
  const countries = clientStateSelectors.getCountry(state) || [];
  const countryOptions = mapOptions(countries, {
    label: 'name',
    value: 'countryCode'
  });

  return countryOptions;
};

export const cultureList = (state) => {
  const cultures = clientStateSelectors.getCultureList(state) || [];
  const cultureOptions = mapOptions(cultures, { label: 'name', value: 'id' });

  return cultureOptions;
};

export const selectedVersion = (state) => state[events.ROOT_SOCIAL].selectedVersion || 0;
