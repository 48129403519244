import { destroyRequest, mapJsonApiActions } from 'xcel-redux-orm';
import { accountApi, nominationApi } from '../../api/index';

const accountApiActions = mapJsonApiActions(accountApi);
const nominationApiActions = mapJsonApiActions(nominationApi);

const myAwardActions = {
  get: (params) => (dispatch, getState) => {
    if (params.filter === undefined) {
      // because filtering doesn't work, and we have no choice but to delete all the things.
      // it's just another compromise.
      dispatch(destroyRequest('getNominationsMyawards'));
    }
    return dispatch(nominationApiActions.getNominationsMyawards({ ...params }, { jsonApi: true }));
  },
  getDetails: (id) => nominationApiActions.getNominationsDetailsById({ id }, { jsonApi: true }),
  createRedeem: (id, action) => nominationApiActions.createRedeem({ action: { id, action } }, { jsonApi: true }),
  getAccountSummary: () => accountApiActions.getAccountSummary({ jsonApi: true })
};
export default myAwardActions;
