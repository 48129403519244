import * as React from 'react';
import StatusBlock from './StatusBlock';

export interface NominationStatusBlockProps {
  data: { status: string; statusId: any };
  field: string;
}

const NominationStatusBlock: React.SFC<NominationStatusBlockProps> = ({ data, field, ...rest }) => {
  if (field !== 'status') return <span>{data[field]}</span>;
  return <StatusBlock status={data.statusId}>{data.status}</StatusBlock>;
};

export default NominationStatusBlock;
