import { stringify } from 'query-string';
import {
  compose,
  prop,
  sortBy,
  toLower
  } from 'ramda';
import * as React from 'react';
import { Row } from 'react-bootstrap';
import { Link, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import {
  BrowseCard,
  BrowseTitle,
  Card,
  CardContainer,
  CardContent,
  PhantomCard,
  StyledCol
  } from './styles';

interface CategoryBrowseProps {
  categoryBrowseHeading: string;
  childCategories: any;
}

class CategoryBrowse extends React.Component<CategoryBrowseProps, any> {
  constructQuery = (id) => {
    return stringify({
      filterCategories: id
    });
  };

  render() {
    const { categoryBrowseHeading, childCategories } = this.props;
    const sortByNameCaseInsensitive = sortBy(
      compose(
        toLower,
        prop('name')
      )
    );

    if (Array.isArray(childCategories)) {
      const categories = sortByNameCaseInsensitive(childCategories).filter((child) => child.hasProducts);
      return (
        <Row>
          <StyledCol xs={24} xsOffset={0} sm={20} smOffset={2}>
            <BrowseTitle>{categoryBrowseHeading}</BrowseTitle>
            <CardContainer>
              {categories.map((child) => (
                <Card key={child.id}>
                  <Link to={{ pathname: '/catalog/search', search: this.constructQuery(child.id) }}>
                    <BrowseCard>
                      <CardContent>
                        <Text themeVariation="primary-text-2xl-bold">{child.name}</Text>
                      </CardContent>
                    </BrowseCard>
                  </Link>
                </Card>
              ))}
              {/* Flexbox requires some interesting hackery to move the last items in a space-between row left */}
              {/* Solution is to create 'phantom' divs. I just have to do it responsively ;) */}
              <PhantomCard categoryCount={categories.length} index={1} />
              <PhantomCard categoryCount={categories.length} index={2} />
              <PhantomCard categoryCount={categories.length} index={3} />
            </CardContainer>
          </StyledCol>
        </Row>
      );
    } else {
      return null;
    }
  }
}

const mapResourceToProps = (getResource) => ({
  categoryBrowseHeading: getResource('catalog.categoryBrowse.categoryBrowseHeading', 'Shop by Category')
});

export default withResource(mapResourceToProps)(CategoryBrowse);
