import * as React from 'react';
import {
  Alert,
  FormGroup,
  PanelGroup,
  Radio
  } from 'react-bootstrap';
import { Panel, PanelBody } from 'rsv8-components';
import { ErrorFormContainer } from '../SearchBar/SearchBar';
import { withResource } from 'xcel-react-core';

const CriteriaInput = ({ input, meta, options, label, resource: { criteriaInputError }, ...rest }) => {
  const $options = options.map((opt, i) => (
    <Panel key={i}>
      <PanelBody>
        <Radio {...input} checked={input.value.toString() === opt.value.toString()} value={opt.value}>
          {opt.label}
        </Radio>
      </PanelBody>
    </Panel>
  ));
  return (
    <FormGroup>
      <PanelGroup id={input.name}>
        <ErrorFormContainer>
          {meta.invalid && meta.touched ? <Alert bsStyle="danger">{criteriaInputError}</Alert> : null}
        </ErrorFormContainer>
        {$options}
      </PanelGroup>
    </FormGroup>
  );
};

const mapResourceToProps = (getResource) => ({
  resource: { criteriaInputError: getResource('nominations.criteriaInputError', 'Please select a Criteria') }
});

export default (withResource(mapResourceToProps))(CriteriaInput);
