import { getRequestEndpoint, getSingleRequestByEndpoint } from 'xcel-redux-orm';
import {
  DELETE_DRAFT_ENDPOINT,
  GET_INDIVIDUAL_DRAFT_ENDPOINT,
  GET_TEAM_DRAFT_ENDPOINT,
  NOMINATION_ACTIVITY_REDUCER_KEY,
  NOMINATIONS_HISTORY_ENDPOINT,
  NOMINATIONS_INDIVIDUAL_DRAFT_ENDPOINT,
  NOMINATIONS_TEAM_DRAFT_ENDPOINT
  } from '../../constants';
import { ReduxState as State, Resources } from '../../types';
import {
  individualDraftSelectors,
  individualNominationHistorySelectors,
  nominationDetailSelectors,
  teamDraftSelectors,
  teamNominationHistorySelectors
  } from './nomination';

export const getDraftLoading = (state: any, id: string) => {
  return (
    getSingleRequestByEndpoint(state, DELETE_DRAFT_ENDPOINT, { id }).loading ||
    getSingleRequestByEndpoint(state, GET_INDIVIDUAL_DRAFT_ENDPOINT, { id }).loading ||
    getSingleRequestByEndpoint(state, GET_TEAM_DRAFT_ENDPOINT, { id }).loading
  );
};

export const getNominationActivityState = (state: State) => state[NOMINATION_ACTIVITY_REDUCER_KEY];

export const getModalVisibility = (state: State) => getNominationActivityState(state).detailsModalVisible;

export const getModalId = (state: State) => getNominationActivityState(state).detailsModalId;

export const getModalNominationDetail = (
  state: State,
  id?: number,
  type?: string
): Resources.NominationDetail | Resources.TeamNominationHistory => {
  const modalId = id || getModalId(state);
  const modalType = type || getNominationActivityState(state).detailsModalType;
  if (modalType === 'NominationHistoryTeam') {
    return modalId && teamNominationHistorySelectors.selectOne(state, modalId.toString());
  } else {
    return modalId && nominationDetailSelectors.selectOne(state, modalId.toString());
  }
};

export const getIndividualDrafts = (state: State) => individualDraftSelectors.selectMany(state, null) || [];

export const getTeamDrafts = (state: State) => teamDraftSelectors.selectMany(state, null) || [];

export const getIndividualNominationHistory = (state: State) =>
  individualNominationHistorySelectors.selectMany(state, null) || [];

export const getTeamNominationHistory = (state: State) => teamNominationHistorySelectors.selectMany(state, null) || [];

export const getIndividualDraftsRequest = (state: any) =>
  getRequestEndpoint(state, NOMINATIONS_INDIVIDUAL_DRAFT_ENDPOINT);

export const getTeamDraftsRequest = (state: any) => getRequestEndpoint(state, NOMINATIONS_TEAM_DRAFT_ENDPOINT);

export const getHistoryRequest = (state: any) => getRequestEndpoint(state, NOMINATIONS_HISTORY_ENDPOINT);

export const getTeamMemberIds = (state: any) => getNominationActivityState(state).teamMembers;
