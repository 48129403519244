import * as React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelBody } from 'rsv8-components';
import styled from 'styled-components';
import { awardSelectors } from '../../redux/selectors/index';
import { Resources } from '../../types';
import Award from './Award';

const MyPanel: any = styled(Panel)`
  min-height: 175px;

  @media (max-width: 1366px) {
    min-height: 210px;
  }
  @media (max-width: 992px) {
    min-height: 235px;
  }
  @media (max-width: 768px) {
    min-height: auto;
  }

  &.panel {
    height: 95%;
    &.active {
      border: solid 1px #b5be35;
    }
  }

  &.disabled {
    background: transparent;

    h3,
    span {
      color: #e1e1e1 !important;
    }
  }
`;

export interface AwardPanelProps {
  id: string;
  active?: boolean;
  disabled?: boolean;
  onClick: (event: any) => void;
  awardRenderer?: React.SFC<{ award: any }> | React.ComponentClass<{ award: any }>;
  className?: string;
}

interface InternalProps extends AwardPanelProps {
  award: Resources.Award;
  dispatch: Function;
}

const doNothing = () => undefined;

const AwardPanel: React.SFC<InternalProps> = ({
  id,
  award,
  active = false,
  className,
  onClick,
  disabled = false,
  awardRenderer: AwardComponent = Award,
  dispatch,
  ...rest
}) => {
  return (
    <MyPanel
      onClick={!disabled ? onClick : doNothing}
      hover={true}
      disabled={disabled}
      className={`${className || ''} ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
      {...rest}
    >
      <PanelBody>
        <AwardComponent award={award} />
      </PanelBody>
    </MyPanel>
  );
};

const mapState = (state, ownProps) => {
  const selectedAward = awardSelectors.selectOne(state, ownProps.id) || ({} as Resources.AwardAttributes);
  const award = Array.isArray(selectedAward) ? selectedAward[0] : selectedAward;

  return {
    award,
    disabled: !award.isEligible
  };
};

export default connect(
  mapState,
  null
)(AwardPanel) as React.ComponentClass<AwardPanelProps>;
