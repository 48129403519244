import * as React from 'react';
import { HeaderTwo, Tab, Tabs } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import IndividualHistoryTable from './IndividualHistoryTable';
import TeamHistoryTable from './TeamHistoryTable';
// import { Col, Row } from 'react-bootstrap';

const NominationHistory: React.SFC<ReturnType<typeof mapResource>> = (props) => {
  return (
    <Tabs defaultActiveKey={0}>
      <Tab eventKey={0} title={props.individualLabel}>
        <HeaderTwo>{props.individualHeader}</HeaderTwo>
        <IndividualHistoryTable />
      </Tab>
      <Tab eventKey={1} title={props.teamLabel}>
        <HeaderTwo>{props.teamHeader}</HeaderTwo>
        <TeamHistoryTable />
      </Tab>
    </Tabs>
  );
};

const mapResource = (getResource) => ({
  teamLabel: getResource('nominationActivity.teamTabLabel', 'Team'),
  individualLabel: getResource('nominationActivity.individualTabLabel', 'Individual'),
  teamHeader: getResource('nominationActivity.nominationHistoryTeamHeader', 'Team History'),
  individualHeader: getResource('nominationActivity.nominationHistoryIndividualHeader', 'Individual History')
});

export default withResource(mapResource)(NominationHistory) as React.ComponentType<{}>;
