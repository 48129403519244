import * as React from 'react';
import { connect } from 'react-redux';
import { Amount, GoalDto } from 'xcel-api-generator/dist/alcon';
import { getValueOrZero } from '../../../utils';
import { GoalContent } from '../../constants';
import * as selectors from '../../redux/selectors';
import { GoalAchieved } from './GoalAchieved';
import { GoalInProgress } from './GoalInProgress';
import * as styles from './style';

const MarketShareGoal: React.FC<Props> = ({
  chartWidgetSize,
  completedLeftSideColor,
  notcompletedLeftSideColor,
  subTitleText = 'share growth needed',
  goalContent,
  goal,
  current,
  baseline,
}) => {
  const baseAmount = baseline?.marketShare * 100 || 0.0;
  const myAmount = (current?.marketShare * 100 || 0.0) - baseAmount;
  const goalTarget = (goal?.targetMarketShare || 0.0) - baseAmount;
  const isGoalAchived = myAmount >= goalTarget;
  const goalRemaining = (myAmount < goalTarget ? goalTarget - myAmount : 0).toFixed(1) + '%';

  return (
    <styles.GoalContainer>
      {isGoalAchived && <GoalAchieved color={completedLeftSideColor} chartWidgetSize={chartWidgetSize} isDisplayBar={false} />}

      {!isGoalAchived && (
        <GoalInProgress
          chartWidgetSize={chartWidgetSize}
          myAmount={getValueOrZero(myAmount)}
          myGoalTarget={goalTarget}
          color={notcompletedLeftSideColor}
          goalRemaining={goalRemaining}
          subTitleText={subTitleText}
        />
      )}

      <styles.GoalsContainerRight color="transparent">
        {goalContent && <styles.GoalsRightTitle color={goalContent.goalTitleColor}>{goalContent.goalTitle}</styles.GoalsRightTitle>}
        {!goalContent && <styles.GoalsRightTitle color="#505d6f">GOAL #{goal.sequence}</styles.GoalsRightTitle>}

        <styles.GoalsRightSubtitle color="#505d6f">{goal.name}</styles.GoalsRightSubtitle>
        <styles.GoalsRightParagraph color="#505d6f">{goal.description}</styles.GoalsRightParagraph>
      </styles.GoalsContainerRight>
    </styles.GoalContainer>
  );
};

const mapStateToProps = (state) => ({
  current: selectors.marketShareAmountSelector.selectOne(state, 'Current'),
  baseline: selectors.amountSelector.selectOne(state, 'Baseline'),
});

interface StateProps {
  current: Amount;
  baseline: Amount;
}
interface DispatchProps {}
interface OwnProps {
  chartWidgetSize: number;
  completedLeftSideColor: string;
  notcompletedLeftSideColor: string;
  subTitleText: string;
  goalContent?: GoalContent;
  goal: GoalDto;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(MarketShareGoal);
