import * as React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

const QuantityFormGroup = styled(FormGroup)`
  margin-bottom: 0;
` as any;

const QuantityFormControl = styled(FormControl)`
  @media (max-width: 768px) {
    width: 55px;
  }
` as any;

const Quantity = styled.div`
  @media (max-width: 768px) {
    margin-right: 15px;
  }
` as any;

class CartQuantity extends React.Component<any, any> {
  createOptions = (quantity, productPoints, accountSummary) => {
    let options = [];
    const cartTotal = accountSummary.available.total;
    let availablePoints = cartTotal + quantity * productPoints;

    if (
      this.props.product &&
      accountSummary.hasPointRestrictions &&
      (this.props.product.isDigitalCard || this.props.product.isGyftProduct || this.props.product.nonTangible)
    ) {
      availablePoints = accountSummary.available.taxable + quantity * productPoints;
    }

    for (let i = 1; i <= quantity + 5; i++) {
      if (i <= quantity || (accountSummary && i * productPoints <= availablePoints)) {
        options.push(<option value={i}>{i}</option>);
      }
    }

    return options;
  };

  render() {
    const { product, changeQuantity, accountSummary } = this.props;

    return (
      <Quantity>
        <QuantityFormGroup controlId="formControlsSelect">
          <QuantityFormControl
            defaultValue={product.quantity}
            componentClass="select"
            placeholder="select"
            onChange={(e) => {
              changeQuantity(e, product.id);
            }}
          >
            {accountSummary && this.createOptions(product.quantity, product.productPoints, accountSummary)}
          </QuantityFormControl>
        </QuantityFormGroup>
      </Quantity>
    );
  }
}

export default CartQuantity;
