import { Text } from 'rsv8-components';
import styled from 'styled-components';

export const Promo = styled.div`
  margin-bottom: 30px;
` as any;

export const PromoDate = styled.div`
  color: #606060;
  margin-top: 5px;
` as any;

export const PointValue = styled(Text)`
  color: #7c8180;
  font-size: 20px;
  font-weight: bold;
` as any;

export const PointsNeeded = styled(Text)`
  color: #7c8180;
  margin-left: 15px;
  margin-right: 5px;

  @media (max-width: 768px) {
    display: block;
    margin: 5px 0;
  }
` as any;

export const ListPrice = styled.span`
  color: #d8374b;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
` as any;
