import * as React from 'react';
import { compose } from 'redux';
import { Card } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { FieldContainer, SubmitButton } from '../../../components/FormControls';
import { LoginMaxWidth } from '../../../components/LoginMaxWidth';
import { StyledFormGroup } from '../styles/index';

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
` as any;

const CalloutBox = styled(Card).attrs({
  style: (props: any) => ({
    background: props.background,
    border: props.border
  })
})`
  border: 1px solid;
  border-radius: 3px;
  font-family: helvetica;
  font-size: 13px;
  margin: 20px 0 !important;
  box-shadow: none !important;
  padding: 10px;
` as any;

function ErrorList(props: any) {
  return (
    <ul>
      {props.passwordRules.map((item, index) => {
        return <li key={index}>{item.resourceLabel}</li>;
      })}
    </ul>
  );
}

function ErrorListValidation(props: any) {
  return (
    <ul>
      {props.error.map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
}

interface ChangePasswordFormProps {
  passwordExpressions: any;
  resource: {
    submitButtonLabel: any;
    currentPasswordLabel: any;
    newPasswordLabel: any;
    confirmPasswordLabel: any;
    tooltipHeader: string;
    showPasswordLabel: string;
  };
  handleSubmit: any;
  disableShowPassword: boolean;
  error: any;
}

interface ChangePasswordFormState {
  showPassword: boolean;
  passwordValue: boolean;
  currentPasswordValue: boolean;
  passwordConfirmationValue: any;
}

class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showPassword: false,
      passwordValue: false,
      currentPasswordValue: false,
      passwordConfirmationValue: ''
    };
  }

  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChangeCurrent = (e) => {
    this.setState({ currentPasswordValue: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ passwordValue: e.target.value });
  };

  handleChangeConfirmation = (e) => {
    this.setState({ passwordConfirmationValue: e.target.value });
  };

  render() {
    const {
      resource: {
        submitButtonLabel,
        currentPasswordLabel,
        newPasswordLabel,
        confirmPasswordLabel,
        tooltipHeader,
        showPasswordLabel
      },
      passwordExpressions,
      disableShowPassword,
      error
    } = this.props;
    return (
      <LoginMaxWidth>
        <form onSubmit={this.props.handleSubmit}>
          <CalloutBox
            themeVariation={error ? 'requirements-error' : 'requirements'}
            background={error ? '#f2dede' : '#ebebeb'}
            border={error ? '1px solid #a94442' : '1px solid #c8c8c8'}
          >
            <p>{tooltipHeader}</p>
            {!this.props.error ? (
              <ErrorList passwordRules={passwordExpressions} />
            ) : (
              <ErrorListValidation error={this.props.error} />
            )}
          </CalloutBox>
          <StyledFormGroup
            controlId="formResetPasswordValidationError"
            validationState={
              this.props.error && (!this.state.passwordValue || !this.state.passwordConfirmationValue) ? 'error' : null
            }
          >
            <Field
              name="currentPassword"
              type={this.state.showPassword ? 'text' : 'password'}
              component={'bs-input'}
              label={currentPasswordLabel}
              value={this.state.currentPasswordValue}
              onChange={this.handleChangeCurrent}
            />
            <Field
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              component={'bs-input'}
              label={newPasswordLabel}
              value={this.state.passwordValue}
              onChange={this.handleChange}
            />
            <Field
              name="passwordConfirmation"
              type={this.state.showPassword ? 'text' : 'password'}
              component={'bs-input'}
              value={this.state.passwordConfirmationValue}
              onChange={this.handleChangeConfirmation}
              label={confirmPasswordLabel}
            />
          </StyledFormGroup>
          {!disableShowPassword && (
            <Field
              name="showPassword"
              type="checkbox"
              component={'checkbox'}
              label={showPasswordLabel}
              onChange={this.togglePassword}
            />
          )}
          <FieldContainer>
            <StyledSubmitButton
              value={submitButtonLabel}
              disabled={
                this.props.error &&
                (!this.state.currentPasswordValue || !this.state.passwordValue || !this.state.passwordConfirmationValue)
              }
            />
          </FieldContainer>
        </form>
      </LoginMaxWidth>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  resource: {
    submitButtonLabel: getResource('changePassword.submitButtonText', 'Change Password'),
    currentPasswordLabel: getResource('changePassword.currentPasswordText', 'Current Password'),
    newPasswordLabel: getResource('changePassword.newPasswordText', 'New Password'),
    confirmPasswordLabel: getResource('changePassword.confirmPasswordText', 'Confirm Password'),
    tooltipHeader: getResource('login.tooltipHeader', 'Password requirements:'),
    showPasswordLabel: getResource('login.showPasswordLabelText', 'Show Password')
  }
});

export default compose(
  withResource(mapResourceToProps),
  reduxForm({ form: 'changePassword' })
)(ChangePasswordForm as any) as any;
