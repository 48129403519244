import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { withResource } from 'xcel-react-core';
import { toggleOrderDetailsModal, toggleProductDetailsModal } from '../../redux/actions';
import { FavoritesButton } from '../FavoritesButton';
import {
  CardFooter,
  CardImage,
  Details,
  ImageContainer,
  ProductContainer,
  ProductName,
  RewardCardContainer
  } from './styles';
import { CarouselCardProps } from './types';

class CarouselRewardCard extends React.Component<CarouselCardProps> {
  toggleModal = () => {
    const { toggleProductModal, toggleOrderModal, product, level } = this.props;
    return product.orderId ? toggleOrderModal(product, level) : toggleProductModal(product, level);
  };

  render() {
    const { favoritesList, product, level, detailsLabel, orderDetailsLabel, redeemLabel } = this.props;
    if (!product) {
      return null;
    }

    const { imageLg } = Array.isArray(product.images) ? product.images[0] : product.images;
    let label = detailsLabel;
    if (level.achievedDate) {
      label = product.orderId ? orderDetailsLabel : redeemLabel;
    }
    return (
      <RewardCardContainer>
        <ImageContainer>
          <CardImage imageurl={imageLg} />
        </ImageContainer>
        <ProductContainer>
          <ProductName className="product-name">{product.name || product.productName}</ProductName>
        </ProductContainer>
        <CardFooter>
          <Details themeVariation="achievement-details-button" onClick={this.toggleModal}>
            {label}
          </Details>
        </CardFooter>
        <FavoritesButton favorites={favoritesList} product={product} />
      </RewardCardContainer>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  redeemLabel: getResource('achievement.programLanding.card.redeemLabel', 'Redeem Now'),
  detailsLabel: getResource('achievement.programLanding.card.detailsLabel', 'Details'),
  orderDetailsLabel: getResource('achievement.programLanding.card.orderDetailsLabel', 'Order Details')
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleProductModal: toggleProductDetailsModal,
      toggleOrderModal: toggleOrderDetailsModal
    },
    dispatch
  );

export default compose(
  withRouter,
  withResource(mapResourceToProps),
  connect(null, mapDispatchToProps)
)(CarouselRewardCard) as any;
