import styled from 'styled-components';
export interface ColorSwatchProps {
  color: string;
  margin: string;
  padding: string;
  value: string;
}
const ColorSwatch = styled.div`
  width: 1.9rem;
  height: 1.9rem;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  border: 1px solid #c5c5c5;
  border-radius: 0.25rem;
  background-color: ${(props: ColorSwatchProps) => props.color};
  ${(props: ColorSwatchProps) => (props.margin ? `margin: ${props.margin};` : '')};
  ${(props: ColorSwatchProps) => (props.padding ? `padding: ${props.padding};` : '')};
  ${(props: ColorSwatchProps) => {
    const { value = '' } = props;
    if (value === undefined || value === null || value === '') {
      return `
            position: relative;
            overflow: hidden;
            &:before {
                content: ' ';
                background-color: red;
                position: absolute;
                height: 150%;
                width: 2px;
                transform: translate(-50%, -55%) rotate(45deg);
                left: 50%;
                top: 50%;
            }
        `;
    }
    return '';
  }};
` as any;
export default ColorSwatch;
