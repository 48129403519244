import * as events from '../../events';

const initialState = {
  currentCart: []
};

const cartReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case events.SET_CART:
      return {
        ...state,
        currentCart: payload.filter(result => result !== null && result.productId).map((result) => result && result.id)
      };
    default:
      return {
        ...state
      };
  }
};

export { cartReducer };
