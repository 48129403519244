import { splitEvery } from 'ramda';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Loader } from 'rsv8-components';
import { Context } from 'rsv8-wizard';
import { Resources } from '../../types';
import { AwardPanel } from '../index';

export interface AwardSelectionProps {
  awards: Array<Resources.Award>;
  nominee?: number;
  selectedAward?: string | number;
  requestData: { loading: boolean; exists: boolean };
  confirmAwardChangeText: any;
  cultureId: number;
  wizard?: Context;
  actions: {
    getAwards: (nomineeId?: string | number) => void;
    selectAward: (awardId: string | number, confirmText: string) => void;
    getAwardDetails: (awardId: string, nominee: number) => void;
  };
}

class AwardSelection extends React.Component<AwardSelectionProps, {}> {
  componentDidMount() {
    this.props.actions.getAwards(this.props.nominee);
  }

  handleClick = (value) => (e) => {
    this.props.actions.getAwardDetails(value, this.props.nominee);
    this.props.actions.selectAward(value, this.props.confirmAwardChangeText.toString());
  };

  renderAward = (award, i, twoAwards) => {
    return (
      <Col key={award.id} xs={24} sm={12}>
        <AwardPanel active={this.props.selectedAward === award.id} onClick={this.handleClick(award.id)} id={award.id} />
      </Col>
    );
  };

  componentDidUpdate(prevProps: AwardSelectionProps, prevState: {}) {
    if (prevProps.selectedAward !== this.props.selectedAward) {
      if (this.props.wizard !== undefined) {
        this.props.wizard.enableAllSteps();
      }
    }
    if (prevProps.cultureId !== this.props.cultureId) {
      this.props.actions.getAwards(this.props.nominee);
    }
  }

  sort = (a, b) => {
    if (a.sequence > b.sequence) return 1;
    if (a.sequence < b.sequence) return -1;
    return 0;
  };

  renderAwardsRow = (twoAwards, i) => {
    return <Row key={i}>{twoAwards.sort(this.sort).map(this.renderAward)}</Row>;
  };

  render() {
    const { awards, requestData } = this.props;
    if (!requestData.exists || requestData.loading) {
      return <Loader />;
    }
    const splitAwards = splitEvery(2, awards);
    return <React.Fragment>{splitAwards.map(this.renderAwardsRow)}</React.Fragment>;
  }
}

export default AwardSelection;
