import * as React from 'react';
import { Panel as PanelBase, PanelProps } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const HoverCss = css`
  :hover {
    background-color: #f5f5f5;
  }
`;

const Panel = styled(PanelBase)`
  ${({ hover = false }: { hover: boolean }) => (hover ? HoverCss : '')};
  ${({ border = true }: any) => (border ? '' : 'border: none')};
` as any;

export default register('rsv8-components/Panel')(withTheme(['&.active']))(Panel as React.SFC<
  PanelProps & { hover?: boolean; border?: boolean }
>);
