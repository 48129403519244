import * as React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Forms } from '../../types';
import addValidationState from '../HOC/addValidationState';
import FormControl from './FormControl';
import { OptionsList, StatusText } from './helpers';
import Label from './Label';
import { TooltipContainer } from './TooltipContainer';
const getSelectValues = (select: any) => {
  const result = [];
  const options = select && select.options;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    const opt = options[i];

    if (opt.selected) {
      result.push(opt.value);
    }
  }
  return result;
};

const MultiSelect: Forms.Inputs.MultiSelect = ({
  input,
  meta,
  validationState,
  helpText,
  helpIconText,
  options,
  optionRenderer = OptionsList,
  ...rest
}) => {
  const { onChange, value, ...inputProps } = input;
  const OptionsComponent = optionRenderer as any;
  return (
    <FormGroup controlId={input.name}>
      <Label>{rest.label}</Label>
      {helpIconText && <TooltipContainer tooltipChildren={helpIconText} />}
      <FormControl
        componentClass="select"
        multiple={true}
        onChange={(e) => {
          input.onChange(getSelectValues(e.currentTarget));
        }}
        value={[].concat(value)}
        {...inputProps}
      >
        {options && <OptionsComponent options={options} />}
      </FormControl>
      <StatusText meta={meta} helpText={helpText} />
    </FormGroup>
  );
};

export default addValidationState(MultiSelect);
