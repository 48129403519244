import { ContentCultureModel } from 'xcel-api-generator/dist/cms';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { contentDetailSelector, contentSelector } from './index';
const contentCultureSelector = {
  ...createTypeSelector<ContentCultureModel>('contentCultureModel'),
  getByCultureIdAndProviderId: (state, contentTypeId, cultureId, providerId) => {
    const content = contentDetailSelector.getActive(state, contentTypeId);
    const result = content.cultures.find(
      (contentCulture) =>
        contentCulture.cultureId.toString() === cultureId.toString() &&
        contentCulture.provider.id.toString() === providerId.toString()
    );
    return result;
  },
  getActive: (state, contentTypeId) => {
    const active = contentSelector.getActiveProperty(state, contentTypeId);
    if (!active) return undefined;
    const { contentCultureId } = active;
    if (!contentCultureId) return null;
    return contentCultureSelector.selectOne(state, contentCultureId);
  }
};
export default contentCultureSelector;
export { Resource };
