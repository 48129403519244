import { CalculatorMetric, CalculatorResult, TimePeriod } from 'xcel-api-generator/dist/metric';
import { createTypeSelector } from 'xcel-redux-orm';

const timePeriod = createTypeSelector<TimePeriod>('timePeriod')
const calculatorMetric = createTypeSelector<CalculatorMetric & { timePeriodKey: string}>('calculatorMetric')
const calculatorResult = createTypeSelector<CalculatorResult>('calculatorResult')
export { CalculatorMetric, CalculatorResult, TimePeriod, calculatorMetric, calculatorResult, timePeriod }

import { AUTH_USER_STATE } from '../../constants';
export const getUserCultureLanguage = (state) => {
  const {
    [AUTH_USER_STATE]: { cultureLanguage }
  } = state;
  return cultureLanguage;
};
