import { Amount, CustomerProduct, MarketShareAmount, ProductSummary } from 'xcel-api-generator/dist/alcon';

export function isValidData(data: any) {
  let isValid: boolean = false;
  if (data !== undefined && data !== null) {
    isValid = true;
  }
  return isValid;
}
export function filterSummaryData(summaryData: any, findType: string) {
  let data: any;
  if (summaryData !== undefined) {
    if (summaryData.data !== undefined) {
      if (summaryData.data.type.toLowerCase() === toLowerCase(findType)) {
        if (summaryData.data.attributes !== undefined) {
          return summaryData.data.attributes;
        }
      }
    }
  }

  return data;
}

export const allowDollarChars = (value: any) => {
  return value && value !== undefined && value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
};

export function getProductSummaryData(summaryData: ProductSummary, findType: string) {
  let products: any = filterSummaryIncludedData(summaryData, findType);
  if (products !== undefined) {
    products = products.filter((product) => toLowerCase(product.type) === toLowerCase(findType) && product.attributes.name === null);
  }
  return products;
}

export function getAllProductsData(summaryData: ProductSummary, findType: string) {
  let products: any = filterSummaryIncludedData(summaryData, findType);
  if (products !== undefined) {
    products = products.filter((product) => toLowerCase(product.type) === toLowerCase(findType) && product.attributes.name !== null);
  }
  return products;
}

export function filterSummaryIncludedData(summaryData: any, findType: string) {
  let data: any;

  if (summaryData !== undefined) {
    if (summaryData.included !== undefined) {
      data = summaryData.included.filter((element) => {
        return element.type.toLowerCase() === toLowerCase(findType);
      });
    }
  }

  return data;
}

export const calculateUserPoints = (totalUserPoints: any) => {
  const totalPoints = totalUserPoints / 100;

  if (!totalPoints) {
    return 0;
  }
  return totalPoints;
};

export function toLowerCase(text?: string) {
  return text !== undefined ? text.toLowerCase() : text;
}

export const isNumber = (value) => {
  return value != null && (typeof value === 'number' || /^-?[0-9]([0-9.,]+)?/.test(value));
};

export const getDecimalValue = (value: any) => {
  if (isNumber(value)) {
    let num = Number(value);
    value = num.toFixed(2);
  } else if (value === null || value === undefined) {
    value = Number(0).toFixed(2);
  }

  return value;
};

export const getCurrencyValue = (value?: any, maximumFractionDigits = 2) => {
  if (value === undefined || value === null || !isNumber(value) || Number.isNaN(Number(value))) return value;

  return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits });
};

export const getRoundedValue = (value: any, fractionDigits = 2) => {
  if (value === undefined || value === null || !isNumber(value) || Number.isNaN(Number(value))) return value;

  return Number(value).toFixed(fractionDigits);
};

export const getMarketSharePercent = (amount: Amount, emptyValue?: string, addPercentageSign: boolean = true) => {
  if (amount === undefined || amount === null) return emptyValue;

  const value = amount.marketShare;
  if (value === undefined || value === null || !isNumber(value) || Number.isNaN(Number(value))) return emptyValue;

  return (value * 100).toFixed(2) + (addPercentageSign ? '%' : '');
};

export const convertToPercent = (value) => {
  return value * 100;
};

export const getGoalValues = (target: MarketShareAmount) => {
  return !target
    ? { shareGoal: null, salesGoal: null }
    : {
        shareGoal: isNumber(target.shareGoal) ? Math.round(target.shareGoal * 100 * 100) / 100 : 0,
        salesGoal: isNumber(target.salesGoal) ? target.salesGoal : 0,
      };
};

export const getValueOrNull = (value: number) => {
  if (!isNumber(value)) {
    return null;
  }
  return value > 0 ? value : null;
};

export const getValueOrZero = (value: string | number) => {
  if (!isNumber(value)) {
    return 0;
  }
  return Number(value) > 0 ? Number(value) : 0;
};

export const convertCustomerProductToPatient = (product: CustomerProduct) => {
  return {
    productFamilyKey: product.familyKey,
    productTypeKey: product.productTypeKey,
    patients: isNumber(product.customPatients) && product.customPatients > 0 ? product.customPatients : product.runRatePatients,
  };
};
