import * as React from 'react';
import BackendResourceContext from './BackendResourceContext';
import BackendResourceItem, { BackendResourceItemProps } from './DefaultResourceItem';

export interface BackendResourceProps {
  resourceKey: string;
  component?: React.ComponentType<BackendResourceItemProps>;
}

const ErrorWrapper: React.SFC<BackendResourceProps> = ({ resourceKey, component: Component = BackendResourceItem }) => {
  return (
    <BackendResourceContext.Consumer>
      {({ resourceIndex, resources }) => (
        <Component id={resourceKey} data={resourceIndex[resourceKey]} text={resources[resourceKey]} />
      )}
    </BackendResourceContext.Consumer>
  );
};

export default ErrorWrapper;
