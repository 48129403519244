import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AlertIcon from '../Icons/AlertIcon';

const AlertsButtonBase = styled.button`
  position: relative;
  height: 30px;
  width: 30px;
  margin-top: 20px;
  margin-left: 15px;
  background-color: rgba(255, 255, 255, 0);
  float: right;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 50%;
  padding: 0 0 0 0;
  border: none;
` as any;

const AlertsBadge = styled(Badge)`
  background-color: #f5a623;
  position: absolute;
  top: -5px;
  right: -8px;
` as any;

const AlertsButton = (props) => (
  <AlertsButtonBase onClick={props.onAlertsClick}>
    <AlertsBadge>{props.alertsCount}</AlertsBadge>
    <AlertIcon />
  </AlertsButtonBase>
);

const mapState = (state) => ({
  alertsCount: state.alertsCount || 3
});

const mapDispatch = (dispatch) => ({
  onAlertsClick: (e) => console.log('clicked alerts', e)
});

export default connect(
  mapState,
  mapDispatch
)(AlertsButton) as any;
