import * as d3 from 'd3';
import * as React from 'react';

export const Trophy: React.FunctionComponent<any> = ({ anchorId }) => {
  React.useEffect(() => {
    // Currently setting font family of SVG text manually
    // There's def a better way to do this
    // This is fine for the purposes of this demo
    const svg = d3.select(`#${anchorId}`).style('font-family', 'SimpleSans');

    // Create groups for text and position within svg
    const width = +svg.style('width').replace('px', '');
    const g = svg.append('g').attr('transform', 'translate(' + width / 2 + ', 0)');

    // Text Group of text positioned on trophy
    const text = g
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('x', '0')
      .attr('y', '0')
      .style('font-size', '14px')
      .style('fill', '#0b2265');

    // First row of text on top of trophy
    // @ts-ignore: D3 Code Convention
    const firstRowText = text // tslint:disable-line
      .append('tspan')
      .attr('x', '0')
      .attr('dy', '2em')
      .attr('text-anchor', 'middle')
      .text('In 2019, you');

    // second row of text on top of trophy
    // @ts-ignore: D3 Code Convention
    const secondRowText = text
      .append('tspan')
      .attr('x', '0')
      .attr('text-anchor', 'middle')
      .attr('dy', '1.5em')
      .text('have submitted');

    // Text for displaying input data
    // @ts-ignore: D3 Code Convention
    const dataText = text
      .append('tspan')
      .attr('x', '0')
      .attr('text-anchor', 'middle')
      .attr('dy', '1.1em')
      .text('9')
      .style('font-weight', '300')
      .style('font-size', '58px');

    // Text for displaying unit of input data
    // @ts-ignore: D3 Code Convention
    const lastRowText = text
      .append('tspan')
      .attr('x', '0')
      .attr('text-anchor', 'middle')
      .attr('dy', '1.5em')
      .text('nominations')
      .style('font-weight', 'bold');
  }, []);
  return <span />;
};
