import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const PrimaryMenuContainer = styled.div`
  background-color: #4192a5;
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
  position: relative;
  @media (max-width: 767px) {
    padding: 10px 15px;
  }
  .row {
    display: flex;
    align-items: center;
  }
`;

export default register('rsv8-app/PrimaryMenu')(withTheme())(PrimaryMenuContainer);
