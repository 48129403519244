import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUser } from '../../redux/actions/userActions';
import {
  Avatar,
  AvatarWrap,
  Container,
  ContentWrap,
  Detail,
  DetailIcon,
  LoadingContainer,
  Title,
  } from './styles';
import {
  EmailSvg,
  PhoneSvg,
} from './Icons';
import * as Icon from 'react-fontawesome';

interface UserPreviewProps {
  userId: number,
  dispatch: any,
}

const UserPreview: React.SFC<UserPreviewProps> = ({children, dispatch, userId, ...rest}) => {
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    dispatch(
      fetchUser(userId)
    ).then(result => {
      setFirstName(result.data.attributes.firstName);
      setLastName(result.data.attributes.lastName);
      setEmail(result.data.attributes.email);
      setEmail(result.data.attributes.email);
      setPhone(result.data.attributes.phone);
      setLoading(false);

    }).catch(err => console.log("failed to fetch user with id ", userId));
  }, []);

  return (
    <div {...rest}>
      {loading && (
        <LoadingContainer>
          <div>
            <Icon
              name="spinner"
              spin
            />
          </div>
        </LoadingContainer>
      )}

      {!loading && (
        <Container>
          <AvatarWrap>
            <Avatar>
              <span>{ firstName && firstName.charAt(0) }{ lastName && lastName.charAt(0) }</span>
            </Avatar>
          </AvatarWrap>

          <ContentWrap>
            <Title>
              {`${firstName} ${lastName}`}
            </Title>

            {email && (
              <Detail>
                <DetailIcon>
                  <EmailSvg />
                </DetailIcon>

                { email }
              </Detail>
            )}

            {phone && (
              <Detail>
                <DetailIcon>
                  <PhoneSvg />
                </DetailIcon>

                { phone }
              </Detail>
            )}
          </ContentWrap>
        </Container>
      )}
    </div>
  );
};

export default connect()(UserPreview);
