import * as React from 'react';
import { NomineeTableProps } from '../Shared/index';
import NomineePanel from './NomineePanel';

interface InternalProps extends NomineeTableProps {}

const NomineePanels: React.SFC<InternalProps> = (props) => {
  const renderNomineePanel = (key) => (
    <NomineePanel
      key={key}
      userId={key}
      active={props.currentNominees.indexOf(key.toString()) !== -1}
      loading={props.loadingNominees.indexOf(key.toString()) !== -1}
      selectNominee={props.selectNominee}
    />
  );
  return <div className={props.className}>{props.nomineeIds.map(renderNomineePanel)}</div>;
};

export default NomineePanels as React.SFC<NomineeTableProps>;
