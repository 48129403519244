import styled from 'styled-components';
export interface CardProps {
  width: string;
  height: string;
  borderRadius: string;
}
const Card = styled.div`
  display: inline-block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: ${(props: CardProps) => (props.width ? props.width : '100px')};
  height: ${(props: CardProps) => (props.height ? props.height : '100px')};
  border-radius: ${(props: CardProps) => (props.borderRadius ? props.borderRadius : '0px')};
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
` as any;

export default Card;
