import * as events from './events';

export default function navigationMenuReducer(state, action) {
  state = state || { isOpen: false };
  switch (action.type) {
    case events.NAVIGATION_MENU_TOGGLE: {
      return {
        ...state,
        isOpen: !state.isOpen
      };
    }
    case events.NAVIGATION_MENU_CLOSE: {
      return {
        ...state,
        isOpen: false
      };
    }
    case events.GET_NAVIGATION_MENU_SUCCESS: {
      const appKey = action.request.appKey;
      return {
        ...state,
        [appKey]: action.payload
      };
    }

    case events.GET_NAVIGATION_MENU_FAILURE:
    default: {
      return state;
    }
  }
}
