import { promiseDispatcher } from 'xcel-react-core';
import { destroy } from 'xcel-redux-orm';
import { nominationApi } from '../../../../api/index';
import { IndividualNomination } from '../../../../types';
import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';

const deleteDraftNominationCall = (id) => nominationApi.deleteNominationsById({ id });

const deleteDraftNominationSuccess = (response, id) => (dispatch, getState) => {
  if (id) {
    dispatch(destroy('draft', id));
  }
  const clearCurrentNominationData = individualNominationSelectors.getClearCurrentNominationData(getState());
  if (clearCurrentNominationData) {
    dispatch({ type: events.CLEAR_CURRENT_NOMINATION_DATA } as IndividualNomination.Action);
  } else {
    dispatch({ type: events.CLEAR_CURRENT_CUSTOM_FIELDS } as IndividualNomination.Action);
  }
};

const deleteDraftNominationFailure = (error, id) => (dispatch, getState) => {
  dispatch({
    type: events.DELETE_NOMINATION_DRAFT_FAILURE
  });
  const clearCurrentNominationData = individualNominationSelectors.getClearCurrentNominationData(getState());
  if (clearCurrentNominationData) {
    dispatch({ type: events.CLEAR_CURRENT_NOMINATION_DATA } as IndividualNomination.Action);
  } else {
    dispatch({ type: events.CLEAR_CURRENT_CUSTOM_FIELDS } as IndividualNomination.Action);
  }
};

export const clearCurrentAward = () => ({
  type: events.CLEAR_CURRENT_AWARD
});

export const setClearNominationData = (value) => ({
  type: events.SET_CLEAR_CURRENT_NOMINATION_DATA,
  payload: value
});

export const clearNomination = promiseDispatcher(deleteDraftNominationCall, {
  success: deleteDraftNominationSuccess,
  failure: deleteDraftNominationFailure
});

export const clearCurrentNomination = () => (dispatch, getState) => {
  const currentNomination = individualNominationSelectors.getCurrentNominationId(getState());
  if (currentNomination === null) {
    dispatch(deleteDraftNominationSuccess(null, null));
  } else {
    dispatch(clearNomination(currentNomination));
  }
};
