import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { HelpIcon } from '../Icons';

const StyledHelpIconSpan = styled.span`
  margin-left: 10px;

  /* Portrait and Landscape
  Galaxy S8+ Galaxy S8 Galaxy S7 Galaxy S6
  iPhone X iPhone 8 iPhone 7 iPhone 6 iPhone 6s */
  @media only screen and (min-device-width: 360px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    margin-left: 15px !important;
  }
` as any;

const TooltipContent = styled.div`
  margin: 10px 0;
`;

export const StyledTooltip = styled.div`
  .icon-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
  }
  .closebtn {
    position: absolute;
    top: 5px;
    right: 7px;
    font-size: 14px;
    color: #333333;
    margin-left: 50px;
    text-decoration: none;
  }
  .tooltip-inner {
    background-color: #fff;
    color: #333333;
    font-size: 14px;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }
` as any;

export interface TooltipContainerProps {
  tooltipChildren?: any;
}

export class TooltipContainer extends React.Component<TooltipContainerProps, any> {
  constructor(props: TooltipContainerProps) {
    super(props);
  }

  handleClick = () => {
    document.body.click();
  };

  render() {
    const tooltip = (
      <Popover>
        <StyledTooltip>
          <button className="icon-button pull-right" onClick={this.handleClick}>
            <a href="javascript:void(0)" onClick={this.handleClick} className="closebtn">
              ×
            </a>
          </button>
        </StyledTooltip>
        <TooltipContent>
          <div dangerouslySetInnerHTML={{ __html: this.props.tooltipChildren }} />
        </TooltipContent>
      </Popover>
    );
    return (
      <OverlayTrigger placement="right" overlay={tooltip} trigger="click" rootClose={true}>
        <StyledHelpIconSpan>
          <HelpIcon />
        </StyledHelpIconSpan>
      </OverlayTrigger>
    );
  }
}
