import * as React from 'react';
import { PanelBody, PanelCollapse } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import { CustomFieldsProps } from 'rsv8-customfields';
import { FieldOnChangeHandler, reduxForm } from 'rsv8-forms';
import TeamNominationContext from '../../TeamNominationContext';
export interface DetailPanelDetailsProps {
  customFieldFormType: string;
  customFieldGroupKey: string;
  awardId: string;
  teamMemberId: string;
  onChange: FieldOnChangeHandler;
  initialValues: any;
}

const ConnectedCustomFields: React.ComponentType<CustomFieldsProps> = reduxForm({} as any)((props: any) => {
  return (
    <TeamNominationContext.Consumer>
      {({ addForm }) => {
        addForm({ ...props });
        return (
          <form {...props}>
            <CustomFields {...props as any} />
          </form>
        );
      }}
    </TeamNominationContext.Consumer>
  );
}) as any;

const DetailPanelDetails: React.SFC<DetailPanelDetailsProps> = (props) => {
  return (
    <PanelCollapse>
      <PanelBody>
        <ConnectedCustomFields
          form={`TeamMemberDetails${props.teamMemberId}`}
          formType={props.customFieldFormType}
          groupKey={props.customFieldGroupKey}
          appKey={props.awardId}
          initialValues={props.initialValues}
          nomineeId={props.teamMemberId}
          onChange={props.onChange}
          onSubmit={() => {}}
        />
      </PanelBody>
    </PanelCollapse>
  );
};

export default DetailPanelDetails;
