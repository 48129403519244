import { Forms } from '../../types';

export interface ComponentMap {
  [index: string]: Forms.InputComponent;
}

const STRING_COMPONENTS = ['input', 'select', 'textarea'];

const isReservedName = (name: string): boolean => STRING_COMPONENTS.indexOf(name) !== -1;

export default class ComponentProvider {
  components: ComponentMap;

  constructor(initial: ComponentMap) {
    this.components = {};
    this._addMultipleComponents(initial);
  }

  addComponent(name: string, component: Forms.InputComponent) {
    if (isReservedName(name)) {
      console.log(`[ComponentProvider] The names 'input', 'textarea', and 'select' are reserved.`);
      return undefined;
    }
    if (this.hasComponent(name)) {
      console.log(`[ComponentProvider] Warning! You're overwriting a pre-existing '${name}' component.`);
    }
    this.components[name] = component;
  }

  getAllComponentNames() {
    return Object.keys(this.components);
  }

  getAllComponents() {
    return Object.keys(this.components).map((key) => this.components[key]);
  }

  // Return the named component, if available.
  // If not, attempt to fallback to HTML elements.
  // If that doesn't work, write to the log and return a plain input.
  getComponent(name: string): Forms.InputComponent | string {
    if (this.hasComponent(name)) {
      return this.components[name];
    } else if (isReservedName(name)) {
      return name;
    } else {
      this._logErrors(name);
      return 'input';
    }
  }

  hasComponent(name: string) {
    return this.components.hasOwnProperty(name);
  }

  _logErrors(name: string) {
    const validTypes = this.getAllComponentNames().join(', ');
    console.log(`[ComponentProvider] Cannot find the ${name} component.`);
    console.log(`[ComponentProvider] Currently available components are: ${validTypes}`);
  }

  _addMultipleComponents(list: ComponentMap) {
    this.components = { ...this.components, ...list };
  }
}
