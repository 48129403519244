import { path } from 'ramda';
import { ClientCountryModel, ClientCultureModel, ClientModel } from 'xcel-api-generator/dist/user';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { ROOT_STATE } from '../constants';
const clientSelector = {
  ...createTypeSelector<ClientModel>('clientModel'),
  getActive: (state): ClientModel => path([ROOT_STATE, 'client', 'active'], state)
};
export { ClientModel, ClientCountryModel, ClientCultureModel, Resource };
export default clientSelector;
