import { SalesApp, SalesEntryDetail } from 'xcel-api-generator/dist/sales';
import { createTypeSelector } from 'xcel-redux-orm';

export interface SalesActivityAttributes {
  salesAppKey: string;
  dateSubmitted: string;
  confirmationNumber: number;
  productName: string;
  statusKey: string;
  status: string;
}

export type SalesNavigationAttributes = {
  name: string;
  appKey: string;
};

export const salesActivitySelectors = createTypeSelector<SalesActivityAttributes>('salesEntrySummary');
export const saleDetailSelectors = createTypeSelector<SalesEntryDetail>('salesEntryDetail');
export const salesNavigationSelectors = createTypeSelector<SalesApp>('salesApp');
