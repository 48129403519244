import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Icon, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { teamMemberActions } from '../../redux/index';

export interface TeamMemberDeleteButtonProps {
  id: string | number;
}

interface InternalProps extends TeamMemberDeleteButtonProps {
  handleClick: (id: string | number) => void;
  deleteText: any;
}

const TeamMemberDeleteButton: React.SFC<InternalProps> = ({ handleClick, id, deleteText, children, ...rest }) => (
  <Text themeVariation="primary-link" onClick={() => handleClick(id)} {...rest}>
    <Icon icon="trash" />
    {'  '}
    {deleteText}
  </Text>
);

const mapDispatch = (dispatch) => bindActionCreators({ handleClick: teamMemberActions.remove }, dispatch);

const mapResource = (getResource) => ({ deleteText: getResource('nominationProcess.deleteTeamMemberLabel', 'Delete') });

export default compose<any>(
  connect(
    null,
    mapDispatch
  ),
  withResource(mapResource as any)
)(TeamMemberDeleteButton as any) as React.ComponentType<TeamMemberDeleteButtonProps>;
