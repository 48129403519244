import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { register, withContent, withResource } from 'xcel-react-core';
import { analyticsApiActions } from '../../redux/actions/analytics/analyticsApiActions';
import {
  getEmployeeReceivedPercentage,
  getPendingApprovalCount,
  getTotalAwardedTeamPoints,
  getYourTeamPercentage
  } from '../../redux/selectors/analyticsSelectors';
import { Doc } from './Doc';
import { Movie } from './Movie';
import {
  ButtonColumn,
  ButtonLink,
  Buttons,
  ButtonText,
  Dashboard,
  DashboardIcon,
  Description,
  DetailsLink,
  Header,
  Information,
  InfoRow,
  Title,
  Value
  } from './styles';
import { Support } from './Support';

interface Props {
  actions: {
    getData: (options) => void;
  };
  resources: {
    teamTotalAwarded: string;
    teamReceivedPercentage: string;
    teamAwardedPercentage: string;
    totalPendingLabel: string;
    helpLabel: string;
    policyLabel: string;
    tutorialLabel: string;
    detailsLink: string;
    title: string;
    detailsLinkUrl: string;
    policyUrl: string;
    policyTarget: string;
    helpUrl: string;
    tutorialUrl: string;
    tutorialTarget: string;
  };
  pendingApprovalCount: number;
  teamPercentage: number;
  teamAwardedValue: number;
  totalAwardedPoints: number;
}

const ManagerDashboard: React.FunctionComponent<Props> = (props) => {
  React.useEffect(() => {
    props.actions.getData({ ids: ['nomination', 'user'], startDate: '01-01-2019' });
  }, []);

  return (
    <Dashboard>
      <Header>
        <Title>{props.resources.title}</Title>
        <DetailsLink to={props.resources.detailsLinkUrl}>{props.resources.detailsLink}</DetailsLink>
      </Header>
      <Information>
        <InfoRow>
          <Description>{props.resources.teamTotalAwarded}</Description>
          <Value>{props.totalAwardedPoints && props.totalAwardedPoints.toLocaleString()}</Value>
        </InfoRow>
        <InfoRow>
          <Description>{props.resources.teamReceivedPercentage}</Description>
          <Value>
            {props.teamPercentage !== undefined &&
              props.teamPercentage !== null &&
              `${props.teamPercentage.toFixed(0)}%`}
          </Value>
        </InfoRow>
        <InfoRow>
          <Description>{props.resources.teamAwardedPercentage}</Description>
          <Value>
            {props.teamAwardedValue !== undefined &&
              props.teamAwardedValue !== null &&
              `${props.teamAwardedValue.toFixed(0)}%`}
          </Value>
        </InfoRow>
        <InfoRow>
          <Description>{props.resources.totalPendingLabel}</Description>
          <Value>{props.pendingApprovalCount}</Value>
        </InfoRow>
      </Information>
      <Buttons>
        <ButtonColumn>
          <ButtonLink to={props.resources.tutorialUrl} target={props.resources.tutorialTarget}>
            <DashboardIcon>
              <Movie />
            </DashboardIcon>
            <ButtonText>{props.resources.tutorialLabel}</ButtonText>
          </ButtonLink>
        </ButtonColumn>
        <ButtonColumn>
          <ButtonLink to={props.resources.policyUrl} target={props.resources.policyTarget}>
            <DashboardIcon>
              <Doc />
            </DashboardIcon>
            <ButtonText>{props.resources.policyLabel}</ButtonText>
          </ButtonLink>
        </ButtonColumn>
        <ButtonColumn>
          <ButtonLink to={props.resources.helpUrl}>
            <DashboardIcon>
              <Support />
            </DashboardIcon>
            <ButtonText>{props.resources.helpLabel}</ButtonText>
          </ButtonLink>
        </ButtonColumn>
      </Buttons>
    </Dashboard>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pendingApprovalCount: getPendingApprovalCount(state),
    teamPercentage: getEmployeeReceivedPercentage(state),
    teamAwardedValue: getYourTeamPercentage(state),
    totalAwardedPoints: getTotalAwardedTeamPoints(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      getData: analyticsApiActions.getData
    },
    dispatch
  )
});

const mapResourceToProps = (getResource) => ({
  resources: {
    title: getResource('managerDashboard.title', 'Manager’s Snapshot'),
    detailsLink: getResource('managerDashboard.detailsLink', 'See more details'),
    detailsLinkUrl: getResource('managerDashboard.detailsLinkUrl', '/reports/reports-overview'),
    teamTotalAwarded: getResource(
      'managerDashboard.teamTotalAwarded',
      'Total amount of Recognition points awarded by you and your team YTD:'
    ),
    teamReceivedPercentage: getResource(
      'managerDashboard.teamReceivedPercentage',
      'Percentage of employees in your team that has received Recognition points YTD:'
    ),
    teamAwardedPercentage: getResource(
      'managerDashboard.teamAwardedPercentage',
      'Percentage of employees in your team that has awarded Recognition points YTD:'
    ),
    totalPendingLabel: getResource(
      'managerDashboard.totalPendingLabel',
      'Total number of nominations currently pending your approval:'
    ),
    tutorialLabel: getResource('managerDashboard.tutorialLabel', 'Manager Tutorial'),
    tutorialUrl: getResource('managersDashboard.tutorialUrl', '/clientassets/100047/docs/manager-system-guide.pdf'),
    tutorialTarget: getResource('managersDashboard.tutorialTarget', '_blank'),

    policyLabel: getResource('managerDashboard.policyLabel', 'Manager Policy'),
    policyUrl: getResource('managerDashboard.policyUrl', '/clientassets/100047/docs/information-for-managers.pdf'),
    policyTarget: getResource('managerDashboard.policyTarget', '_blank'),

    helpLabel: getResource('managerDashboard.helpLabel', 'Need Help?'),
    helpUrl: getResource('managersDashboard.helpUrl', '/help/contact-us')
  }
});

export default register('rsv8-data-vis/ManagerDashboard')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(ManagerDashboard);
