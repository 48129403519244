import * as React from 'react';
import styled from 'styled-components';
import GridFile from './GridFile';

export interface GridFiles {
  assetFile: any;
  onImageClick: any;
}

const GridWrapper = styled.div`
  display: flex;
`;

const GridAssembler: React.SFC<GridFiles> = (props) => {
  const {
    onImageClick,
    assetFile: { assets }
  } = props;

  if (assets.length > 0) {
    return (
      <GridWrapper>
        {assets
          .sort((a, b) => {
            if (a.createdDateTimeUtc < b.createdDateTimeUtc) {
              return 1;
            }
            if (a.createdDateTimeUtc > b.createdDateTimeUtc) {
              return -1;
            }
            return 0;
          })
          .map((file, i) => (
            <GridFile key={file + i} onImageClick={onImageClick} file={file} />
          ))}
      </GridWrapper>
    );
  }
  return null;
};

export default GridAssembler;
