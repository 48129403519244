import * as React from 'react';
import { ActivityAction } from '../../../../api/delete-user-action';
import { LocalShareableFeedActivity } from '../../../../api/get-shareables-activities';
import { saveFile } from '../../../../utils';
import SocialShareableButton from '../../../SocialShareableButton';
import {
  BlockLink,
  ProductImage,
  ShareableButtonContainer,
  SlideItem
  } from './style';

export const shareableButtonIsVisible = (activity: LocalShareableFeedActivity) =>
  !!activity.actions.find((act) => act.id === ActivityAction.Share) && !!activity.imgSrc;

export interface SocialShareableActivityProps {
  activity: LocalShareableFeedActivity;
  trackActivityAction: Function;
}
export class SocialShareableActivity extends React.PureComponent<SocialShareableActivityProps> {
  render() {
    const { activity, trackActivityAction } = this.props;
    const shareAction = activity.actions.find((act) => act.id === ActivityAction.Share);
    const saveAction = activity.actions.find((act) => act.id === ActivityAction.Save);
    return (
      <SlideItem>
        {this.renderImage()}
        {!!shareableButtonIsVisible(activity) && (
          <ShareableButtonContainer>
            <SocialShareableButton
              shareURL={activity.imgSrc}
              saveAction={{
                defaultIcon: (saveAction && saveAction.iconDefault) || '',
                hoverIcon: (saveAction && saveAction.iconHover) || '',
                action: () => {
                  trackActivityAction(activity.id, ActivityAction.Save);
                  saveFile(activity.imgSrc);
                },
                label: saveAction && saveAction.label
              }}
              shareAction={{
                defaultIcon: (shareAction && shareAction.iconDefault) || '',
                hoverIcon: (shareAction && shareAction.iconHover) || '',
                action: () => trackActivityAction(activity.id, ActivityAction.Share),
                label: shareAction && shareAction.label
              }}
            />
          </ShareableButtonContainer>
        )}
      </SlideItem>
    );
  }

  renderImage() {
    const { activity } = this.props;

    const Image = <ProductImage className="slide-image" src={activity.imgSrc} alt={activity.title} />;

    if (activity.href) {
      return (
        <BlockLink className="slide-image-link" href={activity.href} style={{ display: 'block' }}>
          {Image}
        </BlockLink>
      );
    }

    return Image;
  }
}
