import {
  Dictionary,
  ThemeLibrary,
  ThemeStyle,
  ThemeStyleDeclaration
  } from '../types';
import { customStyles, getStyle } from './themeDefinitions';
const stylePropertySort = (a, b) => {
  if (a.indexOf('media') !== -1 || b.indexOf('media') !== -1) {
    if (a.indexOf('media') !== -1 && b.indexOf('media') !== -1) {
      const aInt = parseInt(a.replace(/[^\d]+(\d+).+/, '$1'), 10);
      const bInt = parseInt(b.replace(/[^\d]+(\d+).+/, '$1'), 10);
      if (aInt > bInt) return 1;
      else if (bInt > aInt) return -1;
    }
    return 1;
  } else {
    if (a > b) return 1;
    else if (b > a) return -1;
  }
  return 0;
};
const processStyle = (library: ThemeLibrary, styles: Dictionary<ThemeStyle> | ThemeStyle) => {
  let { colors, fonts } = library;
  let css = Object.keys(styles)
    .sort(stylePropertySort)
    .filter((key) => styles[key] !== undefined && !customStyles.isProperty(key))
    .map((key) => {
      let value = styles[key];
      
      if (
        /[\.&: ]/.test(key) ||
        ['strong', 'input', 'span', 'button', 'select'].indexOf(key) !== -1 ||
        (['top', 'all'].indexOf(key) === -1 && key.length <= 3)
      ) {
        return `${key}{${processStyle(library, styles[key])}}`;
      }

      return value === null || value.toString() === '' ? undefined : getStyle(`${key}:${value}`);
    })
    .filter((key) => key !== undefined)

    .join(' ');

  css += `${customStyles.toCSS(styles, colors, fonts)}`; /*?*/
  return css;
};
const processData = (
  library: ThemeLibrary,
  data: Dictionary<ThemeStyle> | ThemeStyle | ThemeStyleDeclaration,
  depth = 0
) => {
  if (!data || !library || Object.keys(data).length === 0 || depth > 100) {
    return data;
  }
  Object.keys(data).forEach((key) => {
    if (key !== 'styles') {
      data[key] = processData(library, { ...data[key] }, depth + 1);
    } else {
      data.styles = Object.keys(data.styles).reduce((styles, styleName) => {
        styles[styleName] = processStyle(library, { ...data.styles[styleName] });
        return styles;
      }, {});
    }
  });
  return data;
};

export default processData;
