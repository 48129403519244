import { FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

const StyledFormGroup = styled(FormGroup)`
  input[type='password']::-ms-reveal,
  input[type='password']::-ms-clear {
    display: none;
  }
` as any;

export { StyledFormGroup };
