import * as React from 'react';
import { Icon } from '../Icon';

export type SORT_DIRECTION_VALUES = 'Asc' | 'Desc' | 'unsorted';
export const SORT_DIRECTIONS = {
  asc: 'Asc',
  desc: 'Desc',
  unsorted: 'unsorted'
};
const getSortIcon = (direction) => {
  switch (direction) {
    case 'Asc':
      return 'sort-up';
    case 'Desc':
      return 'sort-down';
    default:
      return 'sort';
  }
};

export const SortIcon = ({ direction, ...rest }) => <Icon {...rest} icon={getSortIcon(direction)} />;
export default SortIcon;
