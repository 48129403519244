﻿import * as React from 'react';
import { isNil } from 'lodash';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';

interface Props {
  content: {
    pt?: number;
    value?: number;
  };
  resource: {
    ptCaption: string;
  };
  className?: string;
}

const GrowthMarketShareRebate: React.FC<Props> = ({ content: { pt, value }, resource: { ptCaption }, className }) => {
  if (isNil(pt) || isNil(value)) {
    return null;
  }

  return (
    <div className={className}>
      <div className="growth-market-share-rebate-pt">
        +{pt} {ptCaption}
      </div>
      <div className="growth-market-share-rebate-value">{value}%</div>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  content: {
    pt: getContent('pt', { type: 'number', label: 'Pt' }),
    value: getContent('value', { type: 'number', label: 'Value' }),
  },
});

const mapResourceToProps = (getResource) => ({
  resource: {
    ptCaption: getResource('alcon.growthMarketShareRebate.ptCaption', 'pt'),
  },
});

export default register('alcon-data-vis/GrowthMarketShareRebate')(
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(GrowthMarketShareRebate);
