import styled from 'styled-components';

export const PrimaryNavStyledWrap = styled.div`
  .navbar {
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 0;
    min-height: auto;
    border: 0;
    padding: 0;

    .container {
      width: auto;
    }
    .nav {
      float: right;
      @media screen and (max-width: 767px) {
        white-space: normal;
      }

      .userImageIcon {
        border-radius: 50%;
      }
      .notificationCenter {
        .dropdown-toggle {
          .caret {
            display: none;
          }
        }
        .dropdown-menu {
          top: 57px !important;
        }
      }

      li {
        margin: 0;
        float: none;
        display: inline-block;

        @media screen and (max-width: 767px) {
          padding: 0 25px !important;
        }
        &:nth-last-child(1) {
          margin-right: 0;
        }

        a {
          font-size: 14px;
          padding: 0 15px;
          background-color: transparent !important;
          img {
            height: 25px;
            width: 25px;
            max-height: 25px;
            min-height: 25px;
            margin: 0 3px;
          }
          @media screen and (max-width: 767px) {
            padding: 18px 4px 18px 50px;
            background-color: transparent !important;
          }
        }

        .dropdown-menu {
          -webkit-box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          -moz-box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);
          border: 0;
          border-radius: 0;
          right: 0;
          left: auto;
          padding: 0;
          li {
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: 33px 18px;
            border-bottom: 0;
            margin: 0;

            &:first-child {
              padding-top: 0;
            }
            &:last-child {
              padding-bottom: 0;
            }
            display: block;
            @media screen and (min-width: 767px) {
              background-image: inherit !important;
            }
            a {
              padding: 10px 25px;
            }
          }
        }
      }
    }
  }
` as any;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  h1 {
    margin: 0 !important;
  }
  .form-group {
    margin-bottom: 0 !important;
  }
  @media screen and (min-width: 768px) {
    padding: 20px 0;
  }
` as any;

export const NotificationsContainer = styled.div`
  width: 288px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-track {
    border-radius: 3.5px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
` as any;

export const NotificationsHeader = styled.div`
  font-family: 'open-sans', sans-serif !important;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  padding: 10px;
  letter-spacing: normal;
  text-align: center;
  color: #373a36;
` as any;

export const NotificationDate = styled.div`
  width: 48px;
  font-family: 'open-sans', sans-serif !important;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: normal;
  color: #373a36;
` as any;

export const NotificationMessage = styled.div`
  width: 258px;
  font-family: 'open-sans', sans-serif !important;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: normal;
  color: #373a36;
` as any;

export const HorizontalRule = styled.div`
  width: 288px;
  height: 1px;
  border: solid 1px #e9e9e9;
` as any;

export const NotificationItem = styled.div`
  padding: 10px 15px;
  width: 287px;
` as any;

export const NotificationCount = styled.div`
  position: absolute;
  z-index: 99;
  top: -2px;
  left: 32px;
  background-color: #00aeef;
  width: 16px;
  height: 15px;
  border-radius: 50%;
  span {
    font-family: OpenSans;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: table;
    margin: 0 auto;
    padding-top: 2px;
  }
` as any;
