// import * as React from 'react';
import { connect } from 'react-redux';
import PublishButtonComponent from './PublishButtonComponent';
import { contentTypeSelector, contentVersionSelector } from '../../redux';
import contentVersionActions from '../../redux/actions/contentVersion';

const mapStateToProps = state => ({
  version: contentVersionSelector.getActiveVersion(
    state,
    contentTypeSelector.getActive(state)
  ),
  canPublishVersion: contentVersionSelector.canPublishVersion(
    state,
    contentTypeSelector.getActive(state)
  )
});

const mapDispatch = dispatch => ({
  onClick: () => dispatch((_, getState) => {
    const contentTypeId = contentTypeSelector.getActive(getState());
    dispatch(contentVersionActions.publish(contentTypeId));
  })
});

export default connect(mapStateToProps, mapDispatch)(PublishButtonComponent) as any;
