import * as React from 'react';
import { connect } from 'react-redux';
import { AddBehavior } from 'rsv8-components';
import { register, withContent } from 'xcel-react-core';
import { getAuth } from '../../app/redux/selectors';

const AuthLinkBehavior = ({ dispatch, onClick, auth, ...props }) => {
  const handleClick = async (e) => {
    e.preventDefault();

    let { accessToken } = auth;

    try {
      const dynamicLink = document.createElement('a');
      const linkValue = e.currentTarget.attributes.href && e.currentTarget.attributes.href.value.trim();

      if (linkValue) {
        dynamicLink.href = linkValue + accessToken.trim();
        dynamicLink.target = '_blank';
        dynamicLink.click();
      }
    } catch (e) {
      console.log('Failed to set the conset.', e);
    }
    // fire parent onClick

    if (onClick) {
      onClick(e);
    }
  };

  return <AddBehavior {...props} onClick={handleClick} />;
};

const mapStateToProps = (state) => ({ auth: getAuth(state) });

export default register('rsv8-auth/AuthLinkBehavior')(connect(mapStateToProps), withContent())(AuthLinkBehavior);
