import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { clientAdminSelector } from 'rsv8-client';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { ErrorBoundary, registry } from 'xcel-react-core';
import { withErrorBoundary } from 'xcel-react-core';

const ScrollingContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding-top: 20px;
  overflow-x: hidden;
  text-align: center;
`;
const ComponentDrag = styled.span`
  display: inline-block;
  position: relative;
  width: calc(100% - 10px);
  height: 150px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  overflow: hidden;
` as any;
const ComponentContainer = styled.div``;
const ComponentPreview = styled.div`
  vertical-align: text-top;
  text-align: center;
  div {
    padding: 0;
    zoom: 0.85;
  }
`;
const ComponentTitle = styled.div`
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background-color: white;
  height: 20px;
`;

class ComponentAdd extends React.Component<any> {
  state = { search: '' };
  handleChange = (value) => {
    this.setState({ search: value });
  };
  onDragStart = (event) => {
    event.persist();
    event.dataTransfer.setData('draggingElementTitle', event.target.title);
  };
  render() {
    const registryData = registry.get();
    const { clientId } = this.props;
    const clientFilter = (key) => clientId === 100102 || key.indexOf('randf') === -1;
    return (
      <div>
        <h4>Add Component</h4>
        <ReactField component="bs-input" label="Search" placeholder="Search" onChange={this.handleChange} />
        <ScrollingContainer>
          <Row>
            {Object.keys(registryData.components)
              .filter(
                (key) => registryData.content && registryData.content[key] && registryData.content[key].withContent
              )
              .filter(
                (key) =>
                  this.state.search.trim() === '' || key.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
              )
              .filter(clientFilter)
              .sort()
              .map((key, index) => {
                const name = key.replace(/.+\//, '');
                // const Component = key.indexOf('randf') === -1 ? registryData.components[key] : name;
                return (
                  <Col key={key} md={12} sm={8}>
                    <ComponentTitle>{name}</ComponentTitle>
                    <ComponentDrag
                      name={name}
                      namespace={key}
                      title={key}
                      canDrop={false}
                      draggable="true"
                      onDragStart={this.onDragStart}
                    >
                      <ComponentContainer>
                        <ComponentPreview>
                          <ErrorBoundary name={key}>
                            No Preview Available
                            <br />
                            {name}
                            {/* <Component /> */}
                          </ErrorBoundary>
                        </ComponentPreview>
                      </ComponentContainer>
                    </ComponentDrag>
                  </Col>
                );
              })}
          </Row>
        </ScrollingContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clientId: clientAdminSelector.getActive(state).id
});
export default connect(mapStateToProps)(withErrorBoundary()(ComponentAdd));
