import { getReduxStore } from 'xcel-redux-module';
import { authStateSelectors } from '../module';

const authMiddleware = {
  request: ({ request }) => ({ reject, next, resolve }) => {
    if (request.url.indexOf('auth') === -1) {
      const store = getReduxStore();
      if (store) {
        const state = store.getState();
        const auth = authStateSelectors.getAuth(state);

        if (auth && auth.accessToken) {
          request.headers = {
            ...request.headers,
            authorization: 'bearer ' + auth.accessToken
          };
        }
      }
      next();
    } else {
      next();
    }
  }
};

export default authMiddleware;
