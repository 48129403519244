import { mapJsonApiMethodAction } from 'xcel-redux-orm';
import { nominationApi } from '../../../api/index';
import { nominationActions } from '../nomination';

const applyJsonApi = <Fn extends (params: any, options: any) => any>(method: Fn) => {
  const newFn = (params, options) => method(params, { jsonApi: true, ...options });
  return newFn as Fn;
};

export const getIndividualDraftDetails = nominationActions.getNominationsDraftById;

export const deleteNomination = nominationActions.deleteNominationsById;

export const getTeamDraftDetails = async ({ id }) => {
  try {
    const response = await nominationApi.getTeamDraftById({ id }, { jsonApi: false });
    return response;
  } catch (e) {
    throw e;
  }
};
export const deleteTeamDraft = nominationActions.deleteTeamDraftById;

export const getNomineeById = mapJsonApiMethodAction({
  method: applyJsonApi(nominationApi.getNomineeById),
  endpoint: 'getNomineeById',
  options: { allowExecuteMultiple: true }
});
