import * as React from 'react';
import { ControlLabel } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

const StyledLabel = styled(ControlLabel)`
  font-weight: normal;
` as any;
const Label = ({ children, ...props }) => (
  <StyledLabel {...props}>
    <Text variationName="field-label">{children}</Text>
  </StyledLabel>
);

export default register('rsv8-forms/Label')(withTheme())(Label);
