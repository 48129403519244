import * as React from 'react'

interface StatusRendererProps {
  value: string,
  colDef: any,
}

const Status = (props: StatusRendererProps) => {
  const value = props.value ? props.value.trim() : ''
  const color = props.colDef.statuses[ value ] || 'inherit'
  return (
    <div data-status-value={value} style={{ color }}>
      {value}
    </div>
  )
}

export default Status
