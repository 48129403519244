import * as React from 'react';
import { compose } from 'recompose';
import { Button } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import SubmitMessages from './SubmitMessages';
import { ApprovalFormInternalProps, ApprovalFormProps, connectApprovalForm } from './util';

class SendBackForm extends React.Component<ApprovalFormInternalProps, {}> {
  state = { displayForm: true as boolean };
  removeForm = () => {
    this.setState({ displayForm: false });
    this.props.changeVisibility();
  };
  submit = (values) => {
    return this.props
      .approveNomination({
        model: {
          id: this.props.processId,
          action: 'SendBack',
          scheduledDate: undefined,
          sendBackComments: values.sendBackComments,
          customFields: undefined
        }
      })
      .then(() => this.removeForm());
  };
  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <SubmitMessages
          themeVariation="success"
          messages={this.props.resources.sendback}
          request={this.props.lastRequest}
          {...this.props}
        />
        {this.state.displayForm && (
          <React.Fragment>
            <Field name="sendBackComments" component="bs-textarea" validate="required" />
            <Button type="submit">{this.props.buttons.sendback}</Button>
          </React.Fragment>
        )}
      </form>
    );
  }
}

export default compose<ApprovalFormInternalProps, ApprovalFormProps>(
  connectApprovalForm,
  reduxForm({ form: 'SendBackForm' })
)(SendBackForm);
