import * as moment from 'moment';
import * as React from 'react';
import { withResource } from 'xcel-react-core';
import {
  ListPrice,
  PointsNeeded,
  PointValue,
  Promo,
  PromoDate
  } from './styles';

export interface ProductPromoProps {
  pointValueLabel: string;
  product: any;
  promoEndsLabel: string;
  promoPointsNeededPostLabel: string;
  promoPointsNeededPreLabel: string;
  promoWasPostLabel: string;
  promoWasPreLabel: string;
  physicalPointsNeededLabel: string;
  currentPoints: number;
  currentTaxable: number;
  productGroupsProduct?: any;
  hasRestrictedPoints: boolean;
}

class ProductPromo extends React.Component<ProductPromoProps, any> {
  state = {
    productGroupsProduct: {}
  };

  digitalPointCheck = () => {
    const {
      productGroupsProduct,
      promoPointsNeededPreLabel,
      promoPointsNeededPostLabel,
      product,
      currentTaxable
    } = this.props;

    if (
      productGroupsProduct && productGroupsProduct.pointValue
        ? currentTaxable < productGroupsProduct.pointValue
        : productGroupsProduct !== undefined && currentTaxable < product.pointValue
    ) {
      let points = 0;
      if (productGroupsProduct && productGroupsProduct.pointValue) {
        points = productGroupsProduct.pointValue - currentTaxable;
      } else {
        points = product.pointValue - currentTaxable;
      }
      return (
        <PointsNeeded themeVariation="secondary-text-light">{`${promoPointsNeededPreLabel} ${points.toLocaleString()} ${promoPointsNeededPostLabel}`}</PointsNeeded>
      );
    } else {
      return null;
    }
  };

  physicalPointCheck = () => {
    if (this.props.currentPoints < this.props.product.pointValue) {
      return (
        <PointsNeeded themeVariation="secondary-text-light">{`${this.props.promoPointsNeededPreLabel} ${(
          this.props.product.pointValue - this.props.currentPoints
        ).toLocaleString()} ${this.props.physicalPointsNeededLabel}`}</PointsNeeded>
      );
    } else {
      return null;
    }
  };
  render() {
    const {
      pointValueLabel,
      product,
      promoEndsLabel,
      promoWasPostLabel,
      promoWasPreLabel,
      productGroupsProduct,
      hasRestrictedPoints,
    } = this.props;

    return (
      <Promo>
        {productGroupsProduct !== undefined &&
          productGroupsProduct.pointValue && (
            <PointValue themeVariation="alternate-jumbo-light-text">{`${parseFloat(
              productGroupsProduct.pointValue
            ).toLocaleString()} ${pointValueLabel}`}</PointValue>
          )}
        {product.groupedProducts &&
          product.groupedProducts.length > 0 &&
          (!productGroupsProduct || !productGroupsProduct.pointValue) && (
            <PointValue themeVariation="alternate-jumbo-light-text">
              {`${product.productGroupMinPoints} - ${product.productGroupMaxPoints} ${pointValueLabel} `}
            </PointValue>
          )}
        {product.groupedProducts &&
          product.groupedProducts.length === 0 && (
            <PointValue themeVariation="alternate-jumbo-light-text">{`${parseFloat(
              product.pointValue
            ).toLocaleString()} ${pointValueLabel}`}</PointValue>
          )}

        {hasRestrictedPoints && (product.isDigitalCard || product.isGyftProduct || product.nonTangible)
          ? this.digitalPointCheck()
          : this.physicalPointCheck()}
        {product.listPrice > 0 &&
          product.promotionEndDate && (
            <React.Fragment>
              <ListPrice>{`${promoWasPreLabel} ${parseFloat(
                product.listPrice
              ).toLocaleString()} ${promoWasPostLabel}`}</ListPrice>
              <PromoDate>{`${promoEndsLabel} ${moment(product.promotionEndDate).format('MM/DD/YY')})`}</PromoDate>
            </React.Fragment>
          )}
      </Promo>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  pointValueLabel: getResource('catalog.productDetail.pointValueLabel', 'pts.'),
  promoPointsNeededPreLabel: getResource('catalog.productDetail.promoPointsNeededPreLabel', 'You need'),
  promoPointsNeededPostLabel: getResource('catalog.productDetail.promoPointsNeededPostLabel', 'non-restrictive points'),
  physicalPointsNeededLabel: getResource('catalog.productDetail.physicalPointsNeededLabel', 'points'),
  promoWasPreLabel: getResource('catalog.productDetail.promoWasPreLabel', 'Was'),
  promoWasPostLabel: getResource('catalog.productDetail.promoWasPostLabel', 'pts.'),
  promoEndsLabel: getResource('catalog.productDetail.promoEndsLabel', '(Offer ends')
});

export default withResource(mapResourceToProps)(ProductPromo);
