import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { accountStyles as styles } from 'rsv8-components';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getRedeemHistory } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import * as utils from '../../../utils';
import DesktopTable from './DesktopTable';
import RedemptionHistoryStatusModal from './RedemptionHistoryStatusModal';

interface RedemptionProps {
  actions: any;
  dataTableTitle: string;
  redemptionData: any;
}

enum SortOrdering {
  ASC_DIR = 'Asc',
  DESC_DIR = 'Desc',
  UNSORT = 'unsorted'
}

const MobileDropDownField = styled(ReactField)`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: solid 1px #b2b2b2;
  text-align: center;
  align-content: center;
  align-self: center;
  vertical-align: middle;
  font-family: open-sans;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #505d6f;
` as any;

const tabletSize: number = 1024;
const mobileSize: number = 511;

const NoDataAvailableText: string = "Rebates redeemed from the Rebate Options page will appear here";

class RedemptionHistory extends React.Component<RedemptionProps> {

  state = {
    tableData: [],
    windowSize: window.innerWidth,
    showStatusModal: false,
    windowScrollY: -1,
    rebateOption: null,
    option: 0
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.setState({ tableData: this.props.redemptionData });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.redemptionData !== this.props.redemptionData) {
      this.setState({ tableData: this.props.redemptionData });
    }
  }

  getCurrencyValue(value: any) {
    const showValue = value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
    return utils.getDecimalValue(showValue);
  };

  getDateValue(value: any) {
    if (value !== undefined) {
      const date = new Date(value);
      return date.toLocaleDateString("en-US");
    } else {
      return value;
    }
  };

  removeSpaceToLowerCase(value: string) {
    if (value !== undefined && value !== null) {
      return value.replace(" ", "").toLocaleLowerCase();
    } else {
      return value;
    }
  };

  getRedemptionHistoryDisplayStatus(status: any) {
    switch (status.toLocaleLowerCase()) {
      case "delivered":
        return "Delivered";
      case "cashed":
        return "Cashed";
      case "mailed":
        return "Mailed";
      case "applied":
        return "Applied";
      case "shipped":
        return "Shipped";
      case "in_process":
        return "In Process";
      case "complete":
        return "Complete";
      default:
        return status;
    }
  }

  renderTabletTable(props: any, headerNames: any, redeemData: any, setShowStatusModal: Function) {
    if (headerNames.length > 0) {

      return (
        <styles.TabletDataTable>
          <styles.TabletTableHeader>
            {headerNames.map((headerValue, index) => (
              <styles.TabletTableHeaderCell>
                <styles.TabletTableHeaderText>
                  {headerValue}
                </styles.TabletTableHeaderText>
              </styles.TabletTableHeaderCell>
            ))}
          </styles.TabletTableHeader>
          { (redeemData && redeemData.length > 0) ? redeemData.map((redeemObject) => (
            <styles.TabletTableRow>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {redeemObject.redeemOption}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {this.getDateValue(redeemObject.date)}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {redeemObject.quantity}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {this.getCurrencyValue(redeemObject.faceValue)}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {this.getCurrencyValue(redeemObject.amount)}
                </styles.TabletTableText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableLinkText onClick={(e) => setShowStatusModal(redeemObject.redeemOptionKey)}>
                  {this.getRedemptionHistoryDisplayStatus(redeemObject.status)}
                </styles.TabletTableLinkText>
              </styles.TabletTableCell>
              <styles.TabletTableCell>
                <styles.TabletTableText>
                  {redeemObject.id}
                </styles.TabletTableText>
              </styles.TabletTableCell>
            </styles.TabletTableRow>
          )) :
            <styles.TabletTableRow>
              <styles.DesktopNoDataTableCell colSpan={'7'}>
                <styles.NoDataContainer>
                  {NoDataAvailableText}
                </styles.NoDataContainer>
              </styles.DesktopNoDataTableCell>
            </styles.TabletTableRow>
          }
        </styles.TabletDataTable>
      );
    } else {
      return null;
    }
  }

  renderMobileTable(
    props: any,
    headerNames: any,
    redeemData: any,
    selectedOption: any,
    setShowStatusModal: Function
  ) {
    let selectedData: any;
    if (redeemData && redeemData.length > 0 && selectedOption !== undefined) {
      selectedData = redeemData.filter(
        item => this.removeSpaceToLowerCase(item.redeemOptionKey) === selectedOption
      );

      if (selectedData !== undefined && selectedOption.length > 0) {
        return (
          <div>
            {selectedData.map((data, index) => (
              <styles.MobileDataTable {...props}>
                {/*create header */}
                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[1]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell
                    style={{
                      width: "52px",
                      paddingTop: "0px",
                      borderTop: "0px solid #b2b2b2"
                    }}
                  >
                    <styles.DataTableText>
                      {this.getDateValue(data.date)}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[2]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>{data.quantity}</styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[3]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>
                      {this.getCurrencyValue(data.faceValue)}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[4]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableText>
                      {this.getCurrencyValue(data.amount)}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[5]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell>
                    <styles.DataTableLinkText onClick={(e) => setShowStatusModal(data.redeemOptionKey)}>
                      {this.getRedemptionHistoryDisplayStatus(data.status)}
                    </styles.DataTableLinkText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>

                <styles.MobileDataTableHeaderRow>
                  <styles.MobileDataTableHeaderCell>
                    <styles.MobileDataTableHeaderText>
                      {headerNames[6]}
                    </styles.MobileDataTableHeaderText>
                  </styles.MobileDataTableHeaderCell>

                  <styles.MobileDataTableCell
                    style={{
                      width: "52px",
                      paddingTop: "0px",
                      borderTop: "0px solid #b2b2b2"
                    }}
                  >
                    <styles.DataTableText>
                      {data.id}
                    </styles.DataTableText>
                  </styles.MobileDataTableCell>
                </styles.MobileDataTableHeaderRow>
              </styles.MobileDataTable>
            ))}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  handleOnSort = ({ direction, field }) => {
    if (direction === SortOrdering.UNSORT) {
      return;
    }

    const { tableData } = this.state;
    tableData.sort((a, b) => {
      const value1 = a[field];
      const value2 = b[field];

      switch (direction) {
        case SortOrdering.ASC_DIR:
          if (typeof (value1) === 'string') {
            return value1.toLowerCase() < value2.toLowerCase() ? 1 : -1;
          } else {
            return value1 < value2 ? 1 : -1;
          }
        case SortOrdering.DESC_DIR:
          if (typeof (value1) === 'string') {
            return value1.toLowerCase() > value2.toLowerCase() ? 1 : -1;
          } else {
            return value1 > value2 ? 1 : -1;
          }
        default: return 0;
      }
    });

    this.setState({ tableData: tableData });

  }

  onResize = () => {
    this.setState({ windowSize: window.innerWidth });
  };

  showStatusDialog = (rebateOption) => {
    this.setState({ rebateOption, windowScrollY: window.scrollY, showStatusModal: true })
  }

  hideStatusDialog = () => {
    this.setState({ showStatusModal: false })
    setTimeout(() => window.scrollTo(0, this.state.windowScrollY), 50);
  }

  render() {
    const { windowSize, showStatusModal, rebateOption, option, tableData } = this.state;
    const { dataTableTitle } = this.props;
    const headerNames: any = [
      "Rebate Option",
      "Date",
      "Quantity",
      "Value",
      "Total Redemption Amount",
      "Status",
      "Confirmation"
    ];

    if (windowSize > tabletSize) {
      return (
        <div {...this.props}>
          <styles.DesktopContainer>
            <styles.DesktopContainerTitle>
              {dataTableTitle}
            </styles.DesktopContainerTitle>
            <DesktopTable data={tableData} setShowStatusModal={this.showStatusDialog} onSort={this.handleOnSort} />
          </styles.DesktopContainer>
          {showStatusModal && <RedemptionHistoryStatusModal show={showStatusModal} handleHide={this.hideStatusDialog} rebateOption={rebateOption} />}
        </div>
      );
    } else if (windowSize > mobileSize) {
      return (
        <div {...this.props}>
          <styles.TabletContainer>
            <styles.TabletContainerTitle>
              {dataTableTitle}
            </styles.TabletContainerTitle>
            {this.renderTabletTable(this.props, headerNames, tableData, this.showStatusDialog)}
          </styles.TabletContainer>
          {showStatusModal && <RedemptionHistoryStatusModal show={showStatusModal} handleHide={this.hideStatusDialog} rebateOption={rebateOption} />}
        </div>
      );
    } else {
      let dropdownOptions: any = _.union([{ label: 'Select Rebate Option', value: '' }], _.uniqWith(tableData.map(data => {
        return {
          label: data.redeemOption,
          value: this.removeSpaceToLowerCase(data.redeemOptionKey)
        };
      }), _.isEqual));

      return (
        <div {...this.props}>
          <styles.MobileContainer>
            <styles.MobileDataTableTitle>
              {dataTableTitle}
            </styles.MobileDataTableTitle>
            <styles.MobileDropDownContainer>
              {tableData && tableData !== undefined && tableData.length > 0 ? (
                <MobileDropDownField
                  component="bs-select"
                  options={dropdownOptions}
                  onChange={value => this.setState({ option: value })}
                />
              ) : (
                  <styles.NoDataContainer>
                    {NoDataAvailableText}
                  </styles.NoDataContainer>
                )}

            </styles.MobileDropDownContainer>
            {this.renderMobileTable(
              this.props,
              headerNames,
              tableData,
              option,
              this.showStatusDialog
            )}
          </styles.MobileContainer>
          {showStatusModal && <RedemptionHistoryStatusModal show={showStatusModal} handleHide={this.hideStatusDialog} rebateOption={rebateOption} />}
        </div>
      );
    }
  }
};

const mapStateToProps = state => ({
  redemptionData: selectors.redemptionHistorySelector.selectMany(state)
});

const mapContentToProps = getContent => ({
  dataTableTitle: getContent("dataTableTitle", {
    type: "string",
    schema: [{ model: "Data Table Title", label: "Data Table Title:" }]
  })
});

export default register("alcon-data-vis/RedemptionHistory")(
  connect(mapStateToProps),
  withApiDataProvider(getRedeemHistory, selectors.redemptionHistorySelector),
  withContent(mapContentToProps),
  withTheme()
)(RedemptionHistory);

export { RedemptionHistory };
