import * as React from 'react';

export const ProductDetail: React.FC<{
  label: string;
  unitLabel?: string;
  units?: string | number;
  value?: string | number;
}> = ({ label, unitLabel, units, value }) => (
  <div className="product__detail">
    <div className="product__detail-label">{label}</div>

    <div className="product__detail-values">
      {!!units && (
        <div className="product__detail-value">
          <div>UNITS</div>
          <div>{units}</div>
        </div>
      )}

      {!!unitLabel && !!value && (
        <div className="product__detail-value">
          <div>{unitLabel.toUpperCase()}</div>
          <div>{value}</div>
        </div>
      )}
    </div>
  </div>
);
