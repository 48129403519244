import * as React from 'react';
import { connect } from 'react-redux';
import { Amount, GoalDto } from 'xcel-api-generator/dist/alcon';
import { getCurrencyValue } from '../../../utils';
import { GoalContent } from '../../constants';
import * as selectors from '../../redux/selectors';
import { GoalAchieved } from './GoalAchieved';
import { GoalInProgress } from './GoalInProgress';
import * as styles from './style';

const SalesGoal: React.FC<Props> = ({
  chartWidgetSize,
  completedLeftSideColor,
  notcompletedLeftSideColor,
  subTitleText = 'needed',
  goalContent,
  goal,
  amount,
}) => {
  const myAmount = goal.isYTD ? amount.ytdSales : amount.sales || 0.0;
  const goalTarget = goal.targetSaleAmount;
  const isGoalAchived = myAmount >= goalTarget;
  const goalRemaining = getCurrencyValue(myAmount < goalTarget ? goalTarget - myAmount : 0, 0);

  return (
    <styles.GoalContainer>
      {isGoalAchived && <GoalAchieved color={completedLeftSideColor} chartWidgetSize={chartWidgetSize} isDisplayBar={true} />}

      {!isGoalAchived && (
        <GoalInProgress
          chartWidgetSize={chartWidgetSize}
          myAmount={myAmount}
          myGoalTarget={goalTarget}
          color={notcompletedLeftSideColor}
          goalRemaining={goalRemaining}
          subTitleText={subTitleText}
        />
      )}

      <styles.GoalsContainerRight color="transparent">
        {goalContent && <styles.GoalsRightTitle color={goalContent.goalTitleColor}>{goalContent.goalTitle}</styles.GoalsRightTitle>}

        <styles.GoalsRightSubtitle color="#505d6f">{goal.name}</styles.GoalsRightSubtitle>
        <styles.GoalsRightParagraph color="#505d6f">{goal.description}</styles.GoalsRightParagraph>
      </styles.GoalsContainerRight>
    </styles.GoalContainer>
  );
};

const mapStateToProps = (state) => ({
  amount: selectors.amountSelector.selectMany(state)[0],
});

interface StateProps {
  amount: Amount;
}
interface DispatchProps {}
interface OwnProps {
  chartWidgetSize: number;
  completedLeftSideColor: string;
  notcompletedLeftSideColor: string;
  subTitleText: string;
  goalContent?: GoalContent;
  goal: GoalDto;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(SalesGoal);
