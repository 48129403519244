import * as Icon from 'react-fontawesome';
import styled from 'styled-components';

export const BreadcrumbContainer = styled.div`
  margin-bottom: 15px;
` as any;

export const BreadcrumbIcon = styled(Icon)`
  color: #7c8180;
  margin: 0 8px;
` as any;

export const BreadcrumbLink = styled.a`
  color: #7c8180;

  &:active,
  &:focus,
  &:hover {
    color: #7c8180;
    outline: none !important;
    text-decoration: none;
  }
` as any;
