import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import LoadingAnimation from './LoadingAnimation';

const LoadingBase = styled.div`
  text-align: center;
  font-size: 24px;
  font-family: 'Helvetica';
`;

const LoadingBody = styled.div`
  padding: 200px 0;
`;
const Loading = ({ resource: { messageText } }) => {
  return (
    <LoadingBase>
      <LoadingBody>
        <LoadingAnimation />
        <h1>
          <strong>{messageText}</strong>
        </h1>
      </LoadingBody>
    </LoadingBase>
  );
};

const mapState = () => ({});

const mapDispatch = (dispatch) => ({});

const mapResourceToProps = (getResource) => ({
  resource: {
    messageText: getResource('loadingPage.messageText', 'Signing you on...')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(Loading) as any;
