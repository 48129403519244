import { cacheService } from 'xcel-cache-service';
import { reduxObserver } from 'xcel-observer';
import { cachePlugin, createReduxModule, ReduxModule } from 'xcel-redux-module';
import reducers from './app/redux';
import resourceActions from './app/redux/resource.actions';
const resourceModule: ReduxModule = cachePlugin(cacheService)(
  createReduxModule({
    name: 'rsv8-resource',
    init: resourceActions.init,
    reducers
  })
);
reduxObserver.addModule(resourceModule);

export default resourceModule;
