import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Content } from 'react-bootstrap/lib/Tab';
import styled from 'styled-components';

export const PopoverContent = styled(Content)`
  font-family: open-sans;
  color: #515550;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const InfoIcon = styled.div`
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 15px;
  text-align: center;
  vertical-align: middle;
  background-color: #00aeef;
  font-stretch: normal;
  font-style: normal;
  border-radius: 50%;
  border: 2px solid #00aeef;
  padding: 0;
  color: #ffffff;
  width: 15px;
  height: 15px;
  display: inline-block;
  object-fit: contain;
  font-family: open-sans;
  font-size: 8px;
  line-height: normal;
  letter-spacing: normal;

  &:before {
    content: 'i';
    color: white;
    font-size: 9px;
  }
`;

const overlayOption = (id, children) => (
  <Popover id={id}>
    <PopoverContent>{children}</PopoverContent>
  </Popover>
);

interface Props extends React.HTMLProps<any> {
  id?: string;
  placement?: string;
}

const OverlayPopover: React.FC<Props> = ({ id, placement = 'bottom', children }) => (
  <React.Fragment>
    {children && (
      <OverlayTrigger placement={placement} overlay={overlayOption(id, children)}>
        <InfoIcon className="overlay-popover-icon" />
      </OverlayTrigger>
    )}
  </React.Fragment>
);

export default OverlayPopover;
