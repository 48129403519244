import { getConfigurationProperty } from 'xcel-config';
const gigyaAsyncMethod = (fn) => (config = {} as any) => {
  const callback = config.callback;
  return new Promise((resolve, reject) => {
    config.callback = (response) => {
      const success = response.errorCode === 0;
      if (callback) callback(response);
      if (success) resolve(response);
      else reject(response);
    };
    fn(config);
  });
};
// helper method to interate through all api calls and execute async method.
const gigyaApiWrap = (obj) =>
  Object.keys(obj).reduce((dic, key) => {
    if (typeof obj[key] === 'function') {
      dic[key] = gigyaAsyncMethod(obj[key]);
    } else {
      dic[key] = obj[key];
    }
    return dic;
  }, {});
let injected;
const gigyaService = {
  config: {} as any,
  accounts: {} as any,
  inject: (config) => {
    if (injected) return injected;
    injected = new Promise<void>((resolve, reject) => {
      // setup the callback for gigya ready.
      (window as any).onGigyaServiceReady = () => {
        gigyaService.init();
        console.log('gigya service is ready');
        resolve();
      };
      let script = document.createElement('script');
      script.setAttribute('src', `https://cdns.gigya.com/JS/gigya.js?apiKey=${config.apiKey}`);
      document.head.appendChild(script);
      gigyaService.config = config;
    });
    return injected;
  },
  init: async () => {
    gigyaService.accounts = gigyaApiWrap((window as any).gigya.accounts);
  },
  isGigyaEnabled: () => {
    var isEnabled = getConfigurationProperty(['enableGigya']);
    if (!isEnabled) {
      return false;
    } else {
      return isEnabled;
    }
  },
  login: ({ username, password }) => {
    console.log('gigya attempting to login');
    return gigyaService.accounts.login({ loginID: username, password });
  },
  logout: () => {
    return gigyaService.accounts.logout();
  },
  getAccountInfo: () => {
    return gigyaService.accounts.getAccountInfo();
  }
};

export const inject = gigyaService.inject;
export const login = gigyaService.login;
export const getAccountInfo = gigyaService.getAccountInfo;
export const createRedirectUrl = (config, uid) => `${config.redirect}{"UID":"${uid}"}`;
export const isGigyaServiceAvailable = () => injected !== undefined;
export const logout = gigyaService.logout;
export const isGigyaEnabled = gigyaService.isGigyaEnabled;
export const getGigyaConfig = () => gigyaService.config;
