import * as React from 'react';
import { register, withTheme } from 'xcel-react-core';
import Image from '../../components/Image';
import OopsIcon from './Oops';
import { Container } from './styles';

export interface PlaceholderBannerProps extends React.HTMLAttributes<{}> {
  iconComponent?: React.ComponentType<{}>;
  text?: string;
  emptyIcon?: string;
}

const PlaceholderBanner: React.SFC<PlaceholderBannerProps> = ({
  iconComponent: Component = OopsIcon,
  className = '',
  text,
  children,
  emptyIcon,
  ...rest
}) => {
  return (
    <Container {...rest} className={`${className} placeholder-banner`}>
      <Image themeVariation={emptyIcon ? emptyIcon : 'icon-oops'} />
      {children ? children : text ? <span>{text}</span> : null}
    </Container>
  );
};

export default register('rsv8-components/PlaceholderBanner')(withTheme())(PlaceholderBanner) as React.ComponentType<
  PlaceholderBannerProps
>;
