import * as React from 'react';
import { ComponentType } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  Drawer,
  HeaderThree,
  InitialsImage,
  Link,
  Text
  } from 'rsv8-components';
import { DataTableColumn, DataTableDefault, SORT_DIRECTIONS } from 'rsv8-components';
import { ApiFilters } from 'rsv8-forms';
import styled from 'styled-components';
import { register, withContent, withResource } from 'xcel-react-core';
import { ApiDataProviderProps, withApiDataProvider } from 'xcel-redux-orm';
import { nominationActions } from '../../redux';
import { winnersSelectors } from '../../redux/selectors';
import WinnerDetailModal from './WinnerDetailModal';

const InitialsCellNameContainer = styled.div`
  display: inline-block;
  padding-left: 10px;
`;

const renderInitialsImage = ({ record }) => (
  <div>
    <div style={{ display: 'inline-block' }}>
      <InitialsImage firstName={record.firstName} lastName={record.lastName} width={`45px`} />
    </div>
    <InitialsCellNameContainer className={`hidden-sm hidden-md hidden-lg`}>
      <Text themeVariation={`secondary-text-bold`}>
        {record.firstName} {record.lastName}
      </Text>
    </InitialsCellNameContainer>
  </div>
);

const renderNameCell = ({ record, column }) => (
  <Text themeVariation={`secondary-text-bold`}>{record[column.field]}</Text>
);

const defaultSort = { field: 'firstName', direction: SORT_DIRECTIONS.asc };

// const fields = ['firstName', 'lastName', 'nominationType', 'criteria', 'teamName'];
class WinnersTable extends React.PureComponent<
  ApiDataProviderProps<ReturnType<typeof winnersSelectors.selectMany>> & ReturnType<typeof mapResource>,
  any
> {
  state = {
    showDetailModal: false,
    winnerDetail: undefined
  };

  showDetailModal = (winnerDetail) => {
    this.setState({ showDetailModal: true, winnerDetail });
  };

  hideDetailModal = () => {
    this.setState({ showDetailModal: false, winnerDetail1: undefined });
  };
  renderAwardDetailsLink = ({ record }) => {
    return (
      <Link
        href="#"
        onClick={() => {
          this.showDetailModal(record);
        }}
      >
        Show Contribution
      </Link>
    );
  };

  render() {
    const { props } = this;
    const { columnLabels, emptyText } = props;

    const filterFields = Object.keys(props.filterLabels).map((key) => ({
      id: key,
      label: props.filterLabels[key]
    }));

    return (
      <React.Fragment>
        <Row>
          <Col sm={4}>
            <Drawer start="sm" buttonText="Filter">
              <HeaderThree>{props.filterSectionLabels.filterBy}</HeaderThree>
              <ApiFilters filters={props.filters} fields={filterFields} onChange={props.filter} />
            </Drawer>
          </Col>
          <Col sm={20}>
            <DataTableDefault {...props} defaultSort={defaultSort} emptyText={emptyText}>
              <DataTableColumn width={`80px`} renderCell={renderInitialsImage} />
              <DataTableColumn className={`hidden-xs`} field="firstName" renderCell={renderNameCell}>
                {columnLabels.firstNameTableHeader}
              </DataTableColumn>
              <DataTableColumn className={`hidden-xs`} field="lastName" renderCell={renderNameCell}>
                {columnLabels.lastNameTableHeader}
              </DataTableColumn>
              <DataTableColumn className={`hidden-sm hidden-md`} field="city">
                {columnLabels.cityTableHeader}
              </DataTableColumn>
              <DataTableColumn width={`120px`} className={`hidden-sm`} field="nominationType">
                {columnLabels.nominationTypeTableHeader}
              </DataTableColumn>
              <DataTableColumn field="criteria">{columnLabels.criteriaTableHeader}</DataTableColumn>
              <DataTableColumn field="teamName">{columnLabels.teamNameTableHeader}</DataTableColumn>
              <DataTableColumn renderCell={this.renderAwardDetailsLink}>
                {columnLabels.awardDetailsTableHeader}
              </DataTableColumn>
            </DataTableDefault>
          </Col>
        </Row>
        <WinnerDetailModal
          show={this.state.showDetailModal}
          onHide={this.hideDetailModal}
          winnerDetail={this.state.winnerDetail}
        />
      </React.Fragment>
    );
  }
}
const mapResource = (getResource) => ({
  columnLabels: {
    firstNameTableHeader: getResource('nominationWinners.firstNameTableHeader', 'First Name'),
    lastNameTableHeader: getResource('nominationActivity.lastNameTableHeader', 'Last Name'),
    cityTableHeader: getResource('nominationActivity.cityTableHeader', 'City'),
    statusTableHeader: getResource('nominationActivity.statusTableHeader', 'Status'),
    nominationTypeTableHeader: getResource('nominationActivity.nominationTypeTableHeader', 'Type'),
    criteriaTableHeader: getResource('nominationActivity.criteriaTableHeader', 'Criteria'),
    teamNameTableHeader: getResource('nominationActivity.teamNameTableHeader', 'Team'),
    awardDetailsTableHeader: getResource('nominationActivity.awardDetailsTableHeader', 'Award Details')
  },
  filterLabels: {
    firstName: getResource('nominationActivity.firstNameFilterLabel', 'First Name'),
    lastName: getResource('nominationActivity.lastNameFilterLabel', 'Last Name'),
    city: getResource('nominationActivity.cityFilterLabel', 'City'),
    nominationType: getResource('nominationActivity.nominationTypeFilterLabel', 'Type'),
    criteria: getResource('nominationActivity.criteriaFilterLabel', 'Critera'),
    teamName: getResource('nominationActivity.teamNameFilterLabel', 'Team')
  },
  filterSectionLabels: {
    filterBy: getResource('ApiFilters.filterByLabel', 'Filter by')
  },
  emptyText: getResource('winnersTable.emptyText', 'There are currently no four star winners')
});

export default register('rsv8-nomination/WinnersTable')(
  withContent(),
  withResource(mapResource),
  withApiDataProvider(nominationActions.getWinners, winnersSelectors, {
    sort: defaultSort
  })
)(WinnersTable) as ComponentType<{}>;
