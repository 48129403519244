import * as React from 'react';
import { connect } from 'react-redux';
import { HeaderFour } from 'rsv8-components';
import { RebateTimePeriod } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getSummary } from '../../redux/actions';
import { currentTimePeriodSelector } from '../../redux/selectors';
import NoDataSpinner from '../NoDataSpinner';
import { OverlayPopover } from '../Overlay';

const DashBoardTitle: React.FC<Props> = ({
  className,
  currentTimePeriod,
  infoIconText = 'Sales data may be delayed 1-2 weeks if you order from a distributor.',
}) => {
  if (!currentTimePeriod) return <NoDataSpinner />;

  return (
    <div className={className}>
      <HeaderFour themeVariation="my-dashboard-plan">
        Sales Data Last Updated{' '}
        {new Date(currentTimePeriod.estimatedSalesAsOfDate).toLocaleString('en-US', { month: 'long', day: '2-digit', year: 'numeric' })}
        <OverlayPopover id="dashBoardtitle-popover" placement="bottom">
          {infoIconText}
        </OverlayPopover>
      </HeaderFour>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  infoIconText: getContent('infoIconText', { type: 'string', label: 'Info Icon Text' }),
});

export const mapStateToProps = (state) => ({
  currentTimePeriod: currentTimePeriodSelector(state),
});

interface StateProps {
  currentTimePeriod: RebateTimePeriod;
}
interface ContentProps {
  infoIconText: string;
}
type Props = StateProps & ContentProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/DashBoardTitle')(
  withTheme(),
  withContent(mapContentToProps),
  withApiDataProvider(getSummary, currentTimePeriodSelector),
  connect(mapStateToProps)
)(DashBoardTitle);
