import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withResource } from 'xcel-react-core';
import { FieldContainer } from '../../../components/Label';
import Masonry from '../../../components/Masonry/Masonry';
import { setBrandChange, toggleBrands } from '../../../redux/actions';
import { isBrandsModalOpen } from '../../../redux/selectors';
import { sortBrands } from '../util/sortBrands';
import {
  BrandGroup,
  BrandGroupAlpha,
  BrandsCheckboxGroup,
  BrandsHeader,
  BrandsModalBody,
  BrandsModalContainer,
  BrandsTitle
  } from './styles';

interface BrandsModalProps {
  actions: {
    toggleBrands: any;
    setBrandChange: any;
  };
  brandOptions: any;
  checkedBrands: any;
  isOpen: boolean;
  history: any;
  cancelLabel: string;
  filterLabel: string;
  brandsModalTitle: string;
}

class BrandsModal extends React.Component<BrandsModalProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      brandGroups: {},
      currentlyChecked: []
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.brandOptions !== prevProps.brandOptions) {
      this.setState({ currentlyChecked: this.props.checkedBrands, brandGroups: sortBrands(this.props.brandOptions) });
    }
  }

  handleBrandChange = (value) => {
    this.setState({ currentlyChecked: value });
  };

  revertChanges = (value) => {
    this.setState({ currentlyChecked: this.props.checkedBrands });
    this.props.actions.toggleBrands();
  };

  submitFilter = () => {
    this.props.actions.setBrandChange(this.state.currentlyChecked, this.props.history);
    this.props.actions.toggleBrands();
  };

  render() {
    const { isOpen, cancelLabel, filterLabel, brandsModalTitle } = this.props;

    return (
      <BrandsModalContainer show={isOpen} onHide={this.revertChanges}>
        <BrandsHeader closeButton={true}>
          <BrandsTitle>{brandsModalTitle}</BrandsTitle>
        </BrandsHeader>
        <BrandsModalBody>
          <Masonry>
            {Object.keys(this.state.brandGroups).map((brand) => (
              <BrandGroup key={brand}>
                <BrandGroupAlpha>{brand}</BrandGroupAlpha>
                <FieldContainer>
                  <BrandsCheckboxGroup
                    component={'checkbox-group'}
                    name="brandFiltersModal"
                    options={this.state.brandGroups[brand]}
                    onChange={this.handleBrandChange}
                    value={this.state.currentlyChecked}
                  />
                </FieldContainer>
              </BrandGroup>
            ))}
          </Masonry>
        </BrandsModalBody>
        <Modal.Footer>
          <Button onClick={this.revertChanges}>{cancelLabel}</Button>
          <Button onClick={this.submitFilter}>{filterLabel}</Button>
        </Modal.Footer>
      </BrandsModalContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isOpen: isBrandsModalOpen(state)
});

const mapResourceToProps = (getResource) => ({
  cancelLabel: getResource('catalog.search.cancelLabel', 'Cancel'),
  filterLabel: getResource('catalog.search.filterLabel', 'Filter'),
  brandsModalTitle: getResource('catalog.search.brandsModalTitle', 'Brands')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setBrandChange,
      toggleBrands
    },
    dispatch
  )
});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(BrandsModal);
