import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { routeActions } from '../../../../../redux/actions';
import { contentTypeSelector, routeSelector } from '../../../../../redux/selectors';
import { RouteModel } from '../../../../../redux/types/route';
class CreateRouteModal extends React.Component<{
  onHide: Function;
  show?: boolean;
  routes?: RouteModel[];
  contentTypeId?: string;
  dispatch?: any;
  onCreate?: any;
}> {
  state = { key: null };
  handleCreate = () => {
    const { dispatch, routes, contentTypeId, onCreate } = this.props;
    const route = routes[0];
    if (this.state.key !== null && route.id !== undefined) {
      dispatch(routeActions.create(contentTypeId, route.id, this.state.key));
    }
    if (onCreate) {
      onCreate(this.state.key);
    }
  };
  handleChange = (property) => (value) => {
    this.setState({ ...this.state, [property]: value });
  };
  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.handleCreate();
    }
  };
  render() {
    return (
      <Modal {...this.props} bsSize="small">
        <Modal.Header closeButton={true}>
          <Modal.Title>Create Route</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ReactField
            label="Route Key"
            placeholder="Type Route Key"
            component="bs-input"
            autoFocus={true}
            onChange={this.handleChange('key')}
            onKeyDown={this.handleEnter}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="primary" onClick={this.handleCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    routes: routeSelector.getActive(state, contentTypeId)
  };
};
export default connect(mapStateToProps)(CreateRouteModal as any) as any;
