import * as React from 'react';
import { SessionExpireWarning } from 'rsv8-auth/dist/component';
import { PageRenderer } from 'rsv8-cms';
import { Toastify, ZendeskWidget } from 'rsv8-components';
import styled from 'styled-components';
import { RouteMatch } from 'xcel-react-core';
import { Navigation, PrimaryMenu } from '../components';
import { LayoutProps } from './types';

// ie11 needs the parent of the flexed container to also be flexed
const IeFix = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const ContentContainer = styled.div`
  flex-grow: 1;
`;
const DefaultLayout = ({ children, ...rest }: LayoutProps) => {
  return (
    <RouteMatch>
      <IeFix>
        <Wrapper {...rest}>
          <SessionExpireWarning />
          <PrimaryMenu />
          <Navigation />
          <Toastify hideProgressBar={true} autoClose={2500} />
          <ContentContainer>{children}</ContentContainer>
          <PageRenderer path={`/layout/footer`} />
          <ZendeskWidget />

        </Wrapper>
      </IeFix>
    </RouteMatch>
  );
};

export default DefaultLayout;
