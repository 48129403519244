import * as React from 'react';
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow
  } from 'rsv8-components';
import styled from 'styled-components';
import { RouteItem } from '../../redux/types/route';

const ContentTableBase = styled.div`
  margin-bottom: 100px;
  tr {
    cursor: pointer;
  }
` as any;
const TableIcon = styled.span`
  font-size: 20px;
  color: ${(props: any) => props.color};
`;
const ContentTable = ({
  items,
  onClick,
  onDelete,
  onSearch,
  onSort,
  ...rest
}: {
  items: RouteItem[];
  onClick?: any;
  onDelete?: any;
  onSearch?: any;
  onSort?: any;
}) => {
  const handleClick = (item, e) => {
    if (onClick) {
      onClick(item, e);
    }
  };
  const handleDelete = (item, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onDelete) {
      if (e.button === 0) {
        onDelete(item);
      }
    } else {
      alert("Delete hasn't been implemented yet.");
    }
  };

  return (
    <ContentTableBase>
      <Table {...rest} striped={true} bordered={true} hover={true} condensed={true} onSort={onSort}>
        <thead>
          <TableRow>
            <TableHeaderCell width={'30px'} />
            <TableHeaderCell width={'30px'} />
            <TableHeaderCell field="label">Name</TableHeaderCell>
            <TableHeaderCell field="updatedDateTimeUtc">Modified Date</TableHeaderCell>
            <TableHeaderCell field="updatedBy">Modified By</TableHeaderCell>
            <TableHeaderCell>Published</TableHeaderCell>
            <TableHeaderCell width={'30px'} />
          </TableRow>
        </thead>
        <tbody>
          {items &&
            items.map((item, index) =>
            (
              <TableRow key={index} onMouseDown={handleClick.bind(this, item)}>
                <TableCell>
                  {item.type === 'routeModel' ? (
                    <TableIcon color={'#666666'}>
                      <i className={`fa fa-folder`} title="Folder" />
                    </TableIcon>
                  ) : (
                    <TableIcon color={'#666666'}>
                      <i className={`fa fa-file-text-o`} title="Content" />
                    </TableIcon>
                  )}
                </TableCell>
                <TableCell>
                  {item.type !== 'routeModel' && item.isPublished === true ? (
                    <TableIcon color={'#FF0000'}>
                      <i className={`fa fa-flag`} title="Flag" />
                    </TableIcon>
                  ) : (
                    <TableIcon />
                  )}
                </TableCell>
                <TableCell>{item.label}</TableCell>
                <TableCell>
                  {item.updatedDateTimeUtc
                    ? new Date(item.updatedDateTimeUtc)
                      .toISOString()
                      .split('T')
                      .join(' ')
                      .replace('Z', '')
                    : 'N/A'}
                </TableCell>
                <TableCell>{item.updatedBy}</TableCell>
                <TableCell>
                  {item.isPublished && <TableIcon color={'#666666'}>
                    <i className={`fa fa-check`} title="Published" />
                  </TableIcon>}
                </TableCell>
                <TableCell onMouseDown={handleDelete.bind(this, item)}>
                  <TableIcon color={'#666666'}>
                    <i className={`fa fa-trash`} title="Delete" />
                  </TableIcon>
                </TableCell>
              </TableRow>
            ))}
        </tbody>
      </Table>
    </ContentTableBase>
  );
};

export default ContentTable;
