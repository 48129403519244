import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ensureArray } from 'xcel-util/dist';
import { NomineeSearchResults } from '../../components/index';
import { individualNominationActions } from '../../redux/actions/index';
import { individualNominationSelectors } from '../../redux/selectors/index';

export interface NomineeDisplayProps {}

interface InternalProps extends NomineeDisplayProps {
  currentNominees: string | Array<string>;
  selectNominee: any;
}

const NomineeDisplay: React.SFC<InternalProps> = (props) => {
  const currentNominees = ensureArray(props.currentNominees);
  return (
    <NomineeSearchResults currentNominees={currentNominees} selectNominee={props.selectNominee} loadingNominees={[]} />
  );
};

const mapState = (state) => ({
  currentNominees: individualNominationSelectors.getCurrentNomineeId(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ selectNominee: individualNominationActions.selectNominee }, dispatch);

export default connect(
  mapState,
  mapDispatch
)(NomineeDisplay as any) as React.ComponentClass<NomineeDisplayProps>;
