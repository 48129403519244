import { promiseDispatcher } from 'xcel-react-core';
import { createRequestActions } from 'xcel-redux-orm';
import { nominationApi } from '../../../../api/index';
import { REMOVE_TEAM_MEMBER_ENDPOINT_NAME } from '../../../../constants';
import * as events from '../../../events';

const removeTeamMemberActions = createRequestActions(REMOVE_TEAM_MEMBER_ENDPOINT_NAME);

const removeTeamMemberBase = ({ nominationId, userIds }: { nominationId: string; userIds: string }) => {
  return nominationApi.deleteTeamDraftByIdMembersAndUserId({ id: nominationId, userId: userIds } as any);
};

export const removeTeamMemberRequest = promiseDispatcher(removeTeamMemberBase, removeTeamMemberActions);

const removeTeamMemberResults = (response, { nominationId, userIds }) => {
  return {
    type: events.REMOVE_TEAM_MEMBERS,
    payload: { id: userIds }
  };
};

export const removeTeamMember = ({ nominationId, userIds }) => async (dispatch) => {
  try {
    const response = await dispatch(removeTeamMemberRequest({ nominationId, userIds }));
    dispatch(removeTeamMemberResults(response, { nominationId, userIds }));
    return response;
  } catch (e) {
    throw e;
  }
};
