import * as events from '../events';
const contentReducer = (state = {}, action) => {
  switch (action.type) {
    case events.CONTENT_GET_REQUEST: {
      return {
        ...state,
        [action.payload.path]: {
          prefix: action.payload.prefix,
          path: action.payload.path
        }
      };
    }
    case events.CONTENT_GET_FAILURE: {
      return {
        ...state,
        [action.path]: {
          ...state[action.path],
          page: { content: [] }
        }
      };
    }
    case events.CONTENT_GET_SUCCESS: {
      if (state[action.path] === undefined) {
        state[action.path] = { ...state[action.path], page: { content: [] } };
      }
      return {
        ...state,
        [action.path]: {
          ...state[action.path],
          page: { ...state[action.path].page, ...action.payload }
        }
      };
    }
    default: {
      return state;
    }
  }
};
const cmsReducer = (state = {}, action) => {
  switch (action.type) {
    case events.CONTENT_GET_REQUEST:
    case events.CONTENT_GET_FAILURE:
    case events.CONTENT_GET_SUCCESS: {
      if (action.cultureId === undefined) return state;
      return {
        ...state,
        [action.cultureId]: contentReducer(state[action.cultureId], action)
      };
    }
    default: {
      return state;
    }
  }
};
export default cmsReducer;
