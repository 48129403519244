import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';
import { IndividualNomination } from '../../../../types';
import { clearCurrentNomination } from '../nomineeSave/index';

export const selectAwardBase = (appKey): IndividualNomination.Action => {
  return {
    type: events.SELECT_AWARD,
    payload: appKey
  };
};

const selectAward = (value, confirmText = 'Changing award now will clear later steps.') => (dispatch, getState) => {
  const state = getState();
  if (individualNominationSelectors.getCurrentAwardId(state) !== value) {
    if (individualNominationSelectors.getCurrentCustomFields(state).length === 0 || confirm(confirmText)) {
      dispatch(clearCurrentNomination());
      dispatch(selectAwardBase(value));
    }
  }
};

export default selectAward;
