import * as React from 'react';
import { connect } from 'react-redux';
import { register, withContent, withTheme } from 'xcel-react-core';
import programSelector from '../../../redux/selectors/programSelector';

const Container = ({ children, program, className }) => {
  const platinumClass = program && program.isPlatinum ? 'platinum' : '';
  return <div className={`${className} ${platinumClass}`}>{children}</div>;
};

const mapStateToProps = (state) => ({
  program: programSelector.selectMany(state) ? programSelector.selectMany(state)[0] : null
});

export default register('rsv8-components/layout/Container')(connect(mapStateToProps), withContent(), withTheme())(
  Container
);
