import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { Loader } from 'rsv8-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { getActivities } from '../../redux/actions';
import { activities, isFailedActivities, isLoadingActivities } from '../../redux/selectors';
import FeedCard from './FeedCard';
import { CroppedContent, FillColumn, FitRow } from './styles';

export interface SocialFeedProps {
  socialActivities: Array<any>;
  cultureId: string;
  isLoading: boolean;
  isFailed: boolean;
  className?: string;
  getSocialActivities: (params?: any) => void;
}

class SocialFeed extends React.PureComponent<SocialFeedProps> {
  componentDidMount() {
    this.props.getSocialActivities();
  }

  componentDidUpdate(prevProps: SocialFeedProps) {
    if (Number(prevProps.cultureId) !== Number(this.props.cultureId)) {
      this.props.getSocialActivities();
    }
  }

  renderCards() {
    const { socialActivities } = this.props;
    return socialActivities.map((entry, index) => <FeedCard key={index} {...entry} />);
  }

  render() {
    if (this.props.isFailed || this.props.isLoading) {
      return <Loader />;
    }

    return (
      <FitRow className={this.props.className}>
        <FillColumn>
          <CroppedContent>{this.renderCards()}</CroppedContent>
        </FillColumn>
      </FitRow>
    );
  }
}

const mapStateToProps = (state) => ({
  socialActivities: activities(state),
  isLoading: isLoadingActivities(state),
  isFailed: isFailedActivities(state),
  cultureId: cultureSelector.getActiveCultureId(state)
});

const mapContentToProps = (getContent) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSocialActivities: bindActionCreators(getActivities, dispatch)
});

export default register('rsv8-app/SocialFeed')(
  withTheme(),
  withContent(mapContentToProps),
  connect(mapStateToProps, mapDispatchToProps)
)(SocialFeed);
