import * as React from 'react';
import { Grid } from 'react-bootstrap';
import { ModularMenu } from 'rsv8-navigation';
import { DashboardModal, RecognitionButtons } from '../../components/index';
import { ApprovalHistory } from './History/index';
import { PendingApprovals } from './Pending/index';

export interface NominationActivityProps {}

interface InternalProps extends NominationActivityProps {}

class NominationActivity extends React.PureComponent<InternalProps, {}> {
  render() {
    return (
      <React.Fragment>
        <ModularMenu appKey="nominationNav" title="Recognition" template="NominationsHeaderNav" />
        <Grid>
          <RecognitionButtons />
          <PendingApprovals />
          <ApprovalHistory />
          <DashboardModal />
        </Grid>
      </React.Fragment>
    );
  }
}

export default NominationActivity as React.ComponentType<NominationActivityProps>;
