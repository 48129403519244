import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AuthLink from '../MenuItem/AuthLink';
import { MenuContext } from '../ModularMenus/NavigationContext';
const StyleAuthLink = styled(AuthLink)`
  ${(props: any) => props.backgroundImage && `background-image: url("/clientassets/images${props.backgroundImage}");`};
` as any;
let LegacyLink = (props) => {
  return (
    <MenuContext.Consumer {...props}>
      {({ open, close, closeAll }: any) => {
        return (
          <StyleAuthLink
            onMouseEnter={() => open(props.id)}
            onMouseLeave={() => close(props.id)}
            onClick={closeAll}
            href={props.targetUrl}
            className={props.className}
            backgroundImage={props.imageURL}
          >
            {props.label}
          </StyleAuthLink>
        );
      }}
    </MenuContext.Consumer>
  );
};
const mapStateToProps = (state) => ({ routing: state.routing });
export default connect(mapStateToProps)(LegacyLink) as any;
