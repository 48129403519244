import { ActivityAddCultureModel } from 'xcel-api-generator/dist/social';
import { destroy, destroyRequest } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import * as events from '../../events';
import { contentCultureSelector, socialSelector } from '../../selectors';
import componentActions from '../component';
import contentActions from '../content';
import ActivityStatus from './ActivityStatus';
import { debounce, findNewVersion } from './helpers';
import {
  createActivitiesById,
  deleteActivitiesByIdVersionsAndVersionId,
  getActivitiesByIdDetails,
  patchActivitiesByIdVersionsAndVersionId
  } from './mapActions';

const {
  contentVersionSelector,
  cultureByCultureId,
  currentCulture,
  currentVersion,
  getLastCulture,
  socialRoot,
  versions
} = socialSelector;

/**
 * Redux action for adding a new version for editing
 *
 */
export const setEditVersion: any = (version) => ({
  type: events.SET_EDIT_VERSION,
  version
});

/**
 * Redux action for adding a new version culture for editing
 *
 */
export const setEditCulture: any = (culture) => ({
  type: events.SET_EDIT_CULTURE,
  culture
});

/**
 * Put the version and the culture in the redux state when both are modified
 * And mapping their data in the edit form on the activity edit view
 *
 * @param {function} dispatch Function - redux dispatch method
 * @param {object} state Object - redux global state
 * @param {string} activityId String
 */
export const setVersionAndCulture: any = (dispatch, state, activityId: string) => {
  const culture = socialRoot(state).culture;
  const version = socialRoot(state).version;
  const current = !!culture
    ? contentCultureSelector.selectOne(state, culture.id)
    : // Setting last culture available based on in the culture list
      getLastCulture(state, activityId);
  const currentVersions = (current && current.versions) || [];
  // Get last updated version from API
  const currentVer = !!version ? contentVersionSelector.selectOne(state, version.id) : findNewVersion(currentVersions);

  dispatch(setEditCulture(current));
  dispatch(setEditVersion(currentVer));
  dispatch(
    contentActions.setEdit('Activity', {
      content: currentVer.content
    })
  );
};

export const createNewCulture: any = (activityId: string, payload: ActivityAddCultureModel) => async (
  dispatch,
  getState
) => {
  try {
    // Add a provitional culture before to make the request
    // in order to display a change to the user and to show
    // that a new draft is processing
    dispatch(
      setEditCulture({
        cultureId: payload.cultureId,
        versions: [payload]
      })
    );
    await dispatch(
      createActivitiesById({
        id: activityId,
        model: payload
      })
    );
    dispatch(destroyRequest('getActivitiesByIdDetails'));
    await dispatch(getActivitiesByIdDetails({ id: activityId }));

    const { cultureId } = payload;
    const state = getState();
    const newCulture = cultureByCultureId(state, cultureId);
    const [firstVersion] = (newCulture && newCulture.versions) || ([] as any);

    dispatch(setEditCulture(newCulture));
    dispatch(setEditVersion(firstVersion));
  } catch (error) {
    alert(`Cannot create a new culture: ${error.message}`);
  }
};

export const createNewVersion: any = (activityId: string, payload: ActivityAddCultureModel) => async (
  dispatch,
  getState
) => {
  debounce(async () => {
    try {
      // create the new version in the current culture
      const { cultureId } = currentCulture(getState());
      payload.cultureId = cultureId;

      await dispatch(createActivitiesById({ id: activityId, model: payload }));
      dispatch(destroyRequest('getActivitiesByIdDetails'));
      await dispatch(getActivitiesByIdDetails({ id: activityId }));

      const state = getState();
      const { id } = currentCulture(state);
      // Reset the culture
      const updatedCulture: any = contentCultureSelector.selectOne(state, id) || {};
      dispatch(setEditCulture(updatedCulture));
      // Update the editing form
      const [newVersion] = versions(getState());
      dispatch(setEditVersion(newVersion));
      dispatch(
        contentActions.setEdit('Activity', {
          content: newVersion.content
        })
      );
    } catch (error) {
      alert(`Cannot create a new version: ${error.message}`);
    }
  });
};

export const editVersionContent: any = (dropProps) => async (dispatch, getState) => {
  const { dragItem, to } = dropProps;

  if (dragItem !== undefined) {
    const { component } = dragItem;

    dispatch(componentActions.add({ component, to }));
  }
};

export const editVersion: any = (activityId: string, versionId: number, payload: any) => async (dispatch, getState) => {
  // update redux state first
  const version = currentVersion(getState());
  dispatch(setEditVersion({ ...version, ...payload }));
  debounce(async () => {
    const request = {
      operations: convertToPatchOperations(payload)
    };
    await dispatch(
      patchActivitiesByIdVersionsAndVersionId({
        id: activityId,
        versionId,
        request
      })
    );
    dispatch(destroyRequest('getActivitiesByIdDetails'));
    await dispatch(getActivitiesByIdDetails({ id: activityId }));
    // update the current version and culture
    setVersionAndCulture(dispatch, getState(), activityId);
  });
};

export const removeVersion: any = (activityId, versionId) => async (dispatch, getState) => {
  try {
    dispatch(destroy('contentVersionModel', [versionId]));
    const culture = currentCulture(getState());
    const versionIndex = culture.versions.findIndex(
      (version) => version.publishStatus !== ActivityStatus.PUBLISHED && version.id === versionId
    );

    if (versionIndex !== -1) {
      culture.versions.splice(versionIndex, 1);
      dispatch(setEditCulture(culture));

      const [version] = versions(getState());
      dispatch(setEditVersion(version));
      dispatch(
        contentActions.setEdit('Activity', {
          content: version.content
        })
      );

      await dispatch(
        deleteActivitiesByIdVersionsAndVersionId({
          id: activityId,
          versionId: versionId
        })
      );

      dispatch(destroyRequest('getActivitiesByIdDetails'));
      await dispatch(getActivitiesByIdDetails({ id: activityId }));
    }
  } catch (error) {
    alert(`Cannot delete version activity: ${error.message}`);
  }
};
