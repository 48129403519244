// Custom Fields Non-Admin API
// GET /usercustomfields/{formType}/{key}
export const GET_USER_CUSTOM_FIELDS_REQUEST = 'rsv8-cf/GET_USER_CUSTOM_FIELDS_REQUEST';
export const GET_USER_CUSTOM_FIELDS_SUCCESS = 'rsv8-cf/GET_USER_CUSTOM_FIELDS_SUCCESS';
export const GET_USER_CUSTOM_FIELDS_FAILURE = 'rsv8-cf/GET_USER_CUSTOM_FIELDS_FAILURE';

// PUT /usercustomfield/{formType}/{key}
export const PUT_USER_CUSTOM_FIELDS_REQUEST = 'rsv8-cf/PUT_USER_CUSTOM_FIELDS_REQUEST';
export const PUT_USER_CUSTOM_FIELDS_SUCCESS = 'rsv8-cf/PUT_USER_CUSTOM_FIELDS_SUCCESS';
export const PUT_USER_CUSTOM_FIELDS_FAILURE = 'rsv8-cf/PUT_USER_CUSTOM_FIELDS_FAILURE';

// GET /customfields
export const GET_FORM_TYPES_REQUEST = 'rsv8-cf/GET_FORM_TYPES_REQUEST';
export const GET_FORM_TYPES_SUCCESS = 'rsv8-cf/GET_FORM_TYPES_SUCCESS';
export const GET_FORM_TYPES_FAILURE = 'rsv8-cf/GET_FORM_TYPES_FAILURE';

export const GET_FORM_KEYS_REQUEST = 'rsv8-cf/GET_FORM_KEYS_REQUEST';
export const GET_FORM_KEYS_SUCCESS = 'rsv8-cf/GET_FORM_KEYS_SUCCESS';
export const GET_FORM_KEYS_FAILURE = 'rsv8-cf/GET_FORM_KEYS_FAILURE';

// GET /customfields/{formType}/{key}
export const GET_FORM_CUSTOM_FIELDS_REQUEST = 'rsv8-cf/GET_FORM_CUSTOM_FIELDS_REQUEST';
export const GET_FORM_CUSTOM_FIELDS_SUCCESS = 'rsv8-cf/GET_FORM_CUSTOM_FIELDS_SUCCESS';
export const GET_FORM_CUSTOM_FIELDS_FAILURE = 'rsv8-cf/GET_FORM_CUSTOM_FIELDS_FAILURE';

// GET /customfields/lookup
export const GET_CUSTOM_FIELD_LOOKUPS_REQUEST = 'rsv8-cf/GET_CUSTOM_FIELD_LOOKUPS_REQUEST';
export const GET_CUSTOM_FIELD_LOOKUPS_SUCCESS = 'rsv8-cf/GET_CUSTOM_FIELD_LOOKUPS_SUCCESS';
export const GET_CUSTOM_FIELD_LOOKUPS_FAILURE = 'rsv8-cf/GET_CUSTOM_FIELD_LOOKUPS_FAILURE';

export const GET_VALIDATIONS_REQUEST = 'rsv8-cf/GET_CUSTOM_FIELD_VALIDATIONS_REQUEST';
export const GET_VALIDATIONS_SUCCESS = 'rsv8-cf/GET_CUSTOM_FIELD_VALIDATIONS_SUCCESS';
export const GET_VALIDATIONS_FAILURE = 'rsv8-cf/GET_CUSTOM_FIELD_VALIDATIONS_FAILURE';

export const GET_FIELD_TYPES_REQUEST = 'rsv8-cf/GET_CUSTOM_FIELD_TYPES_REQUEST';
export const GET_FIELD_TYPES_SUCCESS = 'rsv8-cf/GET_CUSTOM_FIELD_TYPES_SUCCESS';
export const GET_FIELD_TYPES_FAILURE = 'rsv8-cf/GET_CUSTOM_FIELD_TYPES_FAILURE';

// GET /customfields/lookup/{key}
export const GET_CUSTOM_FIELD_LOOKUP_KEY_REQUEST = 'rsv8-cf/GET_CUSTOM_FIELD_LOOKUP_KEY_REQUEST';
export const GET_CUSTOM_FIELD_LOOKUP_KEY_SUCCESS = 'rsv8-cf/GET_CUSTOM_FIELD_LOOKUP_KEY_SUCCESS';
export const GET_CUSTOM_FIELD_LOOKUP_KEY_FAILURE = 'rsv8-cf/GET_CUSTOM_FIELD_LOOKUP_KEY_FAILURE';

export const ADD_CUSTOM_FIELDS = 'rsv8-cf/ADD_CUSTOM_FIELDS';
export const ADD_CUSTOM_FIELDS_TO_FORM = 'rsv8-cf/ADD_CUSTOM_FIELDS_TO_FORM';
