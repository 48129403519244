import * as d3 from 'd3';
import * as React from 'react';

export const Calendar: React.FunctionComponent<any> = ({ anchorId }) => {
  React.useEffect(() => {
    const svg = d3.select(`#${anchorId}`).style('font-family', 'SimpleSans');

    const width = svg.style('width').replace('px', '');
    const height = svg.style('height').replace('px', '');

    const g = svg.append('g').attr('transform', 'translate(' + (width / 2 - 15) + ',' + height / 2 + ')');
    const g2 = svg.append('g').attr('transform', 'translate(' + (width / 2 - 15) + ',' + height / 2 + ')');

    // Fine for now
    // Need to refactor into new method of text
    // See Trophy
    let dateText = g
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'central') // NOT IE11 COMPATIBLE. ADD VAL TO DY
      .text(`4`);
    dateText
      .style('font-weight', '300')
      .style('font-size', '58px')
      .style('fill', '#fad37c');

    let durationText = dateText
      .append('tspan')
      .attr('text-anchor', 'start')
      .attr('dy', '1.15em')
      .attr('dx', '.25em')
      .attr('alignment-baseline', 'baseline') // NOT IE11 COMPATIBLE. ADD VAL TO DY
      .text(`days`);
    durationText
      .style('font-weight', 'bold')
      .style('font-size', '14px')
      .style('fill', '#fad37c');

    let firstContent = g2
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'central') // NOT IE11 COMPATIBLE. ADD VAL TO DY
      .attr('dy', '3em')
      .text(`since you last`);
    firstContent.style('font-size', '14px').style('fill', '#fad37c');

    let secondContent = g2
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '4.6em')
      .text(`submitted a nomination`);
    secondContent.style('font-size', '14px').style('fill', '#fad37c');
  }, []);

  return <span />;
};
