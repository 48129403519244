import * as React from 'react';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { ShippingInfoLabel, ShippingInfoTable, ShippingInfoValue } from '../../components/Checkout/ShippingInfo/styles';
import { CheckoutConfirmSubHeading, GyftEmail } from './styles';

export interface GyftEmailInformationProps {
  orderInfo: any;
  emailLabel: string;
  headingLabel: string;
}
class GyftEmailInformation extends React.Component<GyftEmailInformationProps, any> {
  render() {
    const { orderInfo, emailLabel, headingLabel } = this.props;

    return (
      <GyftEmail>
        <CheckoutConfirmSubHeading themeVariation="default-sm-margin">{headingLabel}</CheckoutConfirmSubHeading>
        <ShippingInfoTable nopadding={true}>
          <ShippingInfoLabel>
            <Text themeVariation="secondary-text">{emailLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{orderInfo.shippingInfo.gyftEmail}</Text>
          </ShippingInfoValue>
        </ShippingInfoTable>
      </GyftEmail>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  headingLabel: getResource('catalog.checkoutConfirmation.gyftOrderInfoHeading', 'Gyft Email Information'),
  emailLabel: getResource('catalog.checkoutConfirmation.gyftOrderEmailLabel', 'Gyft Email')
});

export default withResource(mapResourceToProps)(GyftEmailInformation);
