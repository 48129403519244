import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './index';
import { Button } from '../Button/index';
import { Text } from '../Typography/index';

interface ModalConfirmationProps {
  closeModal?: () => void;
  show?: boolean;
  bodyText?: string;
  header?: string;
  accept?: () => void;
  decline?: () => void;
  acceptLabel?: string;
  declineLabel?: string;
  acceptColor?: string;
  declineColor?: string;
  swapColors?: boolean;
}

class ModalConfirmation extends React.Component<ModalConfirmationProps> {
  static defaultProps = {
    acceptColor: '#b5be35',
    declineColor: 'red',
  };
  render() {
    const {
      closeModal,
      show,
      bodyText,
      header,
      accept,
      decline,
      acceptLabel,
      declineLabel,
      acceptColor,
      declineColor,
      swapColors,
    } = this.props;
    return (
      <Modal onHide={closeModal} show={show}>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          <Text>{bodyText}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              backgroundColor: `${swapColors ? declineColor : acceptColor}`,
              marginRight: '25px',
            }}
            onClick={() => accept()}
          >
            {acceptLabel}
          </Button>
          <Button
            style={{
              backgroundColor: `${swapColors ? acceptColor : declineColor}`,
            }}
            onClick={() => decline()}
          >
            {declineLabel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalConfirmation;
