import * as React from 'react';
import { Alert, Col, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'rsv8-components';
import { NarrowGrid } from '../../components/index';
import {
  FilterForm,
  NomineeColumn,
  NomineeSearch,
  TeamNomineeInstructions as NomineeInstructions
  } from '../../components/index';
import { ErrorFormContainer } from '../../components/SearchBar/SearchBar';
import { nomineeSearchSelectors, teamMemberActions, teamNominationSelectors } from '../../redux/index';
import { PlaceholderNoResults } from '../MyAwards/Placeholder/index';
import NomineeDisplay from './NomineeDisplay';
import { PaddedRow } from './styles';

export interface NomineeLayoutProps {
  currentNominee: any;
  hasError: boolean;
  errorText: any;
}

interface InternalProps extends NomineeLayoutProps {
  requestSuccessful: boolean;
  requestLoading: boolean;
  requestFailed: boolean;
  total: number;
  current: number;
  currentAward: string;
  loadingNominees: Array<string>;
  deleteNominee: (id: string) => void;
}

const NomineeLayout: React.SFC<InternalProps> = (props) => {
  const CenterComponent = props.requestSuccessful ? (
    <PaddedRow>
      <Col xs={24} md={4}>
        <FilterForm applicationId={props.currentAward} />
      </Col>
      <Col xs={24} md={16}>
        <NomineeDisplay />
      </Col>
      <Col xs={24} md={4}>
        <NomineeColumn
          nominees={props.currentNominee}
          handleIconClick={props.deleteNominee}
          loadingNominees={props.loadingNominees}
        />
      </Col>
    </PaddedRow>
  ) : props.requestLoading ? (
    <Loader />
  ) : props.requestFailed ? (
    <PlaceholderNoResults />
  ) : null;

  return (
    <Grid>
      <PaddedRow>
        <Col xs={24} md={18} mdOffset={3} lg={12} lgOffset={6}>
          <NomineeInstructions />
        </Col>
      </PaddedRow>
      <PaddedRow>
        <NarrowGrid>
          <NomineeSearch awardId={props.currentAward} />
          <ErrorFormContainer>
            {props.hasError ? <Alert bsStyle="danger">{props.errorText}</Alert> : null}
          </ErrorFormContainer>
        </NarrowGrid>
      </PaddedRow>
      {CenterComponent}
    </Grid>
  );
};

const mapState = (state) => {
  const nomineeRequest = nomineeSearchSelectors.getRequest(state);
  return {
    requestSuccessful: nomineeRequest.exists && nomineeRequest.succeeded,
    requestFailed: nomineeRequest.exists && !nomineeRequest.succeeded,
    requestLoading: nomineeRequest.loading,
    currentAward: teamNominationSelectors.getCurrentAwardId(state),
    loadingNominees: teamNominationSelectors.getLoadingNominees(state)
  };
};

const mapDispatch = (dispatch) => bindActionCreators({ deleteNominee: teamMemberActions.remove }, dispatch);

export default connect(
  mapState,
  mapDispatch
)(NomineeLayout) as React.ComponentClass<NomineeLayoutProps>;
