import * as React from 'react';
import { CustomIcon, StyledDownloadIcon, StyledShareIcon } from './styles';

export interface ActionSettings {
  hoverIcon?: string;
  defaultIcon?: string;
  useOnlyDefaultIcon?: boolean;
  label?: string;
  action: () => void;
}

export interface SocialShareableButtonProps {
  shareURL: string;
  saveAction: ActionSettings;
  shareAction: ActionSettings;
  className?: string;
  shareLabelText: string;
  saveLabelText: string;
}

export abstract class BaseSocialShareablesButton<P extends SocialShareableButtonProps, S> extends React.PureComponent<
  P,
  S
> {
  renderSaveButtonIcon() {
    const customIconExist =
      !!this.props.saveAction && (!!this.props.saveAction.defaultIcon || !!this.props.saveAction.hoverIcon);
    if (customIconExist && !this.props.saveAction.useOnlyDefaultIcon) {
      return (
        <CustomIcon
          className="custom-save-icon"
          defaultCustomIcon={this.props.saveAction.defaultIcon}
          title={this.props.saveAction.label}
        />
      );
    }

    return <StyledDownloadIcon />;
  }

  renderShareButtonIcon() {
    const customIconExist = !!this.props.shareAction.defaultIcon || !!this.props.shareAction.hoverIcon;
    if (customIconExist && !this.props.shareAction.useOnlyDefaultIcon) {
      return (
        <CustomIcon
          className="custom-share-icon"
          defaultCustomIcon={this.props.shareAction.defaultIcon}
          title={this.props.shareAction.label}
        />
      );
    }

    return <StyledShareIcon />;
  }
}
