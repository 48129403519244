import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import { HeaderTwo } from '../Typography';
import Modal from './Modal';
import ModalAnchoredContext from './ModalAnchoredContext';
import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';

export const StyledModal = styled(Modal as any)`
  ${(props: any) =>
    props.landingPageSize &&
    `@media (min-width: 601px) {
    .modal-dialog {
      height: 75vh;
      width: 95vw;
    }
  }
  .modal-content {
    position: relative;
  }
  .modal-body {
    height: calc(75vh - 67px);
    padding: 0;
  }`}
` as any;

export interface ModalAnchoredProps {
  title?: string;
  anchor: string;
  onHide?: () => void;
}
class ModalAnchored extends React.Component<any> {
  hide = () => {
    const { dispatch } = this.props;

    if (this.props.onHide) {
      this.props.onHide();
    } else {
      dispatch(push(window.location.pathname + window.location.search));
    }
  };

  isModalOpen = (hash) => {
    if (hash === this.props.anchor) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const isEditing = this.props['data-cms-editing'];
    const { children, anchor, title, width, location, landingPageSize, ...rest } = this.props;

    let hash = window.location.hash.substr(1);
    return (
      <div {...rest}>
        {isEditing && <ModalBody>{children}</ModalBody>}
        <StyledModal
          landingPageSize={landingPageSize}
          width={width}
          show={hash === anchor}
          onHide={this.hide}
          container={this}
        >
          {!landingPageSize && (
            <ModalHeader closeButton={true}>
              {title && <HeaderTwo themeVariation="no-margins-left">{title}</HeaderTwo>}
            </ModalHeader>
          )}
          <ModalAnchoredContext.Provider value={this.isModalOpen(hash)}>
            <ModalBody>{children}</ModalBody>
          </ModalAnchoredContext.Provider>
        </StyledModal>
      </div>
    );
  }
}
const mapContentToProps = (getContent) => {
  return {
    title: getContent('title', { type: 'html', label: 'Title' }),
    anchor: getContent('anchor', { type: 'none', label: 'Anchor' }),
    width: getContent('width', { type: 'none', label: 'Width' }),
    landingPageSize: getContent('landingPageSize', { type: 'checkbox', label: 'Landing Page Size?' })
  };
};
export default register('rsv8-components/ModalAnchored')(
  withRouter,
  connect(),
  withTheme(),
  withContent(mapContentToProps)
)(ModalAnchored);
