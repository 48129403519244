import CartIconWithCount from './app/components/CartIconWithCount';
import CatalogSearch from './app/components/CatalogSearch';
import catalogModule from './catalogModule';

export { FavoritesCarousel, TrendingCarousel } from './app/components/CarouselWrappers';
export { getCart } from './app/redux/actions/cart/getCart';
export { getCartProducts } from './app/redux/selectors/cartSelectors';
export { ProductPointTypes } from './app/utils/cart';

export {
  AllDepartments,
  BrowseByPoints,
  CategoryLanding,
  Checkout,
  CheckoutConfirmation,
  MyFavorites,
  OrderHistory,
  ProductDetail,
  ScrollToTop,
  SearchResults,
  ShopLanding,
  ShoppingCart
} from './app/views';

export { catalogModule, CatalogSearch, CartIconWithCount };
