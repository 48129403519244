import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect, DispatchProp } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { getRequest, RequestTypes } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { contentTypeSelector } from '../../../index';
import { contentVersionActions } from '../../../redux/actions';
import contentVersionSelector, { ContentVersionModel } from '../../../redux/selectors/contentVersion';
import { sortVersions } from '../../../util';

const VersionField = styled.div`
  > div {
    display: inline-block;
    width: calc(100% - 65px);
  }
  button {
    margin: 0 10px;
  }
`;
const versionToLabel = (versionItem: ContentVersionModel, index: number) => {
  const date = new Date(versionItem.updatedDateTimeUtc + 'Z');
  const day = date.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
  const time = date.toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  return `(${index})[${versionItem.publishStatus}] ${day} ${time} (${versionItem.updatedBy})`;
};

const VersionOptionRenderer = ({ options }) => {
  return options.map((item: ContentVersionModel, index: number) => (
    <option key={index} value={item.id}>
      {versionToLabel(item, options.length - index)}
    </option>
  ));
};
class VersionDropdown extends React.Component<{
  version?: ContentVersionModel;
  versions?: ContentVersionModel[];
  dispatch?: any;
  onChange?: any;
  contentTypeId: string;
  contentVersionsRequest: any;
}> {
  handleChangeVersion = (id) => {
    const { dispatch, onChange, contentTypeId } = this.props;
    dispatch(contentVersionActions.setActive(contentTypeId, id));
    if (onChange) {
      onChange(id);
    }
  };
  handleDeleteVersion = () => {
    const { dispatch, version, contentTypeId } = this.props;
    if (confirm('Are you sure you wanted to delete this version?')) {
      dispatch(contentVersionActions.delete(contentTypeId, version.id));
    }
  };

  getVersionList = () => {
    const { versions } = this.props;
    if (versions === undefined) {
      return [];
    } else if (versions instanceof Array) {
      let list: any[] = versions;
      return list.sort(sortVersions);
    } else {
      // The "object" check makes versions a type of "never" so we have to change the type.
      return [versions];
    }
  };

  render() {
    const { version, versions, contentVersionsRequest, ...rest } = this.props;
    return (
      <VersionField>
        <ReactField
          component="bs-select"
          label={contentVersionsRequest && contentVersionsRequest.loading === true ? 'Versions (Loading)' : 'Versions'}
          value={version.id}
          {...rest}
          optionRenderer={VersionOptionRenderer}
          options={this.getVersionList()}
          onChange={this.handleChangeVersion}
        />
        <Button bsStyle="default" title="Delete version." onClick={this.handleDeleteVersion}>
          <i className="fa fa-trash" />
        </Button>
      </VersionField>
    );
  }
}
const noVersion = {};
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    version: contentVersionSelector.getActiveVersion(state, contentTypeId) || noVersion,
    versions: contentVersionSelector.getActiveVersions(state, contentTypeId),
    contentVersionsRequest: getRequest(state, 'getContentversionsByContentCultureId') as RequestTypes.Endpoint
  };
};
export { Resource, DispatchProp };
export default connect(mapStateToProps)(VersionDropdown as any);
