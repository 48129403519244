import { Grid } from 'react-bootstrap';
import styled from 'styled-components';

export const NarrowGrid = styled(Grid)`
  max-width: 575px;
`;

export interface DivProps {
  display?: 'block' | 'inline-block' | 'inline';
  align?: 'right' | 'left' | 'center';
  float?: 'right' | 'left' | 'none';
}

export const Div = styled.div`
  display: ${(props: DivProps) => (props.display ? props.display : 'block')};
  text-align: ${(props: DivProps) => (props.align ? props.align : 'left')};
  float: ${(props: DivProps) => (props.float ? props.float : 'none')};
`;
