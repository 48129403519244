import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withErrorBoundary } from "xcel-react-core";
import { emptyArray } from "xcel-util";
import { contentTypeSelector, routeSelector } from "../../index";
import ScopedStyles from "../../../components/ScopedStyledComponent";
import ContentManager from "../../components/ContentManager";
import ContentPromotionButton from "../../components/ContentPromotionButton";
import PublishButton from "../../components/PublishButton";
import AdminPageRenderer from "../../components/Rendering/AdminPageRenderer";
import SourceEditor from "../../components/SourceEditor";
import SourceButton from "../../components/SourceEditor/SourceButton";
import TranslateButton from "../../components/TranslateButton";
import HoverProvider from "../../hoc/withHover";
import { contentActions } from "../../redux/actions";
import EditToolbar from "./components/EditToolbar";
import scopedStyleLoader from "./scopedStyleLoader";
import { Link } from "rsv8-components";
import styled from "styled-components";
import { routeActions } from "../../redux/actions";
import { push } from "react-router-redux";
import { RouteList } from "../../redux/types/route";

const ContentListBreadcrumb = styled(Breadcrumb)`
  position: relative;
  margin-top: 1rem;
  background-color: #2695f3;
  span {
    color: white;
    text-decoration: none;
  }
`;

export interface ContentEditProps {
  activeRoutes: RouteList[];
  breadcrumbs: RouteList[];
  contentTypeId: string;
  match: { path: string };
  dispatch: any;
}

interface ContentEditState {
  errorMessage: string;
  sourceOpen: boolean;
}

class ContentEdit extends React.Component<any, ContentEditState> {
  state = {
    sourceOpen: false,
    errorMessage: null
  };

  static getDerivedStateFromProps(
    { contentTypeId, activeRoutes, dispatch }: ContentEditProps,
    prevState: { contentTypeId: string }
  ) {
    if (prevState === null || contentTypeId !== prevState.contentTypeId) {
      if (!activeRoutes) {
        dispatch(routeActions.getRoot(contentTypeId));
      }
    }
    return { contentTypeId };
  }

  componentDidMount() {
    const { dispatch, match, contentTypeId } = this.props;
    dispatch(contentActions.getById(contentTypeId, match.params.id));
  }
  componentDidCatch(error: any, info: any) {
    console.warn("Failed to render component", "AdminPageRenderer", error, info);
    this.setState({ errorMessage: "error:" + JSON.stringify(error) });
  }
  handleOpenSource = () => {
    this.setState({ sourceOpen: !this.state.sourceOpen });
  };
  getPathsToPromote = () => {
    const { activeRoutes, match, breadcrumbs } = this.props;
    let activePath = breadcrumbs.reduce((accumulator, route) => {
      return route.label.trim() !== "" ? `${accumulator}/${route.label}` : accumulator;
    }, "");
    let path = "";
    if (Array.isArray(activeRoutes) && activeRoutes.length) {
      const item = activeRoutes[0].items.find((i) => i.id === match.params.id);
      if (item) {
        path = `/${item.label}`;
      }
    }
    const pathsToPromote = activePath + path;

    return [pathsToPromote];
  };

  handleClickBreadcrumb = (crumb) => (e) => {
    const { dispatch, contentTypeId } = this.props;
    dispatch(routeActions.setActive(contentTypeId, [crumb.id]));
    dispatch(push("/cms/list"));
  };

  render() {
    const { errorMessage, sourceOpen } = this.state;
    const { breadcrumbs } = this.props;
    return (
      <HoverProvider property={"model"}>
        <EditToolbar />
        <Row>
          <ContentListBreadcrumb>
            {breadcrumbs.map((crumb, index) => (
              <Breadcrumb.Item key={index}>
                <span onClick={this.handleClickBreadcrumb(crumb)}>{crumb.label.trim() === "" ? "home" : crumb.label}</span>
              </Breadcrumb.Item>
            ))}
          </ContentListBreadcrumb>
        </Row>
        <Row>
          <Col md={18}>
            <SourceEditor visible={sourceOpen} />
            <ScopedStyles {...scopedStyleLoader(100066)}>
              {errorMessage === null && <AdminPageRenderer />}
              {errorMessage !== null && (
                <div>
                  {" "}
                  Failed to render component [Error Message]: {errorMessage}.
                  <div>
                    Try to <Link to={`/cms/json/edit/${this.props.match.params.id}`}> edit JSON</Link>
                  </div>
                </div>
              )}
            </ScopedStyles>
          </Col>
          <Col md={6}>
            <ContentManager />
            <PublishButton />
            <SourceButton onClick={this.handleOpenSource} />
            <TranslateButton />
            <ContentPromotionButton index={3} pathsToPromote={this.getPathsToPromote()} />
          </Col>
        </Row>
      </HoverProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    activeRoutes: routeSelector.getActive(state, contentTypeId),
    breadcrumbs: routeSelector.getActiveBreadcrumbs(state, contentTypeId) || emptyArray
  };
};
export default connect(mapStateToProps)(withErrorBoundary()(ContentEdit));
