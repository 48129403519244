import * as React from 'react';
import { TableHeaderCell, TableRow } from '../index';
class DataTableHeaderRow extends React.PureComponent<any> {
  render() {
    const { columns } = this.props;
    return (
      <TableRow>
        {columns &&
          columns.map((column, index) => {
            const { renderCell, renderHeader, ...rest } = column;
            return (
              <TableHeaderCell key={column.field} {...rest}>
                {renderHeader ? renderHeader({ record: {}, column, index, isMobile: false }) : column.label}
              </TableHeaderCell>
            );
          })}
      </TableRow>
    );
  }
}

export default DataTableHeaderRow;
