import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as moment from 'moment';
import { withResource } from 'xcel-react-core';
import { Col } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import { OrderDetailsContainer, OrderDetailsLabel } from './styles';

const OrderDetails: React.SFC<any> = ({
  orderedLabel,
  confirmationLabel,
  shipToLabel,
  image,
  orderDate,
  orderId,
  shipFirstName,
  shipLastName,
  shipAddress1,
  shipCity,
  shipStateProvince,
  shipPostalCode,
  shipCountry
}) => {
  return (
    <OrderDetailsContainer>
      {image &&<Col sm={6}>
        <img src={image} alt="" />
      </Col>}
      <Col sm={image ? 6 : 10}>
        <OrderDetailsLabel themeVariation="text-bold">{orderedLabel}</OrderDetailsLabel>
        <Text themeVariation="text-black">{moment(orderDate).format('MM/DD/YYYY')}</Text>
        <OrderDetailsLabel themeVariation="text-bold">{confirmationLabel}</OrderDetailsLabel>
        <Text themeVariation="text-black">#{orderId}</Text>
      </Col>
      <Col sm={image ? 12 : 14}>
        <OrderDetailsLabel themeVariation="text-bold">{shipToLabel}</OrderDetailsLabel>
        <Text themeVariation="text-black">
          {shipFirstName} {shipLastName}<br/>
          {shipAddress1}<br/>
          {shipCity}, {shipStateProvince}<br/>
          {shipPostalCode} {shipCountry}
        </Text>
      </Col>
    </OrderDetailsContainer>
  );
};

const mapResourceToProps = (getResource) => ({
  orderedLabel: getResource('achievement.orderDetails.orderedLabel', 'Orderer'),
  confirmationLabel: getResource('achievement.orderDetails.confirmationLabel', 'Confirmation'),
  shipToLabel: getResource('achievement.orderDetails.shipToLabel', 'Ship to'),
});

export default compose<any>(
  connect(),
  withResource(mapResourceToProps)
)(OrderDetails);
