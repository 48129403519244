import * as R from 'ramda';
import { LandingAction, LandingContent, LandingState } from '../../types/landing';
import * as events from '../events';

const defaultState: LandingState = {
  intentedPath: '',
  nextLandingPath: '',
  nextLandingRedirect: '',
  postponed: {},
  loading: true,
  notVisitedLandings: [],
  confirmedPaths: {}
};

const rejectVisited = (visitedLandings) => (landing) => R.any(visited => R.equals(visited, landing), visitedLandings);
const notVisited = (all: Array<LandingContent>, visitedLandings: Array<string>, postponed: Array<string>, confirmedPaths: Array<string>) => 
  R.reject(rejectVisited(visitedLandings.concat(postponed, confirmedPaths)))(all);
const getNextLandingPath = (all: Array<LandingContent>, visitedLandings: Array<string>, postponed: Array<string>, confirmedPaths: Array<string>) => {
  let nextLandingPagePath = ''
  const notVisitedLandings: string[] = notVisited(all, visitedLandings, postponed, confirmedPaths);

  if(notVisitedLandings.length > 0) {
    nextLandingPagePath = `${notVisitedLandings[0]}`;
  }

  return nextLandingPagePath;
}

const landingReducer = (state: LandingState = defaultState, action: LandingAction | any) => {
  switch (action.type) {
    case events.LANDING_INTENT_PATH:
      return {
        ...state,
        intentedPath: action.payload
      };

    case events.LANDING_SET_PATH:
      const currentPath = action.payload.paths.length > 0 ? action.payload.paths[0].substring(0,action.payload.paths[0].lastIndexOf('/')) : '/'
      const nextLandingPath = getNextLandingPath(action.payload.paths, action.payload.visitedPaths, state.postponed[currentPath] || [], state.confirmedPaths[currentPath] || []);
      const nextLandingRedirect = nextLandingPath ? `${nextLandingPath}` : '';
      const notVisitedLandings = notVisited(action.payload.paths, action.payload.visitedPaths, state.postponed[currentPath] || [], state.confirmedPaths[currentPath] || []);
      return {
        ...state,
        nextLandingPath,
        nextLandingRedirect,
        notVisitedLandings
      };

    case events.LANDING_CONFIRM_PATH:
    
      const confirmPath = action.payload.path.substring(0,action.payload.path.lastIndexOf('/')) || '/'
      if (state.confirmedPaths[confirmPath] && state.confirmedPaths[confirmPath].indexOf(action.payload.path) !== -1) {
        return state
      } else {
        return {
          ...state,
          confirmedPaths: {
            ...state.confirmedPaths,
            [confirmPath]: [...state.confirmedPaths[confirmPath] || [], action.payload.path]
          }
        };
      }

    case events.LANDING_CONFIRM_CLEAR:
      return {
        ...state,
        confirmedPaths: {}
      };
    
    case events.LANDING_POSTPONE_PATH:
      const postponePath = action.payload.path.substring(0,action.payload.path.lastIndexOf('/')) || '/'
      
      if (state.postponed[postponePath] && state.postponed[postponePath].indexOf(action.payload.path) !== -1) {
        return state
      } else {
        return {
          ...state,
          postponed: {
            ...state.postponed,
            [postponePath]: [...state.postponed[postponePath] || [], action.payload.path]
          }
        };
      }
 
    case events.DELETE_LAST_VISITED_LANDING:
      state.postponed[action.payload.path.substring(0,action.payload.path.lastIndexOf('/'))].pop()
      return {
        ...state,
        postponed: state.postponed
      };

    case events.LANDING_CLEAR:
      return {
        ...state,
        postponed: {}
      };

    case events.SET_LOADING_DATA:
      return {
        ...state,
        loading: true
      };
    
    case events.SET_DATA_LOADED:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default landingReducer;
