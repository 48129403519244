import * as React from 'react';
import styled from 'styled-components';
import videoApi from 'xcel-api-generator/dist/video';
import {register, withContent, withResource, withTheme} from 'xcel-react-core';
import { VideoEventsEnum } from './video-event.enum';
import { assetService } from 'xcel-asset-service';

interface VideoProps {
  className?: string
  supportLabel: string
  videoKey: string
  poster: string
}

const VideoContainer = styled.div`
  width: ${({ width }: any) => width || 'auto'};
  margin: ${({ margin }: any) =>margin || 'auto'};
  object-fit: contain;
`;

const Video: React.FC<VideoProps> = ({ className, videoKey, poster, supportLabel, ...rest }) => {
  if (!videoKey) {
    return null;
  }

  const [data, setData] = React.useState(null);
  const triggerEvent = (eventKey: VideoEventsEnum) => {
    videoApi.updateUserVideoEvent({ eventKey, videoKey: videoKey.trim() });
  };

  const onPlayEvent = () => triggerEvent(VideoEventsEnum.PLAY)

  const fetchVideoByKey = async (_videoKey: string) => {
    try {
      const res = await videoApi.getVideoByVideoKey({ videoKey: _videoKey }, { jsonApi: true });
      setData(res.included);
    } catch (err) {
      console.error(`Failed to load video by key ${_videoKey}: `, err);
    }
  };
  React.useEffect(() => void fetchVideoByKey(videoKey.trim()), []);

  if (!data) {
    return null
  }
  return (
    <VideoContainer {...rest} className={className}>
      <video controls={true} width="100%" height="auto" playsInline={true} onPlay={onPlayEvent} poster={assetService.getImage(poster)}>
        {data
          .map(item => <source key={item.id} src={item.attributes.source} type={item.attributes.mimeType}/>)}
        {supportLabel}
      </video>
    </VideoContainer>
  );
}

const mapContentToProps = (getContent) => ({
  videoKey: getContent('videoKey', { type: 'none', label: 'Video key' }),
  poster: getContent('poster', { type: 'none', label: 'Initial Display Image' }),
});

const mapResource = (getResource) => ({
  supportLabel: getResource('video.noSupport.label', 'Sorry, your browser doesn\'t support embedded videos'),
});

export default register('rsv8-video/Video')(
  withTheme(),
  withResource(mapResource),
  withContent(mapContentToProps)
)(Video);
