import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, formSelectors, reduxForm } from 'rsv8-forms';
import { BootstrapSuccessAlert } from '../BootstrapAlert';

let EditMenuItemForm = (props) => {
  const { handleSubmit, pristine, submitting, setCurrentForm, navigationMenuAdmin } = props;

  const { lookup, items } = navigationMenuAdmin;

  const menuProviderPopulate = navigationMenuAdmin.providerTypes;
  const menuTypePopulate = lookup.menuType;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>
          Node ID <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="nodeID" component="input" type="text" className="form-control" readOnly="true" />
        </div>
      </div>

      <div className="form-group">
        <label>
          Label <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="nodeLabel" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <label>
          Menu Type <sup>*</sup>
        </label>
        <div>
          <Field name="menuType" required="true" component="select" className="form-control">
            {menuTypePopulate.map((items) => (
              <option value={items.id} key={items.id}>
                {items.key}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Target URL <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="targetUrl" component="input" type="text" className="form-control" />
        </div>
      </div>
      <div className="form-group">
        <label>
          Menu Provider <sup>*</sup>
        </label>
        <div>
          <Field name="providerId" component="select" className="form-control">
            {menuProviderPopulate.map((item) => (
              <option value={item.id} key={item.id}>
                {item.id}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>Parent Menu ID</label>
        <div>
          <Field name="parentMenuId" component="select" className="form-control">
            <option value="">Optional</option>
            {items.map((items) => (
              <option value={items.id} key={items.id}>
                {items.label}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Direction <sup>*</sup>
        </label>
        <div>
          <Field name="direction" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <label>Menu Image</label>
        <div>
          <Field name="menuItemImage" component="input" type="text" placeholder="Menu Icon" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <label>Is Menu Title</label>
        <div>
          <Field name="menuTitle" component="select" className="form-control" required="true">
            <option value="false">False</option>
            <option value="true">True</option>
          </Field>
        </div>
      </div>
      <div className="form-group">
        <label>Add Auth Token?</label>
        <div>
          <Field name="addAuthToken" component="select" className="form-control">
            <option value="none">None</option>
            <option value="legacy">Legacy (v6)</option>
            <option value="v8">v8</option>
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>Link Target</label>
        <div>
          <Field name="linkTarget" component="select" className="form-control">
            <option value="">none</option>
            <option value="_self">_self (default)</option>
            <option value="_blank">_blank</option>
            <option value="_parent">_parent</option>
            <option value="_parent">_top</option>
          </Field>
        </div>
      </div>

      <div id="success-message" style={{ display: 'none' }}>
        <div className="panel panel-success">
          <div className="panel-body">Menu Item has been updated</div>
        </div>
      </div>

      {props.error && <Panel bsStyle="danger">{props.error}</Panel>}

      <div className="form-group">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          style={{ marginRight: '15px' }}
          disabled={pristine || submitting}
          onClick={(values) => {
            setCurrentForm('EditMenuItemForm', props.actions);
            handleSubmit('EditMenuItemForm');
          }}
        >
          Save
        </button>
        {navigationMenuAdmin.showAlert.show && navigationMenuAdmin.showAlert.formName === 'EditMenuItemForm' ? (
          <BootstrapSuccessAlert />
        ) : (
          ''
        )}

        <div id="success-message_edit" />
      </div>
      <div className="error-report" />
    </form>
  );
};

EditMenuItemForm = reduxForm({
  form: 'EditMenuItemForm',
  enableReinitialize: true
})(EditMenuItemForm) as any;
const mapState = (state, ownProps) => {
  // can select values individually
  const cultureId = selector(state, 'cultureId');
  const menuType = selector(state, 'menuType');
  const providerId = selector(state, 'providerId');
  const key = selector(state, 'key');

  return {
    cultureId,
    menuType,
    providerId,
    key,
    initialValues: {
      nodeID: ownProps.node.id,
      clientId: ownProps.node.clientId,
      nodeLabel: ownProps.node.label,
      targetUrl: ownProps.node.targetUrl,
      cultureId: ownProps.node.cultureId,
      menuType: ownProps.node.menuTypeId,
      sequence: ownProps.node.sequence,
      parentMenuId: ownProps.node.parentMenuId,
      providerId: ownProps.node.provider.id,
      direction: ownProps.node.direction,
      applicationId: ownProps.node.applicationId,
      applicationKey: ownProps.node.key,
      menuItemImage: ownProps.node.meta ? ownProps.node.meta.menuItemImage : null,
      menuTitle: ownProps.node.meta ? ownProps.node.meta.menuTitle : null,
      addAuthToken: ownProps.node.meta ? ownProps.node.meta.addAuthToken : null,
      linkTarget: ownProps.node.meta ? ownProps.node.meta.linkTarget : null
    }
  };
};

// Decorate with connect to read form values
const selector: any = formSelectors.getFormValues('SortForm'); // <-- same as form name
EditMenuItemForm = connect(mapState, null)(EditMenuItemForm) as any;

export default EditMenuItemForm;
