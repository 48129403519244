import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { Loader } from 'rsv8-components';
import styled from 'styled-components';
import { register } from 'xcel-react-core';
import { withResource } from 'xcel-react-core';
import { IndividualCriteriaInstructions, NarrowGrid, SelectCriteria } from '../../components/index';
import NominationCollapse from '../../components/NominationCollapse/index';
import { individualNominationActions } from '../../redux/actions/index';
import { individualNominationSelectors } from '../../redux/selectors/index';

const { save, selectCustomFields } = individualNominationActions;

const StyledGrid = styled(NarrowGrid)`
  &.hide {
    display: none;
  }
` as any;

class IndividualCriteriaPage extends React.Component<{
  wizard: any;
  buttonTitle: string;
  award: string;
  form: string;
  initialValues: { NominationForm: { criteria: object } };
  selectCustomFields: (v: object) => void;
}> {
  state = { showPage: false };

  handleShowPage = () => {
    this.setState({ showPage: true });
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.showPage && <Loader />}
        <StyledGrid className={!this.state.showPage && 'hide'}>
          <IndividualCriteriaInstructions />
          <SelectCriteria type="individual" showPage={this.handleShowPage} {...this.props} />
          <NominationCollapse title={this.props.buttonTitle}>
            <PageRenderer path={`partial/nomination/individual/criteria`} />
          </NominationCollapse>
        </StyledGrid>
      </React.Fragment>
    );
  }
}

const mapState = (state) => ({
  form: 'IndividualCriteriaForm',
  award: individualNominationSelectors.getCurrentAwardId(state),
  initialValues: {
    NominationForm: individualNominationSelectors.getCurrentCustomFieldMap(state)
  }
});

const mapResource = (getResource) => ({
  buttonTitle: getResource('nominationProcess.criteriaDefinitionsbuttonTitle', 'View Business Principle Defintions')
});

const mapDispatch = (dispatch) => bindActionCreators({ selectCustomFields, saveNomination: save }, dispatch);

const compose = register('rsv8-nomination/IndividualSelectCriteria');

export default compose(withResource(mapResource), connect(mapState, mapDispatch))(IndividualCriteriaPage);
