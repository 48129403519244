import styled from 'styled-components';
import { LeftArrowIcon, RightArrowIcon } from './ArrowIcon';

export const FeedTitle = styled.h1`
  font-size: 40px;
  text-align: center;
  margin-bottom: 22px;
  font-weight: normal;
  letter-spacing: -0.5px;
`;

export const Container = styled.section`
  margin-bottom: 60px;
`;

interface ArrowIconProps {
  marginTop?: string | null;
}
export const StyledLeftArrowIcon = styled(LeftArrowIcon)<ArrowIconProps>`
  fill: #000;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`};
`;
export const StyledRightArrowIcon = styled(RightArrowIcon)<ArrowIconProps>`
  fill: #000;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`};
`;
