import { splitEvery } from 'ramda';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Grid, GridColumn } from 'rsv8-components';
import { Context } from 'rsv8-wizard';
import { Resources } from '../../types';
import { ProductPanel } from '../index';

export interface ProductSelectionProps {
  products: Array<Resources.Product>;
  nominee?: number;
  selectedProduct?: string | number;
  selectedOptionId?: string | number;
  requestData: { loading: boolean; exists: boolean };
  confirmProductChangeText: any;
  clearNominationData: string;
  cultureId: number;
  wizard?: Context;
  actions: {
    getProducts: (nomineeId?: string | number) => void;
    selectProduct: (productId: string | number, confirmText: string) => void;
    getProductDetails: (productId: string, nominee: number) => void;
    selectProductOptionId: (optionId: string) => void;
    selectProductOptionPointValue: (optionPointValue: number) => void;
  };
}

class ProductSelection extends React.Component<ProductSelectionProps, any> {
  constructor(props: any) {
    super(props);
  }

  /**
   * Function that updates state of current nomination to the selected product.
   * @param {ANY} arg
   */
  handleClick = (value) => (e) => {
    const { actions, selectedOptionId, nominee, confirmProductChangeText } = this.props;
    if (selectedOptionId) {
      actions.selectProductOptionId(null);
      actions.selectProductOptionPointValue(null);
    }
    actions.getProductDetails('recognition', nominee);
    actions.selectProduct(value, confirmProductChangeText.toString());
  };

  /**
   * A function that adds the optionId and point value to the state of current nomination.
   * @param {String, Number} arg
   */
  handleOptionSelect = (id, pointValue) => (e) => {
    const { actions } = this.props;
    actions.selectProductOptionId(id);
    actions.selectProductOptionPointValue(pointValue);
  };

  renderProduct = (product, i, threeProducts) => {
    const { selectedProduct, selectedOptionId } = this.props;
    return (
      <Col key={product.id} xs={24} sm={8} md={8} lg={8}>
        <ProductPanel
          active={selectedProduct === product.id || selectedProduct === product.name}
          onClick={this.handleClick(product.id)}
          handleOption={this.handleOptionSelect}
          id={product.id}
          reduxOptionId={selectedOptionId}
        />
      </Col>
    );
  };
  sort = (a, b) => {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };
  renderProductsRow = (threeProducts, i) => {
    return <Row key={i}>{threeProducts.map(this.renderProduct)}</Row>;
  };
  render() {
    let { products } = this.props;
    products = products.sort(this.sort);
    const splitProducts = splitEvery(3, products);
    return (
      <Grid>
        <GridColumn sm={12} md={24}>
          <React.Fragment>{splitProducts.map(this.renderProductsRow)}</React.Fragment>
        </GridColumn>
      </Grid>
    );
  }
}

export default ProductSelection;
