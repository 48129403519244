import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

interface AdminModalProps {
  formName: any;
  title: any;
  modaleTitle: any;
}

interface AdminModalState {
  showModal: boolean;
}

class AdminModal extends React.Component<AdminModalProps, AdminModalState> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  hasFormChanged = () => {
    const formName = this.props.formName;
    return this.props[formName].anyTouched;
  };

  close = () => {
    let hasChanged = this.hasFormChanged();
    if (hasChanged === true) {
      window.confirm('Are you done adding items?') ? this.setState({ showModal: false }) : '';
    } else {
      this.setState({ showModal: false });
    }
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    return (
      <div
        style={{
          display: 'inline-block',
          paddingLeft: '5px'
        }}
      >
        <Button bsStyle="primary" bsSize="sm" onClick={this.open}>
          {this.props.title}
        </Button>

        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.modaleTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.children}</Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close}>I am Done Adding Items</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let reduxFormSlice = 'rsv8-forms/form';
  let formSelector = state[reduxFormSlice];
  return formSelector;
};

export default connect(mapStateToProps)(AdminModal) as any;

//export default AdminModal;
