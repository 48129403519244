import * as React from 'react';
import { Toastify } from 'rsv8-components';
import styled from 'styled-components';
import { RouteMatch } from 'xcel-react-core';

// ie11 needs the parent of the flexed container to also be flexed
const IeFix = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BlankLayout = ({ children, ...rest }) => {
  return (
    <RouteMatch>
      <IeFix>
        <ContentContainer {...rest}>
          <Toastify hideProgressBar={true} autoClose={2500} />
          {children}
        </ContentContainer>
      </IeFix>
    </RouteMatch>
  );
};

export default BlankLayout;
