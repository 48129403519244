import { NominationApplicationHistoryModel } from 'xcel-api-generator/dist/nomination';
import { createTypeSelector, getRequest } from 'xcel-redux-orm';
import {
  JSON_TYPE_APPROVAL_HISTORY,
  JSON_TYPE_AWARD,
  JSON_TYPE_FILTER,
  JSON_TYPE_INDIVIDUAL_DRAFT_NOMINATION,
  JSON_TYPE_INDIVIDUAL_NOMINATION_HISTORY,
  JSON_TYPE_NOMINATION_DETAIL,
  JSON_TYPE_NOMINEE,
  JSON_TYPE_PENDING_APPROVAL,
  JSON_TYPE_PRODUCT,
  JSON_TYPE_ROLE,
  JSON_TYPE_TEAM_DRAFT_NOMINATION,
  JSON_TYPE_TEAM_NOMINATION_HISTORY,
  JSON_TYPE_WINNERS
  } from '../../constants';
import { ReduxState as State, Resources } from '../../types';
import { modifySelectorCreateDate } from './util';
export const winnersSelectors = createTypeSelector<Resources.WinnerAttributes>(JSON_TYPE_WINNERS);

export const nomineeSelectors = createTypeSelector<Resources.NomineeAttributes>(JSON_TYPE_NOMINEE);

export const awardSelectors = createTypeSelector<Resources.AwardAttributes>(JSON_TYPE_AWARD);

export const productSelectors = createTypeSelector<Resources.ProductAttributes>(JSON_TYPE_PRODUCT);

export const roleSelectors = createTypeSelector<{ name: string; description: string }>(JSON_TYPE_ROLE);

export const applicationSelector = createTypeSelector<NominationApplicationHistoryModel>('application');

export const individualDraftSelectors = createTypeSelector<Resources.IndividualDraftNominationAttributes>(
  JSON_TYPE_INDIVIDUAL_DRAFT_NOMINATION
);

export const nominationDetailSelectors = modifySelectorCreateDate(
  createTypeSelector<Resources.NominationDetailAttributes>(JSON_TYPE_NOMINATION_DETAIL)
);

export const teamDraftSelectors = createTypeSelector<Resources.TeamDraftNominationAttributes>(
  JSON_TYPE_TEAM_DRAFT_NOMINATION
);

export const pendingApprovalSelectors = createTypeSelector<Resources.PendingApprovalAttributes>(
  JSON_TYPE_PENDING_APPROVAL
);

export const approvalHistorySelectors = createTypeSelector<Resources.ApprovalHistoryAttributes>(
  JSON_TYPE_APPROVAL_HISTORY
);

export const individualNominationHistorySelectors = createTypeSelector<Resources.IndividualNominationHistoryAttributes>(
  JSON_TYPE_INDIVIDUAL_NOMINATION_HISTORY
);

export const teamNominationHistorySelectors = createTypeSelector<Resources.TeamNominationHistoryAttributes>(
  JSON_TYPE_TEAM_NOMINATION_HISTORY
);

export const nomineeFilterSelectors = createTypeSelector<Resources.FilterAttributes>(JSON_TYPE_FILTER);

export const getNomineeAwardsRequest = (state: any) => getRequest(state, 'getAwards');

export const getNomineeProductsRequest = (state: any) => getRequest(state, 'getProducts');

export const getAwardsWithName = (state: State): Array<Resources.Award> =>
  awardSelectors.searchMany(state, (a) => !!a.name);

export const getProductsWithName = (state: State): Array<Resources.Product> =>
  productSelectors.searchMany(state, (a) => !!a.name);
