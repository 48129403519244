import * as React from 'react';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';

const FooterBase = styled.div`
  font-family: helvetica;
  text-align: center;
  width: 100%;
  .container {
    > a,
    span {
      margin: 10px;
    }
    .copyright {
      margin: 0 20px;
    }
  }
  .wrapper::-ms-expand {
    display: none;
  }
  .wrapper {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url('/clientassets/images/icons/dropdown.svg') calc(100% - 5px) / 10% no-repeat;
    padding-left: 5px;
    padding-right: 20px;

    option {
      margin: 5px;
    }
  }
  /* Fix only for IE because the image looks too small */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .wrapper {
      background: url('/clientassets/images/icons/dropdown.svg') calc(100% + 3px) / 35% no-repeat;
    }
  }
`;

const V8Footer = ({ children, className = '' }) => {
  return <FooterBase className={`footer-base ${className}`}>{children}</FooterBase>;
};

export default register('rsv8-app/Footer')(withContent(), withTheme())(V8Footer);
