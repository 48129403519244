import {
  Amount,
  Badge,
  CustomerAffiliation,
  CustomerProduct,
  Dashboard,
  GoalDto,
  MarketShareAmount,
  Organization,
  Performance,
  RebateTimePeriod,
  Tier,
} from 'xcel-api-generator/dist/alcon';
import { createTypeSelector } from 'xcel-redux-orm';
import * as constants from '../../constants';

export const dashboardSelector = createTypeSelector<Dashboard>('dashboard');
export const customerProductsSelector = createTypeSelector<CustomerProduct>('customerProduct');
export const performanceSelector = createTypeSelector<Performance>('performance');
export const organizationSelector = createTypeSelector<Organization>('organization');
export const amountSelector = createTypeSelector<Amount>('amount');
export const marketShareAmountSelector = createTypeSelector<MarketShareAmount>('marketShareAmount');
export const goalSelector = createTypeSelector<GoalDto>('goalDto');
export const rebateTimePeriodSelector = createTypeSelector<RebateTimePeriod>('rebateTimePeriod');
export const affiliationSelector = createTypeSelector<CustomerAffiliation>('customerAffiliation');
export const tierSelector = createTypeSelector<Tier>('tier');
export const badgeSelector = createTypeSelector<Badge>('badge');

export const timePeriodNoteSelector = (state) => {
  const timePeriods: RebateTimePeriod[] = rebateTimePeriodSelector.selectMany(state);
  if (timePeriods.length === 0) return 'All data displayed is for Q1 in 2021 and Q1 in 2022';

  const baseTimePeriod = baseTimePeriodSelector(state) || { quarter: '-', year: '-' };
  const currentTimePeriod = currentTimePeriodSelector(state) || { quarter: '-', year: '----' };

  return `All data displayed is for Q${baseTimePeriod.quarter} in ${baseTimePeriod.year} and Q${currentTimePeriod.quarter} in ${currentTimePeriod.year}`;
};

const timePeriodSort = (a: RebateTimePeriod, b: RebateTimePeriod) => {
  if (a.year === b.year) return a.quarter - b.quarter;
  return a.year - b.year;
};

export const baseTimePeriodSelector = (state) => {
  const timePeriods: RebateTimePeriod[] = rebateTimePeriodSelector.selectMany(state);
  if (timePeriods.length === 0) return undefined;

  return timePeriods.sort(timePeriodSort)[0];
};

export const currentTimePeriodSelector = (state) => {
  const timePeriods: RebateTimePeriod[] = rebateTimePeriodSelector.selectMany(state);
  if (timePeriods.length === 0) return undefined;

  return timePeriods.sort(timePeriodSort)[timePeriods.length - 1];
};

export const currentYearLabelSelector = (state) => {
  const currentTimePeriod = currentTimePeriodSelector(state);
  return currentTimePeriod?.year;
};

export const patientsSelector = (state): constants.PatientsStateProps => state[constants.ROOT_STATE][constants.PATIENTS_STATE];

export const performanceAmountSelector = (state, id: string): Amount => {
  const marketShareAmount = marketShareAmountSelector.selectOne(state, id);
  if (marketShareAmount != null) {
    return marketShareAmount;
  }
  return amountSelector.selectOne(state, id);
};
