import styled from 'styled-components';

const PrimaryNavStyledWrap = styled.div`
  padding-left: 0;
  padding-right: 0;
  #controlled-tab-example{
    position: relative;
  }
  .menu-item-container{
    overflow: hidden;
  }
  .not-more {
    display: inline-block !important;
    visibility: visible;
  }
  .more {
    visibility: hidden;
    display: none;
  }
  .dropdown {
    display: inline-block;
  }
  .more-button {
    position: absolute;
    right: 0;
    background-color: white;
  }
  .tabs-with-dropdown {
    overflow: hidden;
  }
  .nav {
    white-space: nowrap;
    

      li {
        float: none;
        display: inline-block;
        position: initial;
        .dropdown-menu {
          position: absolute;
          right: 0;
          li {
            display: block;

          }
     
        }
      }
    }


  }
` as any;

export { PrimaryNavStyledWrap };
