import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'rsv8-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import AdjustmentsTable from '../AdjustmentsTable';
import OrdersTable from '../OrdersTable';
import PointEarningsTable from '../PointEarningsTable';

const accountTabs = ['earnings', 'orders', 'adjustments'];

export const AccountBalanceTables: React.SFC<any> = (props) => {
  const windowHash = window.location.hash.substr(1);
  const hash = windowHash.length > 0 && accountTabs.indexOf(windowHash) > -1 ? windowHash : accountTabs[0];

  function handleTabChange(eventKey: string | any) {
    props.dispatch(push(`#${eventKey}`));
  }

  return (
    <Tabs onSelect={handleTabChange} defaultActiveKey={hash}>
      <Tab id={accountTabs[0]} eventKey={accountTabs[0]} title={props.pointEarningsLabel}>
        <PointEarningsTable />
      </Tab>
      <Tab id={accountTabs[1]} eventKey={accountTabs[1]} title={props.orderHistoryLabel}>
        <OrdersTable />
      </Tab>
      <Tab id={accountTabs[2]} eventKey={accountTabs[2]} title={props.adjustmentsLabel}>
        <AdjustmentsTable />
      </Tab>
    </Tabs>
  );
};

const mapResource = (getResource) => ({
  pointEarningsLabel: getResource('accountBalance.pointEarningsLabel', 'Point Earnings'),
  orderHistoryLabel: getResource('accountBalance.orderHistoryLabel', 'Order History'),
  adjustmentsLabel: getResource('accountBalance.adjustmentsLabel', 'Adjustments')
});

export default register('rsv8-account/AccountBalanceTables')(
  connect(),
  withResource(mapResource),
  withTheme(),
  withContent()
)(AccountBalanceTables);
