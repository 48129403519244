import * as d3 from 'd3';
import * as React from 'react';

interface Props {
  id: any;
  title: any;
  chartData: any;
  bgColor: any;
}

export const BarChart: React.FunctionComponent<Props> = ({ id, title, chartData, bgColor }) => {
  const WIDTH = 275;
  const HEIGHT = 325;
  const INNER_HEIGHT = HEIGHT - 110;
  const BAR_WIDTH = 30;
  const BAR_GAP = 12;

  function preprocess(data) {
    return data.map((d, i) => ({ id: i, value: d }));
  }

  const scale = d3
    .scaleLinear()
    .domain([0, d3.max(chartData)])
    .range([0, INNER_HEIGHT]);

  function y(d) {
    return HEIGHT - scale(d.value);
  }

  function height(d) {
    return scale(d.value);
  }

  const colors = ['#158ac2', '#fff', '#5fbbb6', '#073da3'];

  React.useEffect(() => {
    const svg = d3
      .select(`#${id}`)
      .append('svg')
      .attr('width', WIDTH)
      .attr('height', HEIGHT)
      .style('margin', '10px')
      .style('font-family', 'SimpleSans')
      .append('g');

    svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', bgColor);

    function update(data) {
      const t = d3.transition().duration(1000);

      const bar = svg.selectAll('g').data(data, (d) => d.id);

      // EXIT section
      bar.exit().remove();

      // UPDATE section
      bar.transition(t).attr('transform', (d, i) => `translate(${i * (BAR_WIDTH + BAR_GAP)},${y(d)})`);

      bar
        .select('rect')
        .transition(t)
        .attr('height', height);

      bar
        .select('text')
        .transition(t)
        .tween('text', function(d) {
          const v0 = this.textContent || '0';
          const v1 = d.value;
          const i = d3.interpolateRound(v0, v1);
          return (t) => (this.textContent = i(t));
        });

      // ENTER section
      const barEnter = bar
        .enter()
        .append('g')
        .attr('transform', (d, i) => `translate(${i * (BAR_WIDTH + BAR_GAP)},${INNER_HEIGHT + 110})`);

      barEnter.transition(t).attr('transform', (d, i) => `translate(${i * (BAR_WIDTH + BAR_GAP)},${y(d)})`);

      const rect = barEnter
        .append('rect')
        .attr('x', 60)
        .attr('y', -60)
        .attr('width', BAR_WIDTH)
        .attr('height', 0)
        .style('fill', (d, i) => colors[i]);

      rect.transition(t).attr('height', height);

      const text = barEnter.append('text');
      text
        .text((d) => `${d.value}%`)
        .attr('text-anchor', 'middle')
        .attr('dx', BAR_WIDTH / 2 + 60)
        .attr('dy', -70)
        .style('font-size', '17px')
        .style('font-weight', '300')
        .style('fill', '#0b2265');

      svg
        .append('text')
        .attr('transform', 'translate(' + WIDTH / 2 + ' ,' + (HEIGHT - 35) + ')')
        .style('text-anchor', 'middle')
        .style('fill', 'rgba(36,53,101)')
        .style('font-size', '14px')
        .style('fill', '#0b2265')
        .text(title);
    }

    update(preprocess(chartData));
  }, []);

  return <span id={id} />;
};
