import styled from 'styled-components';

/**
 * css to create the complete bar
 */
export const CompletedBar = styled.div<{ color: string, width: string }>`
  border-radius:45px;
  opacity:0.60;
  ${(props: any) => props.color && `background-color: ${props.color};`}
  ${(props: any) => props.width && `width: ${props.width};`}
  height:7px;
  margin: 10px auto 0;
`;
/**
 * css for the each goal container
 */
export const GoalContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  border-width: 1px!important;
  border-color:#b2b2b2;
  border-style: solid;
  background-color: white;
  width:360px;
  height:120px;

  @media (max-width: 375px) {
    width: 320px;
  };
`;

/**
 * css for the  right side of the a goal
 */
export const GoalsContainerRight = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  ${(props: any) => props.color && `background-color: ${props.color};`}
  text-align:center;
  width: 100%;
`;
/**
 * css for the  left side of the a goal
 */
export const GoalsLeftContainer = styled.div<{ color: string }>`
  min-width:114px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius:10px;
  border-bottom-left-radius:10px;
  border-color:#b2b2b2;
  ${(props: any) => props.color && `background-color: ${props.color};`}
`;
/**
 * css for the  amount of points
 */
export const PointsRemaining = styled.h1<{ color: string }>`
  ${(props: any) => props.color && `color: ${props.color} !important;`}
  margin: 0;
  font-family: open-sans;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
`;
/**
 * css for the subtitle under amount of points
 */
export const PointsSubtitle = styled.h1<{ color: string }>`
  ${(props: any) => props.color && `color: ${props.color} !important;`}
  font-size:12px;
  margin:0;
  text-align:center;
  font-family:open-sans;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
`;

/**
 * css for right title
 */
export const GoalsRightTitle = styled.h1<{ color?: string }>`
  text-transform: uppercase;
  font-family: open-sans;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  text-align: center;
  margin: 10px 0;
  padding: 0;
  ${(props: any) => props.color && `color: ${props.color} !important;`}
`;
/**
 * css for right subtitle
 */
export const GoalsRightSubtitle = styled.h1<{ color: string }>`
  font-family: open-sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0 0 10px 0;
  padding: 0;
  ${(props: any) => props.color && `color: ${props.color} !important;`}
`;
/**
 * css for right paragraph
 */
export const GoalsRightParagraph = styled.p<{ color: string }>`
  display:block;
  word-wrap:break-word;
  font-family: open-sans;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  padding: 0;
  ${(props: any) => props.color && `color: ${props.color} !important;`}
`;

/**
 * css for goals section
 */

export const GoalsContainer = styled.div`
  background-color: white;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3.5px;
    background-color: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
`;

export const GoalsCheckmark = styled.img`
  color: white;
  font-style: italic;
`;

export const GoalsPointsContainer = styled.div`
  margin-bottom: -20px;
`;

export const GoalsBarContainer = styled.div`
  position: relative;
  top: 20px;
`;