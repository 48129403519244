import user from './user.reducer';
import session from './session.reducer';
import auth from './auth.reducer';
import { AUTH_STATE, SESSION_STATE, USER_STATE } from './selectors';

export default {
  [AUTH_STATE]: auth,
  [USER_STATE]: user,
  [SESSION_STATE]: session
};
