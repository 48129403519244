import * as React from 'react';
import { Switch } from 'react-router';
import { AuthRoute } from 'rsv8-auth';
import {
  AllDepartments,
  BrowseByPoints,
  CategoryLanding,
  Checkout,
  CheckoutConfirmation,
  MyFavorites,
  OrderHistory,
  ProductDetail,
  SearchResults,
  ShopLanding,
  ShoppingCart
} from 'rsv8-catalog';
import { CatalogLayout } from './app/layouts';

const getParamId = (props) => {
  return props.match.params.id;
};
const ProductDetailContainer = (productProps) => {
  return <ProductDetail id={getParamId(productProps)} {...productProps} />;
};
const ProductHistoryContainer = (orderProps) => {
  return <OrderHistory id={getParamId(orderProps)} {...orderProps} />;
};
const CatalogRoutes = (props) => {
  const { path } = props.match;
  return (
    <CatalogLayout>
      <Switch>
        <AuthRoute exact={true} path={`${path}`} component={ShopLanding} />
        <AuthRoute exact={true} path={`${path}/category`} component={AllDepartments} />
        <AuthRoute path={`${path}/category/:id`} component={CategoryLanding} />
        <AuthRoute path={`${path}/product/:id`} component={ProductDetailContainer} />
        <AuthRoute path={`${path}/favorites`} component={MyFavorites} />
        <AuthRoute path={`${path}/browse`} component={BrowseByPoints} />
        <AuthRoute exact={true} path={`${path}/history`} component={OrderHistory} />
        <AuthRoute path={`${path}/history/:id`} component={ProductHistoryContainer} />
        <AuthRoute path={`${path}/search`} component={SearchResults} />
        <AuthRoute path={`${path}/cart`} component={ShoppingCart} />
        <AuthRoute path={`${path}/checkout`} component={Checkout} />
        <AuthRoute path={`${path}/orderConfirmation`} component={CheckoutConfirmation} />
      </Switch>
    </CatalogLayout>
  );
};

export { CatalogRoutes };
