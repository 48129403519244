import { promiseDispatcher } from 'xcel-react-core';
import { createRequestActions } from 'xcel-redux-orm';
import { customFieldApi } from '../../api';
import { normalizeByKey, sortByKey } from '../helpers';
import transformField from './transformField';
import * as types from './types';

// How we transform the array of fields into a nested object
const normalizeByCustomFieldKey = normalizeByKey('id');

// The ordering of the field keys when they get stored under a form
const sortFormGroupList = sortByKey('sequence');

// The fn that turns the array of field objects into an array of keys
const trimFormGroupList = (list) => list.map((i) => i.id);

const paramsToPath = ({ formType, appKey, groupKey }) => [formType, appKey, ...groupKey];

const getCustomFields = ({ formType, groupKey, appKey }) => {
  return customFieldApi.getFormtypesByIdCustomfields({ id: formType, groupKey, appKey });
};

const addCustomFields = (fields) => ({
    type: types.ADD_CUSTOM_FIELDS,
    payload: { fields }
})

const addFieldsToForm = (params, fields) => ({
    type: types.ADD_CUSTOM_FIELDS_TO_FORM,
    payload: {
      path: paramsToPath(params),
      fields: trimFormGroupList(fields)
    }
  
});

const getFieldsRequest = (params) => (dispatch, getState) => {
  dispatch({
    type: types.GET_FORM_CUSTOM_FIELDS_REQUEST,
    payload: { ...params, path: paramsToPath(params) }
  });
  dispatch(createRequestActions(`getCustomFieldsByGroup(${paramsToPath(params).join('-')})`).request(params));
};

const getFieldsSuccess = (response, params) => {
  // Get a list of field definitions, sorted by sequence
  const fieldDefinitions = sortFormGroupList(response.map(transformField));
  const definitions = normalizeByCustomFieldKey(fieldDefinitions);
  return (dispatch) => {
    dispatch(addFieldsToForm(params, fieldDefinitions));
    dispatch(addCustomFields(definitions));
    dispatch(
      createRequestActions(`getCustomFieldsByGroup(${paramsToPath(params).join('-')})`).success(response, params)
    );
  };
};

const getFieldsFailure = (response, params) => (dispatch) => {
  dispatch({
    type: types.GET_FORM_CUSTOM_FIELDS_FAILURE,
    payload: { response, params, path: paramsToPath(params) }
  });
  dispatch(createRequestActions(`getCustomFieldsByGroup(${paramsToPath(params).join('-')})`).failure(response, params));
};

const getCustomFieldsAction = promiseDispatcher(getCustomFields, {
  request: getFieldsRequest,
  success: getFieldsSuccess,
  failure: getFieldsFailure
});

// const lazyGetCustomFields = ({ formType, groupKey, appKey }) => {
//   return (dispatch, getState) => {
//     // const myTest = getFieldGroupIsFetched(getState(), formType, groupKey, appKey);
//     // if (!myTest) {
//     dispatch(getCustomFieldsAction({ formType, groupKey, appKey }));
//     //}
//   };
// };
export default getCustomFieldsAction;
