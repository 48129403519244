import styled from 'styled-components';
const ColorPickerHexInput = styled.input`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: #555459;
  padding: 0 0.5rem;
  font-family: 'Consolas', monaco, courier, monospace;
  border: none;
  min-width: 0;
  width: calc(100% - 1.9rem - 20px);
  position: absolute;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
` as any;
export default ColorPickerHexInput;
