import * as events from './events';

const authReducer = (state, action) => {
  state =
    state === undefined
      ? {
          isValid: false,
          isPending: false,
          isAnonymous: true,
          isExpiring: false,
          isGigyaPending: true,
          doingGigyaSSOSync: false,
          isOAuth: false,
          isFirstVisit: true
        }
      : state;

  switch (action.type) {
    case events.O_AUTH_POPUP_OPEN:
    case events.AUTH_TOKEN_BASIC_CREATE_REQUEST:
    case events.AUTH_TOKEN_CREATE_REQUEST:
    case events.AUTH_CODE_CREATE_REQUEST:
    case events.O_AUTH_TOKEN_CREATE_REQUEST:
    case events.O_AUTH_TOKEN_REFRESH_REQUEST:
    case events.AUTH_TOKEN_REFRESH_REQUEST: {
      return { ...state, isPending: true };
    }
    case events.AUTH_GIGYA_SSO_SYNC_REQUEST: {
      return { ...state, isGigyaPending: true, doingGigyaSSOSync: true };
    }
    case events.AUTH_TOKEN_BASIC_CREATE_SUCCESS:
    case events.AUTH_TOKEN_CREATE_SUCCESS:
    case events.O_AUTH_TOKEN_CREATE_SUCCESS:
    case events.O_AUTH_TOKEN_REFRESH_SUCCESS:
    case events.AUTH_CODE_CREATE_SUCCESS:
    case events.AUTH_TOKEN_REFRESH_SUCCESS: {
      // don't let Basic win the race condition
      if (action.type === events.AUTH_TOKEN_BASIC_CREATE_SUCCESS && state.isPending === false) {
        return { ...state };
      } else {
        const { payload } = action;
        let auth = {
          ...state,
          ...{ ...payload, isSSO: action.isSSO === true },
          isValid: true,
          isPending: false,
          isGigyaPending: false,
          isAnonymous: action.type === events.AUTH_TOKEN_BASIC_CREATE_SUCCESS,
          isOAuth: action.type === events.O_AUTH_TOKEN_CREATE_SUCCESS || action.type === events.O_AUTH_TOKEN_REFRESH_SUCCESS
        };
        return auth;
      }
    }

    case events.AUTH_GIGYA_SSO_SYNC_SUCCESS: {
      return { ...state, isGigyaPending: true, doingGigyaSSOSync: false };
    }
    case events.AUTH_GIGYA_SSO_SYNC_FAILURE: {
      return { ...state, isGigyaPending: false, doingGigyaSSOSync: false };
    }
    case events.AUTH_TOKEN_BASIC_CREATE_FAILURE:
    case events.AUTH_TOKEN_CREATE_FAILURE:
    case events.AUTH_CODE_CREATE_FAILURE:
    case events.O_AUTH_TOKEN_CREATE_FAILURE:
    case events.AUTH_TOKEN_REFRESH_FAILURE:
    case events.USER_LOGOUT: {
      let auth = {
        isValid: false,
        isPending: false,
        isOAuth: action.type === events.O_AUTH_TOKEN_CREATE_FAILURE || action.type === events.O_AUTH_TOKEN_REFRESH_FAILURE
      };
      return auth;
    }
    case events.AUTH_TOKEN_EXPIRE_MODAL_OPEN:
      return { ...state, isExpiring: true };
    case events.AUTH_TOKEN_EXPIRE_MODAL_CLOSE:
      return { ...state, isExpiring: false };
    case events.AUTH_FIRST_VISIT_CHANGE:
      return { ...state, isFirstVisit: action.payload };
    default: {
      return state;
    }
  }
};
export default authReducer;
