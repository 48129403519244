import * as events from '../../events';
import { cartSelector } from '../../selectors';
import { cartApiActions } from './cartApiActions';
const getCart = () => async (dispatch, getState) => {
  try {
    const response = await dispatch(cartApiActions.getCart());
    dispatch({ type: events.SET_CART, payload: cartSelector.selectLast(getState(), 'getCart') });

    return response;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: events.SET_CART, payload: [] });
    }
    throw e;
  }
};

export { getCart };
