import * as React from 'react';
import {
  ProductDetailImage,
  SelectedImage,
  SelectedImageContainer,
  ThumbnailImage,
  ThumbnailImageContainer,
  ThumbnailViewer
} from './styles';

export interface ImageViewerProps {
  product: any;
  handleChange: any;
  currentImage: any;
}

class ImageViewer extends React.Component<ImageViewerProps, any> {
  state = {
    imageLoaded: false
  };

  render() {
    const { product, handleChange, currentImage } = this.props;
    return (
      <ProductDetailImage>
        <ThumbnailViewer>
          <ThumbnailImageContainer
            onClick={handleChange(product.imageLg)}
            image={product.imageLg}
            currentImage={currentImage}
          >
            <ThumbnailImage src={product.imageLg} />
          </ThumbnailImageContainer>
          {product.alternateImages.filter((image) => image.imageLg !== product.imageLg).map((image, i) => (
            <ThumbnailImageContainer
              onClick={handleChange(image.imageLg)}
              image={image.imageLg}
              currentImage={currentImage}
            >
              <ThumbnailImage src={image.imageLg} />
            </ThumbnailImageContainer>
          ))}
        </ThumbnailViewer>
        <SelectedImageContainer>
          <SelectedImage
            loaded={this.state.imageLoaded}
            onLoad={() => this.setState({ imageLoaded: true })}
            src={currentImage}
          />
        </SelectedImageContainer>
      </ProductDetailImage>
    );
  }
}

export default ImageViewer;
