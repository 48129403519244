import * as React from 'react';
import { FormGroup } from 'react-bootstrap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { connect } from 'react-redux';
import { Label, ReactField } from 'rsv8-forms';
import { withErrorBoundary } from 'xcel-react-core';
import ContentCultureDropdown from '../../../components/Dropdown/ContentCulture';
import ProviderDropdown from '../../../components/Dropdown/Provider';
import VersionDropdown from '../../../components/Dropdown/Version';
import UnpublishButton from '../../../components/UnpublishButton';
import { contentActions, contentVersionActions } from '../../../redux/actions';
import {
  contentDetailSelector,
  contentSelector,
  contentTypeSelector,
  contentVersionSelector
  } from '../../../redux/selectors';
import { ContentVersionModel } from '../../../redux/selectors/contentVersion';
import { ContentModel } from '../../../redux/types/content';
import moment = require('moment')

export interface ContentPropertiesProps {
  version?: ContentVersionModel;
  content?: ContentModel;
  contentTypeId?: any;
  edit?: any;
  dispatch?: any;
}

class ContentProperties extends React.Component<ContentPropertiesProps> {
  state = { key: '' };
  static getDerivedStateFromProps(props: ContentPropertiesProps, state: any) {
    if (props.content && props.content.key !== state.key) {
      return { key: props.content.key };
    }
    return state;
  }
  componentDidMount() {
    const { content } = this.props;
    if (content) {
      this.setState({ key: content.key });
    }
  }
  handleContentVersionPropertyChange = (property) => (data) => {
    const { dispatch } = this.props;
    dispatch(contentVersionActions.setProperty({ [property]: data }));
    // actions.contentFormChange({ [property]: data });
  };
  handleContentPropertyChange = (property) => (value) => {
    this.setState({ [property]: value });
  };
  handleContentPropertyBlur = (property) => (e) => {
    const { content = {} as ContentModel, contentTypeId, dispatch } = this.props;

    dispatch(contentActions.patch(contentTypeId, content.id, { [property]: this.state[property] }));
  };

  getDateTime = (value: string) => value ? moment(value).format('YYYY-MM-DD') : '';

  render() {
    const { version = {} as ContentVersionModel, edit } = this.props;
    return (
      <div>
        <VersionDropdown />
        <ReactField
          component="bs-input"
          type="text"
          label="Content Key"
          value={this.state.key}
          onChange={this.handleContentPropertyChange('key')}
          onBlur={this.handleContentPropertyBlur('key')}
        />

        <ReactField
          component="bs-input"
          type="text"
          label="Title"
          value={edit.title}
          onChange={this.handleContentVersionPropertyChange('title')}
        />
        <ReactField
          component="bs-input"
          type="text"
          label="Description"
          value={edit.description}
          onChange={this.handleContentVersionPropertyChange('description')}
        />
        <ReactField component="bs-input" type="text" label="Updated By" value={version.updatedBy} disabled="disabled" />

        <ReactField
          component="bs-input"
          type="text"
          label="Publish Status"
          value={version.publishStatus}
          disabled="disabled"
        />
        <ContentCultureDropdown />
        <ProviderDropdown />

        <FormGroup>
          <Label>Publish Date</Label>
          <Datetime
            locale="en"
            dateFormat={'YYYY-MM-DD'}
            timeFormat={false}
            onChange={this.handleContentVersionPropertyChange('publishedDateTimeUtc')}
            value={this.getDateTime(version.publishedDateTimeUtc)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Unpublish Date</Label>
          <Datetime
            locale="en"
            dateFormat={'YYYY-MM-DD'}
            timeFormat={false}
            onChange={this.handleContentVersionPropertyChange('unPublishedDateTimeUtc')}
            value={this.getDateTime(version.unPublishedDateTimeUtc)}
          />
        </FormGroup>

        <UnpublishButton />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    content: contentDetailSelector.getActive(state, contentTypeId),
    edit: contentSelector.getEdit(state, contentTypeId),
    version: contentVersionSelector.getActiveVersion(state, contentTypeId) || {}
  };
};
export default connect(mapStateToProps)(withErrorBoundary()(ContentProperties));