import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CaptionImage, Image, Toastify } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { AwardImage, NomineeImage } from '../../components/index';
import { individualNominationSelectors } from '../../redux/selectors/index';

const DisplayContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
` as any;

const IconContainer = styled.div`
  margin: 0 10px;
` as any;

const NominationDisplay = ({ nominee, award, selectNomineeText, save, showNominationIcon }: any) => {
  return (
    <DisplayContainer>
      {nominee ? (
        <IconContainer>
          <NomineeImage nominee={nominee} width={'80px'} caption={true} />
        </IconContainer>
      ) : (
        <Image themeVariation="no-nominee-image" />
      )}
      {award && showNominationIcon === 'true' && (
        <IconContainer>
          <CaptionImage component={AwardImage} width={'auto'} height={'80px'} object={award}>
            {award.name}
          </CaptionImage>
        </IconContainer>
      )}
      <Toastify hideProgressBar={true} autoClose={2500} />
    </DisplayContainer>
  );
};

const mapState = (state) => {
  const nominee = individualNominationSelectors.getCurrentNominee(state);
  const award = individualNominationSelectors.getCurrentAward(state);
  return {
    nominee: nominee !== null ? nominee : false,
    award: award !== null ? award : false
  };
};

const mapResource = (getResource) => ({
  selectNomineeText: getResource('nominees.selectNomineeText', 'Select a Nominee'),
  showNominationIcon: getResource('nominations.showNominationIcon', 'true')
});

export default compose<any>(
  connect(
    mapState,
    null
  ),
  withResource(mapResource)
)(NominationDisplay);
