import * as React from 'react';

// TODO: Replace hardcoded fill.
const CaretIcon = (props) => {
  const fillColor = props.dark ? '#606060' : '#ffffff';
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15">
      <title>carrot</title>
      <g id="Layer_2" data-name="Layer 2">
        <path
          fill={fillColor}
          d="M5,4.39a.4.4,0,0,1,.13-.31.43.43,0,0,1,.31-.14.45.45,0,0,1,.32.14L8.87,7.19a.43.43,0,0,1,0,.62L5.76,10.92a.45.45,0,0,1-.32.14.43.43,0,0,1-.31-.14A.4.4,0,0,1,5,10.61V4.39Z"
        />
      </g>
    </svg>
  );
};

export default CaretIcon;
