import * as React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelBody } from 'rsv8-components';
import styled from 'styled-components';
import { register, withResource } from 'xcel-react-core';
import { nomineeSelectors } from '../../../redux/selectors/index';
import { Resources } from '../../../types';
import { NomineeImage } from '../../NomineeImage/index';
import { TextRow } from '../../TextRow/index';
import { LoadingContainer, NomineeRowProps, NomineeStatusIcon } from '../Shared/index';
import { FlexRow } from './styles';

const NomineePanelContainer = LoadingContainer.withComponent(Panel as any);

const StyledNomineeStatusIcon = styled(NomineeStatusIcon)`
  position: absolute;
`;

interface InternalProps extends NomineeRowProps {
  nominee: Resources.Nominee;
  confirmText: string;
  text: { supervisor: string; department: string };
}

class NomineePanel extends React.Component<InternalProps, any> {
  onClick = (e) => this.props.selectNominee(this.props.nominee.id.toString(), this.props.confirmText);
  render() {
    const { nominee, text, loading, active } = this.props;
    return (
      <NomineePanelContainer onClick={this.onClick} loading={loading}>
        <PanelBody>
          <FlexRow>
            <StyledNomineeStatusIcon loading={loading} active={active} />
            <NomineeImage nominee={nominee} width={'50px'} caption={true} />
          </FlexRow>
          <TextRow head={text.supervisor}>
            {nominee.managerFirstName} {nominee.managerLastName}
          </TextRow>
          <TextRow head={text.department}>{nominee.organizationName}</TextRow>
        </PanelBody>
      </NomineePanelContainer>
    );
  }
}

const mapState = (state, ownProps) => ({
  nominee: nomineeSelectors.selectOne(state, ownProps.userId)
});

const mapResource = (getResource) => ({
  text: {
    supervisor: getResource('nominees.supervisorHeaderLabel', 'Supervisor'),
    department: getResource('nominees.departmentHeaderLabel', 'Department')
  },
  confirmText: getResource(
    'nominees.confirmNomineeChangeText',
    'Changing nominee at this point will clear data from later steps.'
  ),
  thresholdErrorText: getResource('nominees.thresholdErrorText', `Chosen points will exceed nominee's threshold.`)
});

const compose = register('rsv8-nomination/NomineePanel');

export default compose(
  connect(
    mapState,
    null
  ),
  withResource(mapResource)
)(NomineePanel) as React.SFC<NomineeRowProps>;
