import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { Dashboard, GoalDto, Performance, Program } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getCurrencyValue } from '../../../utils';
import { GoalContent, GoalType, ProgramName } from '../../constants';
import { getProgram, getSummary } from '../../redux/actions';
import { dashboardSelector, performanceSelector, programSelector } from '../../redux/selectors';
import { OverlayPopover } from '../Overlay';
import { PlaceHolderBar } from './PlaceHolderBar';
import { BarCellLabel, BarCellValue, BarToggle, RebateBarCell, RebateBarContainer, Wrapper } from './styles';

const Goal: React.FC<{
  goal: GoalDto;
  index: number;
  goalsContent?: GoalContent[];
}> = ({ goal, index, goalsContent }) => {
  const goalContent = goalsContent ? goalsContent.find((e) => e.id === goal.id) : undefined;
  const goalTitle = goalContent ? goalContent.goalTitle : `GOAL #${index + 1}`;
  const goalTitleColor = goalContent ? goalContent.goalTitleColor : undefined;
  return (
    <RebateBarCell key={index} className={`rebate-bar__goal-${index + 1}`}>
      <BarCellLabel color={goalTitleColor}>{goalTitle}</BarCellLabel>

      <BarCellValue key={'units-cell' + index}>{goal.targetUnits}</BarCellValue>
    </RebateBarCell>
  );
};

const SalesRebateBar: React.FC<{
  performance: Performance;
  goalsContent?: GoalContent[];
  mySortedGoals: GoalDto[];
}> = ({ performance, goalsContent, mySortedGoals }) => (
  <>
    <RebateBarCell className="rebate-bar__points">
      <BarCellLabel>YTD UNITS</BarCellLabel>
      <BarCellValue>{performance.current.ytdUnits}</BarCellValue>
    </RebateBarCell>

    {mySortedGoals
      .filter((goal) => goal.goalType === GoalType.Units)
      .map((goal, index) => (
        <Goal goal={goal} index={index} goalsContent={goalsContent} />
      ))}
  </>
);

const UnitsRebateBar: React.FC<{
  performance: Performance;
  goalsContent?: GoalContent[];
  mySortedGoals: GoalDto[];
}> = ({ performance, goalsContent, mySortedGoals }) => (
  <>
    <RebateBarCell className="rebate-bar__points">
      <BarCellLabel>UNITS</BarCellLabel>
      <BarCellValue>{performance.current.units}</BarCellValue>
    </RebateBarCell>

    {mySortedGoals.map((goal: any, index: any) => (
      <Goal goal={goal} index={index} goalsContent={goalsContent} />
    ))}
  </>
);

const MarketShareRebateBar: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => (
  <>
    <RebateBarCell className="rebate-bar__goal-1">
      <BarCellLabel>
        {dashboard.baselineTimePeriod.quarter != 1 ? 'Q1 - ' : ''}
        {dashboard.baselineTimePeriod.name} SALES
      </BarCellLabel>
      <BarCellValue>{getCurrencyValue(dashboard.performance.baseline.sales)}</BarCellValue>
    </RebateBarCell>

    <RebateBarCell className="rebate-bar__goal-2">
      <BarCellLabel>
        {dashboard.currentTimePeriod.quarter != 1 ? 'Q1 - ' : ''}
        {dashboard.currentTimePeriod.name} SALES
      </BarCellLabel>
      <BarCellValue>{getCurrencyValue(dashboard.performance.current.sales)}</BarCellValue>
    </RebateBarCell>
  </>
);

const RebateBar: React.FC<Props> = ({ dashboard, performance, program, goalsContent, infoButtonText, className }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  if (!dashboard || !program) {
    return <PlaceHolderBar collapsed={collapsed} className={className} />;
  }

  const mySortedGoals = dashboard.infoBarGoals
    ? Array.isArray(dashboard.infoBarGoals)
      ? dashboard.infoBarGoals.filter((goal) => goal.displayInInfobar).sort((a, b) => a.sequence - b.sequence)
      : [dashboard.infoBarGoals]
    : [];

  return (
    <div className={className}>
      <RebateBarContainer collapsed={collapsed}>
        <Wrapper>
          <OverlayPopover id="popover-dashboard-title" placement="bottom">
            {infoButtonText}
          </OverlayPopover>

          <div className="rebate-bar__content">
            <RebateBarCell className="rebate-bar__q">
              <BarCellLabel>QUARTER</BarCellLabel>
              <BarCellValue>{dashboard.currentTimePeriod.name}</BarCellValue>
            </RebateBarCell>

            {(ProgramName.match(ProgramName.OptiPort, program) || ProgramName.match(ProgramName.VisionSource, program)) && (
              <SalesRebateBar performance={performance} goalsContent={goalsContent} mySortedGoals={mySortedGoals} />
            )}
            {(ProgramName.match(ProgramName.Magnifeye, program) || ProgramName.match(ProgramName.Platinum, program)) && (
              <UnitsRebateBar performance={performance} goalsContent={goalsContent} mySortedGoals={mySortedGoals} />
            )}
            {(ProgramName.match(ProgramName.MarketShare, program) || ProgramName.match(ProgramName.TotalPartner, program)) && (
              <MarketShareRebateBar dashboard={dashboard} />
            )}

            <RebateBarCell className="rebate-bar__projected-rebate">
              <BarCellLabel>PROJECTED REBATE</BarCellLabel>
              <BarCellValue>{getCurrencyValue(dashboard.performance.current.rebate)}</BarCellValue>
            </RebateBarCell>
          </div>

          <div className="rebate-bar__content">
            <RebateBarCell className="rebate-bar__run-rate">
              <BarCellLabel>RUN RATE ESTIMATED REBATE</BarCellLabel>
              <BarCellValue>{getCurrencyValue(performance.runrate.rebate)}</BarCellValue>
            </RebateBarCell>

            <RebateBarCell className="rebate-bar__target">
              <BarCellLabel>TARGET ESTIMATED REBATE</BarCellLabel>
              <BarCellValue>{getCurrencyValue(performance.target.rebate)}</BarCellValue>
            </RebateBarCell>

            <RebateBarCell className="rebate-bar__custom">
              <BarCellLabel>CUSTOM ESTIMATED REBATE</BarCellLabel>
              <BarCellValue>{getCurrencyValue(performance.custom.rebate)}</BarCellValue>
            </RebateBarCell>
          </div>
        </Wrapper>

        <BarToggle collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
          {collapsed && <div className="dashboard-bar__toggle-label">QUICKSTATS</div>}

          <div className="dashboard-bar__toggle-icon">
            {collapsed && <Icon name="angle-down" />}
            {!collapsed && <Icon name="angle-up" />}
          </div>
        </BarToggle>
      </RebateBarContainer>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  goalsContent: getContent('goalsContent', {
    type: 'array',
    schema: [
      { model: 'id', label: 'Goal key' },
      { model: 'goalTitle', label: 'Title of the goal' },
      { model: 'goalTitleColor', label: 'Color of title of the goal' },
    ],
  }),
  infoButtonText: getContent('infoButtonText', {
    type: 'string',
    schema: [{ model: 'Add info button text', label: 'add info button text' }],
  }),
});

const mapStateToProps = (state) => ({
  dashboard: dashboardSelector.selectMany(state)[0],
  performance: performanceSelector.selectMany(state)[0],
  program: programSelector.selectMany(state)[0],
});

interface StateProps {
  dashboard: Dashboard;
  program?: Program;
  performance: Performance;
}
interface ContentProps {
  goalsContent?: GoalContent[];
  infoButtonText: string;
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('alcon-information/RebateBar')(
  connect<StateProps>(mapStateToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withApiDataProvider(getProgram, programSelector),
  withTheme(),
  withContent(mapContentToProps)
)(RebateBar);
