import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { InputField, InputWrapper, StyledCopyIcon } from '../styles';

export interface ShareInputTextProps {
  onLeave: () => void;
  value: string;
}

export class ShareInputText extends React.PureComponent<ShareInputTextProps> {
  inputRef: HTMLInputElement = null;

  componentDidMount() {
    if (this.inputRef) {
      this.inputRef.select();
    }
  }

  render() {
    return (
      <InputWrapper className="input-wrapper" onMouseLeave={this.props.onLeave}>
        <InputField
          className="input-field"
          innerRef={(ref) => (this.inputRef = ref)}
          type="text"
          value={this.props.value}
          disabled={true}
        />
        <CopyToClipboard text={this.props.value}>
          <StyledCopyIcon />
        </CopyToClipboard>
      </InputWrapper>
    );
  }
}
