import * as React from 'react';
import { onlyUpdateForKeys } from 'recompose';
import { replaceKeys } from 'xcel-util';

const JSONBodyBase: React.SFC<{ whitelist: string[]; json: object }> = ({
  whitelist,
  json
}) => <pre>{JSON.stringify(json, replaceKeys(whitelist), 2)}</pre>;

export const JSONBody = onlyUpdateForKeys(["json"])(JSONBodyBase);
