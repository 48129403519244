import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cultureSelector } from 'rsv8-client';
import { withWizard } from 'rsv8-wizard';
import { register, withResource } from 'xcel-react-core';
import { ProductSelection } from '../../components/index';
import { ProductSelectionProps } from '../../components/Product/ProductSelection';
import { teamNominationActions } from '../../redux/actions/index';
import {
  nominationSelectors,
  teamNominationSelectors
} from '../../redux/selectors/index';
import { Resources } from '../../types/index';

const {
  selectProduct,
  getProductDetails,
  getProducts,
  selectProductOptionId,
  selectProductOptionPointValue
} = teamNominationActions;

interface TeamProductSelectionProps extends ProductSelectionProps {
  product: Resources.ProductAttributes;
}

class TeamProductSelect extends React.Component<TeamProductSelectionProps, any> {
  constructor(props: TeamProductSelectionProps) {
    super(props);
  }

  render() {
    return <ProductSelection {...this.props} />;
  }
}

const mapState = (state) => {
  let currentProductOptionId = teamNominationSelectors.getCurrentOptionId(state);
  let currentProductId = teamNominationSelectors.getCurrentProductId(state);
  let product = teamNominationSelectors.getCurrentProduct(state);

  if (!currentProductId) {
    const info = teamNominationSelectors.getProductInfoFromDraft(state);
    if (info && info.product) {
      product = info.product;
      currentProductId = info.productId;
      currentProductOptionId = info.optionId;
    }
  }

  return {
    nominee: null,
    selectedOptionId: currentProductOptionId,
    selectedProduct: currentProductId,
    product: product,
    requestData: nominationSelectors.getNomineeProductsRequest(state),
    cultureId: cultureSelector.getActiveCultureId(state)
  };
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    { getProducts, selectProduct, getProductDetails, selectProductOptionId, selectProductOptionPointValue },
    dispatch
  )
});

const mapResource = (getResource) => ({
  confirmProductChangeText: getResource(
    'teamNomination.confirmProductChangeText',
    'Changing product at this point will clear data from later steps.'
  )
});

const compose = register('rsv8-nomination/TeamNominationProductSelection');

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource),
  withWizard()
)(TeamProductSelect) as React.SFC<{ products: Array<Resources.Product> }>;
