import { Program } from "xcel-api-generator/dist/alcon";
import { createTypeSelector } from "xcel-redux-orm";
import { ProgramType } from "../../constants";

export const programSelector = createTypeSelector<Program>("program");
export const programBannersSelector = createTypeSelector<any>("banner");

export const programKeySelector = (state) => {
  const program = programSelector.selectMany(state)[0];
  return program && program.programKey ? program.programKey : undefined;
};

export const baselineYearLabelSelector = (state) => {
  const program = programSelector.selectMany(state)[0];
  let baselineYearLabel = "2019";

  if (!program) {
    return undefined;
  }

  let programConfiguration = program.programConfiguration;
  if (typeof programConfiguration === "string") {
    programConfiguration = JSON.parse(programConfiguration);
  }

  if (programConfiguration && typeof programConfiguration === "object") {
    baselineYearLabel = programConfiguration.baselineYearLabel;
  }
  return baselineYearLabel;
};

export const unitLabelSelector = (state) => {
  const programKey = programKeySelector(state);
  switch (programKey) {
    case ProgramType.Point:
      return 'points';
    case ProgramType.Sales:
    case ProgramType.MarketShare:
      return 'sales*';
    case ProgramType.Units:
      return 'units';
    default:
      return '';
  }
};
