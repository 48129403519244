import * as React from 'react';

const VideoDisplay = (props) => {
  const { videoSrc } = props;
  return (
    <div>
      <video width="100%" height="100%" controls>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoDisplay;
