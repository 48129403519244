import {
  Alert,
  ControlLabel,
  Form,
  FormControl,
  FormGroup
  } from 'react-bootstrap';
import * as Icon from 'react-fontawesome';
import { Button } from 'rsv8-components';
import styled from 'styled-components';

export const FilterPointsButton = styled(Button)`
  align-self: flex-end;
  background-color: #b5be35;
  border-radius: 3px;
  border: 0;
  color: #fff;
  font-family: helvetica;
  height: 34px;
  width: 137px;

  &[disabled]:hover {
    background-color: #b5be35;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #b5be35;
    color: #fff;
  }
` as any;

export const FormGroupDivider = styled.span`
  align-items: center;
  align-self: flex-end;
  background: transparent;
  border: 0px;
  display: flex;
  height: 34px;
  justify-content: center;
  padding: 5px 10px;
  @media (max-width: 768px) {
    display: none;
  }
` as any;

export const PointFilterFormGroup = styled(FormGroup)`
  margin: 0;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
` as any;

export const ErrorIcon = styled(Icon)`
  bottom: ${(props: any) => (props.isFilter ? `5px` : `7px`)};
  color: #d8374b;
  position: absolute;
  right: 5px;
  font-size: 1.5em;
  -webkit-text-stroke: 2px white;
` as any;

export const FilterFormControl = styled(FormControl)`
  width: 100% !important;

  ${(props: any) => props.isFilter && props.hasError && (`padding-right: 25px !important` as any)};
` as any;

export const PointFilterForm = styled(Form)`
  display: flex;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
    flex-wrap: wrap;
  }

  &.danger {
    ${FilterFormControl} {
      border: solid 1px #db504d;

      &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(219, 80, 77, 0.6);
      }
    }
  }
` as any;

export const PointFilterControlLabel = styled(ControlLabel)`
  display: block;
  margin-bottom: 5px;
` as any;

export const Error = styled(Alert)`
  align-items: center;
  border: solid 1px #d8374b;
  color: #333;
  display: flex;
  flex-direction: column;
  margin: 0 auto 20px auto;
  max-width: 675px;
  width: 75%;
` as any;
