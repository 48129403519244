import * as React from 'react';
import styled from 'styled-components';
import { SalesNavProps } from './types';

const NavLink = styled.a`
  cursor: pointer;
`;

export const NavItems: React.FunctionComponent<SalesNavProps> = ({ actions: { setSalesApp }, applications, qs }) => (
  <React.Fragment>
    {applications &&
      applications.map((application) => (
        <li
          key={application.appKey}
          onClick={() => setSalesApp({ appKey: application.appKey })}
          className={application.appKey === qs.appKey ? 'nav-link-selected' : ''}
        >
          <NavLink>{application.name}</NavLink>
        </li>
      ))}
  </React.Fragment>
);
