
  import { bindActionCreators } from 'redux';
  import {getGoals,getMetrics} from '../../../redux/actions/index';
  /**
   * Creates test metric data so that you can see what it could look like
   * @param MetricsObjectTemplate 
   * @param TempStatus 
   */
   const createMetricObjectsUsingStatus = (status:string, statusObject: Object) => {
    
    let obj = {};
    obj[status] = statusObject;;
    return obj;
  }
  
  /**
   * Creates fake metrics
   * @param MetricsObjectTemplate 
   * @param TempStatus 
   */
  const createFakeMetrics = (MetricsObjectTemplate: Object,myTempStatus: Array<any>) => {
      let returnMe = [];
      var arrayLength = myTempStatus.length;
      if(arrayLength > 0) {
      // loop through the array
      for (var i = 0; i < arrayLength; i++) {
        //  get the status stored in the array
          var key = myTempStatus[i].status;
         
          // and add to return array the new Metrics object
             returnMe.push({[key]:MetricsObjectTemplate});
        }
      }
      if(arrayLength <= 0) {
  
        returnMe.push( createMetricObjectsUsingStatus("None",MetricsObjectTemplate));
  
      };
  
    return returnMe;
  }
  
  /**
   * Returns a series Object
   * @param TempObject 
   * @param TempStatus 
   */
  const createBarSeriesObjectPerStatus = (TempObject:any,myTempStatus:string,ColorOfBar:string) => {
    // Remove this before submitting
    // simpling pritting out created series
      let returnMe = null;
      console.log(TempObject)
      // let series =[];
      if( TempObject[myTempStatus]) {
    TempObject[myTempStatus].forEach(element => {
      if(element) {
        if(element.id ==="TOTAL") {    
      returnMe = {
        stacking:'normal',
        groupPadding: 0,
        pointPadding: 0,
        name: myTempStatus,
        type: 'bar',
        data: [Number(element.attributes.value),null],
        color:ColorOfBar,
        borderRadius: 0,
        pointInterval:1,  
        maxPointWidth:20,
        
      }
    }
  }
  });
    }
    
    if(returnMe!=null) {
      return returnMe;
    }
  }
  /**
   * maps functions to props that we use to get metrics
   * @param dispatch 
   */
  const mapDispatchToProps = (dispatch) => ({
    getGoals: bindActionCreators(getGoals, dispatch),
    getMetrics:bindActionCreators(getMetrics,dispatch),
  });
  
  /**
   * Gets the name of the first and second bar
   * @param getResource 
   */
  const mapResourceToProps = (getResource) => {
    return {
      GoalsProgressBarFirstCategory: getResource(' GoalsProgressBar.firstCategory', 'ACTUAL'),
      GoalsProgressBarSecondCategory: getResource('GoalsProgressBar.SecondCategory', 'GOAL'),
    };
  };
  
  /**
   * This creates the input from cms where we get the color and status
   * @param getContent 
   */
  const mapContentToProps = (getContent) => ({
    ProgressBarStatusandColors: getContent('ProgressBarStatusandColors', {
      type: 'array',
      schema: [
        { model: 'color', label: 'Color' },
        { model: 'status', label: 'Status' },
      ]
    }),
    GoalsColor: getContent('GoalsColor', {
      type: 'String',
      label:'Color of Goals Bar'
    }),
    ProgressBarYaxisMarkers: getContent('ProgressBarYaxisMarkers', {
      type: 'array',
      schema: [
        { model: 'label', label: 'Your Label' },
        { model: 'start', label: 'Starting Value' },
        { model: 'end', label: 'Ending Value' },
      ]
    }),
    GoalsMax: getContent('GoalsMax', {
      type: 'String',
      label:'The maximum number metrics will have'
    }),
    GoalsMin: getContent('GoalsMin', {
      type: 'String',
      label:'The minimum number metrics will have'
    }),
    GoalsIncrement: getContent('GoalsIncrement', {
      type: 'String',
      label:'Set value to change increment'
    }),
    GoalsBuffer: getContent('GoalsBuffer', {
      type: 'String',
      label:'Number that will add a buffer to your max'
    }),
    GoalsBarSize: getContent('GoalsBarSize', {
      type: 'String',
      label:'How much space/Width your bar will tak on the page'
    }),
      programKey: getContent('programKey', {type: 'none', label: 'ProgramKey'}),
  calculatorKey: getContent('calculatorKey', {type: 'none', label: 'CalculatorKey'}),
  flipped: getContent('flipped', {type: 'String', label: 'true or false (expected value)'})
  });
  /**
   * Creates progress bar options
   * @param props 
   */
  const createProgressBar = (props:any)=> {
   // check for width and maxwidth
   var graphwidth  =window.innerWidth* .8;
    if (props.GoalsBarSize) {
      graphwidth = Number(props.GoalsBarSize.replace(/[^\d\.\-]/g, ''));   
    }
  // Create options
    const options: Highcharts.Options = {
        title: {
            text:null,
          },
          chart: {
            type: 'bar',
            height:window.innerHeight*.12,
            width:graphwidth,
            backgroundColor: null,
            
          }, 
          plotOptions: {
            candlestick: {
              lineColor: '#404048'
          },
        },
          credits:{
           enabled: false,
          },
          tooltip:{
            enabled:false,
          },
    
          legend: {
              enabled:false,},
    
          navigation: {
            buttonOptions: {
              enabled: false
            }
          },
          xAxis: {
            categories: [props.GoalsProgressBarFirstCategory,props.GoalsProgressBarSecondCategory],
            minorTickInterval: 'auto',
            labels: {
              useHTML: true,
                style: {
                  fontFamily:null,
                  fontSize: '10px',
                  fontWeight: 'bold',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: '1px',
                  color:'#414141',
                }
              },
          
        },
          yAxis: {
            title:null,
            visible: true,
            gridLineColor:'transparent',
            min:0,
            max:5000,
            startOnTick:true,
            tickInterval:100,
            labels:{
                 formatter:function() {
                  var myvalue =null;
                  if(props.GoalsValueString) {
                    myvalue = this.value+ " " + props.GoalsValueString
                  }
                  if(!props.GoalsValueString) {
                     myvalue = this.value + " POINTS ";
                  }
                  if(props.ProgressBarYaxisMarkers) {
                    props.ProgressBarYaxisMarkers.forEach(
                      element=> {
                        if(element>element.start || element< element.end) {
                          myvalue=element.label;
                        } 
                      }
                  )
                  }
                
                  return myvalue;
                },
               x:-20,
                useHTML: true,
                align: 'center',
                style:{
                  fontFamily:null,
                   fontSize:'10px',
                   fontStretch: 'normal',
                   fontStyle: 'normal',
                   lineHeight: 'normal',
                   letterSpacing: '1px',
                   textAlign: 'center',
                   color: '#757575',
                
                }
              
            }
          },
         
    }
    return options
   
    } 

  export{createBarSeriesObjectPerStatus,createFakeMetrics,createMetricObjectsUsingStatus,mapDispatchToProps,mapContentToProps,mapResourceToProps,createProgressBar}