import { queryString } from 'xcel-util';
const createEventDispatch = (type, fn) =>
  function(...args: any[]) {
    return (dispatch, getState) => {
      const request = (payload) => ({ type: `${type}_REQUEST`, request: payload });
      const success = (payload) => ({ type: `${type}_SUCCESS`, response: payload });
      const failure = (payload) => ({ type: `${type}_FAILURE`, error: payload });
      dispatch(request.apply(this, args));
      const result = fn.apply(this, args);
      return new Promise((resolve, reject) => {
        let response = result;
        if (!result.then) {
          response = result(dispatch, getState);
        }
        response
          .then((data) => {
            dispatch(success(data));
            resolve(data);
          })
          .catch((error) => {
            dispatch(failure(error));
            console.error(error);
          });
      });
    };
  };

let paramsObjectToQueryString = (params, parent) => {
  parent = parent ? parent + '.' : '';
  return Object.keys(params)
    .map((param) => {
      let value = params[param];
      let response = `${parent}${param}=${params[param]}`;
      if (!Array.isArray(value) && typeof value === 'object') {
        response = paramsObjectToQueryString(value, `${parent}${param}`);
      }
      if (Array.isArray(value)) {
        response = value.map((item) => `${parent}${param}=${item}`).join('&');
      }
      return response;
    })
    .join('&');
};

let setSearchObjectToQueryString = (name, data) => {
  let foo = Object.keys(data[name])
    .filter((key) => data[name][key])
    .map((key) => escape(key));

  return queryString.setParam(name, foo);
};
let setSearchFormToQueryString = (data) => {
  Object.keys(data).map((key) => {
    if (key === 'selectedCheckbox') {
      setSearchObjectToQueryString('points', data.selectedCheckbox);
      setSearchObjectToQueryString('brands', data.selectedCheckbox);
    } else {
      return queryString.setParam(`${key}`, escape(data[key]));
    }
  });
};
export { createEventDispatch, setSearchFormToQueryString, setSearchObjectToQueryString, paramsObjectToQueryString };
