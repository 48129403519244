import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ModalAnchored } from 'rsv8-components';
import { HeaderOne, HeaderTwo, Text } from 'rsv8-components';
import { formSelectors } from 'rsv8-forms';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import WizardFormPage from './WizardFormPage';

const StyledHeaderTwo = styled(HeaderTwo)`
  margin: 0 !important;
  padding: 0 5px !important;
  text-align: center;
` as any;

const StyledRow = styled(Row)`
  margin: 1.5em !important;
` as any;

const BreadCrumbContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5em !important;
  .fa-long-arrow-down {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: block;
    .fa-long-arrow-right {
      display: none;
    }
    .fa-long-arrow-down {
      display: block;
    }
  }
` as any;

const BreadCrumbs = ({ className, page, topics }) => {
  const topicsRendering = topics.map((topic, i) => {
    if (topic.label) {
      return (
        <React.Fragment key={topic.id}>
          <div className={'p-' + (page >= i)}>
            <StyledHeaderTwo>{topic.label}</StyledHeaderTwo>
          </div>
          {i !== topics.length - 1 && (
            <React.Fragment>
              <div className={'p-' + (page >= i)}>
                <StyledHeaderTwo>
                  <i className="fa fa-long-arrow-right" />
                  <i className="fa fa-long-arrow-down" />
                </StyledHeaderTwo>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
    return null;
  });

  return (
    <div>
      <BreadCrumbContainer className={className}>{topicsRendering}</BreadCrumbContainer>
    </div>
  );
};

const StyledBreadCrumbs = styled(BreadCrumbs)`
  h2 {
    font-size: 1.7em !important;
  }
  margin: 1em 1em 0 1em;
  .p-false {
    opacity: 0.5;
  }
` as any;

const ResultTitle = styled(HeaderOne)`
  font-size: 4.5em !important;
  margin: 15px 0 !important;
  line-height: 50px !important;
  text-align: center !important;

  @media (max-width: 575px) {
    font-size: 10vw !important;
  }
` as any;

export interface ModalWizardProps {
  title?: string;
  show?: boolean;
  onHide?: () => void;
  onSubmit: any;
  dispatch: any;
  topics: any;
  options: any;
  results: any;
  handleShowModal?: any;
  descriptionText: string;
  subtitles: any;
  topicsDescriptionTexts: any;
  recommendationTextPartA: string;
  recommendationTextPartB: string;
  textAfterResult: string;
  textBeforeResult: string;
  anchor: string;
  formError: any;
}
class ModalWizard extends React.Component<ModalWizardProps> {
  state = { show: this.props.show, page: 0, result: undefined, formSubmitted: false };

  handleClose = () => {
    this.props.dispatch(push(window.location.pathname + window.location.search));
  };

  nextPage = (values) => {
    this.setState({ formSubmitted: this.props.formError ? true : false });
    Object.keys(values).length >= this.state.page + 1 && this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  getResultFromOptions = (optionIds) => {
    const topics = this.props.topics;
    if (topics.length - 1 !== optionIds.length) return undefined;
    const results = this.props.results;
    let resultKey = '';
    optionIds.forEach((option, index) => {
      resultKey += topics[index].id + '.' + option + ':';
    });
    resultKey = resultKey.slice(0, -1);

    const searchedResultArray = results.filter((result) => result && result.key === resultKey);
    return searchedResultArray.length > 0 && searchedResultArray[0];
  };

  submit = (values) => {
    this.nextPage(values);
    const resultValue = Object.keys(values).map((item) => Number.parseInt(values[item]));
    const result = this.getResultFromOptions(resultValue);
    this.setState({ result: result && result.result });
  };

  getSubtitleFromIndex = (index) => {
    const topicId = this.props.topics[index] && this.props.topics[index].id;
    const searchedSubtitleArray = this.props.subtitles.filter((subtitle) => subtitle.topicId === topicId);
    return searchedSubtitleArray.length > 0 && searchedSubtitleArray[0].label;
  };

  getTopicDescriptionTextFromIndex = (index) => {
    const topicId = this.props.topics[index] && this.props.topics[index].id;
    const searchedTopicDescriptionArray = this.props.topicsDescriptionTexts.filter(
      (textItem) => textItem.topicId === topicId
    );
    return searchedTopicDescriptionArray.length > 0 && searchedTopicDescriptionArray[0].label;
  };

  getRecommendationPage = (
    page,
    topicsLength,
    recommendationTextPartA,
    recommendationTextPartB,
    textAfterResult,
    textBeforeResult
  ) => {
    let resultValue = `${textBeforeResult ? textBeforeResult : ''} ${this.state.result}
    ${textAfterResult ? textAfterResult : ''}`;
    return (
      page === topicsLength - 1 && (
        <StyledRow>
          <Col xs={24} sm={10}>
            <ResultTitle>{resultValue}</ResultTitle>
          </Col>
          <Col xs={24} sm={14}>
            <Text>{`${recommendationTextPartA} ${resultValue} ${recommendationTextPartB}`}</Text>
          </Col>
        </StyledRow>
      )
    );
  };
  getOnSubmit = (index) => {
    const { topics } = this.props;
    return index === topics.length - 1 ? this.handleClose : index !== topics.length - 2 ? this.nextPage : this.submit;
  };

  render() {
    const {
      title,
      topics,
      options,
      descriptionText,
      recommendationTextPartA,
      recommendationTextPartB,
      textAfterResult,
      textBeforeResult,
      anchor
    } = this.props;
    const { page } = this.state;
    const recommendation = this.getRecommendationPage(
      page,
      topics.length,
      recommendationTextPartA,
      recommendationTextPartB,
      textAfterResult,
      textBeforeResult
    );

    const listPages = topics.map((topic, index) => (
      <div>
        {page === index && (
          <WizardFormPage
            topicId={`${topic.id}`}
            key={index}
            pageNumber={`${index}`}
            onSubmit={this.getOnSubmit(index)}
            previousPage={index !== 0 ? this.previousPage : undefined}
            lastPage={index === topics.length - 1}
            subtitle={this.getSubtitleFromIndex(index)}
            descriptionText={this.getTopicDescriptionTextFromIndex(index)}
            recommendation={recommendation}
            options={options.filter((item) => item.topic === topic.id)}
            formError={this.props.formError}
            formSubmitted={this.state.formSubmitted}
          />
        )}
      </div>
    ));

    return (
      <div>
        <ModalAnchored anchor={anchor} title={title}>
          <Text>{descriptionText}</Text>
          <StyledBreadCrumbs page={this.state.page} topics={topics} />
          {listPages}
        </ModalAnchored>
      </div>
    );
  }
}

const mapContentToProps = (getContent) => {
  const show = getContent('show', { type: 'checkbox', label: 'Show modal?' });
  const title = getContent('title', { type: undefined, label: 'Title' });
  const descriptionText = getContent('descriptionText', { type: undefined, label: 'Description Text' });
  const anchor = getContent('anchor', { type: 'none', label: 'Anchor Path' });

  const topics =
    getContent('topics', {
      type: 'array',
      schema: [{ model: 'id', label: 'topicId' }, { model: 'label', label: 'Label' }]
    }) || [];
  const subtitles =
    getContent('subtitles', {
      type: 'array',
      schema: [{ model: 'topicId', label: 'topicId' }, { model: 'label', label: 'Label' }]
    }) || [];
  const topicsDescriptionTexts =
    getContent('Topic Description Text', {
      type: 'array',
      schema: [{ model: 'topicId', label: 'topicId' }, { model: 'label', label: 'Label' }]
    }) || [];
  const recommendationTextPartA = getContent('recommendationTextPartA', {
    type: undefined,
    label: 'Recommendation Text before result'
  });
  const recommendationTextPartB = getContent('recommendationTextPartB', {
    type: undefined,
    label: 'Recommendation Text after result'
  });
  const textBeforeResult = getContent('textBeforeResult', {
    type: undefined,
    label: 'Text before result value'
  });
  const textAfterResult = getContent('textAfterResult', {
    type: undefined,
    label: 'Text after result value'
  });

  const topicOptions = topics
    ? topics.map((topic) => {
        topic = { ...topic, value: topic.id };
        return topic;
      })
    : [];
  const options =
    getContent('options', {
      type: 'array',
      schema: [
        { model: 'topic', type: 'select', label: 'Topic', options: topicOptions }, // relates to content.topics
        { model: 'id', label: 'Id' },
        { model: 'label', label: 'Label' }
      ]
    }) || [];
  const getKeySet = (set, key = undefined, index = 0): Array<any> => {
    return set.length
      ? set[index].reduce((list, item) => {
          const id = (key ? `${key}:` : '') + `${item.topic}.${item.id}`;
          if (index < set.length - 1) {
            return [...list, ...getKeySet(set, id, index + 1)];
          }
          return [...list, id];
        }, [])
      : [];
  };
  let results =
    getContent('results', {
      type: 'array',
      label: 'Results',
      schema: [{ model: 'key', label: 'Key' }, { model: 'result', label: 'Result' }],
      getDefaultContent: (content) => {
        const setA = content.topics
          ? content.topics
              .map((topic) => (content.options ? content.options.filter((option) => option.topic === topic.id) : []))
              .filter((sets) => sets.length > 0)
          : [];

        let keySetA = getKeySet(setA) || [];
        let contentResultKeysStateA = Object.keys(content).length ? content.results || [] : [];
        if (!Array.isArray(contentResultKeysStateA))
          contentResultKeysStateA = Object.keys(content.results || []).map((key) => ({
            key: keySetA[key],
            result: content.results[key].result
          }));
        const contentResultKeysStateRemoved = contentResultKeysStateA.filter(
          (item) => keySetA.filter((key) => key === item.key).length > 0
        );
        let defaultSetMap = keySetA
          .map((key) => ({ key, result: '' }))
          .filter((key) => contentResultKeysStateRemoved.filter((item) => item.key === key.key).length === 0);
        return [...contentResultKeysStateRemoved, ...defaultSetMap];
      }
    }) || [];

  let contentResultKeysStateB = Object.keys(results).length ? results || [] : [];
  const setB = topics
    ? topics
        .map((topic) => (options ? options.filter((option) => option.topic === topic.id) : []))
        .filter((sets) => sets.length > 0)
    : [];
  let keySetB = getKeySet(setB) || [];
  if (!Array.isArray(contentResultKeysStateB))
    contentResultKeysStateB = Object.keys(results || []).map((key) => ({
      key: keySetB[key],
      result: results[key].result
    }));
  results = [...contentResultKeysStateB];
  return {
    descriptionText,
    topics,
    subtitles,
    topicsDescriptionTexts,
    options,
    results,
    show,
    title,
    recommendationTextPartA,
    recommendationTextPartB,
    textAfterResult,
    textBeforeResult,
    anchor
  };
};

const mapStateToProps = (state) => {
  return {
    formError: formSelectors.getFormSyncErrors('wizard')(state).message
  };
};

export default register('rsv8-components/ModalWizard')(
  withContent(mapContentToProps),
  withTheme(),
  connect(mapStateToProps)
)(ModalWizard);
