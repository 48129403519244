import * as React from 'react';
import { Field } from '../Fields';

const FieldBuilder = (props): React.ReactElement<any> => {
  const { component, name, validation, warning, ...rest } = props.field;

  return (
    <Field
      name={name}
      component={component}
      validate={validation ? validation : []}
      warn={warning ? warning : []}
      props={rest}
    />
  );
};

export default FieldBuilder;
