import styled from 'styled-components';

export const AchievementHomepageContainer = styled.div`
  display: block;
  clear: both;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  
  & > .scroll-container {
    overflow: hidden;
    scroll-behavior: smooth
  }
`;

export const AchievementHomepageList = styled.div`
  background: #fff;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  
  .level-circle-content {
    font-size: 12px;
    line-height: normal;
  }
`;

export const AchievementHomepageItem = styled.div`
  margin: 0 50px 0 0;
  font-family: MillerBanner-Roman,serif;
  
  &:last-child {
    margin-right: 0;
  }
`;

export const AchievementHomepageTitle = styled.div`
  background: #fff;
  color: #000;
  font-family: GothamBold,sans-serif;
  font-size: 14px;
  padding-right: 30px;
  margin-bottom: 20px;
  position: relative;
  
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -2px;
    border-bottom: 2px solid #ccc;
  }
  
  span {
    display: inline-block;
    padding: 0 0.5em 0 0;
    position: relative;
    z-index: 2;
    background: #fff;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;

export const CarouselArrow = styled.div`
  border: none;
  cursor: pointer;
  display: block;
  filter: drop-shadow(1px 2px 4px rgba(0,0,0,0.2));
  font-size: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;

  &::before {
    display: none;
  }
  
  &:hover {
    filter: drop-shadow(1px 3px 6px rgba(0,0,0,0.5));
  }
    
  span {
    font-size: 30px !important;
    line-height: 40px;
    margin: 0 0 0 3px;
    padding: 0;
    color: #4c4f4f;
  }

  &.next {
    right: 0;
  }
  
  &.prev {
    left: 0;
    span {
      margin: 0 3px 0 0;
    }
  }
  
` as any;