import { TrendingProductModel } from 'xcel-api-generator/dist/catalog';
import { createTypeSelector, getRequest } from 'xcel-redux-orm';
import { ROOT_STATE, TRENDING_PRODUCT_JSON_TYPE, TRENDING_STATE } from '../../constants';

export const trendingSelector = createTypeSelector<TrendingProductModel>(TRENDING_PRODUCT_JSON_TYPE);

export const getTrending = (state) =>
  state && state[ROOT_STATE] && state[ROOT_STATE][TRENDING_STATE].trendingProducts.products;

export const getTrendingProductList = (state) => trendingSelector.selectMany(state, getTrending(state));
export const getTrendingDepartmentProductList = (state) =>
  trendingSelector.selectLast(state, 'getProductsTrendingByCategoryId');

export const getTrendingDepartmentRequest = (state) => getRequest(state, 'getProductsTrendingByCategoryId');

export const getTrendingRequest = (state) => getRequest(state, 'getProductsTrending');
