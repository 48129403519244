import { userStateSelectors } from 'rsv8-auth';
import { setClient } from 'xcel-asset-service';
import { cacheService } from 'xcel-cache-service';
import { reduxObserver } from 'xcel-observer';
import { onSelectorChange } from 'xcel-react-core';
import { cachePlugin, createReduxModule, ReduxModule } from 'xcel-redux-module';
import clientAdminActions from './redux/actions/client';
import reducers from './redux/reducers';
import clientSelector from './redux/selectors/client';

const clientModule: ReduxModule = cachePlugin(cacheService)(
  createReduxModule({
    name: 'rsv8-client-admin',
    init: ({ store }) => {
      const clientChanged = onSelectorChange(store, clientSelector.getActive);
      const userChanged = onSelectorChange(store, userStateSelectors.getUser);

      store.subscribe(() => {
        clientChanged((current, next) => {
          if (next.id !== undefined) {
            setClient(next.id);
          }
        });
        userChanged((current, next) => {
          if (next.tokenType === 'AdminUser') {
            store.dispatch(clientAdminActions.getAdminClientClients());
          }
        });
      });
    },
    reducers
  })
);
reduxObserver.addModule(clientModule);
export default clientModule;
