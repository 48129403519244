import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Loader } from 'rsv8-components';
import { register, withTheme } from 'xcel-react-core';
import { getPermissions } from "../../redux/actions";
import { permissionsSelector } from '../../redux/selectors';
import { PermissionsOptions } from "./PermissionsOptions";

interface Props {
    actions: any;
    userPermissions?: any;
    permissionsOptions: PermissionsOptions;
    redirectUrl?: string;
    children: any;
}

const RequirePermissions: React.FC<Props> = ({
    actions,
    userPermissions,
    permissionsOptions = {},
    redirectUrl,
    children,
}) => {
    React.useEffect(() => {
        if (!userPermissions) {
            actions.getPermissions()
            return;
        }
    }, []);

    const permissions = userPermissions ? userPermissions.permissions : {};

    const show = (!permissionsOptions.canEditRegistration || permissions.canEditRegistration)
        && (!permissionsOptions.canRedeem || permissions.canRedeem)
        && (!permissionsOptions.canViewAccount || permissions.canViewAccount)
        && (!permissionsOptions.canViewAccountBalance || permissions.canViewAccountBalance)
        && (!permissionsOptions.canViewRebateOptions || permissions.canViewRebateOptions)
        && (!permissionsOptions.canViewDashboard || permissions.canViewDashboard)
        && (!permissionsOptions.canManageParticipants || permissions.canManageParticipants)
        && (!permissionsOptions.canAccessCalculator || permissions.canAccessCalculator)
        && (!permissionsOptions.canViewRedemptionHistory || permissions.canViewRedemptionHistory);

    if (show) {
        return children;
    } else if (!!userPermissions && redirectUrl) {
        return <Redirect to={redirectUrl}/>;
    } else if (redirectUrl) {
        return <Loader />;
    } else {
        return null;
    }
};

const mapStateToProps = state => ({
    userPermissions: permissionsSelector.selectMany(state)[0]
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getPermissions }, dispatch)
});

export default register("rsv8-account/RequirePermissions")(
    connect(mapStateToProps, mapDispatchToProps),
    withTheme()
)(RequirePermissions);
