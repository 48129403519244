import { ContentTypeModel } from 'xcel-api-generator/dist/cms';
import * as events from '../events';
import cmsAdminApi from './cmsAdminApi';
const { getAdminContentTypes, createAdminContentTypes, createAdminContentTypesByIdRoutes } = cmsAdminApi;

const contentTypeActions = {
  get: getAdminContentTypes,
  /**
   * Creates the content type as well as the root route for that content type.
   */
  create: (id: string, description: string) => async (dispatch, getState) => {
    const response = await dispatch(
      createAdminContentTypes({
        body: {
          id,
          description,
          createdBy: null,
          createdDateTimeUtc: new Date().toJSON(),
          updatedBy: null,
          updatedDateTimeUtc: new Date().toJSON()
        }
      })
    );
    await dispatch(contentTypeActions.createRootRoute(id));
    return response;
  },
  createRootRoute: (id: string) => {
    return createAdminContentTypesByIdRoutes({ id, body: { routeNodeName: '', parentRouteId: null } });
  },
  setActive: (contentTypeId) => {
    return {
      type: events.CONTENT_TYPE_ACTIVE_SET,
      payload: contentTypeId
    };
  }
};
export { ContentTypeModel };
export default contentTypeActions;
