import { concat, uniqBy } from 'ramda';
import { IndividualNomination } from '../../../types';
import * as events from '../../events';

const initialState = {
  customFields: [],
  award: null,
  nominee: null,
  points: 0,
  id: null,
  product: null,
  optionId: null,
  optionPointValue: null,
  isEligible: true,
  incentive: null,
  clearCurrentNominationData: false
};

const currentNominationReducer = (
  state: IndividualNomination.CurrentNominationState = initialState,
  action: IndividualNomination.Action
) => {
  switch (action.type) {
    case events.SET_INDIVIDUAL_NOMINATION_PRODUCT:
      return { ...state, product: action.payload };
    case events.SET_INDIVIDUAL_NOMINATION:
      return action.payload;
    case events.CLEAR_INDIVIDUAL_NOMINATION:
      return initialState;
    case events.SELECT_CUSTOM_FIELDS:
      const newCustomFields = uniqBy((v: any) => v.id, concat(action.payload, state.customFields));
      return { ...state, customFields: newCustomFields };
    case events.SELECT_AWARD:
      return { ...state, award: action.payload };
    case events.SELECT_PRODUCT:
      return { ...state, product: action.payload };
    case events.SELECT_PRODUCT_OPTION_ID:
      return { ...state, optionId: action.payload };
    case events.SET_INDIVIDUAL_NOMINATION_OPTION:
      return { ...state, optionId: action.payload };
    case events.SELECT_PRODUCT_OPTION_POINT_VALUE:
      return { ...state, optionPointValue: action.payload };
    case events.SET_INDIVIDUAL_NOMINATION_PRODUCT_VALUE:
      return { ...state, optionPointValue: action.payload };
    case events.SELECT_NOMINEE:
      return { ...state, nominee: action.payload };
    case events.SELECT_NOMINATION_POINTS:
      return { ...state, points: action.payload };
    case events.SAVE_NOMINATION_SUCCESS:
      return { ...state, id: action.payload.nominationDetailId };
    case events.CLEAR_CURRENT_CUSTOM_FIELDS:
      // we also need to remove the nomination id because it's no longer valid.
      return { ...state, customFields: [], points: null, id: null };
    case events.SET_CLEAR_CURRENT_NOMINATION_DATA:
      return { ...state, clearCurrentNominationData: action.payload };
    case events.CLEAR_CURRENT_NOMINATION_DATA:
      return { ...state, points: null, id: null };
    case events.CLEAR_CURRENT_AWARD:
      return { ...state, award: null };

    case events.CLEAR_CURRENT_PRODUCT:
      return { ...state, product: null };

    case events.CLEAR_CURRENT_NOMINEE:
      return { ...state, nominee: null };
    default:
      return state;
  }
};

export default currentNominationReducer;
