import * as React from 'react';
import { ScrollTo } from 'react-scroll-to';
import { register, withContent, withTheme } from 'xcel-react-core';
import Link from '../Link';

export type Props = {
  scrollToX?: number;
  scrollToY?: number;
  children?: string;
  className?: string;
};

class ScrollToPosition extends React.Component<Props> {
  state = { scrolledToTop: false };

  componentDidMount() {
    this.checkScrollPosition();
    window.addEventListener('scroll', this.checkScrollPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  }

  checkScrollPosition = () => {
    const scrollPosition = document.documentElement.scrollTop;
    this.setState({ scrolledToTop: scrollPosition === 0 ? true : false });
  };
  render() {
    const { scrollToX, scrollToY, className, children } = this.props;
    const isEditing = this.props['data-cms-editing'];
    return (
      !isEditing &&
      !this.state.scrolledToTop && (
        <ScrollTo>
          {(scroll) => {
            const handleClick = () => scroll(scrollToX, scrollToY);
            return (
              <Link className={className} onClick={handleClick}>
                {children}
              </Link>
            );
          }}
        </ScrollTo>
      )
    );
  }
}

const mapContentToProps = (getContent) => ({
  scrollToX: getContent('scrollToX', { type: 'none' }),
  scrollToY: getContent('scrollToY', { type: 'none' })
});

export default register('rsv8-components/ScrollToPosition')(withContent(mapContentToProps), withTheme())(
  ScrollToPosition
);
