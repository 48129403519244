import * as React from 'react';

// TODO: Replace hardcoded fill.
const AlertIcon = (props) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <title>alerts</title>
    <path
      fill="#ffffff"
      d="M8.61,20.55H21.39A4.91,4.91,0,0,1,21,20a4.08,4.08,0,0,1-.34-.57,6.26,6.26,0,0,1-.49-1.35A6.15,6.15,0,0,1,20,16.67V13.33a4.86,4.86,0,0,0-.39-1.93,4.94,4.94,0,0,0-2.67-2.67,4.92,4.92,0,0,0-2-.39,4.86,4.86,0,0,0-1.93.39,4.94,4.94,0,0,0-2.67,2.67A4.86,4.86,0,0,0,10,13.33v3.34a6.15,6.15,0,0,1-.16,1.43,5.84,5.84,0,0,1-.51,1.35A5.36,5.36,0,0,1,9,20C8.87,20.2,8.74,20.38,8.61,20.55Zm8.05,1.67a2.46,2.46,0,0,0,0-.28c0-.09,0-.18-.07-.28H13.43c0,.1,0,.19-.07.28a2.46,2.46,0,0,0,0,.28,1.61,1.61,0,0,0,.49,1.19,1.59,1.59,0,0,0,1.16.48,1.66,1.66,0,0,0,1.67-1.67ZM14.45,6.67v.59a1.12,1.12,0,0,1,.27,0h.56a1.12,1.12,0,0,1,.27,0V6.67a.52.52,0,0,0-.16-.38.54.54,0,0,0-.4-.16.51.51,0,0,0-.38.16A.52.52,0,0,0,14.45,6.67Zm8.65,14a.58.58,0,0,1,.21.28.6.6,0,0,1,0,.35.52.52,0,0,1-.21.27.5.5,0,0,1-.31.1H17.72a1.5,1.5,0,0,1,0,.29c0,.1,0,.19,0,.27a2.71,2.71,0,0,1-.82,2,2.78,2.78,0,0,1-3.92,0,2.67,2.67,0,0,1-.82-2c0-.08,0-.17,0-.27s0-.19,0-.29H7.22a.55.55,0,0,1-.32-.1.43.43,0,0,1-.2-.27.6.6,0,0,1,0-.35.58.58,0,0,1,.21-.28A4.89,4.89,0,0,0,8.37,18.9a4.85,4.85,0,0,0,.52-2.23V13.33a6,6,0,0,1,.34-2,6.26,6.26,0,0,1,.92-1.71,6.11,6.11,0,0,1,1.4-1.33,6,6,0,0,1,1.79-.85V6.67a1.59,1.59,0,0,1,.49-1.18A1.56,1.56,0,0,1,15,5a1.67,1.67,0,0,1,1.67,1.67v.78a6.21,6.21,0,0,1,1.78.85,6,6,0,0,1,1.41,1.33,6.26,6.26,0,0,1,.92,1.71,6,6,0,0,1,.34,2v3.34a5,5,0,0,0,2,4Z"
    />
  </svg>
);

export default AlertIcon;
