import { getRequest as getRequestBase } from 'xcel-redux-orm';
import { INDIVIDUAL_NOMINATION_REDUCER_KEY, NOMINEE_SEARCH_ENDPOINT_NAME, NOMINEE_SEARCH_KEY } from '../../constants';
import { IndividualNomination, ReduxState as State } from '../../types';
import { nomineeFilterSelectors } from './nomination';

export const getNomineeSearch = (state: State) => state[INDIVIDUAL_NOMINATION_REDUCER_KEY][NOMINEE_SEARCH_KEY];

export const getLatestNomineeSearch = (state: State): IndividualNomination.NomineeSearch => getNomineeSearch(state).latestSearch;

export const getNomineeTableSort = (state: State) => getLatestNomineeSearch(state).sort;

export const getVisibleNominees = (state: State): Array<string | number> => getNomineeSearch(state).visibleNominees;

export const getNomineeScroll = (state: State): string => getNomineeSearch(state).nomineeScroll;

export const getLatestNomineePage = (state: State): number => getLatestNomineeSearch(state).page;

export const getTotalNomineeCount = (state: State): number => getNomineeSearch(state).totalRecords;

export const getNomineeSearchFilterNames = (state: State): Array<string> => getNomineeSearch(state).filters;

export const getNomineeSearchFilterExists = (state: State, name: string) =>
  !!nomineeFilterSelectors.selectOne(state, name);

export const getRequest = (state: any) => getRequestBase(state, NOMINEE_SEARCH_ENDPOINT_NAME);
