import * as React from 'react';
import { connect } from 'react-redux';
import { formSelectors } from 'rsv8-forms';
import { getTheme } from '../../redux/theme.selectors';
import { FontPreviewContainer, FontText } from './styles';
const FontSizePreview = ({ showPreview, previewSizes, preview }) => {
  preview = preview ? preview : {};
  return (
    <div>
      {showPreview &&
        previewSizes.map((fontSize, index) => (
          <FontPreviewContainer key={fontSize}>
            <FontText font={preview} size={fontSize + 'px'}>
              <span>
                <strong>{previewSizes.length - index}</strong>
              </span>
              <span>Aa The quick brown fox jumps over the lazy dog</span>
            </FontText>
          </FontPreviewContainer>
        ))}
    </div>
  );
};
const mapStateToProps = (state) => {
  const values = formSelectors.getFormValues('fontManage')(state);
  const theme = getTheme(state);
  return {
    preview: theme.font.preview,
    previewSizes: theme.font.previewSizes,
    showPreview: values === undefined ? false : values.showPreview
  };
};

export default connect(mapStateToProps)(FontSizePreview);
