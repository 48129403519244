import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CultureDropdown } from 'rsv8-client';
import { Field, reduxForm } from 'rsv8-forms';
import { navigationAdminApi } from '../../../api/generated';
import { updateMenuadmin } from '../../redux/navigationMenuAdmin.actions';

const DuplicateNav = (props) => {
  const { handleSubmit, pristine, submitting, clientId } = props;

  const { navigationMenuAdmin } = props;

  const duplicateMenu = (values) => {
    let newCultureId = parseInt(values.cultureId);
    let flattenedDupMenu = flattenMenu(navigationMenuAdmin.items);
    let updatedCultureMenu = changeCulture(flattenedDupMenu, newCultureId);

    duplicateMenuApi(updatedCultureMenu).then(() => {
      document.querySelector<any>('#success-message-duplicate').style.display = 'block';
      setTimeout(function() {
        document.querySelector<any>('#success-message-duplicate').style.display = 'none';
      }, 3000);
    });
  };

  let flattenMenu = (menutree) => {
    let flattenedMenu = [];
    menutree.forEach((item, i) => {
      if (item.children.length) {
        let child = item.children;
        child.forEach((item) => {
          flattenedMenu.push(item);
        });
      }
      flattenedMenu.push(item);
    });
    return flattenedMenu;
  };

  let changeCulture = (flattenedDupMenu, newCultureId) => {
    let updatedArray = [];
    flattenedDupMenu.map((item) => ((item.cultureId = newCultureId), updatedArray.push(item)));
    return updatedArray;
  };

  const duplicateMenuApi = (values) => {
    let updatedValues = values.map((menuItem) => {
      menuItem.meta = JSON.stringify(menuItem.meta);
      return menuItem;
    });

    return new Promise((resolve, reject) => {
      navigationAdminApi
        .updateMenuadmin({ clientId: clientId, models: updatedValues })
        .then((response) => {
          resolve(response);
        })
        .catch(function(err) {
          console.log(err);
        });
    });
  };

  return (
    <form onSubmit={handleSubmit(duplicateMenu)}>
      <div className="form-group">
        <Panel bsStyle="danger">
          <Row>
            <Col xs={12}>
              <label>
                <h4>Duplicate Menu to Another Language</h4>
                <small>
                  <span style={{ color: '#ff0000' }}>
                    Doing this will overwrite the entire menu structure of any current menu in this language
                  </span>
                </small>
              </label>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <div>
                <Field
                  name="cultureId"
                  component={({ input }) => {
                    return <CultureDropdown property="id" allowCultureChange={false} {...input} />;
                  }}
                />
              </div>
            </Col>
            <Col xs={3}>
              <button type="submit" className="btn btn-sm btn-primary" disabled={pristine || submitting}>
                Submit
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <div id="success-message-duplicate" style={{ display: 'none' }}>
                <div className="panel panel-success">
                  <div className="panel-body">Menu has been duplicated</div>
                </div>
              </div>
            </Col>
          </Row>
        </Panel>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  navigationMenuAdmin: state.navigationMenuAdmin
});
const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateMenuadmin
    },
    dispatch
  )
});

const wrappedSomething = connect(
  mapStateToProps,
  mapDispatch
)(DuplicateNav);

export default reduxForm({ form: 'DuplicateNav' })(wrappedSomething as any) as any;
