import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { register, withResource, withTheme } from 'xcel-react-core';
import { BadgeButton } from '../../components/index';
import { nominationApprovalSelectors } from '../../redux/selectors';
import CompleteNominations from './CompleteNominations';

const StyledRow = styled(Row)`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 20px;
`;

export interface NominationBadgesProps {
  onTrackNominations: (e: any) => any;
  onViewAwards: (e: any) => any;
  onApproveNominations: () => any;
}

interface InternalProps extends NominationBadgesProps {
  awardsCount: number;
  trackableNominationsCount: number;
  approvableNominationsCount: number;
  resource: {
    trackNominationsButtonLabelText: string;
    trackNominationsButtonUrl: string;
    approveNominationsButtonImageURI: string;
    approveNominationsButtonArrowImageURL: string;
    viewAwardsButtonLabelText: string;
    viewAwardsButtonUrl: string;
    viewAwardsButtonImageURI: string;
    approveNominationsButtonLabelText: string;
    approveNominationsButtonUrl: string;
  };
}

const NominationBadges: React.SFC<InternalProps> = (props) => {
  const checkNominationCount: any = () => {
    return props.approvableNominationsCount ? 8 : 12;
  };

  return (
    <React.Fragment>
      <Row>
        <StyledCol xs={22} xsPush={1} md={24} mdPush={0}>
          <CompleteNominations onClick={props.onApproveNominations} />
        </StyledCol>
      </Row>
      <StyledRow>
        <StyledCol xs={22} xsPush={1} md={checkNominationCount()} mdPush={0}>
          <BadgeButton
            href={props.resource.trackNominationsButtonUrl}
            count={props.trackableNominationsCount}
            labelText={props.resource.trackNominationsButtonLabelText}
            imageURI={props.resource.approveNominationsButtonImageURI}
          />
        </StyledCol>
        <StyledCol xs={22} xsPush={1} md={checkNominationCount()} mdPush={0}>
          <BadgeButton
            href={props.resource.viewAwardsButtonUrl}
            count={props.awardsCount}
            labelText={props.resource.viewAwardsButtonLabelText}
            imageURI={props.resource.viewAwardsButtonImageURI}
          />
        </StyledCol>

        {props.approvableNominationsCount > 0 && (
          <StyledCol xs={22} xsPush={1} md={8} mdPush={0}>
            <BadgeButton
              href={props.resource.approveNominationsButtonUrl}
              count={props.approvableNominationsCount}
              labelText={props.resource.approveNominationsButtonLabelText}
              imageURI={props.resource.approveNominationsButtonImageURI}
            />
          </StyledCol>
        )}
      </StyledRow>
    </React.Fragment>
  );
};

const mapState = (state) => ({
  trackableNominationsCount: state.nominationDashboard.trackNominationsCount,
  awardsCount: state.nominationDashboard.awardsCount,
  approvableNominationsCount: nominationApprovalSelectors.getPendingApprovals(state, {}).length
});

const mapResource = (getResource) => ({
  resource: {
    trackNominationsButtonLabelText: getResource(
      'recognition.trackNominationsButtonLabelText',
      'Track your Nominations'
    ),
    trackNominationsButtonUrl: getResource('recognition.trackNominationsButtonUrl', '/nominations/activity'),
    trackNominationsButtonImageURI: getResource('recognition.trackNominationsButtonImageURI', ''),
    viewAwardsButtonLabelText: getResource('recognition.viewAwardsButtonLabelText', 'View your Awards'),
    viewAwardsButtonUrl: getResource('recognition.viewAwardsButtonUrl', '/nominations/awards'),
    viewAwardsButtonImageURI: getResource(
      'recognition.viewAwardsButtonImageURI',
      '/clientassets/images/icons/recognition.svg'
    ),
    approveNominationsButtonLabelText: getResource(
      'recognition.approveNominationsButtonLabelText',
      'Approve Nominations'
    ),
    approveNominationsButtonUrl: getResource('recognition.approveNominationsButtonUrl', '/nominations/history'),
    approveNominationsButtonImageUri: getResource('recognition.approveNominationsButtonImageURI', '')
  }
});

export default register('rsv8-nomination/NominationBadges')(
  connect(mapState, null),
  withResource(mapResource),
  withTheme(['.test'])
)(NominationBadges);
