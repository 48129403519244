import { HeaderFive } from 'rsv8-components';
import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${(props: { justify?: string }) => (props.justify ? props.justify : 'flex-start')};
  padding: 2px 0px;
`;

export const PaddedHeader = styled(HeaderFive)`
  padding-left: 5px;
`;
