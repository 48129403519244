import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { TableCell, TableRow } from 'rsv8-components';
import { toast } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import { teamMemberActions } from '../../../redux';
import { nomineeSelectors } from '../../../redux/selectors/index';
import { Resources } from '../../../types';
import { NomineeImage } from '../../index';
import { LoadingContainer, NomineeRowProps, NomineeStatusIcon } from '../Shared/index';

const NomineeTableRow = LoadingContainer.withComponent(TableRow as any);

interface InternalProps extends NomineeRowProps {
  nominee: Resources.Nominee;
  confirmText: string;
  textPartA: string;
  textPartB: string;
  linkText: string;
  validateTeamMember: Function;
}

class NomineeRow extends React.Component<InternalProps, {}> {
  selectNominee = async (e) => {
    const { validateTeamMember, nominee, selectNominee, confirmText } = this.props;
    const isEligible = await validateTeamMember(nominee.id.toString());
    if (isEligible) {
      selectNominee(nominee.id.toString(), confirmText);
    } else {
      toast({
        template: 'ContentToast',
        contentTemplate: (
          <PageRenderer
            onNotFound={() => <PageRenderer path={`toast/nomination/team/threshold`} cultureId="0" />}
            path={'toast/nomination/team/threshold'}
            nominee={nominee}
          />
        )
      });
    }
  };

  render() {
    const { nominee } = this.props;
    return (
      <NomineeTableRow onClick={this.selectNominee} loading={this.props.loading}>
        <TableCell>
          <NomineeStatusIcon loading={this.props.loading} active={this.props.active} />
        </TableCell>
        <TableCell>
          <NomineeImage nominee={nominee} width="45px" caption={false} />
        </TableCell>
        <TableCell>{nominee.firstName}</TableCell>
        <TableCell>{nominee.lastName}</TableCell>
        <TableCell>{nominee.organizationName}</TableCell>
        <TableCell>{`${nominee.managerFirstName} ${nominee.managerLastName}`}</TableCell>
      </NomineeTableRow>
    );
  }
}

const mapState = (state, ownProps) => ({
  nominee: nomineeSelectors.selectOne(state, ownProps.userId)
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ validateTeamMember: teamMemberActions.validateTeamMember }, dispatch);
const compose = register('rsv8-nominations/NomineeRow');

const mapResource = (getResource) => ({
  confirmText: getResource(
    'nominees.confirmNomineeChangeText',
    'Changing nominee at this point will clear data from later steps.'
  )
});

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource)
)(NomineeRow) as React.SFC<NomineeRowProps>;
