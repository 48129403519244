export default ({fieldType}) => {
  switch (fieldType.toLowerCase()) {
    case 'radiobutton':
      return 'radio-group';
    case 'checkbox':
      return 'checkbox-group';
    case 'boolean':
      return 'checkbox';
    case 'memo':
      return 'bs-textarea';
    case 'dropdown':
      return 'bs-select';
    default:
      return 'bs-input';
  }
};
