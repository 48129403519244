import * as React from 'react';

const AccountIcon = (props) => (
  <svg
    className={props.className}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <title>account</title>
    <path
      fill={'#ffffff'}
      d="M19.69,20.54H10.31a.81.81,0,0,1-.57-.23.76.76,0,0,1-.23-.55s0-.21.09-.6a3.11,3.11,0,0,1,.63-1.32,4.08,4.08,0,0,1,.85-.81,4.94,4.94,0,0,1,1.1-.56,7.16,7.16,0,0,1,1.31-.34,10.84,10.84,0,0,1,3,0,7.07,7.07,0,0,1,1.32.34,5.08,5.08,0,0,1,1.09.56,3.64,3.64,0,0,1,.85.81,3,3,0,0,1,.64,1.32l.08.5a.78.78,0,0,1-.79.88Zm-9.14-1h8.9l0-.22a2.09,2.09,0,0,0-.45-.88,2.69,2.69,0,0,0-.62-.59,4.73,4.73,0,0,0-.87-.45,5.92,5.92,0,0,0-1.12-.28,9.46,9.46,0,0,0-2.71,0,5.3,5.3,0,0,0-1.11.29,4,4,0,0,0-.88.44,3,3,0,0,0-.63.6,2.12,2.12,0,0,0-.43.87C10.57,19.41,10.56,19.48,10.55,19.54Z"
    />
    <path
      fill={'#ffffff'}
      d="M15,15.28A3.11,3.11,0,0,1,13.78,15a3.16,3.16,0,0,1-1-.68,3,3,0,0,1-.67-1,3.1,3.1,0,0,1,0-2.44,3.08,3.08,0,0,1,.67-1,3.53,3.53,0,0,1,1-.67,3.14,3.14,0,0,1,2.46,0,3.53,3.53,0,0,1,1,.67,3.08,3.08,0,0,1,.67,1,3.15,3.15,0,0,1-.67,3.44,3.06,3.06,0,0,1-2.23.92ZM15,10a2,2,0,0,0-.81.17,2.27,2.27,0,0,0-.7.47,2.08,2.08,0,0,0-.45.67,2,2,0,0,0-.17.84A2.07,2.07,0,0,0,13,13a2.2,2.2,0,0,0,1.15,1.15,2.22,2.22,0,0,0,1.66,0,2.18,2.18,0,0,0,.68-.47A2,2,0,0,0,17,13a1.91,1.91,0,0,0,.17-.82,2.13,2.13,0,0,0-.16-.84,2.2,2.2,0,0,0-1.16-1.14A2.05,2.05,0,0,0,15,10Z"
    />
    <path fill={'#ffffff'} d="M15,6a9,9,0,1,1-9,9,9,9,0,0,1,9-9m0-1A10,10,0,1,0,25,15,10,10,0,0,0,15,5Z" />
  </svg>
);

export default AccountIcon;
