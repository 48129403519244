export interface TranslateRequest {
  source?: string;
  target: string;
  q: string[];
}

export const translate = async (request: TranslateRequest, options?: any) => {
  const response = await fetch(
    'https://translation.googleapis.com/language/translate/v2?key=AIzaSyD4nTG7JjZojPZwEadV-mhImD6WQ7IdCY8',
    {
      method: 'POST',
      body: JSON.stringify(request)
    }
  );

  return response.json();
};
