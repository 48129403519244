import * as React from 'react';
import { register, withTheme } from 'xcel-react-core';

export interface Props {
  contentTemplate: React.Component;
}

const Body: React.SFC<Props> = ({ contentTemplate, ...rest }) => {
  return <React.Fragment>{contentTemplate}</React.Fragment>;
};

const ContentToast = register('rsv8-components/Toastify/templates/ContentToast')(withTheme())(Body);

export default ContentToast;
