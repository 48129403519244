export interface RenderProps<T> {
  (
    { record, column, index, isMobile }: { record: T; column: DataTableColumnProps; index: number; isMobile: boolean }
  ): any;
}

export interface DataTableColumnProps extends React.Props<any> {
  sortable?: boolean;
  renderCell?: RenderProps<any>;
  renderHeader?: RenderProps<any>;
  field?: string;
  width?: string;
  className?: string;
  [index: string]: any;
}

const DataTableColumn: React.SFC<DataTableColumnProps> = () => null;
DataTableColumn.displayName = 'DataTableColumn';

export default DataTableColumn;
