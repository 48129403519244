import { Modal } from 'react-bootstrap';
import {
  Button,
  HeaderThree,
  HeaderTwo,
  Link,
  Loader
  } from 'rsv8-components';
import styled from 'styled-components';
import { Error } from '../../components/PointRangeSearch/styles';

export const ButtonLoader = styled(Loader)`
padding: 0 5% 0 18%;
& > div {
  width: 44px;
}
`as any;

export const CheckoutModuleHeading = styled(HeaderThree)`
  font-size: 20px;
  margin-bottom: 0;
` as any;

export const CheckoutModal = styled(Modal)`
  opacity: 0;
  transition: opacity 0.25s linear;
  padding-left: 0 !important;

  &.in {
    opacity: 0.96;
  }

  .modal-dialog {
    height: 100%;
    margin: 0;
    padding: 0;
    transform: none !important;
    width: 100%;
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    height: auto;
    min-height: 100%;
  }
` as any;

export const CheckoutModalBody = styled(Modal.Body)`
  overflow-x: hidden;
  overflow-y: auto;
` as any;

export const PlaceOrderContainer = styled.div`
  align-items: center;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-left: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
    padding-bottom: 30px;
    padding-top: 20px;
  }
` as any;

export const PointTotal = styled.div`
  color: #606060;
  font-size: 16px;
  font-weight: bold;
` as any;

export const OrderButton = styled(Button)`
  background-color: #b5be35;
  border: 0;
  color: #fff;
  font-weight: bold;
  margin-top: 0;
  padding: 10px 50px;
  margin-right: 15px;
  transition: background-color 0.2s linear;

  @media (max-width: 768px) {
    margin-top: 15px;
    order: 1;
    width: 90%;
  }
  @media (min-width: 769px) {
    float: right;
  }

  &:active,
  &:focus,
  &:hover,
  &:active:focus {
    background-color: #a2aa2f;
    color: #fff;
    outline: none;
  }
` as any;

export const CheckoutHeading = styled(HeaderTwo)`
  text-align: center;
` as any;

export const CheckoutIcon = styled.img`
  display: flex;
  margin: 0 auto;
  width: 70px;

  @media (min-width: 769px) {
    width: 97px;
  }
` as any;

export const CheckoutErrors = styled(Error)`
  align-items: flex-start;
  padding-bottom: 0;
  width: 91.667%;

  @media (min-width: 768px) {
    width: 66.667%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }
  @media (min-width: 1366px) {
    width: 33.333%;
  }
` as any;

export const BackLink = styled(Link)`
  color: #4192a5;

  &:active,
  &:focus,
  &:hover,
  &:active:focus {
    color: #4192a5;
    outline: none;
    text-decoration: none;
  }
` as any;
