import * as React from 'react';
import {
  Button,
  Grid,
  Tab,
  Tabs
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import { convertToPatchOperations } from 'xcel-util';
import {
  createMenuadmin,
  deleteMenuadminById,
  getApplication,
  getLookupByKey,
  getMenuadmin,
  getMenuAdminProviders,
  getMenuadminReadOnly,
  lastSubmittedForm,
  navigationMenuAdminUpdate,
  patchMenuadmin,
  showBootstrapAlert,
  updateMenuadmin
  } from '../../redux/navigationMenuAdmin.actions';
import { AddMenuItemForm, DuplicateALanguageMenuItemForm, EditMenuItemForm } from '../AdminForms';
import AdminModal from '../AdminModal';
import ApplicationControl from '../ApplicationControl';
import DuplucateNav from '../DuplicateNav';
import SortForm from '../SortForm';
import updateNodes from './updateNodes';

interface NavAdminProps {
  clientId: any;
  actions: {
    getLookupByKey: any;
    getMenuadmin: any;
    getMenuadminReadOnly: any;
    getApplication: any;
    createMenuadmin: any;
    patchMenuadmin: any;
    navigationMenuAdminUpdate: any;
    updateMenuadmin: any;
    deleteMenuadminById: any;
    getMenuAdminProviders: any;
  };
  navigationMenuAdmin: any;
}

interface NavAdminState {
  editing: any;
  formChanged: any;
  treeData: any;
}

class NavAdmin extends React.Component<NavAdminProps, NavAdminState> {
  state = {
    editing: false,
    formChanged: false,
    treeData: [{}]
  };
  componentDidMount() {
    this.getNavMenu(this.props.clientId, { cultureId: 0 });
    this.getLookupAdmin('menuType');
    this.getLookupAdmin('culture');
    this.showNavApplication(this.props.clientId);
    this.getProviderTypes();
  }
  getProviderTypes = () => {
    this.props.actions.getMenuAdminProviders();
  };
  getLookupAdmin = (key) => {
    this.props.actions.getLookupByKey(key);
  };
  getNavMenu = (clientId, values) => {
    this.props.actions.getMenuadmin(clientId, values);
  };
  getNavMenuReadOnly = (clientId, cultureId, MenuTypeId) => {
    this.props.actions.getMenuadminReadOnly(clientId, cultureId, MenuTypeId);
  };
  showNavApplication = (clientId) => {
    this.props.actions.getApplication(clientId);
  };

  // Save a new menu items when called, takes an objec that is the submitted values from AddMenuItemForm Form
  save = (values) => {
    let { navigationMenuAdmin } = this.props;
    this.props.actions
      .createMenuadmin({ ...values, clientId: this.props.clientId }, navigationMenuAdmin.lastForm)
      .then((values) => {
        this.getNavMenu(this.props.clientId, values);
        document.querySelector<any>('#success-message_save').style.display = 'block';
      });
  };

  /*takes a given tree object and pulls its children out and makes them root level objects to make it easier to
  compare them, otherwise we would have had to do it recursivly and this was just easier at the time*/
  flattenMenu = (menutree) => {
    let flattenedMenu = [];
    menutree.forEach((item, i) => {
      if (item.children.length) {
        let child = item.children;
        child.forEach((itemChild) => {
          if (itemChild.children.length) {
            let child2 = itemChild.children;
            child2.forEach((itemChild2) => {
              flattenedMenu.push(itemChild2);
            });
          }
          flattenedMenu.push(itemChild);
        });
      }

      flattenedMenu.push(item);
    });

    return flattenedMenu;
  };

  /*saveDragDrop takes the passed client ID and the updated menu tree. This is trigger on a button press. There are two things being
  pulled from stte here. one is Updated Menu Items, which is the slice or redux that is the representation of the current menu state after
  reordering and the second is the original menu that doesn't change. */
  saveDragDrop = () => {
    const updatedMenuItems = updateNodes(this.props.navigationMenuAdmin.items);

    const originalMenuTree = this.props.navigationMenuAdmin.readOnlyItems;

    let differencesMenuTree = [];

    let flattenOrigMenuTree = this.flattenMenu(originalMenuTree);
    let flattenUpdatedMenuTree = this.flattenMenu(updatedMenuItems);

    flattenOrigMenuTree.forEach((item, i) => {
      let found = flattenUpdatedMenuTree.find((search) => search.id === item.id);

      if (found) {
        if (found.sequence !== item.sequence || found.parentMenuId !== item.parentMenuId) {
          const operationsModel = {
            sequence: found.sequence,
            parentMenuId: found.parentMenuId,
            cultureId: found.cultureId
          };

          const patchRequest = { operations: convertToPatchOperations(operationsModel) };

          let newObject = {
            id: found.id,
            patchRequest
          };
          differencesMenuTree.push(newObject);
        }
      }
    });

    if (differencesMenuTree.length > 0) {
      const cultureId = flattenOrigMenuTree[0].cultureId;
      this.props.actions.patchMenuadmin({ cultureId, models: differencesMenuTree }).then((response) => {
        this.setState({ editing: 'false' });
      });

      this.setState({ editing: 'false' });
    } else {
      console.log('nothing changed');
    }
  };

  updateTreeData = (treeData) => {
    this.props.actions.navigationMenuAdminUpdate(treeData);
  };

  updateMenuadmin = (values) => {
    let { navigationMenuAdmin } = this.props;

    return this.props.actions
      .updateMenuadmin(values, this.props.clientId, navigationMenuAdmin.lastForm)
      .then((response) => {
        let getValues = {
          cultureId: values.cultureId,
          menuType: values.menuType,
          applicationId: values.applicationKey,
          countryCode: values.countryCode
        };

        this.getNavMenu(this.props.clientId, getValues);
        let getSuccessMessageLocation: any = document.querySelector('#success-message_edit');

        getSuccessMessageLocation.style.display = 'block';
      })
      .catch((err) => {
        throw new SubmissionError({
          providerId: 'That App Key is already in use',
          _error: 'Form Failed to Submit'
        });
      });
  };

  deleteTreeItem = (treeItem) => {
    this.props.actions.deleteMenuadminById(treeItem.node.id, treeItem.node.cultureId).then((res) => {
      let getValues = {
        cultureId: treeItem.node.cultureId,
        menuType: treeItem.node.menuTypeId,
        applicationId: treeItem.node.key,
        countryCode: treeItem.node.countryCode || 'USA'
      };
      this.getNavMenu(this.props.clientId, getValues);
    });
  };

  expand(expanded: any) {
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: [...this.state.treeData],
        expanded
      })
    });
  }

  expandAll() {
    this.expand(true);
  }
  setCurrentForm(formName: any, actions: any) {
    formName && actions.lastSubmittedForm(formName);
  }
  newNode = (node) => {
    return node;
  };

  render() {
    const {
      navigationMenuAdmin: { items, lookup, application },
      clientId
    } = this.props;

    return (
      <Grid fluid={true}>
        <Tabs defaultActiveKey={1} id="main-tabs-wrapper">
          <Tab eventKey={1} title="Navigation">
            <div style={{ height: '100%' }}>
              {lookup.menuType !== undefined &&
                lookup.menuType.length !== 0 &&
                application !== undefined &&
                application.length !== 0 && <SortForm clientId={clientId} />}
              <br />
              <AdminModal title="Add New Menu Item" formName="MenuItemForm" modaleTitle="Add Menu Item">
                <AddMenuItemForm {...this.props} onSubmit={this.save} setCurrentForm={this.setCurrentForm} />
              </AdminModal>
              <SortableTree
                treeData={items}
                onChange={this.updateTreeData}
                isVirtualized={false}
                onMoveNode={() => {
                  this.setState({ editing: 'true' });
                }}
                generateNodeProps={(node) => ({
                  buttons: [
                    <div key={node.id}>
                      <AdminModal
                        title="Edit"
                        modaleTitle="Edit menu Item"
                        formName="EditMenuItemForm"
                        node={node.node}
                        {...this.props}
                      >
                        <EditMenuItemForm
                          onSubmit={this.updateMenuadmin}
                          node={this.newNode(node.node)}
                          {...this.props}
                          setCurrentForm={this.setCurrentForm}
                        />
                      </AdminModal>
                      <AdminModal
                        title="Duplicate"
                        formName="DuplicateALanguageMenuItemForm"
                        node={node.node}
                        {...this.props}
                      >
                        <DuplicateALanguageMenuItemForm
                          onSubmit={this.updateMenuadmin}
                          node={node.node}
                          {...this.props}
                        />
                      </AdminModal>
                      <button
                        className="btn btn-danger"
                        style={{ marginLeft: '5px' }}
                        onClick={() =>
                          window.confirm('Are you sure you want to delete this item?') ? this.deleteTreeItem(node) : ''
                        }
                      >
                        x
                      </button>
                    </div>
                  ]
                })}
              />
              {this.state &&
                (this.state.editing as any) === 'true' && (
                  <Button bsStyle="primary" onClick={this.saveDragDrop}>
                    Save Menu Order
                  </Button>
                )}

              <DuplucateNav clientId={this.props.clientId} />
            </div>
          </Tab>
          <Tab eventKey={2} title="Application Settings">
            <ApplicationControl clientId={this.props.clientId} />
          </Tab>
        </Tabs>
      </Grid>
    );
  }
}

const mapState = (state) => ({
  navigationMenuAdmin: state.navigationMenuAdmin
});
const mapDispatch = (dispatch) => ({
  actions: bindActionCreators(
    {
      getMenuadmin,
      updateMenuadmin,
      deleteMenuadminById,
      createMenuadmin,
      getLookupByKey,
      navigationMenuAdminUpdate,
      getMenuadminReadOnly,
      patchMenuadmin,
      getApplication,
      showBootstrapAlert,
      lastSubmittedForm,
      getMenuAdminProviders
    },
    dispatch
  )
});
export default connect(
  mapState,
  mapDispatch
)(NavAdmin as any) as any;
