import * as React from 'react';
import { withResource } from 'xcel-react-core';

class DragAndDrop extends React.Component<any, any> {
  [x: string]: any;
    dropRef: any;

    constructor(props: any) {
        super(props);
        this.dropRef = React.createRef()
        this.state = { 
            dragging: false,
            droppedImage: null
        }
    }

      componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
      }
    
      componentWillUnmount() {
        let div = this.dropRef.current
        const el = document.getElementById(div);
        if(el) {
            div.removeEventListener('dragenter', this.handleDragIn)
            div.removeEventListener('dragleave', this.handleOut)
            div.removeEventListener('dragover', this.handleDrag)
            div.removeEventListener('drop', this.handleDrop)
        }
      }
    
      handleDrag = e => {
        e.preventDefault()
        e.stopPropagation()
      }
      
      handleDragIn = e => {
        e.preventDefault()
        e.stopPropagation()
        if(e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({
                dragging: true
            })
        }
      }
      
      handleDragOut = e => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({dragging: false})
      }
      
      handleDrop = e => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({dragging:false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0 && e.dataTransfer.files.length < 2) {
            this.setState({droppedImage: e.dataTransfer.files[0]})
            e.dataTransfer.clearData()
        }
      }

      dragAndDropImage = () => {
        this.props.droppedImage(this.state.droppedImage)
      }

    render() {
        {
          if(this.state.droppedImage === null) {
              return (
                <div className="dragAndDrop" ref={this.dropRef} data-droppedimage={this.state.droppedImage}>
                    <i className={this.props.labels.iconUploadClassName} />
                    <div>{this.props.labels.dragAndDropText}</div>                        
                </div>
              )
          } else {
              return (
                <div>
                  <div />
                    {this.dragAndDropImage()}
                </div>
              )
          }
        }
    }
}

const mapResource = (getResource) => ({
  labels: {
    dragAndDropText: getResource('AvatarEditor.dragAndDropText', 'Drag and drop or choose a file to upload below.'),
    iconUploadClassName: getResource('AvatarEditor.iconUploadClassName', 'fa fa-upload')
  }
});
   
export default withResource(mapResource)(DragAndDrop)
