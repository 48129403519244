import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AuthLinkBehavior } from 'rsv8-auth';
import { Card, Link, Text } from 'rsv8-components';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import { RadialGauge } from '../../components/RadialGauge/RadialGauge';
import { BullseyeSvg } from './BullseyeSvg';

const Container = styled.div`
  background: #0a5b89;
  padding: 30px;
  height: 415px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Heading = styled(Text)`
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #cbe3e9;
  align-self: flex-start;
`;
// interface Props {}
const TrendingInfoText = styled(Text)`
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.57 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #fbd476 !important;
`;
const StatusText = styled(Text)`
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -3px;
  text-align: center;
  color: #fbd476;
  margin-right: 5px;
`;

const HintText = styled(Text)`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #cae3e9;
  text-align: center;
`;

const BudgetStatus: React.FunctionComponent = (props) => {
  return (
    <Card>
      <Container>
        <Heading>Your Budget Status</Heading>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRight: '1px dashed white',
              justifyContent: 'center',
              padding: '15px'
            }}
          >
            <div style={{ textAlign: 'center', marginBottom: '4px' }}>
              <div>
                <TrendingInfoText>Your 2019 budget</TrendingInfoText>
              </div>
              <div>
                <TrendingInfoText>is trending</TrendingInfoText>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <StatusText>on track</StatusText>
              <BullseyeSvg />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '15px'
            }}
          >
            <RadialGauge id="radialgauge1" percentage={79} />
          </div>
        </div>

        <HintText>Helpful Hints</HintText>
        <AuthLinkBehavior>
          <Link
            href="https://reporting.rewardstation.net/Reporting/Report.aspx?reportPath=FD-RollupM-1926-BudgetSummary-Program&auth="
            themeVariation="secondary-alternate-button"
            style={{ width: '80%', alignSelf: 'center', textAlign: 'center' }}
          >
            View budget summary report
          </Link>
        </AuthLinkBehavior>
      </Container>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch)
});

export default register('rsv8-data-vis/BudgetStatus')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)(BudgetStatus);
