import * as React from 'react';
import { PageRenderer } from 'rsv8-cms';

class ContentPage extends React.Component<any> {
  shouldComponentUpdate(nextProps: any) {
    const { location } = this.props;
    return location.pathname !== nextProps.location.pathname || location.hash !== nextProps.location.hash;
  }
  render() {
    return <PageRenderer {...this.props} path={this.props.location.pathname.substring(1)} />;
  }
}

export default ContentPage;
