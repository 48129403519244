import * as React from 'react';
import { NavDropdown as BSNavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MenuContext } from '../ModularMenus/NavigationContext';

interface NotificationsDropdownProps {
  id: any;
  dispatch: any;
  className: any;
  title: any;
  targetUrl: any;
}

class NotificationsdropDown extends React.Component<NotificationsDropdownProps> {
  render() {
    return (
      <MenuContext.Consumer {...this.props}>
        {({ isOpen, open, closeAll}: any) => {          
          const handleClick = (href) => (e) => {
            if (isDropdownDropped() === false) {
              open(this.props.id);              
            } else {
              closeAll();
            }
                        
          };         

          const isDropdownDropped = () => {
            return isOpen(this.props.id);
          };
          const stopOnTouchPropagation = (e) => {
            e.stopPropagation();
          };

          return (
            <BSNavDropdown
              id={this.props.id.toString()}
              className={this.props.className && this.props.className}              
              open={isDropdownDropped()}
              title={this.props.title}
              onClick={handleClick(this.props.targetUrl)}
              onTouchStart={stopOnTouchPropagation}
              onBlur={closeAll}
            >
              {this.props.children}
            </BSNavDropdown>
          );
        }}
      </MenuContext.Consumer>
    );
  }
}

export default connect()(NotificationsdropDown as any) as any;
