export const TRANSFORM_TEAM_MEMBERS = 'rsv8-nomination/TRANSFORM_TEAM_MEMBER';
export const CREATE_PROCESS_REQUEST = 'rsv8-nomination/CREATE_PROCESS_REQUEST';
export const CREATE_PROCESS_SUCCESS = 'rsv8-nomination/CREATE_PROCESS_SUCCESS';
export const CREATE_PROCESS_FAILURE = 'rsv8-nomination/CREATE_PROCESS_FAILURE';

export const NOMINEE_SEARCH_SUCCESS = 'rsv8-nomination/NOMINEE_SEACH_SUCCESS';
export const NOMINEE_SEARCH_FAILURE = 'rsv8-nomination/NOMINEE_SEACH_FAILURE';
export const NOMINEE_SEARCH_REQUEST = 'rsv8-nomination/NOMINEE_SEACH_REQUEST';

export const TOGGLE_TEAM_NOMINEE = 'rsv8-nomination/SELECT_TEAM_NOMINEE';

export const SET_NOMINEE_SEARCH_FILTERS = 'rsv8-nomination/SET_NOMINEE_SEARCH_FILTERS';

export const GET_AWARDS_REQUEST = 'rsv8-nomination/GET_AWARDS_REQUEST';
export const GET_AWARDS_SUCCESS = 'rsv8-nomination/GET_AWARDS_SUCCESS';
export const GET_AWARDS_FAILURE = 'rsv8-nomination/GET_AWARDS_FAILURE';

export const GET_MY_AWARDS_REQUEST = 'rsv8-nomination/GET_MY_AWARDS_REQUEST';
export const GET_MY_AWARDS_SUCCESS = 'rsv8-nomination/GET_MY_AWARDS_SUCCESS';
export const GET_MY_AWARDS_FAILURE = 'rsv8-nomination/GET_MY_AWARDS_FAILURE';
export const SET_DEFAULT_TEAM_MEMBER_VALUE = 'rsv8-nomination/SET_DEFAULT_TEAM_MEMBER_VALUE';

export const GET_AWARD_DETAIL_SUCCESS = 'rsv8-nomination/GET_AWARD_DETAIL_SUCCESS';
export const GET_AWARD_DETAIL_FAILURE = 'rsv8-nomination/GET_AWARD_DETAIL_FAILURE';

// Products
export const GET_PRODUCTS_REQUEST = 'rsv8-nomination/GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'rsv8-nomination/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'rsv8-nomination/GET_PRODUCTS_FAILURE';
export const SET_INDIVIDUAL_NOMINATION_PRODUCT = 'rsv8-nomination/SET_INDIVIDUAL_NOMINATION_PRODUCT';
export const SET_INDIVIDUAL_NOMINATION_OPTION = 'rsv8-nomination/SET_INDIVIDUAL_NOMINATION_OPTION';
export const SET_INDIVIDUAL_NOMINATION_PRODUCT_VALUE = 'rsv8-nomination/SET_INDIVIDUAL_NOMINATION_PRODUCT_VALUE';

// Team
export const SET_TEAM_NOMINATION_PRODUCT = 'rsv8-nomination/SET_TEAM_NOMINATION_PRODUCT';
export const SET_TEAM_NOMINATION_OPTION = 'rsv8-nomination/SET_TEAM_NOMINATION_OPTION';
export const SET_TEAM_NOMINATION_PRODUCT_VALUE = 'rsv8-nomination/SET_TEAM_NOMINATION_PRODUCT_VALUE';

export const GET_MY_PRODUCTS_REQUEST = 'rsv8-nomination/GET_MY_PRODUCTS_REQUEST';
export const GET_MY_PRODUCTS_SUCCESS = 'rsv8-nomination/GET_MY_PRODUCTS_SUCCESS';
export const GET_MY_PRODUCTS_FAILURE = 'rsv8-nomination/GET_MY_PRODUCTS_FAILURE';

export const GET_PRODUCT_DETAIL_SUCCESS = 'rsv8-nomination/GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'rsv8-nomination/GET_PRODUCT_DETAIL_FAILURE';

export const SELECT_NOMINEE = 'rsv8-nomination/SELECT_NOMINEE';
export const SELECT_NOMINATION_POINTS = 'rsv8-nomination/SELECT_NOMINATION_POINTS';
export const SELECT_TEAM_NOMINATION_POINTS = 'rsv8-nomination/SELECT_TEAM_NOMINATION_POINTS';
export const SELECT_TEAM_NOMINATION_PRODUCT_POINT_VALUE = 'rsv8-nomination/SELECT_TEAM_NOMINATION_PRODUCT_POINT_VALUE';
export const ADD_TEAM_MEMBERS = 'rsv8-nomination/ADD_TEAM_MEMBER';

export const PENDING_ADD_TEAM_MEMBER = 'rsv8-nomination/PENDING_ADD_TEAM_MEMBER';
export const PENDING_REMOVE_TEAM_MEMBER = 'rsv8-nomination/PENDING_REMOVE_TEAM_MEMBER';

export const REMOVE_TEAM_MEMBERS = 'rsv8-nomination/REMOVE_TEAM_MEMBERS';

export const SELECT_AWARD = 'rsv8-nomination/SELECT_AWARD';
export const SELECT_PRODUCT = 'rsv8-nomination/SELECT_PRODUCT';
export const SELECT_PRODUCT_OPTION_POINT_VALUE = 'rsv8-nomination/SELECT_PRODUCT_OPTION_POINT_VALUE';
export const SELECT_PRODUCT_OPTION_ID = 'rsv8-nomination/SELECT_PRODUCT_OPTION_ID';
export const SELECT_POINT_VALUE = 'rsv8-nomination/SELECT_POINT_VALUE';
export const SELECT_PRODUCT_VALUE = 'rsv8-nomination/SELECT_PRODUCT_VALUE';
export const SELECT_TEAM_AWARD = 'rsv8-nomination/SELECT_TEAM_AWARD';
export const SELECT_TEAM_PRODUCT = 'rsv8-nomination/SELECT_TEAM_PRODUCT';
export const SELECT_CUSTOM_FIELDS = 'rsv8-nomination/SELECT_CF';
export const SELECT_TEAM_CUSTOM_FIELDS = 'rsv8-nomination/SELECT_TEAM_CUSTOM_FIELDS';
export const CLEAR_CURRENT_NOMINEE = 'rsv8-nomination/CLEAR_CURRENT_NOMINEE';
export const CLEAR_CURRENT_CUSTOM_FIELDS = 'rsv8-nomination/CLEAR_CURRENT_CUSTOM_FIELDS';
export const CLEAR_CURRENT_NOMINATION_DATA = 'rsv8-nomination/CLEAR_CURRENT_NOMINATION_DATA';
export const SET_CLEAR_CURRENT_NOMINATION_DATA = 'rsv8-nomination/SET_CLEAR_CURRENT_NOMINATION_DATA';
export const CLEAR_CURRENT_AWARD = 'rsv8-nomination/CLEAR_CURRENT_AWARD';
export const CLEAR_CURRENT_PRODUCT = 'rsv8-nomination/CLEAR_CURRENT_PRODUCT';
export const SELECT_TEAM_MEMBER_CUSTOM_FIELDS = 'rsv8-nomination/SELECT_TEAM_MEMBER_CUSTOM_FIELDS';
export const SAVE_NOMINATION_SUCCESS = 'rsv8-nomination/SAVE_NOMINATION_SUCCESS';
export const SAVE_NOMINATION_FAILURE = 'rsv8-nomination/SAVE_NOMINATION_FAILURE';
export const DELETE_NOMINATION_DRAFT_FAILURE = 'rsv8-nomination/DELETE_NOMINATION_DRAFT_FAILURE';
export const DELETE_TEAM_MEMBER = 'rsv8-nomination/DELETE_TEAM_MEMBER';

export const SUBMIT_NOMINATION_SUCCESS = 'rsv8-nomination/SUBMIT_NOMINATION_SUCCESS';
export const SUBMIT_NOMINATION_FAILURE = 'rsv8-nomination/SUBMIT_NOMINATION_FAILURE';
export const SET_TEAM_NAME = 'rsv8-nomination/SET_TEAM_NAME';
export const NOMINATE_INDIVIDUAL = 'rsv8-nomination/NOMINATE_INDIVIDUAL';
export const NOMINATE_GROUP = 'rsv8-nomination/NOMINATE_GROUP';

export const CLEAR_INDIVIDUAL_NOMINATION = 'rsv8-nomination/CLEAR_INDIVIDUAL_NOMINATION';
export const CLEAR_TEAM_NOMINATION = 'rsv8-nomination/CLEAR_TEAM_NOMINATION';

export const SET_NOMINEE_SCROLL_ID = 'rsv8-nomination/SET_NOMINEE_SCROLL_ID';
export const SET_VISIBLE_NOMINEES = 'rsv8-nomination/SET_VISIBLE_NOMINEES';
export const ADD_VISIBLE_NOMINEES = 'rsv8-nomination/ADD_VISIBLE_NOMINEES';
export const SET_LATEST_SEARCH = 'rsv8-nomination/SET_LATEST_SEARCH';
export const SET_TOTAL_SEARCH_RESULTS = 'rsv8-nomination/SET_TOTAL_SEARCH_RESULT';
export const SET_VISIBLE_AWARDS = 'rsv8-nomination/SET_VISIBLE_AWARDS';
export const SET_VISIBLE_PRODUCTS = 'rsv8-nomination/SET_VISIBLE_PRODUCTS';
export const SET_INDIVIDUAL_NOMINATION_PROPERTY = 'rsv8-nomination/SET_INDIVIDUAL_NOMINATION_PROPERTY';

export const SAVE_TEAM_NOMINATION_SUCCESS = 'rsv8-nomination/SAVE_TEAM_NOMINATION_SUCCESS';
export const SAVE_TEAM_NOMINATION_FAILURE = 'rsv8-nomination/SAVE_TEAM_NOMINATION_FAILURE';

export const SET_NOMINATION_ACTIVITY_SORT = 'rsv8-nomination/SET_NOMINATION_ACTIVITY_SORT';
export const SET_NOMINATION_ACTIVITY_FILTERS = 'rsv8-nomination/SET_NOMINATION_ACTIVITY_FILTERS';
export const SET_NOMINATION_ACTIVITY_MODAL_VISIBILITY = 'rsv8-nomination/SET_NOMINATION_ACTIVITY_MODAL_VISIBILITY';
export const SET_NOMINATION_ACTIVITY_MODAL_ID = 'rsv8-nomination/SET_NOMINATION_ACTIVITY_MODAL_ID';
export const SET_INDIVIDUAL_NOMINATION = 'rsv8-nomination/SET_INDIVIDUAL_NOMINATION';
export const SET_TEAM_NOMINATION = 'rsv8-nomination/SET_TEAM_NOMINATION';
export const HIDE_NOMINATION_DETAIL_MODAL = 'rsv8-nomination/HIDE_NOMINATION_DETAIL_MODAL';
export const SHOW_NOMINATION_DETAIL_MODAL = 'rsv8-nomination/SHOW_NOMINATION_DETAIL_MODAL';

export const SET_NOMINATION_TEAM_MEMBER_IDS = 'rsv8-nomination/SET_NOMINATION_TEAM_MEMBER_IDS';
export const CLEAR_NOMINATION_TEAM_MEMBER_IDS = 'rsv8-nomination/CLEAR_NOMINATION_TEAM_MEMBER_IDS';
