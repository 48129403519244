import * as React from 'react';
import { HeaderFour, Text } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const TitleMobile = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;

const TitleIcon = styled.img`
  height: 18px;
  float: left;
  margin-top: 5px;
  margin-right: 8px;
`;

interface Props {
  text: string;
  textMobile: string;
}

const FilterTitle: React.SFC<Props> = ({ text, textMobile }) => (
  <div>
    <HeaderFour themeVariation="secondary-bold" className="hidden-xs">
      {text}
    </HeaderFour>
    <TitleMobile className="hidden-lg hidden-md hidden-sm">
      <TitleIcon src="/clientassets/images/icons/filter.png" />
      <Text>{textMobile}</Text>
    </TitleMobile>
  </div>
);

const mapResourceToProps = (getResource): Props => ({
  text: getResource('FilterTitle.text', 'Filter by'),
  textMobile: getResource('FilterTitle.textMobile', 'Filter')
});

export default withResource(mapResourceToProps)(FilterTitle);
