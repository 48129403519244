import {
  DropdownButton,
  Form,
  FormControl,
  InputGroup
  } from 'react-bootstrap';
import styled from 'styled-components';

export const CatalogHeaderNavStyledWrap = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    margin: 0 0 0 20px;
  }
  @media (max-width: 767px) {
    padding-top: 20px;
  }
  .navbar-brand.nav-title a {
    color: #4c4f4f;
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0;
    text-decoration: none;
  }

  .navbar {
    background: #f7f7f7;
    border-radius: 0;
    margin-bottom: 0;
    min-height: auto;

    .navbar-form {
      @media (max-width: 768px) {
        display: flex;
      }

      @media (min-width: 481px) and (max-width: 768px) {
        justify-content: center;
      }
    }

    .nav {
      max-width: 100%;
      overflow-x: auto;
      white-space: nowrap;

      @media screen and (max-width: 768px) {
        margin-bottom: 0;
        margin-top: 0;
      }

      li {
        border-bottom: 4px solid transparent;
        display: inline-block;
        float: none;
        margin: 0 15px;

        &.open {
          position: initial;

          a {
            background-color: transparent;
          }
        }

        a {
          color: #606060;
          font-size: 14px;
          @media screen and (max-width: 768px) {
            padding-bottom: 15px;
            padding-top: 15px;
          }
        }

        &.nav-link-selected {
          border-bottom: 4px solid #00a9e0;
        }

        .dropdown-menu {
          border: 0;
          border-radius: 0;
          box-shadow: 0px 0px 21px -1px rgba(28, 39, 76, 0.13);

          li {
            border-bottom: 0;
            display: block;
            margin: 0;
            padding: 0 25px;

            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }
  .form-group {
    display: inline-block;
  }
`;

export const CategoryDropdownButton = styled(DropdownButton)`
  background: #eff5f7;
  border: solid 1px rgba(81, 81, 81, 0.3);
  border-right: 0;
  color: #4c4f4f;
  padding-right: 30px;
  text-align: left;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    background: #eff5f7;
    border: solid 1px rgba(81, 81, 81, 0.3);
    border-right: 0;
    color: #4c4f4f;
    outline: none !important;
    text-decoration: none;
  }

  .caret {
    position: absolute;
    right: 5px;
    top: 45%;
  }
` as any;

export const SearchFormControl = styled(FormControl)`
  @media (max-width: 768px) {
    border-radius: 0 3px 3px 0 !important;
    width: auto;
  }
` as any;

export const SearchButton = styled(InputGroup.Button)`
  @media (max-width: 768px) {
    display: none;
  }
` as any;

export const NavSearch = styled(Form)`
  width: 100%;
` as any;

export const SearchInputGroup = styled(InputGroup)`
  border: 0;
  display: flex !important;
  width: 100%;

  .input-group-btn {
    width: auto;
  }
` as any;
