import * as React from 'react';
import { withRouter } from 'react-router';

class ScrollToTop extends React.Component<any, any> {
  componentDidUpdate(prevProps: any) {
    const hasChanged = ['search', 'pathname'].filter(key => this.props.location[key] !== prevProps.location[key]).length > 0
    if (hasChanged) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
