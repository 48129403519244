import styled from 'styled-components';

export const TierBreakdownTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 0 20px;
  margin: 0;

  .tier-breakdown-title {
    margin: 0 !important;
    color: rgba(80, 93, 111, 1) !important;
    font-size: 32px !important;
    line-height: 38px !important;
  }

  .overlay-popover-icon {
    margin-left: 10px;
    position: static;
  }
`;

export const TierBreakdownTable = styled.div`
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
  ::-webkit-scrollbar-track {
    border-radius: 3.5px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #e1e1e1;
  }
`;

export const TemplateTableHeaderRow = styled.div<{ columns: any[] }>`
  display: none;
  padding: 0 20px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-rows: 45px;
    grid-template-columns: ${(props) => props.columns && `250px repeat(${props.columns.length - 1}, auto)`};
    margin: 0 auto;
  }
`;

export const TemplateTableHeaderCell = styled.div`
  font-family: open-sans;
  font-size: 10px;
  font-weight: 800;
  color: #505d6f;
  text-transform: uppercase;
  text-align: center;
  line-height: 14px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
  }
`;

export const TemplateTableCellContainer = styled.div<{ columns: any[] }>`
  display: grid;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    grid-template-rows: 65px 75px;
    grid-template-columns: ${(props) => props.columns && `repeat(${props.columns.length - 1}, auto)`};
  }

  @media screen and (min-width: 768px) {
    grid-template-rows: 45px;
    grid-template-columns: ${(props) => props.columns && `250px repeat(${props.columns.length - 1}, auto)`};
  }
`;

export const TemplateTableCellHeader = styled.div<{ columns: any[] }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  color: #505d6f;
  background-color: #fafafa;
  text-align: center;
  line-height: 14px;

  @media screen and (max-width: 767px) {
    grid-row: 1 / 2;
    grid-column: ${(props) => props.columns && `1 / ${props.columns.length}`};
    border-bottom: 1px solid #b2b2b2;
  }

  @media screen and (min-width: 768px) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    border-right: 1px solid #b2b2b2;
    padding: 0 15px;
  }
`;

export const TemplateTableCellValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  color: #505d6f;
  background-color: #fff;
  text-align: center;

  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }

  @media screen and (min-width: 768px) {
    padding: 0 15px;
  }
`;
