import { UserProfileModel } from 'xcel-api-generator/dist/user';
import { createTypeSelector } from 'xcel-redux-orm';
import { camelCaseObject, parseJwt } from '../../util';
import { getAuth } from '../selectors';

const userSelector = {
  ...createTypeSelector('userProfileModel'),
  get: (state) => userSelector.selectMany(state, null)[0],
  getUserFromAccessToken: (state) => {
    const auth = getAuth(state);
    return userSelector.getUserWithToken(auth.accessToken);
  },
  getUserWithToken: (token) => {
    return camelCaseObject(parseJwt(token));
  }
} as any;
export { UserProfileModel };
export default userSelector;
