import { destroyRequest } from 'xcel-redux-orm';
import { routeSelector } from '../index';
import cmsAdminApi from './cmsAdminApi';
import { contentActions } from './index';

const { getAdminRoutes, createAdminContentTypesByIdRoutes, deleteAdminRoutesById } = cmsAdminApi;

const mergeFilterParam = (filters: any = {}, param: string, value: any) => {
  const filterList = [];
  if (filters[param]) {
    filterList.push(filters[param]);
  }
  filterList.push(value);
  return { ...filters, [param]: filterList.join(' && ') };
};
let searchDebounceId;
const routeActions = {
  getRoot: (contentTypeId) => async (dispatch, getState) => {
    try {
      const response = await dispatch(routeActions.get(contentTypeId, { filter: 'parentId = null' }));
      const rootRoute = routeSelector.searchOne(
        getState(),
        (route) => route.contentType && route.contentType.id === contentTypeId && !route.parentId
      );
      if (rootRoute) {
        dispatch(routeActions.setActive(contentTypeId, [rootRoute.id]));
      }

      return response;
    } catch (e) {
      throw e;
    }
  },

  get: (contentTypeId, filters?: any) =>
    getAdminRoutes({
      ...mergeFilterParam(filters, 'filter', `contentType.id = "${contentTypeId}"`)
    }),
  search: (contentTypeId, search: string) => (dispatch, getState) => {
    clearTimeout(searchDebounceId);
    searchDebounceId = setTimeout(async () => {
      if (search === '') {
        return dispatch(routeActions.getRoot(contentTypeId));
      }
      const response = await dispatch(
        routeActions.get(contentTypeId, {
          filter: `(contents.key.contains("${search}") || routeNodeName.contains("${search}"))`
        })
      );
      const routes = routeSelector.selectLast(getState(), 'getRoutes');
      if (routes) {
        dispatch(routeActions.setActive(contentTypeId, routes.map((item) => item.id)));
      }
      return response;
    }, 250);
  },
  delete: (contentTypeId: string, id: any) => async (dispatch, getState) => {
    try {
      await dispatch(deleteAdminRoutesById({ id }));
    } catch (e) {
      alert(`Cannot delete route: ${e}`);
    }
    dispatch(destroyRequest('getAdminRoutes'));
    let activeRoute = routeSelector.getActive(getState(), contentTypeId)[0];
    dispatch(routeActions.getById(contentTypeId, activeRoute.id));
  },
  create: (contentTypeId: string, parentRouteId: number, routeNodeName: string) => (dispatch, getState) => {
    dispatch(createAdminContentTypesByIdRoutes({ id: contentTypeId, body: { parentRouteId, routeNodeName } })).then(
      () => {
        dispatch(destroyRequest('getAdminRoutes'));
        dispatch(routeActions.getById(contentTypeId, parentRouteId));
      }
    );
  },
  patch: cmsAdminApi.patchAdminRoutesById,
  getById: (contentTypeId, id: any) => async (dispatch, getState) => {
    const response = await dispatch(routeActions.get(contentTypeId, { filter: `id = ${id}` }));
    dispatch(routeActions.setActive(contentTypeId, [id]));
    return response;
  },
  setActive: (contentTypeId, id: any) => contentActions.setActiveProperty(contentTypeId, 'routeId', id)
};
export default routeActions;
