import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'rsv8-components';
import { CustomFields } from 'rsv8-customfields';
import { Field, formSelectors, reduxForm } from 'rsv8-forms';
import { register, withContent } from 'xcel-react-core';
import { salesActions } from '../../redux/actions';
interface Props {
  qs: { [index: string]: any };
  createSalesEntry: any; // typeof salesActions.createApplicationByAppKeyEntry;
  formValues: any;
  handleSubmit: any;
}
const SalesEntryForm: React.FunctionComponent<Props> = (props) => {
  const submit = (values) => {
    props.createSalesEntry(props.qs, values);
  };

  return (
    <form
      onSubmit={props.handleSubmit(submit)}
    >
      <Field
        name="productId"
        type="text"
        label="Product Name"
        component="bs-input"
      />
      <Field
        name="quantity"
        type="text"
        label="Quantity"
        component="bs-input"
      />
      {props.qs.appKey && <CustomFields formType="SalesEntry" groupKey={props.qs.appKey} />}

      <Button
      >
        Submit
      </Button>
    </form>
  );
};

const mapStateToProps = (state: any, ownProps) => ({
  formValues: formSelectors.getFormValues('SalesEntryForm')(state),
  formError1: formSelectors.getFormSubmitErrors('SalesEntryForm')(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSalesEntry: salesActions.createSalesEntry
    },
    dispatch
  );

export default register('rsv8-sales-entry/SalesEntryForm')(
  withContent(),
  reduxForm({ form: 'SalesEntryForm' }),
  connect<any>(
    mapStateToProps,
    mapDispatchToProps
  )
)(SalesEntryForm);
