import { cacheService } from 'xcel-cache-service';
import { reduxObserver } from 'xcel-observer';
import { cachePlugin, createReduxModule, crossTabPlugin, ReduxModule } from 'xcel-redux-module';
import reducers from './app/redux';
import { themePlugin } from './config';

const themeMainModule = createReduxModule({
  name: 'rsv8-theme',
  reducers,
  init: themePlugin.actions.init,
});

const themeModuleWithCache: ReduxModule = cachePlugin(cacheService)(themeMainModule);
const themeModule: ReduxModule = crossTabPlugin()(themeModuleWithCache);
reduxObserver.addModule(themeModule);
export default themeModule;
