import { HeaderThree, Link } from 'rsv8-components';
import styled from 'styled-components';

export const ItemCarouselContainer = styled.div`
  .slick-track {
    display: flex;
    height: 100%;
    left: -1px;
    overflow: hidden;
    border: none;

    .slick-slide {
      height: auto;
      overflow: hidden;
      position: relative;

      & > div {
        height: 100%;
      }
    }
  }
`;

export const CarouselHeader = styled.div`
  display: flex;
  h2 {
    margin: 20px 20px 20px 0;
  }
`;

export const CarouselArrow = styled.div`
  &.slick-arrow {
    border: none;
    cursor: pointer;
    display: block;
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.2));
    font-size: inherit;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #fff;
    &::before {
      display: none;
    }
  }
  &.slick-next {
    right: 5px;
    & > span {
      margin-left: 2px;
    }
  }
  &.slick-prev {
    left: 5px;
    & > span {
      margin-right: 2px;
    }
  }
  &.slick-disabled {
    display: none;
  }
  span {
    color: #4c4f4f;
    line-height: 40px;
    font-size: 2.5em;
  }
`;

export const ItemCarouselHeading = styled(HeaderThree)`
  color: #4c4f4f;
  display: inline-block;
  margin-bottom: 25px;
`;

export const ItemCarouselSubHeading = styled.div`
  font-size: 16px;
  margin: 10px 0 0 5px;

  @media (min-width: 769px) {
    display: inline-block;
  }
`;

export const AllLinkContainer = styled.span`
  align-self: center;
  margin-bottom: 17px;
  margin-left: 10px;
`;

export const CardLink = styled(Link)`
  color: #4192a5;
  font-size: 14px;
  text-align: center;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    text-decoration: none;
  }
` as any;

export const ImageContainer = styled.div`
  display: contents;
  img {
    width: 150px;
    height: auto;
    padding-bottom: 20px;
  }
` as any;

export const CustomCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
  border-radius: 20px;
  padding: 3rem 2rem 2rem;
` as any;

export const CustomCardImage = styled.img`
  width: 100%;
  max-width: 207px;
` as any;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 354px;
  justify-content: center;
`;

export const ItemContainer = styled.div`
  height: 100%;
`;

export const CustomCard = styled.div`
  &:focus {
    outline: none;
  }
`;

export const EmptyImage = styled.img`
  width: 200px;
  height: auto;
`;
