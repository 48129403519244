import * as React from 'react';
import { DropdownButton, MenuItem, DropdownButtonProps as BaseProps } from 'react-bootstrap';

const renderMenuItem = (child, i) => <MenuItem key={i} children={child} />;

export type Overwrite<T1, T2> = { [P in Exclude<keyof T1, keyof T2>]: T1[P] } & T2;

export type DropdownButtonProps = Partial<BaseProps> & { id: string };

const Dropdown: React.SFC<DropdownButtonProps> = ({ title, children, ...rest }) => (
  <DropdownButton title={title ? title : ''} {...rest}>
    {children && React.Children.map(children, renderMenuItem)}
  </DropdownButton>
);

export default Dropdown;
