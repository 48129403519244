import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Button, Loader } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import { Context } from '../../../types';
import { getHumanReadableStepCount, getHumanReadableStepNumber } from '../../redux/selectors';
import { WizardProgressBar } from '../ProgressBar';
import { WizardPanel, WizardRow } from './styles';

export interface WizardBarProps {
  name: string;
  wizard: Context;
}

type InternalProps = WizardBarProps & {
  currentIndex: number;
  stepCount: number;
  nextButtonText: any;
  prevButtonText: any;
};

const Bar = (props: InternalProps) => {
  const [submitted, setSubmitted] = (React as any).useState(false);
  const { steps, currentStep, isFirstStep, decrement, increment } = props.wizard;
  const handleIncrement = () => {
    const currentWizardStep = steps.find((step) => {
      return step.component === currentStep.component;
    });
    if (currentWizardStep.title === 'Confirm' || currentWizardStep.title === 'Review') {
      setSubmitted(true);
    }
    increment();
  };
  const handleDecrement = () => {
    if (submitted) {
      setSubmitted(false);
    }
    decrement();
  };

  return (
    <WizardPanel>
      <WizardRow>
        <Col xs={4}>
          {!currentStep.hideBackButton && !isFirstStep ? (
            <Button disabled={isFirstStep} onClick={() => handleDecrement()}>
              {currentStep.backText || props.prevButtonText}
            </Button>
          ) : null}
        </Col>
        <Col xs={16}>
          <WizardProgressBar current={props.currentIndex} total={props.stepCount - 1} />
        </Col>
        <Col xs={4}>
          {!currentStep.hideNextButton ? (
            <React.Fragment>
              {submitted ? (
                <Loader width={30} />
              ) : (
                <Button onClick={() => handleIncrement()}>{currentStep.nextText || props.nextButtonText}</Button>
              )}
            </React.Fragment>
          ) : null}
        </Col>
      </WizardRow>
    </WizardPanel>
  );
};

const mapState = (state, ownProps) => {
  return {
    currentIndex: getHumanReadableStepNumber(state, ownProps.name),
    stepCount: getHumanReadableStepCount(state, ownProps.name)
  };
};

const compose = register('rsv8-wizard/WizardProgressBar');

const mapResource = (getResource) => ({
  nextButtonText: getResource('wizard.nextButtonLabel', 'Next'),
  prevButtonText: getResource('wizard.prevButtonLabel', 'Prev')
});

export default compose(
  connect(
    mapState,
    null
  ),
  withResource(mapResource)
)(Bar) as React.SFC<WizardBarProps>;
