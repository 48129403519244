import * as React from 'react';
import { connect } from 'react-redux';
import { register, withResource } from 'xcel-react-core';
import { nomineeSearchSelectors } from '../../redux/selectors/index';
import { Header } from '../Header/index';

export interface SearchResultHeaderProps {
  total: number;
  current: number;
}

interface InternalProps extends SearchResultHeaderProps {
  resultText: any;
  headerText: any;
}

const SearchResultHeaderBase: React.SFC<InternalProps> = (props) => (
  <Header text={props.headerText}>{props.resultText}</Header>
);

const mapResource = (getResource) => ({
  resultText: getResource('nominationProcess.nomineeResultCountText', 'Showing {current} results out of {total}'),
  headerText: getResource('nominationProcess.nomineeResultHeaderText', 'Search Results')
});

export const SearchResultHeader = register('rsv8-nomination/SearchResultsHeader')(withResource(mapResource))(
  SearchResultHeaderBase
) as React.SFC<SearchResultHeaderProps>;

const mapNomineeState = (state) => ({
  total: nomineeSearchSelectors.getTotalNomineeCount(state),
  current: nomineeSearchSelectors.getVisibleNominees(state).length
});

export const NomineeSearchResultHeader = connect(mapNomineeState)(SearchResultHeader) as React.ComponentClass<{}>;
