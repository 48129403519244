import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withResource } from 'xcel-react-core';
import { ForgotUserPass } from '../../../components/Layout';

const UserHasNoEmail = ({ loginHelpLinkPath, resource: { headerText, noEmailText } }) => (
  <ForgotUserPass loginHelpLinkPath={loginHelpLinkPath} headerText={headerText} noEmailText={noEmailText} />
);

const mapState = () => ({});

const mapDispatch = (dispatch) => ({});

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource(
      'userHasNoEmail.headerText',
      "We're sorry but we don't have an email address on file for your account."
    ),
    noEmailText: getResource(
      'userHasNoEmail.noEmailText',
      'Please contact Own It Honors Program Support by calling 888-402-7286 in the United States or at + 1-770-668-2436 outside of the United States or send an e-mail to the HR Service Center at'
    )
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(UserHasNoEmail) as any;
