import { project } from 'ramda';
import { CartItemModel } from 'xcel-api-generator/dist/catalog';
import { destroy, destroyRequest } from 'xcel-redux-orm';
import { ResourceObject } from 'xcel-redux-orm';
import { CART_STATE, ORDER_STATE, ROOT_STATE } from '../../../constants';
import { cartSelector, orderConfirmationSelector } from '../../selectors/cartSelectors';
import { getAccountSummary } from '../account/getSummary';
import { cartApiActions } from '../cart/cartApiActions';
import { orderApiActions } from './orderApiActions';

const constructCart = (getState) => {
  let cart = project<ResourceObject<CartItemModel>, { productId: string; quantity: number; productOptionId: string }>(
    ['productId', 'quantity', 'productOptionId'],
    cartSelector.selectMany(getState(), getState()[ROOT_STATE][CART_STATE].currentCart)
  );

  cart.forEach((item) => {
    if (!item.productOptionId) {
      item.productOptionId = '';
    }
  });

  return cart;
};

const constructShippingInfo = (userInfo) => {
  return {
    accountNumber: userInfo.id.toString(),
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    businessName: userInfo.businessName || null,
    addressOne: userInfo.addressOne,
    addressTwo: userInfo.addressTwo || null,
    city: userInfo.city,
    state: userInfo.state || null,
    province: userInfo.province || null,
    postalCode: userInfo.postalCode,
    country: userInfo.countryCode,
    requireCPF: userInfo.isCPFRequired,
    cpf: userInfo.cpf || null,
    phone: userInfo.phone,
    email: userInfo.emailAddress,
    participantFirstName: userInfo.participantFirstName || null,
    participantLastName: userInfo.participantLastName || null,
    participantSSN: userInfo.ssn || null,
    isThisSSN: userInfo.isSSNRequired || false,
    alternateEmail: userInfo.altEmailAddress || null,
    gyftEmail: userInfo.gyftEmail || null
  };
};

const placeOrder = (history) => async (dispatch, getState) => {
  try {
    const cart = constructCart(getState);
    let userInfo = getState()[ROOT_STATE][ORDER_STATE].userInfo;

    // If cart has eGift card, send email through gyftEmail as per Uday
    // Both gyft and egift cards POST email through gyftEmail
    const currentCart = cartSelector.selectMany(getState(), getState()[ROOT_STATE][CART_STATE].currentCart);
    if (currentCart.some((product) => product.isDigitalCard && !product.isGyftProduct)) {
      userInfo.gyftEmail = userInfo.digitalCardEmail;
    }
    const shippingInfo = constructShippingInfo(userInfo);

    await dispatch(destroy('orderComposite', null));
    await dispatch(destroy('shippingInfo', null));
    await dispatch(destroy('orderDetailItem', null));

    const response = await dispatch(
      orderApiActions.createOrders(
        {
          model: {
            orderItems: cart,
            shippingInfo
          }
        } as any,
        { jsonApi: true }
      )
    );

    await dispatch(cartApiActions.deleteCart({ jsonApi: true }));
    await dispatch(destroyRequest('getSummary'));

    dispatch(getAccountSummary());
    dispatch(cartApiActions.getCart());

    history.push({
      pathname: '/catalog/orderConfirmation',
      state: orderConfirmationSelector.selectMany(getState(), null)
    });

    dispatch(destroy('cartItem', null));

    return response;
  } catch (e) {
    throw e;
  }
};

export { placeOrder };
