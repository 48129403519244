import { promiseDispatcher } from 'xcel-react-core';
import { nominationApi } from '../../../api/index';
import { nomineeSearchSelectors as selectors } from '../../selectors/index';
import {
  addVisibleNominees,
  getId,
  modifyLatestSearch,
  scrollActions,
  setScrollId
  } from './miscActions';
import { nomineeSearchCall } from './nomineeSearch';

const nomineeScrollCall = () => (dispatch, getState) => {
  return nominationApi.getNomineeScroll({ scrollId: selectors.getNomineeScroll(getState()) });
};

export const nomineeScrollSuccess = (response, ...args) => (dispatch, getState) => {
  dispatch(scrollActions.success({ data: response.data }, args));
  dispatch(setScrollId(response.meta.scrollId));
  dispatch(addVisibleNominees(response.data.map(getId)));
  dispatch(modifyLatestSearch({ page: selectors.getLatestNomineePage(getState()) + 1 }));
};

const nomineeSearch = promiseDispatcher(nomineeSearchCall, {
  request: scrollActions.request,
  success: nomineeScrollSuccess,
  failure: scrollActions.failure
});

export const nomineeScrollFailure = (error, ...args) => (dispatch, getState) => {
  dispatch(scrollActions.failure(error, args));
  dispatch(pagedStrategy());
};

const elasticSearchStrategy = promiseDispatcher(nomineeScrollCall, {
  request: scrollActions.request,
  success: nomineeScrollSuccess,
  failure: nomineeScrollFailure
});

const pagedStrategy = () => (dispatch, getState) => {
  const state = getState();
  const current = selectors.getVisibleNominees(state).length;

  const lastSearch = selectors.getLatestNomineeSearch(state);
  lastSearch.page = lastSearch.page ? lastSearch.page : Math.floor(current / lastSearch.pageSize) + 1;
  dispatch(nomineeSearch(lastSearch));
};

const nomineeScroll = () => (dispatch, getState) => {
  if (selectors.getNomineeScroll(getState()) !== 'Not Available') {
    dispatch(elasticSearchStrategy());
  } else {
    dispatch(pagedStrategy());
  }
};

export default nomineeScroll;
