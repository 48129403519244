import * as React from 'react';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import { Button } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const MyForm = styled(Form)`
  text-align: center;
  margin-bottom: 10px;
  input {
    @media (min-width: 768px) {
      margin-right: 15px;
    }
  }
`;

const SearchButton = styled(Button)`
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const ErrorFormContainer = styled.div`
  margin: 20px 0 0 0;
  @media (min-width: 768px) {
    margin: 20px auto;
    max-width: 400px;
  }
`;

const BasicSearchBar = (props) => {
  return (
    <MyForm inline={true} onSubmit={props.handleSubmit}>
      <div>
        <Field name="searchText" component="bs-input" />
        <SearchButton disabled={props.submitting} type="submit" bsStyle="primary">
          {props.startSearch}
        </SearchButton>
      </div>
    </MyForm>
  );
};

const mapResource = (getResource) => ({
  startSearch: getResource('search.searchLabel', 'Search')
});

const SearchBar = compose<any>(
  withResource(mapResource),
  reduxForm({} as any)
)(BasicSearchBar);

export default SearchBar;
