import * as events from '../../events';

const initialState = {
  products: []
};

const trendingReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case events.GET_TRENDING_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...payload]
      };
    case events.GET_TRENDING_PRODUCTS_FAILURE:
    case events.GET_TRENDING_PRODUCTS_REQUEST:
    case events.SET_CURRENT_TRENDING:
      return {
        ...state,
        products: payload.map((a) => a.id)
      };
    default:
      return {
        ...state
      };
  }
};

const trendingProductsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case events.GET_TRENDING_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        products: [...action.payload.data]
      };
    case events.GET_TRENDING_PRODUCT_DETAILS_FAILURE:
    case events.GET_TRENDING_PRODUCT_DETAILS_REQUEST:
    default:
      return {
        ...state
      };
  }
};

export { trendingReducer, trendingProductsReducer };
