import { createTypeSelector } from 'xcel-redux-orm';
import { LANDING_PAGE_ROOT } from '../constants';
import { LandingState } from '../types/landing';
import { getLandingState } from './state';

export const getLanding = (state): LandingState => getLandingState(state);

export const landingSelectors = {
  ...createTypeSelector<any>(LANDING_PAGE_ROOT)
};
