import * as React from 'react';
import { CustomFields } from 'rsv8-customfields';
import ParagraphField from '../../CustomFieldValue/ParagraphField';

interface InputOption {
  value: string;
  label: string;
}

export interface DetailsFieldProps {
  className?: string;
  field: {
    id: string;
    label: string;
    fieldType: string;
    options: InputOption[];
    sequence?: number;
  };
  value: string;
  valueTheme?: string;
}
export interface DetailsLayoutProps extends DetailsFieldProps {
  customFieldValues: object;
}
export const DetailsLayout: React.SFC<DetailsLayoutProps> = ({ field, customFieldValues, ...rest }) => {
  let customFieldValue: string | any[] = '';
  if (customFieldValues) {
    if (Array.isArray(customFieldValues)) {
      const foundValue = customFieldValues.filter((value) => {
        return value.id === field.id;
      });

      if (foundValue.length < 1) {
        return null;
      }

      const foundValueArray = foundValue && Array.isArray(foundValue) ? foundValue[0].values : foundValue.values;

      customFieldValue =
        customFieldValues && Array.isArray(foundValueArray)
          ? foundValueArray.length > 1
            ? foundValueArray.join(', ')
            : foundValueArray[0]
          : foundValueArray;
    } else {
      customFieldValue = customFieldValues[field.id];
    }
  }

  return customFieldValue ? <ParagraphField value={customFieldValue} field={field} {...rest} /> : null;
};

export interface TeamDetailsProps {
  appKey: string;
  formType: string;
  customFields: Record<string, string>;
}

const groups = ['criteria', 'details'];

const TeamDetails: React.SFC<TeamDetailsProps> = ({ customFields, ...props }) => (
  <React.Fragment>
    <CustomFields {...props} groupKey={groups} component={DetailsLayout} customFieldValues={customFields} />
  </React.Fragment>
);

export default TeamDetails;
