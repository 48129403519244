import { CustomerProduct } from "xcel-api-generator/dist/alcon";
import { createTypeSelector } from "xcel-redux-orm";

export const customerProductSelector = createTypeSelector<CustomerProduct>("customerProduct");

export const productSummarySelector = (state) => {
  return customerProductSelector.selectOne(state, "0");
};

export const productDataSelector = (state) => {
  const customerProducts: CustomerProduct[] = customerProductSelector.selectMany(state);
  if (!customerProducts || customerProducts.length === 0) {
    return [];
  }

  return customerProducts.filter((item) => item.id !== 0).sort((a, b) => a.sequence - b.sequence);
};
