import * as React from 'react';
import { connect } from 'react-redux';
import { CustomerProduct, RebateTimePeriod } from 'xcel-api-generator/dist/alcon';
import { baselineYearLabelSelector, currentTimePeriodSelector } from '../../redux/selectors';
import { ProductDetail } from './ProductDetail';
import { ProductHeader } from './ProductHeader';
import { ProductBody, ProductContainer } from './styles';

const UnitsProduct: React.FC<Props> = ({ baselineYearLabel, currentTimePeriod, product }) => (
  <ProductContainer className="product">
    <ProductHeader baselineYearLabel={baselineYearLabel} name={product.name} unitLabel="units" />

    <ProductBody>
      <ProductDetail label={'YTD ' + baselineYearLabel} units={product.ytdBaseLineUnits} />
      <ProductDetail label={'YTD ' + currentTimePeriod.year} units={product.ytdCurrentUnits} />
    </ProductBody>
  </ProductContainer>
);

const mapStateToProps = (state) => ({
  baselineYearLabel: baselineYearLabelSelector(state),
  currentTimePeriod: currentTimePeriodSelector(state),
});

interface StateProps {
  baselineYearLabel: string;
  currentTimePeriod: RebateTimePeriod;
}
interface DispatchProps {}
interface OwnProps {
  product: CustomerProduct;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(UnitsProduct);
