import styled from 'styled-components';
import { Button } from '../Button';
import { ContainerProps } from './types';

export const DrawerContainer = styled.div`
  @media (max-width: ${(props: ContainerProps) => props.start + 'px'}) {
    background: ${(props: ContainerProps) => props.backgroundColor || 'white'};
    height: 100%;
    margin: 0px;
    opacity: 1;
    padding: 20px;
    position: fixed;
    right: ${(props: ContainerProps) => (props.open ? `0px` : `-100%`)};
    top: 0px;
    transition: right 0.2s ease-out;
    width: 100%;
    z-index: 1000;
  }
` as any;

export const DrawerClose = styled.div`
  display: none;
  @media (max-width: ${(props: ContainerProps) => props.start + 'px'}) {
    color: #606060;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-size: 24px;
  }
` as any;

export const DrawerToggle = styled(Button)`
  display: none;
  @media (max-width: ${(props: ContainerProps) => props.start + 'px'}) {
    display: block;
    text-align: center;
  }
` as any;
