import * as React from 'react';
import styled from 'styled-components';

const Alert = styled.span`
  position: absolute;
  min-width: '250px';
  top: 2%;
  right: 2%;
  opacity: 0.55;
  &.info {
    color: blue;
  }
  &.danger {
    color: red;
  }
  &.warning {
    color: yellow;
  }
  &.success {
    color: green;
  }
` as any;

const FloatedAlert = ({ children, status = 'info', ...rest }) => (
  <Alert {...rest} className={`${status} ${rest.className}`}>
    {children}
  </Alert>
);

const FloatedInfo = (props) => <FloatedAlert {...props} status="info" />;

const FloatedError = (props) => <FloatedAlert {...props} status="danger" />;

const FloatedWarning = (props) => <FloatedAlert {...props} status="warning" />;

const FloatedSuccess = (props) => <FloatedAlert {...props} status="success" />;

export { FloatedAlert, FloatedInfo, FloatedWarning, FloatedError, FloatedSuccess };
