import { ProductListSummaryModel, ProductModel } from 'xcel-api-generator/dist/catalog';
import { createTypeSelector } from 'xcel-redux-orm';
import { PRODUCT_JSON_TYPE, PRODUCT_LIST_JSON_TYPE, ROOT_STATE } from '../../../constants';

export const favoriteSelector = createTypeSelector<ProductListSummaryModel>(PRODUCT_LIST_JSON_TYPE);
export const productSelector = createTypeSelector<ProductModel>(PRODUCT_JSON_TYPE);

const getFavoriteList = (state) => state[ROOT_STATE] && state[ROOT_STATE].currentFavorites;
export const getFavoritesLoaded = (state) => state[ROOT_STATE] && state[ROOT_STATE].favoritesLoaded;
export const getFavoriteProductList = (state) =>
  productSelector
    .selectMany(state, getFavoriteList(state), { eager: true }) // select products from orm that match favorites arr
    .filter((product) => product !== null); // HAVE TO REMOVE NULL ENTRIES FROM ARRAY
