import { HeaderThree, HeaderTwo, Text } from 'rsv8-components';
import styled from 'styled-components';

export const AchievementRewardsHeading = styled.div`
  max-width: 560px;
  margin: 0 auto;
  padding: 4.5rem 3rem;
  text-align: center;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  @media screen and (max-width: 992px) {
    width: calc(100% - 2rem);
  }
`;

export const AlertHeading = styled(HeaderThree)`
  text-align: center;
`;

export const AchievementRewardsHeadingTitle = styled.div`
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 40px;
  @media screen and (max-width: 992px) {
    font-size: 26px;
  }
`;
export const AchievementRewardsHeadingText = styled(Text)`
  max-width: 360px;
`;

export const AchievementRewardsContainer = styled.div`
  margin-top: 5rem;
  position: relative;
`;

export const AchievementRewardsContent = styled.div`
  &.redeemed {
    display: flex;
    @media screen and (min-width: 992px) {
      justify-content: center;
    }
  }
  @media screen and (min-width: 768px) {
    position: relative;
  }
`;

export const AchievementRewardsLevelCheckIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  z-index: 2;
  top: -150px;
  right: 50%;
  transform: translateX(50%);
  .achieved + .achieved > & {
    top: -65px;
  }
  @media screen and (min-width: 992px) {
    top: -20px;
    right: -20px;
    transform: translateX(0);
    .achieved + .achieved > & {
      top: -20px;
    }
  }
`;

export const AchievementCarouselWrapper = styled.div`
  width: 100%;
  flex: 0 1 100%;
  padding: 0 5rem 5rem;
  .row > & {
    padding-bottom: 7rem;
  }
  @media screen and (min-width: 768px) {
    padding: 1rem 5rem 10rem;
    .row > & {
      padding: 10rem 0;
      width: calc(100% - 500px);
    }
  }
  div {
    :focus {
      outline: 0;
      /* or */
      outline: none;
    }
  }
`;

export const AchievementLevelBlock = styled.div`
  background: #f4f4f4;
  border-radius: 100px;
  margin-bottom: 3rem;
  &.redeemed {
    flex: 0 0 50%;
    max-width: 400px;
    min-width: 270px;
    padding: 2rem 1rem 7rem;
    margin: 0 2.5rem;
    .fa-heart,
    .fa-heart-o {
      display: none !important;
    }
  }
  &:not(.redeemed):first-child {
    padding-top: 8rem;
    margin-top: -7rem;
  }
  &.row + .row {
    margin-top: 3rem;
  }
  &.achieved {
    position: relative;
    overflow: visible;
    & + .achieved {
      padding-top: 2rem;
    }
  }
  @media screen and (min-width: 992px) {
    max-width: 100%;
    overflow: hidden;
    &.row {
      display: flex;
      align-items: center;
    }
    &.achieved + .achieved {
      padding-top: 4rem;
    }
  }
  @media screen and (max-width: 992px) {
    border-radius: 45px;
    &.redeemed {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-bottom: 0;
    }
  }
`;

export const AchievementLevelHeading = styled(HeaderTwo)`
  text-align: center;
  width: 100%;
  flex: 0 0 auto;
  .row > & {
    padding-top: 5rem;
  }
  .redeemed > & {
    padding: 2rem 0 3rem;
  }
  @media screen and (min-width: 992px) {
    .row > & {
      width: 400px;
      margin: 0 50px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`;

export const AchievementLevelCardWrapper = styled.div`
  width: 215px;
  margin: 0 auto;
`;
