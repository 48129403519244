import * as React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { compose } from 'redux';
import styled, { css } from 'styled-components';
import { withResource } from 'xcel-react-core';
import { NavigationItem, NavigationItemGroup } from '../Navigation';

const stickyCSS = css`
  position: fixed;
  top: 0;
` as any;

interface StickyMenuContainerBaseProps {
  stickyContainerRef: any;
  className: any;
}

class StickyMenuContainerBase extends React.Component<StickyMenuContainerBaseProps, any> {
  render() {
    return (
      <div ref={this.props.stickyContainerRef} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

const StickyMenuSpacer = styled.div`
  height: ${(props: any) => props.spacerHeight}px;
` as any;

const StickyMenuContainer = styled(StickyMenuContainerBase)`
  position: relative;
  z-index: 180;
  width: 100%;
  content: '';
  clear: both;
  display: table;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  ${(props: any) => (props.stuck ? stickyCSS : '')} @media (min-width: 769px) {
    padding: 0 15px;
    border-bottom: 1px solid #cccccc;
  }
` as any;

const StickyMenuNavigationIcon = styled.div`
  height: 25px;
  width: 25px;
  background-image: url(${(props: any) => props.iconURI});
  background-size: auto;
  background-repeat: no-repeat;
  margin: 0 auto 5px;
  background-position: center;
` as any;

interface StickyMenuProps {
  stickyScrollThreshold: any;
  homeLinkImagePath: any;
  homeLinkLabelText: any;
  recognitionLinkImagePath: any;
  recognitionLinkLabelText: any;
  shopLinkImagePath: any;
  shopLinkLabelText: any;
  alertsLinkImagePath: any;
  alertsLinkLabelText: any;
  firstLinks: any;
  moreLinks: any;
}

interface StickyMenuState {
  isStuck: boolean;
}

class StickyMenu extends React.Component<StickyMenuProps, StickyMenuState> {
  stickyContainer: any;
  constructor(props) {
    super(props);
    this.state = {
      isStuck: false
    };
  }

  componentWillMount() {
    window.addEventListener('scroll', this.toggleStuck);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleStuck);
  }

  shouldStick = () => {
    return window.scrollY >= this.props.stickyScrollThreshold;
  };

  toggleStuck = () => {
    this.setState({ isStuck: this.shouldStick() });
  };

  // FIXME: Separate concerns.
  // The StickyMenu component should be able to show all of the nested menu
  // items fetched from the Menu API (NavItem). On mobile screen sizes,
  // it should only show the featured links (home, recognition, shop, alerts).
  // The other links are shown in the HamburgerMenu.
  //
  // FIXME: The `link` property seems leaky.
  render() {
    return (
      <React.Fragment>
        {this.state.isStuck ? (
          <StickyMenuSpacer spacerHeight={this.stickyContainer.getBoundingClientRect().height} />
        ) : null}
        <StickyMenuContainer
          stuck={this.state.isStuck}
          stickyContainerRef={(el) => {
            this.stickyContainer = el;
          }}
        >
          <MediaQuery query="(max-width: 768px)">
            <NavigationItemGroup>
              <NavigationItem to="/nominations/home" numberOfLinks={4}>
                <StickyMenuNavigationIcon iconURI={this.props.homeLinkImagePath} />
                <span>{this.props.homeLinkLabelText}</span>
              </NavigationItem>
              <NavigationItem to="/nominations/dashboard" numberOfLinks={4}>
                <StickyMenuNavigationIcon iconURI={this.props.recognitionLinkImagePath} />
                <span>{this.props.recognitionLinkLabelText}</span>
              </NavigationItem>
              <NavigationItem to="/nominations/shop" numberOfLinks={4}>
                <StickyMenuNavigationIcon iconURI={this.props.shopLinkImagePath} />
                <span>{this.props.shopLinkLabelText}</span>
              </NavigationItem>
              <NavigationItem to="/nominations/alerts" numberOfLinks={4}>
                <StickyMenuNavigationIcon iconURI={this.props.alertsLinkImagePath} />
                <span>{this.props.alertsLinkLabelText}</span>
              </NavigationItem>
            </NavigationItemGroup>
          </MediaQuery>
          <MediaQuery query="(min-width: 769px)">
            {this.props.firstLinks ? <NavigationItemGroup links={this.props.firstLinks} /> : null}
            <NavigationItemGroup links={[{ label: 'More', targetUrl: '#', children: this.props.moreLinks }]} />
            {/* <IconMenu onCartButtonClick={() => null} cartButtonItemCount={3} /> */}
          </MediaQuery>
        </StickyMenuContainer>
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({
  homeLinkLabelText: getResource('stickyMenu.homeLinkLabelText', 'Home'),
  homeLinkImagePath: getResource('stickyMenu.homeLinkImagePath', '/clientassets/images/icons/recognition.svg'),
  recognitionLinkLabelText: getResource('stickyMenu.recognitionLinkLabelText', 'Recognition'),
  recognitionLinkImagePath: getResource(
    'stickyMenu.recognitionLinkImagePath',
    '/clientassets/images/icons/recognition.svg'
  ),
  shopLinkLabelText: getResource('stickyMenu.shopLinkLabelText', 'Shop'),
  shopLinkImagePath: getResource('stickyMenu.shopLinkImagePath', '/clientassets/images/icons/shop.svg'),
  alertsLinkLabelText: getResource('stickyMenu.alertsLinkLabelText', 'Alerts'),
  alertsLinkImagePath: getResource('stickyMenu.alertsLinkImagePath', '/clientassets/images/icons/alerts.svg')
});

const mapState = (state) => ({
  firstLinks: state.navigationMenu.firstItems,
  moreLinks: state.navigationMenu.moreItems
});

const mapDispatch = (dispatch) => ({});

export default compose(withResource(mapResource), connect(mapState, mapDispatch))(StickyMenu);
