import * as React from 'react';
import {
  Col,
  FormControl,
  FormGroup,
  Row
  } from 'react-bootstrap';
import { findDOMNode } from 'react-dom';
import * as Icon from 'react-fontawesome';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import {
  AddToCartButton,
  CatalogTableInfoLink,
  CatalogTableInfoProduct,
  LinkButton
  } from '../../../components/ProductTable/ProductTableStyles';
import { isCartDisabled } from '../../../utils/cart';
import { FavoritesDisplayProps } from '../types';
import {
  FavoritePanel,
  PanelButtonGroup,
  PanelContainer,
  PanelImage,
  PanelInformation,
  PanelName,
  PanelPoints,
  RemoveButton,
  UndoButton,
  UndoContent,
  UndoFavoritePanel
  } from './styles';

class FavoritesPanels extends React.Component<FavoritesDisplayProps, any> {
  productOptionSelection: Array<any>;

  constructor(props: any) {
    super(props);
    this.productOptionSelection = [];
  }

  handleAddCartOption = (product) => (e) => {
    e.preventDefault();

    let optionSelected = findDOMNode(this.productOptionSelection[product.id]) as any;
    if (optionSelected) {
      optionSelected = optionSelected.value;
    }
    this.props.addToCart(product, optionSelected);
  };

  showUndoProducts = () => {
    const { undoProducts, undoSuccessLabel, undoLabel } = this.props;
    return (
      <React.Fragment>
        {undoProducts &&
          undoProducts.map((product, index) => (
            <UndoFavoritePanel key={`${product.id}-${product.productOptionId}`}>
              <UndoContent>
                <span>
                  <LinkButton
                    themeVariation="primary-link"
                    to={`/catalog/product/${encodeURIComponent(product.id)}${
                      product.groupedProducts && product.groupedProducts.length > 0
                        ? `?defaultGroupProduct=${encodeURIComponent(product.id)}`
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: `${product.name.replace(/<(.|\n)*?>/g, '').substring(0, 10)}...`
                    }}
                  />
                  <Text>{` ${undoSuccessLabel}`}</Text>
                </span>
              </UndoContent>
              <UndoButton themeVariation="primary-link" onClick={this.props.undoFavorite(product)}>
                <Icon name="undo" />
                {undoLabel}
              </UndoButton>
            </UndoFavoritePanel>
          ))}
      </React.Fragment>
    );
  };

  showButtonGroup = (product) => (
    <PanelButtonGroup>
      <RemoveButton themeVariation="primary" onClick={this.props.removeFavorite(product)}>
        {this.props.removeFromFavoritesLabel}
      </RemoveButton>
      <AddToCartButton
        themeVariation="primary"
        onClick={this.handleAddCartOption(product)}
        disabled={isCartDisabled(product, this.props.accountSummary)}
      >
        {this.props.addToCartLabel}
      </AddToCartButton>
    </PanelButtonGroup>
  );

  showProductOptions = (product) =>
    product.productOptions.length > 0 && (
      <FormGroup controlId="formControlsSelect">
        <FormControl
          componentClass="select"
          placeholder="select"
          ref={(component) => {
            this.productOptionSelection[product.id] = component;
          }}
        >
          {product.productOptions.map((option) => (
            <option key={option.productOptionID} value={option.productOptionID}>
              {option.selection}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    );

  showPanelData = (product) => (
    <PanelContainer>
      <PanelImage src={product.imageLg} />
      <PanelInformation>
        <CatalogTableInfoLink to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
          {product.manufacturer}
        </CatalogTableInfoLink>
        <PanelName>
          <CatalogTableInfoProduct
            themeVariation="text-link"
            to={`/catalog/product/${encodeURIComponent(product.id)}`}
            dangerouslySetInnerHTML={{ __html: product.name }}
          />
        </PanelName>
        <PanelPoints>
          <Text themeVariation="secondary-text-bold">
            {`${parseFloat(product.pointValue).toLocaleString('en')} ${this.props.pointsAbbrev}`}
          </Text>
        </PanelPoints>
        <div>{this.showProductOptions(product)}</div>
      </PanelInformation>
    </PanelContainer>
  );

  render() {
    const { products } = this.props;

    return (
      <Row className="hidden-sm hidden-md hidden-lg hidden-xl">
        <Col xs={22} xsOffset={1}>
          <div>
            {this.showUndoProducts()}
            {products &&
              products.map(
                (product, index) =>
                  product && (
                    <FavoritePanel key={`${product.id}-${product.productOptionId}`}>
                      {this.showPanelData(product)}
                      {this.showButtonGroup(product)}
                    </FavoritePanel>
                  )
              )}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  pointsAbbrev: getResource('catalog.myFavorites.pointsAbbrev', 'pts.'),
  addToCartLabel: getResource('catalog.myFavorites.addToCartLabel', 'Add to Cart'),
  undoLabel: getResource('catalog.myFavorites.undoLabel', 'Undo'),
  undoSuccessLabel: getResource('catalog.myFavorites.undoSuccessLabel', 'was removed from your favorites'),
  removeFromFavoritesLabel: getResource('catalog.myFavorites.removeFromFavoritesLabel', 'Remove')
});

export default withResource(mapResourceToProps)(FavoritesPanels);
