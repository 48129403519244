import { ChildRouteModel, ClientAssetChildRouteDto } from 'xcel-api-generator/dist/clientassetAdmin';
import { createTypeSelector } from 'xcel-redux-orm';

export interface Asset {
  isPrivate: boolean;
  fileName: string;
  mimeType: string;
  fileExtension: string;
  downloadUrl: string;
  createdBy: string;
  createdDateTimeUtc: string;
  updatedBy: string;
  updatedDateTimeUtc: string;
  routeId: any;
}

export interface AssetRoute extends ChildRouteModel {
  clientId: number;
  childeRoutes: ClientAssetChildRouteDto[];
  routes: Asset[];
}
const RSV8_ASSETS_ADMIN = 'rsv8-assets-admin';

const adminAssetsRoutesSelectors = {
  ...createTypeSelector<any>('rsv8-assets-admin')
};

const getActiveRoute = (state) => state[RSV8_ASSETS_ADMIN].activeRoute;

const getEditingRoute = (state) => state[RSV8_ASSETS_ADMIN].editingRoute;

const getCurrentModal = (state) => state[RSV8_ASSETS_ADMIN].activeModal;

const routeSelector = createTypeSelector<AssetRoute>('clientAssetRouteDto');

const routeSelectorWithChildren = createTypeSelector<AssetRoute>('clientAssetChildRouteDto');

export {
  adminAssetsRoutesSelectors,
  getActiveRoute,
  getEditingRoute,
  routeSelector,
  routeSelectorWithChildren,
  getCurrentModal
};
