import * as React from 'react';
import {
  Button,
  Col,
  Grid,
  Row
  } from 'react-bootstrap';
import { connect } from 'react-redux';
import { VersionDropdown } from 'rsv8-cms';
import styled from 'styled-components';
import { getValue } from 'xcel-util';
import { getThemeComponents } from '../../../config';
import { componentStyleReset, componentStyleSelect } from '../../redux/component.actions';
import { getTheme } from '../../redux/theme.selectors';
import { mapDispatchToActions } from '../../util/index';
import ComponentExampleList from './ComponentExampleList';
import ComponentExampleManage from './ComponentExampleManage';
import { ComponentStyleSelect } from './components';
import ComponentSelectList from './ComponentSelectList';
import ComponentStyleEdit from './ComponentStyleEdit';
import ComponentVariations from './ComponentVariations';
const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
`;
const Subtitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  display: block;
`;
const Break = styled.hr`
  margin: 5px;
`;
const themeComponents = getThemeComponents();
class ComponentEdit extends React.Component<any> {
  componentDidMount() {
    const { computedMatch, match, actions } = this.props;
    const activeComponentName =
      (match.params && match.params.name) || (computedMatch.params && computedMatch.params.name);
    actions.componentStyleSelect({ name: activeComponentName });
  }
  render() {
    const { computedMatch, match, selectedStyle, actions } = this.props;
    if (!selectedStyle) return null;
    const themeComponent = getValue(themeComponents, selectedStyle);
    const activeComponentName = match.params.name || computedMatch.params.name;
    const handleStyleSelect = (value) => {
      actions.componentStyleSelect({ name: value });
    };
    const handleStyleReset = (e) => {
      actions.componentStyleReset({ name: themeComponent.name });
    };
    return (
      <Grid bsClass="container-fluid">
        <Row>
          <Col md={4} color={'#eeeeee'}>
            <ComponentSelectList name={activeComponentName} match={match} />
          </Col>
          <Col md={14}>
            <Title>{activeComponentName}</Title>
            <Break />
            <ComponentExampleList name={activeComponentName} />
          </Col>
          <Col md={6}>
            <Title>Settings</Title>
            <Break />
            <VersionDropdown />
            <Subtitle>Variations</Subtitle>
            <Row>
              <Col md={24}>
                <ComponentVariations name={activeComponentName} />
              </Col>
            </Row>
            <Subtitle>Styles</Subtitle>
            <Row>
              <Col md={12}>
                <ComponentStyleSelect
                  selectedStyle={selectedStyle}
                  name={activeComponentName}
                  onChange={handleStyleSelect}
                />
              </Col>
              <Col md={12}>
                <Button onClick={handleStyleReset}>Reset</Button>
              </Col>
            </Row>
            <Break />
            <ComponentStyleEdit themeComponent={themeComponent} />
            <br />
            <Subtitle>Examples</Subtitle>
            <Break />
            <ComponentExampleManage name={activeComponentName} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedStyle: getTheme(state).component.selectedStyle
});
const mapDispatchToProps = mapDispatchToActions({
  componentStyleSelect,
  componentStyleReset
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentEdit);
