import * as React from 'react';
import { HeaderTwo, Tab, Tabs } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import IndividualDraftTable from './IndividualDraftTable';
import TeamDraftTable from './TeamDraftTable';

const NominationHistory: React.SFC<ReturnType<typeof mapResource>> = (props) => {
  return (
    <Tabs defaultActiveKey={0}>
      <Tab eventKey={0} title={props.individualLabel}>
        <HeaderTwo>{props.individualHeader}</HeaderTwo>
        <IndividualDraftTable />
      </Tab>
      <Tab eventKey={1} title={props.teamLabel}>
        <HeaderTwo>{props.teamHeader}</HeaderTwo>
        <TeamDraftTable />
      </Tab>
    </Tabs>
  );
};

const mapResource = (getResource) => ({
  teamLabel: getResource('nominationActivity.teamTabLabel', 'Team'),
  individualLabel: getResource('nominationActivity.individualTabLabel', 'Individual'),
  teamHeader: getResource('nominationActivity.nominationDraftTeamHeader', 'Team Drafts'),
  individualHeader: getResource('nominationActivity.nominationDraftIndividualHeader', 'Individual Drafts')
});

export default withResource(mapResource)(NominationHistory) as React.ComponentType<{}>;
