export const SHAREABLES_ACTIVITIES_INITIAL = 'SHAREABLES_ACTIVITIES_INITIAL';
export const SHAREABLES_ACTIVITIES_LOADING = 'SHAREABLES_ACTIVITIES_STATE_LOADING';
export const SHAREABLES_ACTIVITIES_SUCCESS = 'SHAREABLES_ACTIVITIES_STATE_SUCCESS';
export const SHAREABLES_ACTIVITIES_FAILED = 'SHAREABLES_ACTIVITIES_STATE_FAILED';

export const ACTIVITIES_INITIAL = 'ACTIVITIES_INITIAL';
export const ACTIVITIES_LOADING = 'ACTIVITIES_STATE_LOADING';
export const ACTIVITIES_SUCCESS = 'ACTIVITIES_STATE_SUCCESS';
export const ACTIVITIES_FAILED = 'ACTIVITIES_STATE_FAILED';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
