import * as React from 'react';
import {
  Loader,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow
  } from 'rsv8-components';
import styled from 'styled-components';

const ActivityTableBase = styled.div`
  margin-bottom: 100px;
  tr {
    cursor: pointer;
  }
` as any;

const TableIcon = styled.span`
  font-size: 20px;
  color: ${(props: any) => props.color};
`;

interface ActivityTableProps {
  items: any;
  loaded?: boolean;
  onClick?: any;
  onDelete?: any;
  onSearch?: any;
  onSort?: any;
}

/**
 * Formats the incoming date in order to be easier to read
 *
 * @param  {string} rawDate - activity date from API
 * @return {string}         - cleaned string date
 */
function displayDate(rawDate?: string) {
  // fallback value in cases where there isn't date on runtime
  let resultDate = 'N/A';

  if (rawDate !== undefined || rawDate !== null) {
    const date = new Date(rawDate).toISOString();
    const milisecondLocation = date.lastIndexOf('.');

    resultDate = date
      .split('T')
      .join(' ')
      .replace('Z', '')
      .substring(0, milisecondLocation);
  }

  return resultDate;
}

const ActivityTable = ({ items, loaded, onClick, onDelete, onSearch, onSort, ...rest }: ActivityTableProps) => {
  const handleClick = (item) => (e) => {
    if (onClick) {
      onClick(item);
    }
  };
  const handleDelete = (item) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onDelete) {
      onDelete(item);
    }
  };

  function renderRows(items = []) {
    return items.map((item, index) => (
      <TableRow key={index} onClick={handleClick(item)}>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.clientActivityType.name}</TableCell>
        <TableCell>{item.countries.join(', ')}</TableCell>
        <TableCell>{displayDate(item.updatedDateTimeUtc)}</TableCell>
        <TableCell>{item.updatedBy}</TableCell>
        <TableCell onClick={handleDelete(item)}>
          <TableIcon color={'#666666'}>
            <i className={`fa fa-trash`} title="Delete" />
          </TableIcon>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <ActivityTableBase>
      <Table {...rest} striped={true} bordered={true} hover={true} condensed={true} onSort={onSort}>
        <thead>
          <TableRow>
            <TableHeaderCell field="name">Name</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>country</TableHeaderCell>
            <TableHeaderCell field="updatedDateTimeUtc">Modified Date</TableHeaderCell>
            <TableHeaderCell field="updatedBy">Modified By</TableHeaderCell>
            <TableHeaderCell width={'30px'} />
          </TableRow>
        </thead>
        <tbody>{renderRows(items)}</tbody>
      </Table>
      {!loaded ? <Loader /> : null}
    </ActivityTableBase>
  );
};

export default ActivityTable;
