import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import reducers from './redux/reducers';
const socialAdminModule: ReduxModule = createReduxModule({
  name: 'rsv8-social',
  reducers
});
reduxObserver.addModule(socialAdminModule);

export default socialAdminModule;
