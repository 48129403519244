import { deleteActivity } from '../../api/delete-activity';
import { ActivityAction, deleteUserAction } from '../../api/delete-user-action';
import { getActivities as fetchActivities } from '../../api/get-activities';
import { getShareablesActivities as fetchShareablesActivities } from '../../api/get-shareables-activities';
import { logActionActivity } from '../../api/log-action-activity';
import {
  ACTIVITIES_FAILED,
  ACTIVITIES_LOADING,
  ACTIVITIES_SUCCESS,
  DELETE_SUCCESS,
  SHAREABLES_ACTIVITIES_FAILED,
  SHAREABLES_ACTIVITIES_LOADING,
  SHAREABLES_ACTIVITIES_SUCCESS
  } from '../constants';
import * as events from '../events';

export const getActivities = (params = {}) => async (dispatch) => {
  try {
    dispatch({
      type: events.SET_ACTIVITIES,
      payload: {
        state: ACTIVITIES_LOADING
      }
    });
    const data = await fetchActivities(params);
    dispatch({
      type: events.SET_ACTIVITIES,
      payload: {
        state: ACTIVITIES_SUCCESS,
        data
      }
    });
  } catch (err) {
    dispatch({
      type: events.SET_ACTIVITIES,
      payload: {
        state: ACTIVITIES_FAILED
      }
    });
  }
};

const refreshActivities = (params = {}) => async (dispatch) => {
  const data = await fetchActivities(params);
  dispatch({
    type: events.SET_ACTIVITIES,
    payload: {
      data
    }
  });
};

const normalizeActivityId = (id: string) => (id[0] === '/' ? id.slice(1) : id);

const handleActivityAction = (action: () => void, activitiesParams = {}) => async (dispatch) => {
  try {
    dispatch({
      type: events.SET_ACTIVITIES,
      payload: {
        actionInProcess: true
      }
    });

    try {
      await action();
    } catch (err) {
      // If action failed - we still reload the list of activities
      // to show user most recent status
      refreshActivities(activitiesParams)(dispatch);
      console.error(err);
    }
  } finally {
    dispatch({
      type: events.SET_ACTIVITIES,
      payload: {
        actionInProcess: false
      }
    });
  }
};

export const likeActivity = (activityId: string, activitiesParams = {}) => async (dispatch) => {
  dispatch({
    type: events.ACTIVITIES_OPTIMISTIC_ADD,
    payload: {
      id: activityId,
      request: { actionId: ActivityAction.Like, actionName: ActivityAction.Like, metadata: '', isCurrentUser: true }
    }
  });

  await handleActivityAction(async () => {
    await logActionActivity({
      id: normalizeActivityId(activityId),
      body: { action: ActivityAction.Like, metadata: [] }
    });
  }, activitiesParams)(dispatch);
};

export const unlikeActivity = (activityId: string, activitiesParams = {}) => async (dispatch) => {
  dispatch({
    type: events.ACTIVITIES_OPTIMISTIC_REMOVE,
    payload: {
      id: activityId,
      action: ActivityAction.Like
    }
  });

  await handleActivityAction(async () => {
    await deleteUserAction({ id: normalizeActivityId(activityId), actionId: ActivityAction.Like });
  }, activitiesParams)(dispatch);
};

export const removeActivity = (activityId: string, activitiesParams = {}) => async (dispatch) => {
  const data = await fetchActivities(activitiesParams);
  dispatch({
    type: events.DELETE_ACTIVITIES,
    payload: {
      state: DELETE_SUCCESS,
      id: activityId,
      data
    }
  });

  await deleteActivity({ id: normalizeActivityId(activityId) });
};

export const shareActivity = (activityId: string, activitiesParams = {}) => async (dispatch) => {
  await handleActivityAction(async () => {
    await logActionActivity({
      id: normalizeActivityId(activityId),
      body: { action: ActivityAction.Share, metadata: [] }
    });
  }, activitiesParams)(dispatch);
};

export const downloadActivity = (activityId: string, activitiesParams = {}) => async (dispatch) => {
  await handleActivityAction(async () => {
    await logActionActivity({
      id: normalizeActivityId(activityId),
      body: { action: ActivityAction.Save, metadata: [] }
    });
  }, activitiesParams)(dispatch);
};

export const trackShareablesActivityAction = (activityId: string, actionName: string) => async () => {
  try {
    await logActionActivity({ id: normalizeActivityId(activityId), body: { action: actionName, metadata: [] } });
  } catch (err) {
    console.error(err);
  }
};

export const requestShareablesActivities = (params) => async (dispatch) => {
  try {
    dispatch({
      type: events.SET_SHAREABLES_ACTIVITIES,
      payload: {
        state: SHAREABLES_ACTIVITIES_LOADING
      }
    });
    const data = await fetchShareablesActivities(params);

    dispatch({
      type: events.SET_SHAREABLES_ACTIVITIES,
      payload: {
        state: SHAREABLES_ACTIVITIES_SUCCESS,
        data
      }
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: events.SET_SHAREABLES_ACTIVITIES,
      payload: {
        state: SHAREABLES_ACTIVITIES_FAILED,
        data: []
      }
    });
  }
};
