import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'rsv8-forms';
import { fontAdd } from '../../redux/font.actions';
import { mapDispatchToActions } from '../../util';
import FontAddPreview from './FontAddPreview';
import { Spacer } from './styles';
const FontAdd = ({ actions, handleSubmit }) => {
  const handleFontAdd = (font) => {
    if (!font.color) {
      font.color = '#000000';
    }
    actions.fontAdd(font);
  };
  return (
    <form onSubmit={handleSubmit(handleFontAdd)}>
      <h4>Add Fonts</h4>
      <Row>
        <Col md={2}>
          <FontAddPreview />
        </Col>
        <Col md={4}>
          <Field label={'Name'} component={'bs-input'} type={'text'} name={'name'} />
        </Col>
        <Col md={6}>
          <Field label={'Font Family'} component={'bs-input'} type={'text'} name={'family'} />
        </Col>
        <Col md={4}>
          <Field label={'Line Height'} component={'bs-input'} type={'text'} name={'lineHeight'} />
        </Col>
        <Col md={2}>
          <Spacer />
          <Button type="submit">
            <i className="fa fa-plus" />
          </Button>
        </Col>
        <Col md={20}>
          <Field label={'Font Face'} component={'bs-textarea'} name={'face'} rows={10} />
        </Col>
      </Row>
    </form>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = mapDispatchToActions({ fontAdd });

export default reduxForm({ form: 'fontAdd' })(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(FontAdd)
);
