import { stringify } from 'query-string';
import { destroyRequest } from 'xcel-redux-orm';
import { ROOT_STATE, SEARCH_STATE } from '../../../constants';
import * as events from '../../events';
import { getSearchQuery, getSearchResults } from '../../selectors/productSelectors';
import { productApiActions } from './productApiActions';

export const setCurrentProductsSearch = (response) => ({ type: events.SET_CURRENT_PRODUCT_SEARCH, payload: response });

export const updateCurrentProductSearch = (response) => ({ type: events.UPDATE_SEARCH_RESULTS, payload: response });

export const setCurrentSearchQuery = (options) => {
  if (options.filterBrands && !Array.isArray(options.filterBrands)) {
    options.filterBrands = new Array(options.filterBrands);
  }

  return { type: events.SET_CURRENT_SEARCH_QUERY, payload: options };
};

export const resetSearchTotal = () => ({ type: events.RESET_SEARCH_TOTAL });

const getProductsSearch = (searchParams) => async (dispatch, getState) => {
  dispatch(destroyRequest('getProductsSearch'));
  dispatch(resetSearchTotal());

  try {
    const response = await dispatch(
      productApiActions.getProductsSearch(
        { ...searchParams, pagingLimit: searchParams.pagingLimit || 16, pagingPageSize: 16 },
        { jsonApi: true }
      )
    );

    dispatch(setCurrentSearchQuery(searchParams));
    dispatch(setCurrentProductsSearch(response));

    return response;
  } catch (e) {
    throw e;
  }
};

const updateSearch = (history, getState) => {
  history.push({
    pathname: '/catalog/search',
    search: stringify(getState()[ROOT_STATE] && getState()[ROOT_STATE][SEARCH_STATE].currentQuery)
  });
};

const newBrandChange = (value) => ({ type: events.SET_BRAND_CHANGE, payload: value });

const setBrandChange = (value, history) => (dispatch, getState) => {
  dispatch(newBrandChange(value));
  updateSearch(history, getState);
};

const newCategoryChange = (value) => ({ type: events.SET_CATEGORY_CHANGE, payload: value });

const setCategoryChange = (value, history) => (dispatch, getState) => {
  dispatch(newCategoryChange(value));
  updateSearch(history, getState);
};

const categorySearch = (categoryIds, history) => async (dispatch, getState) => {
  await dispatch(newCategoryChange(categoryIds));
  updateSearch(history, getState);
};

const pointsChange = (filterMinPoints, filterMaxPoints) => ({
  type: events.SET_POINT_RANGE_CHANGE,
  payload: { filterMinPoints, filterMaxPoints }
});

const submitPointSearch = (maxPoints, minPoints, history) => (dispatch, getState) => {
  dispatch(pointsChange(minPoints, maxPoints));
  updateSearch(history, getState);
};

const submitAllSearch = (categoryIds, brands, minPoints, maxPoints, history) => async (dispatch, getState) => {
  await dispatch(newCategoryChange(categoryIds));

  if (minPoints && maxPoints) {
    await dispatch(pointsChange(minPoints, maxPoints));
  }

  await dispatch(newBrandChange(brands));

  updateSearch(history, getState);
};

const sortChange = (value) => ({
  type: events.SET_SEARCH_SORT,
  payload: value
});

const resetSort = () => ({
  type: events.RESET_SEARCH_SORT
});

const sortSearch = (value, history) => (dispatch, getState) => {
  if (value === 'none') {
    dispatch(resetSort());
  } else {
    dispatch(sortChange(value));
  }

  updateSearch(history, getState);
};

const resetSearchResults = () => ({ type: events.RESET_SEARCH_RESULTS });
const loadMoreWithoutScroll = () => async (dispatch, getState) => {
  const originalQuery: any = getSearchQuery(getState());
  const recordCount: number = Object.keys(getSearchResults(getState())).length;
  let params = {
    ...originalQuery,
    pagingOffset: recordCount,
    // get all the current products plus the next page -> :)
    pagingLimit: originalQuery.pagingPageSize || 16
  };
  const response = await dispatch(productApiActions.getProductsSearch(params, { jsonApi: true }));
  dispatch(updateCurrentProductSearch(response));
};
const scrollSearch = (scrollId) => async (dispatch, getState) => {
  dispatch(destroyRequest('getScroll'));
  if (scrollId === 'Not Available') {
    return dispatch(loadMoreWithoutScroll());
  }
  try {
    const response = await dispatch(productApiActions.getProductsScroll({ scrollId }, { jsonApi: true }));
    dispatch(updateCurrentProductSearch(response));
  } catch (e) {
    // failed to load, must have timed out, but we want to keep this moving.
    dispatch(loadMoreWithoutScroll());
  }
};

const resetSearchFilters = () => ({ type: events.RESET_SEARCH_FILTERS });

const resetFilters = (history) => (dispatch, getState) => {
  dispatch(resetSearchFilters());
  updateSearch(history, getState);
};

const resetRangeFilter = () => ({ type: events.RESET_POINT_RANGE_FILTER });

const resetPointRangeFilter = (history) => (dispatch, getState) => {
  dispatch(resetRangeFilter());
  updateSearch(history, getState);
};

export {
  getProductsSearch,
  sortSearch,
  submitPointSearch,
  categorySearch,
  resetFilters,
  submitAllSearch,
  resetSearchResults,
  setBrandChange,
  setCategoryChange,
  scrollSearch,
  resetPointRangeFilter
};
