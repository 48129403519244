import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { SubmissionError } from 'redux-form';
import { Field, reduxForm } from 'rsv8-forms';

let AddApplication = (props) => {
  const { handleSubmit, pristine, reset, submitting, actions, clientId } = props;

  let saveApplication = (values) => {
    return actions
      .createApplication(values, clientId)
      .then((response) => {
        actions.getApplication(clientId);
        document.querySelector<any>('#success-message').style.display = 'block';
        reset('saveApplication');
      })
      .catch((err) => {
        throw new SubmissionError({
          appKey: 'That App Key is already in use',
          _error: 'Form Failed to Submit'
        });
      });
  };

  let checkForDefault = () => {
    let isDefaultSearch = false;
    let { application } = props.navigationMenuAdmin;

    application.forEach((item) => {
      item.isDefault ? (isDefaultSearch = true) : '';
    });
    return isDefaultSearch;
  };

  return (
    <form onSubmit={handleSubmit(saveApplication)}>
      <div className="form-group">
        <label>
          App Key <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="appKey" component={'bs-input'} type="text" className="form-control" />
          {props.appKey && props.appKey}
        </div>
      </div>

      <div className="form-group">
        <label>
          Is Default: <sup>*</sup>
        </label>
        <div>
          <Field
            required="true"
            name="appIsDefault"
            component="select"
            type="select"
            className="form-control"
            disabled={checkForDefault() ? true : false}
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          name <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="appName" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div id="success-message" style={{ display: 'none' }}>
        <div className="panel panel-success">
          <div className="panel-body">Application has been added</div>
        </div>
      </div>
      {props.error && <Panel bsStyle="danger">{props.error}</Panel>}

      <div className="form-group">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          style={{ marginRight: '15px' }}
          disabled={pristine || submitting}
        >
          Save
        </button>
        {/*<button type="button" className="btn btn-sm btn-primary" disabled={pristine || submitting} onClick={reset}>
          Edit Menu Item (Clear Fields)
          </button>*/}
      </div>
      <div className="error-report" />
    </form>
  );
};

export default reduxForm({
  form: 'AddApplication' // a unique identifier for this form
})(AddApplication) as any;
