import { CartItemModel, OrderModel } from 'xcel-api-generator/dist/catalog';
import { createTypeSelector, getRequest } from 'xcel-redux-orm';
import { emptyArray, emptyObject } from 'xcel-util';
import {
  CART_ITEM_JSON_TYPE,
  CART_STATE,
  ORDER_JSON_TYPE,
  ORDER_STATE,
  ROOT_STATE
  } from '../../constants';
import { ReduxState as State } from '../types';

export const isCheckoutModalOpen = (state) => state[ROOT_STATE][ORDER_STATE].isCheckoutOpen;
export const getUserInfo = (state) => state[ROOT_STATE][ORDER_STATE].userInfo;

export const cartSelector = createTypeSelector<CartItemModel>(CART_ITEM_JSON_TYPE);
export const getCartRequest = (state) => getRequest(state, 'getCart');
export const getCartProducts = (state: State) => {
  const cartRequest = getCartRequest(state);
  if (!cartRequest.loading && Object.keys(cartRequest.requests).length > 0 && cartRequest.error) {
    return emptyArray;
  } else {
    const cartState: any = (state[ROOT_STATE] && state[ROOT_STATE][CART_STATE]) || emptyObject;
    return cartSelector.selectMany(state, cartState.currentCart) || emptyArray;
  }
};

export const orderSelector = createTypeSelector<OrderModel>('orderHistoryModel');

export const orderConfirmationSelector = createTypeSelector<OrderModel>(ORDER_JSON_TYPE);

export const getOrderProducts = (state) => orderSelector.selectMany(state, null);
export const getOrdersRequest = (state) => getRequest(state, 'getOrder');
export const getOrderRequest = (state) => getRequest(state, 'getOrderById');
export const getOrder = (state, id) => orderSelector.selectOne(state, id);
export const getOrderConfirmation = (state, id) => orderConfirmationSelector.selectOne(state, id);

export const getShippingModalVisibility = (state) => state[ROOT_STATE][ORDER_STATE].isShippingInfoOpen;
export const getPointsModalVisibility = (state) => state[ROOT_STATE][ORDER_STATE].isPointsBreakdownOpen;
