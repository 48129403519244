import * as constants from '../../constants';
import { getCalculatorGoalsCombinedByCalculatorKey } from './getCalculatorGoalsCombinedByCalculatorKey';
import { destroyRequest } from 'xcel-redux-orm';
import { getReduxStore } from 'xcel-redux-module';

const setCombinedMetricGoals = (response) => ({ 
    type: constants.COMBINED_METRICS_REDUCER_KEY, 
    payload: response 
});

const dispatchCombinedMetricGoals = (calcGoals: any) => async (dispatch, getState) => {
  try {
    const store = getReduxStore();
    const response = store.dispatch( setCombinedMetricGoals( calcGoals ) );
    return response;
    
  } catch (error) {
      console.log("Error Found!: ", error);
  }
}

 const getCalculations = ({ programKey, calculatorKey, key }) => async (dispatch, getState) => {
  try {     
    dispatch(destroyRequest('getCalculatorGoalsCombinedByCalculatorKey'));
    const response = await dispatch( getCalculatorGoalsCombinedByCalculatorKey(programKey, calculatorKey, key), { jsonApi: true } );
     if (!!response) {
      dispatch(setCombinedMetricGoals( response ));
    } 
    return response;

  } catch (error) {
    console.log("Error Found! :", error);  
  } 
};
export { getCalculations,dispatchCombinedMetricGoals };
