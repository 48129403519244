import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Button = styled.button`
  background-color: #b5be35;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Helvetica;
  font-size: 1.2em;
  text-align: center;
  padding: 5px 15px;
  &.full-width {
    width: 100%;
  }
  :disabled {
    background-color: #ebebeb;
    color: #4c4f4f;
    cursor: not-allowed;    
  }
`;

export const AvatarModalContainer = styled(Modal)`
  .modal-dialog {
    margin: 100px auto;
    width: calc(100vw - 500px);
    
    @media (max-width: 480px) {
      margin: 25px auto;
      width: calc(100vw - 50px);
    }
    @media (min-width: 481px) and (max-width: 1024px) {
      margin: 25px auto;
      width: calc(100vw - 50px);
    }
  }
` as any;

export const AvatarHeader = styled(Modal.Header)`
  padding: 4rem 5rem;

  @media (max-width: 480px) {
    line-height: 36px;
    padding: 10px;
  }

  button {
    color: #ffffff;
    font-weight: normal;
    opacity: 1;
  }
` as any;

export const AvatarBody = styled(Modal.Body)`
  /*height: calc(100vh - 300px);*/
  overflow-y: auto;
  padding: 15px 15px 0;
  text-align: center;
  .selectFile {
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
  }
  .selectFile:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .dragAndDrop {
    border: 5px dashed #000000;
    border-radius: 50px;
    font-size: 25px;
    margin: 50px auto;
    padding: 70px 0;
    width: 80%;

    @media (max-width: 480px) {
      border: 2px dashed #000000;
      border-radius: 30px;
      font-size: 18px;
      margin: 15px auto 40px;
      padding: 20px 20px;
      width: 100%;
    }
    @media (min-width: 481px) and (max-width: 1024px) {
      font-size: 22px;
      margin: 20px auto 55px;
    }
  }
  .fa-upload {
    font-family: 'FontAwesome' !important;
    color: #333;
    font-size: 40px;

    @media (max-width: 480px) {
      font-size: 30px;
      margin-bottom: 10px;
    }
    @media (min-width: 481px) and (max-width: 1024px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }
  .avatarEditorContent {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  .avatarEditorContent {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  a {
    text-decoration: underline;
  }
` as any;

export const AvatarFooter = styled(Modal.Footer)`
  padding: 28px 15px 0;
  text-align: center;
  .cancelButton, .cropButton {
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
    padding: 5px 15px 
  }
  .fa-pulse {
    display: none;
    font-size: 35px;
  }
  span {
    display: inline-block;
    font-size: 25px;
  }
  input {
    width: 100%;
    display: inline-block;
  }
  input:hover {
    background: transparent;
    border-color: transparent;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #000000;
    height: 18px;
    margin-top: -7px;
    width: 6px;
  }
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    vertical-align: middle;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4.2px;
    cursor: pointer;
    background: #dbdbdb;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #dbdbdb;
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 4.2px;
    cursor: pointer;
    background: #dbdbdb;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 4.2px;
    cursor: pointer;
    background: #dbdbdb;
  }
` as any;

export const StyledBar = styled.label`
  width: 100%;
` as any;

export const StyledInput = styled.input`
  display: none !important;
` as any;

export const StyledLabel = styled.label`
  color: #ffffff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-weight: 500;
  margin-top: 10px;
  width: 275px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.6) !important;
    color: #ffffff;
  }
` as any;

export const StyledRotateIcon = styled.span`
  margin-top: 6px;
  cursor: pointer;
`;
