import * as React from 'react';
import { connect } from 'react-redux';
import { register } from 'xcel-react-core';
import { nomineeSearchSelectors } from '../../redux/selectors/index';
import { NomineePanels } from './NomineePanels/index';
import { NomineeTable } from './NomineeTable/index';

export interface NomineeListProps {
  selectNominee: any;
  currentNominees: Array<string>;
  loadingNominees: Array<string>;
}

interface InternalProps extends NomineeListProps {
  visibleNominees: Array<string>;
}

const NomineeDisplay: React.SFC<InternalProps> = (props) => {
  return (
    <React.Fragment>
      <NomineeTable
        className="hidden-sm hidden-xs"
        selectNominee={props.selectNominee}
        nomineeIds={props.visibleNominees}
        currentNominees={props.currentNominees}
        loadingNominees={props.loadingNominees}
      />
      <NomineePanels
        className="hidden-md hidden-lg"
        selectNominee={props.selectNominee}
        nomineeIds={props.visibleNominees}
        currentNominees={props.currentNominees}
        loadingNominees={props.loadingNominees}
      />
    </React.Fragment>
  );
};

const mapState = (state) => ({
  visibleNominees: nomineeSearchSelectors.getVisibleNominees(state)
});

const compose = register('rsv8-nomination/NomineeSearchResults');

export default compose(
  connect(
    mapState,
    null
  )
)(NomineeDisplay) as React.SFC<NomineeListProps>;
