import IndividualProductPage from './IndividualProducts/ProductPage';
import TeamProductPage from './TeamProducts/ProductPage';
export { CompleteNomination, ConfirmNomination } from './ConfirmNomination';
export { AwardPage as IndividualAwardPage } from './IndividualAwards';
export { ProductPage as IndividualProductsPage } from './IndividualProducts';
export { IndividualCriteriaPage } from './IndividualCriteria';
export { DetailForm } from './IndividualDetails';
export { NominationWizard } from './IndividualNomination';
export { NominationSearch as IndividualNomineeSearch } from './IndividualNominee';
export { MyAwards } from './MyAwards';
export { NominationDashboard } from './NominationDashboard';
export { AwardPage as TeamAwardPage } from './TeamAwards';
export { TeamCriteriaPage } from './TeamCriteria';
export { TeamDetailForm, TeamDetailPage } from './TeamDetails';
export { TeamNominationWizard } from './TeamNomination';
export { TeamNomineePage } from './TeamNominee';
export { DirtyWizard } from './Congratulations';
export { ApproveByEmailForm } from './ApproveByEmail';
export { RedeemingGiftWizard } from './RedeemingGiftViaEmail';

export { TeamNomineeDetailForm, TeamNomineeDetailPanel, TeamNomineeDetailPage } from './TeamNomineeDetails';
export { TeamConfirmationPage, TeamCongratulationsPage, TeamMemberPanel } from './TeamSummary';
export {
  NominationActivity,
  TeamDraftTable,
  IndividualDraftTable,
  TeamHistoryTable,
  IndividualHistoryTable
} from './NominationActivity';
export {
  NominationApproval,
  IndividualPendingApprovalsTable,
  TeamPendingApprovalsTable,
  IndividualApprovalHistoryTable,
  TeamApprovalHistoryTable
} from './NominationApproval';

export { IndividualProductPage, TeamProductPage };
