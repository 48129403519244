import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import styled from 'styled-components';

export type Props = {
  current: number,
  maximum: number,
  chartWidgetSize: number,
  totalBarColor?: string,
  myPointsBarColor?: string,
};

/**
 * creates the chart container
 */
const ChartContainer = styled.div<{ size: number }>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: ${(props: any) => props.size}px;
  z-index: 0;

  // Highchart elements
  svg > defs {
    display: none;
  }
  svg > rect.highcharts-plot-background {
    rx: 4px;
    ry: 4px;
  }
`;

/**
 * Main component that puts together the piechart
 * @param param0 
 */
const SimpleBar: React.FunctionComponent<Props> = (
  {
    totalBarColor = 'white',
    myPointsBarColor = 'navy',
    chartWidgetSize = 320,
    maximum,
    current,
  }) => (
  <ChartContainer size={chartWidgetSize}>
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: null,
        subtitle: null,
        caption: null,
        chart: {
          type: 'bar',
          height: 30,
          width: chartWidgetSize,
          backgroundColor: 'transparent',
          plotBackgroundColor: 'transparent'
        },
        credits: { enabled: false },
        tooltip: { enabled: false },
        legend: { enabled: false },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          visible: false,
          min: 0,
          max: maximum,
        },
        series: [
          {
            type: "bar",
            data: [maximum],
            color: totalBarColor,
            animation: false,
          } as Highcharts.SeriesBarOptions,
          {
            type: "bar",
            data: [current],
            color: myPointsBarColor,
            animation: {
              duration: 250,
            },
          } as Highcharts.SeriesBarOptions
        ],
        plotOptions: {
          bar: {
            animation: false,
            borderRadius: 4,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            enableMouseTracking: false,
            grouping: false,
            pointWidth: 25,
          }
        },
      } as Highcharts.Options}
    />
  </ChartContainer>
);

function areEqual(prevProps, nextProps) {
  return prevProps.chartWidgetSize === nextProps.chartWidgetSize
    && prevProps.myGoalTarget === nextProps.myGoalTarget
    && prevProps.myAmount === nextProps.myAmount;
}

export default React.memo(SimpleBar, areEqual);
