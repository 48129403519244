import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { getRequest, RequestTypes } from 'xcel-redux-orm';
import { contentCultureActions, providerActions } from '../../../redux/actions';
import { contentCultureSelector, contentTypeSelector, providerSelector } from '../../../redux/selectors';

class ProviderDropdown extends React.Component<any> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(providerActions.get());
  }
  handleChange = (providerId: any) => {
    const { onChange, dispatch, contentTypeId } = this.props;
    if (onChange) {
      onChange(providerId);
    }
    dispatch(contentCultureActions.setActiveByProviderId(contentTypeId, providerId));
  };
  render() {
    const { providers, value, contentCulture, contentVersionsRequest, ...rest } = this.props;
    return (
      <ReactField
        {...rest}
        component="bs-select"
        title="Provider"
        label={contentVersionsRequest && contentVersionsRequest.loading === true ? 'Provider (Loading)' : 'Provider'}
        value={contentCulture.provider && contentCulture.provider.id}
        onChange={this.handleChange}
        options={providers.map((item) => ({ value: item.id, label: item.id }))}
      />
    );
  }
}
const emptyObject = {};
const emptyArray = [];
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    contentCulture: contentCultureSelector.getActive(state, contentTypeId) || emptyObject,
    providers: providerSelector.selectMany(state) || emptyArray,
    contentVersionsRequest: getRequest(state, 'getContentversionsByContentCultureId') as RequestTypes.Endpoint
  };
};

export default connect(mapStateToProps)(ProviderDropdown) as any;
