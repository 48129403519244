import * as React from 'react';
import { Clearfix, MenuItem } from 'react-bootstrap';
import * as Icon from 'react-fontawesome';
import { FabButton } from 'rsv8-components';
import styled from 'styled-components';
import ContentPromotionButton from '../../../../components/ContentPromotionButton';
import CreateContentModal from '../Modals/CreateContent';
import CreateContentTypeModal from '../Modals/CreateContentType';
import CreateRouteModal from '../Modals/CreateRoute';
const ContentListToolbarBase = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 18px;
  cursor: pointer;
  ul.dropdown-menu.open {
    display: block;
    right: 0;
    left: auto;
  }
`;

const BottomFixed = styled.div`
  position: fixed !important;
  bottom: 2em;
  right: 3em;
`;

const FloatMenu = styled.ul`
  top: -190px;
  left: -168px;
  ${(props: { open: boolean }) => (props.open ? 'display: block;' : '')};
`;

class ContentListToolbar extends React.Component<any> {
  state = { open: false, modal: null };
  handleToggle = () => {
    this.setState({ ...this.state, open: !this.state.open });
  };
  handleClickItem = (type) => (e) => {
    const { onClick } = this.props;
    this.setState({ open: !this.state.open, modal: type });
    if (onClick) {
      onClick(type);
    }
  };
  handleHide = () => {
    this.setState({ ...this.state, modal: null });
  };
  render() {
    return (
      <React.Fragment>
        <ContentListToolbarBase>
          <Clearfix>
            <CreateRouteModal
              onHide={this.handleHide}
              onCreate={this.handleHide}
              show={this.state.modal === 'folder'}
            />
            <CreateContentModal
              onHide={this.handleHide}
              onCreate={this.handleHide}
              show={this.state.modal === 'content'}
            />
            <CreateContentTypeModal
              onHide={this.handleHide}
              onCreate={this.handleHide}
              show={this.state.modal === 'type'}
            />
          </Clearfix>
        </ContentListToolbarBase>
        <BottomFixed>
          <FabButton className="btn btn-primary" onClick={this.handleToggle}>
            <Icon name="plus" size="2x" />
          </FabButton>
          <ContentPromotionButton index={1} />
          <FloatMenu className={`dropdown-menu`} open={this.state.open}>
            <MenuItem header={true}>Add Item</MenuItem>
            <MenuItem onClick={this.handleClickItem('type')}>
              <Icon name="cube" tag="i" /> Type
            </MenuItem>
            <MenuItem onClick={this.handleClickItem('folder')}>
              <Icon name="folder" tag="i" /> Folder
            </MenuItem>
            <MenuItem onClick={this.handleClickItem('content')}>
              <Icon name="file-text-o" tag="i" /> Content
            </MenuItem>
          </FloatMenu>
        </BottomFixed>
      </React.Fragment>
    );
  }
}
export default ContentListToolbar;
