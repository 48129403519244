import * as events from '../../events';

const initialState = {
  visibleNominees: [],
  nomineeScroll: null,
  latestSearch: {},
  totalRecords: 0,
  filters: [],
  visibleAwards: []
};

const nomineeSearchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case events.CLEAR_INDIVIDUAL_NOMINATION:
      return initialState;
    case events.SET_INDIVIDUAL_NOMINATION_PROPERTY:
      return { ...state, [payload.property]: payload.value };
    case events.SET_VISIBLE_NOMINEES:
      return { ...state, visibleNominees: payload };
    case events.ADD_VISIBLE_NOMINEES:
      return { ...state, visibleNominees: state.visibleNominees.concat(payload) };
    default:
      return state;
  }
};

export default nomineeSearchReducer;
