import * as moment from 'moment';
import * as React from 'react';
import { Clearfix, Col, Row } from 'react-bootstrap';
import * as Datetime from 'react-datetime';
import { Field, Label } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const FilterDateRangeContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const DashDiv = styled(Col)`
  font-size: 14px;
  font-family: Helvetica;
  color: #4c4f4f;
  padding: 5px 0px;
  text-align: center;
`;

const BreakDiv = styled.div`
  padding: 10px 0px;
  clear: both;
`;

const ErrDiv = styled.div`
  font: 12px;
  text-transform: uppercase;
  padding: 3px 0px;
  color: #f00;
  display: ${(props: DatetimeProps) => (props.hasErr ? 'block' : 'none')};
`;

const FieldDatetime = styled(Datetime)`
  .form-control {
    border: 1px solid ${(props: DatetimeProps) => (props.hasErr ? '#F00' : '#ccc')};
  }
`;

const isConstraintAfterToday = (currentDate) => {
  let todaysDate = moment();
  return currentDate.isBefore(moment(todaysDate));
};

type DatetimeProps = {
  hasErr?: boolean;
};

const localDateFormat = 'MM/DD/YYYY';

interface Props {
  checkForm: Function;
  dateRangeText: string;
  hasErr: any;
}

const FilterDateRange: React.SFC<Props> = (props) => {
  const handleChange = (fieldProps, value) => {
    fieldProps.input.onChange(value);
    props.checkForm(fieldProps.input.name, value);
  };
  const { dateRangeText } = props;

  let errStartDate = props.hasErr.err && props.hasErr.field === 'start' ? true : false;
  let errEndDate = props.hasErr.err && props.hasErr.field === 'end' ? true : false;

  return (
    <FilterDateRangeContainer>
      <Label>{dateRangeText}</Label>
      <Row>
        <Col className="start-date" lg={11} md={24} sm={24} xs={11}>
          <div className={errStartDate ? 'error' : 'pristine'}>
            <Field
              type="text"
              name="awardDateStart"
              component={(fieldProps) => (
                <FieldDatetime
                  // {...props.input}
                  hasErr={errStartDate}
                  isValidDate={isConstraintAfterToday}
                  value={fieldProps.input.value}
                  onChange={(value) => handleChange(fieldProps, value)}
                  dateFormat={localDateFormat}
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'MM/DD/YYYY' }}
                />
              )}
            />
            <ErrDiv hasErr={errStartDate}>{props.hasErr.msg}</ErrDiv>
          </div>
        </Col>
        <DashDiv xs={2} smHidden={true} mdHidden={true}>
          {'-'}
        </DashDiv>
        <BreakDiv className="hidden-xs hidden-lg" />
        <Col className="end-date" lg={11} md={24} sm={24} xs={11}>
          <div className={errEndDate ? 'error' : 'pristine'}>
            <Field
              type="text"
              name="awardDateEnd"
              component={(fieldProps) => (
                <FieldDatetime
                  hasErr={errEndDate}
                  isValidDate={isConstraintAfterToday}
                  value={fieldProps.input.value}
                  onChange={(value) => handleChange(fieldProps, value)}
                  dateFormat={localDateFormat}
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'MM/DD/YYYY' }}
                />
              )}
            />
            <ErrDiv hasErr={errEndDate}>{props.hasErr.msg}</ErrDiv>
          </div>
        </Col>
        <Clearfix />
      </Row>
    </FilterDateRangeContainer>
  );
};

const mapResourceToProps = (getResource) => ({
  dateRangeText: getResource('FilterDateRange.dateRangeText', 'Date Range')
});

export default withResource(mapResourceToProps)(FilterDateRange);
