import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { dispatchTimePeriod, getTimePeriod } from '../../../redux/actions/getTimePeriod';
import * as utils from '../../../utils';
import { CircleButtonSlider, NewCircleButton } from './Styles/CircleButtonStyles';

export const CircleButtonContainer = styled.div`
  width: 98%;
  max-width: 490px;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  vertical-align: middle;
  margin: 0 auto;
  box-shadow: -3px 3px transparent, -2px 2px transparent, -1px 1px transparent;
  background-color: transparent;
  @media only screen and (max-width: 980px) {
    width: 62%;
  }
  @media only screen and (max-width: 780px) {
    width: 89%;
  }
  @media only screen and (max-width: 541px) {
    width: 89%;
  }
` as any;

export const MobileCircleButtonContainer = styled.div`
  width: 310px;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  vertical-align: middle;
  margin: 1%;
  overflow: auto;
  white-space: wrap;
  box-shadow: -3px 3px transparent, -2px 2px transparent, -1px 1px transparent;
  background-color: transparent;
` as any;

export interface Props {
  ProgramKey: any;
  CalculatorKey: any;
  TimePeriodStatusAndColor: any;
  mobileSliderSettings: any;
  sliderSettings: any;
  DataKey: string;
  actions: any;
}
class CircleButtonSelector extends React.Component<Props, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      currTimePeriodKey: [],
      arrTimePeriod: [],
      arrCalcCombinedGoals: [],
      isSelected: ""
    }     
   }

  async componentDidMount() {
   await this.loadTimePeriodData();
   }  

  async loadTimePeriodData() {
      const { props } = this;
      const { ProgramKey }   = props;  
      try {
          await this.props.actions.getTimePeriod(ProgramKey).then(response => { (response !== undefined) ? this.setState({arrTimePeriod:response.data}) : null });
          let allTimePeriods = this.state.arrTimePeriod
          this.onClickSelectTimePeriod({id: this.setDefaultTimeKey(allTimePeriods)} ); 
      } catch (error) {
        console.log("error loading data=", error);
      }
   }
  
renderError = (error?: any) => {
   console.log("Error Found!" + error);
  };

  onClickSelectTimePeriod(timePeriod: any) {
    if (timePeriod !== undefined) {
      const timePeriodKey = timePeriod.id;
      this.setState({ currTimePeriodKey: timePeriodKey });
      this.setState({ isSelected: 'true' });
      this.postTimePeriod(timePeriodKey);
    }
  }

  postTimePeriod(key: number) {
    const dataKey = this.props.DataKey;
    const calculatoKey = this.props.CalculatorKey;
    const programKey = this.props.ProgramKey;
    this.props.actions.dispatchTimePeriod({ dataKey, key, calculatoKey, programKey });
  }

  hasValidStatus(timePeriodStatus: string) {
    if (timePeriodStatus !== undefined && timePeriodStatus.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isCurrentTime(timePeriodKey: any) {
    const currentDate = utils.getTimePeriodKeyFromCurrentDate(new Date());
    return timePeriodKey <= currentDate ? true : false;
  }
  getColorForStatus(timePeriodKey: any, timePeriodStatus: string): string {
    let statusAndColor = this.props.TimePeriodStatusAndColor;
    let returnColor = '';
    timePeriodStatus = utils.ToLowerCase(timePeriodStatus);

    if (statusAndColor !== undefined && this.hasValidStatus(timePeriodStatus)) {
      for (var i = 0; i <= statusAndColor.length - 1; i++) {
        let status = utils.ToLowerCase(statusAndColor[i].status);
        if (status !== undefined && status.includes(timePeriodStatus)) {
          returnColor = statusAndColor[i].color;
          // returnColor = (returnColor) ? returnColor : defaultColor;
          break;
        }
      }
    }
    return returnColor;
  }

  // the default key is the active key, not exceeding the current timeperiod (year + month)).
  // the active key is a key with a status, any status
  setDefaultTimeKey(arrTimePeriod: any) {
    let currentDateKey = utils.getTimePeriodKeyFromCurrentDate(new Date());
    let currPeriod = '';
    try {
      if (arrTimePeriod.length > 0) {
        let currStatus = '';
        let currKey = '';
        for (let i = 0; i < arrTimePeriod.length - 1; i++) {
          currStatus = arrTimePeriod[i].attributes.periodStatus;
          currKey = arrTimePeriod[i].attributes.key;
          if (this.getColorForStatus(currKey, currStatus) !== '') {
            if (currKey <= currentDateKey) {
              currPeriod = currKey;
            }
          }
        }
      }
    } catch (error) {
      console.log('Error looking for Active Month', error);
    }
    return currPeriod;
  }

  createButtons(Params: any) {
    const { timeperiodStatus, name, key } = Params;

    const defaultColor = '#414141'; // ""#000000";
    const defBorderRadius = '10px';
    const hasColorForStatus = this.getColorForStatus(key, timeperiodStatus);

    const currColor = hasColorForStatus ? hasColorForStatus : defaultColor;
    const isTimeValid = this.isCurrentTime(key);

    const nonValidStatusColor = {
      border: 'solid 1px ' + defaultColor,
      color: defaultColor,
      borderRadius: defBorderRadius
    };
    const nonValidStatusSelected = {
      border: 'double 4px ' + defaultColor,
      color: defaultColor,
      borderRadius: defBorderRadius
    };

    const isAMonthSelected = this.state.isSelected;
    const currentMonthKey = this.state.currTimePeriodKey;

    const doubleBorderStyle = {
      border: 'double 5px ' + currColor,
      color: currColor,
      borderRadius: defBorderRadius,
      borderSpacing: '15px'
    };
    const singleBorderStyle = { border: 'solid 1px ' + currColor, color: currColor, borderRadius: defBorderRadius };
    
    return hasColorForStatus && isTimeValid ? (
      <NewCircleButton
        title={timeperiodStatus}
        style={isAMonthSelected === 'true' && currentMonthKey === key ? doubleBorderStyle : singleBorderStyle}
        onClick={() => this.onClickSelectTimePeriod({ id: key })}
      >
        {utils.ToUpperCase(name)}
      </NewCircleButton>
    ) : (
      <NewCircleButton
        title={timeperiodStatus}
        style={isAMonthSelected === 'true' && currentMonthKey === key ? nonValidStatusSelected : nonValidStatusColor}
      >
        {utils.ToUpperCase(name)}
      </NewCircleButton>
    );
  }

  renderButtons(timePeriod: any) {
    if (timePeriod !== undefined && timePeriod.length > 0) {
      const timePeriodLength = timePeriod.length;
      var index = 0;
      return timePeriod.map((month) => (
        <Col
          md={0}
          xs={0}
          sm={0}
          lg={0}
          xsOffset={0}
          smOffset={0}
          mdOffset={0}
          lgOffset={0}
          cellPadding={0}
          cellSpacing={0}
          key={'row_' + month.attributes.key}
        >
          {this.createButtons({
            timeperiodStatus: month.attributes.periodStatus,
            key: month.attributes.key,
            name: month.attributes.name
          })}
          {(index = index + 1) === timePeriodLength ? (
            <svg type="text/css" height="19" width="35%" stroke="transparent">
              s
              <path strokeWidth="0" d="M1 20 5500 20" />
            </svg>
          ) : (
            <svg type="text/css" height="19" width="26%" stroke="#bebebe">
              <path strokeWidth="4" d="M7 20 5000 20" />
            </svg>
          )}
        </Col>
      ));
    } else {
     /* return (
        <Loader />
        // this.renderError('There wasnt any time periods returned. Ensure you have the proper authorization.')
      );*/
    }
  }
  render() {
    const allTimePeriods = this.state.arrTimePeriod;
    const showSliders = (count: number) => (allTimePeriods !== undefined ? Math.min(allTimePeriods.length, count) : 0);

    const sliderSettings = {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: showSliders(6),
      slidesToScroll: 3,
      arrows: true,
      responsive: [
        {
          breakpoint: 1370,
          settings: {
            slidesToShow: showSliders(5),
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1190,
          settings: {
            slidesToShow: showSliders(5),
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 658,
          settings: {
            slidesToShow: showSliders(4),
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 350,
          settings: {
            slidesToShow: showSliders(3),
            slidesToScroll: 2
          }
        }
      ],
      ...this.props.sliderSettings
    };

    const mobileSliderSettings = {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: showSliders(4),
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: showSliders(4),
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 638,
          settings: {
            slidesToShow: showSliders(4),
            slidesToScroll: 3
          }
        }
      ],
      ...this.props.mobileSliderSettings
    };

    return (
      <React.Fragment id="circleButtons" key="circleButtons" {...this.state.props}>
        <MediaQuery query="(min-device-width: 551px)">
          <CircleButtonContainer>
            {/* monthly time period image buttons */}
            <Row>
              <CircleButtonSlider {...sliderSettings}>{this.renderButtons(allTimePeriods)}</CircleButtonSlider>
            </Row>
          </CircleButtonContainer>
        </MediaQuery>

        <MediaQuery query="(max-device-width: 550px)">
          <MobileCircleButtonContainer>
            {/* for mobile*/}
            <Row>
              <CircleButtonSlider {...mobileSliderSettings}>{this.renderButtons(allTimePeriods)}</CircleButtonSlider>
            </Row>
          </MobileCircleButtonContainer>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  //  newTimePeriod: dispatchTimePeriod(state),
})
  
const mapDispatchToProps = (dispatch) => ({
    actions:  bindActionCreators( { dispatchTimePeriod, getTimePeriod} , dispatch ),
 });

const mapResourceToProps = (getResource: any) => ({});

const mapContentToProps = (getContent) => ({
  TimePeriodStatusAndColor: getContent('Add a Status and a Corresponding Color:', {
    type: 'array',
    schema: [
      { model: 'status', label: 'Add Status' },
      { model: 'color', label: 'Add Color for Status' }
    ]
  }),

  ProgramKey: getContent('Enter Program Key', {
    type: 'string',
    schema: [{ model: 'Program Key:', label: 'Program Key:' }]
  }),

  DataKey: getContent('Enter Data Key', {
    type: 'string',
    schema: [{ model: 'Data Key:', label: 'Data Key:' }]
  })
});

export default register('rsv8-metrics/CircleButtonSelector')(
  connect(mapStateToProps, mapDispatchToProps),
  withResource(mapResourceToProps),
  withContent(mapContentToProps),
  withTheme()
)(CircleButtonSelector);
