import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'rsv8-forms';
import { ColorPicker } from '../../components/Fields/Color';
import { colorAdd } from '../../redux/color.actions';
import { mapDispatchToActions } from '../../util';
import { Spacer } from '../fonts/styles';
const ColorAdd: React.SFC<any> = ({ actions, handleSubmit }) => {
  const handleEnterKey = (fn) => (e) => {
    if (e.key === 'Enter') {
      fn(e);
    }
  };
  const handleColorAdd = (color) => {
    if (!color.color) {
      color.color = '#000000';
    }
    actions.colorAdd(color);
  };
  return (
    <form onSubmit={handleSubmit(handleColorAdd)}>
      <h4>Add Colors</h4>
      <Row>
        <Col md={4}>
          <Field label={'Group'} component={'bs-input'} type={'text'} name={'group'} />
        </Col>
        <Col md={4}>
          <Field label={'Name'} component={'bs-input'} type={'text'} name={'name'} />
        </Col>
        <Col md={2}>
          <Col md={12}>
            <Field
              label="Color"
              component={ColorPicker}
              name={'color'}
              placeholder={'#000000'}
              onKeyUp={handleEnterKey(handleSubmit(handleColorAdd))}
            />
          </Col>
          <Col md={12}>
            <Spacer />
            <Button type="submit" title="Add Color">
              <i className="fa fa-plus" />
            </Button>
          </Col>
        </Col>
      </Row>
    </form>
  );
};

const mapDispatchToProps = mapDispatchToActions({ colorAdd });

export default reduxForm({ form: 'colorAdd' })(connect(
  null,
  mapDispatchToProps
)(ColorAdd) as any);
