import { AnyAction, combineReducers } from 'redux';
import { ROOT_STATE } from '../constants';
import contentType from './contentType';
import hoverReducer from './hover';
import socialReducer from './social';
export default { [ROOT_STATE]: combineReducers({
  content: contentType,
  hover: hoverReducer,
  social: socialReducer
}) };
export { AnyAction };
