import * as React from 'react';
import { Button } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const PagingContainer = styled.div`
  text-align: center;
`;

const Paging = ({ loadMoreLabel, disabled, ...rest }) => {
  return <PagingContainer>{disabled ? '' : <Button {...rest}>{loadMoreLabel}</Button>}</PagingContainer>;
};

const mapResource = (getResource) => ({
  loadMoreLabel: getResource('loadMoreLabel', 'Load More')
});

export default withResource(mapResource)(Paging);
