import * as React from 'react';
import { connect } from 'react-redux';
import { cultureSelector } from 'rsv8-client';
import { Loader } from 'rsv8-components';
import { AwardHeader } from 'rsv8-nomination';
import { nominationSelectors } from 'rsv8-nomination';
import { emptyObject } from 'xcel-util';
import { myAwardSelectors } from '../../../redux/selectors';

const { nominationSelectors: selectors } = nominationSelectors;
export interface NominationDetailModalBodyProps {
  nominationDetail: object;
  cultureLanguage: object;
  nominationDetailId: number | undefined;
  award: any;
  dispatch: Function;
}

export const NominationDetailModalBody: React.SFC<NominationDetailModalBodyProps> = (props) => {
  if (!props.nominationDetail || props.award === undefined || props.award === emptyObject) {
    return <Loader />;
  }

  return (
    <AwardHeader
      id={props.nominationDetailId}
      culture={props.cultureLanguage}
      open={true}
      award={props.award}
      awardLabel={props.award && props.award.awardCertificateLinkLabelText}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const nominationDetail = selectors.nominationDetailSelectors.selectOne(state, ownProps.nominationDetailId);
  const award =
    nominationDetail && nominationDetail.applicationId
      ? myAwardSelectors.selectOne(state, ownProps.nominationDetailId)
      : emptyObject;
  return {
    nominationDetail,
    award,
    cultureLanguage: cultureSelector.getActive(state).language
  };
};

export default connect(mapStateToProps)(NominationDetailModalBody as any);
