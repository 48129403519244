import * as React from 'react';
import { debounce } from 'xcel-util';
import { CriteriaForm } from '../index';

export interface SelectCriteriaProps {
  selectCustomFields: (v: object) => void;
  saveNomination?: () => void;
  initialValues: { NominationForm: { criteria: object } };
  form: string;
  award: string;
  type: string;
  showPage?: Function;
}

const SelectCriteria: React.SFC<SelectCriteriaProps> = (props) => {
  let delayedSaveNomination = () => undefined;
  if (props.saveNomination) {
    delayedSaveNomination = debounce(props.saveNomination, 1000, false);
  }

  const handleChange = (values) => {
    const form = Object.keys(values.NominationForm).length > 0 ? values.NominationForm : values.TeamNomination;
    if (form.recognition_pillars) {
      form.recognition_pillars = [];
    }
    props.selectCustomFields(
      form
    );
    delayedSaveNomination();
  };

  return (
    <CriteriaForm
      type={props.type}
      form="IndividualCriteriaForm"
      award={props.award}
      initialValues={props.initialValues}
      onChange={handleChange}
      showPage={props.showPage}
    />
  );
};

export default SelectCriteria;
