import * as React from 'react';
import styled from 'styled-components';
import { jsx } from 'xcel-react-core';
import { PlaceholderBanner } from '../../PlaceholderBanner/index';
import { Table } from '../index';
import ResponsiveTable from '../ResponsiveTable';
import DataTableCellRow from './DataTableCellRow';
import DataTableHeaderRow from './DataTableHeaderRow';
const TABLE_TEMPLATE_COLUMN = 'DataTableColumn';
const ScrollableTable = styled(Table)`
  tbody {
    display: block;
    max-height: 600px;
    ${(props: any) => props.height && `max-height: ${props.height};`};
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
` as any;
class DataTable extends React.PureComponent<any> {
  state: any = { columns: [] };
  componentDidMount() {
    this.setState({ columns: this.getColumns() });
  }
  getColumns = () => {
    // we don't want to include children so their not transformed into objects as well.
    const config = jsx.toObject(this.props.children, { includeChildren: false }) as any;
    return config.children
      .filter((child) => child.type === TABLE_TEMPLATE_COLUMN)
      .map(({ props: { children: label, ...rest } }) => ({ label, ...rest }));
  };
  render() {
    const { data, children, className, emptyIcon, emptyText, loading, ...props } = this.props;
    const { columns } = this.state;
    return (
      <React.Fragment>
        {data.length === 0 && !loading ? (
          <PlaceholderBanner emptyIcon={emptyIcon} text={emptyText} />
        ) : (
          data.length !== 0 && (
            <React.Fragment>
              <ScrollableTable {...props} className={`${className || ''} hidden-xs`}>
                <thead>
                  <DataTableHeaderRow columns={columns} />
                </thead>
                <tbody>
                  {data &&
                    data.map((record, index) => {
                      return <DataTableCellRow key={index} index={index} columns={columns} record={record} />;
                    })}
                  <tr>
                    <td colSpan={columns.length}>{children}</td>
                  </tr>
                </tbody>
              </ScrollableTable>
              <ResponsiveTable data={data} columns={columns} className={`${className || ''} visible-xs`} />
            </React.Fragment>
          )
        )}
      </React.Fragment>
    );
  }
}
export default DataTable;
