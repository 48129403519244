import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { injectGlobal } from 'styled-components';
import { LocalShareableFeed } from '../../../api/get-shareables-activities';
import { shareableButtonIsVisible, SocialShareableActivity } from './SocialShareableActivity';
import {
  Container,
  FeedTitle,
  StyledLeftArrowIcon,
  StyledRightArrowIcon
  } from './style';

export interface SocialShareableFeedProps {
  feed: LocalShareableFeed;
  trackActivityAction: Function;
  sliderSettings: any;
}

injectGlobal`
  [data-social-shareable-feed] .slick-slide {
    padding: 5px;
  }
`;

export class SocialShareableFeed extends React.PureComponent<SocialShareableFeedProps> {
  render() {
    return (
      <Container data-social-shareable-feed={true} {...this.props}>
        {this.props.feed.feedGroupLabel && (
          <FeedTitle className="social-feed-title">{this.props.feed.feedGroupLabel}</FeedTitle>
        )}
        {this.renderContent()}
      </Container>
    );
  }

  renderContent() {
    const withShareableButton = this.props.feed.activities.some((activitity) => shareableButtonIsVisible(activitity));

    const getSlidersToShow = (cnt: number) => Math.min(this.props.feed.activities.length, cnt);

    const sliderSettings = {
      dots: false,
      infinite: false,
      speed: 200,
      slidesToShow: getSlidersToShow(4),
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1368,
          settings: {
            slidesToShow: getSlidersToShow(3),
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 968,
          settings: {
            slidesToShow: getSlidersToShow(1)
          }
        }
      ],
      prevArrow: <StyledLeftArrowIcon className="left-arrow-icon" marginTop={withShareableButton && '-8px'} />,
      nextArrow: <StyledRightArrowIcon className="left-arrow-icon" marginTop={withShareableButton && '-8px'} />,
      ...this.props.sliderSettings
    };
    if (this.props.feed.activities.length === 1) {
      return (
        <SocialShareableActivity
          activity={this.props.feed.activities[0]}
          trackActivityAction={this.props.trackActivityAction}
        />
      );
    }
    return (
      <Slider {...sliderSettings}>
        {this.props.feed.activities.map((activity) => (
          <SocialShareableActivity
            key={activity.id}
            activity={activity}
            trackActivityAction={this.props.trackActivityAction}
          />
        ))}
      </Slider>
    );
  }
}
