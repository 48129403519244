import * as React from 'react';
// @ts-ignore
/* tslint:disable */

// TODO: Replace hardcoded fill.
// TODO: change ID naming scheme. Would hypothetically cause issues with 2+
export const CalendarSvg = (props) => (
  <svg
    id="calendarSvg"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="214"
    height="185"
    viewBox="0 0 214 185"
  >
    <defs>
      <path id="a" d="M0 .385h184.651V164H0z" />
      <path id="c" d="M0 0h63.42v63.41H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#005A8B"
        d="M50.5 38c4.694 0 8.5-3.687 8.5-8.236V8.237C59 3.687 55.194 0 50.5 0 45.807 0 42 3.688 42 8.237v21.527C42 34.313 45.807 38 50.5 38"
      />
      <g transform="translate(0 21)">
        <path
          fill="#005A8B"
          d="M173.572.385h-19.316v8.316c0 10.738-8.698 19.473-19.389 19.473-10.691 0-19.389-8.735-19.389-19.473V.385H69.173v8.316c0 10.738-8.698 19.473-19.388 19.473-10.692 0-19.39-8.735-19.39-19.473V.385H11.08c-6.12 0-11.08 4.395-11.08 9.816v143.983C0 159.605 4.96 164 11.08 164h162.493c6.12 0 11.08-4.395 11.08-9.816V10.2c0-5.42-4.96-9.816-11.08-9.816"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#005A8B"
        d="M134.5 38c4.695 0 8.5-3.687 8.5-8.236V8.237C143 3.687 139.195 0 134.5 0c-4.692 0-8.5 3.688-8.5 8.237v21.527c0 4.549 3.808 8.236 8.5 8.236"
      />
      <g transform="translate(150 61)">
        <path
          fill="#0089C4"
          d="M43.647 3.744L45.145 0c4.732.828 8.844 2.153 12.236 5.11 3.095 2.698 4.877 5.962 6.042 10.525l-3.338 1.977c-3.76-6.272-9.539-11.2-16.438-13.868zm-23.875 0L18.277 0C13.545.828 9.433 2.153 6.041 5.11 2.946 7.808 1.167 11.072-.001 15.635l3.338 1.977A33.157 33.157 0 0 1 19.772 3.744zm41.142 30.444c0 16.141-13.085 29.223-29.222 29.223-16.141 0-29.229-13.082-29.229-29.223 0-16.14 13.088-29.222 29.229-29.222 16.137 0 29.222 13.082 29.222 29.222zm-26.8 3.056l-.803-20.604h-3.517l-.611 15.43-9.434.65v3.904l14.365.62z"
          mask="url(#d)"
        />
      </g>
    </g>
  </svg>
);
