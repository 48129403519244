import { ComponentType } from 'react';
import { Modal as ModalBase, ModalProps } from 'react-bootstrap';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';

// Adding this to be able to scroll within the modal
// When react-bootstrap v1.0.0 is ready, this won't be needed
// we'll just have to add scrollable={true} to <Modal />
const Modal = styled(ModalBase as any)`
  .modal-dialog {
    display: flex;
    min-height: calc(100vh - 1rem);
    margin: 0.5rem;
    align-items: center;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    max-height: calc(100vh - 1rem);
  }
  .modal-body {
    overflow-y: auto;
  }
  @media (min-width: 768px) {
    .modal-dialog {
      min-height: calc(100vh - 3.5rem);
      width: ${(props: Props) => (props.width ? (parseInt(props.width, 10) >= 728 ? '728px' : props.width) : '600px')};
      margin: 1.75rem auto;
    }
    .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
  }
  @media (min-width: 1000px) {
    .modal-dialog {
      width: ${(props: Props) => (props.width ? (parseInt(props.width, 10) >= 960 ? '960px' : props.width) : '600px')};
    }
  }
  @media (min-width: 1200px) {
    .modal-dialog {
      width: ${(props: Props) =>
        props.width ? (parseInt(props.width, 10) >= 1160 ? '1160px' : props.width) : '600px'};
    }
  }
  /* for ie11 */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .modal-dialog {
      overflow-y: auto;
      min-height: calc(100vh - 3.5rem);
    }
    .modal-content {
      overflow-y: auto;
    }
    .modal-body {
      overflow-x: hidden;
    }
  }
` as any;

export type Props = {
  width?: string;
};

export default register('rsv8-components/Modal')(withTheme())(Modal as ComponentType<ModalProps>);
