import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { register, withContent, withTheme } from 'xcel-react-core';
import { getPoints } from '../../redux/actions/metric';
import { numberFormat } from 'xcel-util';

interface PointsProps {
  dispatch: any,
  calculatorKey: string,
  periodStatus: any,
  programKey: string,
  EmptyHolder:string,
}

const Points: React.SFC<PointsProps> = ({dispatch, children, periodStatus, programKey, calculatorKey,EmptyHolder, ...rest}) => {
  var temp='-';
  if(EmptyHolder) {
    temp =EmptyHolder;
   }
   const [pointTest, setPointTest] = useState(temp);
  useEffect(() => {
    dispatch(
      getPoints(programKey, calculatorKey, periodStatus)
    ).then(result => {
      const total = result.data.find(dataItem => dataItem.id === "TOTAL");
      const formattedTotal = numberFormat(total.attributes.value);
      setPointTest(formattedTotal);

    }).catch(err => console.log("failed to fetch points for ", programKey, calculatorKey, periodStatus));
  }, []);

  return <div {...rest}>{pointTest}</div>;
};

const mapContentToProps = (getContent) => ({
  programKey: getContent('programKey', { type: 'none', label: 'Enter the Program Key' }),
  calculatorKey: getContent('calculatorKey', { type: 'none', label: 'Enter the Calculator Key' }),
  periodStatus: getContent('periodStatus', { type: 'none', label: 'Enter the Period Status' }),
  EmptyHolder: getContent('EmptyHolder', { type: 'none', label: 'Enter a number or letter to use if empty' })
});

export default register('rsv8-metrics/metricpoints')(
  connect(),
  withContent(mapContentToProps),
  withTheme()
)(Points);
export { Points };
