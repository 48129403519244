import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Icon, TableHeaderCell } from 'rsv8-components';
import { nomineeSort as nomineeSortBase } from '../../../redux/actions/index';
import { nomineeSearchSelectors } from '../../../redux/selectors/index';

const getSortIcon = (direction) => {
  switch (direction) {
    case 'Asc':
      return 'sort-up';
    case 'Desc':
      return 'sort-down';
    default:
      return 'sort';
  }
};

const SortIcon = ({ direction, ...rest }) => <Icon {...rest} icon={getSortIcon(direction)} />;

export interface HeaderCellProps {
  value: string;
  label: string;
}

interface InternalProps extends HeaderCellProps {
  currentSort: { field: string; direction: 'Asc' | 'Desc' };
  nomineeSort: Function;
}

const HeaderCell = ({ value, label, currentSort, nomineeSort }: InternalProps) => {
  const { field, direction } = currentSort;
  const status = field === value ? direction : 'unsorted';
  const callSort = (e) => {
    if (currentSort.field === value) {
      const newDirection = currentSort.direction === 'Asc' ? 'Desc' : 'Asc';
      nomineeSort(value, newDirection);
    } else {
      nomineeSort(value, 'Desc');
    }
  };
  return (
    <TableHeaderCell onClick={callSort}>
      {label} <SortIcon direction={status} />
    </TableHeaderCell>
  );
};

const mapState = (state) => ({ currentSort: nomineeSearchSelectors.getNomineeTableSort(state) || {} });

const mapDispatch = (dispatch) => ({ nomineeSort: bindActionCreators(nomineeSortBase, dispatch) });

export default compose<any>(
  connect(
    mapState,
    mapDispatch
  )
)(HeaderCell) as React.SFC<HeaderCellProps>;
