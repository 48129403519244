import * as events from '../events';
import { ProgramLevelProduct, ProgramLevel, ShowPreviewModel } from 'xcel-api-generator/dist/achievement';

interface State {
  product: ProgramLevelProduct
  order: any
  sharePreview: ShowPreviewModel
  programLevel: ProgramLevel
  isProductDetailsModalOpen: boolean
  isOrderDetailsModalOpen: boolean,
  windowScrollY: number
}

const initialState: State = {
  product: null,
  order: null,
  sharePreview: null,
  programLevel: null,
  isProductDetailsModalOpen: false,
  isOrderDetailsModalOpen: false,
  windowScrollY: -1
};

const achievementReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case events.ACHIEVEMENT_PRODUCT_DETAILS_TOGGLE_MODAL:
      return {
        ...state,
        isProductDetailsModalOpen: !state.isProductDetailsModalOpen,
        product: payload.product,
        programLevel: payload.programLevel,
        sharePreview: null,
        order: null,
        windowScrollY: window.scrollY
      };
    case events.ACHIEVEMENT_ORDER_DETAILS_TOGGLE_MODAL:
      return {
        ...state,
        isOrderDetailsModalOpen: !state.isOrderDetailsModalOpen,
        product: payload.product,
        programLevel: payload.programLevel,
        sharePreview: null,
        order: null,
        windowScrollY: window.scrollY
      };
    case events.SET_ORDER_INFO:
      return {
        ...state,
        order: payload
      };
    case events.SET_SHARE_PREVIEW:
      return {
        ...state,
        sharePreview: payload
      };
    default:
      return {
        ...state
      };
  }
};

export { achievementReducer };
