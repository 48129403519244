import {
  DASHBOARD_REDUCER_KEY
} from '../../constants'

const initialState = {
};

const dashboardReducer = (state: any = initialState, action: any) => {
 
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_REDUCER_KEY: {
      return {
        ...state,
       data: payload.data,
       included: payload.included
      };
    }
    default: 
    return {
      ...state
    };
  }
}
export {dashboardReducer};