import * as React from 'react';
import {
  BaseCollapse,
  HeaderOne,
  HorizontalRule,
  PanelBody,
  Text
  } from 'rsv8-components';
import styled from 'styled-components';
import { DivProps, register, withResource } from 'xcel-react-core';
import { withThemedStatus } from '../../NominationStatus';
import { NomineeImage } from '../../NomineeImage';
import { CircularContainer, StyledPanel } from '../Body/styles';
import { TeamIcon } from './index';
import TeamMemberPanel from './TeamMemberPanel';

export interface NomineePanelProps extends DivProps {
  nominee: { firstName: string; lastName: string; statusId: string };
  teamMembers?: Array<any>;
  viewLessLabel: string;
  viewMoreLabel: string;
}

export const NominationStatusPanel = register('rsv8-nomination/NominationStatusPanel')(withThemedStatus)(PanelBody);
const Head = styled.div`
  width: 100%;
`;

const StyledHeaderOne = styled(HeaderOne)`
  margin: 15px 0 20px 0 !important;
` as any;

const Tail = styled.div`
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: -30px;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  align-items: center;
  width: 100%;
` as any;

const Image = styled(NomineeImage)`
  padding: 0;
  display: block;

  > :first-child {
    margin: 0 auto;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // IE10+ CSS here
      margin: 0;
    }
  }
` as any;

const StatusPanel = styled(NominationStatusPanel)`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0;
` as any;

const PanelContainer = styled.div`
  position: relative;
  margin-top: 80px;
  width: 100%;
` as any;

class CollapsingNomineePanel extends React.Component<NomineePanelProps, {}> {
  renderHeader = ({ open, onClick }) => {
    return (
      <Head>
        <HorizontalRule />
        {!open && (
          <Tail onClick={onClick}>
            <Text themeVariation="primary-text">{this.props.viewMoreLabel}</Text>
          </Tail>
        )}
      </Head>
    );
  };

  renderTail = ({ open, onClick }) => {
    return (
      <Tail onClick={onClick}>
        <Text themeVariation="primary-text">{this.props.viewLessLabel}</Text>
      </Tail>
    );
  };

  render() {
    return (
      <StyledPanel>
        <StatusPanel>
          <CircularContainer>{''}</CircularContainer>
          {this.props.teamMembers ? (
            <ImageContainer>
              <TeamIcon style={{ paddingTop: '0' }}>
                <StyledHeaderOne>{`${this.props.nominee.firstName} ${this.props.nominee.lastName}`}</StyledHeaderOne>
              </TeamIcon>
            </ImageContainer>
          ) : (
            <ImageContainer>
              <Image
                onClick={this.props.onClick}
                separate={true}
                nominee={this.props.nominee}
                caption={true}
                orientation="right"
              />
            </ImageContainer>
          )}
          <PanelContainer>
            <BaseCollapse heading={this.renderHeader} tailComponent={this.renderTail} tailCollapses={true}>
              <PanelBody>{this.props.children}</PanelBody>
              <HorizontalRule />
            </BaseCollapse>
          </PanelContainer>
        </StatusPanel>
        {this.props.teamMembers &&
          this.props.teamMembers.map(
            (member) => member.id && <TeamMemberPanel member={member} status={member.statusId} />
          )}
      </StyledPanel>
    );
  }
}

const mapResource = (getResource) => ({
  viewMoreLabel: getResource('nominations.viewMoreLabel', 'View More'),
  viewLessLabel: getResource('nominations.viewLessLabel', 'View Less')
});

export default withResource(mapResource)(CollapsingNomineePanel);
