import * as React from 'react';
import { Button } from 'rsv8-components';
import ReactField from '../../Fields/ReactField';
import { withContent, withResource } from 'xcel-react-core';

export interface ApiFilterProps {
  fields: any;
  filters: any;
  onChange: any;

  clearFilters: any;
  filterLabels: any;
}

class ApiFilters extends React.Component<ApiFilterProps, any> {
  state = {};
  handleChange = (key) => (value) => {
    if (value === 'all') {
      value = undefined;
    }
    const state = { ...this.state, [key]: value };
    this.setState(state);
    if (this.props.onChange) {
      this.props.onChange(state);
    }
  };

  handleInputChange = (key) => {
    let debounceTimeoutId;

    return (value) => {
      const state = { ...this.state, [key]: value };
      this.setState(state);
      clearTimeout(debounceTimeoutId);
      debounceTimeoutId = setTimeout(() => {
        if (this.props.onChange) {
          this.props.onChange(state);
        }
      }, 1000);
    };
  };

  handleClear = () => {
    const state = this.props.fields.reduce((obj, key) => {
      obj[key.id || key] = undefined;
      return obj;
    }, {});
    this.setState(state);
    if (this.props.onChange) {
      this.props.onChange(state);
    }
  };

  renderCustomFilterField = (field) => {
    const key = field.id;
    return (
      <ReactField
        key={key}
        name={key}
        label={field.label}
        value={this.state[key] || ''}
        component="bs-input"
        onChange={this.handleInputChange(field.id)}
      />
    );
  };

  render() {
    const { fields, filters, clearFilters, filterLabels } = this.props;

    return (
      <React.Fragment>
        {fields.map((field) => {
          let label;
          let key = field;

          if (typeof field === 'object') {
            key = field.id;
            label = field.label;
          }
          const filter = filters && filters.filter((item) => item.id === key)[0];

          if (!filter) return this.renderCustomFilterField(field);

          filter.label = label ? label : filter.label;

          const options = [
            { value: `all`, label: `All` },
            ...filter.data.map((option) => ({ ...option, label: `${option.label} (${option.count})` }))
          ];

          return (
            <ReactField
              key={key}
              name={key}
              label={filterLabels[filter.label] || filter.label}
              value={this.state[key] === undefined ? 'all' : this.state[key]}
              component="bs-select"
              onChange={this.handleChange(key)}
              options={options}
            />
          );
        })}
        <Button themeVariation="primary-link" onClick={this.handleClear}>
          {clearFilters}
        </Button>
      </React.Fragment>
    );
  }
}

const mapResource = (getResource) => ({
  filterLabels: {
    nomination_award_type: getResource('apiFilters.nominationAwardType', 'Award Type'),
    nomination_status: getResource('apiFilters.nominationStatus', 'Status'),
    nomination_type: getResource('apiFilters.nominationType', 'Type'),
    nomination_winnerlist_city: getResource('apiFilters.nominationWinnerlistCity', 'City'),
    nomination_winnerlist_jobfunction: getResource('apiFilters.nominationWinnerlistJobfunction', 'Job'),
    nomination_winnerlist_nominationType: getResource('apiFilters.nominationWinnerlistNominationType', 'Type'),
    nomination_winnerlist_criteria: getResource('apiFilters.nominationWinnerlistCriteria', 'Criteria'),
    nomination_winnerlist_team: getResource('apiFilters.nominationWinnerlistTeam', 'Team')
  },
  clearFilters: getResource('apiFilters.clearFilters', 'Clear Filters')
});

export default (withContent(), withResource(mapResource))(ApiFilters);
