import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { userStateSelectors } from 'rsv8-auth';
import { HeaderTwo } from 'rsv8-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import { numberFormat } from 'xcel-util';
import { getCurrentAccountSummary } from '../../redux/selectors/accountSelectors';
import PointsBadge from '../Points/PointsShortHand';
import { BalanceWrapper, LineAmount, StyledCollapse } from './styles';

const buildCollapseTitle = (title, quantity) => {
  return (
    <Row>
      <Col xs={18}>{title}</Col>
      <LineAmount xs={6} className="text-right">
        {quantity}
      </LineAmount>
    </Row>
  );
};

const AccountBalanceBreakdown = (props) => {
  const {
    accountBreakdown,
    nonServiceAwardPointsEarned,
    serviceAwardPointsEarned,
    nonServiceAwardPointsRedeemed,
    serviceAwardPointsRedeemed,
    nonServiceAwardPointsAdjusted,
    serviceAwardPointsAdjusted,
    pointsInCartLabel,
    currentBalance,
    breakdownTitle,
    pointsEarnedTitle,
    pointsRedeemedTitle,
    pointsAdjustedTitle,
    nonTangiblePointsPointsInCart,
    tangiblePointsPointsInCart,
    nonTangiblePointsTotal,
    tangiblePointsTotal,
    className,
    grossUpPointsEarned,
    grossUpPointsRedeemed,
    grossUpPointsAdjusted,
    grossUpPointsInCart,
    grossUpPointsTotal
  } = props;
  const { hasNonTaxablePoints, hasGrossupTaxablePoints, credited, earned, redeemed, pointsInCart, available } =
    accountBreakdown || ({} as any);

  // Titles
  const pointsEarnedRow = buildCollapseTitle(pointsEarnedTitle, earned && numberFormat(earned.total));
  const pointsRedeemedRow = buildCollapseTitle(pointsRedeemedTitle, redeemed && numberFormat(redeemed.total));
  const pointsAdjustedRow = buildCollapseTitle(pointsAdjustedTitle, credited && numberFormat(credited.total));

  const pointsInCartRowTitle = hasNonTaxablePoints ? pointsInCartLabel + ' ' + breakdownTitle : pointsInCartLabel;
  const currentBalanceRowTitle = hasNonTaxablePoints ? currentBalance + ' ' + breakdownTitle : currentBalance;

  const pointsInCartRow = buildCollapseTitle(pointsInCartRowTitle, pointsInCart && numberFormat(pointsInCart.total));
  const currentBalanceRow = buildCollapseTitle(currentBalanceRowTitle, available && numberFormat(available.total));

  // Taxable Values
  const taxableEarnedValue = earned && earned.taxable;
  const taxableRedeemedValue = redeemed && redeemed.taxable;
  const taxableCreditedValue = credited && credited.taxable;
  const taxableCartBreakdownValue = pointsInCart && pointsInCart.taxable;
  const taxableBalanceBreakdownValue = available && available.taxable;

  // Non Taxable Values
  const nonTaxableEarnedValue = earned && earned.nonTaxable;
  const nonTaxableRedeemedValue = redeemed && redeemed.nonTaxable;
  const nonTaxableCreditedValue = credited && credited.nonTaxable;
  const nonTaxableCartBreakdownValue = pointsInCart && pointsInCart.nonTaxable;
  const nonTaxableBalanceBreakdownValue = available && available.nonTaxable;

  // Grossup Taxable Values
  const grossupTaxableEarnedValue = earned && earned.grossupTaxable;
  const grossupTaxableRedeemedValue = redeemed && redeemed.grossupTaxable;
  const grossupTaxableCreditedValue = credited && credited.grossupTaxable;
  const grossupTaxableCartBreakdownValue = pointsInCart && pointsInCart.grossupTaxable;
  const grossupTaxableBalanceBreakdownValue = available && available.grossupTaxable;

  const buildCollapseRow = (
    rowTitle,
    taxableTitle,
    taxableValue,
    nonTaxableTitle,
    nonTaxableValue,
    grossUpTitle,
    grossUpValue
  ) => {
    let bodyArray = [
      {
        title: taxableTitle,
        value: taxableValue
      }
    ];

    if (hasNonTaxablePoints) {
      bodyArray = [...bodyArray, { title: nonTaxableTitle, value: nonTaxableValue }];
    }

    if (hasGrossupTaxablePoints) {
      bodyArray = [...bodyArray, { title: grossUpTitle, value: grossUpValue }];
    }

    return bodyArray.length > 1 ? (
      <StyledCollapse themeVariation="secondary" title={rowTitle} disableAutoScroll={true}>
        {bodyArray.map((item, index) => {
          return (
            <Row key={index}>
              <Col xs={18}>{item.title} </Col>
              <LineAmount xs={6} className="text-right">
                {numberFormat(item.value)}
              </LineAmount>
            </Row>
          );
        })}
      </StyledCollapse>
    ) : (
      <div className="no-collapse">{rowTitle}</div>
    );
  };

  return (
    <Row className={className}>
      <Col md={10} className="text-center">
        <BalanceWrapper>
          <HeaderTwo>{currentBalance}</HeaderTwo>
          <HeaderTwo>
            <PointsBadge themeVariation="dark-text" />
          </HeaderTwo>
        </BalanceWrapper>
      </Col>

      <Col md={14}>
        {buildCollapseRow(
          pointsEarnedRow,
          nonServiceAwardPointsEarned,
          taxableEarnedValue,
          serviceAwardPointsEarned,
          nonTaxableEarnedValue,
          grossUpPointsEarned,
          grossupTaxableEarnedValue
        )}

        {buildCollapseRow(
          pointsRedeemedRow,
          nonServiceAwardPointsRedeemed,
          taxableRedeemedValue,
          serviceAwardPointsRedeemed,
          nonTaxableRedeemedValue,
          grossUpPointsRedeemed,
          grossupTaxableRedeemedValue
        )}

        {buildCollapseRow(
          pointsAdjustedRow,
          nonServiceAwardPointsAdjusted,
          taxableCreditedValue,
          serviceAwardPointsAdjusted,
          nonTaxableCreditedValue,
          grossUpPointsAdjusted,
          grossupTaxableCreditedValue
        )}

        {buildCollapseRow(
          pointsInCartRow,
          nonTangiblePointsPointsInCart,
          taxableCartBreakdownValue,
          tangiblePointsPointsInCart,
          nonTaxableCartBreakdownValue,
          grossUpPointsInCart,
          grossupTaxableCartBreakdownValue
        )}

        {buildCollapseRow(
          currentBalanceRow,
          nonTangiblePointsTotal,
          taxableBalanceBreakdownValue,
          tangiblePointsTotal,
          nonTaxableBalanceBreakdownValue,
          grossUpPointsTotal,
          grossupTaxableBalanceBreakdownValue
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  accountBreakdown: getCurrentAccountSummary(state),
  countryCode: userStateSelectors.getUser(state).countryCode
});

const mapResource = (getResource) => ({
  pointsEarnedTitle: getResource('account.pointsEarnedTitle', 'Points Earned'),
  pointsRedeemedTitle: getResource('account.pointsRedeemedTitle', 'Points Redeemed'),
  pointsAdjustedTitle: getResource('account.pointsAdjustedTitle', 'Points Adjusted'),
  pointsInCartLabel: getResource('account.pointsInCart', 'Points In Cart'),
  currentBalance: getResource('account.currentBalance', 'Current Balance'),
  breakdownTitle: getResource('account.breakdownTitle', 'Breakdown'),

  nonServiceAwardPointsEarned: getResource('account.menuLogoURI', 'Non-Service Award Points Earned'),
  serviceAwardPointsEarned: getResource('account.serviceAwardPointsEarned', 'Service Award Points Earned'),
  grossUpPointsEarned: getResource('account.grossUpPointsEarned', 'GrossUp Points Earned'),

  nonServiceAwardPointsRedeemed: getResource(
    'account.nonServiceAwardPointsRedeemed',
    'Non-Service Award Points Redeemed'
  ),
  serviceAwardPointsRedeemed: getResource('account.serviceAwardPointsRedeemed', 'Service Award Points Redeemed'),
  grossUpPointsRedeemed: getResource('account.grossUpPointsRedeemed', 'GrossUp Points Redeemed'),

  nonServiceAwardPointsAdjusted: getResource(
    'account.nonServiceAwardPointsAdjusted',
    'Non-Service Award Points Adjusted'
  ),
  serviceAwardPointsAdjusted: getResource('account.serviceAwardPointsAdjusted', 'Service Award Points Adjusted'),
  grossUpPointsAdjusted: getResource('account.grossUpPointsAdjusted', 'GrossUp Points Adjusted'),

  nonTangiblePointsPointsInCart: getResource('account.nonTangiblePointsPointsInCart', 'Non-Service Points In Cart'),
  tangiblePointsPointsInCart: getResource('account.tangiblePointsPointsInCart', 'Service Points In Cart'),
  grossUpPointsInCart: getResource('account.grossUpPointsInCart', 'GrossUp Points In Cart'),

  nonTangiblePointsTotal: getResource('account.nonTangiblePointsTotal', 'Non-Service Points'),
  tangiblePointsTotal: getResource('account.tangiblePointsTotal', 'Service Points'),
  grossUpPointsTotal: getResource('account.grossUpPointsTotal', 'GrossUp Points')
});

export default register('rsv8-account/AccountBalanceBreakdown')(
  connect(mapStateToProps),
  withTheme(['.no-collapse']),

  withResource(mapResource),
  withContent()
)(AccountBalanceBreakdown);
