import * as React from 'react';
import { connect } from 'react-redux';
import { userStateSelectors } from 'rsv8-auth';
import { authGetCode } from '../../redux/auth.action';

const CodeLink  = ({ dispatch, user, children, ...props }) => {

  const handleClick = async (e) => {
    e.preventDefault();

    let redirectUri = props.href.trim();
    let response = await dispatch(authGetCode(user, redirectUri));

    const dynamicLink = document.createElement('a');
    dynamicLink.href = response.location; 

    document.body.appendChild(dynamicLink);

    dynamicLink.click();
  };

  return (
    <li className={props.className}>
      <a href={props.href} onClick={handleClick} onTouchStart={handleClick}>
        {children}
      </a>
    </li>
  );
};

const mapStateToProps = (state) => ({
  user: userStateSelectors.getUser(state)
});
export default connect(mapStateToProps)(CodeLink) as any;
