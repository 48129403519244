import * as d3 from 'd3';
import * as React from 'react';

interface Props {
  id: any;
  percentage: any;
}

export const RadialGauge: React.FunctionComponent<Props> = ({ id, percentage }) => {
  React.useEffect(() => {
    const tau = 2 * Math.PI;

    const arc = d3
      .arc()
      .innerRadius(70)
      .outerRadius(80)
      .startAngle(0);

    let container = d3.select(`#${id}`);
    let svg = container
      .append('svg')
      .attr('width', 160)
      .attr('height', 160);
    const width = +container.attr('width');
    const height = +container.attr('height');

    let g = svg.append('g').attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    g.append('circle')
      .attr('r', '70')
      .attr('cx', '0')
      .attr('cy', '0')
      .style('fill', '#cbe3e9');
    let text = g
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('y', 18)
      .attr('fill', '#0b2265')
      .text(`${percentage}%`);
    text
      .style('font-weight', '300')
      .style('letter-spacing', '-3px')
      .style('font-size', '58px');

    let background = g
      .append('path')
      .datum({ endAngle: tau })
      .attr('d', arc);
    background.style('fill', 'rgba(238, 238, 238, .65)');

    let foreground = g
      .append('path')
      .datum({ endAngle: 0 * tau })
      .style('fill', '#158ac2')
      .attr('d', arc);

    // @ts-ignore
    let interval = d3.interval(function() {
      foreground
        .transition()
        .duration(500)
        .attrTween('d', arcTween(((percentage > 100 ? 100 : percentage) / 100) * tau));
    }, 500);

    function arcTween(newAngle) {
      return function(d) {
        var interpolate = d3.interpolate(d.endAngle, newAngle);
        return function(t) {
          d.endAngle = interpolate(t);
          return arc(d);
        };
      };
    }
  }, []);
  return <svg id={id} width="160" height="160" />;
};
