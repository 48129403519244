import * as React from 'react';
import { HeaderFour } from 'rsv8-components';

const Header = (props) => (
  <React.Fragment>
    <HeaderFour>
      {props.text}
      {props.children ? ' ' : null}
      {props.children ? <small>{props.children}</small> : null}
    </HeaderFour>
    <hr />
  </React.Fragment>
);

export default Header;
