import { promiseDispatcher } from 'xcel-react-core';
import { createRequestActions } from 'xcel-redux-orm';
import { ensureArray } from 'xcel-util';
import { nominationApi } from '../../../../api/index';
import { ADD_TEAM_MEMBER_ENDPOINT_NAME } from '../../../../constants';
import * as events from '../../../events';
import { getCurrentFieldsByTeamFields } from '../../../selectors/team';

const addTeamMemberActions = createRequestActions(ADD_TEAM_MEMBER_ENDPOINT_NAME);

const transformTeamMember = (state, val) => {
  const customFields =
    val.customFields && val.customFields.length
      ? val.customFields.map((cf) => ({ id: cf.id, values: ensureArray(cf.values) }))
      : getCurrentFieldsByTeamFields(state);

  return {
    ...val,
    customFields
  };
};

const addTeamMemberBase = ({ nominationId, userIds }: { nominationId: string; userIds: string | string[] }) => {
  const teamMembers = ensureArray(userIds).map((id) => ({ userId: id }));
  return nominationApi.createTeamDraftByIdMembers({ id: nominationId, model: teamMembers } as any, { jsonApi: true });
};

export const addTeamMemberRequest = promiseDispatcher(addTeamMemberBase, addTeamMemberActions);

const addTeamMemberResults = (response, { nominationId, userIds }, state) => {
  return {
    type: events.ADD_TEAM_MEMBERS,
    payload: {
      ids: ensureArray(userIds),
      teamMembers: response.map(transformTeamMember.bind(null, state))
    }
  };
};

export const addTeamMember = ({ nominationId, userIds }) => async (dispatch, getState) => {
  try {
    const response = await dispatch(addTeamMemberRequest({ nominationId, userIds }));
    dispatch(addTeamMemberResults(response, { nominationId, userIds }, getState()));
    return response;
  } catch (e) {
    throw e;
  }
};
