import * as events from './events';

const componentReducer = (state = {}, action) => {
  switch (action.type) {
    case events.COMPONENT_STYLE_SELECT: {
      return { ...state, selectedStyle: action.name };
    }
    default:
      return state;
  }
};
export default componentReducer;
