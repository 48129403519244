import * as d3 from 'd3';
import * as React from 'react';

interface Props {
  id: any;
  colorRange: any;
  legendVals: any;
}

export const Legend: React.FunctionComponent<Props> = ({ id, colorRange, legendVals }) => {
  React.useEffect(() => {
    const svg = d3
      .select(`#${id}`)
      .append('svg')
      .style('font-family', 'SimpleSans')
      .attr('width', 250)
      .attr('height', 225)
      .append('g');

    svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', 'rgba(0,0,0,0)');

    var legend = svg.append('g').attr('class', 'legend');

    legend
      .selectAll('rect')
      .data(legendVals)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', function(d, i) {
        return i * 40;
      })
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', function(d, i) {
        return colorRange[i];
      });

    legend
      .selectAll('text')
      .data(legendVals)
      .enter()
      .append('text')
      .text(function(d) {
        return d;
      })
      .attr('x', 38)
      .attr('y', function(d, i) {
        return i * 40 + 14;
      })
      .attr('text-anchor', 'start')
      .style('fill', '#0b2265')
      .style('font-size', '14px');
  }, []);
  return <span id={id} />;
};
