import * as React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { register, withResource } from 'xcel-react-core';
import { HeaderOne, Text, Link } from "rsv8-components";
import { PaddedRow, CentredContainer, BigButton } from "./Elements";

const TRADED_AWARD_DESCRIPTION_TEXT = 'Thank you for trading in your digital gift card for credits. Check your email inbox for a\n' +
    'confirmation for the trade. The credits have been automatically debited to your account\n' +
    'and those credits are available to use instantly. You may redeem them for any digital or\n' +
    'physical gift card of your choice in the Reward Catalog.\n' +
    'Contact support if you have any questions.';

interface InternalProps {
    resource: {
        tradedAwardViaEmailHeader: string;
        chooseGiftCardButtonLabelText: string;
        tradedAwardViaEmailDescriptionText: string;
    };
}

const TradedAwardForm: React.SFC<InternalProps> = (props) => {

    return (
        <React.Fragment>
            <PaddedRow>
                <Col>
                    <CentredContainer>
                        <HeaderOne themeVariation="primary-link-3xl-bold-text">
                            {props.resource.tradedAwardViaEmailHeader}
                        </HeaderOne>
                    </CentredContainer>
                </Col>
            </PaddedRow>

            <PaddedRow>
                <Col mdOffset={3} lgOffset={4} xs={24} md={18} lg={16}>
                    <Text themeVariation="center">{props.resource.tradedAwardViaEmailDescriptionText}</Text>
                </Col>
            </PaddedRow>

            <PaddedRow>
                <CentredContainer>
                    <Link to={'/catalog'}>
                        <BigButton>{props.resource.chooseGiftCardButtonLabelText}</BigButton>
                    </Link>
                </CentredContainer>
            </PaddedRow>
        </React.Fragment>
    );
};

const mapResource = (getResource) => ({
    resource: {
        tradedAwardViaEmailHeader: getResource('nominations.tradedAwardViaEmailHeader', '{userName}, we’ve added those credits to your account balance.'),
        tradedAwardViaEmailDescriptionText: getResource('nominations.tradedAwardViaEmailDescriptionText', TRADED_AWARD_DESCRIPTION_TEXT),
        chooseGiftCardButtonLabelText: getResource('nominations.chooseGiftCardButtonLabelText', 'Choose gift Card'),
    }
});

export default register('rsv8-nomination/TradedAwardForm')(
    connect(
        null
    ),
    withResource(mapResource),
)(TradedAwardForm);
