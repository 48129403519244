import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HeaderFour, HeaderTwo } from 'rsv8-components';
import { Organization, RebateTimePeriod } from 'xcel-api-generator/dist/alcon';
import { assetService } from 'xcel-asset-service';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { getSummary } from '../../redux/actions';
import { currentTimePeriodSelector, organizationSelector } from '../../redux/selectors';
import NoDataSpinner from '../NoDataSpinner';

const ProgramHeader: React.FC<Props> = ({ className, logo = 'logos/alcon-logo.png', planName, organization, currentTimePeriod }) => {
  if (!organization || !currentTimePeriod) return <NoDataSpinner />;

  return (
    <div className={className}>
      <Row>
        <Col xs={24} sm={6} className="program-header-logo">
          <img src={assetService.getImage(logo)} alt="Program Logo" />
        </Col>

        <Col xs={24} sm={12} className="organization-name">
          <HeaderTwo themeVariation="my-dashboard">{organization.name}</HeaderTwo>
        </Col>

        <Col xs={24} sm={6}>
          <HeaderFour
            themeVariation="my-dashboard-plan"
            dangerouslySetInnerHTML={{
              __html: planName + ' ' + currentTimePeriod.description,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  logo: getContent('logo', { type: 'string', label: 'Logo' }),
  planName: getContent('planName', { type: 'string', label: 'Plan Name' }),
});

const mapStateToProps = (state) => ({
  organization: organizationSelector.selectMany(state)[0],
  currentTimePeriod: currentTimePeriodSelector(state),
});

interface StateProps {
  organization: Organization;
  currentTimePeriod: RebateTimePeriod;
}
interface ContentProps {
  logo: string;
  planName: string;
}
type Props = StateProps & ContentProps & React.HtmlHTMLAttributes<any>;

export default register('rsv8-alcon/ProgramHeader')(
  connect(mapStateToProps),
  withApiDataProvider(getSummary, organizationSelector),
  withTheme(),
  withContent(mapContentToProps)
)(ProgramHeader);
