import { AnimatedCircle } from 'rsv8-components';
import styled, { css } from 'styled-components';

export const FIRST_COLUMN_WIDTH = `18px`;

export const NomineeLoader = styled(AnimatedCircle)`
  margin-left: 0px;
`;

const loadingStyles = css`
  background-color: #f1f1f1 !important;
  &:hover {
    background-color: #f1f1f1 !important;
  }
`;

export const LoadingContainer = styled.div`
  ${(props: { loading: boolean }) => (props.loading ? loadingStyles : '')};
`;
