import { Row } from 'react-bootstrap';
import { Button, Separator, Text, Loader } from 'rsv8-components';
import styled from 'styled-components';

export const OrderDetailsContainer = styled(Row)`
  padding: 2rem 0;
  span {
    display: block;
  }
  img {
    width: 90%;
  }
`;
export const ModalDetailsButton = styled(Button)`
  margin: 2rem 0;
`;
export const ModalDetailsRow = styled(Row)`
  padding: 1rem 7rem;
  p {
    margin: 3rem 0;
  }
  @media screen and (max-width: 992px) {
    padding: 1rem 2rem 3rem;
  }
`;
export const OrderDetailsLabel = styled(Text)`
  display: block;
  margin: 1rem 0 .5rem;
` as any;
export const ProductDetailsImage = styled.img`
  height: auto;
  width: 100%;
`;
export const ProductShareRedemptionImage = styled.img`
  height: 280px;
  width: 100%;
  object-fit: contain;
`;
export const ModalText = styled(Text)`
  display: block;
`;
export const ModalSocialSuccessText = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
`;
export const ModalSeparator = styled(Separator)`
  width: 100%;
  margin-top: 4rem;
  border-color: #000;
`;
export const ButtonLoader = styled(Loader)`
  padding: 2px;
`;
export const ProductSharePreviewContainer = styled.div`
  position: relative;
  margin-bottom: 2.5rem;
`;
export const ProductSharePreviewHtml = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  footer {
    width: auto;
    height: 80px;
    p {
      font-size: 11px;
    }
  }
`;
