import styled from "styled-components";
import Image from "../../../components/Image";

export const IconWrapper = styled.span`
  cursor: pointer;
`;

export const TooltipContent = styled.div`
  margin: 10px 0;
`;

export const StyledTooltip = styled.div`
  .icon-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
  }
  .closebtn {
    position: absolute;
    top: 5px;
    right: 7px;
    font-size: 14px;
    color: #333333;
    margin-left: 50px;
    text-decoration: none;
  }
  .tooltip-inner {
    background-color: #fff;
    color: #333333;
    font-size: 14px;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }
`;

export const IconImage = styled(Image)`
  width: 15px;
  height: 15px;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
`;
