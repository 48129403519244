import styled from 'styled-components';
const ColorPickerInputContainer = styled.div`
  width: ${(props: any) => props.width || '1.9rem;'};
  height: ${(props: any) => props.height || '1.9rem;'};
  display: inline-block;
  padding: 0;
  cursor: pointer;
  border: 0.5px solid #c9c9c9;
  background-color: ${(props: any) => props.color};
  label {
    display: block;
  }
  .title {
    display: block;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    color: ${(props: any) => props.contrast};
  }
  .text,
  .color {
    display: block;
    font-family: Helvetica;
    font-size: 11px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props: any) => props.contrast};
  }
  .color {
    text-transform: uppercase;
  }
  padding: 10px;
` as any;
export default ColorPickerInputContainer;
