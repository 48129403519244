import styled from "styled-components";
import {AnimatedCircle} from "rsv8-components";
import {Button, Row} from "react-bootstrap";

export const Spinner = styled(AnimatedCircle)`
  margin-top: 12px;
` as any;

export const PaddedRow = styled(Row)`
  padding-bottom: 20px;
`;

export const CentredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
` as any;

export const ButtonGroup = styled(CentredContainer)`
  margin-top: 20px;
` as any;

export const BigButton = styled(Button)`
    padding: 10px 45px;
    min-width: 200px;
` as any;

export const RedeemButton = styled(BigButton)`
  margin-right: 40px;
` as any;