import { mapJsonApiActions } from 'xcel-redux-orm';
import { metricApi } from '../../api';

const metricApiActions = mapJsonApiActions(metricApi);

const getPoints = (programKey: string, calculatorKey: string, periodStatus: any) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      metricApiActions.createCalculatorMetricsCalculateByCalculatorKey(
        {
          programKey,
          calculatorKey,
          body: periodStatus,
        },
        { jsonApi: true }
      )
    );

    return response;
  } catch (e) {
    throw e
  }
};

const getCalculatedGoals = (programKey: string, calculatorKey: string, periodStatus: any) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      metricApiActions.createCalculatorGoalsCalculateByCalculatorKey(
        {
          programKey,
          calculatorKey,
          body: periodStatus,
        },
        { jsonApi: true }
      )
    );

    return response;
  } catch (e) {
    throw e
  }
};

export { getPoints, getCalculatedGoals };
