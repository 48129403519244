import { Link } from 'rsv8-components';
import styled from 'styled-components';

export const BlockLink = styled(Link)`
  display: block;
`;

export const SlideItem = styled.div`
  width: 330px;
  position: relative;

  @media (max-width: 1368px) {
    width: 250px;
  }

  @media (max-width: 968px) {
    width: 100%;
  }
`;

export const ProductImage = styled.img`
  display: block;
  width: 100%;
`;

export const ShareableButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -16px;
`;
