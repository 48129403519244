import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { HeaderTwo, HeadingSpacer } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import RecognitionButton from './RecognitionButton';

export interface RecognizeAColleagueProps {
  showTeamButton?: any;
}

interface InternalProps extends RecognizeAColleagueProps {
  resource: {
    headerText: string;
    individualButtonText: string;
    individualImagePath: string;
    teamButtonText: string;
    teamImagePath: string;
  };
  onTeam: any;
  onIndividual: any;
}

const RecognizeAColleague: React.SFC<InternalProps> = ({ showTeamButton, resource, onIndividual, onTeam }) => {
  return (
    <Row>
      <Col xs={24}>
        <Row>
          <Col xs={24}>
            <HeadingSpacer>
              <HeaderTwo textalign="center">{resource.headerText}</HeaderTwo>
            </HeadingSpacer>
          </Col>
        </Row>
        <Row>
          <Col xs={22} xsOffset={1} sm={showTeamButton ? 10 : 22} smOffset={1}>
            <RecognitionButton onClick={onIndividual} imageURI={resource.individualImagePath}>
              {resource.individualButtonText}
            </RecognitionButton>
          </Col>
          {showTeamButton && (
            <Col xs={22} xsOffset={1} sm={10} smOffset={2}>
              <RecognitionButton onClick={onTeam} imageURI={resource.teamImagePath}>
                {resource.teamButtonText}
              </RecognitionButton>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch) => bindActionCreators({ push }, dispatch);

const mapHandlers = {
  onTeam: (props) => (event) => props.push('/nominations/team'),
  onIndividual: (props) => (event) => props.push('/nominations/individual')
};

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource('recognize.recognizeAColleagueLabelText', 'Recognize a Colleague'),
    individualButtonText: getResource('recognize.recognizeAnIndividualButtonText', 'Recognize an Individual'),
    individualImagePath: getResource('recognize.recognizeAnIndividualImagePath', '/clientassets/images/individual.svg'),
    teamButtonText: getResource('recognize.recognizeATeamButtonText', 'Recognize a Team'),
    teamImagePath: getResource('recognize.recognizeATeamImagePath', '/clientassets/images/group.svg')
  }
});

export default register('rsv8-nomination/RecognitionButtons')(
  withResource(mapResourceToProps),
  connect(
    null,
    mapDispatch
  ),
  withHandlers(mapHandlers)
)(RecognizeAColleague) as React.ComponentType<RecognizeAColleagueProps>;
