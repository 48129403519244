import { truncate } from 'lodash';
import * as React from 'react';
import { ProductModel } from 'xcel-api-generator/dist/catalog';
import { numberFormat } from 'xcel-util';
import {
  Container,
  Content,
  Description,
  Image,
  ImageContainer,
  Name,
  RedeemButton,
  Title,
  Value
  } from './styles';

interface Props {
  product: ProductModel;
  title: string;
  buttonText: string;
  currencyText: string;
}
export const Card: React.FC<Props> = ({ product, title, buttonText, currencyText }) => {
  return (
    <Container>
      <ImageContainer>
        <Image src={product.imageLg} />
        {/* <Image src="https://picsum.photos/425/507" /> */}
      </ImageContainer>
      <Content>
        <Title>{title}</Title>
        <Name>{product.name}</Name>
        <Description>
          {truncate(product.description, {
            length: 150,
            separator: ' '
          })}
        </Description>
        <Value>{`${numberFormat(product.pointValue)} ${currencyText}`}</Value>
        <RedeemButton themeVariation="secondary-button" to={`/catalog/product/${encodeURI(product.id)}`}>
          {buttonText}
        </RedeemButton>
      </Content>
    </Container>
  );
};
