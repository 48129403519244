import { ROOT_STATE } from '../../../constants';
import { FAVORITE_PRODUCTS_LOADED, SET_FAVORITES } from '../events';

const initialState = {
  currentFavorites: [],
  favoritesLoaded: false
};

const favoritesReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FAVORITES:
      return {
        ...state,
        currentFavorites: payload.products.map((result) => result.id)
      };
    case FAVORITE_PRODUCTS_LOADED:
      return {
        ...state,
        favoritesLoaded: payload
      };
    default:
      return {
        ...state
      };
  }
};
const reducers = { [ROOT_STATE]: favoritesReducer };

export { reducers };
