import { push } from 'connected-react-router';
import { stringify } from 'query-string';
import { omit } from 'ramda';
import {
  assoc,
  curry,
  keys,
  reduce
  } from 'ramda';
import { UserCustomFieldModel } from 'xcel-api-generator/dist/sales';
import { mapJsonApiActions } from 'xcel-redux-orm';
import { salesApi } from '../../../api';
const salesApiActions = mapJsonApiActions(salesApi);

const setSalesApp = (appKey: { appKey: string }) => (dispatch) =>
  dispatch(
    push({
      pathname: '/sales/home',
      search: stringify(appKey)
    })
  );

const activateModal = (id: string, queryStringObject: { [index: string]: any }) => (dispatch) =>
  dispatch(
    push({
      hash: 'sales-activity-modal',
      search: stringify({ ...queryStringObject, entryKey: id })
    })
  );

const hideSaleDetailsModal = (queryStringObject: { [index: string]: any }) => (dispatch) =>
  dispatch(
    push({
      search: stringify(omit(['entryKey'], queryStringObject))
    })
  );

const createEntryRedirect = (qs) => (dispatch) =>
  dispatch(
    push({
      pathname: '/sales/create',
      search: stringify(qs)
    })
  );

const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

const createSalesEntry = (qs, values) => async (dispatch, getState) => {
  let customFieldValues = values.SalesEntry;
  let customFieldValuesArray = [];
  const fieldsState = getState().customfield.field;

  Object.keys(values.SalesEntry).map((key) => {
    if (fieldsState.hasOwnProperty(key) && key.toString() !== fieldsState[key].id) {
      customFieldValues = renameKeys({ [key]: `${key.substr(1)}` })(customFieldValues);
    }
  });
  Object.keys(customFieldValues).forEach((key) => {
    let field: UserCustomFieldModel = { id: null, values: [] };
    field.id = key;
    if (!Array.isArray(customFieldValues[key])) {
      field.values = [customFieldValues[key]];
    } else {
      field.values = customFieldValues[key];
    }

    // field[key] = customFieldValues[key];
    customFieldValuesArray.push(field);
  });

  try {
    await dispatch(
      salesApiActions.createApplicationByAppKeyEntry({
        appKey: qs.appKey,
        salesEntrySubmission: { productId: 'Reward 1', quantity: values.quantity, customFields: customFieldValuesArray }
      } as any)
    );
    dispatch(
      push({
        pathname: '/sales/home'
      })
    );
  } catch (e) {
    console.error(e);
  }
};

export const salesActions = {
  ...mapJsonApiActions(salesApi),
  setSalesApp,
  activateModal,
  hideSaleDetailsModal,
  createEntryRedirect,
  createSalesEntry
};
