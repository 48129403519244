import * as events from './events';
const resourceReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case events.PLUGIN_UPDATED: {
      if (payload.type !== 'Resource') {
        return state;
      }
      return {
        data: payload.data,
        cacheDate: payload.cacheDate
      }
    }
    default: {
      return state;
    }
  }
};
export default resourceReducer;
