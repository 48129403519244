import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SolidGauge from 'highcharts/modules/solid-gauge';

SolidGauge(Highcharts);

interface Props extends React.HTMLProps<any> {
  loading: boolean;
  value: number;
  label: string;
  emptyValue: string;
  formatOptions: Object;
  chartWidgetSize?: number;
  gaugeColor?: string;
  completedGaugeColor?: string;
  labelColor?: string;
}

const UserMetricChart: React.FC<Props> = ({
  className,
  loading,
  value,
  label,
  emptyValue = '-',
  formatOptions = {},
  chartWidgetSize = 360,
  gaugeColor = '#FAFAFA',
  completedGaugeColor = '#00AEEF',
}) => {
  const innerRadius = '100%';
  const outerRadius = '112%';

  const valueStr = React.useMemo(() => {
    return value != null ? new Intl.NumberFormat('en-US', formatOptions).format(value) : emptyValue;
  }, [value]);

  const dataLabel = React.useMemo(
    () => `
  <div style="text-align:center; color:#003595; font-family: open-sans;">
    <div style="font-size:55px; line-height:75px; font-weight: 700;">${valueStr}</div>
    <div style="font-size:20px; line-height:27px;">— <span style="color:#00AEEF;">${label}</span> —</div>
  </div>
  `,
    [valueStr, label]
  );

  const options = {
    chart: {
      type: 'solidgauge',
      height: '100%',
    },
    title: null,
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          backgroundColor: 'transparent',
          innerRadius: '88%',
          outerRadius: '100%',
          borderWidth: 0,
          shape: 'arc',
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -chartWidgetSize / 8,
          borderRadius: 0,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    yAxis: [
      {
        min: 0,
        max: value || 1,
        tickPosition: 'outside',
        lineColor: 'white',
        lineWidth: 0,
        minorTickPosition: 'outside',
        tickColor: 'transparent',
        minorTickColor: 'transparent',
        tickLength: 0,
        minorTickLength: 5,
        y: '1px',
        labels: {
          distance: 12,
          rotation: 'auto',
          enabled: false,
        },
        offset: 20,
        endOnTick: false,
      },
    ],
    credits: {
      enabled: false,
    },
    series: [
      {
        data: [
          {
            color: gaugeColor,
            radius: outerRadius,
            innerRadius: innerRadius,
            zIndex: 20,
            y: value || 1,
          },
        ],
        dataLabels: {
          formatter: () => dataLabel,
        },
      },
      {
        data: [
          {
            color: completedGaugeColor,
            radius: outerRadius,
            innerRadius: innerRadius,
            y: value || 1,
          },
        ],
        dataLabels: {
          useHtml: false,
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className={className} style={{ width: chartWidgetSize, height: chartWidgetSize }}>
      {loading && <span className="fa fa-circle-o-notch fa-spin" />}
      {!loading && <HighchartsReact highcharts={Highcharts} options={options} />}
    </div>
  );
};

export default UserMetricChart;
