import { ensureArray } from 'xcel-util';
import { CURRENT_NOMINATION_KEY, INDIVIDUAL_NOMINATION_REDUCER_KEY } from '../../constants';
import { ReduxState as State } from '../../types';
import { individualNominationSelectors } from './index';
import {
  awardSelectors,
  getAwardsWithName as getAwardsBase,
  getProductsWithName as getProductsBase,
  nomineeSelectors,
  productSelectors
  } from './nomination';
import { transformAwardToOptions, transformProductToOptions } from './team';
import { getPointRange } from './util';

export const getCurrentNomination = (state: State) => state[INDIVIDUAL_NOMINATION_REDUCER_KEY][CURRENT_NOMINATION_KEY];

export const getCurrentNomineeIds = (state: State): string[] => Object.keys(getCurrentNomination(state));

export const getCurrentAwardId = (state: State): string => getCurrentNomination(state).award;

export const getCurrentProductId = (state: State): string => getCurrentNomination(state).product;

export const getCurrentOptionPointValue = (state: State): number => getCurrentNomination(state).optionPointValue;

export const getCurrentOptionId = (state: State): string => getCurrentNomination(state).optionId;

export const getCurrentIncentive = (state: State): any => {
  const incentivePayload = {
    pointValue: getCurrentOptionPointValue(state),
    product: {
      id: getCurrentProductId(state),
      optionId: getCurrentOptionId(state)
    }
  };
  return incentivePayload;
};

export const getCurrentNomineeId = (state: State): string => (getCurrentNomination(state).nominee || '').toString();

export const getCurrentNominationId = (state: State): number => getCurrentNomination(state).id;

export const getCurrentCustomFields = (state: State): Array<{ id: string; values: any[] }> =>
  getCurrentNomination(state).customFields;

export const getCurrentAward = (state: State) => awardSelectors.selectOne(state, getCurrentAwardId(state));

export const getCurrentProduct = (state: State) => productSelectors.selectOne(state, getCurrentProductId(state));

export const getProductInfoFromDraft = (state: State) => {
  const incentive = getCurrentNomination(state).incentive;
  if (incentive) {
    return {
      product: incentive.product,
      productId: incentive.product ? incentive.product.id : null,
      optionId: incentive.product && incentive.product.attributes ? incentive.product.attributes.optionId : null
    };
  }
  return null;
};

export const getCurrentProductPointOptions = (state: State) => {
  var options = transformProductToOptions(getCurrentProduct(state));
  return options;
};
export const getCurrentAwardPointOptions = (state: State) => {
  const award = getCurrentAward(state);
  let points = transformAwardToOptions(award);
  if (points && points.length === 0) {
    // check for point range
    points = getPointRange(award);
  }
  return points;
};

export const getCurrentNominationPoints = (state: State): number => getCurrentNomination(state).points;

export const getCurrentNominee = (state: State) => nomineeSelectors.selectOne(state, getCurrentNomineeId(state));

export const getCurrentCustomFieldArray = (state: State) => getCurrentNomination(state).customFields;

export const hasCustomFieldsOrNominees = (state: State) =>
  getCurrentCustomFieldArray(state).length !== 0 || getCurrentNomineeIds(state).length !== 0;

export const getCurrentCustomFieldMap = (state: State): object =>
  getCurrentCustomFieldArray(state).reduce((acc, val) => ({ ...acc, [val.id]: val.values }), {});

export const getAwards = (state: State) => {
  let awards = getAwardsBase(state);
  return awards && awards.length && ensureArray(awards.filter((award) => award.allowIndividualNominations));
};

export const getProducts = getProductsBase;

export const hasAwardOrCustomFields = (state: State) => {
  return getCurrentAwardId(state) !== null;
};

export const hasProductOrCustomFields = (state: State) => {
  return getCurrentProductId(state) !== null;
};

export const getSaveNominationRequest = (state: State) => {
  let incentive = getCurrentIncentive(state);
  if (!incentive || !incentive.product || !incentive.product.id) {
    const info = getProductInfoFromDraft(state);
    let products = individualNominationSelectors.getProducts(state);
    products = !Array.isArray(products) ? [products] : products;
    const product = products.find((item) => item.id === info.productId || item.name === info.productId);
    if (product) {
      incentive = {
        product: {
          id: product.id,
          optionId: info.optionId
        }
      };
    }
  }
  return {
    id: undefined,
    nominationApplicationKey: getCurrentAwardId(state),
    managementCodeId: null,
    nominationDetailId: getCurrentNominationId(state),
    customFields: getCurrentCustomFields(state).map((field) => ({
      ...field,
      values: ensureArray(field.values)
    })),
    nomineeId: parseInt(getCurrentNomineeId(state), 10),
    incentiveValue: getCurrentNominationPoints(state),
    incentive: incentive,
    scheduledDate: null,
    checkInfo: null
  };
};

export const getClearCurrentNominationData = (state: State): boolean =>
  getCurrentNomination(state).clearCurrentNominationData;
