import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { componentStyleSelect, componentVariationSelect } from '../../../redux/component.actions';
import { mapDispatchToActions } from '../../../util';
const ComponentLink = ({ name, actions, match, ...rest }) => {
  const handleClick = (componentName: string) => (e) => {
    actions.componentStyleSelect({ name: componentName });
    actions.componentVariationSelect('default');
  };
  return <Link {...rest} to={`/theme/component/${name}`} onClick={handleClick(name)} />;
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = mapDispatchToActions({ componentStyleSelect, componentVariationSelect });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentLink) as any;
