import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageRenderer } from 'rsv8-cms';
import { toast } from 'rsv8-components';
import { register, withResource } from 'xcel-react-core';
import { IndividualReviewInstructions as ReviewInstructions, NarrowGrid as Grid, NominationSummary } from '../../components/index';
import { individualNominationSelectors } from '../../redux';
import { individualNominationActions } from '../../redux/actions/index';
import { Resources } from '../../types';

const { submit: submitNomination } = individualNominationActions;

export interface ConfirmNominationProps {
  wizard: any;
  award: Resources.AwardAttributes;
  submitNomination: Function;
}

class ConfirmNomination extends React.Component<ConfirmNominationProps, any> {
  componentWillMount() {
    if (this.props.wizard) {
      this.props.wizard.registerIncrement(this.save);
    }
  }
  save = () => {
    if (!this.props.award.isEligible) {
      toast({
        template: 'ContentToast',
        contentTemplate: (
          <PageRenderer
            path={'toast/nomination/individual/threshold'}
            onNotFound={() => <PageRenderer path={`toast/nomination/individual/threshold`} cultureId="0" />}
          />
        )
      });
      return Promise.reject(new Error('Nominee is no longer eligible'));
    }
    return new Promise<void>((resolve, reject) => {
      this.props
        .submitNomination()
        .then((v) => resolve())
        .catch((e) => {
          const error = e && e.response && e.response.data && e.response.data.errors && e.response.data.errors[0].title;
          const path =
            error === 'nomination_point_exceeds_nominator_threshold' ? 'nominator-threshold' : 'generic-error';

          toast({
            template: 'ContentToast',
            contentTemplate: (
              <PageRenderer
                path={`toast/nomination/individual/${path}`}
                onNotFound={() => <PageRenderer path={`toast/nomination/individual/${path}`} cultureId="0" />}
                error={error}
              />
            )
          });
        });
    });
  };

  render() {
    return (
      <Grid>
        <ReviewInstructions />
        <NominationSummary editable={true} />
      </Grid>
    );
  }
}

type StateProps = Pick<ConfirmNominationProps, 'award'>;
type DispatchProps = Pick<ConfirmNominationProps, 'submitNomination'>;

const mapState = (state): StateProps => ({ award: individualNominationSelectors.getCurrentAward(state) });
const mapDispatch = (dispatch): DispatchProps => bindActionCreators({ submitNomination }, dispatch);

const mapResource = (getResource) => ({
  reviewText: getResource('nominee.reviewNominationHeader', 'Review Your Nomination')
});

const compose = register('rsv8-nomination/IndividualNominationReview');

export default compose(
  withResource(mapResource),
  connect(
    mapState,
    mapDispatch
  )
)(ConfirmNomination);
