import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Text } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { togglePointsModal } from '../../../redux/actions';
import { getPointsModalVisibility } from '../../../redux/selectors';
import Modal from '../Modal/Modal';

const BreakdownContainer = styled.div`
  margin-bottom: 50px;
` as any;

const PointsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
` as any;

const Breakdown = PointsRow.extend`
  border-bottom: solid 1px #ededed;
` as any;

export interface PointsBreakdownProps {
  toggleModal: typeof togglePointsModal;
  pointsBreakdownHeading: string;
  displayOrder: any;
  totalPointsLabel: string;
  nonTangibleRedeemedLabel: string;
  tangibleRedeemedLabel: string;
}

const PointsBreakdown: React.SFC<PointsBreakdownProps> = ({
  toggleModal,
  pointsBreakdownHeading,
  displayOrder,
  totalPointsLabel,
  nonTangibleRedeemedLabel,
  tangibleRedeemedLabel,
  ...rest
}) => (
  <Modal modalHeading={pointsBreakdownHeading} toggleModal={toggleModal} {...rest}>
    <BreakdownContainer>
      <Breakdown>
        <Text themeVariation="secondary-text">{nonTangibleRedeemedLabel}</Text>
        <Text themeVariation="secondary-text-bold">
          {displayOrder.orderPoints - (displayOrder.nonTaxablePoints || 0)}
        </Text>
      </Breakdown>
      <Breakdown>
        <Text themeVariation="secondary-text">{tangibleRedeemedLabel}</Text>
        <Text themeVariation="secondary-text-bold">{displayOrder.nonTaxablePoints || 0}</Text>
      </Breakdown>
      <PointsRow>
        <Text themeVariation="primary-lg-bold-text">{totalPointsLabel}</Text>
        <Text themeVariation="primary-lg-bold-text">{displayOrder.orderPoints}</Text>
      </PointsRow>
    </BreakdownContainer>
  </Modal>
);

const mapState = (state) => ({
  show: getPointsModalVisibility(state)
});

const mapDispatch = (dispatch) => bindActionCreators({ toggleModal: togglePointsModal }, dispatch);

const mapResourceToProps = (getResource) => ({
  pointsBreakdownHeading: getResource('catalog.orderHistory.pointsBreakdownHeading', 'Points Redeemed'),
  nonTangibleRedeemedLabel: getResource(
    'catalog.orderHistory.nonTangibleRedeemedLabel',
    'Non Service Award Points Redeemed'
  ),
  tangibleRedeemedLabel: getResource('catalog.orderHistory.tangibleRedeemedLabel', 'Service Award Points Redeemed'),
  totalPointsLabel: getResource('catalog.orderHistory.totalPointsLabel', 'Total Points')
});

export default compose<any>(
  connect<ReturnType<typeof mapState>, ReturnType<typeof mapDispatch>, any>(
    mapState,
    mapDispatch
  ),
  withResource(mapResourceToProps)
)(PointsBreakdown);
