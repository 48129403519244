import * as React from 'react';
import UserPoints from './UserPoints';
import { RequirePermissions } from 'rsv8-alcon';
import { Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';

const PointsBadgeBase = styled(Badge)`
  background-color: transparent;
  display: inline-block;
`;

const PointsBadge:React.FC<any> = ({pointsShorthand,...rest }) => {
  return (
    <RequirePermissions permissionsOptions={{canViewAccount: true, canViewAccountBalance: true}}>
      <PointsBadgeBase {...rest}>
        <UserPoints /> {pointsShorthand}
      </PointsBadgeBase>
    </RequirePermissions>
  );
}

const mapStateToProps = (state) => ({
  points: state.points
});

const mapResourceToProps = (getResource) => ({
  pointsShorthand: getResource('account.pointsShorthand', 'pts')
});

export default register('rsv8-account/UserPointsShorthand')(
  withResource(mapResourceToProps),
  connect(mapStateToProps),
  withTheme(),  
  withContent()
)(PointsBadge);
