import * as React from 'react';
import { connect } from 'react-redux';
import { userStateSelectors } from 'rsv8-auth';
import { Text } from 'rsv8-components';
import styled from 'styled-components';
import { PointCostViewModel } from 'xcel-api-generator/dist/nomination';
import { register, withResource } from 'xcel-react-core';
import { createTypeSelector } from 'xcel-redux-orm';
import { emptyObject } from 'xcel-util';
import { nominationSelectors } from '../../../module';
import { nominationActions } from '../../redux/index';

const PointCostContainer = styled.div`
  padding: ${(props: any) => (props.padding ? props.padding : '15px 0 0 15px;')};
  ${(props: any) =>
    props.flexDirection &&
    `
    display: flex;
    flex-direction: ${props.flexDirection};
  `};
  @media (max-width: 991px) {
    padding: ${(props: any) => (props.padding ? '0' : '15px 0 0 0;')};
  }
` as any;
const pointCostSelector = createTypeSelector<PointCostViewModel>('pointCostViewModel');
interface PointCostProps {
  dispatch?: any;
  pointCost?: PointCostViewModel;
  id: any;
  nomineeId: any;
  pointValue: any;
  flexDirection?: any;
  padding?: any;
  showCost?: boolean;
  showValue?: boolean;
  award: any;
  localCurrencyLabel: string;
  yourCostLabel: string;
}
const PointCost: React.SFC<PointCostProps> = ({
  dispatch,
  pointCost,
  id,
  nomineeId,
  pointValue,
  flexDirection,
  showCost = true,
  showValue = true,
  padding,
  award,
  localCurrencyLabel,
  yourCostLabel
}: PointCostProps) => {
  const canShowPointCost = id !== undefined && nomineeId !== undefined && pointValue !== undefined;
  (React as any).useEffect(
    () => {
      if (canShowPointCost && award.displayPointCostAnalysis) {
        dispatch(
          nominationActions.getApplicationByIdPointCost({
            id,
            nomineeId,
            pointValue
          })
        );
        dispatch(nominationActions.getApplicationById({ id, nomineeId }));
      }
    },
    [nomineeId, id, pointValue]
  );
  if (!canShowPointCost) return null;
  if (!award.displayPointCostAnalysis) return null;
  return (
    <React.Fragment>
      {showCost && (
        <PointCostContainer flexDirection={flexDirection} padding={padding}>
          <Text themeVariation="field-label">{yourCostLabel} </Text>
          <Text> {pointCost.nominatorCost}</Text>
        </PointCostContainer>
      )}
      {showValue && (
        <PointCostContainer flexDirection={flexDirection} padding={padding || '0 0 0 15px;'}>
          <Text themeVariation="field-label">{localCurrencyLabel} </Text>
          <Text>{pointCost.nomineeValue}</Text>
        </PointCostContainer>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state, { pointValue, nomineeId, id: applicationId }) => {
  const userId = userStateSelectors.getUser(state).userId;
  const clientId = userStateSelectors.getUser(state).clientId;
  const id = `${clientId}_${userId}_${pointValue}_${nomineeId}`;
  return {
    pointCost: pointCostSelector.selectOne(state, id) || emptyObject,
    award: nominationSelectors.awardSelectors.selectOne(state, applicationId) || emptyObject
  };
};

const mapResource = (getResource) => ({
  yourCostLabel: getResource('individualNomination.yourCostLabel', 'Your cost'),
  localCurrencyLabel: getResource('individualNomination.localCurrencyLabel', 'Value in local currency')
});

export default register('rsv8-nomination/PointCost')(connect(mapStateToProps), withResource(mapResource))(
  PointCost as any
) as any;
