import { path } from 'ramda';
import { ClientCultureModel } from 'xcel-api-generator/dist/user';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { ROOT_STATE } from '../constants';
import clientSelector from './client';

const cultureSelector = {
  ...createTypeSelector<ClientCultureModel>('clientCultureModel'),
  getAll: (state) => {
    const cultures = clientSelector.getActive(state).cultures as ClientCultureModel[];
    return cultures;
  },
  getActive: (state): ClientCultureModel => path([ROOT_STATE, 'culture', 'active'], state)
};

export { ClientCultureModel, Resource };
export default cultureSelector;
