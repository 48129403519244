import { DropTarget } from 'react-dnd';
import * as types from './types';
// import { findDOMNode } from 'react-dom';
const canDropComponent = (from, to) => {
  return from !== to && from !== to.replace(/(.+).children.\d+/, '$1');
};
const ComponentDropTarget = (model = '') => {
  let config = {
    component: {
      canDrop(props: any, monitor: any) {
        const dragItem = monitor.getItem();
        let from = dragItem.model;
        let to = props.model + (model ? model : '');
        // console.log('can drop?', !canDropComponent(from, to));
        if (!canDropComponent(from, to)) {
          return false;
        }
        return true;
      },
      drop(props: any, monitor: any, component: any) {
        const dragItem = monitor.getItem();
        let from = dragItem.model;
        let to = props.model + (model ? model : '');

        const isOverCurrent = monitor.isOver({ shallow: true });
        if (!canDropComponent(from, to) || !isOverCurrent) {
          return;
        }

        // // Dragging downwards
        // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        //   return;
        // }

        // // Dragging upwards
        // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        //   return;
        // }
        if (props.onDrop) {
          props.onDrop({ dragItem, from, to });
        }
        dragItem.model = to;
      }
    },
    mapSourceToProps: (connector: any, monitor: any) => ({
      dropTarget: connector.dropTarget(),
      isDroppable: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  };
  return DropTarget(types.COMPONENT, config.component, config.mapSourceToProps);
};
export default ComponentDropTarget;
