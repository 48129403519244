import {
  Col,
  FormGroup,
  ListGroup,
  ListGroupItem
  } from 'react-bootstrap';
import * as Icon from 'react-fontawesome';
import {
  Button,
  Grid,
  HeaderFive,
  HeaderFour,
  HeaderThree,
  Loader,
  Text
  } from 'rsv8-components';
import styled from 'styled-components';
import { FieldContainer } from '../../components/Label';

export const PointRangeContainer = styled.div`
  span {
    padding: 2px;
  }
  button {
    margin-left: 10px;
    padding: 0;
  }
  input {
    padding: 6px 9px;
  }
  label {
    font-weight: normal;
  }
` as any;

export const SearchCard = styled.div`
  border-radius: 3px;
  border: solid 1px #dddddd;
  flex-grow: 0;
  margin: 0.5em;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: 450px) and (max-width: 768px) {
    width: calc(50% - 15px);
  }
  @media (min-width: 769px) and (max-width: 1350px) {
    width: calc(33.3333% - 15px);
  }
  @media (min-width: 1351px) {
    width: calc(25% - 15px);
  }
` as any;

export const SearchResultsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
` as any;

export const FacetFilterContainer = styled.div`` as any;
export const CategoryFilterContainer = styled.div`` as any;
export const PointValueFilterContainer = styled.div`` as any;

export const ClearRangeButton = styled(Button)`
  margin-top: 10px !important;
`as any;

export const BrandFilterContainer = styled.div`` as any;
export const ViewMoreCategoriesIcon = styled(Icon)`
  margin-right: 5px;
` as any;

export const SearchResultsHeading = styled(HeaderThree)`
  color: #4c4f4f;
  font-size: 18px;
  margin-left: 0.5em !important;

  @media (min-width: 993px) {
    display: inline-block;
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 17px;
  }
` as any;

export const Divider = styled.div`
  border-top: solid 1px #ededed;
  margin-bottom: 15px;
  margin-top: 10px;
` as any;

export const CategoryContainer = styled.div`
  margin-bottom: 20px;
` as any;

export const SubCategoryFieldContainer = styled(FieldContainer)`` as any;

export const CategoryFieldContainer = styled(FieldContainer)`
  .checkbox {
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed;
    margin-bottom: 10px;
    margin-top: 0;
    padding: 5px 0;
  }
` as any;

export const TotalResultsContainer = styled(Text)`
  color: #4c4f4f;
  margin-left: 0.5em;
` as any;

export const ShowAllButton = styled(Button)`
  color: #4192a5;
  margin-left: 5px;
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    text-decoration: none;
    outline: none;
  }
` as any;

export const FilterHeading = styled(HeaderFive)`
  color: #4c4f4f;
` as any;

export const BrandHeading = styled(FilterHeading)`
  margin-bottom: 0;
  margin-left: 4px;
` as any;

export const SubCategory = styled.div`
  padding-left: 25px;
` as any;

export const DrawerContainer = styled.div`
  margin: 20px 0 30px 0;
  padding: 0 0.6em 0 0.6em;

  & > button {
    display: inline-block;
    width: 50%;
    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
` as any;

export const FilterTitle = styled(HeaderFour)`
  color: #4c4f4f;
  font-size: 16px;
  margin: 0 !important;
` as any;

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 25px;
` as any;

export const PaddedCol = styled(Col)`
  @media (min-width: 993px) {
    padding-left: 10px;
  }
` as any;

export const TitleCol = styled(PaddedCol)`
  @media (min-width: 993px) {
    width: 100%;
  }
` as any;

export const CategoryGroupAlpha = styled.div`
  color: #4192a5;
  font-family: LucidaGrande, Helvetica, Arial;
  margin-bottom: 3px;
  margin-left: 9px;
` as any;

export const ResetFilterButton = styled(Button)`
  color: rgba(0, 0, 0, 0.2);
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    color: rgba(0, 0, 0, 0.2);
    outline: none;
    text-decoration: none;
  }
` as any;

export const SortByContainer = styled.div`
  float: right;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 10px;
  display: flex;
` as any;

export const SortByFormGroup = styled(FormGroup)`
  display: inline-block;
  margin-left: 20px;
` as any;

export const SortGroup = styled(ListGroup)`
  margin-top: 30px;
` as any;

export const SortGroupItem = styled(ListGroupItem)`
  cursor: pointer;
` as any;

export const DrawerFooter = styled.div`
  width: 100%;
  box-shadow: 0 2px 11px 2px rgba(28, 39, 76, 0.09), 0 -1px 0 0 #ededed;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 17px 25px;
  display: flex;
  justify-content: space-between;
` as any;

export const DrawerButton = styled(Button)`
  padding: 8px 10px;
  width: 40%;
` as any;

export const Search = styled.div`
  display: ${(props: any) => (props.hidden ? 'none' : 'block')};
` as any;

export const SearchLoader = styled(Loader)`
  display: ${(props: any) => (props.hidden ? 'none' : 'block')};
` as any;

export const SearchGrid = styled(Grid)`
  position: relative;
` as any;

export const SearchOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: gray;
  opacity: 0.6;
  z-index: 1000;
` as any;
