import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import cultureActions from '../../redux/actions/culture';
import cultureSelector from '../../redux/selectors/culture';
export interface CultureDropdownProps {
  cultureSet: any;
  list: Array<any>;
  active: any;
  property?: string;
  onChange?: Function;
  label?: string;
  placeholder?: string;
  props?: any;
  allowCultureChange?: boolean;
  component: any;
  dispatch: any;
  [index: string]: any;
}
const CultureDropdown: React.SFC<CultureDropdownProps> = ({
  cultureSet: cultureSetAction,
  list,
  active,
  onChange,
  property = 'language',
  allowCultureChange = true,
  dispatch,
  ...rest
}) => {
  const handleChange = (value) => {
    if (onChange) onChange(value);
    dispatch(cultureActions.set(value, property, allowCultureChange));
  };
  const options =
    list &&
    list.map((culture, index) => {
      return { label: culture && culture.name, value: culture && culture[property] };
    });
  return (
    <ReactField
      name="CultureDropdown"
      component="bs-select"
      value={active && active[property]}
      {...rest}
      onChange={handleChange}
      options={options}
    />
  );
};
const mapStateToProps = (state) => ({
  list: cultureSelector.selectMany(state, null),
  active: cultureSelector.getActive(state)
});
export default connect(mapStateToProps)(CultureDropdown) as any;
