import * as React from 'react';
import { PageRenderer } from 'rsv8-cms';
import { Toastify } from 'rsv8-components';
import styled from 'styled-components';
import { RouteMatch } from 'xcel-react-core';

// ie11 needs the parent of the flexed container to also be flexed
const IeFix = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const ContentContainer = styled.div`
  flex-grow: 1;
`;

const FooterLayout = ({ children, ...rest }) => {
  return (
    <RouteMatch>
      <IeFix>
        <Wrapper>
          <ContentContainer {...rest}>
            <Toastify hideProgressBar={true} autoClose={2500} />
            {children}
          </ContentContainer>
          <PageRenderer path={`/layout/footer`} />
        </Wrapper>
      </IeFix>
    </RouteMatch>
  );
};

export default FooterLayout;
