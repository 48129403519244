import * as React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Forms } from '../../types';
import addValidationState from '../HOC/addValidationState';
import FormControl from './FormControl';
import { StatusText } from './helpers';
import Label from './Label';
import { TooltipContainer } from './TooltipContainer';

const BSInput: Forms.Inputs.BootstrapInput = ({
  input,
  meta,
  validationState,
  helpText,
  label,
  helpIconText,
  ...rest
}) => {
  return (
    <FormGroup controlId={input.name} validationState={validationState}>
      {label && <Label {...rest}>{label}</Label>}
      {helpIconText && <TooltipContainer tooltipChildren={helpIconText} />}
      <FormControl {...input} {...rest} />
      <StatusText meta={meta} helpText={helpText} />
    </FormGroup>
  );
};

export default addValidationState(BSInput);
