import * as React from 'react';
import { Clearfix, Col } from 'react-bootstrap';
import styled from 'styled-components';
import AwardCard from './AwardCard/index';
import Filter from './Filter/index';
import { PlaceholderNoResults } from './Placeholder/index';
import { PlaceholderFiltering } from './Placeholder/index';

const ListContainer = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
  margin-bottom: 100px;
`;

type ListState = {
  currentList?: Array<Object>;
};

type ListProps = {
  actions?: any;
  isFiltering: boolean;
  filterMsgText: string;
  isDisplayed: boolean;
  noSearchResults: boolean;
  clickApply: any;
  clickClear: any;
  awards: Array<Object>;
  selectList: Array<Object>;
  culture: string;
  view: string;
};

class MyAwardsList extends React.Component<ListProps, ListState> {
  componentDidMount() {
    this.setState({
      currentList: this.props.awards
    });
  }

  public render() {
    let { awards, culture, view, isFiltering, isDisplayed } = this.props;
    return isDisplayed ? (
      <ListContainer>
        <Col lg={6} md={6} sm={6} xs={24}>
          <Filter
            clickClear={this.props.clickClear}
            clickApply={this.props.clickApply}
            selectList={this.props.selectList}
          />
        </Col>

        <Col lg={18} md={18} sm={18} xs={24}>
          {isFiltering ? (
            <PlaceholderFiltering displayText={this.props.filterMsgText} />
          ) : (
            awards &&
            awards.map((award, idx) => (
              <AwardCard key={`award-row-${idx}`} award={award} culture={culture} view={view} />
            ))
          )}
          {this.props.noSearchResults ? <PlaceholderNoResults /> : null}
        </Col>
        <Clearfix />
      </ListContainer>
    ) : null;
  }
}

export default MyAwardsList;
