import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid, HeaderTwo, Loader } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import EmptyList from '../../components/ItemCarousel/EmptyList';
import {
  deleteFavorite,
  getAccountSummary,
  getFavoriteProducts,
  postCart,
  postFavorite,
  resetUndoList
  } from '../../redux/actions';
import {
  getCartProducts,
  getCurrentAccountSummary,
  getFavoriteProductList,
  getFavoritesLoaded,
  getUndoProductsList
  } from '../../redux/selectors';
import FavoritesPanels from './FavoritesPanels/FavoritesPanels';
import FavoritesTable from './FavoritesTable/FavoritesTable';
import { MyFavoritesProps } from './types';

const MyFavoritesHeading = styled(HeaderTwo)`
  color: #4c4f4f;
  font-weight: bold;
  margin-bottom: 40px;
` as any;

class MyFavorites extends React.Component<MyFavoritesProps, any> {
  state = {
    addCartButtonDisabled: false,
    removeFavoriteDisabled: false,
    undoDisabled: false
  };

  componentDidMount() {
    this.props.actions.getAccountSummary();
    this.props.actions.getFavoriteProducts({ id: 'favorite' });
  }

  componentWillUnmount() {
    this.props.actions.resetUndoList();
  }

  removeFavorite = (product) => (e) => {
    this.setState({ removeFavoriteDisabled: true });
    this.props.actions.deleteFavorite(product, { undo: true }).then((res) => {
      this.setState({ removeFavoriteDisabled: false });
    });
  };

  addToCart = (product, optionSelection,groupSelection) => {
    this.setState({ addCartButtonDisabled: true }); 
    this.props.actions.postCart(product, optionSelection,groupSelection).then((res) => {
      this.setState({ addCartButtonDisabled: false });
    });
  };

  undoFavorite = (product) => (e) => {
    this.setState({ undoDisabled: true });
    this.props.actions.postFavorite(product, { undo: true }).then((res) => {
      this.setState({ undoDisabled: false });
    });
  };

  renderFavorites = () => {
    const {
      accountSummary,
      favoriteProducts,
      cartProducts,
      emptyFavoritesLabel,
      undoProducts,
      favoritesIcon
    } = this.props;

    return (favoriteProducts.filter((product) => product !== null).length > 0 || undoProducts.length > 0) &&
      accountSummary ? (
      <React.Fragment>
        <FavoritesTable
          accountSummary={accountSummary}
          addToCart={this.addToCart}
          products={favoriteProducts}
          removeFavorite={this.removeFavorite}
          undoFavorite={this.undoFavorite}
          undoDisabled={this.state.undoDisabled}
          undoProducts={undoProducts}
          removeFavoriteDisabled={this.state.removeFavoriteDisabled}
          addCartButtonDisabled={this.state.addCartButtonDisabled}
          cartProducts={cartProducts}
        />
        <FavoritesPanels
          accountSummary={accountSummary}
          addToCart={this.addToCart}
          products={favoriteProducts}
          removeFavorite={this.removeFavorite}
          undoFavorite={this.undoFavorite}
          undoProducts={undoProducts}
          cartProducts={cartProducts}
        />
      </React.Fragment>
    ) : (
      <Row>
        <Col xs={20} xsOffset={2} sm={24} smOffset={0} md={22} mdOffset={1} lg={20} lgOffset={2}>
          <EmptyList icon={favoritesIcon} label={emptyFavoritesLabel} />
        </Col>
      </Row>
    );
  };

  render() {
    const { accountSummary, favoritesLoaded, favoritesHeading } = this.props;

    return (
      <Grid themeVariation="page-wrapper">
        <Row>
          <Col xs={22} xsOffset={1} sm={24} smOffset={0} md={22} mdOffset={1} lg={20} lgOffset={2}>
            <MyFavoritesHeading>{favoritesHeading}</MyFavoritesHeading>
          </Col>
        </Row>
        {favoritesLoaded && accountSummary ? this.renderFavorites() : <Loader />}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  accountSummary: getCurrentAccountSummary(state),
  favoriteProducts: getFavoriteProductList(state),
  favoritesLoaded: getFavoritesLoaded(state),
  undoProducts: getUndoProductsList(state),
  cartProducts: getCartProducts(state)
});

const mapResourceToProps = (getResource) => ({
  emptyFavoritesLabel: getResource('catalog.myFavorites.emptyFavoritesLabel', 'You have no favorites saved.'),
  favoritesIcon: getResource('catalog.favoritesIcon', '/clientassets/images/icons/empty-state--wishlist.svg'),
  favoritesHeading: getResource('catalog.myFavorites.favoritesHeading', 'My Favorites')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteFavorite,
      getAccountSummary,
      getFavoriteProducts,
      postCart,
      postFavorite,
      resetUndoList
    },
    dispatch
  )
});
export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withResource(mapResourceToProps)
)(MyFavorites);
