import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ComponentRenderer } from 'rsv8-cms';
import { cultureSelector } from 'rsv8-client';
import { register, withTheme } from 'xcel-react-core';
import { parseActivityMetadata, RawShareableActivity } from '../../../api/get-shareables-activities';
import {
  downloadActivity,
  likeActivity,
  removeActivity as deleteActivity,
  shareActivity,
  unlikeActivity
  } from '../../../redux/actions';
import { actionInProcess } from '../../../redux/selectors';
import { ActionPanel } from './ActionPanel';
import FeedCardHeader from './FeedCardHeader';
import { FeedCardStyle, StyledRemoveIcon } from './styles';
import ConfirmFeedCardDeletionModal from "./ConfirmFeedCardDeletionModal";

interface Props extends RawShareableActivity {
  className?: string;
  cultureId: number;
  path: string;
  actionInProcess: boolean;
  removeActivity: (id: string, params?: any) => void;
  unlikeActivity: (id: string, params?: any) => void;
  likeActivity: (id: string, params?: any) => void;
  shareActivity: (id: string, params?: any) => void;
  downloadActivity: (id: string, params?: any) => void;
}
class FeedCard extends React.PureComponent<Props> {
  state = {ActiveModal: false};
  handleCloseClick = () => {
    this.setState({ActiveModal: false});
  };

  handleRemoveClick = () => {
    this.setState({ActiveModal: false});
    this.props.removeActivity(this.props.path);
  };
  render() {
    const {
      className,
      publishedDateTimeUtc,
      title,
      content,
      activityType,
      allowDelete
    } = this.props;
    const metadata = parseActivityMetadata(this.props.activityTypeMetadata);
    const availableUserActions = (metadata && metadata.availableUserActions) || [];

    const imageContent = (Array.isArray(content) ? content : []).find((c) => c.component === 'rsv8-components/Image');

    return (
      <FeedCardStyle className={className}>
        {this.state.ActiveModal && (
          <ConfirmFeedCardDeletionModal toggleModal={this.handleCloseClick} removeHandler={this.handleRemoveClick} show={true}  />
        )}
        {allowDelete && <StyledRemoveIcon className="remove-icon" onClick={() => this.setState({ActiveModal: true})} />}
        {activityType !== 'promotions' ? (

          <FeedCardHeader title={title} publishedDateTimeUtc={publishedDateTimeUtc} />
        ) : null}
        <ComponentRenderer data={content} />
        <ActionPanel
          actionInProcess={this.props.actionInProcess}
          imageSrc={(imageContent && imageContent.content && imageContent.content.imgSrc) || ''}
          userActions={this.props.userActions || []}
          cultureId={this.props.cultureId}
          availableUserActions={availableUserActions}
          postPath={this.props.path}
          unlikeActivity={this.props.unlikeActivity}
          likeActivity={this.props.likeActivity}
          shareActivity={this.props.shareActivity}
          downloadActivity={this.props.downloadActivity}
        />
      </FeedCardStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  actionInProcess: actionInProcess(state),
  cultureId: Number(cultureSelector.getActiveCultureId(state))
});

const mapDispatchToProps = (dispatch) => ({
  unlikeActivity: bindActionCreators(unlikeActivity, dispatch),
  likeActivity: bindActionCreators(likeActivity, dispatch),
  removeActivity: bindActionCreators(deleteActivity, dispatch),
  shareActivity: bindActionCreators(shareActivity, dispatch),
  downloadActivity: bindActionCreators(downloadActivity, dispatch)
});

export default register('rsv8-social/FeedCard')(withTheme(), connect(mapStateToProps, mapDispatchToProps))(FeedCard);
