import * as React from 'react';
import { Text } from 'rsv8-components';
import styled from 'styled-components';
import { register } from 'xcel-react-core';
import { withThemedStatus } from '../../NominationStatus';
import { NomineeImage } from '../../NomineeImage';

const StyledHr = styled.hr`
  margin: 0;
` as any;

const TeamMemberContainer = styled.div`
  padding: 23px 20px;
` as any;

const TeamMember = styled.div`
  display: inline-block;
  margin-right: 5px;
` as any;

const TeamMemberPanel = ({ member, onClick, ...props }) => (
  <React.Fragment>
    <StyledHr />
    <TeamMemberContainer key={member.id} {...props}>
      <TeamMember>
        <NomineeImage onClick={onClick} nominee={member.nomineeUser} caption={false} orientation="right" width="40px" />
      </TeamMember>
      <Text themeVariation="secondary-text-bold">
        {`${member.nomineeUser.firstName} ${member.nomineeUser.lastName}`}
      </Text>
    </TeamMemberContainer>
  </React.Fragment>
);

export default register('rsv8-nomination/TeamMemberPanel')(withThemedStatus)(TeamMemberPanel);
