import { push } from "connected-react-router";
import { convertToPatchOperations } from "xcel-util";
import * as events from "../events";
import userSelector from "../selectors/user";
import userApiActions from "./userApiActions";
// import { authActions } from '../../../authModule';

const userActions = {
  setData: (user) => {
    return {
      type: events.USER_DATA_SET,
      payload: user,
    };
  },
  get: () => async (dispatch, getState) => {
    try {
      await dispatch(userApiActions.getUser({ config: { headers: { Accept: "application/vnd.api+json" } } }));
      const user: any = userSelector.get(getState());
      dispatch(userActions.setData({ ...user }));
      if (!user.hasConsented && !user.resetPassword) {
        // user must consent before doing anything in the application
        dispatch(push("/consent"));
      }
      return user;
    } catch (e) {
      console.warn(e, "Failed to get user");
    }
  },
  update: (user) => async (dispatch, getState) => {
    const response = await dispatch(
      userApiActions.patchUser(
        { body: convertToPatchOperations(user) },
        { config: { headers: { Accepts: "application/vnd.api+json" } } }
      )
    );
    dispatch(userActions.setData(user));
    return response;
  },
  setConsent: (hasConsented) => async (dispatch, getState) => {
    await dispatch(userActions.update({ hasConsented }));
    dispatch(userActions.setData({ hasConsented }));
    if (hasConsented === false) {
      dispatch(push("/logout"));
    }
  },
};
export default userActions;
