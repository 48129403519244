import { parse } from "query-string";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { cultureSelector } from "rsv8-client";
import { Loader, PlaceholderBanner } from "rsv8-components";
import { withResource } from "xcel-react-core";
import { FieldLabel } from "../../components/Label";
import {
  categorySearch,
  getFavoriteProducts,
  getProductsSearch,
  resetFilters,
  resetPointRangeFilter,
  resetSearchResults,
  scrollSearch,
  setBrandChange,
  sortSearch,
  submitPointSearch,
  toggleBrands,
  toggleCategories
} from "../../redux/actions";
import {
  getBrandFilters,
  getCategoryFilters,
  getCategorySearchName,
  getCurrentQuery,
  getFavoriteList,
  getProductsSearchRequest,
  getSearchResults,
  getSearchScrollId,
  getTotalResultsMeta
} from "../../redux/selectors";
import BrandsModal from "./BrandsModal/BrandsModal";
import CategoryModal from "./CategoryModal/CategoryModal";
import FilterColumn from "./FacetFilters/FilterColumn/FilterColumn";
import { FilterDrawer, SortDrawer } from "./FacetFilters/FilterDrawers";
import HeadingRow from "./HeadingRow/HeadingRow";
import Results from "./Results/Results";
import { DrawerContainer, PaddedCol, SearchGrid, TitleCol } from "./styles";
import { SearchResultsProps, SearchResultsState } from "./types";
// import { CurrentFilters } from './FacetFilters/CurrentFilters';

class SearchResults extends React.Component<SearchResultsProps, SearchResultsState> {
  state = {
    brandOptions: [],
    checkedBrands: [],
    checkedCategories: [],
    displayError: false,
    exposedCategories: [],
    isSortDrawerOpen: false,
    maxPoints: "",
    minPoints: "",
    noResults: false,
    params: {},
    searchCategories: "",
    searchSort: "",
    searchString: "",
    showCategoriesText: false,
    showMoreBrands: false
  };

  componentWillMount() {
    this.createParams();
  }

  componentDidMount() {
    this.createSearch();
  }

  componentDidUpdate(prevProps: any) {
    // If search query / filters changed, resubmit search
    if (this.props.location.search !== prevProps.location.search || this.props.activeCulture !== prevProps.activeCulture) {
      this.createParams().then((res) => {
        this.createSearch();
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.resetSearchResults();
  }

  createParams = () => {
    const params = parse(this.props.location.search);

    this.setState({
      params,
      searchString: params.filterQuery as string,
      searchCategories: params.filterCategories as string,
      searchSort: params.pagingSortDirection as string
    });
    return Promise.resolve();
  };

  createSearch = () => {
    this.props.actions.getFavoriteProducts({ id: "favorite" });

    this.props.actions
      .getProductsSearch(this.state.params)
      .then((res) => {
        this.setState({ noResults: false });
        this.initBrandFilter();
        this.initCategoryFilter();
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          this.setState({ noResults: true });
        }
      });
  };

  initBrandFilter = () => {
    const { brandFilters, currentQuery } = this.props;

    if (brandFilters && brandFilters.length > 7) {
      this.setState({ showMoreBrands: true });
    }

    const checkedBrands = (Array.isArray(currentQuery.filterBrands) && currentQuery.filterBrands.map(Number)) || [];

    const brandOptions = brandFilters
      ? brandFilters
        .map((filter) => {
          return {
            filterLabel: `${filter.name} (${filter.productCount})`,
            label: <FieldLabel isCheckbox={true} labelName={`${filter.name} (${filter.productCount})`} />,
            value: filter.id
          };
        })
        .sort((x, y) => (checkedBrands.includes(x.value) ? -1 : checkedBrands.includes(y.value) ? 1 : 0))
      : [];

    this.setState({ brandOptions, checkedBrands });
  };

  initCategoryFilter = () => {
    const { categoryFilters, currentQuery } = this.props;

    let categoryCount = 0;
    let exposedCategories = [];
    let checkedCategories = [];

    // Make reduced set of category filters to display on page.
    if (categoryFilters) {
      for (const category of categoryFilters) {
        if (categoryCount <= 10) {
          exposedCategories.push(category);
          categoryCount += category.children.length + 1;
        } else {
          this.setState({ showCategoriesText: true });
          break;
        }
      }
    }

    Array.isArray(currentQuery.filterCategories)
      ? (checkedCategories = currentQuery.filterCategories)
      : currentQuery.filterCategories && checkedCategories.push(currentQuery.filterCategories);

    this.setState({ checkedCategories, exposedCategories });
  };

  handleBrandChange = (value) => {
    this.props.actions.setBrandChange(value, this.props.history);
  };

  handleMaxChange = (e) => {
    if (!e.target.value || e.target.value.match(/^\d+$/) !== null) {
      this.setState({ maxPoints: e.target.value });
    }
  };

  handleMinChange = (e) => {
    if (!e.target.value || e.target.value.match(/^\d+$/) !== null) {
      this.setState({ minPoints: e.target.value });
    }
  };

  toggleSortDrawer = (openState) => {
    this.setState({ isSortDrawerOpen: openState });
  };

  handleSubmit = (e) => {
    const { maxPoints, minPoints } = this.state;
    e.preventDefault();
    this.setState({ displayError: false });
    if (
      !Number.isInteger(parseInt(minPoints, 10)) ||
      !Number.isInteger(parseInt(maxPoints, 10)) ||
      parseInt(minPoints, 10) < 0 ||
      parseInt(maxPoints, 10) < 0 ||
      parseInt(maxPoints, 10) < parseInt(minPoints, 10)
    ) {
      this.setState({ displayError: true });
    } else {
      this.props.actions.submitPointSearch(this.state.maxPoints, this.state.minPoints, this.props.history);
    }
  };

  handleRangeClear = () => {
    if (this.state.minPoints || this.state.maxPoints) {
      this.setState({ minPoints: "", maxPoints: "" }, () => {
        this.props.actions.resetPointRangeFilter(this.props.history);
      });
    }
  };

  toggleBrandModal = () => {
    this.props.actions.toggleBrands();
  };

  toggleCategoryModal = () => {
    this.props.actions.toggleCategories();
  };

  preventActionDispatch = (e) => {
    e.preventDefault();
  };

  loadMore = () => {
    this.props.actions.scrollSearch(this.props.scrollId);
  };

  resetFilters = () => {
    this.props.actions.resetFilters(this.props.history);
  };

  checkCategory = (id) => {
    const { currentQuery } = this.props;

    if (currentQuery) {
      return Array.isArray(currentQuery.filterCategories)
        ? currentQuery.filterCategories.includes(id)
        : parseInt(currentQuery.filterCategories, 10) === parseInt(id, 10);
    } else return false;
  };

  changeSort = (e) => {
    this.props.actions.sortSearch(e.target.value, this.props.history);
  };

  render() {
    const {
      favoritesList,
      brandFilters,
      searchResults,
      location,
      categoryFilters,
      totalResults,
      noResultsLabel,
      categorySearchQuery,
      ...rest
    } = this.props;

    const {
      searchSort,
      searchString,
      showMoreBrands,
      displayError,
      brandOptions,
      checkedBrands,
      exposedCategories,
      checkedCategories,
      showCategoriesText
    } = this.state;

    if (this.state.noResults) {
      return (
        <SearchGrid themeVariation="page-wrapper">
          <Row>
            <Col xs={24}>
              <PlaceholderBanner text={noResultsLabel} />
            </Col>
          </Row>
        </SearchGrid>
      );
    }

    return (
      <SearchGrid themeVariation="page-wrapper">
        <CategoryModal categoryFilters={categoryFilters} checkedCategories={this.state.checkedCategories} {...rest} />
        <BrandsModal brandOptions={this.state.brandOptions} checkedBrands={this.state.checkedBrands} {...rest} />
        <Row>
          <TitleCol xs={24} md={23} mdOffset={0}>
            <HeadingRow changeSort={this.changeSort} {...{ totalResults, categorySearchQuery, searchSort, searchString }} />
          </TitleCol>
        </Row>
        {Array.isArray(searchResults) && searchResults.length > 0 ? (
          <React.Fragment>
            <Row>
              <Col xs={24} mdHidden={true} lgHidden={true}>
                <DrawerContainer>
                  <FilterDrawer categoryFilters={categoryFilters} {...{ brandOptions, checkedCategories, checkedBrands }} />
                  <SortDrawer
                    toggleDrawer={this.toggleSortDrawer}
                    isOpen={this.state.isSortDrawerOpen}
                    searchSort={this.state.searchSort}
                  />
                </DrawerContainer>
                {/* Add me back after demo */}
                {/* <CurrentFilters searchSort={this.state.searchSort} toggleDrawer={this.toggleSortDrawer} /> */}
              </Col>
            </Row>
            <Row>
              <PaddedCol xsHidden={true} smHidden={true} md={5} lg={4} mdOffset={0}>
                <FilterColumn
                  handleBrandChange={this.handleBrandChange}
                  handleMaxChange={this.handleMaxChange}
                  handleMinChange={this.handleMinChange}
                  handleSubmit={this.handleSubmit}
                  resetFilters={this.resetFilters}
                  toggleBrandModal={this.toggleBrandModal}
                  toggleCategoryModal={this.toggleCategoryModal}
                  minVal={this.state.minPoints}
                  maxVal={this.state.maxPoints}
                  handleRangeClear={this.handleRangeClear}
                  {...{
                    displayError,
                    brandOptions,
                    checkedBrands,
                    showMoreBrands,
                    checkedCategories,
                    brandFilters,
                    categoryFilters,
                    exposedCategories,
                    showCategoriesText
                  }}
                />
              </PaddedCol>
              <Col xs={24} md={19} lg={20}>
                <Results loadMore={this.loadMore} {...{ searchResults, favoritesList, location, totalResults }} />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </SearchGrid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const categoryId = parse(ownProps.location.search).filterCategories;
  const currentQuery = getCurrentQuery(state);

  return {
    currentQuery,
    brandFilters: getBrandFilters(state),
    categoryFilters: getCategoryFilters(state),
    categorySearchQuery: categoryId ? getCategorySearchName(state, categoryId) : {},
    favoritesList: getFavoriteList(state),
    searchResults: getSearchResults(state),
    totalResults: getTotalResultsMeta(state, ownProps),
    scrollId: getSearchScrollId(state),
    searchRequest: getProductsSearchRequest(state),
    activeCulture: cultureSelector.getActive(state)
  };
};

const mapResourceToProps = (getResource) => ({
  noResultsLabel: getResource("catalog.searchResults.noResultsLabel", "No products found. Please try again")
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getFavoriteProducts,
      getProductsSearch,
      resetSearchResults,
      setBrandChange,
      categorySearch,
      submitPointSearch,
      sortSearch,
      scrollSearch,
      resetFilters,
      toggleBrands,
      toggleCategories,
      resetPointRangeFilter
    },
    dispatch
  )
});

export default compose<any>(connect(mapStateToProps, mapDispatchToProps), withResource(mapResourceToProps))(SearchResults);
