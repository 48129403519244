import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { emptyArray } from 'xcel-util';
import { contentTypeActions } from '../../../redux/actions';
import { contentTypeSelector } from '../../../redux/selectors';
class ContentTypeDropdown extends React.Component<any> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(contentTypeActions.get());
  }
  handleChange = (value: any) => {
    const { dispatch } = this.props;
    dispatch(contentTypeActions.setActive(value));
  };
  render() {
    const { contentTypes, active } = this.props;
    return (
      <ReactField
        component="bs-select"
        title="Content Type"
        label="Content Type"
        value={active}
        onChange={this.handleChange}
        options={contentTypes.map((item) => ({ value: item.id, label: item.name }))}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  active: contentTypeSelector.getActive(state),
  contentTypes: contentTypeSelector.selectMany(state) || emptyArray
});

export default connect(mapStateToProps)(ContentTypeDropdown);
