import { connect } from 'react-redux';
import { contentTypeSelector, contentVersionSelector } from '../../redux';
import cmsAdminApiActions from '../../redux/actions/cmsAdminApi';
import ContentPromotionButtonComponent from './ContentPromotionButtonComponent';
// import * as React from 'react';

const { createAdminPromotions } = cmsAdminApiActions;

const mapStateToProps = state => ({
  version: contentVersionSelector.getActiveVersion(
    state,
    contentTypeSelector.getActive(state)
  )
});

const mapDispatch = (dispatch, ownProps) => ({
  onClick: () => {
    if (
      confirm(`Are you sure you want to promote all the content?`)
    ) {
      const { pathsToPromote } = ownProps;
      console.log("pathsToPromote", pathsToPromote);

      const response = dispatch(
        createAdminPromotions({
          body: {
            contentTypesToPromote: [],
            foldersToPromote: [],
            pathsToPromote: pathsToPromote,
            publishedFromDateUtc: null
          }
        })
      );
      return response;
    }
  }
});

export default connect(mapStateToProps, mapDispatch)
  (ContentPromotionButtonComponent) as any;
