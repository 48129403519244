/**
 * FONT
 */

export const THEME_FONT_ADD = 'rsv8-theme/THEME_FONT_ADD';
export const THEME_FONT_UPDATE = 'rsv8-theme/THEME_FONT_UPDATE';
export const THEME_FONT_REMOVE = 'rsv8-theme/THEME_FONT_REMOVE';

export const THEME_FONT_PREVIEW_SET = 'rsv8-them/THEME_FONT_PREVIEW_SET';
/**
 * COLOR
 */
export const THEME_COLOR_ADD = 'rsv8-theme/THEME_COLOR_ADD';
export const THEME_COLOR_UPDATE = 'rsv8-theme/THEME_COLOR_UPDATE';
export const THEME_COLOR_REMOVE = 'rsv8-theme/THEME_COLOR_REMOVE';
/**
 * THEME
 */
export const THEME_ADD = 'rsv8-theme/THEME_ADD';
export const THEME_REMOVE = 'rsv8-theme/THEME_REMOVE';
/**
 * COMPONENT
 */
export const COMPONENT_STYLE_PROPERTY_SET = 'rsv8-theme/COMPONENT_STYLE_PROPERTY_SET';
export const COMPONENT_STYLE_PSEUDO_SELECTOR_SET = 'rsv8-theme/COMPONENT_STYLE_PSEUDO_SELECTOR_SET';
export const COMPONENT_STYLE_MEDIA_QUERY_SET = 'rsv8-theme/COMPONENT_STYLE_MEDIA_QUERY_SET';
export const COMPONENT_EXAMPLE_ADD = 'rsv8-theme/COMPONENT_EXAMPLE_ADD';
export const COMPONENT_EXAMPLE_UPDATE = 'rsv8-theme/COMPONENT_EXAMPLE_UPDATE';
export const COMPONENT_EXAMPLE_REMOVE = 'rsv8-theme/COMPONENT_EXAMPLE_REMOVE';
export const COMPONENT_EXAMPLE_SELECT = 'rsv8-theme/COMPONENT_EXAMPLE_SELECT';
export const COMPONENT_EXAMPLE_ALL_SELECT = 'rsv8-theme/COMPONENT_EXAMPLE_ALL_SELECT';
export const COMPONENT_ACTIVE_STYLE_SET = 'rsv8-theme/COMPONENT_ACTIVE_STYLE_SET';
export const COMPONENT_STYLE_SELECT = 'rsv8-theme/COMPONENT_STYLE_SELECT';
export const COMPONENT_VARIATION_SELECT = 'rsv8-theme/COMPONENT_VARIATION_SELECT';
export const COMPONENT_VARIATION_ADD = 'rsv8-theme/COMPONENT_VARIATION_ADD';
export const COMPONENT_STYLE_RESET = 'rsv8-theme/COMPONENT_STYLE_RESET';
export const COMPONENT_CREATE_SELECTOR = 'rsv8-theme/COMPONENT_CREATE_SELECTOR';
/**
 * CMS
 */
export const THEME_TYPE_ID_GET_REQUEST = 'rsv8-theme/THEME_TYPE_ID_GET_REQUEST';
export const THEME_TYPE_ID_GET_SUCCESS = 'rsv8-theme/THEME_TYPE_ID_GET_SUCCESS';
export const THEME_TYPE_ID_GET_FAILURE = 'rsv8-theme/THEME_TYPE_ID_GET_FAILURE';

export const CONTENT_TYPE_CREATE_REQUEST = 'rsv8-theme/CONTENT_TYPE_CREATE_REQUEST';
export const CONTENT_TYPE_CREATE_SUCCESS = 'rsv8-theme/CONTENT_TYPE_CREATE_SUCCESS';
export const CONTENT_TYPE_CREATE_FAILURE = 'rsv8-theme/CONTENT_TYPE_CREATE_FAILURE';

export const THEME_GET_REQUEST = 'rsv8-theme/THEME_GET_REQUEST';
export const THEME_GET_SUCCESS = 'rsv8-theme/THEME_GET_SUCCESS';
export const THEME_GET_FAILURE = 'rsv8-theme/THEME_GET_FAILURE';

export const THEME_CREATE_REQUEST = 'rsv8-theme/THEME_CREATE_REQUEST';
export const THEME_CREATE_SUCCESS = 'rsv8-theme/THEME_CREATE_SUCCESS';
export const THEME_CREATE_FAILURE = 'rsv8-theme/THEME_CREATE_FAILURE';

export const THEME_UPDATE_REQUEST = 'rsv8-theme/THEME_UPDATE_REQUEST';
export const THEME_UPDATE_SUCCESS = 'rsv8-theme/THEME_UPDATE_SUCCESS';
export const THEME_UPDATE_FAILURE = 'rsv8-theme/THEME_UPDATE_FAILURE';
