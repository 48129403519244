import * as React from 'react';
import {
  accountStyles as styles,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow
  } from 'rsv8-components';
import styled from 'styled-components';
import * as utils from '../../../utils';

const DesktopTableContainer = styled(styles.DesktopTableContainer)`
  th, td {
    text-align: left !important;
  }
`;
interface DesktopTableProps {
  data: any;
  onSearch?: any;
  onSort?: any;
  setShowStatusModal: any
}

const DesktopTable = ({
  data,
  onSearch,
  onSort,
  setShowStatusModal,
  ...rest
}: DesktopTableProps) => {

  const getRedemptionHistoryDisplayStatus = (status) => {
    switch (status.toLocaleLowerCase()) {
      case "delivered":
        return "Delivered";
      case "cashed":
        return "Cashed";
      case "mailed":
        return "Mailed";
      case "applied":
        return "Applied";
      case "shipped":
        return "Shipped";
      case "in_process":
        return "In Process";
      case "complete":
        return "Complete";
      default:
        return status;
    }
  }

  const getCurrencyValue = (value: any) => {
    const showValue = value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
    return utils.getDecimalValue(showValue);
  };

  const getDateValue = (value: any) => {
    if (value !== undefined) {
      const date = new Date(value);
      return date.toLocaleDateString("en-US");
    } else {
      return value;
    }
  };

  function renderRows(items: any = []) {
    return items.map((item, index) => (
      <TableRow key={index} style={{ height: '60px' }}>
        <TableCell>{item.redeemOption}</TableCell>
        <TableCell>{getDateValue(item.redemptionDate)}</TableCell>
        <TableCell>{item.quantity}</TableCell>
        <TableCell>{getCurrencyValue(item.faceValue)}</TableCell>
        <TableCell>{getCurrencyValue(item.amount)}</TableCell>
        <TableCell onClick={(e) => setShowStatusModal(item.redeemOptionKey)}><styles.DesktopTableLinkText>{getRedemptionHistoryDisplayStatus(item.status)}</styles.DesktopTableLinkText></TableCell>
        <TableCell>{item.id}</TableCell>
      </TableRow>
    ));
  }

  return (
    <DesktopTableContainer>
      {data !== undefined && data.length > 0 ? (
        <Table {...rest} striped={false} bordered={false} hover={true} condensed={true} onSort={onSort}>
          <thead>
            <TableRow>
              <TableHeaderCell field="rebateOption" sortable={false}>Rebate Option</TableHeaderCell>
              <TableHeaderCell field="date">Date</TableHeaderCell>
              <TableHeaderCell field="quantity">Quantity</TableHeaderCell>
              <TableHeaderCell field="value">Value</TableHeaderCell>
              <TableHeaderCell field="totalRedemptionAmount">Total Redemption Amount</TableHeaderCell>
              <TableHeaderCell field="status">Status</TableHeaderCell>
              <TableHeaderCell field="confirmation">Confirmation</TableHeaderCell>
            </TableRow>
          </thead>
          <tbody>
            {renderRows(data)}
          </tbody>
        </Table>
      ) : <styles.NoDataContainer>Rebates redeemed from the Rebate Options page will appear here</styles.NoDataContainer>}
    </DesktopTableContainer>
  );
};

export default DesktopTable;