import * as Icon from 'react-fontawesome';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 5rem 0;
`;

export const Chevrons = styled(Icon)<{ isVisible: boolean }>`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #2f82ab;
  cursor: pointer;
`;

export const Dot = styled.button<{ isSelected: boolean }>`
  background-color: transparent;
  border: 1px solid rgba(190, 190, 190, 0.5) !important;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  padding: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
  outline: none;

  &:after {
    background-color: #efefef;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    margin: 0 auto;
    content: '';
  }

  ${(props) =>
    props.isSelected &&
    `
    border-color: #2f82ab !important;
    
    &:after {
      background-color: #2f82ab;
    }
  `}
`;
