import OAuthService from '../services/OAuthService';
import { userStateSelectors } from '../module';
import { getReduxStore } from 'xcel-redux-module';

const clientMiddleware = {
  request: ({ request }) => ({ reject, next, resolve }) => {
    let clientId;
    const store = getReduxStore();
    if (store) {
      const state = store.getState();
      const user = userStateSelectors.getUser(state);
      if (user && user.clientId) {
        clientId = user.clientId;
      }
    }

    if (!clientId) {
      const identityServerConfigData = OAuthService.getIdentityServerConfigData();
      if (identityServerConfigData && identityServerConfigData.apiHost.clientId) {
        clientId = identityServerConfigData.apiHost.clientId;
      }
    }

    if (clientId) {
      request.headers = {
        ...request.headers,
        clientId
      };
      next();
    } else {
      next();
    }
  }
};

export default clientMiddleware;
