import { Col } from 'react-bootstrap';
import { HeaderTwo } from 'rsv8-components';
import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 20px;
  }
` as any;

export const BrowseCard = styled.div`
  border-radius: 3px;
  box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.09);
  height: auto;
  margin-bottom: 25px;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  transition: background-color 0.25s ease;

  &:hover {
    background-color: #eef5f7;
  }
` as any;

// I hate how well this works... Oh well.
// Maybe they'll solve it in Flexbox 2
export const PhantomCard = styled.div`
  display: ${(props: any) => {
    if(props.index === 1) {
      return 'block';
    } else {
      return 'none';
    }
  }};
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis:calc(50% - 1rem);

  @media (min-width: 769px) and (max-width: 992px) {
    display: ${(props: any) => {
      if(props.index === 1 || props.index === 2) {
        return 'block';
      } else {
        return 'none';
      }
    }};

    flex-basis: calc(32% - 1rem);
  }

  @media (min-width: 993px) {
    display: block;

    flex-basis: calc(24% - 1rem);
  }
`as any;

export const Card = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(24% - 1rem);
    

  @media (max-width: 768px) {
    flex-basis: calc(50% - 1rem);
  }
  @media (min-width: 769px) and (max-width: 992px) {
    flex-basis: calc(32% - 1rem);
  }
`as any;

export const CardContent = styled.div`
  align-items: center;
  border-radius: 0;
  border: 0;
  color: #4c4f4f;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 115px;
  justify-content: center;
  text-align: center;

  .panel-body {
    padding: 0;
  }
` as any;

export const BrowseTitle = styled(HeaderTwo)`
  margin-left: 20px;
` as any;

export const StyledCol = styled(Col)`
  margin-top: 40px;
  margin-right: 8.33333333%;
` as any;
