import { ValidationProvider } from 'rsv8-forms';
import * as validations from './validations';

export default () => {
  ValidationProvider.addValidation('lengthGTE8', (value) => {
    if (!validations.lengthGTE8(value || '')) {
      return 'Password must be longer than 8 characters.';
    } else return undefined;
  });

  ValidationProvider.addValidation('hasUpperCase', (value) => {
    if (!validations.hasUpperCase(value)) {
      return 'Password must contain an uppercase character.';
    } else return undefined;
  });

  ValidationProvider.addValidation('hasNumber', (value) => {
    if (!validations.hasNumber(value)) {
      return 'Password must contain a number.';
    } else return undefined;
  });

  ValidationProvider.addValidation('matchesPassword', (value, allValues) => {
    if (value !== allValues.password) {
      return 'Passwords do not match.';
    } else return undefined;
  });
};
