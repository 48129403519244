import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FabButton } from 'rsv8-components';
import { socialSelector } from 'rsv8-cms';
import { ReactField } from 'rsv8-forms';

const {
  activityTypes
} = socialSelector;

/**
 * This class has the mandatory fields for creating a new activity from API
 */
class ActivityPayload {
  name = "";
  title = "";
  description = "";
  content = [];
  
  changeTitle(label: string) {
    this.name = this.title = label;
  }
};

export interface Props {
  activityTypes: Array<any>;
  createActivity: any
}

class CreateActivityModal extends React.Component<Props> {
  state = {
    isModalOpen: false,
    currentType: '',
    payload: new ActivityPayload()
  };
  
  private findActivityTypeId(option) {
    const { activityTypes } = this.props;
    const { value } = activityTypes.find(type => option === type.value);
    
    return value;
  }
  
  displayModal(show: boolean) {
    this.setState({isModalOpen: show});
  }
  
  onNameChange = input => {
    const { payload } = this.state;
    
    payload.changeTitle(input);
    this.setState({payload});
  }
  
  onTypeChange = option => {    
    this.setState({currentType: this.findActivityTypeId(option)});
  }
  
  onCreateActivity = () => {
    const { createActivity } = this.props;
    let { payload, currentType } = this.state;
    
    createActivity(currentType, payload);
  }
  
  componentWillReceiveProps(nextProps) {
    const { currentType } = this.state;
    const { activityTypes } = nextProps;
    // Get the first activity type as default value
    if(!currentType.length && activityTypes.length) {
      this.setState({currentType: activityTypes[0].value});
    }
  }
  
  render() {
    const { activityTypes } = this.props;
    const { currentType } = this.state;
    return (
      <React.Fragment>
        
        <FabButton
          className="btn btn-primary"
          onClick={this.displayModal.bind(this, true)}>
          <i className="fa fa-plus" />
        </FabButton>
        
        <Modal
          show={this.state.isModalOpen}
          onHide={this.displayModal.bind(this, false)}
          bsSize="small">
          
          <Modal.Header closeButton={true}>
            <Modal.Title>Create Activity</Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            <ReactField
              label="Activity Name"
              placeholder="Type Activity Name"
              component="bs-input"
              autoFocus={true}
              onChange={this.onNameChange}
            />
            <ReactField
              label="Activity Type"
              component="bs-select"
              options={activityTypes}
              onChange={this.onTypeChange}
              value={currentType}
            />
          </Modal.Body>
          
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.onCreateActivity}>
              Create
            </Button>
          </Modal.Footer>
          
        </Modal>
        
      </React.Fragment>
    );
  }
};

const mapStateToProps = state => ({
  activityTypes: activityTypes(state)
});

export default connect(mapStateToProps)(CreateActivityModal);