import * as React from 'react';
import { compose } from 'redux';
import * as styles from './styles'
import {    
  Modal,
  ModalHeader,
  ModalBody  
  } from 'rsv8-components';

import { withResource } from 'xcel-react-core';

export interface Props {
    show: boolean;
    handleHide: Function;
    image: string;
    value: string;
    quantity: string;
    orderTotal: string;
    confirmationNumber: string;   
}

class RebateModal extends React.Component<Props> {       

    render() {
        const {image, value, quantity, orderTotal, confirmationNumber} = this.props;

        return (            
          <Modal show={true}>
              <ModalHeader closeButton={false} />            
            <ModalBody>
                <styles.ModalBodyContainer>
                  <styles.OrderSummaryHeader>Order Summary</styles.OrderSummaryHeader>
                  <styles.RedemptionImage src={image} />
                  { value !== null 
                    ? (
                        <div>
                          <div>Amount</div>
                          <styles.AmountValue>{value}</styles.AmountValue>
                        </div>
                      )
                    : null
                  }
                  { quantity !== null 
                    ? (
                        <div>
                          <div>Quantity</div>
                          <styles.QuantityValue>{quantity}</styles.QuantityValue>
                        </div>
                      )
                    : null
                  }
                  <div>
                    <div>Order Total</div>
                    <styles.OrderTotalValue>{orderTotal}</styles.OrderTotalValue>
                  </div>
                  <div>
                    <div>Confirmation Number</div>
                    <styles.ConfirmationNumberValue>{confirmationNumber}</styles.ConfirmationNumberValue>                                 
                  </div>
                  <styles.RebateOptionsButton href="/rebate-options">Rebate Options</styles.RebateOptionsButton>
                  <styles.DashboardButton href="/home">Return to Dashboard</styles.DashboardButton>
                </styles.ModalBodyContainer>
            </ModalBody>            
          </Modal>
        );
    }    
}
export default compose(withResource())(RebateModal as any);

