import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux/';
const catalogModule: ReduxModule = createReduxModule({
  name: 'rsv8-catalog',
  reducers
});

reduxObserver.addModule(catalogModule);
export default catalogModule;
