import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  HeaderDecoration,
  HeaderOne,
  Image,
  Link,
  Text
  } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';

const ResetPasswordSent = ({
  imageURI,
  loginHelpLinkPath,
  resource: { headerText, checkEmailText, contactText, contactEmail, contactOrText, backToLoginPageText }
}) => (
  <div>
    <HeaderDecoration />
    <Col xs={22} xsOffset={1} lg={22} lgOffset={1} md={22} mdOffset={1}>
      <Image themeVariation="primary-company-logo1" />
    </Col>
    <LayoutContainer>
      <Row>
        <Col xs={22} xsOffset={1} lg={8} lgOffset={8} md={7} mdOffset={8}>
          <Image themeVariation="icon-email-sent" />
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={18} mdOffset={4}>
          <HeaderOne textalign="center">{headerText}</HeaderOne>
        </Col>
      </Row>
      <Row>
        <Col xs={22} xsOffset={1} lg={12} lgOffset={6} md={18} mdOffset={4}>
          <Text textalign="center" display="block">
            {checkEmailText}
            <br />
            {contactText} <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link> {contactOrText}
          </Text>
        </Col>
      </Row>
      <Row>
        <br />
        <Link href={loginHelpLinkPath} textalign="center" display="block">
          {backToLoginPageText}
        </Link>
      </Row>
    </LayoutContainer>
  </div>
);

const mapState = () => ({});

const mapDispatch = (dispatch) => ({});

const mapResourceToProps = (getResource) => ({
  resource: {
    headerText: getResource('resetPasswordSent.headerText', 'A password reset link was emailed to you!'),
    checkEmailText: getResource(
      'resetPasswordSent.checkEmailText',
      'Check your email address associated with your account. We sent you a link to reset your password.'
    ),
    contactText: getResource(
      'resetPasswordSent.contactText',
      'Never received your email? Contact your HR Service Center at '
    ),
    contactOrText: getResource(
      'resetPasswordSent.contactOrText',
      'or calling 888-402-7286 in the United States or at + 1-770-668-2436 outside of the United States.'
    ),
    backToLoginPageText: getResource('resetPasswordSent.backToLoginPageText', 'Back to the login page >'),
    contactEmail: getResource('contactUs.contactEmail', 'support@rewardstation-live.net')
  }
});

export default compose(
  withResource(mapResourceToProps),
  connect(
    mapState,
    mapDispatch
  )
)(ResetPasswordSent) as any;
