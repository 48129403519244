import { path } from 'ramda';
import { ClientCountryModel } from 'xcel-api-generator/dist/user';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { ROOT_STATE } from '../constants';
import clientSelector from './client';

const countrySelector = {
  ...createTypeSelector<ClientCountryModel>('clientCountryModel'),
  getAll: (state) => clientSelector.getActive(state).countries as ClientCountryModel[],
  getActive: (state) => path([ROOT_STATE, 'country', 'active'], state) as ClientCountryModel
};
export { ClientCountryModel, Resource };
export default countrySelector;
