import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getValidationsAndTypes } from '../../redux/actions';

const loadCustomFields = BaseComponent => {
  class ConnectedComponent extends React.Component<any, any> {
    componentWillMount() {
      this.props.actions.getValidationsAndTypes();
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  const mapDispatch = dispatch => ({
    actions: bindActionCreators({ getValidationsAndTypes }, dispatch)
  });

  return connect(null, mapDispatch)(ConnectedComponent);
};

export default loadCustomFields;
