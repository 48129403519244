import { toast } from 'rsv8-components';
import { destroyRequest } from 'xcel-redux-orm';
import { GET_USER_FAVORITES_ENDPOINT } from '../../../constants';
import * as events from '../../events';
import { getFavoriteProducts } from './getFavorites';
import { productApiActions } from './productApiActions';

export const addUndoFavorite = (response) => ({ type: events.ADD_UNDO_FAVORITE, payload: response });
export const removeUndoFavorite = (id) => ({ type: events.REMOVE_UNDO_FAVORITE, payload: id });

const deleteFavorite = (product, options?) => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest(GET_USER_FAVORITES_ENDPOINT));
    await dispatch(
      productApiActions.deleteProductsListsByIdAndProductId(
        {
          id: 'favorite',
          productId: btoa(product.productId)
        },
        { jsonApi: true }
      )
    );

    await dispatch(getFavoriteProducts({ id: 'favorite' }));
    if (options && options.undo) {
      dispatch(addUndoFavorite(product.id));
    }
    toast({
      template: 'CatalogBody',
      productImage: product.images && product.images[0] && product.images[0].imageSm ? product.images[0].imageSm : null,
      productName: product.name || product.productName,
      productManufacturer: product.manufacturer,
      actionText: `has been removed from Favorites`
    });
  } catch (e) {
    throw e;
  }
};

const resetUndoList = () => ({ type: events.RESET_UNDO_FAVORITE });

export { deleteFavorite, resetUndoList };
