import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SolidGauge from 'highcharts/modules/solid-gauge';
import * as React from 'react';
import styled from 'styled-components';

SolidGauge(Highcharts);

const ChartContainer = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;

  @media (min-width: 1366px) {
    .chart-container {
      height: 500px;
    }
  }
`;

interface Props {
  maxValue: number;
  margin: number;
  rebate: number;
  discount: number;
  totalValue: number;
  goalValue: number;

  lineWidth?: number;
  lineBackgroundColor?: string;
  tickColor?: string;
  minorTickColor?: string;
  tickInterval?: number;
  minorTickInterval?: number;

  totalValueColor?: string;
  marginColor?: string;
  rebateColor?: string;
  costSavingsColor?: string;
  goalColor?: string;
  goalLabel?: string;

  fontFamily?: string;
  labelColor?: string;
}

const ProjectionsChart: React.FC<Props> = ({
  maxValue = 20000,
  margin = 0,
  rebate = 0,
  discount = 0,
  totalValue = 0,
  goalValue,

  lineWidth = 10,
  lineBackgroundColor = '#efefef',

  tickColor = '#505d6f',
  minorTickColor = '#D9D9D9',
  tickInterval = 2500,
  minorTickInterval = 500,

  totalValueColor = '#003595',
  marginColor = '#2C9D9D',
  rebateColor = '#00AEEF',
  costSavingsColor = '#AE6CD8',
  goalColor = '#00AE44',
  goalLabel = 'Total Value Goal',

  fontFamily = '"Open Sans", sans-serif',
  labelColor = '#505d6f',
}) => {
  // Data preparation
  const chartSettings = [
    {
      name: 'Total Value',
      radius: 100,
      color: totalValueColor,
      value: totalValue,
    },
    {
      name: 'Margin',
      radius: 85,
      color: marginColor,
      value: margin,
    },
    {
      name: 'Rebate',
      radius: 70,
      color: rebateColor,
      value: rebate,
    },
    {
      name: 'Price Discounts',
      radius: 55,
      color: costSavingsColor,
      value: discount,
    },
  ];
  const background = [];
  const series = [];

  // Prepare background
  chartSettings.map((setting) => {
    background.push({
      borderWidth: lineWidth,
      backgroundColor: 'transparent',
      borderColor: lineBackgroundColor,
      shape: 'arc',
      outerRadius: `${setting.radius - 8}%`,
      innerRadius: `${setting.radius + 1}%`,
    });
  });

  // Fill current values
  chartSettings.map((setting) => {
    series.push({
      name: setting.name,
      showInLegend: true,
      events: {
        legendItemClick() {
          return false;
        },
      },
      data: [
        {
          color: setting.color,
          radius: `${setting.radius + lineWidth / 2}%`,
          innerRadius: `${setting.radius - lineWidth / 2}%`,
          y: setting.value,
        },
      ],
    });
  });

  // Fill goals
  if (goalValue > 0) {
    series.push({
      name: goalLabel,
      data: [goalValue],
      type: 'gauge',
      dial: {
        baseLength: `${chartSettings[0].radius}%`,
        radius: `${chartSettings[0].radius - 5}%`,
        rearLength: `-${chartSettings[0].radius + 10}%`,
        color: 'transparent',
        backgroundColor: goalColor,
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      pivot: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
    });
  }

  // Chart Options
  const options = {
    chart: {
      type: 'solidgauge',
      spacing: [20, 20, 0, 20],
      events: {
        load() {
          for (const el of this.pane[0].group.element.getElementsByTagName('path')) {
            el.setAttributeNS(null, 'stroke-linejoin', 'round');
          }
        },
      },
    },
    plotOptions: {
      series: {
        colorByPoint: false,
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        rounded: true,
      },
    },
    title: null,
    exporting: {
      enabled: false,
    },
    tooltip: {
      followPointer: true,
      borderRadius: 10,
      borderWidth: 0,
      padding: 0,
      style: {
        color: labelColor,
        fontFamily: fontFamily,
        fontSize: '12px',
        lineHeight: '24px',
        fontWeight: '400',
        textAlign: 'center',
      },
      formatter: function(tooltip) {
        return `<div style='padding: 10px 32px;'>${this.series.name}<br/><span style='color: ${
          this.series.color
        }; font-size: 18px; font-weight: 700;'>${Number(this.point.y).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        })}</span></div>`;
      },
      useHTML: true,
    },
    pane: {
      startAngle: -120,
      endAngle: 120,
      background,
    },
    credits: {
      enabled: false,
    },

    colors: chartSettings.map((setting) => setting.color),

    legend: {
      layout: 'vertical',
      margin: -200,
      y: -70,
      labelFormatter: function() {
        return `<span style='font-family: ${fontFamily}; font-size: 12px; line-height: 14px;'>${this.name}</span>`;
      },
    },

    yAxis: {
      min: 0,
      max: maxValue,
      endOnTick: false,
      offset: lineWidth,
      tickPosition: 'outside',
      minorTickPosition: 'outside',
      tickColor: tickColor,
      minorTickColor: minorTickColor,
      tickLength: lineWidth,
      minorTickLength: lineWidth,
      tickWidth: 2,
      minorTickWidth: 2,
      tickInterval,
      minorTickInterval,
      labels: {
        distance: lineWidth * 2,
        rotation: 'auto',
        style: {
          color: labelColor,
          fontFamily,
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: '700',
        },
        formatter: (ctx) => {
          return Number(ctx.value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          });
        },
      },
      lineWidth: 0,
    },

    series,
  };

  return (
    <ChartContainer>
      <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ className: 'chart-container' }} />
    </ChartContainer>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  return (
    prevProps.totalValue === nextProps.totalValue &&
    prevProps.margin === nextProps.margin &&
    prevProps.rebate === nextProps.rebate &&
    prevProps.discount === nextProps.discount &&
    prevProps.goalValue === nextProps.goalValue
  );
}

export default React.memo(ProjectionsChart, areEqual);
