import * as React from 'react';
import { connect } from 'react-redux';
import { cultureSelector } from 'rsv8-client';
import { register, withContent, withTheme } from 'xcel-react-core';
import { getAchievementProgramLevels } from "../../redux/selectors";
import {
    AchievementLevelCheckIcon,
    AchievementLevelCircle,
    AchievementLevelCircleContent,
    AchievementLevelCirclesRow,
    AchievementLevelsContent
} from "../AchievementLevelsList/styles";
import {ProgramLevel} from "xcel-api-generator/dist/achievement";
import cn from "classnames";
import {Image} from "rsv8-components";

export interface AchievementsHomepageLevelProps {
    cultureId: string;
    className?: string;
    program: any;
    programLevels: Array<any>;
    getAchievementProgramsByIdLevel: (params?: any) => void;
}

class AchievementsHomepageLevel extends React.PureComponent<AchievementsHomepageLevelProps> {
    getSortedLevels() {
        const achieved = [];
        const redeemed = [];
        const other = [];
        this.props.program.levels.forEach(level => {
            if (level.achievedDate || level.redeemedDate) {
                achieved.push(level);
            } else {
                other.push(level);
            }
        });
        return { achieved, redeemed, other };
    }

    render() {
        const renderLevel = (level: ProgramLevel) => {
            const hasAchievedDate = !!level.achievedDate;
            const hasRedeemedDate = !!level.redeemedDate;
            const isAchieved = level.redeemedDate || level.achievedDate;

            const classNames = cn({
                achieved_and_redeemed: hasAchievedDate && hasRedeemedDate,
                achieved_not_redeemed: hasAchievedDate && !hasRedeemedDate,
                next_to_be_achieved: !hasAchievedDate && !hasRedeemedDate,
                //not_yet_achieved: !hasAchievedDate && !hasRedeemedDate,
            });

            return (
                <AchievementLevelCircle key={level.id} className={classNames}>
                    {isAchieved && (
                        <AchievementLevelCheckIcon>
                            <Image themeVariation="checkmark-white" />
                        </AchievementLevelCheckIcon>
                    )}
                    <AchievementLevelCircleContent className="level-circle-content">{level.name}</AchievementLevelCircleContent>
                </AchievementLevelCircle>
            );
        };

        const { achieved, redeemed, other } = this.getSortedLevels();

        const levels=[...redeemed, ...achieved, ...other];

        return (
            <AchievementLevelsContent>
                <AchievementLevelCirclesRow>{levels.map(renderLevel)}</AchievementLevelCirclesRow>
            </AchievementLevelsContent>
        );
    }
}

const mapStateToProps = (state) => ({
    cultureId: cultureSelector.getActiveCultureId(state),
    programLevels: getAchievementProgramLevels(state)
});

const mapContentToProps = (getContent) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default register('rsv8-achievements/AchievementsHomepageLevel')(
    withTheme(),
    withContent(mapContentToProps),
    connect(mapStateToProps, mapDispatchToProps)
)(AchievementsHomepageLevel);
