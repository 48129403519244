import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'rsv8-forms';

interface AssignMenuItemFormProps {
  navigationMenuAdmin: any;
  item: any;
  actions: {
    deleteDataruleGroupsByGroupIdMenuAndMenuId: any;
    createDataruleGroupsMenu: any;
    getDataruleGroupsByGroupIdMenu: any;
  };
  clientId: any;
  handleSubmit: any;
  groupOptions: any;
  pristine: any;
  submitting: any;
}

class AssignMenuItemForm extends React.Component<AssignMenuItemFormProps, any> {
  submitEdit = (values) => {
    let { groupMenuItems } = this.props.navigationMenuAdmin;
    //console.log('groupMenuItems', groupMenuItems);
    //{group} are the id's of the menu items submitted
    let { group } = values;

    //groupId is the ID of the group we just clicked
    let gropuId = this.props.item.id;

    console.log('values', values.group);
    groupMenuItems.forEach((items) => {
      if (!values.group.includes(items)) {
        console.log('busted', gropuId, items);
        this.props.actions.deleteDataruleGroupsByGroupIdMenuAndMenuId(gropuId, items);
      }
    });

    group.forEach((rulesId) => {
      this.props.actions.createDataruleGroupsMenu(this.props.clientId, rulesId, gropuId);
      /*DataRule.forEach((dataRule, i) => {
        console.log(dataRule)
        if (DataRule[i].id === parseInt(rulesId)) {
          this.props.navigationMenuAdmin.actions.updateDatarule(DataRule[i], gropuId)
        } else {
          'NOTHING'
        }
      })*/
    });
  };

  componentDidMount() {
    console.log(this.props);
    this.props.actions.getDataruleGroupsByGroupIdMenu(this.props).then((response) => {
      this.props.navigationMenuAdmin.loadedMenuTypesDataRules = true;
      console.log(this.props.navigationMenuAdmin.loadedMenuTypesDataRules);
    });
  }

  componentWillUnmount() {
    this.props.navigationMenuAdmin.loadedMenuTypesDataRules = false;
  }
  /*setGroupsMenuItems = (ownProps) => {
    let { actions, item, clientId } = ownProps;
    let getGroupsMenuItems = actions.getDataruleGroupsByGroupIdMenu(item.id, clientId).then(response => {
      let preselectedValues = [];
      response.map(items => {
        preselectedValues.push(items.menuId.toString());
      })
      return preselectedValues;
    })
    return getGroupsMenuItems;
  };

  removeMenuItem() {

  }*/

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.submitEdit)}>
        <div className="form-group">
          <label>
            Title <sup>*</sup>
          </label>

          <div>
            <div>
              <Field
                name="group"
                component="checkbox-group"
                options={this.props.groupOptions}
                onClick={() => console.log()}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            style={{ marginRight: '15px' }}
            disabled={this.props.pristine || this.props.submitting}
          >
            Save
          </button>
        </div>
        <div className="error-report" />
      </form>
    );
  }
}

const ConnectedForm = reduxForm({ form: 'AssignMenuItemForm', enableReinitialize: true })(AssignMenuItemForm as any);

/*this sets our initial values that populate the raid buttons for the rules to select
We use ownProps here to grab the selceted DataRuleGroup*/

let initialRuleValues = (ownProps) => {
  let { items } = ownProps.navigationMenuAdmin;
  let flatMenu = flattenMenu(items);
  let dataRulesMenu = [];
  flatMenu.map((item) => {
    item.value = item.id.toString();
    if (item.providerId === 1) {
      dataRulesMenu.push(item);
    }
  });

  return dataRulesMenu;
};

let flattenMenu = (menutree) => {
  let flattenedMenu = [];
  menutree.forEach((item, i) => {
    if (item.children.length) {
      let child = item.children;
      child.forEach((item) => {
        flattenedMenu.push(item);
      });
    }
    flattenedMenu.push(item);
  });
  return flattenedMenu;
};

/*This cycles through the dataRuleGroups and finds the mtaching dataRuleGroupId 
from the data rules*/

const mapState = (ownProps) => ({
  groupOptions: initialRuleValues(ownProps),
  initialValues: {
    group: ownProps.navigationMenuAdmin.groupMenuItems
  }
});

export default connect(
  mapState,
  null
)(ConnectedForm) as any;
