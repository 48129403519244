import * as events from '../events';

const cultureReducer = (state, action) => {
  if (state === undefined) state = { active: {} };
  switch (action.type) {
    case events.CULTURE_SET: {
      return { ...state, active: action.payload };
    }
    default:
      return state;
  }
};
export default cultureReducer;
