import * as React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Loader } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import styled from 'styled-components';
import { FieldLabel } from '../Label/Label';
import { USStates } from './USStates';

const SaveButton = styled(Button)`
  margin-top: 2rem;
`;
const FormGroup = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    .form-group {
      display: inline-block;
      width: 50%;
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }
`;
const ErrorContainer = styled.div`
  background-color: #eed3d7;
  border-radius: 4px;
  border: solid 1px #d8374b;
  color: #4c4f4f;
  padding: 15px 15px 5px;
`;
const FormContainer = styled.div`
  select.form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #0c0c0c;
    box-shadow: none;
    &:focus {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    }
  }
  input.form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #0c0c0c;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    box-shadow: none;
    &:focus {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    }
  }
`;
export const ButtonLoader = styled(Loader)`
  padding: 2px;
`;

interface EditInfoProps {
  acceptLabel: string;
  addressOneLabel: string;
  cancelLabel: string;
  cityLabel: string;
  countryLabel: string;
  error?: any;
  firstNameLabel: string;
  handleSubmit: any;
  lastNameLabel: string;
  postalCodeLabel: string;
  saveInfo: any;
  saveLabel: string;
  shippingInfoHeading: string;
  stateLabel: string;
  userProfile: any;
  provinceLabel: string;
  tcLabel: string;
  tcLink: string;
  isWaiting?: boolean;
}

const EditInfo: React.FC<EditInfoProps> = ({
  acceptLabel,
  addressOneLabel,
  cityLabel,
  countryLabel,
  error,
  firstNameLabel,
  lastNameLabel,
  postalCodeLabel,
  saveInfo,
  saveLabel,
  stateLabel,
  userProfile,
  provinceLabel,
  tcLabel,
  tcLink,
  handleSubmit,
  isWaiting
}) => {
  const [isAccepted, setIsAccepted] = React.useState(false);
  return (
    <div>
      {error && <ErrorContainer dangerouslySetInnerHTML={{ __html: error }} />}
      <FormContainer>
        <Form onSubmit={handleSubmit(saveInfo)}>
          <FormGroup>
            <Field
              component="bs-input"
              name="firstName"
              type="text"
              label={<FieldLabel required={true} labelName={firstNameLabel}/>}
            />
            <Field
              component="bs-input"
              name="lastName"
              type="text"
              label={<FieldLabel required={true} labelName={lastNameLabel}/>}
            />
          </FormGroup>

          <Field
            component="bs-input"
            name="address1"
            type="text"
            label={<FieldLabel required={true} labelName={addressOneLabel}/>}
          />

          <FormGroup>
            <Field
              component="bs-input"
              name="city"
              type="text"
              label={<FieldLabel required={true} labelName={cityLabel}/>}
            />
            {userProfile.countryId === 226 ? (
              <Field
                component="bs-select"
                name="stateProvince"
                label={<FieldLabel required={true} labelName={stateLabel}/>}
                options={USStates}
              />
            ) : (
              <Field
                component="bs-input"
                name="province"
                type="text"
                label={<FieldLabel labelName={provinceLabel}/>}
              />
            )}
          </FormGroup>

          <FormGroup>
            <Field
              component="bs-input"
              name="country"
              type="text"
              label={<FieldLabel required={true} labelName={countryLabel}/>}
              disabled={true}
            />
            <Field
              component="bs-input"
              name="postalCode"
              type="text"
              label={<FieldLabel required={true} labelName={postalCodeLabel}/>}
            />
          </FormGroup>

          <Field
            component="checkbox"
            name="accept"
            onChange={({ target }) => setIsAccepted(target.checked)}
            type="checkbox"
            label={
              <FieldLabel isCheckbox={true}>
                {acceptLabel} <a href={tcLink} target="_blank">{tcLabel}</a>.
              </FieldLabel>
            }
          />

          <SaveButton themeVariation="secondary-small-full-width" disabled={!isAccepted || isWaiting} type="submit">
            {isWaiting ? <ButtonLoader width={20} strokeWidth={2} /> : saveLabel}
          </SaveButton>
        </Form>
      </FormContainer>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    firstName: ownProps.userProfile.firstName,
    lastName: ownProps.userProfile.lastName,
    address1: ownProps.userProfile.addressOne,
    city: ownProps.userProfile.city,
    stateProvince: ownProps.userProfile.state,
    postalCode: ownProps.userProfile.postalCode,
    country: ownProps.userProfile.country
  }
});

const mapResourceToProps = (getResource) => ({
  firstNameLabel: getResource('achievement.redeem.firstNameLabel', 'First Name'),
  saveLabel: getResource('achievement.redeem.saveLabel', 'Save'),
  lastNameLabel: getResource('achievement.redeem.lastNameLabel', 'Last Name'),
  addressOneLabel: getResource('achievement.redeem.addressOneLabel', 'Address'),
  cityLabel: getResource('achievement.redeem.cityLabel', 'City'),
  stateLabel: getResource('achievement.redeem.stateLabel', 'State/Province'),
  countryLabel: getResource('achievement.redeem.countryLabel', 'Country'),
  postalCodeLabel: getResource('achievement.redeem.postalCodeLabel', 'Postal Code'),
  provinceLabel: getResource('achievement.redeem.provinceLabel', 'Province'),
  acceptLabel: getResource('achievement.redeem.acceptLabel', 'I accept the Avon rewards'),
  tcLabel: getResource('achievement.redeem.tcLabel', 'Terms & Conditions'),
  tcLink: getResource('achievement.redeem.tcLink', ''),
});

export default compose<any>(
  connect(mapStateToProps),
  withResource(mapResourceToProps),
  reduxForm({
    form: 'OrderPersonalInfoForm' as any,
    enableReinitialize: true
  })
)(EditInfo);
