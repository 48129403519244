import { Button, Link } from 'rsv8-components';
import styled from 'styled-components';

export const ReviewItemsContainer = styled.div`` as any;

export const ReviewItem = styled.div`
  box-shadow: 0 2px 17px 2px rgba(28, 39, 76, 0.09);
  margin: 25px 0;
  padding: 25px 25px 20px 25px;

  @media (min-width: 769px) {
    margin-left: ${(props: any) => (props.confirmation ? '0' : '20px')};
  }
` as any;

export const ReviewItemImage = styled.img`
  height: 76px;
  width: 76px;
` as any;

export const ReviewItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  width: 100%;
` as any;

export const ReviewItemManufacturer = styled(Link)`
  color: #4192a5;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    outline: none !important;
    text-decoration: none;
  }
` as any;

export const ReviewItemProduct = styled.div`
  display: flex;
  margin-bottom: 15px;
` as any;

export const ReviewItemShip = styled.div`
  color: #7c8180;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: 10px;
` as any;

export const ReviewItemTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  padding: 0;
` as any;

export const ReviewItemLabel = styled.div`
  color: #4c4f4f;
  flex-grow: 1;
  font-weight: bold;
  margin-bottom: 15px;
  overflow: hidden;
  width: 33%;
` as any;

export const ReviewItemValue = styled.div`
  color: #4c4f4f;
  flex-grow: 1;
  margin-bottom: 15px;
  overflow: hidden;
  width: 67%;
` as any;

export const RemoveCartButton = styled(Button)`
  color: #4192a5;
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    outline: none !important;
    text-decoration: none;
  }
` as any;
