import * as React from 'react';
import { Link } from 'rsv8-components';
import { Context } from '../../../types';
import { withWizard } from '../withWizard';

export interface WizardLinkWrapperProps {
  name: string;
}

type Props = WizardLinkWrapperProps & { wizard: Context };

const LinkWrapper: React.SFC<Props> = ({ wizard, name, children, ...rest }) => {
  if (!wizard) {
    console.warn(
      "You're using a WizardLink outside of a Wizard. We're going to render it, but clicking won't do anything."
    );
  }
  if (wizard && wizard.steps.findIndex((s) => s.title === name) === -1) {
    console.warn("You've passed in an invalid step name.");
    return null;
  }
  if (wizard && wizard.steps.find((s) => s.title === name).skip) {
    return null;
  } else {
    const handleClick = (e) => {
      if (wizard) {
        wizard.forceWizardStep(name);
      } else {
        console.warn("You're clicking on a WizardLink without a Wizard");
      }
    };
    return (
      <Link onClick={handleClick} {...rest}>
        {children}
      </Link>
    );
  }
};

const WizardLinkWrapper = withWizard()(LinkWrapper) as React.SFC<WizardLinkWrapperProps>;

export default WizardLinkWrapper;
