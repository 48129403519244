import * as React from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGuage from 'highcharts/modules/solid-gauge';
import {getUserMetrics} from '../../redux/actions/';
import { bindActionCreators } from 'redux';
HighchartsMore(Highcharts);
SolidGuage(Highcharts);
const ROTATION_INDENT = 5;
/**
 * Props for Piechart
 */
export type Props = {
    backgroundColor: string;
    dotsPositions: string,
    dotsTexts: string,
    chartWidgetSize: string,
    lineWidth: string,
    total: string,
    value: string,
    circleColor: string,
    labelColor: string,
    labelFont: string,
    labelText: string,
    circleSize: string,
    smallCircleColor:string,
    smallCircleFontColor: string,
    programKey:string,
    metricKey:string,
    getUserMetrics:any,
    mymetrics:any,
    monthOffset:string,
    smallCircleColorBorderColor:string,

};

/**
 * The speed gauge
 * @param param0 
 */
const createChart = ({ props, el}) => {
       // console.log(prop)
    if (el === null) {
        return;
    }
    const lineWidth = 100 - (props.lineWidth || 10);

    Highcharts.chart(el, {
        chart: {
            type: 'solidgauge',
            backgroundColor: 'transparent',
            width: props.chartWidgetSize,
            height: props.chartWidgetSize,
            spacing: [0, 0, 0, 0]
        },
        title: null,
        credits: { enabled: false },
        tooltip: { enabled: false },
        pane: {
            size: '100%',
            startAngle: 0,
            endAngle: 360,
            background: [
                {
                    backgroundColor: props.backgroundColor || '#f3faff',
                    innerRadius: lineWidth + '%',
                    outerRadius: '100%',
                    borderWidth: 0
                }
            ]
        },
        plotOptions: {
            solidgauge: {
                innerRadius: lineWidth + '%',
                dataLabels: { enabled: false }
            }
        },
        yAxis: {
            stops: [
                [1, props.circleColor]
            ],
            lineWidth: 0,
            min: 0,
            max: parseInt(props.total, 10),
            tickPositions: props.tickPositions || [] // aren't used now
        },
        series: [
            {
                name: 'Milestones',
                data: [parseInt(props.value, 10)]
            }
        ]
    } as any);
};

/**
 * creates the chart container
 */
const ChartContainer = styled.div<{size: number}>`
  position: relative;
  display: inline-block;
  width: ${(props: any) => props.size}px;
  height: ${(props: any) => props.size}px;
`;

/**
 * Creates the child container inside the chart
 */
const ChildContainer = styled.div<{font: string}>`
  position: absolute;
  top: 50%;
  left: 50%;
  color: ${(props: any) => props.color};
  transform: translate(-50%, -50%);
  z-index: 1;
  font-weight: bold;
  font-size: 34px;
  font: ${(props: any) => props.font}
`;

/**
 * Creates dots
 */
const RotatedLine = styled.div<{smallCircleColor:string, smallCircleFontColor: string, size: number, rotate: number, text: string, circleSize: number, lineWidth: number,smallCircleColorBorderColor:string}>`
  position: absolute;
  top: calc(50% + ${(props: any) => props.lineWidth/2}px);
  left: calc(0% + ${(props: any) => props.lineWidth/2 - props.lineWidth/4}px);
  z-index: 3;
  width: ${(props: any) => props.size+3 - props.lineWidth/2}px;
  height: 2px;
  &:before {
    display: block;
    content: '${(props: any) => props.text}';
    transform: rotate(${(props: any) => -props.rotate - 90}deg);
    z-index: 2;
    width: ${(props: any) => props.circleSize}px;
    height: ${(props: any) => props.circleSize}px;
    color: ${(props: any) => props.smallCircleFontColor};
    font-size: 10px;
    line-height: 20px;
    background:${(props: any) => props.smallCircleColor};
    text-align: center;
    border-radius: 100%;
    border: 1px solid ;
    border-color:smallCircleColorBorderColor;
    top: calc(50% - ${(props: any) => props.circleSize/2}px);
    left: calc(50% - ${(props: any) => props.circleSize/2}px);
  }
`;

const DotsContainer = styled.div<{size: number, lineWidth: number}>`
    width: ${(props: any) => props.size - props.lineWidth}px;
    height: ${(props: any) => props.size - props.lineWidth}px;
    position: absolute;
    top: 0;
    left: 0;
`;

/**
 * creates a new date based off offset
 * @param monthOffset 
 */
const getNewDate = (monthOffset:string)=> {
    var today = new Date();
    var month = today.getMonth()+1+Number(monthOffset);
    var fullyear = today.getFullYear();
    // if December
    if(today.getMonth()+1 === 12 ) {
        // if offset is positive
        if(Number(monthOffset)>0) {
            month = Number(monthOffset);
            fullyear +=1;
       // if offset is negative
        } else if(Number(monthOffset)<0) {
            month = 12 + Number(monthOffset);

        }
        // if january
    } else if(today.getMonth()+1 === 1 ) {
         // if offset is positive
        if(Number(monthOffset)>0) {
            month = 1 + Number(monthOffset);
         // if offset is negative
        } else if(Number(monthOffset)<0) {
        month = 13 + Number(monthOffset);
        fullyear-=1;

                }

        }

    return {month:month,fullyear:fullyear,today:today}

}
/**
 * Main component that puts together the piechart
 * @param param0 
 */
const PieRadiusChart: React.FC<Props> = (
    {
        dotsPositions='0',
        dotsTexts='0',
        chartWidgetSize = '200',
        lineWidth = '35',
        total = '0',
        value = '0',
        labelText = '',
        circleColor = '#4b82a8',
        labelColor = '#4b82a8',
        labelFont = 'italic 39px Arial, serif',
        smallCircleColor='orange',
        smallCircleFontColor='#fff',
        circleSize = '22',
        programKey ="RFREWARDS",
        metricKey = "NEWPCORDERS",
        mymetrics,
        monthOffset='1',
        smallCircleColorBorderColor='white',
       
    }) => {
        const [values, setvalues] = React.useState('');
        React.useEffect(() => {
            // Using an IIFE
            (async function anyNameFunction() {
                try {
                    var newdateObject = getNewDate(monthOffset);
                    var date = newdateObject.month +'/'+newdateObject.today.getDate()+'/'+newdateObject.fullyear;
                     var data = await mymetrics.getUserMetrics({theprogramKey:programKey,themetricKey:metricKey,thedate:date}).then((response:any)=>response.data);
                    setvalues(data.attributes.value);
                    console.log(data);
                   } catch(error) {
                       console.log("error':"+ error);
                   }
            })();
          },[]);
          if(values) {
            value = values;
          }
          
    const dotsTextsArr = dotsTexts.split(',');
    const chartWidgetSizeNum: number = parseInt(chartWidgetSize, 10);
    const lineWidthNum: number = parseInt(lineWidth, 10);

    labelText = labelText.trim();

    return (
        <ChartContainer size={chartWidgetSizeNum}>
            <ChildContainer color={labelColor} font={labelFont.trim()}>
                {/*show label text if set*/}
                {Boolean(labelText.length) ?
                    labelText
                    :
                    <><span>{value}</span>/<span>{total}</span></>
                }
            </ChildContainer>

            <DotsContainer size={chartWidgetSizeNum} lineWidth={lineWidthNum}>
                {dotsPositions.split(',').map((position: string, index: number) => {
                    return (
                        <RotatedLine
                            key={index}
                            lineWidth={lineWidthNum}
                            text={dotsTextsArr[index]}
                            size={chartWidgetSizeNum}
                            circleSize={parseInt(circleSize, 10)}
                            smallCircleColor={smallCircleColor}
                            smallCircleFontColor={smallCircleFontColor}
                            smallCircleColorBorderColor = {smallCircleColorBorderColor}
                            rotate={parseInt(position, 10) + ROTATION_INDENT}
                            style={{transform: `rotate(${parseInt(position, 10) + ROTATION_INDENT + 90}deg)`}}
                        />
                    )
                })}
            </DotsContainer>

            <div ref={el => createChart({ el, props: { lineWidth, chartWidgetSize, total, value, circleColor} })} />
        </ChartContainer>
    );
};

export { PieRadiusChart }

/**
 * Content that the admin can enter to change this component
 * @param getContent 
 */
const mapContentToProps = (getContent) => ({
    metricKey: getContent('metricKey', {type: 'none', label: 'Metric Key'}),
    programKey: getContent('programKey', {type: 'none', label: 'Program Key'}),
    monthOffset: getContent('monthOffset', {type: 'none', label: 'Month Offset(Enter a Number between -12 and 12 '}),
    circleColor: getContent('circleColor', {type: 'none', label: 'Circle Color'}),
    labelColor: getContent('labelColor', {type: 'none', label: 'Label Color'}),
    labelText: getContent('labelText', {type: 'none', label: 'Label Text'}),
    labelFont: getContent('labelFont', {type: 'none', label: 'Font'}),
    smallCircleColor: getContent('smallCircleColor', {type: 'none', label: 'Small Circle Color'}),
    smallCircleFontColor: getContent('smallCircleFontColor', { type: 'none', label: 'Small Circle Font Color' }),
    smallCircleColorBorderColor: getContent('smallCircleColorBorderColor', {type: 'none', label: 'Small Circle  Border Color'}),
    circleSize: getContent('circleSize', {type: 'none', label: 'small Circle Size'}),
    chartWidgetSize: getContent('widget size', {type: 'none', label: 'Chart Size (value in px)'}),
    dotsPositions: getContent('dotsPositions', {type: 'none', label: 'Dots positions. Expected 0, 33, 90, 230 '}),
    dotsTexts: getContent('dotsTexts', {type: 'none', label: 'Dots texts. Expected 1, 2, 3, 4'}),
    total: getContent('total', {type: 'none', label: 'total number'})
});
/**
 * maps getmetrics to component so we can 
 * use it to grab data that you need
 * @param dispatch 
 */
const mapDispatchToProps = (dispatch) => ({
    mymetrics:bindActionCreators({getUserMetrics},dispatch),
  });
export default register('rsv8-metrics/PieRadiusChart')(connect(null, mapDispatchToProps),withTheme(), withContent(mapContentToProps))(PieRadiusChart);
