import styled from 'styled-components';
import { Collapse } from 'rsv8-components';

export const ModalBodyContainer = styled.div`
  font-family: open-sans,sans-serif;
  font-size: 15px;
  padding: 0 10px 50px 10px;
  text-align: center;

  .contentText {
    padding: 15px 125px;
    text-align: left;
  }

  @media only screen and (max-width: 760px) {
    .contentText {
      padding: 10px 40px 30px;
      text-align: center;
    }
  }

` as any;

export const ModalHeader = styled.div`
  font-size: 28px;
  font-family: 'open-sans-bold';
  margin-bottom: 10px;

  @media only screen and (max-width: 760px) {
    font-size: 25px;
  }
` as any;

export const ModalImage = styled.img`
  margin: 0 auto;
  text-align: center;
  width: 350px;
  margin: 5px 0 15px;

  @media only screen and (max-width: 760px) {
    width: 230px;
  }
` as any;

export const AccountRadio = styled.div`
  display: inline-flex;
` as any;

export const AccountDropdown = styled.div`
  display: inline-flex;
` as any;

export const ShowInvitationContent = styled.div`
  display: block;
` as any;

export const ShowConfirmationContent = styled.div`
  display: block;
` as any;

export const HideInvitationContent = styled.div`
  display: none;
` as any;

export const HideConfirmationContent = styled.div`
  display: none;
` as any;

export const StyledCollapse = styled(Collapse)`
  margin: 0 auto;
  width: 350px;
  padding: 5px 10px;
  :nth-last-child(1) {
    hr {
      display: none;
    }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 12px;
    padding: 0 10px;
    width: 275px;
  }
  }

  .collapse-header {
    background-color: #00AEEF !important;
    border-radius: 5px;
  }
  .collapse-body{
    padding: 15px !important;
    text-align: left;
  }
  .section{
    margin: 10px 0;
  }
  .category{
    color: #999;
    font-size: 13px;
  }
  .information{
    font-size: 12px;
  }
`;

export const ContinueButtonLink = styled.a`
  background-color: #003595;
  border-radius: 30px;
  border: 2px solid #003595;
  color: #ffffff;
  display: inline-block;
  font-family: open-sans;
  font-size: 15px;
  margin: 25px auto 15px;
  min-width: 275px;
  padding: 11px 25px;
  text-transform: uppercase;

  :hover {
    background-color: #ffffff;
    border: 2px solid #003595;;
    color: #003595;
    cursor: pointer;
    text-decoration: none;
  }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;

export const ContinueButton = styled.button`
  background-color: #003595;
  border-radius: 30px;
  border: 2px solid #003595;
  color: #ffffff;
  display: inline-block;
  font-family: open-sans;
  font-size: 15px;
  margin: 25px auto 15px;
  min-width: 275px;
  padding: 11px 25px;
  text-transform: uppercase;

  :hover {
    background-color: #ffffff;
    border: 2px solid #003595;;
    color: #003595;
    cursor: pointer;
    text-decoration: none;
  }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;

export const ContinueButtonDisabled = styled.button`
  background-color: #818181;
  border-radius: 30px;
  border: 2px solid #818181;
  color: #ffffff;
  cursor: not-allowed;
  display: inline-block;
  font-family: open-sans;
  font-size: 15px;
  margin: 25px auto 15px;
  min-width: 275px;
  padding: 11px 25px;
  text-transform: uppercase;

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;

export const OtherButton = styled.button`
  background-color: #ffffff;
  border-radius: 30px;
  border: 2px solid #00aeef;
  color: #00aeef;
  display: inline-block;
  font-family: open-sans;
  font-size: 15px;
  margin: 0 auto 15px;
  min-width: 275px;
  padding: 11px 25px;
  text-transform: uppercase;

  :hover {
    background-color: #00aeef;
    border: 2px solid #00aeef;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;

export const CustServButton = styled.a`
  background-color: #ffffff;
  border-radius: 30px;
  border: 2px solid #00aeef;
  color: #00aeef;
  display: inline-block;
  font-family: open-sans;
  font-size: 15px;
  margin: 0 auto 15px;
  min-width: 275px;
  padding: 11px 25px;
  text-transform: uppercase;

  :hover {
    background-color: #00aeef;
    border: 2px solid #00aeef;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  @media only screen and (max-width: 760px) {
    margin: 0 auto 10px;
    width: 215px;
  }
` as any;

