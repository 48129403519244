import * as React from 'react';
import { BackendResource } from 'rsv8-components';
import styled from 'styled-components';
import { register } from 'xcel-react-core';
import withThemedStatus from './withThemedStatus';

export interface StatusBlockProps {
  themeStyle: string;
}

const SpanBlock = styled.span`
  display: inline-block;
  width: 100%;
  border-radius: 2.6px;
  color: #ffffff;
  text-align: center;
  background-color: #aaa;
  font-size: 12px;
  line-height: 24px;
`;

const StatusBlock = ({ status, children, ...props }) => (
  <SpanBlock {...props}>{isFinite(status) ? children : <BackendResource resourceKey={status} />}</SpanBlock>
);

export default register('rsv8-nomination/NominationStatusBlock')(withThemedStatus)(StatusBlock);
