import { valueToKey } from '../util/valueToKey';
import themeCmsPlugin from './cms.plugin.actions';
import selector from './cms.plugin.selectors';
export const colorAdd = (color) => (dispatch, getState) => {
  color.name = valueToKey(color.name);
  dispatch(themeCmsPlugin.setProperty({ colors: { [color.name]: color } }));
};
export const colorUpdate = (color) => (dispatch, getState) => {
  color.name = valueToKey(color.name);
  dispatch(themeCmsPlugin.setProperty({ colors: { [color.name]: color } }));
};
export const colorRemove = (color) => (dispatch, getState) => {
  let colors = selector.getColors(getState());
  let key = Object.keys(colors).find((colorName: string) => colorName.toLowerCase() === color.name.toLowerCase());
  delete colors[key];
  dispatch(themeCmsPlugin.setProperty({ colors: { ...colors } }));
};
