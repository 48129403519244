import * as React from 'react';
import HoverContext from './HoverContext';
class HoverProvider extends React.Component<any, any> {
  active: any = {};
  leaveTimeoutId;
  enterTimeoutId;
  state: any = { active: undefined };
  constructor(props: any) {
    super(props);
    this.state = {
      onMouseOver: this.handleMouseOver,
      onMouseLeave: this.handleMouseLeave,
      isOver: this.isOver,
      getActive: this.getActive
    };
  }
  handleMouseOver = (data) => (e: MouseEvent) => {
    e.stopPropagation();
    const { property } = this.props;
    const element = e.target as HTMLElement;
    clearTimeout(this.leaveTimeoutId);
    if (this.active.property !== data[property]) {
      clearTimeout(this.enterTimeoutId);
      this.active = {
        data,
        element: element.getBoundingClientRect ? element.getBoundingClientRect() : element,
        property: data[property]
      };
      this.enterTimeoutId = setTimeout(() => {
        if (this.state.active !== data[property]) {
          this.setState({ active: data[property] });
        }
      }, 10);
    }
  };
  handleMouseLeave = (data) => (e: MouseEvent) => {
    e.stopPropagation();
    clearTimeout(this.leaveTimeoutId);
    const { property } = this.props;
    if (this.active.property === data[property]) {
      this.active = {};
      this.leaveTimeoutId = setTimeout(() => {
        if (this.state.active !== undefined) {
          this.setState({ active: undefined });
        }
      }, 10);
    }
  };
  isOver = (id) => {
    return this.state.active === id;
  };
  getActive = () => {
    return this.active;
  };
  render() {
    return <HoverContext.Provider value={this.state}>{this.props.children}</HoverContext.Provider>;
  }
}
export default HoverProvider as any;
