import styled from 'styled-components';
import { Collapse } from 'rsv8-components';
import { Col } from 'react-bootstrap';

export const BalanceWrapper = styled.div`
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
`;

export const LineAmount = styled(Col)`
  padding-right: 30px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const StyledCollapse = styled(Collapse)`
  width: 100%;
  :nth-last-child(1) {
    hr {
      display: none;
    }
  }
`;
