import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { register, withContent, withTheme } from 'xcel-react-core';
import { getProgramByKey } from '../../redux/actions/program';
import { getUserCultureLanguage } from '../../redux/selectors/metricSelectors';

interface LastUpdatedProps {
  dispatch: any,
  programKey: string,
  prefixText: string,
  suffixText: string,
  culture: string,
}

const LastUpdated: React.SFC<LastUpdatedProps> = ({dispatch, children, programKey, prefixText, suffixText, culture, ...rest}) => {
  const [lastUpdated, setLastUpdated] = useState('');

  const lastUpdatedLabel = () => {
    if (!lastUpdated) return '';

    const dateString = new Date(lastUpdated).toLocaleString(culture, {
      hour: "2-digit",
      minute: "2-digit",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      timeZoneName: "short"
    });

    return (
      <div>
        {prefixText && `${prefixText} `}
        {dateString}
        {suffixText && ` ${suffixText}`}
      </div>
    )
  };

  useEffect(() => {
    if (!programKey) return;

    dispatch(
      getProgramByKey(programKey)
    ).then(result => {
      setLastUpdated(result.data.attributes.lastUpdated);
    }).catch(err => console.log("failed to fetch program for ", programKey));
  }, [programKey]);

  return <div {...rest}>{lastUpdatedLabel()}</div>;
};

const mapStateToProps = (state) => ({
  culture: getUserCultureLanguage(state),
});

const mapContentToProps = (getContent) => ({
  programKey: getContent('programKey', { type: 'none', label: 'Program Key' }),
  prefixText: getContent('prefixText', { type: 'none', label: 'Prefix Text' }),
  suffixText: getContent('suffixText', { type: 'none', label: 'Suffix Text' }),
});

export default register('rsv8-metrics/lastupdated')(
  connect(mapStateToProps),
  withContent(mapContentToProps),
  withTheme()
)(LastUpdated);
