import { ROOT_STATE, TIME_PERIOD_REDUCER_KEY } from '../../constants';
import { combineReducers } from 'redux';
import { metricsReducer } from './metricsReducer';
     
const reducers = {[ROOT_STATE]: 
    combineReducers({
     /* [TIME_PERIOD_REDUCER_KEY]: combineReducers({
        key: metricsReducer,
      }), */
     [TIME_PERIOD_REDUCER_KEY]: metricsReducer,
    })
  };

export { reducers };
