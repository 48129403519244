import { promiseDispatcher } from 'xcel-react-core';

import { createJsonApiActions } from 'xcel-redux-orm';

import {metricApiAction} from '../../api';

const actions = createJsonApiActions('getUserMetrics');

const getUserMetricsCall = async ({theprogramKey,themetricKey,thedate}) => {

    if(themetricKey!==undefined &&  theprogramKey !==undefined &&  thedate !==undefined) {
      return await  metricApiAction.getUserMetricByMetricKeyFromDate({ metricKey:themetricKey,programKey:theprogramKey,date:thedate},{jsonApi:true});
    } else {
        console.log("Metric Error: you do are missing a key")
        return null;
    }

};
const getUserMetrics = promiseDispatcher(getUserMetricsCall, actions);
export { getUserMetrics };