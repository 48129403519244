import * as Quill from 'quill';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import styled from 'styled-components';
import  componentActions from '../../redux/actions/component'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

const HtmlEditorContainer = styled.span`
  display: block;
  outline: none;
  .ql-tooltip {
    z-index: 2000 !important;
  }
  .ql-editor {
    overflow: visible;
  }
`;
var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote' /*, 'code-block'*/],

  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['link', 'video'],
  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }], // need to change this to show custom fonts based on theme
  [{ align: [] }],

  ['clean'] // remove formatting button
];
class HtmlEditor extends React.Component<any> {
  editor: any;
  el: any;
  setValue = (value) => {
    // this is needed or it can blow up with max call stack exception :)
    if (value === undefined || value === null) return;
    try {
      this.editor.setContents(this.editor.clipboard.convert(value));
    } catch (e) {
      console.warn(e);
    }
  };
  componentDidMount() {
    this.setValue(this.props.value);
  }
  componentDidUpdate() {
    this.setValue(this.props.value);
  }
  shouldComponentUpdate(nextProps: any) {
    return nextProps.value !== this.getHTML() && nextProps.model !== undefined;
  }
  getHTML = () => {
    return this.editor.root.innerHTML;
  };

  handleChange = (delta, oldDelta, source) => {
    if (this.props.value !== this.getHTML()) {
      const { onInput, onChange } = this.props;
      if (onInput) {
        onInput(this.getHTML());
      }
      if (onChange) {
        onChange(this.getHTML());
      }
      if (source.toString() === 'user') {
        const model = this.props.model;
        const property = this.props.property;
        const value = this.getHTML();
        this.props.actions.propertySet({model, property, value});
      }
    }
  };
  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(this.getHTML());
    }
  };
  handleDrop = (e) => {
    e.preventDefault(e);
  };
  isEditing = () => {
    return this.el.querySelector('.ql-tooltip.ql-hidden') === null;
  };
  selectionChange = (range, oldRange, source) => {
    if ((range === null && oldRange !== null) || source === 'api') {
      if (!this.isEditing()) {
        setTimeout(this.handleBlur);
      }
    } else if (range !== null && oldRange === null) {
      // focus
    }
  };
  onMount = (el) => {
    this.el = findDOMNode(el);
    if (this.el !== null) {
      Quill;
      toolbarOptions;
      this.editor = new Quill(this.el, {
        modules: {
          toolbar: toolbarOptions,
          clipboard: {
            matchVisual: false
          }
        },
        placeholder: `Please enter text [${this.props.property}]`,
        theme: 'bubble'
      });
      this.editor.on('text-change', this.handleChange);
      this.editor.on('selection-change', this.selectionChange);
    }
  };
  componentWillUnmount() {
    this.editor.off('text-change', this.handleChange);
    this.el = null;
    this.editor = null;
  }

  render() {
    return (
      <HtmlEditorContainer
        className={this.props.className}
        ref={this.onMount}
        onClick={this.props.onClick}
        onDrop={this.handleDrop}
        onDragOver={this.handleDrop}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            propertySet: componentActions.propertySet
        },
        dispatch
    )
});

export default connect(
    null,
    mapDispatchToProps
)(HtmlEditor);
