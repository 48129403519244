import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { teamMemberActions } from '../../redux/actions/index';
import { teamNominationSelectors } from '../../redux/selectors/index';
import { DetailPanel } from './DetailPanel/index';

export interface TeamMemberDetailPanelProps {
  teamMemberId: string;
  selected: boolean;
  handleSelect: any;
  openOnError: boolean;
}

const mapState = (state, ownProps: TeamMemberDetailPanelProps) => ({
  awardId: teamNominationSelectors.getCurrentAwardId(state),
  // hasAwardOptions: teamNominationSelectors.getAwardHasPoints(state),
  hasRoleOptions: teamNominationSelectors.getAwardHasRoles(state),
  // awardOptions: teamNominationSelectors.getNomineeAwardOptions(state),
  roleOptions: teamNominationSelectors.getNomineeRoleOptions(state),
  // teamMemberAward: teamNominationSelectors.getNomineeAwardDetail(state, ownProps.teamMemberId),
  teamMemberRole: teamNominationSelectors.getNomineeRoleDetail(state, ownProps.teamMemberId),
  teamMemberCustomFields: teamNominationSelectors.getNomineeCustomFieldsMap(state, ownProps.teamMemberId),
  loading: teamNominationSelectors.getLoadingNominees(state).indexOf(ownProps.teamMemberId) !== -1
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      handleDelete: teamMemberActions.remove,
      handleChangeRole: teamMemberActions.setRole,
      // handleChangeAward: teamMemberActions.setAward,
      handleChangeCustomField: teamMemberActions.setCustomFields
    },
    dispatch
  );

export default connect(
  mapState,
  mapDispatch
)(DetailPanel as any) as React.ComponentType<TeamMemberDetailPanelProps>;
