import { ContentTypeModel } from 'xcel-api-generator/dist/cms';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { getContentState } from './state';
const contentTypeSelector = createTypeSelector<ContentTypeModel>('contentTypeModel');

export default {
  ...contentTypeSelector,
  getActive: (state) => getContentState(state).contentTypeId
};
export { Resource };
