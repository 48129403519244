import styled from 'styled-components';

export const SliderContainer = styled.div`
  overflow-x: hidden;

  .slick-slider {
    padding-top: 50px;
    margin-top: -50px;
  }
  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: static;
    height: 50px;
    margin-top: -7px;

    li {
      button {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        border: 2px solid #dddddd;
      }
      button::before {
        border-radius: 50%;
        content: '' !important;
        opacity: 1;
        height: 18px;
        width: 18px;
        margin: 2px;
      }
    }
    .slick-active button::before {
      opacity: 1;
      background-color: #000000;
      border: 2px solid #ffffff;
    }
  }
` as any;

export const CarouselArrow = styled.div`
  &.slick-arrow {
    align-items: center;
    background: rgba(245, 245, 245, 0.7) !important;
    border: none;
    cursor: pointer;
    display: flex;
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.2));
    font-size: inherit;
    height: 50%;
    justify-content: center;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%);
    transition: width 0.25s;
    width: 25px;
    z-index: 10;

    @media (max-width: 768px) {
      display: none;
    }

    &:hover {
      width: 50px;
    }
    &::before {
      display: none;
    }
  }
  &.slick-next {
    right: 0 !important;
    left: auto !important;
  }
  &.slick-prev {
    left: 0 !important;
    right: auto !important;
  }
  &.slick-disabled {
    display: none;
  }
  span {
    color: #4c4f4f;
  }
` as any;

export const SliderImage = styled.img`
  max-width: 100%;
  width: 100%;
` as any;

export const SingleImageWrapper = styled.div`
  margin-bottom: 50px;
` as any;

export const SingleImage = styled.img`
  max-width: 100%;
  width: 100%;
` as any;
