import { destroyRequest } from 'xcel-redux-orm';
import * as events from '../../events';
import { trendingSelector } from '../../selectors';
import { productApiActions } from './productApiActions';

const setCurrentTrending = (response) => ({ type: events.SET_CURRENT_TRENDING, payload: response });

const getTrendingProducts = () => async (dispatch, getState) => {
  dispatch(destroyRequest('getProductsTrending'));
  await dispatch(productApiActions.getProductsTrending({ limit: 10 }, { jsonApi: true }));
  const trendingProductSelector = trendingSelector.selectLast(getState(), 'getProductsTrending');
  dispatch(setCurrentTrending(trendingProductSelector));
};

const getTrendingByDepartment = (id) => async (dispatch, getState) => {
  dispatch(destroyRequest('getProductsTrendingByCategoryId'));
  await dispatch(productApiActions.getProductsTrendingByCategoryId({ categoryId: id, limit: 10 }, { jsonApi: true }));
};
export { getTrendingProducts, getTrendingByDepartment };
