import { teamNominationSelectors } from '../../../selectors/index';
import { addTeamMember } from './addTeamMember';
import { getTeamMembers } from './getTeamMembers';
import { removeTeamMember } from './removeTeamMember';
import { saveAllTeamMembers, saveTeamMember } from './saveTeamMember';
import { selectTeamMember } from './selectTeamMember';
import { setTeamMemberAward, setTeamMemberCustomFields, setTeamMemberRole } from './setValues';
import { validateTeamMember } from './validateTeamMember';

interface TeamMemberActions {
  select: (id: string) => any;
  add: (id: string | string[]) => any;
  save: (id: string) => any;
  saveAll: () => any;
  remove: (id: string) => any;
  setRole: (val: any, id?: string | string[]) => any;
  setAward: (val: any, id?: string | string[]) => any;
  setCustomFields: (fields: Record<string, any>, id?: string) => any;
  validateTeamMember: (userId: number) => any;
  getTeamMembers: (id: any) => any;
}

const teamMemberActions: TeamMemberActions = {
  select: (id) => (dispatch, getState) => {
    const nominationId = teamNominationSelectors.getCurrentNominationId(getState());
    if (nominationId) {
      dispatch(selectTeamMember({ nominationId, id }));
    }
  },
  add: (id) => (dispatch, getState) => {
    const nominationId = teamNominationSelectors.getCurrentNominationId(getState());
    if (nominationId) {
      dispatch(addTeamMember({ nominationId, userIds: id }));
    }
  },
  save: saveTeamMember,
  saveAll: saveAllTeamMembers,
  remove: (id) => (dispatch, getState) => {
    const nominationId = teamNominationSelectors.getCurrentNominationId(getState());
    if (nominationId) {
      dispatch(removeTeamMember({ nominationId, userIds: id }));
    }
  },
  setRole: setTeamMemberRole,
  setAward: setTeamMemberAward,
  setCustomFields: setTeamMemberCustomFields,
  validateTeamMember: validateTeamMember,
  getTeamMembers: getTeamMembers
};

export default teamMemberActions;
