import * as React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Text } from 'rsv8-components';
import { Field, reduxForm } from 'rsv8-forms';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { updateUserInfo } from '../../../redux/actions/user/updateUserInfo';
import { FieldLabel } from '../../Label/Label';
import { CheckoutModuleHeading } from '../styles';
import { validateEmail } from '../util';

const ContactInfoContainer = styled.div`
  margin-top: 20px;

  @media (min-width: 769px) {
    margin-left: 20px; 
  }
` as any;

class EditContactInfo extends React.Component<any, any> {
  state = { wasChanged: false };
  handleChange = (e) => { 
    if(e.target.id==='phone') {
          this.props.actions.updateUserInfo(e.target.id, e.target.value.replace(/[a-zA-Z]/g, ''));
    } else {     
          this.props.actions.updateUserInfo(e.target.id, e.target.value);     
    }
    this.setState({ wasChanged: true });
  };
 
  containsApostrophe = () => {
    return /["'"]+/g.test(this.props.initialValues.emailAddress);
  };

  isEmailFieldDisabled = () => {
    if (this.containsApostrophe) return false;
    if (this.state.wasChanged) return false;
    if (this.props.isEmailFieldEnabled()) return false;
    if (this.props.initialValues.emailAddress !== undefined) {
      return true;
    } else {
      this.props.enableEmailField();
      return false;
    }
  };

  validatePhone = (value) => {
    if (!value) return value;
    return value.replace(/[a-zA-Z]/g, '');
  };

  email = (value) => validateEmail(value, this.props.invalidEmailLabel);

  render() {
    const { altEmailLabel, contactInfoHeading, CPFLabel, emailLabel, formCount, phoneLabel, userProfile } = this.props;

    return (
      <div>
        <CheckoutModuleHeading>{`${formCount}. ${contactInfoHeading}`}</CheckoutModuleHeading>
        <ContactInfoContainer>
          <Form>
            <Field
              component={'bs-input'}
              name="emailAddress"
              type="email"
              label={
                <FieldLabel required={true}>
                  <Text themeVariation="secondary-text">{emailLabel}</Text>
                </FieldLabel>
              }
              required={false}
              disabled={this.isEmailFieldDisabled()}
              validate={this.email}
              onChange={this.handleChange}
            />
            {this.containsApostrophe && (
              <small>
                <strong>
                  Emails with apostrophes not accepted. Please provide an alternate email address if your company email
                  contains an apostrophe.
                </strong>
              </small>
            )}
            <Field
              component={'bs-input'}
              name="altEmailAddress"
              type="email"
              validate={this.email}
              label={
                <FieldLabel>
                  <Text themeVariation="secondary-text">{altEmailLabel}</Text>
                </FieldLabel>
              }
              onChange={this.handleChange}
            />
            <Field
              component={'bs-input'}
              name="phone"
              label={
                <FieldLabel required={true}>
                  <Text themeVariation="secondary-text">{phoneLabel}</Text>
                </FieldLabel>
              }
              onChange={this.handleChange}
              required={true}
              type="tel"
              normalize={this.validatePhone}
            />
            {userProfile.isCPFRequired && (
              <Field
                component={'bs-input'}
                name="cpf"
                onChange={this.handleChange}
                label={
                  <FieldLabel required={true}>
                    <Text themeVariation="secondary-text">{CPFLabel}</Text>
                  </FieldLabel>
                }
              />
            )}
          </Form>
        </ContactInfoContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    altEmailAddress: ownProps.userProfile.altEmailAddress,
    phone: ownProps.userProfile.phone,
    emailAddress: ownProps.userProfile.emailAddress
  }
});

const mapResourceToProps = (getResource) => ({
  contactInfoHeading: getResource('catalog.checkout.EditContactInfoHeading', 'Contact Information'),
  emailLabel: getResource('catalog.checkout.emailLabel', 'Email'),
  altEmailLabel: getResource('catalog.checkout.altEmailLabel', 'Alternative Email'),
  phoneLabel: getResource('catalog.checkout.phoneLabel', 'Phone'),
  invalidEmailLabel: getResource('catalog.checkout.invalidEmailLabel', 'Invalid email address'),
  CPFLabel: getResource('catalog.checkout.CPFLabel', 'CPF')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateUserInfo
    },
    dispatch
  )
});

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'ContactInfoForm' as any,
    enableReinitialize: true
  }),
  withResource(mapResourceToProps)
)(EditContactInfo);