import {
  TIME_PERIOD_REDUCER_KEY,
  COMBINED_METRICS_REDUCER_KEY
} from '../../constants';

const initState = {};

const metricsReducer = (state: any = initState, action: any) => {

  const { type, payload } = action;

  switch (type) {
    case COMBINED_METRICS_REDUCER_KEY: 
      return {
        ...state, 
        data: payload
      };    
    case TIME_PERIOD_REDUCER_KEY:
      return {
        ...state, 
        data: payload
      };
    default: 
      return {
        ...state
      };
  }
}

export { metricsReducer }; 