import { destroyRequest } from "xcel-redux-orm";
import { alconApi } from '../../api';
import { mapJsonApiActions } from 'xcel-redux-orm';
import * as events from './events';

const alconActions = mapJsonApiActions(alconApi);

export const getNotifications = () => async (dispatch, getState) => {
  try {
    dispatch(destroyRequest("getMagnifeyeNotifications"));
    const response = await dispatch(alconActions.getMagnifeyeNotifications({ jsonApi: true }));
    return dispatch({ payload: response, type: events.NOTIFICATIONS_GET});
  } catch (error) {
    console.log("getMagnifeyeNotifications Error: ", error);
  }
};
(getNotifications as any).endpoint = "getMagnifeyeNotifications";

export const markNotificationsAsRead = () => async (dispatch, getState)  => {
  try {
    dispatch(destroyRequest("createMagnifeyeNotifications"))
    const response = await dispatch(alconActions.createMagnifeyeNotifications({jsonApi: true}) );
    return response;
  } catch (error) {
    console.log("markNotificationsAsRead Error:", error);
  }
};
