import * as React from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

const Error = styled(Alert)`
  align-items: center;
  border: solid 1px #d8374b;
  color: #333;
  display: inline-block;
  margin-top: 20px;
` as any;

const ErrorMessage = styled.p`
  margin-bottom: 10px;
` as any;

class PointRangeFilterError extends React.Component<any, any> {
  render() {
    return (
      <Error bsStyle="danger">
        <ErrorMessage>Both fields are required to filter by point value</ErrorMessage>
      </Error>
    );
  }
}

export default PointRangeFilterError;
