import styled from 'styled-components';

export const UserStoryStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 15px 20px 15px;
  max-width: 500px;
`;

export const InfoAside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserName = styled.div`
  font-size: 28px;
  max-width: 200px;
  padding: 10px 0;
  font-weight: 300;
`;

export const PointsBadge = styled.div`
  border: solid 1px #d4d4d4;
  border-radius: 35px;
  min-width: 80px;
  font-size: 24px;
  font-weight: bold;
  overflow: hidden;
  padding: 5px 10px;
  display: flex;
  align-content: flex-end;
  align-items: center;
  justify-content: space-around;
`;

export const Abbrev = styled.span`
  font-size: 12px;
`;
