import { CategoryMenuModel, ProductMenuModel } from 'xcel-api-generator/dist/catalog';
import { createTypeSelector } from 'xcel-redux-orm';
import { CATEGORY_JSON_TYPE, PRODUCT_MENU_JSON_TYPE } from '../../constants';

// DEPARTMENTS
export const menuSelector = createTypeSelector<ProductMenuModel>(PRODUCT_MENU_JSON_TYPE);
export const getAllDepartments = (state) => menuSelector.selectMany(state, null, { eager: true });

// CATEGORY
export const categorySelector = createTypeSelector<CategoryMenuModel>(CATEGORY_JSON_TYPE);
export const getCategories = (state) => categorySelector.searchMany(state, (a) => a.level === 0);
export const getDepartment = (state, ownProps) => categorySelector.selectOne(state, ownProps.match.params.id);
export const getCategorySearchName = (state, categoryId) => categorySelector.selectOne(state, categoryId);
