export type ErrorMap = { resourceKey: string; defaultValue?: string };

export default {
  // Field Labels for Unlocalized Fields
  first_name: { resourceKey: 'common.firstNameText' },
  last_name: { resourceKey: 'common.lastNameText' },
  email: { resourceKey: 'common.emailText' },
  // Labels for Nomination Status
  nom_status_new: { resourceKey: 'nominationStatus.newLabel', defaultValue: 'New' },
  nom_status_approved: { resourceKey: 'nominationStatus.approvedLabel', defaultValue: 'Approved' },
  nom_status_awarded: { resourceKey: 'nominationStatus.awardedLabel', defaultValue: 'Awarded' },
  nom_status_denied: { resourceKey: 'nominationStatus.deniedLabel', defaultValue: 'Denied' },
  nom_status_pending_approval: { resourceKey: 'nominationStatus.pendingLabel', defaultValue: 'Pending' },
  nom_status_draft: { resourceKey: 'nominationStatus.draftLabel', defaultValue: 'Draft' },
  nom_detail_status_draft: { resourceKey: 'nominationStatus.draftLabel', defaultValue: 'Draft' },
  nom_detail_status_submitted: { resourceKey: 'nominationStatus.submittedLabel', defaultValue: 'Submitted' },
  team_status_draft: { resourceKey: 'nominationStatus.draftLabel', defaultValue: 'Draft' },
  team_status_submitted: { resourceKey: 'nominationStatus.submittedLabel', defaultValue: 'Submitted' },
  team_status_approved: { resourceKey: 'nominationStatus.approvedLabel', defaultValue: 'Approved' },
  team_status_denied: { resourceKey: 'nominationStatus.deniedLabel', defaultValue: 'Denied' },
  team_status_released: { resourceKey: 'nominationStatus.releasedLabel', defaultValue: 'Released' },
  // Variety of Nomination Errors
  nomination_invalid_nominee_id: { resourceKey: 'errors.invalidNomineeId' },
  nomination_invalid_nomination_detail_id: { resourceKey: 'errors.invalidNominationDetailId' },
  nomination_invalid_management_code_id: { resourceKey: 'errors.invalidManagementCodeId' },
  nomination_invalid_point: { resourceKey: 'errors.invalidPoints' },
  nomination_denial_msg_nominee_has_met_point_limit: {
    resourceKey: 'errors.nomineeHasMetPointLimit'
  },
  nomination_invalid_nominator: { resourceKey: 'errors.invalidNominator' },
  nomination_validation_error: { resourceKey: 'errors.nominationValidationError' },
  nomination_invalid_application_key: { resourceKey: 'errors.invalidApplicationKey' },
  team_nomination_team_name_required: { resourceKey: 'errors.teamNameRequired' },
  team_nomination_invalid_team_id: { resourceKey: 'errors.invalidTeamId' },
  team_nomination_invalid_member_list: { resourceKey: 'errors.invalidMemberList' },
  team_nomination_invalid_role_id: { resourceKey: 'errors.invalidRoleId' },
  nomination_application_key_required: { resourceKey: 'errors.invalidRole' },
  team_nomination_invalid_team_member_id: { resourceKey: 'errors.invalidTeamMemberId' },
  team_nomination_invalid_nomination_status: { resourceKey: 'errors.invalidNominationStatus' },
  team_nomination_duplicate_member: { resourceKey: 'errors.duplicateTeamMember' },
  nomination_invalid_status: { resourceKey: 'errors.invalidStatus' },
  team_nomination_invalid_team_member_count: { resourceKey: 'errors.invalidTeamMemberCount' },
  customfield_groups_not_found: { resourceKey: 'errors.customFieldGroupsNotFound' },
  nomination_delete_error_associated_process: { resourceKey: 'errors.nomination_delete_error_associated_process' },
  nominations_process_action_taken: {
    resourceKey: 'errors.nominations_process_action_taken'
  },
  nomination_process_previous_action: { resourceKey: 'errors.nomination_process_previous_action' },
  nomination_process_previous_approved: { resourceKey: 'errors.nomination_process_previous_approved' },
  nomination_process_previous_awarded: { resourceKey: 'errors.nomination_process_previous_awarded' },
  nomination_process_previous_denied: { resourceKey: 'errors.nomination_process_previous_denied' }
} as Record<string, ErrorMap>;
