import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from 'rsv8-forms';
import { nomineeFilterSelectors } from '../../redux/selectors/index';

export interface FilterFieldProps {
  name: string;
}

export interface Props extends FilterFieldProps {
  filter: any;
}

const renderOption = ({ options }) => {
  return (
    <React.Fragment>
      <option value={0}>All</option>
      {options.map((opt, i) => (
        <option key={i} value={opt.optionId || opt.name}>
          {opt.name} ({opt.count})
        </option>
      ))}
    </React.Fragment>
  );
};

const FilterField: React.SFC<Props> = ({ name, filter, ...rest }) => {
  if (filter.options === undefined) return null;
  return (
    <Field
      name={name}
      component="bs-select"
      options={filter.options}
      label={filter.label}
      optionRenderer={renderOption}
      {...rest}
    />
  );
};

const mapState = (state, ownProps) => ({ filter: nomineeFilterSelectors.selectOne(state, ownProps.name) });

export default connect(
  mapState,
  null
)(FilterField);
