import { reduxObserver } from 'xcel-observer';
import { createReduxModule, ReduxModule } from 'xcel-redux-module';
import { reducers } from './app/redux/reducers';

const dataVisModule: ReduxModule = createReduxModule({
  name: 'rsv8-data-vis',
  reducers
});

reduxObserver.addModule(dataVisModule);
export default dataVisModule;
