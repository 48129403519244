import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { Panel, PanelBody, PanelCollapse } from 'rsv8-components';
import styled from 'styled-components';

const PanelButton = styled.div`
  color: #4c4f4f;
  cursor: pointer;
  font-weight: bold;
  padding: 12px;
` as any;

const FacetPanel = styled(Panel)`
  border-color: #ededed;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  box-shadow: none;
  margin-bottom: 0;
` as any;

const PanelCaret = styled(Icon)`
  float: right;
` as any;

class FilterPanel extends React.Component<any, any> {
  state = {
    open: false
  };

  toggleState = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div>
        <PanelButton onClick={this.toggleState}>
          {this.props.title}
          {this.state.open ? <PanelCaret name="caret-up" /> : <PanelCaret name="caret-down" />}
        </PanelButton>
        <FacetPanel expanded={this.state.open}>
          <PanelCollapse>
            <PanelBody>{this.props.children}</PanelBody>
          </PanelCollapse>
        </FacetPanel>
      </div>
    );
  }
}

export default FilterPanel;
