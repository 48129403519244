import * as React from "react";
import { BarCell, BarContainer, LoadingPill } from "./styles";

export const PlaceHolderBar: React.FC<{ collapsed: boolean, className: string }> = ({ collapsed, className }) =>
(
    <div className={className}>
        <div>
            <BarContainer collapsed={collapsed}>
                <div className="dashboard-bar__content">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                        <BarCell key={"empty-cell" + index} className="dashboard-bar__points">
                            <LoadingPill small={true} />
                            <LoadingPill />
                        </BarCell>
                    ))}
                </div>
            </BarContainer>
        </div>
    </div>
)