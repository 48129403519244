import { IndividualNomination } from '../../../../types/index';
import * as events from '../../../events';
import { individualNominationSelectors } from '../../../selectors/index';
import { clearCurrentNomination } from '../nomineeSave/index';

export const selectProductOptionIdBase = (appKey): IndividualNomination.Action => {
  return {
    type: events.SELECT_PRODUCT_OPTION_ID,
    payload: appKey
  };
};

export const setIndividualNominationProductOptionId = (value) => ({
  type: events.SET_INDIVIDUAL_NOMINATION_OPTION,
  payload: value
});

const selectIndividualProductOptionId = (value) => (dispatch, getState) => {
  const state = getState();
  if (individualNominationSelectors.getCurrentOptionId(state) !== value) {
    dispatch(clearCurrentNomination());
    dispatch(selectProductOptionIdBase(value));
  }
};

export default selectIndividualProductOptionId;