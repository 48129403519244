import 'react-ui-tree/dist/react-ui-tree.css';
import styled from 'styled-components';
import ComponentStuctureTree from './components/ComponentStructure';

export const ComponentStuctureTreeStyled = styled(ComponentStuctureTree)`
  .m-tree {
    text-align: left;
    white-space: nowrap;
    overflow: scroll;
    max-height: 35vh;
  }

  .m-node.placeholder {
    border: 1px dashed #1385e5;
  }

  .m-node .inner {
    font-size: 12px;
    font-family: sans-serif;
  }

  .m-node .node {
    display: inline-block;
    padding: 4px 5px;
  }

  .m-node .node.is-active {
    color: white;
    background-color: #31363f;
    border-radius: 10px;
  }

  & .caret-right {
    display: inline;
  }

  & .caret-down {
    display: inline;
  }
` as any;
