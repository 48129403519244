import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Card } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import myAwardActions from '../../../redux/actions/myAwards';
import AwardButton from './AwardButton';
import AwardHeader from './AwardHeader';
import { toast } from 'rsv8-components';
import { HeaderFour, Text } from 'rsv8-components';
import { numberFormat } from "xcel-util";
import { destroyRequest } from 'xcel-redux-orm';

interface AwardCardProps {
    actions: {
        getAwardDetail: any;
    };
    onToggleClick?: any;
    award: any;
    awardCertificateLinkLabelText: string;
    culture: string;
    view: string;
    details: Object;
    dispatch: any;
    showNominationIcon: string;

    resource: {
        failedToRedeemGiftToastHeader: string;
        failedToTradeGiftToastHeader: string;
        tradeGiftSuccessHeader: string;
        redeemGiftSuccessHeader: string;
        tradeGiftSuccessToastText: string;
        redeemGiftSuccessToastText: string;
        redeemButtonTextLabel: string;
        tradeButtonTextLabel: string;
        pointsTextLabel: string;
        viewCertificateLabel: string;
    }
}

const TRADE_TOAST_TEXT = `Thank you for trading in your digital gift card for credits.
Check your email inbox for a confirmation for the trade. The
credits have been automatically debited to your account and
those credits are available to use instantly. You may redeem
them for any digital or physical gift card of your choice in the
Reward Catalog. Contact support if you have any questions.`;

const REDEEM_TOAST_TEXT = `Thank you for your redeeming your digital gift card. You
deserve it! You will receive two emails: one with your order
confirmation code and one with your redemption code. All
instructions and details will be included in those emails.
Contact support if you have any questions.`;

enum RedeemActions {
  TRADE = 'trade_for_points',
  REDEEM = 'redeem'
}

const successToast = (className: any, title: any, text: any) => {
    return toast(
        {
            template: 'ContentToast',
            themeVariation: 'denied',
            contentTemplate: (
                <React.Fragment>
                    <HeaderFour>{title}</HeaderFour>
                    <Text>{text}</Text>
                </React.Fragment>
            )
        },
        { autoClose: false, className: `${className}` }
    );
};

const failedToast = (message) => {
    return toast(
        { template: 'Default', themeVariation: 'error-text', message },
        { autoClose: false }
    );
};

class AwardCard extends React.Component<AwardCardProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            actionsLoading: false
        };
    }

    handleClick = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        let open = this.state.open;
        // NOT OPEN AND NO DATA LOADED
        if (!open && this.state.details == null) {
            dispatch(myAwardActions.getDetails(this.props.award.nominationDetailId));

            this.setState({
                open: !this.state.open
            });
            // ALREADY OPEN WITH DATA
        } else {
            this.setState({
                open: !this.state.open
            });
        }
    };

    handleTradeClick = async (e) => {
        const { dispatch, award } = this.props;
        this.setState({
            actionsLoading: true
        });
        dispatch(myAwardActions.createRedeem(award.nominationId, RedeemActions.TRADE)).then(async () => {
            const { resource } = this.props;
            try {
                await dispatch(destroyRequest('getSummary'));
                await dispatch(myAwardActions.getAccountSummary());
            } catch (e) {
            }
            successToast(null, resource.tradeGiftSuccessHeader, resource.tradeGiftSuccessToastText);
            this.hideActionsButtons();
            award.tradedForPoints = true;
            this.setState({
                actionsLoading: false
            });
        }).catch((error) => {
            failedToast(this.props.resource.failedToTradeGiftToastHeader);
            this.setState({
                actionsLoading: false
            });
        });
    };

    redeemClickHandler = async (e) => {
        const { dispatch, award } = this.props;
        this.setState({
            actionsLoading: true
        });
        dispatch(myAwardActions.createRedeem(award.nominationId, RedeemActions.REDEEM)).then(() => {
            const { resource } = this.props;
            successToast(null, resource.redeemGiftSuccessHeader, resource.redeemGiftSuccessToastText);
            this.hideActionsButtons();
            award.isRedeemed = true;
            this.setState({
                actionsLoading: false
            });
        }).catch(error => {
            failedToast(this.props.resource.failedToRedeemGiftToastHeader);
            this.setState({
                actionsLoading: false
            });
        });
    };

    hideActionsButtons = () => {
        const { award } = this.props;
        award.showRedeemAction = false;
        award.showTradeForPointsAction = false;
    };

    render() {
        const { open, actionsLoading } = this.state;
        const { award, culture, awardCertificateLinkLabelText, resource, showNominationIcon } = this.props;
        return (
            <Card themeVariation="award-card">
                <AwardHeader
                    id={award.nominationDetailId}
                    culture={culture}
                    open={open}
                    award={award}
                    awardLabel={awardCertificateLinkLabelText}
                    redeemButtonTextLabel={resource.redeemButtonTextLabel}
                    tradeButtonTextLabel={resource.tradeButtonTextLabel}
                    viewCertificateLabel={resource.viewCertificateLabel}
                    pointsTextLabel={resource.pointsTextLabel}
                    tradeClickHandler={this.handleTradeClick}
                    redeemClickHandler={this.redeemClickHandler}
                    actionsLoading={actionsLoading}
                    showNominationIcon={showNominationIcon}
                />
                <AwardButton clickHandler={this.handleClick} open={open}/>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
  awardValue: ownProps.award.awardValue ? numberFormat(ownProps.award.awardValue) : '',
  pointValue: ownProps.award.pointValue ? ownProps.award.pointValue : ''
});

const mapResource = (getResource) => ({
    resource: {
        failedToRedeemGiftToastHeader: getResource('nominations.failedToRedeemGiftToastHeader', 'Failed to redeem gift'),
        failedToTradeGiftToastHeader: getResource('nominations.failedToTradeGiftToastHeader', 'Failed to trade gift'),
        redeemGiftSuccessHeader: getResource('nominations.redeemGiftSuccessHeader', 'You redeemed your {awardValue}'),
        tradeGiftSuccessHeader: getResource('nominations.tradeGiftSuccessHeader', 'You traded your {awardValue} for {pointValue} Credits'),
        tradeGiftSuccessToastText: getResource('nominations.tradeGiftSuccessToastText', TRADE_TOAST_TEXT),
        redeemGiftSuccessToastText: getResource('nominations.redeemGiftSuccessToastText', REDEEM_TOAST_TEXT),
        redeemButtonTextLabel : getResource('nominations.redeemButtonTextLabel', 'Redeem'),
        tradeButtonTextLabel: getResource('nominations.tradeButtonTextLabel', 'Trade'),
        pointsTextLabel: getResource('nominations.pointsTextLabel', '{pointValue} Credits'),
        viewCertificateLabel: getResource('nominationActivity.viewCertificateButtonText', 'View Certificate')
    },
    showNominationIcon: getResource('nominations.showNominationIcon', 'true')
});

export default compose<AwardCardProps>(
    connect(
        mapStateToProps
    ),
    withResource(mapResource),
)(AwardCard as any) as any;
