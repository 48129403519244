import * as React from 'react';

const OptionsList = ({ options }) => (
  <React.Fragment>
    {options.map((opt, i) => (
      <option key={i} value={opt.value} disabled={opt.disabled} selected={opt.selected} hidden={opt.hidden}>  
        {opt.label || opt.value}
      </option>
    ))}
  </React.Fragment>
);

export default OptionsList;
