import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { register, withContent, withTheme } from "xcel-react-core";
import { withApiDataProvider } from 'xcel-redux-orm';
import { getOrganization, getUserAccessMetricByYearAndMetricKey } from '../../redux/actions';
import { organizationSelector } from '../../redux/selectors';
import UserMetricText from './UserMetricText';

const UserMetricByYear: React.FC<Props> = ({ ownerId, actions, program, metric, byYear, ...props }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [value, setValue] = React.useState<number>(null);

  React.useEffect(() => {
    if (!!program && !!byYear && !!metric && !!ownerId) {
      setLoading(true);
      actions
        .getUserAccessMetricByYearAndMetricKey({ programKey: program, metricKey: metric, year: byYear, userId: ownerId })
        .then((res) => {
          if (!!res && !!res.data) {
            setValue(Number(res.data[res.data.length - 1].attributes.value));
          } else {
            setValue(null);
          }
        })
        .catch((err) => {
          console.error('Cannot load user metric', err);
          setValue(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [program, metric, byYear, ownerId]);

  return <UserMetricText {...props} loading={loading} value={value} />;
};

const mapStateToProps = (getState) => ({
  ownerId: organizationSelector.selectMany(getState)[0]?.ownerUserId,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getUserAccessMetricByYearAndMetricKey }, dispatch),
});
const mapContentToProps = (getContent) => ({
  program: getContent('program', { type: 'string', label: 'Program Key' }),
  metric: getContent('metric', { type: 'string', label: 'Metric Key' }),
  byYear: getContent('byYear', { type: 'string', label: 'By Year' }),
  emptyValue: getContent('emptyValue', { type: 'string', label: 'Empty Value' }),
  formatOptions: getContent('formatOptions', { type: 'object', label: 'Format Options' }),
});

interface StateProps {
  ownerId: number;
}
interface DispatchProps {
  actions: {
    getUserAccessMetricByYearAndMetricKey: Function;
  };
}
interface ContentProps {
  program: string;
  metric: string;
  byYear: string;
  emptyValue: string;
  formatOptions: Object;
}
type Props = React.HTMLAttributes<any> & StateProps & DispatchProps & ContentProps;

export default register('rsv8-alcon/UserMetricByYear')(
  connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps),
  withContent(mapContentToProps),
  withTheme(),
  withApiDataProvider(getOrganization, organizationSelector)
)(UserMetricByYear);