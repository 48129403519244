import * as React from 'react';
import { Text } from 'rsv8-components';
import { ImageContainer } from '../../components/ItemCarousel/styles';
import { EmptyContainer } from '../EmptyContainer';

export interface EmptyListProps {
  label: string;
  icon: string;
}

const EmptyList: React.SFC<EmptyListProps> = ({ label, icon }) => (
  <EmptyContainer>
    <ImageContainer>
      <img src={icon} />
    </ImageContainer>
    <Text themeVariation="alternate-jumbo-light-text">{label}</Text>
  </EmptyContainer>
);

export default EmptyList;
