import * as React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { ModularMenu } from 'rsv8-navigation';
import { withResource } from 'xcel-react-core';
import { getAchievementPrograms } from '../redux/actions';
import { getAchievementPrograms as getPrograms } from '../redux/selectors';
import { Program } from 'xcel-api-generator/dist/achievement';

interface AchievementNavProps {
  actions: any
  title: string
  programs: Program[]
};

const AchievementNav: React.FC<AchievementNavProps> = ({ title, actions, programs }) => {
  React.useEffect(() => {
    actions.getAchievementPrograms({});
  }, []);
  const menuItems = programs.map(program => ({
    baseUrl: location.host,
    children: [],
    id: program.id,
    label:program.name,
    meta: "{\"menuTitle\":false}",
    targetUrl: `/achievement/program/${program.id}`
  }));
  return (
    <ModularMenu
        menuItems={menuItems}
        title={title}
        template="NominationsHeaderNav">
    </ModularMenu>
  );
};

const mapResourceToProps = (getResource) => ({
  title: getResource('achievement.title', 'Rewards Catalog')
});

const mapStateToProps = (state) => ({
  programs: getPrograms(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getAchievementPrograms }, dispatch)
});

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  withResource(mapResourceToProps)
)(AchievementNav);
