import * as React from 'react';
import {register, withContent, withTheme} from 'xcel-react-core';
import {PermissionsOptions} from "./PermissionsOptions";
import RequirePermissions from "./RequirePermissions";

const PermissionsRoute: React.FC<any> = ({
                              children,
                              redirectUrl,
                              canEditRegistration,
                              canRedeem,
                              canViewAccount,
                              canViewAccountBalance,
                              canViewRebateOptions,
                              canViewDashboard,
                              canManageParticipants,
                              canAccessCalculator,
                              canViewRedemptionHistory
                          }) => {
    const permissionsOptions: PermissionsOptions = {
        canEditRegistration: canEditRegistration,
        canRedeem: canRedeem,
        canViewAccount: canViewAccount,
        canViewAccountBalance: canViewAccountBalance,
        canViewRebateOptions: canViewRebateOptions,
        canViewDashboard: canViewDashboard,
        canManageParticipants: canManageParticipants,
        canAccessCalculator: canAccessCalculator,
        canViewRedemptionHistory: canViewRedemptionHistory
    };

    return (
        <RequirePermissions permissionsOptions={permissionsOptions} redirectUrl={redirectUrl}>
            {children}
        </RequirePermissions>
    );
};

const mapContentToProps = (getContent) => ({
    redirectUrl: getContent('redirectUrl', {type: 'string', label: 'redirectUrl'}),
    canEditRegistration: getContent('canEditRegistration', {type: 'boolean', label: 'canEditRegistration'}),
    canRedeem: getContent('canRedeem', {type: 'boolean', label: 'canRedeem'}),
    canViewAccount: getContent('canViewAccount', {type: 'boolean', label: 'canViewAccount'}),
    canViewAccountBalance: getContent('canViewAccountBalance', {type: 'boolean', label: 'canViewAccountBalance'}),
    canViewRebateOptions: getContent('canViewRebateOptions', {type: 'boolean', label: 'canViewRebateOptions'}),
    canViewDashboard: getContent('canViewDashboard', {type: 'boolean', label: 'canViewDashboard'}),
    canManageParticipants: getContent('canManageParticipants', {type: 'boolean', label: 'canManageParticipants'}),
    canAccessCalculator: getContent('canAccessCalculator', {type: 'boolean', label: 'canAccessCalculator'}),
    canViewRedemptionHistory: getContent('canViewRedemptionHistory', { type: 'boolean', label: 'canViewRedemptionHistory' })
});

export default register("rsv8-account/PermissionsRoute")(
    withContent(mapContentToProps),
    withTheme())
(PermissionsRoute);
