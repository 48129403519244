import * as React from 'react';
import { AnimatedCircle } from 'rsv8-components';
import { AnimatedCircleProps } from 'rsv8-components';
import styled from 'styled-components';

const SmallLoader = styled(AnimatedCircle)`
  margin: 10px;
`;
export interface ChildrenLoaderProps extends Partial<AnimatedCircleProps> {
  loading: boolean;
}

const ChildrenLoader: React.SFC<ChildrenLoaderProps> = ({ children, loading, ...rest }) => {
  return loading ? <SmallLoader width={16} {...rest} /> : <React.Fragment>{children}</React.Fragment>;
};

export { ChildrenLoader };
