import { ContentCultureModel, DetailContentModel } from 'xcel-api-generator/dist/cms';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { contentSelector } from './index';
export interface ContentDetailModel extends DetailContentModel {
  cultures: ContentCultureModel[];
}
const contentDetailSelector = {
  ...createTypeSelector<ContentDetailModel>('detailContentModel'),
  getActive: (state, contentTypeId) => {
    const { contentId } = contentSelector.getActiveProperty(state, contentTypeId);
    if (!contentId) return null;
    return contentDetailSelector.selectOne(state, contentId);
  }
};

export default contentDetailSelector;
export { Resource };
