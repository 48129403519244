import * as events from './events';
const initialState = {
  preview: '',
  previewSizes: [124, 72, 64, 48, 32, 24, 16, 14, 12]
};
const fontReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.THEME_FONT_PREVIEW_SET: {
      return { ...state, preview: action.font ? action.font.name : 'None' };
    }
    default:
      return state;
  }
};
export default fontReducer;
