import * as React from 'react';
import { connect } from 'react-redux';
import { ReactField } from 'rsv8-forms';
import { ContentCultureModel, SocialDetailModel } from 'xcel-api-generator/dist/social';
import { withErrorBoundary } from 'xcel-react-core';
import { socialActions } from '../../../redux/actions';
import { socialSelector } from '../../../redux/selectors';
import ActivityInputs from './ActivityInputs';
import { ActivityEditFormStyles, BreakLineForm } from './styles';
import VersionField from './VersionField';

const { cultureList, countries, currentVersion, currentCulture } = socialSelector;

export interface Props {
  activityTypes: Array<any>;
  activity: SocialDetailModel;
  cultures: Array<ContentCultureModel>;
  cultureArray: Array<any>;
  countries: Array<any>;
  culture: any;
  version: any;
  dispatch: any;
}

export interface State {
  formFields?: ActivityInputs;
}

class ActivityEditForm extends React.Component<Props, State> {
  state = {
    formFields: null
  };

  componentWillReceiveProps({ activity, cultures, culture, version }: Props) {
    const { formFields } = this.state;
    // when it receives for first time the data
    if (
      (formFields === null && activity.name && cultures && version.id) ||
      // and when it changes between versions
      version.id !== this.props.version.id ||
      // and the version changes its status
      version.isPublished !== this.props.version.isPublished
    ) {
      this.setState({
        formFields: new ActivityInputs(activity, version, culture.cultureId)
      });
    }
  }

  onChangeCulture = (itemId) => {
    const { dispatch, cultures, activity, version } = this.props;
    const { formFields } = this.state;
    const selectedCulture = cultures.find(({ cultureId }) => cultureId === Number(itemId));

    formFields.cultureId = itemId;
    this.setState({ formFields });
    if (selectedCulture !== undefined) {
      const versions: any = selectedCulture.versions;
      dispatch(socialActions.setEditCulture(selectedCulture));
      dispatch(socialActions.setEditVersion(versions[versions.length - 1]));
    } else {
      dispatch(
        socialActions.createNewCulture(
          activity.id,
          // inherits all data from previous culture
          {
            ...formFields,
            content: version.content,
            cultureId: itemId,
            isPublished: false,
            publishStatus: 'Draft'
          }
        )
      );
    }
  };

  onChangeActivity(propertyName: string, value: any) {
    const { activity, dispatch } = this.props;
    let { formFields } = this.state;
    formFields[propertyName] = value;
    this.setState({ formFields });
    dispatch(socialActions.editActivityById(activity.id, { [propertyName]: value }));
  }

  onChangeVersion(propertyName: string, value: any) {
    const { dispatch, activity, version } = this.props;
    let { formFields } = this.state;

    formFields[propertyName] = value;
    this.setState({ formFields });

    if (version.isPublished) {
      dispatch(
        socialActions.createNewVersion(activity.id, {
          ...formFields,
          [propertyName]: value,
          isPublished: false,
          publishStatus: 'Draft',
          content: version.content
        })
      );
    } else {
      dispatch(socialActions.editVersion(activity.id, version.id, { [propertyName]: value, content: version.content }));
    }
  }

  onChangeType = (typeId) => {
    const { activityTypes } = this.props;
    const activityType = activityTypes.find((type) => type.value === typeId);
    const payload = { id: activityType.value, name: activityType.value };
    const changeType = this.onChangeActivity.bind(this, 'activityType', payload);

    changeType();
  };

  onChangePublish = (value) => {
    const { dispatch, activity, version } = this.props;
    let { formFields } = this.state;

    formFields.isPublished = value;
    this.setState({ formFields });
    dispatch(socialActions.editVersion(activity.id, version.id, { isPublished: value }));
  };

  onChangeSticky = (value) => {
    const changeSticky = this.onChangeActivity.bind(this, 'isSticky', value);
    changeSticky();
  };

  render() {
    const { activity = {} as any, version, cultureArray } = this.props;
    const { formFields } = this.state;
    const fields = !!formFields ? formFields : ({} as any);
    return (
      <ActivityEditFormStyles>
        {/* Culture and version fields related */}
        <VersionField activityId={activity.id} version={version} />

        <ReactField
          component="bs-input"
          type="text"
          label="Title"
          value={fields.title}
          onChange={this.onChangeVersion.bind(this, 'title')}
        />

        <ReactField
          component="bs-input"
          type="text"
          label="Description"
          value={fields.description}
          onChange={this.onChangeVersion.bind(this, 'description')}
        />

        <ReactField component="checkbox" label="Publish?" value={fields.isPublished} onChange={this.onChangePublish} />

        <ReactField
          component="checkbox"
          label="Sticky?"
          value={fields.isSticky}
          onChange={this.onChangeSticky}
        />

        <ReactField
          component="bs-select"
          label="Culture"
          options={cultureArray}
          onChange={this.onChangeCulture}
          value={fields.cultureId}
        />

        <BreakLineForm />

        {/* Activities fields related */}
        <ReactField
          component="bs-input"
          type="text"
          label="Activity Name"
          value={fields.name}
          onChange={this.onChangeActivity.bind(this, 'name')}
        />

        <ReactField
          component="bs-select"
          label="Activity Type"
          options={this.props.activityTypes}
          value={fields.activityType && fields.activityType.id}
          onChange={this.onChangeType}
        />

        <ReactField
          component="multi-select"
          label="Countries"
          options={this.props.countries}
          onChange={this.onChangeActivity.bind(this, 'countries')}
          value={fields.countries}
        />
      </ActivityEditFormStyles>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: countries(state),
  cultureArray: cultureList(state),
  version: currentVersion(state),
  culture: currentCulture(state)
});

export default connect(mapStateToProps)(withErrorBoundary()(ActivityEditForm));
