import { types } from '../actions';

export default (state = { data: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_VALIDATIONS_SUCCESS:
      return { data: payload };
    default:
      return state;
  }
};
