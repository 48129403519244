import { ProductModel } from 'xcel-api-generator/dist/catalog';
import { createTypeSelector, getRequest } from 'xcel-redux-orm';
import {
  FAVORITE_STATE,
  PRODUCT_DETAIL_JSON_TYPE,
  PRODUCT_JSON_TYPE,
  ROOT_STATE,
  SEARCH_STATE
  } from '../../constants';
import { getFavoriteList } from './favoriteSelectors';
export interface ProductDetailItem extends ProductModel {}
export const productSelector = createTypeSelector<ProductModel>(PRODUCT_JSON_TYPE);
export const productDetailSelector = createTypeSelector<ProductDetailItem>(PRODUCT_DETAIL_JSON_TYPE);

export const getProductDetail = (state, ownProps) =>
  productDetailSelector.selectOne(state, decodeURIComponent(ownProps.match.params.id));

export const getFavoriteProductList = (state) =>
  productSelector.selectMany(state, getFavoriteList(state), { eager: true });

export const getSearchResults = (state) =>
  productSelector.selectMany(state, state[ROOT_STATE][SEARCH_STATE].currentResults);
export const getSearchQuery = (state) => state[ROOT_STATE][SEARCH_STATE].currentQuery;

export const getUndoProductsList = (state) =>
  productSelector.selectMany(state, state[ROOT_STATE][FAVORITE_STATE].favoriteUndo.undoFavoritesList);

export const getProductsRequest = (state) => getRequest(state, 'getProducts');
export const getProductsSearchRequest = (state) => getRequest(state, 'getProductsSearch');
