import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { withResource } from 'xcel-react-core';

export interface WizardProgressProps {
  current: number;
  total: number;
}

interface InternalProps extends WizardProgressProps {
  statusText: any;
  showStatusText: string;
}

const WizardProgressBase = ({ current, total, statusText, showStatusText }: InternalProps) => (
  <React.Fragment>
    {showStatusText === 'true' && statusText}
    <ProgressBar now={Math.round((current / total) * 100)} />
  </React.Fragment>
);

const mapResource = (getResource) => ({
  statusText: getResource('wizard.progressStatusText', '{current} of {total} steps completed'),
  showStatusText: getResource('wizard.progressShowStatusText', 'true')
});

const WizardProgressBar = withResource(mapResource)(WizardProgressBase) as React.SFC<WizardProgressProps>;

export { WizardProgressBar };
