import { path } from 'ramda';
import { ClientCultureModel } from 'xcel-api-generator/dist/user';
import { createTypeSelector } from 'xcel-redux-orm';
import { Resource } from 'xcel-redux-orm';
import { ROOT_STATE } from '../constants';

const cultureSelector = {
  ...createTypeSelector<ClientCultureModel>('clientCultureModel'),
  getAll: (state) => cultureSelector.selectMany(state, null),
  getActive: (state): ClientCultureModel => path([ROOT_STATE, 'culture', 'active'], state),
  getActiveCultureId: (state) => {
    const culture = cultureSelector.getActive(state);
    return culture && culture.id !== undefined ? culture.id : 0;
  }
};

export { ClientCultureModel, Resource };
export default cultureSelector;
