import { createInvalidationRule, mapJsonApiActions, mapJsonApiMethodAction } from 'xcel-redux-orm';
import { nominationApi } from '../../api/index';

const getPendingApproval = (awardType) =>
  (({ filter, sort, limit, offset, select }) => {
    const defaultFilter = `awardType = "${awardType}"`;
    if (filter) {
      filter = defaultFilter + ' && ' + filter;
    } else {
      filter = defaultFilter;
    }

    return nominationApi.getNominationsPendingapproval({ filter, sort, limit, offset, select });
  }) as typeof nominationApi.getNominationsPendingapproval;

const getNominationsHistory = (awardTypeLabel) =>
  (({ awardType = awardTypeLabel, statusId, applicationKey, filter, sort, limit, offset, select }) => {
    return nominationApi.getNominationsApprovalhistory({
      awardType,
      statusId,
      applicationKey,
      filter,
      sort,
      limit,
      offset,
      select
    });
  }) as typeof nominationApi.getNominationsApprovalhistory;

const getIndividualPendingApproval = mapJsonApiMethodAction({
  method: getPendingApproval('Individual'),
  endpoint: 'getIndividualPendingApproval'
});

const getTeamPendingApproval = mapJsonApiMethodAction({
  method: getPendingApproval('Team'),
  endpoint: 'getTeamPendingApproval'
});

const getIndividualApprovalHistory = mapJsonApiMethodAction({
  method: getNominationsHistory('Individual'),
  endpoint: 'getIndividualApprovalHistory'
});

const getTeamApprovalHistory = mapJsonApiMethodAction({
  method: getNominationsHistory('Team'),
  endpoint: 'getTeamApprovalHistory'
});

export const nominationActions = {
  ...mapJsonApiActions(nominationApi),
  getIndividualPendingApproval,
  getTeamPendingApproval,
  getIndividualApprovalHistory,
  getTeamApprovalHistory
};
createInvalidationRule(nominationActions.createProcess)(
  getIndividualPendingApproval,
  getTeamPendingApproval,
  getIndividualApprovalHistory,
  getTeamApprovalHistory
);
