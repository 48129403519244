import styled from 'styled-components';

const HamburgerMenuSpan = styled.span`
  width: 27px;
  display: inline-block;
  margin: auto;
  font-family: Helvetica;
  font-size: 9px;
  margin-top: 0;
  margin-bottom: 0;
`;

export default HamburgerMenuSpan as any;
