import * as React from "react";
import ReactAvatarEditor from "react-avatar-editor";
import { withResource } from "xcel-react-core";
import {
  AvatarFooter,
  Button,
  StyledBar,
  StyledRotateIcon
  } from "./styles/index";

export interface AvatarEditorProps {
  className: string;
  imageSrc: string;
  setEditorRef: any;
  onCrop: any;
  onCancel: any;
  scaleValue: number;
  rotateValue: number;
  onScaleChange: any;
  onRotateChange: any;
}

const AvatarEditor = (props) => {
  const { className, imageSrc, onCrop, onCancel, setEditorRef, scaleValue, rotateValue, onScaleChange, onRotateChange } = props;

  return (
    <div className={className}>
      <div className="avatarEditorContent">
        <ReactAvatarEditor
          image={imageSrc}
          width={600}
          height={600}
          border={50}
          color={[0, 0, 0, 0.5]} // RGBA
          scale={scaleValue}
          ref={setEditorRef}
          rotate={rotateValue}
          borderRadius={600}
          disableBoundaryChecks={false}
          style={{
            height: "auto",
            maxWidth: "650px",
            width: "100%"
          }}
        />
        <AvatarFooter className="avatarFooter">
          <div className="col-sm-7 col-md-7 col-lg-7">
            <Button className="cancelButton col-sm-8 col-md-8 col-lg-8" onClick={onCancel}>
              {props.labels.selectAnother}
            </Button>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <StyledRotateIcon
              className="fa fa-undo"
              onClick={() => {
                let newVal = 0;
                if (rotateValue >= 270) {
                  newVal = 0;
                } else {
                  newVal = rotateValue + 90;
                }
                onRotateChange(newVal);
              }}
            />
          </div>
          <div className="col-sm-7 col-md-7 col-lg-7">
            <StyledBar>
              <div>
                <span
                  className="col-md-2 zoom-out"
                  onClick={() => {
                    onScaleChange(Math.max(scaleValue - 1, 1));
                  }}
                >
                  -
                </span>
                <span className="col-md-18">
                  <input
                    type="range"
                    value={scaleValue}
                    min="1"
                    max="10"
                    onChange={(e) => onScaleChange(parseFloat(e.target.value))}
                  />
                </span>
                <span
                  className="col-md-2 zoom-in"
                  onClick={() => {
                    onScaleChange(Math.min(scaleValue + 1, 10));
                  }}
                >
                  +
                </span>
              </div>
            </StyledBar>
          </div>
          <div className="col-sm-8 col-md-8 col-lg-8">
            <Button className="cropButton col-sm-8 col-md-8 col-lg-8" id="cropButtonSubmit" onClick={onCrop}>
              {props.labels.savePhoto}
            </Button>
            <div className="fa fa-spinner fa-pulse" id="cropButtonLoader" />
          </div>
        </AvatarFooter>
      </div>
    </div>
  );
};

const mapResource = (getResource) => ({
  labels: {
    selectAnother: getResource("AvatarEditor.selectAnother", "Select Another"),
    savePhoto: getResource("AvatarEditor.savePhoto", "Save Photo")
  }
});

export default withResource(mapResource)(AvatarEditor);
