import * as React from 'react';
import styled from 'styled-components';
import { ImageDisplay } from './AssetDisplays';

export interface Container {
  downloadUrl?: any;
  file: any;
  onImageClick?: any;
  fileExtension?: string;
}

const FileDisplay = styled.div`
  width: 20%;
  display: inline-block;
  margin: 5px 10px 5px 10px;
  text-align: center;
`;

const FileContainer = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  height: 150px;
  position: relative;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.3s ease-out;
  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.75);
  }

  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
` as any;

const GridFile: React.SFC<Container> = (props) => {
  const { file, onImageClick } = props;
  let fileSource;

  const imageType = ['jpg', 'png', 'gif', 'jpeg', 'svg'] as any;
  const videoType = ['mp4', 'mov', 'ogg', 'wmv', 'flv'] as any;
  const docType = ['doc', 'docx'] as any;
  const xcelType = ['xls', 'xlsx'] as any;
  const pptType = ['ppt', 'pptx'] as any;
  const fontType = ['ttf', 'woff', 'eot'] as any;

  // figure out video display

  if (imageType.includes(file.fileExtension)) {
    fileSource = file.downloadUrl;
  } else if (videoType.includes(file.fileExtension)) {
    fileSource = './images/placeholder/video-placeholder.jpg';
  } else if (file.fileExtension === 'pdf') {
    fileSource = './images/placeholder/pdf-placeholder.png';
  } else if (docType.includes(file.fileExtension)) {
    fileSource = './images/placeholder/doc-placeholder.jpg';
  } else if (xcelType.includes(file.fileExtension)) {
    fileSource = './images/placeholder/xcel-placeholder.jpg';
  } else if (pptType.includes(file.fileExtension)) {
    fileSource = './images/placeholder/ppt-placeholder.jpg';
  } else if (fontType.includes(file.fileExtension)) {
    fileSource = './images/placeholder/font-placeholder.jpg';
  }

  return (
    <FileDisplay>
      <FileContainer onClick={() => onImageClick(file)}>
        <ImageDisplay imageSrc={fileSource} />
      </FileContainer>
      <div> {file.fileName}</div>
    </FileDisplay>
  );
};

export default GridFile as React.SFC<Container>;
