import * as React from 'react';
import { connect } from 'react-redux';
import { CultureDropdown } from 'rsv8-client';
import { getRequest, RequestTypes } from 'xcel-redux-orm';
import { emptyObject } from 'xcel-util';
import { contentCultureActions } from '../../../redux/actions';
import { contentCultureSelector, contentTypeSelector } from '../../../redux/selectors';

class ContentCultureDropdown extends React.Component<any> {
  handleChange = (cultureId: any) => {
    const { dispatch, contentTypeId } = this.props;
    dispatch(contentCultureActions.setActiveByCultureId(contentTypeId, cultureId));
  };
  render() {
    const { contentCulture, active, contentVersionsRequest, ...rest } = this.props;

    return (
      <CultureDropdown
        {...rest}
        label={contentVersionsRequest && contentVersionsRequest.loading === true ? 'Culture (Loading)' : 'Culture'}
        property={'id'}
        allowCultureChange={false}
        value={contentCulture.cultureId}
        onChange={this.handleChange}
      />
    );
  }
}
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);

  return {
    contentTypeId,
    contentCulture: contentCultureSelector.getActive(contentTypeId, state) || emptyObject,
    contentVersionsRequest: getRequest(state, 'getContentversionsByContentCultureId') as RequestTypes.Endpoint
  };
};

export default connect(mapStateToProps)(ContentCultureDropdown);
