import * as React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  height: 12px;
  position: absolute;
  right: 5px;
  width: 12px;
` as any;

const CloseIcon = () => (
  <SVG version="1.1" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#606060"
      d="M10.707 10.5l5.646-5.646c0.195-0.195 0.195-0.512 0-0.707s-0.512-0.195-0.707 0l-5.646 5.646-5.646-5.646c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l5.646 5.646-5.646 5.646c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l5.646-5.646 5.646 5.646c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-5.646-5.646z"
    />
  </SVG>
);

export { CloseIcon };
