import * as events from '../events';
import userApiActions from './userApiActions';
const clientActions = {
  getAdminClientClients: userApiActions.getClientAdminClients,
  setActive: (client) => {
    return {
      type: events.CLIENT_SET,
      payload: client
    };
  }
};

export default clientActions;
