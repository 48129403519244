import * as React from 'react';
import Switch from 'react-switch';
import {
  accountStyles as styles,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow
  } from 'rsv8-components';
import styled from 'styled-components';

const DesktopTableContainer = styled(styles.DesktopTableContainer)`
  th, td {
    text-align: left !important;
  }
`;

interface UserManagmentTableProps {
  data: any;
  loaded?: boolean;
  onActivationClick?: any;
  onSearch?: any;
  onSort?: any;
}

const UserManagmentTable = ({
  data,
  loaded,
  onActivationClick,
  onSearch,
  onSort,
  ...rest
}: UserManagmentTableProps) => {
  const handleActivation = (item, checked) => {
    item.active = checked;
    if (onActivationClick) {
      onActivationClick(item);
    }
  };

  function renderRows(items: any = []) {
    return items.map((item, index) => (
      <TableRow key={index} style={{ height: '60px' }}>
        <TableCell>{item.lastName}</TableCell>
        <TableCell>{item.firstName}</TableCell>
        <TableCell>{item.email}</TableCell>
        <TableCell>{item.repTypeName}</TableCell>
        <TableCell>
          <Switch
            onChange={(checked) => handleActivation(item, checked)}
            checked={item.active}
            onColor="#003595"
            offColor="#999999"
            onHandleColor="#fff"
            offHandleColor="#fff"
            handleDiameter={15}
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 9.5,
                  color: '#fff',
                  paddingRight: 20
                }}
              >
                Inactive
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 9.5,
                  color: '#fff',
                  paddingLeft: 20
                }}
              >
                Active
              </div>
            }
            height={20}
            width={65}
            disabled={!item.canBeEdited}
          />
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <DesktopTableContainer>
      {data !== undefined && data.length > 0 ? (
        <Table {...rest} striped={false} bordered={false} hover={true} condensed={true} onSort={onSort}>
          <thead>
            <TableRow>
              <TableHeaderCell field="lastName">Last Name</TableHeaderCell>
              <TableHeaderCell field="firstName">First Name</TableHeaderCell>
              <TableHeaderCell field="email">Email Address</TableHeaderCell>
              <TableHeaderCell field="role">Role</TableHeaderCell>
              <TableHeaderCell field="active">Status</TableHeaderCell>
            </TableRow>
          </thead>
          <tbody>
            {renderRows(data)}
          </tbody>
        </Table>
      ) : <styles.NoDataContainer>Additional users at your practice will appear here</styles.NoDataContainer>}
    </DesktopTableContainer>
  );
};

export default UserManagmentTable;
