import { destroy, destroyRequest, mapJsonApiMethodAction } from 'xcel-redux-orm';
import { nominationApi } from '../../../api/index';
import { TeamNomination } from '../../../types';
import * as events from '../../events';
import { teamNominationSelectors } from '../../selectors/index';

const saveNominationSuccess = (response) => ({
  type: events.SAVE_TEAM_NOMINATION_SUCCESS,
  payload: { id: response.data.id }
});

const submitNominationRequest = mapJsonApiMethodAction({
  method: nominationApi.updateTeamDraftByIdSubmit,
  endpoint: 'submitTeamNomination'
});

export const setClearNominationData = (value) => ({
  type: events.SET_CLEAR_CURRENT_NOMINATION_DATA,
  payload: value
});

export const submitNomination = () => async (dispatch, getState) => {
  const state = getState();
  const nominationId = teamNominationSelectors.getCurrentNominationId(state);
  if (nominationId) {
    try {
      // TODO: this is now broken, need to come back and fix this.
      await dispatch(
        submitNominationRequest({
          id: nominationId,
          model: teamNominationSelectors.getSaveTeamNominationRequest(state)
        } as any)
      );
      dispatch(destroy('teamNomination', nominationId.toString()));
    } catch (e) {
      throw e;
    }
  }
};

export interface NominationDetailsResponse {
  id: number;
  applicationKey: string;
  createDate: string;
  teamName: string;
  awardType: string;
  productType: string;
  applicationName: string;
  status: string;
  statusString: string;
  customFields: Array<{ id: string; values: string[] }>;
  incentiveValue: number;
  optionId: string;
  optionPointValue: number;
  incentive: any;
  product: any;
  teamMembers: Array<{
    userId: number;
    roleId: number;
    awardValue: number;
    customFields: Array<{ id: string; values: string[] }>;
  }>;
}
export const setNomination = (nomination: NominationDetailsResponse): TeamNomination.Action => {
  const { teamMembers } = nomination;
  return {
    type: events.SET_TEAM_NOMINATION,
    payload: {
      id: nomination.id,
      award: nomination.applicationKey,
      product: nomination.product,
      teamName: nomination.teamName,
      defaultAwardValue: teamMembers && teamMembers[0] ? teamMembers[0].awardValue : 0,
      defaultProductValue: teamMembers && teamMembers[0] ? teamMembers[0].awardValue : 0,
      defaultRoleId: teamMembers && teamMembers[0] ? teamMembers[0].roleId : 0,
      customFields: nomination.customFields,
      points: nomination.incentiveValue,
      optionId: nomination.optionId,
      optionPointValue: nomination.optionPointValue,
      incentive: nomination.incentive,
      clearCurrentNominationData: false,
      teamMembers: teamMembers
        ? nomination.teamMembers.reduce((acc, val) => ({ ...acc, [val.userId]: { id: val.userId, ...val } }), {})
        : {}
    }
  };
};

export const saveNomination = () => async (dispatch, getState) => {
  let response;
  const state = getState();
  const nominationId = teamNominationSelectors.getCurrentNominationId(state);
  if (nominationId) {
    response = await nominationApi.updateTeamDraftById({
      id: nominationId,
      model: teamNominationSelectors.getSaveTeamNominationRequest(state)
    } as any);
  } else {
    response = await nominationApi.createTeamDraft({
      model: teamNominationSelectors.getSaveTeamNominationRequest(state) as any
    });
  }

  dispatch(saveNominationSuccess(response));
  dispatch(destroyRequest('getTeamDraft'));
  // we have to destroy all because the createNomination doesn't return the nomination that changed
  // so we have no way to persist this in state.
  // TODO: Maybe we can manually update this? or make the data reducer more intelligent?
  dispatch(destroy('teamDraft', null));
};
