﻿import * as React from 'react';
import MediaQuery from 'react-responsive';
import { isNil } from 'lodash';
import { register, withContent, withResource, withTheme } from 'xcel-react-core';

interface Rebate {
  share: number;
  baseline: number;
  pts: number[];
}

interface Props {
  content: {
    pts?: number[];
    rebates?: Rebate[];
  };
  resource: {
    shareCaption: string;
    baselineCaption: string;
    ptCaption: string;
  };
  className?: string;
}

const MarketShareRebateTable: React.FC<Props> = ({
  content: { pts, rebates },
  resource: { shareCaption, baselineCaption, ptCaption },
  className,
}) => {
  if (isNil(pts) || isNil(rebates)) {
    return null;
  }

  return (
    <div className={className}>
      <MediaQuery query="(max-width:1365px)">
        {rebates.map((rebate, index) => (
          <div className="market-share-rebate-table-share">
            <div className="market-share-rebate-table-share-caption" key={index}>
              {shareCaption} {rebate.share}%
            </div>
            <div className="market-share-rebate-table-share-content">
              <div className="market-share-rebate-table-baseline">
                <div className="market-share-rebate-table-baseline-caption">Baseline</div>
                <div className="market-share-rebate-table-baseline-value">{rebate.baseline}%</div>
              </div>
              {pts.map((pt, ptIndex) => (
                <div className="market-share-rebate-table-pt" key={ptIndex}>
                  <div className="market-share-rebate-table-pt-caption">
                    +{pt} {ptCaption}
                  </div>
                  <div className="market-share-rebate-table-pt-value">{rebate.pts[ptIndex]}%</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </MediaQuery>
      <MediaQuery query="(min-width:1366px)">
        <div className="market-share-rebate-table-header">
          <div className="market-share-rebate-table-share-caption">{shareCaption}</div>
          {rebates.map((rebate, index) => (
            <div className="market-share-rebate-table-share-value" key={index}>
              {rebate.share}%
            </div>
          ))}
        </div>
        <div className="market-share-rebate-table-baseline">
          <div className="market-share-rebate-table-baseline-caption">Baseline</div>
          {rebates.map((rebate, index) => (
            <div className="market-share-rebate-table-baseline-value" key={index}>
              {rebate.baseline}%
            </div>
          ))}
        </div>
        {pts.map((pt, ptIndex) => (
          <div className="market-share-rebate-table-pt" key={ptIndex}>
            <div className="market-share-rebate-table-pt-caption">
              +{pt} {ptCaption}
            </div>
            {rebates.map((rebate, rebateIndex) => (
              <div className="market-share-rebate-table-pt-value" key={rebateIndex}>
                {rebate.pts[ptIndex]}%
              </div>
            ))}
          </div>
        ))}
      </MediaQuery>
    </div>
  );
};

const mapContentToProps = (getContent) => ({
  content: {
    pts: getContent('pts', { type: 'array', label: 'Pts' }),
    rebates: getContent('rebates', {
      type: 'array',
      label: 'Rebates',
      schema: [
        { model: 'share', type: 'number', label: 'Share' },
        { model: 'baseline', type: 'number', label: 'Baseline' },
        { model: 'pts', type: 'array', label: 'Pts' },
      ],
    }),
  },
});

const mapResourceToProps = (getResource) => ({
  resource: {
    shareCaption: getResource('alcon.marketShareRebateTable.shareCaption', 'Share'),
    baselineCaption: getResource('alcon.marketShareRebateTable.baselineCaption', 'Baseline'),
    ptCaption: getResource('alcon.marketShareRebateTable.ptCaption', 'pt'),
  },
});

export default register('alcon-data-vis/MarketShareRebateTable')(
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme()
)(MarketShareRebateTable);
