import ActivityStatus from './ActivityStatus';
/**
 * Find the new version when the API responses with its updated culture
 *
 * @param {array} - All new versions inside the culture
 * @return {object}
 */
export const findNewVersion: any = (versions: any[]) => {
  let newVersion: any;
  
  for(let itr = 0; itr < versions.length; itr++) {
    const version = versions[itr] || {};
    
    if(newVersion === undefined) {
      newVersion = version;
      continue
    } else {
      const { publishStatus, createdDateTimeUtc } = version;
      
      if(
        publishStatus === ActivityStatus.DRAFT &&
        Date.parse(createdDateTimeUtc) > Date.parse(newVersion.createdDateTimeUtc)
      ) {
        newVersion = version;
      }
    }
  }
  
  return newVersion;
}

let timeout;
export const debounce: any = (callback) => {
  clearInterval(timeout);
  timeout = setTimeout(() => callback(), 800);
}
  