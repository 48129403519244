import { mergeDeepRight } from 'ramda';
import * as events from '../events';
import componentReducer from './component';
export interface ContentState {
  active: {
    contentId?: string;
    versionId?: string;
  };
  raw: string;
  edit: {
    title?: string;
    description?: string;
    content?: any;
  };
}
const safeJSON = {
  stringify: (value: any, replacer: (key: string, value: any) => any = null, space: string | number = 2): string => {
    try {
      return JSON.stringify(value, replacer, space);
    } catch (e) {
      console.warn('failed to stringify JSON');
    }
    return undefined;
  },
  parse: (text: string, reviver?: (key: any, value: any) => any): any => {
    try {
      return JSON.parse(text, reviver);
    } catch (e) {
      console.warn('failed to parsing JSON');
    }
    return undefined;
  }
};
const contentReducer = (state: ContentState = { edit: { content: {} }, active: {}, raw: '' }, action: any) => {
  const { type, payload } = action;
  if (
    [
      events.COMPONENT_ADD,
      events.COMPONENT_DUPLICATE,
      events.COMPONENT_MOVE,
      events.COMPONENT_PROPERTY_SET,
      events.COMPONENT_REMOVE
    ].indexOf(type) !== -1
  ) {
    state.edit = componentReducer(state.edit, action);
  }
  state.raw = safeJSON.stringify(state.edit.content);
  switch (type) {
    case events.CONTENT_EDIT_PROPERTY_SET: {
      const { data } = payload;
      return { ...state, edit: mergeDeepRight(state.edit || {}, data || {}) };
    }
    case events.CONTENT_EDIT_SET: {
      if (!payload) return state;
      let { content, title, description } = payload;
      return { ...state, edit: { content, title, description } };
    }
    case events.CONTENT_ACTIVE_PROPERTY_SET: {
      const { property, value } = payload;
      return { ...state, active: { ...state.active, [property]: value } };
    }
    case events.CONTENT_RAW_SET: {
      const content = safeJSON.parse(payload);
      if (!content) {
        return state;
      }
      const raw = (content && safeJSON.stringify(content)) || payload;
      return { ...state, raw, edit: { ...state.edit, content } };
    }
    default: {
      return state;
    }
  }
};
export default contentReducer;
