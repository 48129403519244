import * as React from 'react';
import { connect } from 'react-redux';
import { getActiveRoute, routeSelector } from '../../redux/selectors';
import GridAssembler from './GridAssembler';

export interface AssetGrid {
  routes?: any;
  activeRouteId?: any;
  onImageClick?: any;
}

const AssetGrid: React.SFC<AssetGrid> = (props) => {
  const assetFile = props.routes.find((routeObj) => routeObj.id === props.activeRouteId);

  return assetFile !== undefined && <GridAssembler onImageClick={props.onImageClick} assetFile={assetFile} />;
};

const mapStateToProps = (state) => {
  return {
    activeRouteId: getActiveRoute(state),
    routes: routeSelector.selectMany(state, null)
  };
};

export default connect(
  mapStateToProps,
  null
)(AssetGrid);
