import { parse } from "query-string";
import * as React from "react";
import { BreadcrumbContainer, BreadcrumbIcon, BreadcrumbLink } from "./styles";

export interface BreadcrumbProps {
  location: any;
  product: any;
}

class Breadcrumb extends React.Component<BreadcrumbProps, any> {
  breadcrumbArray = () => {
    const { product, location } = this.props;
    const catId = parseInt(parse(location.search).filterCategories as string, 10);

    return product.categories
      .filter(
        (a) =>
          a.id === catId ||
          a.parentCategoryId === catId ||
          (Array.isArray(a.childCategories) && a.childCategories.filter((b) => b.id === catId).length > 0)
      )
      .sort((a, b) => a.id - b.id);
  };

  render() {
    const breadcrumbs = this.breadcrumbArray();

    return (
      <BreadcrumbContainer>
        {breadcrumbs.map((category, index) => {
          return (
            <React.Fragment key={category.id}>
              {category.level === 0 ? (
                <BreadcrumbLink to={`/catalog/category/${category.id}`}>{category.name}</BreadcrumbLink>
              ) : (
                <BreadcrumbLink to={`/catalog/search?filterCategories=${category.id}`}>{category.name}</BreadcrumbLink>
              )}
              {breadcrumbs.length - 1 !== index && <BreadcrumbIcon name="angle-right" />}
            </React.Fragment>
          );
        })}
      </BreadcrumbContainer>
    );
  }
}

export default Breadcrumb;
