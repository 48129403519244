import * as React from 'react';
import { connect } from 'react-redux';
import { Amount, GoalDto } from 'xcel-api-generator/dist/alcon';
import { register, withContent, withTheme } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { GoalContent, GoalType } from '../../constants';
import { getSummary } from '../../redux/actions';
import { amountSelector, dashboardSelector, goalSelector } from '../../redux/selectors';
import MarketShareGoal from './MarketShareGoal';
import SalesGoal from './SalesGoal';
import UnitsGoal from './UnitsGoal';
import * as styles from './style';

export const Goals: React.FC<Props> = ({
  chartWidgetSize = 90,
  completedLeftSideColor = '#00ae44',
  notcompletedLeftSideColor = '#00aeef',
  subTitleText,
  goalsContent,
  goals,
  amounts,
  className,
}) => {
  if (!goals || !amounts) {
    return <styles.GoalsContainer className={className}>No data</styles.GoalsContainer>;
  }

  return (
    <styles.GoalsContainer className={className}>
      {goals
        .sort((a, b) => a.sequence - b.sequence)
        .map((goal) => {
          const goalContent = !!goalsContent ? goalsContent.find((e) => e.id === goal.id) : undefined;

          switch (goal.goalType) {
            case GoalType.Sales:
              return (
                <SalesGoal
                  key={goal.id}
                  chartWidgetSize={chartWidgetSize}
                  completedLeftSideColor={completedLeftSideColor}
                  notcompletedLeftSideColor={notcompletedLeftSideColor}
                  subTitleText={subTitleText}
                  goalContent={goalContent}
                  goal={goal}
                />
              );
            case GoalType.Units:
              return (
                <UnitsGoal
                  key={goal.id}
                  chartWidgetSize={chartWidgetSize}
                  completedLeftSideColor={completedLeftSideColor}
                  notcompletedLeftSideColor={notcompletedLeftSideColor}
                  subTitleText={subTitleText}
                  goalContent={goalContent}
                  goal={goal}
                />
              );
            case GoalType.MarketShare:
              return (
                <MarketShareGoal
                  key={goal.id}
                  chartWidgetSize={chartWidgetSize}
                  completedLeftSideColor={completedLeftSideColor}
                  notcompletedLeftSideColor={notcompletedLeftSideColor}
                  subTitleText={subTitleText}
                  goalContent={goalContent}
                  goal={goal}
                />
              );
            default:
              return null;
          }
        })}
    </styles.GoalsContainer>
  );
};

/**
 * Content that the admin can enter to change this component
 * @param getContent
 */
const mapContentToProps = (getContent) => ({
  chartWidgetSize: getContent('chartWidgetSize', { type: 'number', label: 'Chart Size (value in px)' }),
  subTitleText: getContent('subTitleText', { type: 'string', label: 'Subtitle Text' }),

  goalsContent: getContent('goalsContent', {
    type: 'array',
    schema: [
      { model: 'id', label: 'Goal key' },
      { model: 'goalTitle', label: 'Title of the goal' },
      { model: 'goalTitleColor', label: 'Color of title of the goal' },
    ],
  }),
  completedLeftSideColor: getContent('completedLeftSideColor', {
    type: 'none',
    label: 'background color of the right side if completed',
  }),
  notcompletedLeftSideColor: getContent('notcompletedLeftSideColor', {
    type: 'none',
    label: 'background color of the right side if not completed',
  }),
});

export const mapStateToProps = (state) => ({
  goals: goalSelector.selectMany(state),
  amounts: amountSelector.selectMany(state),
});

interface StateProps {
  goals?: GoalDto[];
  amounts?: Amount[];
}
interface ContentProps {
  chartWidgetSize?: number;
  completedLeftSideColor?: string;
  notcompletedLeftSideColor?: string;
  subTitleText?: string;
  goalsContent?: GoalContent[];
}
type Props = React.HTMLAttributes<any> & StateProps & ContentProps;

export default register('alcon-data-vis/Goals')(
  connect(mapStateToProps),
  withApiDataProvider(getSummary, dashboardSelector),
  withTheme(),
  withContent(mapContentToProps)
)(Goals);
