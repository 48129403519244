import * as moment from 'moment';
import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Link, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { getCurrentAccountSummary } from '../../redux/selectors';
import { CardContainer } from '../CardContainer';
import { FavoritesButton } from '../FavoritesButton';
import {
  Brand,
  CardImage,
  Details,
  ImageContainer,
  ImageLink,
  PointsNeeded,
  PointsNeededEmpty,
  PointsRow,
  PrevPrice,
  ProductContainer,
  ProductName,
  PromoDate
  } from './styles';
import { CarouselCardProps } from './types';

class CarouselCard extends React.Component<CarouselCardProps, any> {
  showPointsNeeded = () => {
    const { product, pointsNeededLabel, accountSummary } = this.props;

    if (
      accountSummary &&
      accountSummary.hasPointRestrictions &&
      (product.isDigitalCard || product.isGyftProduct || product.nonTangible)
    ) {
      const userTaxable = accountSummary.available.taxable;
      if (userTaxable - product.pointValue < 0) {
        return (
          <PointsNeeded>{`${(product.pointValue - userTaxable).toLocaleString()} ${pointsNeededLabel}`}</PointsNeeded>
        );
      }
    } else {
      const currentPoints = accountSummary.available.total;
      if (currentPoints - product.pointValue < 0) {
        return (
          <PointsNeeded>{`${(product.pointValue - currentPoints).toLocaleString()} ${pointsNeededLabel}`}</PointsNeeded>
        );
      }
    }
    return <PointsNeededEmpty />;
  };

  render() {
    const {
      favoritesList,
      product,
      searchParams,
      viewDetailsLabel,
      pointValueLabel,
      promoWasPreLabel,
      promoEndsLabel,
      promoWasPostLabel,
      contactUsForDetails
    } = this.props;

    return (
      <CardContainer>
        <ImageContainer>
          <ImageLink to={`/catalog/product/${encodeURIComponent(product.id)}${searchParams ? searchParams : ''}`}>
            <CardImage imageurl={product.imageLg} />
          </ImageLink>
        </ImageContainer>
        <ProductContainer>
          <Brand themeVariation="secondary-text-link" to={`/catalog/search?filterBrands=${product.manufacturerId}`}>
            {product.manufacturer}
          </Brand>
          <ProductName
            themeVariation="text-link"
            to={`/catalog/product/${encodeURIComponent(product.id)}${searchParams ? searchParams : ''}`}
            dangerouslySetInnerHTML={{ __html: product.productGroupName || product.name || product.productName }}
          />
        </ProductContainer>
        <PointsRow>
          {product.groupedProducts && product.groupedProducts.length > 0 ? (
            <div>
              <Text themeVariation="primary-lg-bold-text">
                {`${product.productGroupMinPoints} - ${product.productGroupMaxPoints} ${pointValueLabel}`}
              </Text>
            </div>
          ) : (
            <div>
              {product.pointValue > 0 ? (
                <Text themeVariation="primary-lg-bold-text">{`${product.pointValue.toLocaleString()} ${pointValueLabel}`}</Text>
              ) : (
                <Link themeVariation="default" href={`${window.location.search}#zero-point`}>
                  {contactUsForDetails}
                </Link>
              )}

              {product.listPrice > 0 && product.promotionEndDate && (
                <React.Fragment>
                  <PrevPrice>{`${promoWasPreLabel} ${parseFloat(
                    product.listPrice
                  ).toLocaleString()} ${promoWasPostLabel}`}</PrevPrice>
                  <PromoDate>{`${promoEndsLabel} ${moment(product.promotionEndDate).format('MM/DD/YY')})`}</PromoDate>
                </React.Fragment>
              )}
            </div>
          )}
          {this.showPointsNeeded()}
          <Details
            themeVariation="details-link"
            to={`/catalog/product/${encodeURIComponent(product.id)}${searchParams ? searchParams : ''}`}
          >
            {viewDetailsLabel} <Icon name="angle-right" />
          </Details>
        </PointsRow>
        <FavoritesButton favorites={favoritesList} product={product} />
      </CardContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  accountSummary: getCurrentAccountSummary(state)
});

const mapResourceToProps = (getResource) => ({
  pointsNeededLabel: getResource('catalog.card.pointsNeededLabel', 'pts. needed'),
  pointValueLabel: getResource('catalog.card.pointValueLabel', 'pts.'),
  promoWasPreLabel: getResource('catalog.card.promoWasPreLabel', 'Was'),
  promoWasPostLabel: getResource('catalog.card.promoWasPostLabel', 'pts.'),
  promoEndsLabel: getResource('catalog.card.promoEndsLabel', '(Offer ends'),
  viewDetailsLabel: getResource('catalog.card.viewDetailsLabel', 'View Details'),
  contactUsForDetails: getResource('catalog.card.contactUsForDetails', 'Contact Us for Details')
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  withRouter,
  withResource(mapResourceToProps),
  connect(mapStateToProps, mapDispatchToProps)
)(CarouselCard) as any;
