import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;
const Input = styled.input`
  width: 100%;
  padding-left: 5px;
`;

interface Props {
  show: boolean;
  handleCreate: Function;
}

interface State {
  value: string;
}

class ComponentCustomSelectorModal extends React.Component<Props, State> {
  state = {
    value: '',
  };
  render() {
    const { show, handleCreate } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        <Modal show={show} onHide={() => this.setState({ value: '' })}>
          <Container>
            <Input
              type="text"
              value={value}
              onChange={({ target: { value } }) => this.setState({ value })}
              placeholder="Please enter a valid css selector"
            />
            <Button bsStyle="primary" onClick={() => handleCreate(value)}>
              Create
            </Button>
          </Container>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ComponentCustomSelectorModal;
