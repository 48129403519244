import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withResource } from 'xcel-react-core';
import Modal from '../Modal/Modal';
import OrderDetailsInfo from './OrderDetailsInfo';
import { toggleOrderDetailsModal, getAchievementRedemption } from '../../redux/actions';
import {
  getOrderDetailsModalVisibility,
  getProgramLevelProduct,
  getProgramLevel
} from '../../redux/selectors';
import { getOrderInfo } from '../../redux/selectors/achievementSelectors';
import { ProductDetailsImage, ModalText } from './styles';
import { OrderDetailsModalProps } from './types';
import { HeaderTwo, HeaderThree } from 'rsv8-components';

const OrderDetails: React.SFC<OrderDetailsModalProps> = ({
  toggleModal,
  title,
  getOrder,
  product,
  congratsTextBottom,
  programLevel,
  order,
  ...rest
}) => {
  React.useEffect(() => void getOrder(programLevel.achievementId), []);
  if (!order) {
    return null;
  }
  const { imageLg } = Array.isArray(product.images) ? product.images[0] : product.images;
  return (
    <Modal modalHeading={title} toggleModal={toggleModal} {...rest}>
      <Row>
        <Col md={12}>
          <ProductDetailsImage src={imageLg} alt={product.productName} />
        </Col>
        <Col md={12}>
          <HeaderThree>{programLevel.name}</HeaderThree>
          <HeaderTwo>{product.name || product.productName}</HeaderTwo>
          <ModalText themeVariation="text-black">{product.description}</ModalText>
          <OrderDetailsInfo {...order} />
          <ModalText themeVariation="text-black">{congratsTextBottom}</ModalText>
        </Col>
      </Row>
    </Modal>
  );
};

const mapState = (state) => {
  return {
    show: getOrderDetailsModalVisibility(state),
    product: getProgramLevelProduct(state),
    programLevel: getProgramLevel(state),
    order: getOrderInfo(state)
  };
};

const mapDispatch = (dispatch) => bindActionCreators({
  toggleModal: toggleOrderDetailsModal,
  getOrder: getAchievementRedemption
}, dispatch);

const mapResourceToProps = (getResource) => ({
  title: getResource('achievement.orderDetailsModal.title', 'Order Details'),
  congratsTextBottom: getResource('achievement.productDetailsModal.congratsText.bottom', '')
});

export default compose<any>(
  connect<ReturnType<typeof mapState>, ReturnType<typeof mapDispatch>, any>(mapState, mapDispatch),
  withResource(mapResourceToProps)
)(OrderDetails);
