import * as React from 'react';
import { connect } from 'react-redux';
import {
  ContentCultureDropdown,
  PublishButton,
  SourceButton,
  SourceEditor,
  TranslateButton,
  VersionDropdown
  } from 'rsv8-cms';
import { ReactField } from 'rsv8-forms';
import styled from 'styled-components';
import { registry } from 'xcel-react-core';
import { flattenObject } from 'xcel-util';
import resourceActions from '../../redux/resource.actions';
import resourceSelector from '../../redux/resource.selector';
// const ActionBar = styled.div`
//   position: absolute;
//   right: 0px;
//   top: 0;
//   cursor: pointer;
//   .fa-trash {
//     color: red;
//   }
// `;
const Property = styled.div`
  position: relative;
  font-size: 12px;
  label {
    font-weight: bold;
  }
  textarea.form-control {
    font-size: 12px;
  }
`;

let defaults = flattenObject(registry.get().resources);
defaults = Object.keys(defaults).reduce((obj: any, next: any) => {
  obj[next] = { value: obj[next] };
  return obj;
}, defaults);
class ResourceEdit extends React.Component<any> {
  state = {
    search: '',
    sourceOpen: false
  };
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(resourceActions.adminGet());
    dispatch(resourceActions.setActive());
  }
  handleSearchChange = (value) => {
    this.setState({ search: value.trim() });
  };
  handleOpenSource = () => {
    this.setState({ sourceOpen: !this.state.sourceOpen });
  };
  handlePropertySet = (property) => (e) => {
    const value = e.target.value;
    const { dispatch, resources } = this.props;
    dispatch(resourceActions.setProperty({ ...defaults, ...resources, [property]: { value } }));
  };
  render() {
    let resources = { ...defaults, ...this.props.resources };
    return (
      <div>
        <div className="row">
          <SourceEditor visible={this.state.sourceOpen} />
          <div className="col-md-8">
            <ReactField
              component="bs-input"
              label="Search"
              onChange={this.handleSearchChange}
              placeholder="Search for resource..."
            />
          </div>
          <div className="col-md-8">
            <VersionDropdown />
          </div>
          <div className="col-md-8">
            <ContentCultureDropdown />
          </div>
          <SourceButton onClick={this.handleOpenSource} />
          <PublishButton />
          <TranslateButton />
        </div>
        <div className="row">
          {resources &&
            Object.keys(resources)
              .filter((property) => {
                const noSearch = this.state.search === '';
                const matchProperty = property.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
                const value = resources[property] && resources[property].value;
                const matchValue = String(value).toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
                return noSearch || matchProperty || matchValue;
              })
              .sort((a, b) => (a === b ? 0 : a > b ? 1 : -1))
              .map((property: any, index) => {
                const value = resources[property] !== undefined ? resources[property].value : property;
                return (
                  <div key={property} className="col-md-12 col-lg-8">
                    <Property className="form-group">
                      <label>{property}</label>
                      {/* <ActionBar>
                        {active[property].canDelete && (
                          <i className="fa fa-trash" onClick={this.handleResourcePropertyDelete(property)} />
                        )}
                      </ActionBar> */}

                      <textarea className="form-control" onChange={this.handlePropertySet(property)} value={value} />
                    </Property>
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resources: resourceSelector.getProperties(state)
});
export default connect(mapStateToProps)(ResourceEdit);
