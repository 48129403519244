import * as React from 'react';
import { connect } from 'react-redux';
import { userStateSelectors } from 'rsv8-auth';
import { Image, InitialsImage } from 'rsv8-components';
import {
  register,
  withContent,
  withResource,
  withTheme
  } from 'xcel-react-core';
import Points from '../UserPoints';
import {
  Abbrev,
  InfoAside,
  PointsBadge,
  UserName,
  UserStoryStyle
  } from './styles';

const UserStory = ({ user, userPointsLabel, ...rest }) => (
  <UserStoryStyle {...rest}>
    <InitialsImage {...user} width={'140px'} />
    <InfoAside>
      <UserName className="username">
        {user && user.firstName} {user && user.lastName}
      </UserName>
      <PointsBadge className={'points-badge'}>
        <Image themeVariation="points-ribbon" />
        <div>
          <Points />
          {userPointsLabel && <Abbrev> {userPointsLabel}</Abbrev>}
        </div>
      </PointsBadge>
    </InfoAside>
  </UserStoryStyle>
);

const mapStateToProps = (state) => ({
  user: userStateSelectors.getUser(state)
});

const mapResourceToProps = (getResource) => ({
  userPointsLabel: getResource('account.userPointsLabel', 'pts')
});

export default register('rsv8-account/UserStory')(
  connect(mapStateToProps),
  withResource(mapResourceToProps),
  withContent(),
  withTheme(['.username'])
)(UserStory);
