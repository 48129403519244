import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register, withResource } from 'xcel-react-core';
import { teamNominationSelectors } from '../../redux/index';
import { nomineeSearch } from '../../redux/actions/index';
import NomineeLayout from './NomineeLayout';

class NominationSearch extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  
  componentDidMount() {
    const { wizard, actions, currentNominee, currentAward } = this.props;
    if (wizard) {
      wizard.registerIncrement(this.checkValid);
    }

    if (!currentNominee || !currentNominee.length) {
      actions.nomineeSearch({ applicationId: currentAward });
    }
  }

  checkValid = () => {
    const { currentNominee } = this.props;
    if (currentNominee.length > 1) {
      this.setState({ hasError: false });
      return Promise.resolve();
    } else {
      this.setState({ hasError: true });
      return Promise.reject('Please select a nominee.');
    }
  };

  render() {
    return (
      <NomineeLayout
        currentNominee={this.props.currentNominee}
        hasError={this.state.hasError}
        errorText={this.props.errorText}
      />
    );
  }
}

const mapState = (state) => {
  return {
    currentNominee: teamNominationSelectors.getCurrentNomineeIds(state),
    currentAward: teamNominationSelectors.getCurrentAwardId(state),
  };
};

const mapDispatch = (dispatch) => ({ actions: bindActionCreators({ nomineeSearch }, dispatch) });

const mapResource = (getResource) => ({
  errorText: getResource('teamNomination.selectNomineeError', 'Please Select at least two Nominees')
});

const compose = register('rsv8-nomination/TeamSelectNominee');

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  withResource(mapResource)
)(NominationSearch);
