import { promiseDispatcher } from 'xcel-react-core';

import { createJsonApiActions } from 'xcel-redux-orm';

import { metricApiAction } from '../../api';

const actions = createJsonApiActions('getUserMetricDataByUserMetricKeyFromDate');

const getUserMetricDataByUserMetricKeyFromDateCall = async ({ metricKey, userMetricKey, programKey, date }) =>
  await metricApiAction.getUserMetricDataByUserMetricKeyFromDate({
    metricKey,
    userMetricKey,
    programKey,
    date
  }, { jsonApi: true });
const getUserMetricDataByUserMetricKeyFromDate = promiseDispatcher(getUserMetricDataByUserMetricKeyFromDateCall, actions);
export { getUserMetricDataByUserMetricKeyFromDate };
