import { push } from 'connected-react-router';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HeaderOne, Image } from 'rsv8-components';
import { SubmissionError } from 'rsv8-forms';
import { withResource } from 'xcel-react-core';
import { register, withContent, withTheme } from 'xcel-react-core';
import { LayoutContainer } from '../../../components/Layout';
import { authActions, userStateSelectors } from '../../../module';
import userApiActions from '../../redux/actions/userApiActions';
import passwordExpressionSelector from '../../redux/selectors/passwordExpression';
import { userPasswordChange } from '../../redux/user.actions';
import ChangePasswordForm from './ChangePasswordForm';

interface ChangePasswordProps {
  dispatch: any;
  disableShowPassword: boolean;
  errorMessageNotEqualText: string;
  errorMessageSamePasswordText: string;
  errorMessageIncorrectPasswordText: string;
  changePasswordConfirmationUrl: string;
  changePasswordHeaderText: string;
  passwordExpressions: any;
  passwordResources: any;
  resetPasswordSuccessRedirectUrl: string;
  mustResetPassword: boolean;
  user: any;
  className: string;
}

class ChangePassword extends React.Component<ChangePasswordProps, any> {
  mustResetPassword = false;

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(userApiActions.getPasswordExpressions());
    this.mustResetPassword = user && user.mustResetPassword;
  }

  handleSubmit = (params) => {
    const { currentPassword, password, passwordConfirmation } = params;
    const {
      dispatch,
      changePasswordConfirmationUrl,
      errorMessageSamePasswordText,
      errorMessageNotEqualText,
      resetPasswordSuccessRedirectUrl
    } = this.props;
    const passwordExpressions = this.getPasswordExpressions();
    let _errors: any = [];
    if (password && passwordConfirmation && password !== passwordConfirmation) {
      _errors.push(errorMessageNotEqualText);
    }
    if (password && currentPassword && password === currentPassword) {
      _errors.push(errorMessageSamePasswordText);
    }
    _errors = [
      ..._errors,
      ...passwordExpressions
        .filter((exp) => {
          if (exp.expression === '' || exp.expression === null) {
            return false;
          }
          const regex = new RegExp(exp.expression);
          if (!regex.test(password) || !regex.test(passwordConfirmation)) {
            return true;
          }
          return false;
        })
        .map((exp) => exp.resourceError)
    ];
    if (_errors.length > 0) {
      throw new SubmissionError({
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
        _error: _errors
      });
    }
    return dispatch(userPasswordChange({ body: { newPassword: password, oldPassword: currentPassword } }))
      .then(async () => {
        if (this.mustResetPassword) {
          await dispatch(authActions.user.userLogout());
          dispatch(push(resetPasswordSuccessRedirectUrl));
        } else {
          dispatch(push(changePasswordConfirmationUrl));
        }
      })
      .catch((e) => {
        const errors = e?.response?.data?.errors;
        if (errors) {
          const errorList = errors
            .filter(error => error.title !== undefined)
            .map(error => error.title);
          throw new SubmissionError({
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
            _error: errorList.map((error) => this.props.passwordResources[error])
          });
        }
      });
  };
  getPasswordExpressions = () => {
    const { passwordExpressions, passwordResources } = this.props;
    return passwordExpressions.map((expression) => {
      return {
        ...expression,
        resourceError: passwordResources[expression.resourceError] || expression.resourceError,
        resourceLabel: passwordResources[expression.resourceLabel] || expression.resourceLabel
      };
    });
  };
  render() {
    const { changePasswordHeaderText, disableShowPassword } = this.props;
    return (
      <div className={this.props.className}>
        <LayoutContainer>
          <Row>
            <Col xs={22} xsOffset={1} sm={18} smOffset={3} md={12} mdOffset={6} lg={8} lgOffset={8}>
              <Image themeVariation="icon-reset-password" />
            </Col>
          </Row>
          <Row>
            <Col xs={22} xsOffset={1} sm={22} smOffset={1} md={12} mdOffset={6} lg={8} lgOffset={8}>
              <HeaderOne textalign="center" variationName="no-margins">
                {changePasswordHeaderText}
              </HeaderOne>
            </Col>
          </Row>
          <Row>
            <Col xs={22} xsOffset={1} sm={18} smOffset={3} md={12} mdOffset={6} lg={8} lgOffset={8}>
              <ChangePasswordForm
                passwordExpressions={this.getPasswordExpressions()}
                disableShowPassword={disableShowPassword}
                onSubmit={this.handleSubmit}
              />
            </Col>
          </Row>
        </LayoutContainer>
      </div>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  changePasswordConfirmationUrl: getResource(
    'changePassword.changePasswordConfirmationUrl',
    '/users/change-password-confirmation'
  ),
  resetPasswordSuccessRedirectUrl: getResource(
    'resetPassword.resetPasswordSuccessRedirectUrl',
    '/reset-password/confirmation'
  ),
  changePasswordHeaderText: getResource('changePassword.changePasswordHeaderText', 'Change your password'),
  errorMessageNotEqualText: getResource('resetPassword.errorMessageNotEqualText', 'Password not match'),
  errorMessageSamePasswordText: getResource(
    'changePassword.errorMessageSamePasswordText',
    'Must choose a new password'
  ),

  passwordResources: [
    'password_length',
    'password_uppercase',
    'password_lowercase',
    'password_number',
    'password_username',
    'password_repeating',
    'password_numeric_seq',
    'password_alpha_seq',
    'password_invalid_seq'
  ].reduce(
    (dic, key) => {
      const label = `${key}_label`;
      const error = `${key}_error`;
      dic[label] = getResource(label, label);
      dic[error] = getResource(error, error);
      return dic;
    },
    { password_invalid_old_password: getResource('password_invalid_old_password', 'password_invalid_old_password') }
  )
});

const mapContentToProps = (getContent) => ({
  disableShowPassword: getContent('disable', { type: 'checkbox', label: 'Disable Show Password' })
});
const mapStateToProps = (state) => ({
  passwordExpressions: passwordExpressionSelector.selectMany(state, null),
  user: userStateSelectors.getUser(state)
});
export default register('rsv8-auth/ChangePassword')(
  withContent(mapContentToProps),
  withResource(mapResourceToProps),
  withTheme(),
  connect(mapStateToProps)
)(ChangePassword);
