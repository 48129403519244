import * as React from 'react';
import { Form } from 'react-bootstrap';
import { ReactField } from 'rsv8-forms';
import { FieldLabel } from '../../../../../components/Label';
import {
  CategoryContainer,
  CategoryFieldContainer,
  SubCategory,
  SubCategoryFieldContainer
  } from '../../../styles';

export interface CategoryFilterProps {
  categories: any;
  changeCategory: any;
  currentlyCheckedCategories: any;
}

class CategoryFilter extends React.Component<CategoryFilterProps, any> {
  render() {
    const { categories, changeCategory, currentlyCheckedCategories } = this.props;

    return (
      <Form>
        {categories &&
          categories.map((category) => (
            <CategoryContainer key={category.id}>
              <CategoryFieldContainer>
                <ReactField
                  component={'checkbox'}
                  label={
                    <FieldLabel
                      color={`#606060`}
                      isCheckbox={true}
                      isBold={true}
                      labelName={`${category.name} (${category.productCount})`}
                    />
                  }
                  name={`category-${category.id}`}
                  onChange={() => changeCategory(category.id)}
                  value={
                    currentlyCheckedCategories && currentlyCheckedCategories.includes(category.id.toString())
                      ? true
                      : false
                  }
                />
              </CategoryFieldContainer>
              {category.children.map((subCategory) => (
                <SubCategory key={subCategory.id}>
                  <SubCategoryFieldContainer>
                    <ReactField
                      component={'checkbox'}
                      label={
                        <FieldLabel
                          isCheckbox={true}
                          isBold={false}
                          labelName={`${subCategory.name} (${subCategory.productCount})`}
                        />
                      }
                      name={`category-${subCategory.id}`}
                      onChange={() => changeCategory(subCategory.id)}
                      value={
                        currentlyCheckedCategories && currentlyCheckedCategories.includes(subCategory.id.toString())
                          ? true
                          : false
                      }
                    />
                  </SubCategoryFieldContainer>
                </SubCategory>
              ))}
            </CategoryContainer>
          ))}
      </Form>
    );
  }
}

export default CategoryFilter;
