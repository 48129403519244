import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HorizontalRule, Text } from 'rsv8-components';
import styled from 'styled-components';

export const ExtraSpace = styled.br`
  @media (max-width: 767px) {
    display: none !important;
  }
`;

export const AlignedCol = styled(Col)<{ align?: string; padding?: string }>`
  text-align: ${(props) => (props.align ? props.align : 'center')};
  ${(props) => (props.padding ? `padding: ${props.padding};` : null)};
`;

export const HeaderRow = styled(Row)<{ color?: string }>`
  margin: 0 auto;
  padding: 20px 40px;
  background-color: ${(props) => (props.color ? props.color : '#f0fbff')};
  border-radius: 10px 10px 0px 0px;

  .overlay-popover-icon {
    position: static;
  }
`;
export const HeaderRowWithBackground = styled(HeaderRow)<{ background: string }>`
  padding: 30px 20px;
  background-color: transparent;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media (min-width: 1366px) {
    padding: 35px 40px 35px;
  }

  img {
    max-height: 63px;
  }
`;

export const ContentRow = styled(Row)<{ padding?: string }>`
  margin: 0 auto;
  padding: ${(props) => (props.padding ? props.padding : '40px 0')};
`;

export const StyledNames = styled(Text)<{ fontSize?: number; lineHeight?: number }>`
  display: block;
  margin: 10px auto;
  font-weight: bold;
  font-size: ${(props) => (props.fontSize ? props.fontSize + 'px' : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight + 'px' : '20px')};
  letter-spacing: 0px;
  color: #505d6f;
`;

export const StyledSign = styled(Text)`
  display: block;
  margin: 10px auto;
  font-weight: bold;
  font-size: 20px;
  line-height: 140px;
  letter-spacing: 0px;
  color: #505d6f;

  @media (max-width: 767px) {
    margin: 0 auto;
    line-height: 20px;
  }
`;

export const ProjectionValue = styled.div<{ fontSize?: number; lineHeight?: number; color?: string; textColor?: string }>`
  padding: 10px;
  font-weight: bold;
  font-size: ${(props) => (props.fontSize ? props.fontSize + 'px' : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight + 'px' : '24px')};
  color: ${(props) => (props.textColor ? props.textColor : props.color ? '#FFFFFF' : '#505d6f')};
  background-color: ${(props) => (props.color ? props.color : '#efefef')};
  border-radius: 20px;

  .form-group {
    margin-bottom: 0;
    padding: 0 20px;
  }

  .form-control {
    display: block;
    text-align: center;
    width: 100%;
    height: auto;
    padding: 0;
    font-size: ${(props) => (props.fontSize ? props.fontSize + 'px' : '20px')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight + 'px' : '24px')};
    color: ${(props) => (props.color ? '#FFFFFF' : '#505d6f')};
    background-color: transparent;
    background-image: none;
    border: none;
    border-bottom: 1px solid ${(props) => (props.color ? '#FFFFFF' : '#505d6f')};
    border-radius: 0;
    box-shadow: none;
  }

  .help-block {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0;
  }

  /* Close number buttons */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const SuggestedShareValue = styled(ProjectionValue)`
  display: flex;
  justify-content: center;

  .form-group {
    padding: 0;
  }

  .form-control {
    position: relative;
    width: 68px;
    text-align: center;
  }
`;

export const CurrentValue = styled.div<{ fontSize?: number; lineHeight?: number; color?: string }>`
  padding: 10px;
  font-weight: bold;
  font-size: ${(props) => (props.fontSize ? props.fontSize + 'px' : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight + 'px' : '24px')};
  color: ${(props) => (props.color ? props.color : '#505d6f')};
`;

export const StyledNote = styled(Text)<{ margin?: string }>`
  display: block;
  margin: ${(props) => (props.margin ? props.margin : '10px auto')};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #505d6f;
`;

const BranchingTable = styled.table`
  width: 100%;
  font-size: 16px;
  line-height: 16px;

  @media (max-width: 767px) {
    display: none;
  }
`;
const BranchingCell = styled.td<{ borderTop?: boolean; borderLeft?: boolean }>`
  ${(props) => (props.borderTop ? 'border-top: 2px solid #d9d9d9;' : '')}
  ${(props) => (props.borderLeft ? 'border-left: 2px solid #d9d9d9;' : '')}
`;
export const BranchingImage = () => (
  <BranchingTable>
    <tbody>
      <tr>
        <BranchingCell>&nbsp;</BranchingCell>
        <BranchingCell>&nbsp;</BranchingCell>
        <BranchingCell>&nbsp;</BranchingCell>
        <BranchingCell borderLeft={true}>&nbsp;</BranchingCell>
        <BranchingCell>&nbsp;</BranchingCell>
        <BranchingCell>&nbsp;</BranchingCell>
      </tr>
      <tr>
        <BranchingCell>&nbsp;</BranchingCell>
        <BranchingCell borderTop={true} borderLeft={true}>
          &nbsp;
        </BranchingCell>
        <BranchingCell borderTop={true}>&nbsp;</BranchingCell>
        <BranchingCell borderTop={true} borderLeft={true}>
          &nbsp;
        </BranchingCell>
        <BranchingCell borderTop={true}>&nbsp;</BranchingCell>
        <BranchingCell borderLeft={true}>&nbsp;</BranchingCell>
      </tr>
    </tbody>
  </BranchingTable>
);

export const StyledHorizontalRule = styled(HorizontalRule)<{ width?: string; margin?: string }>`
  border-top: 2px solid #d9d9d9;
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

export const HiddenContainer = styled.div<{ visible: boolean }>`
  ${(props) => (!props.visible ? 'display: none !important;' : '')};
`;
export const HiddenButton = styled.button`
  display: none;
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  font-size: 18px;
  line-height: 18px;
  color: #bdbebf;
  background-color: transparent;
  border: none;
`;

export const StyledGoalContainer = styled.div`
  margin: 20px auto;
  display: grid;
  grid-gap: 10px;
  justify-content: center;

  @media (max-width: 991px) {
    grid-template-columns: 300px;
    grid-auto-rows: 164px;
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 280px);
  }

  @media (min-width: 1366px) {
    padding: 0 100px;
    grid-template-columns: repeat(3, 360px);
  }
`;

export const StyledGoal = styled.button<{ selected: boolean }>`
  position: relative;
  padding: 40px;
  font-weight: bold;
  color: #00aeef;
  background-color: ${(props) => (props.selected ? '#003595' : '#ffffff')};
  border: 2px solid #00aeef;
  border-radius: 24px;

  span {
    display: block;
  }

  .goal-checkmark {
    display: ${(props) => (props.selected ? 'block' : 'none')};
    position: absolute;
    top: -18px;
    right: -18px;
    padding: 8px;
    font-size: 20px;
    line-height: 20px;
    color: #00ae44;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 100%;
  }
`;

export const StyledGoalNote = styled.span`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const StyledGoalName = styled.span`
  font-size: 20px;
  line-height: 24px;
`;

export const StyledGoalDescription = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const StyledProductContainer = styled.div`
  margin: 0 auto;
  display: grid;
  justify-content: center;

  @media (max-width: 991px) {
    grid-gap: 40px;
    grid-template-columns: 300px;
  }

  @media (min-width: 992px) {
    padding: 0;
    grid-gap: 28px;
    grid-template-columns: repeat(2, 300px);
  }

  @media (min-width: 1366px) {
    grid-template-columns: repeat(4, 300px);
  }

  .product-family {
    position: relative;
    margin: 0 0 10px 0;
    text-align: center;
  }

  .product-family-name {
    margin: 0 0 30px 0 !important;
  }

  .product-family-image {
    height: 137px;
  }

  .product-family-note {
    position: relative;
    bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #00aeef;
  }

  .product-type {
    margin-bottom: 10px;
    background-color: #efefef;
    border-radius: 26px;
  }

  .product-type-name {
    padding: 16px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #00aeef;
    background-color: #003595;
    border-radius: 26px 26px 0px 0px;
    text-transform: capitalize;
  }

  .product-type-value {
    width: 200px;
    margin: 0 auto;
    padding: 20px 0;
  }

  .patients-label {
    margin: 0 auto 16px;
  }

  .patients-value {
    background-color: #ffffff;
  }
`;

export const PopoverContent = styled(Text)<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : '#505d6f')};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
`;
