import styled from 'styled-components';

const SideBar = styled.div`
  flex: 0 0 350px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
` as any;

export default SideBar;
