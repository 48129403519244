import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Icon from 'react-fontawesome';
import Slider from 'react-slick-deux';
import { Loader } from 'rsv8-components';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  CarouselArrow,
  ItemCarouselContainer,
  ItemContainer,
  LoadingContainer
  } from './styles';
import { ItemCarouselProps } from './types';
import CarouselRewardCard from '../CarouselCard/CarouselCard';

const CarouselPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-left" size="3x" />
    </CarouselArrow>
  );
};

const CarouselNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <CarouselArrow className={className} onClick={onClick}>
      <Icon name="angle-right" size="3x" />
    </CarouselArrow>
  );
};

const settings = {
  dots: false,
  infinite: false,
  slidesToScroll: 6,
  slidesToShow: 6,
  prevArrow: <CarouselPrevArrow />,
  nextArrow: <CarouselNextArrow />,
  speed: 500,
  lazyLoad: 'progressive',
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToScroll: 6,
        slidesToShow: 6
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToScroll: 5,
        slidesToShow: 5
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToScroll: 4,
        slidesToShow: 4
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 385,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
};

class ItemCarousel extends React.Component<ItemCarouselProps> {
  carousel = React.createRef();

  componentDidUpdate(prevProps: any, prevState: any) {
    let event: any;
    if (this.carousel) {
      if (document.createEvent) {
        event = document.createEvent('Event');
        event.initEvent('resize', false, true);
      } else {
        event = new Event('resize');
      }
      window.dispatchEvent(event);
    }
  }

  renderSlider = () => {
    const { favoritesList, products, level, dataLoaded } = this.props;
    if (!dataLoaded) {
      return (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      );
    }

    if (Array.isArray(products) && products.length > 0) {
      return (
        <Slider {...settings}>
          {products
            .filter((product) => product !== null)
            .map((product, index) => {
              return (
                <ItemContainer key={index}>
                  <CarouselRewardCard {...{ favoritesList, product, level }} />
                </ItemContainer>
              );
            })}
          {this.props.children}
        </Slider>
      );
    }
    return null;
  };

  render() {
    const { className = '', ...rest } = this.props;
    return (
      <Row className={className}>
        <Col xs={24}>
          <ItemCarouselContainer className={'item-carousel-container'} {...rest}>
            {this.renderSlider()}
          </ItemCarouselContainer>
        </Col>
      </Row>
    );
  }
}

export default ItemCarousel;
