import * as React from 'react';
import { Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { CheckoutConfirmSubHeading, GyftInstructionItem, GyftInstructionsContainer } from './styles';

class GyftInstructions extends React.Component<any, any> {
  render() {
    const {
      headingLabel,
      instructionOneText,
      instructionTwoText,
      instructionThreeText,
      instructionFourText
    } = this.props;
    return (
      <React.Fragment>
        <CheckoutConfirmSubHeading>{headingLabel}</CheckoutConfirmSubHeading>
        <GyftInstructionsContainer>
          <GyftInstructionItem>
            <Text themeVariation="secondary-text">{instructionOneText}</Text>
          </GyftInstructionItem>
          <GyftInstructionItem>
            <Text themeVariation="secondary-text">{instructionTwoText}</Text>
          </GyftInstructionItem>
          <GyftInstructionItem>
            <Text themeVariation="secondary-text">{instructionThreeText}</Text>
          </GyftInstructionItem>
          <GyftInstructionItem>
            <Text themeVariation="secondary-text">{instructionFourText}</Text>
          </GyftInstructionItem>
        </GyftInstructionsContainer>
      </React.Fragment>
    );
  }
}

const mapResourceToProps = (getResource) => ({
  headingLabel: getResource('catalog.checkoutConfirmation.gyftInstructionsHeading', 'Gyft Download Instructions'),
  instructionOneText: getResource(
    'catalog.checkoutConfirmation.gyftInstructionsOneText',
    'Your Gyft e-Gift Card will be delivered to the Gyft email you provided. You can also find a link in your Order History.'
  ),
  instructionTwoText: getResource(
    'catalog.checkoutConfirmation.gyftInstructionsTwoText',
    'Browser recommendations for Gyft e-Gift Card redemption if not using the Gyft app: Microsoft Internet Explorer 10, Google Chrome, Opera, Safari and Firefox. Chrome is now available on all employee systems. (Internet Explorer 8 is NOT recommended)'
  ),
  instructionThreeText: getResource(
    'catalog.checkoutConfirmation.gyftInstructionsThreeText',
    'Any questions should be directed to the IT Help Desk.'
  ),
  instructionFourText: getResource(
    'catalog.checkoutConfirmation.gyftInstructionsFourText',
    'If you have any questions or difficulties with the Gyft website or Gyft App, please contact Gyft directly at 1-650-727-9009 or bizsupport@gyft.com.'
  )
});

export default withResource(mapResourceToProps)(GyftInstructions);
