import * as React from 'react';
import { Route } from 'react-router';
import DefaultLayout from '../layouts/DefaultLayout';
import ContentEdit from '../views/ContentEdit';
import ContentList from '../views/ContentList';
const ManageContentRoutes: React.SFC<any> = ({ match, children }) => {
  return (
    <DefaultLayout>
      <Route path={`${match.path}/list`} component={ContentList} />
      <Route path={`${match.path}/content/edit/:id`} component={ContentEdit} />
      <Route path={`${match.path}/json/edit/:id`} component={ContentEdit} />
    </DefaultLayout>
  );
};

export default ManageContentRoutes;
