import * as React from 'react';
import { getValue } from 'xcel-util';
import RenderDecorator from '../Render/RenderDecorator';
import { TreeBranch, TreeIcon, TreeLeaf } from './styles';

const TreeNode = ({ treeNodeRenderer, treeNodeChildRenderer, treeNodeLabelRenderer, ...props }: any) => {
  const {
    model,
    data,
    active,
    isRoot,
    isLast,
    activeProperty,
    childrenProperty = 'children',
    labelProperty = 'name',
    leafIcon = 'fa-code',
    branchIcon = 'fa-file-code-o',
    onClick
  } = props;
  const children = getValue(data, childrenProperty);
  const hasChildren = children && children.length > 0;
  const handleClick = (node) => (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick(node);
    }
  };
  const isActive = data[activeProperty] === active && active !== undefined;
  return (
    <TreeBranch isRoot={isRoot} isLast={isLast}>
      <TreeLeaf
        isRoot={isRoot}
        isLast={isLast}
        hasChildren={hasChildren}
        onClick={handleClick(data)}
        isActive={isActive}
      >
        <RenderDecorator {...props} name={data[labelProperty]} renderer={treeNodeRenderer}>
          <RenderDecorator {...props} name={data[labelProperty]} renderer={treeNodeLabelRenderer}>
            <span>
              <TreeIcon className={`fa ${hasChildren ? branchIcon : leafIcon}`} />&nbsp;
              {data[labelProperty]}
            </span>
          </RenderDecorator>
          {hasChildren &&
            children.map((child, index) => {
              const namespace = model === undefined ? index.toString() : `${model}.${index}`;
              const name = child[labelProperty];
              const isLastChild = index === children.length - 1;
              return (
                <RenderDecorator key={index} {...props} name={name} model={namespace} renderer={treeNodeChildRenderer}>
                  <TreeNode
                    {...props}
                    treeNodeRenderer={treeNodeRenderer}
                    treeNodeChildRenderer={treeNodeChildRenderer}
                    treeNodeLabelRenderer={treeNodeLabelRenderer}
                    model={`${namespace}.children`}
                    data={child}
                    isLast={isLastChild}
                  />
                </RenderDecorator>
              );
            })}
        </RenderDecorator>
      </TreeLeaf>
    </TreeBranch>
  );
};
export default TreeNode;
