import { social } from 'xcel-api-generator';
import { mapJsonApiActions } from 'xcel-redux-orm';

const {
  getActivities,
  getActivitiesByIdDetails,
  getActivityTypes,
  createActivityTypesByActivityTypeActivities,
  createActivitiesById,
  patchActivitiesById,
  patchActivitiesByIdVersionsAndVersionId,
  deleteActivitiesById,
  deleteActivitiesByIdVersionsAndVersionId
}: any = mapJsonApiActions(social);

export {
  getActivities,
  getActivitiesByIdDetails,
  getActivityTypes,
  createActivityTypesByActivityTypeActivities,
  createActivitiesById,
  patchActivitiesById,
  patchActivitiesByIdVersionsAndVersionId,
  deleteActivitiesById,
  deleteActivitiesByIdVersionsAndVersionId
};
