import { HeaderFour, HeaderTwo, Link } from 'rsv8-components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 0 0 100%;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

export const Title = styled(HeaderFour)`
  font-weight: 300 !important;
  color: #414141 !important;
  margin: 0 0 1rem 0 !important;
`;

export const Name = styled(HeaderTwo)`
  font-size: 30px !important;
  font-style: italic;
  color: #414141 !important;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const Description = styled(Title)`
  font-size: 14px !important;
  margin-bottom: auto !important;
  @media (max-width: 990px) {
    text-align: center;
  }
`;

export const Value = styled(HeaderTwo)`
  font-size: 20px !important;
  font-style: italic !important;
  line-height: 1.5 !important;
  color: #2f82ab !important;
  margin: 1rem 0 !important;
`;

export const Content = styled.div`
  padding: 1rem 6rem 2rem 6rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 990px) {
    align-items: center;
    padding: 1rem 6rem 2rem 6rem;
    height: 100%;
  }
  @media (min-width: 991px) {
    padding: 6rem 6rem 0 6rem;
    flex: 1 1 60%;
  }
`;

export const RedeemButton = styled(Link)`
  margin-top: 20px;
  width: 100%;
`;

export const ImageContainer = styled.div`
  @media (min-width: 991px) {
    flex: 0 0 40%;
  }

  @media (max-width: 990px) {
    display: flex;
    justify-content: center;
  }
`;

export const Image = styled.img`
  @media (max-width: 990px) {
    max-height: 274px;
    width: auto;
  }
  @media (min-width: 991px) {
    max-width: 400px;
    height: auto;
  }
`;
