import {
  ClientCountryModel,
  ClientCultureModel,
  ClientModel,
  PasswordExpressionModel
  } from '../../api/clientAdmin';
import clientSelector from './client';
import countrySelector from './country';
import cultureSelector from './culture';
// legacy state selectors for backwards compatability.
const clientStateSelectors = {
  getClientActive: clientSelector.getActive,
  getCultureActive: cultureSelector.getActive,
  getCultureList: cultureSelector.getAll,
  getCountry: countrySelector.getAll
};

export {
  clientSelector,
  countrySelector,
  cultureSelector,
  clientStateSelectors,
  ClientCountryModel,
  ClientCultureModel,
  ClientModel,
  PasswordExpressionModel
};
