import * as React from 'react';

export interface CopyIconProps {
  onClick?: () => void;
  className?: string;
}

export function CopyIcon(props: CopyIconProps) {
  return (
    <svg className={props.className} onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24">
      <g fillRule="evenodd">
        <path d="M14.104 4H1.896C.85 4 0 4.8 0 5.783v16.434C0 23.2.85 24 1.896 24h12.208C15.15 24 16 23.2 16 22.217V5.783C16 4.8 15.15 4 14.104 4" />
        <path d="M18.104 0H5.895C4.85 0 4 .8 4 1.783v.48c0 .355.307.644.685.644.379 0 .685-.289.685-.645v-.479c0-.272.236-.494.525-.494h12.209c.29 0 .526.222.526.494v16.434c0 .273-.236.494-.526.494h-.51c-.378 0-.684.289-.684.645 0 .355.306.644.685.644h.509C19.149 20 20 19.2 20 18.217V1.783C20 .8 19.15 0 18.104 0" />
      </g>
    </svg>
  );
}
