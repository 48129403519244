// import * as ReactDOM from 'react-dom';

export function  getTimePeriodKeyFromCurrentDate(date: any) {
    let month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : '' + month;

    // let year =;
    return  date.getFullYear() + '' + month;
}  

export function ToUpperCase(text: any) {
    return (text !== undefined) ? text.toUpperCase() : text;
  }

  export function ToLowerCase(text: any) {
    return (text !== undefined) ? text.toLowerCase() : text;
  }
  
export function returnZero(value: any) {
    return (value == null) ? 0 : value;
  }
