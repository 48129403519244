import * as React from 'react';
import { connect } from 'react-redux';
import componentActions from '../../redux/actions/component';
import DragAndDropComponent from './DragAndDropComponent';
import * as types from './types';

const MoveComponent = (props: any): any => (
  <DragAndDropComponent
    {...props}
    data={{ type: types.COMPONENT_EXISTING }}
    onDrop={(dropProps) => {
      const { dragItem, from, to } = dropProps;
      const { dispatch } = props;
      if (dragItem === undefined) return;
      switch (dragItem.type) {
        case types.COMPONENT_EXISTING: {
          dispatch(componentActions.move({ from, to }));
          break;
        }
        case types.COMPONENT_NEW: {
          const component = dragItem.component;
          dispatch(componentActions.add({ component, to }));
          break;
        }
        default:
          break;
      }
    }}
  />
);
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(MoveComponent) as any;
