import { keyframes } from 'styled-components';

export default keyframes`
0% {
   opacity: 0;
}
40% {
   opacity: 1;
   transform: translate(0, -20%);
}
100% {
   opacity: 0;
   transform: translate(0, -1000%);
}
` as any;
