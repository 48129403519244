import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { WizardLink } from 'rsv8-wizard';
import { withResource } from 'xcel-react-core';
import {
  Div,
  NomineeImage,
  TeamMemberDeleteButton,
  TextRow
  } from '../../../components/index';
import PointCost from '../../../components/PointCost';
import { teamNominationSelectors } from '../../../redux/index';
import { getAwards } from '../../../redux/selectors/team';
import { Resources } from '../../../types';
import { TeamPanelBody } from './styles';

export interface TeamMemberPanelProps {
  nominee: Resources.Nominee;
  editable: boolean;
}

interface InternalProps extends TeamMemberPanelProps {
  awardKey: string;
  hasRoleOptions: boolean;
  awardValue: string;
  customFields: object;
  awardLabel: string;
  roleLabel: string;
  roleId: any;
  currentNomination: any;
}

const NomineeNameAndOrganization = ({ nominee }) => (
  <div style={{ paddingLeft: '10px' }}>
    <strong>
      {nominee.firstName} {nominee.lastName}
    </strong>
    <br />
    <span>{nominee.organizationName}</span>
  </div>
);
const TeamMemberPanel: React.SFC<InternalProps & ReturnType<typeof mapState>> = (props) => {
  return (
    <TeamPanelBody variationName="nomination-card">
      <Div display="inline-block">
        <NomineeImage
          nominee={props.nominee}
          caption={true}
          orientation="right"
          captionComponent={NomineeNameAndOrganization}
        />
      </Div>
      {props.editable && (
        <Div float="right">
          <WizardLink stepName="NomineeDetails">Edit Nominee</WizardLink>
          <TeamMemberDeleteButton id={props.nominee.id} />
        </Div>
      )}
      {props.hasRoleOptions ? <TextRow head={props.roleLabel}>{props.role.name}</TextRow> : null}
      <PointCost
        id={props.currentNomination.award}
        pointValue={props.currentNomination.points}
        nomineeId={props.nominee.id}
      />
    </TeamPanelBody>
  );
};

const mapState = (state, { nominee }) => {
  const { id } = nominee;
  const awardKey = teamNominationSelectors.getCurrentAwardId(state);
  const currentAward = getAwards(state).filter((award) => award.id === awardKey)[0];
  const roleId = teamNominationSelectors.getNomineeRoleDetail(state, id);
  const currentRole = roleId && currentAward.roles.filter((role) => role.id.toString() === roleId.toString())[0];
  return {
    hasRoleOptions: teamNominationSelectors.getAwardHasRoles(state),
    awardKey: teamNominationSelectors.getCurrentAwardId(state),
    role: currentRole,
    awardValue: teamNominationSelectors.getNomineeAwardDetail(state, id),
    customFields: teamNominationSelectors.getNomineeCustomFields(state, id),
    currentNomination: teamNominationSelectors.getCurrentNomination(state)
  };
};

const mapResource = (getResource) => ({
  awardLabel: getResource('nominationProcess.awardValueLabel', 'Award Value'),
  roleLabel: getResource('nominationProcess.roleLabel', 'Role')
});

export default compose<any>(
  connect(
    mapState,
    null
  ),
  withResource(mapResource)
)(TeamMemberPanel) as React.ComponentType<TeamMemberPanelProps>;
