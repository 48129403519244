//base config => provider
import { withAuth } from './withAuth';
import { defaultApiProvider } from 'xcel-api-service';

/**
 * Basic Authentication Wrapper for Api Requests
 * @param {Object} Properties any properties you can pass to api provider (i know this doesn't help)
 */

export const discoveryApiProvider = props => {
  const service = withAuth(defaultApiProvider({ ...props, url: '' }), {
    onAuthChange: function(auth) {
      service.setConfig({ headers: { authorization: `bearer ${auth.accessToken}` } });
    },
    exclude: ['setConfig', 'getConfig']
  });
  return {
    //common interface
    put: (url, data, options) => {
      return service.put(url, data, options);
    },
    patch: (url, data, options) => {
      return service.patch(url, data, options);
    },
    post: (url, data, options) => {
      return service.post(url, data, options);
    },
    delete: (url, options) => {
      return service.delete(url, options);
    },
    get: (url, options) => {
      return service.get(url, options);
    },
    options: (url, options) => {
      return service.options(url, options);
    },
    head: (url, options) => {
      return service.head(url, options);
    },
    setConfig: newConfig => {
      service.setConfig(newConfig);
    },
    getConfig: () => service.getConfig()
  };
};
