import styled from 'styled-components';

const ContextMenuContainer = styled.div`
  display: inline-block;
  .open > .dropdown-menu {
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
  }
`;

export default ContextMenuContainer;
