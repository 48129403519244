import * as React from 'react';
import { Clearfix, Col } from 'react-bootstrap';
import { Button, Link } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';

const BtnContainer = styled.div``;

const ApplyBtn = styled(Button)`
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 20px;
`;

const ClearBtn = styled(Link)`
  cursor: pointer;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
    font-size: 14px;
    color: lightgray;
    &:before {
      font-size: 13px;
      content: ' X ';
    }
  }
`;

const MobileBtns = styled.div`
  @media (max-width: 767px) {
    box-shadow: 0 2px 11px 2px rgba(28, 39, 76, 0.09), 0 -1px 0 0 #ededed;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 20px;
    button {
      width: 100%;
      border: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
`;

interface Props {
  clickClear: Function;
  handleReset: Function;
  clickApply: Function;
  handleClose: Function;
  applyFilterButtonText: string;
  clearFilterButtonText: string;
  filterMobileButtonText: string;
  disabled: boolean;
}

const FilterButtons: React.SFC<Props> = (props) => {
  // CLICK CLEAR
  const clickClearBtn = () => {
    props.clickClear(props);
    props.handleReset(props);
  };

  // CLICK APPLY
  const clickApplyBtn = (e) => {
    props.clickApply(props);
    props.handleClose();
  };

  const { applyFilterButtonText, clearFilterButtonText, filterMobileButtonText } = props;

  return (
    <BtnContainer>
      <ApplyBtn
        type="button"
        themeVariation="secondary"
        className="hidden-xs"
        disabled={props.disabled}
        onClick={clickApplyBtn}
      >
        {applyFilterButtonText}
      </ApplyBtn>

      <ClearBtn onClick={clickClearBtn}>{clearFilterButtonText}</ClearBtn>
      <MobileBtns>
        <Col xs={12} xsOffset={6} lgHidden={true} mdHidden={true} smHidden={true}>
          <Button type="button" onClick={clickApplyBtn}>
            {filterMobileButtonText}
          </Button>
        </Col>
        <Clearfix />
      </MobileBtns>
    </BtnContainer>
  );
};

const mapResourceToProps = (getResource) => ({
  applyFilterButtonText: getResource('FilterButtons.applyFilterButtonText', 'Apply Filter'),
  clearFilterButtonText: getResource('FilterButtons.clearFilterButtonText', 'Clear Filter'),
  filterMobileButtonText: getResource('FilterButtons.filterMobileButtonText', 'Filter')
});

export default withResource(mapResourceToProps)(FilterButtons);
