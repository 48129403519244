export const parseJwt = (token) => {
  if (!token) return token;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};
export const camelCase = (value) => value.substr(0, 1).toLowerCase() + value.substr(1);
export const camelCaseObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    let newKey = camelCase(key);
    if (newKey !== key) {
      obj[newKey] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};

export const renameToCamelCaseObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    let newKey = renameToCamelCase(key);
    if (newKey !== key) {
      obj[newKey] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};

export const renameToCamelCase = (str) => {
  const result = str.replace(/_([a-z])/g, (g) => { return g[1].toUpperCase(); });
  return result.charAt(0).toLocaleLowerCase() + result.slice(1);
};
