import { AnyAction } from 'redux';
import * as actions from './actions';
import reducer from './reducers';
import { customFieldSelectors, getFieldsByGroup } from './selectors';

const reducers = {
  customfield: reducer
};

export { reducers, actions, customFieldSelectors, getFieldsByGroup, AnyAction };
