import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  DataTableColumn,
  DataTableDefault,
  Drawer,
  HeaderTwo,
  Text
  } from 'rsv8-components';
import { ModalAnchored } from 'rsv8-components';
import { ApiFilters } from 'rsv8-forms';
import styled from 'styled-components';
import { SalesApp } from 'xcel-api-generator/dist/sales';
import { register } from 'xcel-react-core';
import { withApiDataProvider } from 'xcel-redux-orm';
import { salesActions } from '../../redux/actions';
import { salesActivitySelectors, salesNavigationSelectors } from '../../redux/selectors/';
import SaleDetailEntry from '../SaleDetailEntry/SaleDetailEntry';
import SaleDetailsIcon from './SaleDetailsIcon';
import { SalesActivityContext } from './SalesActivity';

const TableHeader = styled(HeaderTwo)`
  margin: 10px 0 30px 0 !important;
`;

const fields = ['statusId', 'productId'];

type FilterParams = {
  [index: string]: string | number | boolean | Function;
};

interface Props {
  qs: { [index: string]: any };
  getApplicationDetails: typeof salesActions.getApplicationByAppKey;
  hideModal: typeof salesActions.hideSaleDetailsModal;
  filters: FilterParams;
  filter: (filters: FilterParams) => void;
  currentApplication: Partial<SalesApp>;
}

const RowMenu = ({ record }) => <SaleDetailsIcon data={record} />;

const SalesActivityDashboard: React.FunctionComponent<Props> = (props) => {
  const context = React.useContext(SalesActivityContext);

  React.useEffect(
    () => {
      context.setQueryString(props.qs);
    },
    [context.qs, props.qs]
  );

  if (!(props.qs && props.qs.appKey)) return null;

  return (
    <React.Fragment>
      <ModalAnchored
        title={`Submission Details for ${props.currentApplication.name}`}
        anchor="sales-activity-modal"
        onHide={() => props.hideModal(context.qs)}
      >
        <SaleDetailEntry qs={context.qs} />
      </ModalAnchored>
      <div style={{ boxShadow: '0 0 23px 2px rgba(28, 39, 76, 0.12)', padding: '20px', marginBottom: '25px' }}>
        <TableHeader>Sales Activity</TableHeader>
        <Row>
          <Col md={6} style={{ paddingRight: '20px' }}>
            <Drawer start="sm" buttonText="Filter">
              <div style={{ marginBottom: '10px' }}>
                <Text>
                  <strong>Filter by</strong>
                </Text>
              </div>
              <ApiFilters
                filters={props.filters}
                fields={fields}
                onChange={(val) => props.filter({ ...val, appKey: props.qs.appKey })}
              />
            </Drawer>
          </Col>
          <Col md={18}>
            <DataTableDefault {...props} emptyText={'No Entries Found'}>
              <DataTableColumn renderCell={RowMenu} width="45px" />
              <DataTableColumn field="dateSubmitted">Date Submitted</DataTableColumn>
              <DataTableColumn field="confirmationNumber" className="hidden-xs">
                Confirmation Numbers
              </DataTableColumn>
              <DataTableColumn field="productName" className="hidden-xs">
                Product Name
              </DataTableColumn>
              <DataTableColumn field="status">Status</DataTableColumn>
            </DataTableDefault>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

type DispatchProps = Pick<Props, 'getApplicationDetails' | 'hideModal'>;
type StateProps = Pick<Props, 'currentApplication'>;

const mapStateToProps = (state: any, ownProps): StateProps => {
  const appKey = ownProps.qs && ownProps.qs.appKey ? ownProps.qs.appKey : null;
  return {
    currentApplication: salesNavigationSelectors.selectMany(state, appKey)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getApplicationDetails: salesActions.getApplicationByAppKey,
      hideModal: salesActions.hideSaleDetailsModal
    },
    dispatch
  );

export default register('rsv8-sales-entry/SalesActivityDashboard')(
  connect<any, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
  ),
  withApiDataProvider(salesActions.getApplicationByAppKeyEntry, salesActivitySelectors, {
    filters: {
      appKey: (props) => props.qs && props.qs.appKey
    },
    filterParams: ['appKey']
  })
)(SalesActivityDashboard);
