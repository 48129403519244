import * as React from 'react';
import { connect } from 'react-redux';
import { userStateSelectors } from 'rsv8-auth';
import { DataContext } from 'rsv8-cms';
import { register, withContent } from 'xcel-react-core';
import { createTypeSelector } from 'xcel-redux-orm';
import { getValue } from 'xcel-util';
const DataProvider = ({ content, user, children, map, typeSelector, ...rest }) => {
  return <DataContext.Provider value={{ user, ...rest }}>{children}</DataContext.Provider>;
};
const mapContentToProps = (getContent) => ({
  userData: getContent('userData', { type: 'checkbox', label: 'Add User Data' }),
  map: getContent('map', {
    type: 'array',
    schema: [{ model: 'property', label: 'Property' }, { model: 'value', label: 'Redux value' }]
  }),
  typeSelectors: getContent('typeSelectors', {
    type: 'array',
    schema: [
      { model: 'property', label: 'Property' },
      { model: 'type', label: 'Type name' },
      {
        model: 'method',
        type: 'select',
        options: [{ label: 'selectOne', value: 'selectOne' }, { label: 'selectMany', value: 'selectMany' }]
      },
      {
        model: 'id',
        label: 'Id',
        type: 'text'
      }
    ]
  })
});
const mapStateToProps = (state, ownProps) => {
  const map = ownProps.map ? ownProps.map : [];
  const typeSelectors = ownProps.typeSelectors ? ownProps.typeSelectors : [];

  let typeSelectorSet = typeSelectors.reduce((set, mapReduxValue) => {
    const selector = createTypeSelector(mapReduxValue.type);
    if (selector[mapReduxValue.method]) {
      set[mapReduxValue.property] = selector[mapReduxValue.method](
        state,
        mapReduxValue.id === 'null' ? null : parseInt(mapReduxValue, 10)
      );
    }
    return set;
  }, {});

  Object.keys(typeSelectorSet).forEach((typeSelector) => {
    if (typeSelectorSet[typeSelector][0] && typeSelectorSet[typeSelector][0].awardType) {
      typeSelectorSet = {
        ...typeSelectorSet,
        [typeSelector]: typeSelectorSet[typeSelector].filter((x) => {
          return x.awardType === 'Team';
        })
      };
    }
  });

  return {
    user: ownProps.userData ? userStateSelectors.getUser(state) : undefined,
    ...map.reduce((set, mapReduxValue) => {
      set[mapReduxValue.property] = getValue(state, mapReduxValue.value);
      return set;
    }, {}),
    ...typeSelectorSet
  };
};
export default register('rsv8-app/DataProvider')(withContent(mapContentToProps), connect(mapStateToProps))(
  DataProvider
);
