import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { register, withContent, withTheme } from 'xcel-react-core';
import {
  IconImage,
  IconWrapper,
  StyledTooltip,
  TooltipContent
} from './styles/index';

interface TooltipContainerProps {
  placement?: string;
  fontIcon?: string;
  className: string;
}

class ToolTip extends React.Component<TooltipContainerProps> {
  handleClick = () => {
    document.body.click();
  };

  render() {
    const { placement, className, children, fontIcon } = this.props;
    const tooltip = (
      <Popover>
        <StyledTooltip>
          <a href="javascript:void(0)" onClick={this.handleClick} className="closebtn">×</a>
        </StyledTooltip>
        <TooltipContent>
          {children}
        </TooltipContent>
      </Popover>
    );

    return (
      <OverlayTrigger placement={placement || 'right'} overlay={tooltip} trigger='click' rootClose={true}>
        <IconWrapper className={className}>
          {fontIcon && <i className={`fa ${fontIcon}`} aria-hidden="true" />}
          {!fontIcon && <IconImage className="icon-image" themeVariation="password-tooltip" />}
        </IconWrapper>
      </OverlayTrigger>
    );
  }
}

const mapContentToProps = (getContent) => ({
  placement: getContent('placement', { type: 'none' }),
  fontIcon: getContent('fontIcon', { type: 'none' }),
});

export default register('rsv8-components/ToolTip')(
  withContent(mapContentToProps),
  withTheme(['.icon-image'])
)(ToolTip);
