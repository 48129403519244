import * as events from '../exents';

const initialState = {
  activeRoute: '',
  editingRoute: ''
};

export default function adminRouteReducer(state: any = initialState, action: any) {
  const { type, activeRoute, editingRoute, activeModal } = action;

  switch (type) {
    case events.SET_PARENT_ASSET_ROUTE_SUCCESS: {
      return {
        ...state,
        activeRoute
      };
    }

    case events.SET_EDITING_ROUTE_SUCCESS: {
      return {
        ...state,
        editingRoute
      };
    }
    case events.SET_ACTIVE_MODAL_SUCCESS: {
      return {
        ...state,
        activeModal
      };
    }

    case events.SET_PARENT_ASSET_ROUTE_FAILURE:
    default: {
      return state;
    }
  }
}
