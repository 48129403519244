import * as React from 'react';
import { register, withContent } from 'xcel-react-core';
import PageRenderer from '../Rendering/PageRenderer';
import TemplateContext from './TemplateContext';
const TemplateProvider = ({ children, name, path, ...rest }) => {
  return path && rest['data-cms-editing'] !== true ? (
    <TemplateContext.Provider value={{ children }}>
      <PageRenderer path={path} {...rest} />
    </TemplateContext.Provider>
  ) : (
    children
  );
};
const mapContentToProps = (getContent) => ({
  path: getContent('path', { type: 'none', label: 'Path' })
});
export default register('rsv8-cms/TemplateProvider')(withContent(mapContentToProps))(TemplateProvider);
