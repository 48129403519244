import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose, shouldUpdate } from 'recompose';
import { bindActionCreators } from 'redux';
import { DropdownItem, Text } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { Resource } from 'xcel-redux-orm';
import { ContextMenuContainer } from '../../pages/NominationActivity/styles';
import { nominationActivityActions } from '../../redux';
import { NominationActivity } from '../../types/index';

export interface ViewDetailsIconProps {
  data: Resource;
  detailType: NominationActivity.ActivityTables;
  activateModal: typeof nominationActivityActions.activateModal;
  detailsLabel: React.ReactNode;
  printCertificateLabel: React.ReactNode;
}

class ViewDetailsIcon extends React.PureComponent<ViewDetailsIconProps> {
  handleView = () => {
    this.props.activateModal(
      Number(
        this.props.detailType === 'PendingApprovalIndividualTable' ||
        this.props.detailType === 'PendingApprovalTeamTable'
          ? (this.props.data as any).nominationDetailId
          : this.props.data.id
      ),
      this.props.detailType
    );
  };

  render() {
    return (
      <ContextMenuContainer>
        <DropdownButton title="" id={this.props.data.id.toString()}>
          <MenuItem>
            <DropdownItem onClick={this.handleView}>
              <Text themeVariation="secondary-text">{this.props.detailsLabel}</Text>
            </DropdownItem>
          </MenuItem>
          {(this.props.data as any).certificateViewUrl && (
            <MenuItem href={(this.props.data as any).certificateViewUrl} target="_blank">
              <DropdownItem>
                <Text themeVariation="secondary-text">{this.props.printCertificateLabel}</Text>
              </DropdownItem>
            </MenuItem>
          )}
        </DropdownButton>
      </ContextMenuContainer>
    );
  }
}

const mapDispatch = (dispatch) =>
  bindActionCreators({ activateModal: nominationActivityActions.activateModal }, dispatch);

const mapResource = (getResource) => ({
  detailsLabel: getResource('nominationActivity.viewDetailsButtonText', 'View Details'),
  printCertificateLabel: getResource('nominationActivity.printCertificateButtonText', 'Print Certificate')
});

export default compose(
  shouldUpdate((propsA: ViewDetailsIconProps, propsB: ViewDetailsIconProps) => propsB.data.id !== propsA.data.id),
  withResource(mapResource),
  connect<{}, ReturnType<typeof mapDispatch>>(
    null,
    mapDispatch
  )
)(ViewDetailsIcon) as React.ComponentType<Pick<ViewDetailsIconProps, 'detailType' | 'data'>>;
