import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { HeaderFour, Text } from 'rsv8-components';
import styled from 'styled-components';
import { withResource } from 'xcel-react-core';
import { ShippingInfoLabel, ShippingInfoTable, ShippingInfoValue } from '../../../components/Checkout/ShippingInfo/styles';
import { toggleShippingModal } from '../../../redux/actions';
import { getShippingModalVisibility } from '../../../redux/selectors';
import Modal from '../Modal/Modal';

const Info = styled.div`
  padding: 15px 0;
` as any;

const Table = styled(ShippingInfoTable)`
  @media (min-width: 481px) {
    width: 75%;
  }
` as any;

export interface ShippingInfoProps {
  toggleModal: typeof toggleShippingModal;
  shippingProduct?: any;
  shippingInfoHeading: string;
  shippingInfo?: any;
  shippedWithLabel: string;
  trackingNumberLabel: string;
  shipDateLabel: string;
  trackingInfoHeading: string;
  shippingAddressHeading: string;
}

const ShippingInfo: React.SFC<ShippingInfoProps> = ({
  toggleModal,
  shippingProduct,
  shippingInfo,
  shippingInfoHeading,
  shipDateLabel,
  trackingNumberLabel,
  shippedWithLabel,
  trackingInfoHeading,
  shippingAddressHeading,
  ...rest
}) => {
  let parsedShipDate;
  if (shippingProduct.shipDate) {
    parsedShipDate = new Date(shippingProduct.shipDate).toLocaleDateString();
  }

  return (
    <Modal modalHeading={shippingInfoHeading} toggleModal={toggleModal} {...rest}>
      <Info>
        <Text themeVariation="secondary-text-light">{shippingProduct.shipMethodDescription}</Text>
      </Info>
      <Info>
        <HeaderFour themeVariation="normal-margins">{shippingAddressHeading}</HeaderFour>
        <Text themeVariation="secondary-text">{shippingInfo.addressOne}</Text>
        <br />
        {shippingInfo.addressTwo && (
          <React.Fragment>
            <Text themeVariation="secondary-text">{shippingInfo.addressTwo}</Text>
            <br />
          </React.Fragment>
        )}
        <Text themeVariation="secondary-text">{`${shippingInfo.city}, ${shippingInfo.state} ${
          shippingInfo.postalCode
        }`}</Text>
      </Info>
      <Info>
        <HeaderFour themeVariation="normal-margins">{trackingInfoHeading}</HeaderFour>
        <Table nopadding={true}>
          <ShippingInfoLabel>
            <Text themeVariation="secondary-text-bold">{shippedWithLabel}</Text>
          </ShippingInfoLabel>
          <ShippingInfoValue>
            <Text themeVariation="secondary-text">{shippingProduct.shipCompanyName}</Text>
          </ShippingInfoValue>
          {shippingProduct.trackingNumber && (
            <React.Fragment>
              <ShippingInfoLabel>
                <Text themeVariation="secondary-text-bold">{trackingNumberLabel}</Text>
              </ShippingInfoLabel>
              <ShippingInfoValue>
                <Text themeVariation="secondary-text">{shippingProduct.trackingNumber}</Text>
              </ShippingInfoValue>
            </React.Fragment>
          )}

          {parsedShipDate && (
            <React.Fragment>
              <ShippingInfoLabel>
                <Text themeVariation="secondary-text-bold">{shipDateLabel}</Text>
              </ShippingInfoLabel>
              <ShippingInfoValue>
                <Text themeVariation="secondary-text">{parsedShipDate}</Text>
              </ShippingInfoValue>
            </React.Fragment>
          )}
        </Table>
      </Info>
    </Modal>
  );
};

const mapState = (state) => ({
  show: getShippingModalVisibility(state)
});

const mapDispatch = (dispatch) => bindActionCreators({ toggleModal: toggleShippingModal }, dispatch);

const mapResourceToProps = (getResource) => ({
  shippingInfoHeading: getResource('catalog.orderHistory.shippingInfoHeading', 'Shipping Information'),
  shippingAddressHeading: getResource('catalog.orderHistory.shippingAddressHeading', 'Shipping Address'),
  trackingInfoHeading: getResource('catalog.orderHistory.trackingInfoHeading', 'Tracking Information'),
  shippedWithLabel: getResource('catalog.orderHistory.shippedWithLabel', 'Shipped with:'),
  trackingNumberLabel: getResource('catalog.orderHistory.trackingNumberLabel', 'Tracking #:'),
  shipDateLabel: getResource('catalog.orderHistory.shipDateLabel', 'Ship Date:')
});

export default compose<any>(
  connect<ReturnType<typeof mapState>, ReturnType<typeof mapDispatch>, any>(
    mapState,
    mapDispatch
  ),
  withResource(mapResourceToProps)
)(ShippingInfo);
