import * as React from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import { getContrastColor } from '../../../util/getContrastColor';
import rgbaToHex from '../../../util/rgbaToHex';
import ControlledBlur from './ControlledBlur';
import { ColorPickerInput, ColorPickerInputContainer } from './styles';
const CustomSketchContainer = styled(ControlledBlur)`
  position: absolute;
  z-index: 2000;
  .sketch-picker {
    border: none;
    box-shadow: none !important;
    padding: 0 !important;
  }
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
  padding: 10px;
`;

class ColorPicker extends React.Component<any> {
  state = {
    display: false
  };
  handleClickColorInput = (e) => {
    this.handleToggle(e);
  };
  handleChange = (value) => {
    let rgba = Object.keys(value.rgb)
      .map((key) => value.rgb[key])
      .join(',');
    let color = `rgba(${rgba})`;
    if (this.props.onChange) {
      this.props.onChange(color, value);
    }
    if (this.props.input.onChange) {
      this.props.input.onChange(color, value);
    }
  };
  handleToggle = (e) => {
    e.stopPropagation();
    this.setState({ display: !this.state.display });
  };
  handleOpen = (e) => {
    e.stopPropagation();
    this.setState({ display: true });
  };
  handleClose = () => {
    this.setState({ display: false });
  };

  render() {
    const { input = {}, placeholder, label, height, width, colorLabel, colorContent } = this.props;
    const props = {
      value: this.props.value,
      onChange: this.handleChange,
      placeholder: placeholder || '#FFFFFF',
      ...input
    };

    return (
      <React.Fragment>
        {height === undefined && <label>{label}</label>}
        <ColorPickerInputContainer
          title={`${label || colorLabel} (${rgbaToHex(props.value)})`}
          height={height || '34px'}
          width={width || '34px'}
          color={props.value}
          contrast={height ? getContrastColor(props.value) : '#000000'}
          onClick={this.handleClickColorInput}
        >
          <div>
            {height !== undefined && (
              <div>
                <label className="title">{label}</label>
                <span className="color">{rgbaToHex(props.value)}</span>
              </div>
            )}
            <ColorPickerInput {...this.props} {...props} type="color" />
          </div>
        </ColorPickerInputContainer>
        {this.state.display && (
          <CustomSketchContainer onClick={this.handleOpen} onBlur={this.handleClose}>
            {colorLabel && <label>{colorLabel}</label>}
            <SketchPicker colors={props.colors} color={props.value} onChange={this.handleChange} />
            {colorContent}
          </CustomSketchContainer>
        )}
      </React.Fragment>
    );
  }
}
export default ColorPicker;
