import * as React from 'react';
import styled from 'styled-components';
import { AwardImage } from '../../../components/index';

const AwardStarShell = styled.div`
  margin: 0px auto;
  img {
    max-height: 80px;
    width: auto;
  }
`;

// type StarProps = {
//   stars: string | number;
// };

const AwardStars = (props: any) => {
  return (
    <AwardStarShell>
      <AwardImage object={props.award} />
    </AwardStarShell>
  );
};

export default AwardStars;
