import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'rsv8-forms';

let EditApplicationForm = (props) => {
  const { handleSubmit, pristine, submitting, actions } = props;

  let submitEdit = (values) => {
    let model = {
      isDefault: values.appIsDefault,
      name: values.menuAppTitle
    };
    model.isDefault === undefined ? (model.isDefault = false) : '';

    actions.updateApplication(values.appId, model).then((response) => {
      actions.getApplication(values.appClientId);
      document.querySelector<any>('#success-message-edit-app').style.display = 'block';
    });
  };

  let checkForDefault = () => {
    let isDefaultSearch = false;
    let { application } = props.navigationMenuAdmin;

    application.forEach((item) => {
      item.isDefault ? (isDefaultSearch = true) : '';
    });
    return isDefaultSearch;
  };

  return (
    <form onSubmit={handleSubmit(submitEdit)}>
      <div className="form-group">
        <label>
          Title <sup>*</sup>
        </label>
        <div>
          <Field required="true" name="menuAppTitle" component="input" type="text" className="form-control" />
        </div>
      </div>

      <div className="form-group">
        <label>
          Key <sup>*</sup>
        </label>
        <div>
          <Field
            required="true"
            name="menuAppkey"
            component="input"
            type="text"
            className="form-control"
            readOnly="true"
          />
        </div>
      </div>

      <div className="form-group">
        <label>Default Application</label>
        <div>
          <Field
            name="appIsDefault"
            component="select"
            className="form-control"
            disabled={checkForDefault() ? true : false}
          >
            <option value="false">false</option>
            <option value="true">True</option>
          </Field>
        </div>
      </div>

      <div className="form-group">
        <label>
          Application Id <sup>*</sup>
        </label>
        <div>
          <Field name="appId" component="input" type="text" className="form-control" readOnly="true" />
        </div>
      </div>

      <div className="form-group">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          style={{ marginRight: '15px' }}
          disabled={pristine || submitting}
        >
          Save
        </button>
        {/*<button type="button" className="btn btn-sm btn-primary" disabled={pristine || submitting} onClick={reset}>
          Edit Menu Item (Clear Fields)
          </button>*/}
      </div>
      <div className="error-report" />
    </form>
  );
};

EditApplicationForm = reduxForm({
  form: 'EditApplicationForm',
  enableReinitialize: true
})(EditApplicationForm) as any;

const mapState = (state, ownProps) => {
  return {
    initialValues: {
      menuAppTitle: ownProps.node.name,
      menuAppkey: ownProps.node.id,
      appIsDefault: ownProps.node.isDefault,
      appClientId: ownProps.node.clientId,
      appId: ownProps.node.id
    }
  };
};

EditApplicationForm = connect(
  mapState,
  null
)(EditApplicationForm) as any;

export default EditApplicationForm;

//export default reduxForm({ form: 'AddMenuItemForm', })(AddMenuItemForm);
