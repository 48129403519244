import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Text } from 'rsv8-components';
import styled from 'styled-components';
import { register, withContent } from 'xcel-react-core';
import { Legend } from '../../components/Legend';
import { StackedBarChart } from '../../components/StackedBarChart';

const Container = styled.div`
  background: #fad37c;
  padding: 30px;
  height: 415px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;
const Heading = styled(Text)`
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: '#394568';
  align-self: flex-start;
`;
// interface Props {}

const CoreBehaviors: React.FunctionComponent = (props) => {
  return (
    <Card>
      <Container>
        <Heading>
          Award Criteria
          <br />
          Your team’s nominations in 2019
        </Heading>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: '1' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StackedBarChart id="stackedbar1" />
            <Legend
              id="stackedbarlegend"
              colorRange={['#0089c4', '#fff', '#5bbbb7', '#0039a6', '#72b5cc', '#022771']}
              legendVals={[
                'Company Ownership',
                'Innovation & Collaboration',
                'Global Enterprise Capabilities',
                'Client Focused',
                'Vigilance & Integrity',
                'Work Hard & Enjoy'
              ]}
            />
          </div>
        </div>
      </Container>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch)
});

export default register('rsv8-data-vis/CoreBehaviors')(
  withContent(),
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)(CoreBehaviors);
