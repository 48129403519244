import * as React from 'react';
import { connect } from 'react-redux';
import { NavItem } from '../MenuItem';
import { MenuContext } from '../ModularMenus/NavigationContext';

const NavItemLink = (props) => {
  return (
    <MenuContext.Consumer {...props}>
      {({ closeAll }) => {
        return (
          <NavItem
            meta={props.meta}
            id={props.id}
            onClick={closeAll}
            href={props.targetUrl}
            classNames={
              props.routing.location.pathname === props.targetUrl || props.active
                ? ' nav-link-selected '
                : '' + ` ${props.isMore ? ' more ' : ''}` + `${props.navTitleURL === true ? 'navTitleURL' : ' false'}`
            }
            backgroundImage={props.imageURL}
          >
            {props.label}
          </NavItem>
        );
      }}
    </MenuContext.Consumer>
  );
};

const mapStateToProps = (state) => ({ routing: state.routing });
export default connect(mapStateToProps)(NavItemLink) as any;
