import { metric } from 'xcel-api-generator';
import { destroyRequest, getRequest, mapJsonApiActions } from 'xcel-redux-orm';

const metricApiActions = mapJsonApiActions({ ...metric });

const apiAction = ({ endpoint, ...props }) => async (dispatch, getState) => {
  const request = getRequest(getState(), endpoint);
  if (!request.loading) {
    dispatch(destroyRequest(endpoint));
  }
  return dispatch(metricApiActions[endpoint]({ ...props }, { jsonApi: true }));
};

export const getUserAccessMetric = ({ programKey, timePeriodKey, userId }) =>
  apiAction({ endpoint: 'getUserAccessMetric', programKey, timePeriodKey, userId });

export const getUserAccessMetricByMetricKey = ({ programKey, metricKey, timePeriodKey, userId }) =>
  apiAction({ endpoint: 'getUserAccessMetricByMetricKey', programKey, metricKey, timePeriodKey, userId });

export const getUserAccessMetricByMetricKeyFromDate = ({ programKey, metricKey, date, userId }) =>
  apiAction({ endpoint: 'getUserAccessMetricByMetricKeyFromDate', programKey, metricKey, date, userId });

export const getUserAccessMetricByYearAndMetricKey = ({ programKey, metricKey, year, userId }) =>
  apiAction({ endpoint: 'getUserAccessMetricByYearAndMetricKey', programKey, metricKey, year, userId });
