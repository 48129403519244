import { userStateSelectors } from 'rsv8-auth';
import { setClient } from 'xcel-asset-service';
import { cacheService } from 'xcel-cache-service';
import { reduxObserver } from 'xcel-observer';
import { onSelectorChange } from 'xcel-react-core';
import { cachePlugin, createReduxModule, ReduxModule } from 'xcel-redux-module';
import clientActions from './redux/actions/client';
import cultureActions from './redux/actions/culture';
import reducers from './redux/reducers';
import { clientSelector } from './redux/selectors';
const clientModule: ReduxModule = cachePlugin(cacheService)(
  createReduxModule({
    name: 'rsv8-client',
    init: async ({ store }) => {
      const userChanged = onSelectorChange(store, userStateSelectors.getUser);
      store.subscribe(() => {
        userChanged(async (current, next) => {
          const client = clientSelector.getActive(store.getState());
          if (!client) {
            await store.dispatch(clientActions.getClientClient({ jsonApi: true }));
          }
          const currentCultureId = current && current.cultureId && current.cultureId.toString();
          const nextCultureId = next && next.cultureId && next.cultureId.toString();
          const currentUserId = current && current.id && current.id.toString();
          const nextUserId = next && next.userId && next.userId.toString();
          if (parseInt(next.clientId, 10) !== 0 && next.clientId !== undefined) {
            setClient(next.clientId);
          }
          if (
            next.tokenType === 'User' &&
            currentUserId !== nextUserId &&
            currentCultureId !== nextCultureId &&
            nextCultureId !== undefined
          ) {
            await store.dispatch(cultureActions.set(nextCultureId, 'id', true));
            await store.dispatch(cultureActions.getById({ cultureId: nextCultureId }, { jsonApi: true }));
          }
        });
      });
    },
    reducers
  })
);
reduxObserver.addModule(clientModule);

export default clientModule;
