import * as React from 'react';
import { connect } from 'react-redux';
import { CustomerProduct, RebateTimePeriod } from 'xcel-api-generator/dist/alcon';
import { getCurrencyValue } from '../../../utils';
import * as selectors from '../../redux/selectors';
import { ProductDetail } from './ProductDetail';
import { ProductHeader } from './ProductHeader';
import { ProductBody, ProductContainer } from './styles';

const MarketShareProduct: React.FC<Props> = ({ baseTimePeriod, currentTimePeriod, product }) => (
  <ProductContainer className="product">
    <ProductHeader baselineYearLabel={baseTimePeriod.year} name={product.name} unitLabel="sales*" />

    <ProductBody>
      <ProductDetail
        label={baseTimePeriod.name}
        unitLabel="sales*"
        units={product.baseLineUnits}
        value={getCurrencyValue(product.baseLineSaleAmount, 2)}
      />
      <ProductDetail
        label={currentTimePeriod.name}
        unitLabel="sales*"
        units={product.currentUnits}
        value={getCurrencyValue(product.currentSaleAmount, 2)}
      />
    </ProductBody>
  </ProductContainer>
);

const mapStateToProps = (state) => ({
  baseTimePeriod: selectors.baseTimePeriodSelector(state),
  currentTimePeriod: selectors.currentTimePeriodSelector(state),
});

interface StateProps {
  baseTimePeriod: RebateTimePeriod;
  currentTimePeriod: RebateTimePeriod;
}
interface DispatchProps {}
interface OwnProps {
  product: CustomerProduct;
}
type Props = StateProps & DispatchProps & OwnProps;

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(MarketShareProduct);
