import { getValue, setValue } from 'xcel-util';
import * as events from '../events';

const componentReducer = (
  state: {
    title?: string;
    description?: string;
    content?: any;
  } = {},
  action
) => {
  const getContentArray = (model) => {
    const split = model.split('.');
    const index = parseInt(split.pop(), 10) || 0;
    const child = split.join('.');
    const newModel = child.trim() === '' ? `content` : `content.${child}`;
    let array = getValue(state, newModel);
    return { array, model: newModel, index };
  };
  const mapContentChange = (item) => ({ ...item, content: { ...item.content } });
  switch (action.type) {
    case events.COMPONENT_PROPERTY_SET: {
      return setValue(state, `content.${action.model}.content.${action.property}`, action.value);
    }
    case events.COMPONENT_ADD: {
      const to = getContentArray(action.to);
      if (to.array === undefined) {
        to.array = [];
        state = setValue(state, `${to.model}`, to.array);
      }

      to.array.splice(to.index, 0, { ...action.component });
      to.array = [...to.array.map(mapContentChange)];
      return {
        ...state,
        content: [...state.content]
      };
    }
    case events.COMPONENT_DUPLICATE: {
      const from = getContentArray(action.from);
      if (from.array === undefined) {
        return state;
      }
      let copy = from.array[from.index];
      from.array.splice(from.index, 0, { ...JSON.parse(JSON.stringify(copy)) });
      from.array = [...from.array.map(mapContentChange)];
      return {
        ...state,
        content: [...state.content]
      };
    }
    case events.COMPONENT_REMOVE: {
      const from = getContentArray(action.from);
      if (from.array === undefined) {
        return state;
      }
      from.array.splice(from.index, 1)[0];

      from.array = [...from.array.map(mapContentChange)];
      return {
        ...state,
        content: [...state.content]
      };
    }
    case events.COMPONENT_MOVE: {
      const to = getContentArray(action.to);
      if (to.array === undefined) {
        to.array = [];
        state = setValue(state, `${to.model}`, to.array);
      }
      const from = getContentArray(action.from);
      if (from.array === undefined) {
        from.array = [];
        state = setValue(state, `${from.model}`, from.array);
      }
      const fromComponent = from.array.splice(from.index, 1)[0];
      from.array = [...from.array.map(mapContentChange)];
      to.array.splice(to.index, 0, { ...fromComponent });
      to.array = [...to.array.map(mapContentChange)];
      return {
        ...state,
        content: [...state.content]
      };
    }
    default: {
      return state;
    }
  }
};
export default componentReducer;
