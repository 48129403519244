import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { UserStory } from 'rsv8-account';
import { userStateSelectors } from 'rsv8-auth';
import { FavoritesCarousel, TrendingCarousel } from 'rsv8-catalog';
import { Grid } from 'rsv8-components';
import { ImageSlider } from 'rsv8-components';
import { SocialFeed } from 'rsv8-social';
import styled from 'styled-components';
import { assetService } from 'xcel-asset-service';
import { register, withResource } from 'xcel-react-core';
// import { StarWinners } from 'rsv8-nomination';

const FULL_COL = 24;
const ONE_THIRD_COL = 8;
const TWO_THIRDS_COL = 16;

export interface Props {
  user?: any;
}

let HomeContainer = styled(Grid)`
  padding: 25px 0;
  display: flex;

  @media (max-width: 767px) {
    padding: 25px;
    flex-direction: column-reverse;
  }
  strong {
    padding: 10px;
  }
`;

let FitRow = styled(Row)`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 85%;
`;

let SectionRow = (props) => (
  <Row>
    <Col {...props} />
  </Row>
);

let AsideContent = () => (
  <Col xs={FULL_COL} sm={ONE_THIRD_COL}>
    <SectionRow className="hidden-xs" xs={FULL_COL} md={FULL_COL}>
      <UserStory />
    </SectionRow>
    <FitRow>
      <Col xs={FULL_COL} sm={FULL_COL}>
        <SocialFeed />
      </Col>
    </FitRow>
  </Col>
);

let MainContent = (props) => (
  <Col xs={FULL_COL} sm={TWO_THIRDS_COL}>
    <SectionRow xs={FULL_COL} sm={FULL_COL}>
      {/* <StarWinners
        bgImage="/clientassets/images/Four-Star-Winners-HP-Banner.jpg"
        bgMobileImage="/clientassets/images/Four-Star-Winners-HP-Banner-mobile@2x.jpg"
      /> */}

      <ImageSlider
        sliderSettings={{
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 1,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 3000, // 3 seconds
          pauseOnHover: false
        }}
        contentSlides={[
          { image: assetService.getImage(props.animationTeamworkUrl) },
          { image: assetService.getImage(props.animationAwardUrl) },
          { image: assetService.getImage(props.animationRedeemUrl) },
          { image: assetService.getImage(props.animationGiftUrl) }
        ]}
      />
    </SectionRow>
    <SectionRow xs={FULL_COL} md={FULL_COL}>
      <TrendingCarousel cardPath={'/catalog/category'} />
    </SectionRow>
    <SectionRow xs={FULL_COL} md={FULL_COL}>
      <FavoritesCarousel cardPath={'/catalog/favorites'} allLink={true} />
    </SectionRow>
  </Col>
);

let HomePage = (props: Props) => (
  <HomeContainer>
    <AsideContent />
    <MainContent {...props} />
  </HomeContainer>
);

const mapResource = (getResource) => ({
  animationTeamworkUrl: getResource('home.animationTeamworkUrl', '/animations/home-teamwork-scene.gif'),
  animationAwardUrl: getResource('home.animationAwardUrl', '/animations/home-award-scene.gif'),
  animationRedeemUrl: getResource('home.animationRedeemUrl', '/animations/home-redeem-scene.gif'),
  animationGiftUrl: getResource('home.animationGiftUrl', '/animations/home-gift-scene.gif')
});

const mapState = (state) => ({
  user: userStateSelectors.getUser(state)
});

export default register('rsv8-app/Home')(withResource(mapResource), connect(mapState))(HomePage);
