import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authActions, authStateSelectors, userStateSelectors, userActions } from 'rsv8-auth';
import { clientSelector } from 'rsv8-client';
import OAuthService from "rsv8-auth/dist/services/OAuthService";
import { push } from "react-router-redux";
import { getConfigurationProperty } from "xcel-config";

let isInProcessing = false;

class LogOut extends React.Component<any, any> {
  componentDidMount() {
    const { auth, dispatch, client, user } = this.props;
    if (auth.isSSO) {
      dispatch(authActions.user.userLogout());
      window.location = client.loginURL;
    }
    let identityServerConfigData = getConfigurationProperty([
      'identityServerConfigData'
    ]) as any;
    if (auth.isOAuth || identityServerConfigData) {
      const { apiHost } = identityServerConfigData;
      const userData = { clientId: apiHost.clientId };
      const resetData = () => {
        dispatch(userActions.setData(userData));
        isInProcessing = false;
      };
      if (user && auth.isValid) {
        isInProcessing = true;
        dispatch(authActions.user.userLogoutOAuth());
        OAuthService.userManager.clearStaleState();
        OAuthService.userManager.signoutRedirect().then(() => {
          resetData()
        }).catch(error => {
          console.log(error);
          resetData()
        });
      }
      if (!isInProcessing) {
        dispatch(push('/oauth'))
      }
      return;
    }
    dispatch(authActions.user.userLogout());
  }

  render() {
    const { auth } = this.props;
    if (auth.isSSO) {
      return null;
    }
    if (auth.isOAuth || isInProcessing) {
      return null;
    }
    return <Redirect to="/login" />;
  }
}

const mapStateToProps = (state) => ({
  client: clientSelector.selectMany(state, null)[0],
  auth: authStateSelectors.getAuth(state),
  user: userStateSelectors.getUser(state)
});

export default connect(mapStateToProps)(LogOut) as any;
