import { defaultApiProvider } from 'xcel-api-service';
import { withAuth } from './withAuth';
//base config => provider

/**
 * Basic Authentication Wrapper for Api Requests
 * @param {Object} Properties any properties you can pass to api provider (i know this doesn't help)
 */
export const authApiProvider = (...props) => {
  let service = withAuth(defaultApiProvider(...props), {
    onAuthChange: function(auth) {
      if (!service) {
        service = auth.provider;
        auth = auth.auth;
      }
      service.setConfig({ headers: { authorization: `bearer ${auth.accessToken}` } });
    },
    exclude: ['setConfig', 'getConfig']
  });
  return service;
};
