import * as React from 'react';
import styled from 'styled-components';
import { register, withContent, withTheme } from 'xcel-react-core';
let TextStyle = styled.span`
  ${(props: any) => {
    return props.textalign && `text-align: ${props.textalign};`;
  }};
  ${(props: any) => {
    return props.width && `width: ${props.width};`;
  }};
  ${(props: any) => {
    return props.display && `display: ${props.display};`;
  }};
` as any;
let Text = ({ text, children, ...props }: any) => {
  return <TextStyle {...props}>{text || children}</TextStyle>;
};
// Inline text component
const mapTextContentToProps = (getContent) => ({ text: getContent('text', { type: 'text', label: 'Text' }) });
export { TextStyle };
export default register('rsv8-components/typography/Text')(withContent(mapTextContentToProps), withTheme())(Text);
