import * as React from 'react';
import { connect } from 'react-redux';
import {
  contentTypeSelector, contentVersionActions, contentVersionSelector
} from '../../index';
import { Button } from 'rsv8-components'

const UnpublishButton = ({ version, dispatch, contentTypeId }) => {
  const handleChangePublishStatus = () => {
    dispatch(contentVersionActions.unPublish(contentTypeId, version.id));
  };
  return (
    <Button label="Unpublish?" onClick={handleChangePublishStatus}>
      Unpublish?
    </Button>
  );
};
const mapStateToProps = (state) => {
  const contentTypeId = contentTypeSelector.getActive(state);
  return {
    contentTypeId,
    version: contentVersionSelector.getActiveVersion(state, contentTypeId) || {}
  };
};

export default connect(mapStateToProps)(UnpublishButton);
