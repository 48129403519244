import * as React from 'react';
import {
  Grid,
  HeaderThree,
  Image,
  Link
  } from 'rsv8-components';
import styled from 'styled-components';
import { register, withTheme } from 'xcel-react-core';
import { CountBadge } from '../../components/index';

const BadgeButtonContainerBase = styled(Link)`
  align-items: center;
  display: flex !important;
  .container-fluid {
    margin: 0;
    width: 100%;
  }
`;

const BadgeButtonContainer = (props) => <BadgeButtonContainerBase {...props} />;

const BadgeButtonIconBase = styled.div`
  background-image: url(${(props: any) => props.imageURI});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 30px;
  width: 30px;
  display: inline-block;
  position: relative;
`;

const BadgeButtonIcon = (props) => <BadgeButtonIconBase {...props} />;

export interface BadgeButtonProps {
  imageURI?: any;
  count: number;
  labelText: string;
  className: any;
  href: string;
}

const BadgeButton: React.StatelessComponent<BadgeButtonProps> = (props) => (
  <BadgeButtonContainer href={props.href} variationName="quick-link" className={props.className}>
    <Grid display="flex" alignItems="center" fluid="true">
      {props.imageURI && <BadgeButtonIcon imageURI={props.imageURI} />}
      <HeaderThree variationName="padding-right">{props.labelText}</HeaderThree>
      {props.count && <CountBadge>{props.count}</CountBadge>}
      <Image themeVariation="right-arrow" />
    </Grid>
  </BadgeButtonContainer>
);

export default register('rsv8-nomination/BadgeButton')(withTheme())(BadgeButton);
