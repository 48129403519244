import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactField } from 'rsv8-forms';
import { destroyRequest } from 'xcel-redux-orm';
import { convertToPatchOperations } from 'xcel-util';
import { assetAdminApiActions, setActiveAssetRoute, setEditingRoute } from '../../redux/actions';
import { assetAdminToast } from '../../util';

export interface EditRoutes {
  nodeName?: string;
  routeId: number;
  parentId: number;
  actions?: {
    setEditingRoute?: typeof setEditingRoute;
    updateAssetRoute?: typeof assetAdminApiActions.patchClientAssetRoutesById;
    getRoutes?: typeof assetAdminApiActions.getClientAssetRoutes;
    destroyRequest?: typeof destroyRequest;
    setActiveAssetRoute?: typeof setActiveAssetRoute;
  };
}

class EditRouteInput extends React.Component<EditRoutes> {
  state = {
    routeValue: '',
    originalValue: this.props.nodeName
  };

  componentDidMount() {
    this.setState({ originalValue: this.props.nodeName });
  }

  handleChange = (e) => {
    this.setState({ routeValue: e });
  };

  submitRouteChange = () => {
    const { routeValue, originalValue } = this.state;
    if (routeValue === '' || routeValue === originalValue) {
      assetAdminToast('admin-warning', 'Nothing Changed, Nothing to Update');
      ``;
      this.props.actions.setEditingRoute('');
    } else {
      this.createPatchDocs(routeValue);
    }
  };

  createPatchDocs = async (routeValue) => {
    const { routeId, actions, parentId } = this.props;
    const model = {
      routeNodeName: routeValue
    };

    const request = {
      operations: convertToPatchOperations(model)
    };
    try {
      await actions.updateAssetRoute({ id: routeId, request });
      await actions.destroyRequest('getClientAssetRoutes');
      await actions.getRoutes({ filterFilter: `id = ${parentId}` });
      actions.setEditingRoute('');
    } catch (err) {
      await assetAdminToast('admin-warning', err);
    }
  };

  cancelEdit = () => {
    this.props.actions.setEditingRoute('');
  };

  render() {
    const { nodeName } = this.props;

    return (
      <div>
        <ReactField name="EditRoute" component="bs-input" initialValue={nodeName} onChange={this.handleChange} />
        <Button bsStyle="success" onClick={this.submitRouteChange}>
          Submit
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateAssetRoute: assetAdminApiActions.patchClientAssetRoutesById,
      setEditingRoute,
      getRoutes: assetAdminApiActions.getClientAssetRoutes,
      destroyRequest,
      setActiveAssetRoute
    },
    dispatch
  )
});

export default connect<React.Component<EditRoutes>>(
  null,
  mapDispatchToProps
)(EditRouteInput);
