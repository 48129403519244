import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  padding: 50px 25px;
  font-size: 16px;
  color: #7c8180;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
  text-align: center;
  svg {
    width: 200px;
  }
  @media (max-width: 768px) {
    padding: 25px;
  }
` as any;
