import * as events from '../events';

const clientReducer = (state, action) => {
  if (state === undefined) state = { active: {} };
  switch (action.type) {
    case events.CLIENT_SET: {
      return { ...state, active: action.payload };
    }
    default:
      return state;
  }
};
export default clientReducer;
