import * as React from 'react';
import { Alert as BSAlert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { register, withTheme } from 'xcel-react-core';
import { alertActions } from '../../redux';
import { Alert as AlertType } from '../../types';

interface AlertProps {
  alert: AlertType;
  remove: (i: any) => any;
}

class Alert extends React.Component<AlertProps, {}> {
  componentDidMount() {
    let { alert, remove }: any = this.props;

    if (alert.duration) {
      let timeOut = alert.duration * 1000;

      setTimeout(function() {
        remove(alert.id);
      }, timeOut);
    }
  }
  render() {
    let { alert } = this.props;
    if (!alert) {
      return null;
    }
    return (
      <BSAlert bsStyle={alert && alert.type} onDismiss={(e) => this.props.remove(alert.id)}>
        {alert.title && <h4>alert.title</h4>}

        {alert.message && alert.message}
      </BSAlert>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ remove: alertActions.remove }, dispatch);

export default register('rsv8-components/AlertItem')(
  connect(
    null,
    mapDispatchToProps
  ),
  withTheme()
)(Alert) as React.ComponentClass<{ alert: AlertType }>;
