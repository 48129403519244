import * as React from 'react';
import { compose } from 'redux';
import * as styles from './styles'
import {    
  Modal,
  ModalHeader,
  ModalBody  
  } from 'rsv8-components';

import { withResource } from 'xcel-react-core';

export interface Props {
  show: boolean;
  onHide: Function;
  image: string;
  submissionModalText: string;
  enrollmentUserInfo: Object;
  redemptionId: string;
  redemptionValue: string;
  redemptionCall: Function;
  userInfoHeader: string;
  categoryName: string;
  categoryId: string;
  categoryLocation: string;
  categoryEmail: string;
  modalHeader: string;
  modalConfirmButtonText: string;
  modalCancelButtonText: string;
  modalContactCustomerServiceButtonText: string;
}

class SubmissionModal extends React.Component<Props> {      
  state={
    accountSelected: ''
  }

  getNetworkId = (networkId) => {
    this.setState({ accountSelected: networkId })
  }

  closeModal = () => {
    this.setState({ showConfirmationContent: false })
    this.setState({ showInvitationContent: true })
    this.props.onHide()
  }

  handleSubmit() {
    const {redemptionId, redemptionValue, redemptionCall} = this.props

    if (redemptionId && redemptionId !== null) {
      const redemptionBody = {
        redeemOptionId: redemptionId,
        amount: redemptionValue,
        productId: '',
        quantity: 0
      };

      redemptionCall(redemptionBody);
      this.closeModal()
      }
  }

  getUsersBillDotComAccount = (account) => {
    const { userInfoHeader, categoryName, categoryId, categoryLocation, categoryEmail } = this.props

    return (
      <div>
        <styles.userAccountInfoFrame>
          <styles.userAccountInfoHeader>
            <div>{userInfoHeader}</div>
          </styles.userAccountInfoHeader>
          <div className="section">
            <div className="category">{categoryName}</div>
            <div className="information">{account.name}</div>
          </div>
          <div className="section">
            <div className="category">{categoryId}</div>
            <div className="information">{account.networkId}</div>
          </div>
          <div className="section">
            <div className="category">{categoryLocation}</div>
            <div className="information">{account.addressOne}</div>
            <div className="information">{`${account.city}, ${account.state} ${account.zipCode}`}</div>
          </div>
          <div className="section">
            <div className="category">{categoryEmail}</div>
            <div className="information">{account.email}</div>
          </div>
        </styles.userAccountInfoFrame>
      </div>
    );
  }

  render() {
      const { image, enrollmentUserInfo, submissionModalText, modalHeader, modalConfirmButtonText, modalCancelButtonText, modalContactCustomerServiceButtonText } = this.props;
    
      return (            
        <Modal show={this.props.show} onHide={this.props.onHide}>
            <ModalHeader closeButton={true} />            
          <ModalBody>
              <styles.ModalBodyContainer>
                <styles.ModalHeader>{modalHeader}</styles.ModalHeader>
                <styles.ModalImage src={image} />
                <styles.ShowInvitationContent>
                  <div className="contentText">{submissionModalText}</div>
                  {this.getUsersBillDotComAccount(enrollmentUserInfo)}
                  <styles.ContinueButton onClick={() => this.handleSubmit()}>{modalConfirmButtonText}</styles.ContinueButton>
                  <styles.OtherButton onClick={() => this.closeModal()}>{modalCancelButtonText}</styles.OtherButton>
                </styles.ShowInvitationContent>
                <styles.CustServButton href="/help/contact-us">{modalContactCustomerServiceButtonText}</styles.CustServButton>
              </styles.ModalBodyContainer>
          </ModalBody>            
        </Modal>
      );
    }    
}

export default compose(withResource())(SubmissionModal as any);