import * as React from 'react';
import { connect } from 'react-redux';
import cmsPluginSelectors from '../../../redux/cms.plugin.selectors';
import ColorSelector from './ColorSelector';
const ThemeColorSelector = ({ colors, onChange, value, ...rest }) => {
  let colorOptions = Object.keys(colors).map((key) => {
    let color = colors[key];
    return { label: color.group + ' ' + color.name, group: color.group, name: color.name, value: color.color };
  });
  let handleChange = (changeValue, color) => {
    if (onChange) {
      // we want to store the color name for the theme.
      onChange(color.name, color);
    }
  };
  // we want to pull out the color from the name.
  const name = value;
  if (value) {
    value = colors[value] && colors[value].color;
  }
  return <ColorSelector {...rest} value={value} name={name} colors={colorOptions} onChange={handleChange} />;
};

const mapStateToProps = (state) => ({
  colors: cmsPluginSelectors.getColors(state)
});

export default connect(mapStateToProps)(ThemeColorSelector);
