import * as React from 'react';
import * as Icon from 'react-fontawesome';
import { AutoScroll, PanelCollapse, Text } from 'rsv8-components';
import { CollapseButton, CollapseContainer, CollapsePanel } from './styles';

export interface Props {
  title: any;
}

// TODO - Reconcile this functionality with the new Collapse component
class NominationCollapse extends React.Component<Props, { open: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { open: false };
  }

  toggleOpen = () => this.setState({ open: !this.state.open });

  render() {
    const { open } = this.state;
    const { title, children } = this.props;

    return (
      <AutoScroll scroll={open}>
        <CollapseContainer>
          <CollapseButton>
            <Text themeVariation="primary-link" onClick={this.toggleOpen}>
              {title} <Icon name="chevron-down" />
            </Text>
          </CollapseButton>
          <CollapsePanel border={open} expanded={open} onToggle={() => undefined}>
            <PanelCollapse>{children}</PanelCollapse>
          </CollapsePanel>
        </CollapseContainer>
      </AutoScroll>
    );
  }
}

export default NominationCollapse;
