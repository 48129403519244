import * as React from 'react';
import { Icon } from '../Icon';
import { DrawerClose, DrawerContainer, DrawerToggle } from './styles';
import { DrawerProps } from './types';

const SIZE_CLASSES = { xs: 480, sm: 768, md: 992, lg: 1336 };

interface State {
  start: number;
  open: boolean;
}

class Drawer extends React.Component<DrawerProps, State> {
  state = { start: 0, open: false };

  static getDerivedStateFromProps(nextProps: DrawerProps, prevState: any) {
    return { start: typeof nextProps.start === 'number' ? nextProps.start : SIZE_CLASSES[nextProps.start] };
  }

  onResize = () => {
    if (window.innerWidth > this.state.start && this.state.open) {
      this.forceClose();
    }
  };

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps: DrawerProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (!this.props.isOpen) {
        this.forceClose();
      } else {
        this.forceOpen();
      }
    }
  }

  toggleOpen = () => this.setState({ open: !this.state.open });

  forceClose = () => {
    this.props.onHide && this.props.onHide();
    this.setState({ open: false });
    document.body.style.overflowY = 'visible';
  };

  forceOpen = () => {
    this.props.onOpen && this.props.onOpen();
    this.setState({ open: true });
    document.body.style.overflowY = 'hidden';
  };

  render() {
    return (
      <React.Fragment>
        <DrawerToggle block={true} onClick={this.forceOpen} {...this.state}>
          {this.props.buttonIcon && <Icon icon={this.props.buttonIcon} />}
          {this.props.buttonText}
        </DrawerToggle>
        <DrawerContainer {...this.state} backgroundColor={this.props.backgroundColor}>
          <DrawerClose onClick={this.forceClose} {...this.state}>
            <Icon icon={this.props.closeIcon ? this.props.closeIcon : 'close'} />
          </DrawerClose>
          {this.props.children}
        </DrawerContainer>
      </React.Fragment>
    );
  }
}

export default Drawer as React.ComponentType<DrawerProps>;
