import * as React from 'react';
import { connect } from 'react-redux';
import { authStateSelectors } from 'rsv8-auth';
import { isMobile } from 'xcel-util';

const AuthLink = ({ auth, children, ...props }) => {
  let { accessToken } = auth;
  const isMobileCheck = isMobile();
  const isNewTab = isMobileCheck ? '' : '_blank';

  const handleClick = (e) => {
    e.preventDefault();
    const dynamicLink = document.createElement('a');
    dynamicLink.href = props.href.trim() + accessToken.trim();

    dynamicLink.target = isNewTab;
    document.body.appendChild(dynamicLink);

    dynamicLink.click();
  };

  return (
    <li className={props.className}>
      <a href={props.href} onClick={handleClick} onTouchStart={handleClick}>
        {children}
      </a>
    </li>
  );
};

const mapStateToProps = (state) => ({ auth: authStateSelectors.getAuth(state) });
export default connect(mapStateToProps)(AuthLink) as any;
