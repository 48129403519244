import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SearchBar } from '../index';
import { nomineeSearch } from '../../redux/actions/index';

class NomineeSearch extends React.Component<any, {}> {
  submit = (search) => {
    return this.props.actions.nomineeSearch(
      { query: search.searchText, applicationId: this.props.awardId ? this.props.awardId : undefined },
      { clearFilters: true }
    );
  };

  render() {
    return <SearchBar form="nomineeSearchBar" onSubmit={this.submit} />;
  }
}

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ nomineeSearch }, dispatch)
});

export default connect(null, mapDispatch)(NomineeSearch) as React.ComponentClass<{ awardId?: string }>;
