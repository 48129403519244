import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import cmsPluginSelectors from '../../redux/cms.plugin.selectors';
import { mapDispatchToActions } from '../../util';
import { exampleAdd, exampleUpdate } from './../../redux/example.actions';
import ComponentExampleCode from './ComponentExampleCode';
const ComponentExampleManage = ({ name, examples, actions }) => {
  const handleAdd = () => {
    actions.exampleAdd({ name });
  };
  const handleChange = (index) => (example) => {
    actions.exampleUpdate({ index, name, example });
  };
  return (
    <div>
      {examples[name] &&
        examples[name].map((example, index) => {
          return (
            <ComponentExampleCode
              key={index}
              name={name}
              index={index}
              value={example.jsx}
              onBlur={handleChange(index)}
              isEditable={true}
              isRemovable={true}
            />
          );
        })}
      <Button onClick={handleAdd}>Add Example</Button>
    </div>
  );
};
const mapStateToProps = (state) => ({
  examples: cmsPluginSelectors.getExamples(state)
});
const mapDispatchToProps = mapDispatchToActions({ exampleAdd, exampleUpdate });
export default connect(mapStateToProps, mapDispatchToProps)(ComponentExampleManage);
