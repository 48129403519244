import * as React from 'react';
import { Col, Nav, Navbar } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { register, withResource, withTheme } from 'xcel-react-core';
import { NavItem } from '../../MenuItem';
import AuthCodeLink from '../AuthCodeLink';
import LegacyLink from '../LegacyLink';
import NavDropdown from '../NavDropdown';
import NavItemLink from '../NavItemLink';
import { PrimaryNavStyledWrap } from '../styles';
import { parseCol } from './parseCol';

const stickyCSS = css`
  position: fixed;
  top: 0;
  left: 0;
` as any;

interface StickyMenuContainerBaseProps {
  stickyContainerRef: any;
  stuck: any;
  className: any;
}

class StickyMenuContainerBase extends React.Component<StickyMenuContainerBaseProps, any> {
  render() {
    return (
      <div ref={this.props.stickyContainerRef} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

const StickyMenuSpacer = styled.div`
  height: ${(props: any) => props.spacerHeight}px;
` as any;

const StickyMenuContainer = styled(StickyMenuContainerBase)`
  position: relative;
  z-index: 180;
  width: 100%;
  content: '';
  clear: both;

  ${(props) => (props.stuck ? stickyCSS : '')} @media (min-width: 769px) {
    padding: 0;
  }
`;

interface PrimaryNavigationProps {
  culture: any;
  routing: any;
  menuItems: any;
  moreButtonLabel: any;
  stickyScrollThreshold: any;
  xs: any;
  sm: any;
  md: any;
  lg: any;
  xsOffset: any;
  smOffset: any;
  mdOffset: any;
  lgOffset: any;
  beforeRenderer: any;
  className: any;
  fluid: any;
}

interface PrimaryNavigationState {
  isStuck: any;
  moreButtonIndex: any;
}

class PrimaryNavigation extends React.Component<PrimaryNavigationProps, PrimaryNavigationState> {
  navBar: any;
  nav: any;
  stickyContainer: any;

  constructor(props) {
    super(props);
    this.state = {
      isStuck: false,
      moreButtonIndex: -1
    };
  }

  mapMenuItemChildren = (menuItems, routing, depth = 0) => {
    return menuItems.map((child, index) => {
      // Start More Button Shenanigans
      const { moreButtonLabel } = this.props;

      const moreButton = this.state.moreButtonIndex !== -1 && this.state.moreButtonIndex === index && depth === 0 && (
        <NavDropdown title={moreButtonLabel} id={16786807363}>
          {menuItems
            .filter((item, index) => this.state.moreButtonIndex <= index)
            .map((item, i) => {
              let parsedMeta = item.meta ? JSON.parse(item.meta) : {};
              let navTitleURL = parsedMeta;
              let newObject = { ...item, navTitleURL: navTitleURL };
              if (item.children && item.children.length > 0) {
                return (
                  <React.Fragment>
                    <NavDropdown
                      key={i}
                      id={`${item.id}1`}
                      title={item.label}
                      moreButtonIndex={this.state.moreButtonIndex}
                      className={
                        item.children
                          .map((items) => {
                            if (items.targetUrl === routing.location.pathname || items.active) {
                              return 'nav-link-selected';
                            } else {
                              return '';
                            }
                          })
                          .join('') + ` ${isMore ? 'more' : ''} `
                      }
                    >
                      {/* More drop down children */}
                      {item.children.map((child, i) => {
                        let parsedMeta = child.meta ? JSON.parse(child.meta) : {};
                        let navTitleURL = parsedMeta;
                        let newObject = { ...child, navTitleURL: navTitleURL };

                        return this.linkItem(parsedMeta, newObject);
                      })}
                    </NavDropdown>
                  </React.Fragment>
                );
              } else {
                return this.linkItem(parsedMeta, newObject);
              }
            })}
        </NavDropdown>
      );

      // End More Button Shenanigans
      const isMore = this.state.moreButtonIndex <= index && this.state.moreButtonIndex !== -1 && depth === 0;

      if (child.children && child.children.length > 0) {
        return this.dropdownMenuItem(child, routing, moreButton, isMore, depth);
      } else {
        return this.singleMenuItem(child, routing, moreButton, isMore);
      }
    });
  };

  linkItem = (parsedMeta, newObject) => {
    if (
      parsedMeta &&
      parsedMeta.addAuthToken &&
      (parsedMeta.addAuthToken === 'true' || parsedMeta.addAuthToken === 'legacy')
    ) {
      return <LegacyLink {...newObject} />;
    } else if (parsedMeta && parsedMeta.addAuthToken && parsedMeta.addAuthToken === 'v8') {
      return <AuthCodeLink {...newObject} />;
    } else {
      return <NavItemLink {...newObject} />;
    }
  };

  dropdownMenuItem = (item, routing, moreButton, isMore, depth) => {
    let classes = '';
    if ((routing && routing.location && routing.location.pathname === item.targetUrl) || item.active) {
      classes = `${classes} nav-link-selected`;
    }
    if (isMore) {
      classes = `${classes} more`;
    }
    return (
      <React.Fragment>
        {moreButton}
        <NavDropdown {...item} title={item.label} href={item.targetUrl} isMore={isMore} className={classes}>
          {this.mapMenuItemChildren(item.children, routing, depth + 1)}
        </NavDropdown>
      </React.Fragment>
    );
  };

  singleMenuItem = (item, routing, moreButton, isMore) => {
    let parsedMeta = item.meta ? JSON.parse(item.meta) : {};
    let classes = '';
    if ((routing && routing.location && routing.location.pathname === item.targetUrl) || item.active) {
      classes = `${classes} nav-link-selected`;
    }
    if (isMore) {
      classes = `${classes} more`;
    }

    if (
      parsedMeta &&
      parsedMeta.addAuthToken &&
      (parsedMeta.addAuthToken === 'true' || parsedMeta.addAuthToken === 'legacy')
    ) {
      return (
        <React.Fragment>
          {moreButton}
          <LegacyLink {...item} className={classes} />
        </React.Fragment>
      );
    } else if (parsedMeta && parsedMeta.addAuthToken && parsedMeta.addAuthToken === 'v8') {
      return (
        <React.Fragment>
          {moreButton}
          <AuthCodeLink {...item} className={classes} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {moreButton}
          <NavItem {...item} href={item.targetUrl} id={item.id} className={classes}>
            {item.label}
          </NavItem>
        </React.Fragment>
      );
    }
  };
  checkWidth = () => {
    if (this.nav) {
      const parent = this.navBar;
      if (parent) {
        const width = parent.offsetWidth;

        const parentPos = parent.getBoundingClientRect();
        const nav = this.nav;

        if (nav) {
          if (nav.offsetWidth < parent.offsetWidth) {
            if (this.state.moreButtonIndex !== -1) {
              this.setState({ moreButtonIndex: -1 });
            }
          }

          let moreButtonIndex = -1;

          for (let i = 0; i < nav.children.length; i++) {
            const child = nav.children[i];

            const pos = child.getBoundingClientRect();
            const left = pos.left - parentPos.left;
            if (width < left + child.offsetWidth + 30) {
              moreButtonIndex = i - 1;
              break;
            } else {
              child.style.backgroundColor = 'transparent';
            }
          }
          if (this.state.moreButtonIndex !== moreButtonIndex) {
            this.setState({ moreButtonIndex });
          }
        }
      }
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.checkWidth);
    this.checkWidth();
  }

  componentWillMount() {
    window.addEventListener('scroll', this.toggleStuck);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth);
    window.removeEventListener('scroll', this.toggleStuck);
  }
  componentDidUpdate(prevProps) {
    this.checkWidth();
  }

  shouldStick = () => {
    return window.scrollY >= this.props.stickyScrollThreshold;
  };

  toggleStuck = () => {
    if (this.state.isStuck !== this.shouldStick()) {
      this.setState({ isStuck: this.shouldStick() });
    }
  };

  onMount = (name) => (el) => {
    this[name] = ReactDOM.findDOMNode(el);
  };

  render() {
    let {
      menuItems,
      routing,
      children,
      xs,
      sm,
      md,
      lg,
      xsOffset,
      smOffset,
      mdOffset,
      lgOffset,
      beforeRenderer
    } = this.props;

    return (
      <React.Fragment>
        {this.state.isStuck ? (
          <StickyMenuSpacer spacerHeight={this.stickyContainer.getBoundingClientRect().height} />
        ) : null}
        <StickyMenuContainer
          className={this.state.isStuck ? `${this.props.className} sticky-menu` : this.props.className}
          stuck={this.state.isStuck}
          stickyContainerRef={(el) => {
            this.stickyContainer = el;
          }}
        >
          <PrimaryNavStyledWrap className={!this.props.fluid ? 'container' : ''}>
            <Col
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xsOffset={xsOffset}
              smOffset={smOffset}
              mdOffset={mdOffset}
              lgOffset={lgOffset}
            >
              <Navbar ref={this.onMount('navBar')} fluid={this.props.fluid !== false} className="primary-nav-wrap">
                <Nav ref={this.onMount('nav')} className="menu-item-container">
                  {beforeRenderer && beforeRenderer()}
                  {menuItems !== undefined && this.mapMenuItemChildren(menuItems, routing)}
                </Nav>
              </Navbar>
            </Col>

            <Col
              xs={parseCol(xs, xsOffset)}
              sm={parseCol(sm, smOffset)}
              md={parseCol(md, mdOffset)}
              lg={parseCol(lg, lgOffset)}
            >
              {children}
            </Col>
          </PrimaryNavStyledWrap>
        </StickyMenuContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ routing: state.routing });

const mapResource = (getResource) => ({
  moreButtonLabel: getResource('navigation.moreButtonLabel', 'More')
});

export default register('rsv8-navigation/PrimaryNavigation')(
  withResource(mapResource),
  connect(mapStateToProps),
  withTheme(['&.sticky-menu'])
)(PrimaryNavigation);
