import { HeaderThree, Link } from 'rsv8-components';
import styled from 'styled-components';
import CardContainer from '../../components/CardContainer/CardContainer';

export const ItemCarouselContainer = styled.div`
  ${(props: any) => !props.unslicked && `box-shadow: 0 0 0 1px #dddddd;`};
  margin-bottom: 70px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  .unslicked.slick-slider {
    display: flex !important;
  }

  .unslicked .slick-slide {
    width: 215px !important;
  }

  .unslicked .slick-track {
    border: 1px solid #dddddd;
    width: auto !important;
  }

  .slick-slider:not(.unslicked) {
    overflow: hidden;

    .slick-slide:first-of-type {
      box-shadow: inset 1px 0 0 0 #dddddd, 1px 0 0 0 #dddddd;
    }
  }

  .slick-slider.unslicked {
    overflow: hidden;

    .slick-slide:first-of-type {
      border-left: 1px solid #dddddd;
    }
  }

  .slick-track {
    display: flex;
    height: 100%;
    left: -1px;
    overflow: hidden;

    .slick-slide {
      box-shadow: 1px 0 0 0 #dddddd;
      height: auto;
      overflow: hidden;
      position: relative;

      & > div {
        height: 100%;
      }
    }
  }
` as any;

export const CarouselHeader = styled.div`
  display: flex;
  h2 {
    margin: 20px 20px 20px 0;
  }
` as any;

export const CarouselArrow = styled.div`
  &.slick-arrow {
    background: rgba(245, 245, 245, 0.7) !important;
    border: none;
    cursor: pointer;
    display: block;
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.2));
    font-size: inherit;
    height: 150px;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%);
    transition: width 0.25s;
    width: 25px;
    z-index: 10;

    &:hover {
      width: 50px;
    }
    &::before {
      display: none;
    }
  }
  &.slick-next {
    right: 0;
  }
  &.slick-prev {
    left: 0;
  }
  &.slick-disabled {
    display: none;
  }
  span {
    color: #4c4f4f;
    line-height: 150px;
  }
` as any;

export const ItemCarouselHeading = styled(HeaderThree)`
  color: #4c4f4f;
  display: inline-block;
  margin-bottom: 25px;
` as any;

export const ItemCarouselSubHeading = styled.div`
  font-size: 16px;
  margin: 10px 0 0 5px;

  @media (min-width: 769px) {
    display: inline-block;
  }
` as any;

export const AllLinkContainer = styled.span`
  align-self: center;
  margin-bottom: 17px;
  margin-left: 10px;
` as any;

export const CardLink = styled(Link)`
  color: #4192a5;
  font-size: 14px;
  text-align: center;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    color: #4192a5;
    text-decoration: none;
  }
` as any;

export const ImageContainer = styled.div`
  display: contents;
  img {
    width: 150px;
    height: auto;
    padding-bottom: 20px;
  }
` as any;

export const CustomCardContainer = styled(CardContainer)`
  align-items: center;
  display: flex;
  justify-content: center;
` as any;

export const CustomCardImage = styled.img`
  width: 100%;
  max-width: 207px;
` as any;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 354px;
  justify-content: center;
` as any;

export const ItemContainer = styled.div`
  height: 100%;
` as any;

export const CustomCard = styled.div`
  &:focus {
    outline: none;
  }
` as any;

export const EmptyImage = styled.img`
  width: 200px;
  height: auto;
` as any;
