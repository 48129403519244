import * as React from "react";
import styled from "styled-components";

const ScopedStyledComponent = styled.div`
  ${(props: any) => {
    return props.css;
  }};
` as any;

const getStyledComponentId = () => {
  const component = (<ScopedStyledComponent />) as any;
  return component.type.styledComponentId;
};
let id = getStyledComponentId();
const regex = new RegExp(`\\/\\* sc-component-id.+${id}((?!\\/\\* sc-component-id)[\\s\\S])+`, "gm");
class ScopedStyles extends React.Component<any> {
  state = {
    styles: {},
    internal: ""
  };
  componentDidMount() {
    const { styles, url } = this.props;
    if (styles === undefined) return;
    Promise.all(styles.map((style) => this.getExternalStyle(style, url))).then((externalStyleSet) => {
      const externalStyles = externalStyleSet.reduce((current: any, item: any) => {
        current = { ...current, ...item };
        return current;
      }, {});
      this.setState({ styles: externalStyles, internal: this.getInternalStyle() });
    });
  }
  getExternalStyle = async (url: string, baseUrl: string = "") => {
    const response = await fetch(url);
    let css = await response.text();
    css = css.replace(/url\((['"]*)[/]?([^:'")]+)(['"]*)\)/g, `url($1${baseUrl}$2$3)`);
    return { [url]: css };
  };
  getInternalStyle = () => {
    const elements = { ...document.querySelectorAll("style[data-styled-components]") };
    const css = Object.keys(elements)
      .map((key) => {
        try {
          const text = elements[key].innerText;
          return text.replace(regex, "");
        } catch (e) {
          console.warn(e, elements[key], elements[key].innerText.length);
          return "";
        }
      })
      .join("\n");
    return css;
  };
  render() {
    const external = Object.keys(this.state.styles)
      .map((key) => this.state.styles[key])
      .join("\n");
    const internal = this.state.internal;
    const css = `${external}\n${internal}`;
    const scopedStyle = (
      <ScopedStyledComponent
        data-scoped-style={Object.keys(this.state.styles).join(",")}
        data-scoped-loaded={Object.keys(this.state.styles)
          .map((key) => this.state.styles !== undefined)
          .join(",")}
        css={css}
      >
        {this.props.children}
      </ScopedStyledComponent>
    ) as any;
    return scopedStyle;
  }
}
export default ScopedStyles;
