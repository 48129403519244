import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'rsv8-components';
import styled from 'styled-components';

export interface TextRowProps {
  head: React.ReactNode;
  headComponent?: React.ComponentType<{}>;
}

const StyledRow = styled(Row)`
  padding-top: 10px;
`;

const TextRow: React.SFC<TextRowProps> = ({ head, headComponent: Head = Text, children, ...rest }) => (
  <StyledRow {...rest}>
    <Col xs={24} sm={24}>
      <Head themeVariation="field-label">{head}</Head>
    </Col>
    <Col xs={24} sm={24}>
      {children}
    </Col>
  </StyledRow>
);

export { TextRow };
