import styled from 'styled-components';
export const getHeight = (thesize:any) => {
  const weight = 1;
  return (112 + thesize) * weight;
};
export const getWidth = (thesize:any) => {
  return 112 + thesize;
};
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1365px',
  laptopL: '1440px',
  desktop: '1400px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM:  `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

export const Title = styled.h1`
  font-family:'open-sans-bold';
  color:#000;
  font-size:16px;
  margin: 0;
`as any
export const subText = styled.div`
  font-family:'open-sans';
  color:#4f4f4f;
  font-size:16px;
`as any
export const ShipToComponent = styled.div`
  margin: 20px 10px;
  width: 100%;
  @media ${device.desktop} {
      max-width: 560px;
  }
  @media ${device.tablet} {
      max-width: 560px;
  }
`as any;
export const leftSide = styled.div`
`as any;
export const rightSide = styled.div`
  &.offset {
    margin-top: 16px;
  }
`as any;