import * as React from 'react';

let listeners = [];
const triggerBlur = (controlListener) => {
  listeners.filter((listener) => listener !== controlListener).forEach((listener) => listener());
};
window.addEventListener('click', triggerBlur);

class ControlledBlur extends React.Component<any, any> {
  componentDidMount() {
    triggerBlur(this.props.onBlur);
    listeners.push(this.props.onBlur);
  }
  componentWillUnmount() {
    listeners = listeners.filter((listener) => listener !== this.props.onBlur);
  }
  handleClick = (e) => {
    const { onBlur, onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
    triggerBlur(onBlur);
  };
  render() {
    return <div {...this.props} />;
  }
}

export default ControlledBlur as any;
