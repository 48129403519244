import { stringify } from 'query-string';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid, Loader } from 'rsv8-components';
import { withResource } from 'xcel-react-core';
import { register, withTheme } from 'xcel-react-core';
import { FavoritesCarousel, TrendingCarousel } from '../../components/CarouselWrappers';
import PointRangeError from '../../components/PointRangeSearch/PointRangeError';
import PointRangeSearch from '../../components/PointRangeSearch/PointRangeSearch';
import { getAccountSummary } from '../../redux/actions';
import { getCurrentAccountSummary } from '../../redux/selectors';
import { BrowseHeading, Divider, PointFilterContainer } from './styles';

// Number.isInteger Polyfill for IE11
Number.isInteger =
  Number.isInteger ||
  function(value: any) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };

class BrowseByPoints extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      displayError: false,
      maxPoints: '',
      minPoints: '',
      rangeErrors: {}
    };
  }

  componentDidMount() {
    this.props.actions.getAccountSummary();
  }

  constructQuery = () => {
    return stringify({
      filterMaxPoints: this.state.maxPoints,
      filterMinPoints: this.state.minPoints
    });
  };

  showCarousel = (products) => {
    return products && !products.some((product) => product === null);
  };

  handleSubmit = (e) => {
    const { maxPoints, minPoints } = this.state;
    e.preventDefault();
    let rangeErrors: any = {};

    if (
      !Number.isInteger(parseInt(minPoints, 10)) ||
      !Number.isInteger(parseInt(maxPoints, 10)) ||
      parseInt(minPoints, 10) < 0 ||
      parseInt(maxPoints, 10) < 0
    ) {
      rangeErrors.invalidNumberError = true;
    }
    if (parseInt(maxPoints, 10) < parseInt(minPoints, 10)) {
      rangeErrors.invalidRangeError = true;
    }
    if (Object.keys(rangeErrors).length > 0) {
      this.setState({ displayError: true, rangeErrors: rangeErrors });
    } else {
      const searchQuery = this.constructQuery();
      this.props.history.push({
        pathname: '/catalog/search',
        search: searchQuery
      });
    }
  };

  handleMaxChange = (e) => {
    if (!e.target.value || e.target.value.match(/^\d+$/) !== null) {
      this.setState({ maxPoints: e.target.value });
    }
  };

  handleMinChange = (e) => {
    if (!e.target.value || e.target.value.match(/^\d+$/) !== null) {
      this.setState({ minPoints: e.target.value });
    }
  };

  renderError = () => {
    if (this.state.displayError) {
      return <PointRangeError displayError={this.state.displayError} rangeErrors={this.state.rangeErrors} />;
    } else {
      return '';
    }
  };

  formatPoints = (points) => parseFloat(points).toLocaleString('en');

  render() {
    const {
      accountSummary,
      favoriteCarouselCardText,
      favoriteCarouselTitle,
      maxPointsText,
      minPointsText,
      pageHeading,
      submitButtonText,
      trendingCarouselTitle,
      trendingCarouselCardPreText,
      trendingCarouselCardPostText,
      carouselSubPreText,
      carouselSubPostText
    } = this.props;

    return (
      <Grid themeVariation="page-wrapper">
        <Row>
          <BrowseHeading>{pageHeading}</BrowseHeading>
        </Row>
        {accountSummary ? (
          <React.Fragment>
            <Row>
              <Col>
                {this.renderError()}
                <PointFilterContainer className={this.props.className}>
                  <PointRangeSearch
                    displayError={this.state.displayError}
                    handleSubmit={this.handleSubmit}
                    handleMaxChange={this.handleMaxChange}
                    handleMinChange={this.handleMinChange}
                    buttonText={submitButtonText}
                    maxText={maxPointsText}
                    minText={minPointsText}
                    minVal={this.state.minPoints}
                    maxVal={this.state.maxPoints}
                  />
                </PointFilterContainer>
              </Col>
            </Row>

            <FavoritesCarousel
              cardText={favoriteCarouselCardText}
              cardPath={'/catalog/favorites'}
              filterByPoints={true}
              title={favoriteCarouselTitle}
              titleSubtext={`${carouselSubPreText} ${this.formatPoints(
                accountSummary.balance.total
              )} ${carouselSubPostText}`}
              titleAlign={true}
            />

            <Divider />

            <TrendingCarousel
              cardText={`${trendingCarouselCardPreText} ${this.formatPoints(
                accountSummary.balance.total
              )} ${trendingCarouselCardPostText}`}
              cardPath={`/catalog/search?filterMaxPoints=${accountSummary.balance.total}`}
              filterByPoints={true}
              title={trendingCarouselTitle}
              titleSubtext={`${carouselSubPreText} ${this.formatPoints(
                accountSummary.balance.total
              )} ${carouselSubPostText}`}
            />
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  accountSummary: getCurrentAccountSummary(state)
});

const mapResourceToProps = (getResource) => ({
  pageHeading: getResource('catalog.browseByPoints.heading', 'Browse By Points'),
  maxPointsText: getResource('catalog.browseByPoints.maxPoints', 'Max Point Value'),
  minPointsText: getResource('catalog.browseByPoints.minPoints', 'Min Point Value'),
  submitButtonText: getResource('catalog.browseByPoints.submitButton', 'Search'),
  favoriteCarouselCardText: getResource('catalog.browseByPoints.favoriteCarouselCardText', 'Manage Your Favorites'),
  favoriteCarouselTitle: getResource('catalog.browseByPoints.favoriteCarouselTitle', 'Your Favorites you can get now'),
  trendingCarouselTitle: getResource('catalog.browseByPoints.trendingCarouselTitle', 'Top picks you can get now'),
  trendingCarouselCardPreText: getResource('catalog.browseByPoints.trendingCarouselCardPreText', 'Browse all under'),
  trendingCarouselCardPostText: getResource('catalog.browseByPoints.trendingCarouselCardPostText', 'pts.'),
  carouselSubPreText: getResource('catalog.browseByPoints.trendingCarouselSubPreText', '(Under'),
  carouselSubPostText: getResource('catalog.browseByPoints.trendingCarouselSubPostText', 'pts.)')
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAccountSummary
    },
    dispatch
  )
});

export default register('rsv8-catalog/BrowseByPoints')(
  compose<any>(connect(mapStateToProps, mapDispatchToProps), withTheme(), withResource(mapResourceToProps))
)(BrowseByPoints);
