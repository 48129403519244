import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { register, withContent, withResource } from 'xcel-react-core';
import ItemCarousel from '../../components/ItemCarousel/ItemCarousel';
import { getFavoriteProducts } from '../../redux/actions';
import {
  getFavoriteList,
  getFavoriteProductList,
  getFavoriteRequest,
  getFavoritesLoaded,
  getProductsRequest
  } from '../../redux/selectors';

interface FavoritesCarouselProps {
  dispatch: any;
  allLink?: boolean;
  favoriteProducts: Array<any>;
  favoriteRequest: any;
  favoritesIcon: any;
  favoritesList: any;
  favoritesLoaded: any;
  filterByPoints?: boolean;
  productsRequest: any;
  title: string;
  titleAlign?: boolean;
  titleSubtext?: string;
}

class FavoritesCarousel extends React.Component<FavoritesCarouselProps, any> {
  componentDidMount() {
    this.props.dispatch(getFavoriteProducts({ id: 'favorite' }));
  }

  render() {
    const { allLink, favoritesIcon, favoritesList, favoriteProducts = [], favoritesLoaded, ...rest } = this.props;
    return (
      <ItemCarousel
        allLink={allLink ? `/catalog/favorites` : undefined}
        cardIcon={favoritesIcon}
        favoritesList={favoritesList}
        products={favoriteProducts.filter((product) => product !== null).slice(0, 14)}
        dataLoaded={favoritesLoaded}
        {...rest}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  favoritesList: getFavoriteList(state),
  favoriteProducts: getFavoriteProductList(state),
  favoriteRequest: getFavoriteRequest(state),
  productsRequest: getProductsRequest(state),
  favoritesLoaded: getFavoritesLoaded(state)
});

const mapResourceToProps = (getResource) => ({
  favoritesIcon: getResource('catalog.favoritesIcon', '/clientassets/images/icons/empty-state--wishlist.svg'),
  title: getResource('catalog.shopLanding.favoritesCarouselTitle', 'My Favorites'),
  cardText: getResource('catalog.shopLanding.favoritesCarouselCardText', 'Manage Your Favorites')
});

const mapContentToProps = (getContent) => ({
  cardPath: getContent('cardPath', { type: 'none' }),
  allLink: getContent('allLink', {
    type: 'select',
    label: 'allLink',
    options: [{ label: 'false', value: 'false' }, { label: 'true', value: 'true' }]
  })
});

export default register('rsv8-catalog/FavoritesCarousel')(
  connect(mapStateToProps),
  withRouter,
  withContent(mapContentToProps),
  withResource(mapResourceToProps)
)(FavoritesCarousel) as any;
